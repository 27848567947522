import React from "react";
import {useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import {selectIsLoading} from "../../redux/reducers/interface";
import {Backdrop} from "@mui/material";

const LoadingComponent = () => {
    let isLoading = useSelector(selectIsLoading)
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};


export default LoadingComponent;
