import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useMutation } from '@tanstack/react-query';
import {
  createChangeProductOrderPriceHistory,
} from '../../requests/ProductionServerHandler';
import { OpenErrorNotification, OpenNotificationThenReloadPage } from '../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../customs/ProjectPaper';
import { Button, Grid, Stack } from '@mui/material';
import GridDetailText from '../customs/GridDetailText';
import FormProvider, { RHFAutoCompleteShort, RHFCheckbox, RHFTextField } from '../minimal/hook-form';
import { ProductOrderScanner } from '../barcode-reader';
import * as Yup from 'yup';

EditProductOrderPriceForm.propTypes = {
  productOrder: PropTypes.object,
  onSuccess: PropTypes.func,
};

function EditProductOrderPriceForm({ productOrder, onSuccess }) {
  const { translate } = useLocales();
  const [editProductOrder, setEditProductOrder] = useState(productOrder);

  const editPriceSchema = Yup.object().shape({
    IsBundle: Yup.boolean().required(translate('error.detail_require')),
    SelectProductSellHistory: Yup.object().shape({
      Id: Yup.number().required(translate('error.detail_require')),
      Name: Yup.string().required(translate('error.detail_require')),
    }).required(translate('error.detail_require')),
    NewPrice: Yup.number().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(editPriceSchema),
    defaultValues: {
      IsBundle: false,
      NewPrice: 0,
    },
  });

  const { handleSubmit, control } = methods;

  const selectProductSellHistoryWatch = useWatch({
    control,
    name: 'SelectProductSellHistory',
    defaultValue: null,
  });
  const changeProductSellPriceMutation = useMutation({
    mutationFn: createChangeProductOrderPriceHistory,
    onSuccess: async () => {
      onSuccess !== undefined ? onSuccess() : await OpenNotificationThenReloadPage();
    },
  });
  const changeProductSellPrice = async (values) => {
    if (editProductOrder !== null) {
      await changeProductSellPriceMutation.mutateAsync({
        ProductSellHistoryId: values.SelectProductSellHistory.Id,
        NewPrice: values.NewPrice,
        IsBundle: values.IsBundle,
      });
    } else {
      await OpenErrorNotification('Please Select Delivery Bill Id');
    }
  };
  const handleFindProductOrder = (productOrder) => {
    setEditProductOrder(productOrder);
  };

  return (
    <div>
      {
        productOrder !== null ? <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={editProductOrder.Id} />
              <GridDetailText title={translate('title.name')} value={editProductOrder.Name} />
              {selectProductSellHistoryWatch !== null ? (
                <GridDetailText
                  title={translate('accounting.current_sell_price')}
                  value={selectProductSellHistoryWatch.SellPrice}
                />
              ) : null}
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(changeProductSellPrice)}>
              <Stack spacing={3}>
                <RHFCheckbox name="IsBundle" label={translate('title.bundle_price')} />
                <RHFAutoCompleteShort
                  name={'SelectProductSellHistory'}
                  label={translate('title.process')}
                  data={editProductOrder.ProductSellHistories.map((priceDetail) => {
                    return {
                      Id: priceDetail.Id,
                      Name: priceDetail.Product.Name,
                      SellPrice: priceDetail.SellPrice,
                    };
                  })}
                />
                <RHFTextField name={'NewPrice'} label={translate('title.price')} />
                <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
                  {translate('button.save')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </Stack> : <ProductOrderScanner isPreFetchData onSearchCallBack={handleFindProductOrder} />
      }
    </div>
  );
}

export default EditProductOrderPriceForm;