import React from 'react';
import PropTypes from 'prop-types';
import FormProvider, {
  RHFDatePicker,
  RHFCheckbox,
  RHFTextField,
} from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../customs/ProjectPaper';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

CreateProcurementPoForm.propTypes = {
  handleCreatePO: PropTypes.func.isRequired,
};

function CreateProcurementPoForm({ handleCreatePO }) {
  const { translate } = useLocales();

  const createPOSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    ReferenceId: Yup.string().required(translate('error.detail_require')),
    DeliveryAt: Yup.date().required(translate('error.due_date_require')),
    IsVat: Yup.bool().required(translate('error.invalid_input')),
    Discount: Yup.number()
      .min(0, translate('error.detail_require'))
      .required(translate('error.detail_require')),
    DeliveryPrice: Yup.number()
      .min(0, translate('error.detail_require'))
      .required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(createPOSchema),
    defaultValues: {
      Detail: '',
      ReferenceId: '',
      IsVat: true,
      Discount: 0,
      DeliveryPrice: 0,
    },
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPaper>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleCreatePO)}>
        <Stack spacing={3}>
          <RHFTextField name={'Detail'} label={translate('title.detail')} />
          <RHFTextField
            name={'ReferenceId'}
            label={translate('procurement.po_reference_id_title')}
          />
          <RHFTextField name={'Discount'} label={translate('procurement.discount')} />
          <RHFTextField
            name={'DeliveryPrice'}
            label={translate('procurement.delivery_price_title')}
          />
          <RHFDatePicker
            name={'DeliveryAt'}
            label={translate('title.due_at')}
            inputFormat={'PPPP'}
          />
          <RHFCheckbox name={'IsVat'} label={'7% Vat'} />
          <Button variant={'contained'} type={'submit'} fullWidth color={'primary'}>
            {translate('button.create')}
          </Button>
        </Stack>
      </FormProvider>
    </ProjectPaper>
  );
}

export default CreateProcurementPoForm;
