import PropsTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { LoadingContext } from './loadingContext';

LoadingProvider.PropsTypes = {
  children: PropsTypes.node.isRequired,
};
export function LoadingProvider({ children }) {
  const [open, setOpen] = useState(false);

  const showLoadingScreen = useCallback(() => {
    setOpen(true);
  }, []);
  const hideLoadingScreen = useCallback(() => {
    setOpen(false);
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isLoading: open,
      showLoadingScreen: showLoadingScreen,
      hideLoadingScreen: hideLoadingScreen,
    }),
    [open, showLoadingScreen, hideLoadingScreen]
  );

  return <LoadingContext.Provider value={memoizedValue}>{children}</LoadingContext.Provider>;
}
