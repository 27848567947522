import React, {useState} from 'react';
import {ProjectPageContainer} from '../../container/ProjectPageContainer';
import {
    assignProductOrderToMachine,
    getAllPlanningInfo,
} from '../../../requests/ProductionServerHandler';
import {RouterLink} from 'routes/components';
import Iconify from 'components/minimal/iconify';
import {useLocales} from 'locales';
import ReactTable from '../../react-table/ReactTable';
import {fNumber} from '../../../utilities/formatNumber';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Tabs,
    Tab,
    Paper,
    Slide,
    CardActionArea,
    Typography,
} from '@mui/material';
import useTabs from '../../../hooks/useTabs';
import Label from 'components/minimal/label';
import ProjectPaper from '../../customs/ProjectPaper';
import {useTheme} from '@mui/material/styles';
import {OpenSuccessNotification} from '../../../utilities/Helper/NotificationHelper';
import {OPEN_ICON} from '../../../config-global';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {paths} from '../../../routes/paths';

const ProductionPlanningPage = () => {
    const queryClient = useQueryClient();
    const theme = useTheme();
    const {translate} = useLocales();
    const [selectProductOrder, setSelectProductOrder] = useState(null);
    const [showMachine, setShowMachine] = useState([]);
    const [showMachineSelect, setShowMachineSelect] = useState(false);
    const {currentTab, onChangeTab} = useTabs('not_planning');

    const {data: planningInfo} = useQuery({
        queryKey: ['planningInfo'],
        queryFn: getAllPlanningInfo,
        initialData: {
            AllMachine: [],
            AllActiveProductOrder: [],
        },
    });

    const assignPlanToMachineMutation = useMutation({
        mutationFn: assignProductOrderToMachine,
        onSuccess: async (data, variables) => {
            await OpenSuccessNotification(
                `${translate('planning.assign_to_machine')} ${variables.Machine.Name}`
            );
            await queryClient.invalidateQueries(['planningInfo']);
            setSelectProductOrder(null);
            setShowMachine([]);
            setShowMachineSelect(false);
        },
    });

    const getQueueDetailData = (machines, productOrder) => {
        let onQueue = false;
        let inMachine = [];
        for (let i = 0; i < machines.length; i++) {
            const machine = machines[i];
            for (let j = 0; j < machine.MachineQueues.length; j++) {
                if (machine.MachineQueues[j].ProductOrderId === productOrder.Id) {
                    onQueue = true;
                    inMachine.push(machine);
                }
            }
        }

        return {
            isQueue: onQueue,
            machines: inMachine,
        };
    };
    const onProductOrderSelect = (productOrderId, productionProcessId) => {
        let orderDetails = {
            ProductOrderId: productOrderId,
            ProductionProcessId: productionProcessId,
        };

        setSelectProductOrder(orderDetails);
        setShowMachine(
            planningInfo.AllMachine.filter((machine) => {
                const allProcessId = machine.ProductionProcesses.map(
                    (productionProcess) => productionProcess.Id
                );
                const orderInMachine = machine.MachineQueues.find(
                    (queue) => queue.ProductOrderId === orderDetails.ProductOrderId
                );
                if (orderInMachine !== undefined) {
                    return null;
                } else {
                    if (allProcessId.includes(orderDetails.ProductionProcessId)) {
                        return machine;
                    } else {
                        return null;
                    }
                }
            })
        );
        setShowMachineSelect(!showMachineSelect);
    };

    const getQueueData = () => {
        const queueData = [];
        planningInfo.AllActiveProductOrder.forEach((productOrder) => {
            return productOrder.ProductSellHistories.forEach((sellHistory) => {
                return sellHistory.Product.ProductHasProductionProcesses.forEach(
                    (productProcess) => {
                        let queueDetail = getQueueDetailData(planningInfo.AllMachine, productOrder);
                        queueData.push({
                            ProductOrder: productOrder,
                            ProductionProcess: productProcess.ProductionProcess,
                            QueueDetail: queueDetail,
                        });
                    }
                );
            });
        });

        return queueData;
    };
    const onMachineSelect = async (machine) => {
        await assignPlanToMachineMutation.mutateAsync({
            ProductOrderId: selectProductOrder.ProductOrderId,
            Machine: machine,
        });
    };
    const getPlanningTable = (tableData) => {
        return (
            <ProjectPaper>
                <ReactTable
                    search
                    defaultPageSize={25}
                    data={tableData.map((detail) => {
                        return {
                            id: detail.ProductOrder.Id,
                            name: detail.ProductOrder.Name,
                            customer: detail.ProductOrder.Customer.Name,
                            process: detail.ProductionProcess.Name,
                            amount: detail.ProductOrder.Amount,
                            amount_in: {
                                inAmount: detail.ProductOrder.Piles.reduce((accumulator, pile) => {
                                    return accumulator + pile.GoodAmount;
                                }, 0),
                                pileAmount: detail.ProductOrder.Piles.length,
                            },
                            status: detail.QueueDetail.isQueue,
                            actions: {
                                ProductOrderId: detail.ProductOrder.Id,
                                ProductionProcessId: detail.ProductionProcess.Id,
                            },
                        };
                    })}
                    header={[
                        {
                            header: 'Actions',
                            accessorKey: 'actions',
                            cell: (info) => (
                                <>
                                    <IconButton
                                        component={RouterLink}
                                        to={paths.production.product_order_detail(info.getValue().ProductOrderId)}
                                    >
                                        <Iconify icon={OPEN_ICON}/>
                                    </IconButton>
                                    <IconButton
                                        onClick={() =>
                                            onProductOrderSelect(
                                                info.getValue().ProductOrderId,
                                                info.getValue().ProductionProcessId
                                            )
                                        }
                                    >
                                        <Iconify icon={'carbon:add'} width={20} height={20}/>
                                    </IconButton>
                                </>
                            ),
                            size: 20,
                        },
                        {
                            header: translate('title.id'),
                            accessorKey: 'id',
                            size: 20,
                        },
                        {
                            header: translate('title.name'),
                            accessorKey: 'name',
                        },
                        {
                            header: translate('title.customer'),
                            accessorKey: 'customer',
                        },
                        {
                            header: translate('title.process'),
                            accessorKey: 'process',
                        },
                        {
                            header: translate('title.amount'),
                            accessorKey: 'amount',
                            size: 20,
                        },
                        {
                            header: translate('title.amount_in'),
                            accessorKey: 'amount_in',
                            cell: (info) =>
                                `${fNumber(info.getValue().inAmount)} (${fNumber(info.getValue().pileAmount)} ขา)`,
                        },
                        {
                            header: translate('title.status'),
                            accessorKey: 'status',
                            cell: (info) => (
                                <Label
                                    variant={theme === 'light' ? 'filled' : 'filled'}
                                    color={info.getValue() ? 'success' : 'error'}
                                    sx={{textTransform: 'capitalize'}}
                                >
                                    {info.getValue()
                                        ? translate('planning.already_planning')
                                        : translate('planning.not_planning')}
                                </Label>
                            ),
                        },
                    ]}
                />
            </ProjectPaper>
        );
    };
    const getMachineStatusLabel = (machineStatusId) => {
        let color = 'info';
        if (machineStatusId === 1) {
            color = 'info';
        }
        if (machineStatusId === 2) {
            color = 'error';
        }
        if (machineStatusId === 3) {
            color = 'warning';
        }
        if (machineStatusId === 4) {
            color = 'error';
        }
        if (machineStatusId === 5) {
            color = 'warning';
        }

        return (
            <Label
                variant={theme === 'light' ? 'filled' : 'filled'}
                color={color}
                sx={{textTransform: 'capitalize'}}
            >
                {translate(`status.machine_status_${machineStatusId}`)}
            </Label>
        );
    };
    const INFORMATION_TABS = [
        {
            value: 'not_planning',
            title: `${translate('planning.not_planning')} (${
                getQueueData().filter((po) => !po.QueueDetail.isQueue).length
            })`,
            icon: <Iconify icon={'eva:info-outline'} width={20} height={20}/>,
            component: getPlanningTable(getQueueData().filter((po) => !po.QueueDetail.isQueue)),
        },
        {
            value: 'planning',
            title: `${translate('planning.already_planning')} (${
                getQueueData().filter((po) => po.QueueDetail.isQueue).length
            })`,
            icon: <Iconify icon={'icon-park-outline:plan'} width={20} height={20}/>,
            component: getPlanningTable(getQueueData().filter((po) => po.QueueDetail.isQueue)),
        },
        {
            value: 'machine_list',
            title: translate('planning.machine_planning_list'),
            icon: <Iconify icon={'ant-design:ordered-list-outlined'} width={20} height={20}/>,
            component: (
                <ProjectPaper>
                    <ReactTable
                        search
                        defaultPageSize={25}
                        data={planningInfo.AllMachine.map((machine) => {
                            return {
                                name: machine.Name,
                                number_queue: machine.MachineQueues.length,
                                status: machine.MachineStatusId,
                                queue_detail: machine.MachineQueues,
                                actions: machine.Id,
                            };
                        })}
                        header={[
                            {
                                header: translate('title.name'),
                                accessorKey: 'name',
                                cell: (info) => {
                                    return info.getValue()
                                }
                            },
                            {
                                header: translate('planning.number_of_queue'),
                                accessorKey: 'number_queue',
                            },
                            {
                                header: translate('title.status'),
                                accessorKey: 'status',
                                cell: (info) => getMachineStatusLabel(info.getValue()),
                            },
                            {
                                header: translate('title.amount_in'),
                                accessorKey: 'queue_detail',
                                cell: (info) => {
                                    return info.getValue().length > 0 ? (
                                        `${translate('planning.running_queue')} : ${
                                            info.getValue().find((queue) => queue.QueueNumber === 1) !== undefined ? info.getValue().find((queue) => queue.QueueNumber === 1).ProductOrderId : 'ไม่ได้ระบุ'
                                        }`
                                    ) : (
                                        <Typography variant={'body2'} sx={{color: theme.palette.success.main}}>
                                            {translate('planning.free_of_queue')}
                                        </Typography>
                                    )
                                }
                            },
                            {
                                header: 'Actions',
                                accessorKey: 'actions',
                                cell: (info) => (
                                    <>
                                        <IconButton
                                            component={RouterLink}
                                            href={paths.planning.machine_queue_detail(info.getValue())}
                                        >
                                            <Iconify icon={OPEN_ICON}/>
                                        </IconButton>
                                    </>
                                ),
                            },
                        ]}
                    />
                </ProjectPaper>
            ),
        },
    ];
    return (
        <ProjectPageContainer
            menu_title={translate('planning.production_planning')}
            breadcrumbs={[
                {name: translate('planning.title')},
                {name: translate('planning.production_planning')},
            ]}
        >
            {showMachineSelect ? (
                <Grid container spacing={3}>
                    {showMachine.map((machine, index) => (
                        <Grid item key={index} xs={12} sm={12} md={3}>
                            <Slide direction="up" in={showMachineSelect} mountOnEnter unmountOnExit>
                                <Paper>
                                    <Card>
                                        <CardActionArea
                                            onClick={() => onMachineSelect(machine)}
                                            disabled={machine.MachineStatusId === 3}
                                        >
                                            <CardHeader title={machine.Name}/>
                                            <CardContent>
                                                <Typography
                                                    variant={'body2'}>{`${translate('planning.number_of_queue')} ${
                                                    machine.MachineQueues.length
                                                }`}</Typography>
                                                {machine.MachineStatusId === 2 || machine.MachineStatusId === 3 ? (
                                                    <Typography variant={'h4'} sx={{color: theme.palette.error.main}}>
                                                        {translate('status.machine_status_2')}
                                                    </Typography>
                                                ) : machine.MachineQueues.length > 0 ? (
                                                    <Typography
                                                        variant={'h5'}
                                                        sx={{color: theme.palette.info.main}}
                                                    >{`${translate('planning.running_queue')} : ${
                                                        machine.MachineQueues.find((queue) => queue.QueueNumber === 1) !== undefined ? machine.MachineQueues.find((queue) => queue.QueueNumber === 1).ProductOrderId : 'ไม่ได้ระบุ'
                                                    }`}</Typography>
                                                ) : (
                                                    <Typography variant={'h4'} sx={{color: theme.palette.success.main}}>
                                                        {translate('planning.free_of_queue')}
                                                    </Typography>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Paper>
                            </Slide>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <>
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={currentTab}
                        onChange={onChangeTab}
                    >
                        {INFORMATION_TABS.map((tab, index) => (
                            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value}/>
                        ))}
                    </Tabs>
                    <Box sx={{mb: 3}}/>
                    {INFORMATION_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && tab.component;
                    })}
                </>
            )}
        </ProjectPageContainer>
    );
};
export default ProductionPlanningPage;
