import { useMemo } from 'react';
// routes
import { paths } from './paths';
// locales
import { useLocales } from '../locales';
// components
import Iconify from '../components/minimal/iconify';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
  HumanResourceUserTypeId,
  LogisticUserTypeId,
  MaintenanceControlUserTypeId,
  MaintenanceUserTypeId,
  ManagementUserTypeId,
  ProcurementUserTypeId,
  ProductionControlUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
  StoreUserTypeId,
  WarehouseControlUserTypeId,
} from '../utilities/Constants';
import { useAuth } from '../auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  // OR
  <Iconify icon={name} />
);

const ICONS = {
  home: icon('ic:baseline-home'),
  login: icon('ic:baseline-login'),
  dashboard: icon('ic:baseline-dashboard'),
  accounting: icon('mdi:wallet-outline'),
  submit_production_record: icon('mdi:send'),
  create_delivery_bill: icon('mdi:truck-delivery'),
  accept_item: icon('icon-park-outline:delivery'),
  create_maintenance_job: icon('wpf:maintenance'),
  add_icon: icon('eva:plus-fill'),
  quick_request: icon('carbon:delivery-parcel'),
  management: icon('eva:briefcase-outline'),
  sales: icon('ic:baseline-attach-money'),
  finance: icon('grommet-icons:money'),
  production: icon('cil:factory'),
  product: icon('fluent-mdl2:product'),
  maintenance: icon('ant-design:setting-outlined'),
  planning: icon('ic:baseline-schedule'),
  qa: icon('ic:outline-high-quality'),
  logistic: icon('eva:car-outline'),
  general_warehouse: icon('akar-icons:shipping-box-01'),
  warehouse: icon('ic:outline-warehouse'),
  procurement: icon('ant-design:shopping-cart-outlined'),
  hr: icon('ant-design:user-outlined'),
  it: icon('material-symbols:computer-outline'),
  admin: icon('ep:setting'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { translate } = useLocales();
  const { user } = useAuth();

  let mainMenu = useMemo(() => {
    return [
      {
        subheader: translate('factory'),
        items: [
          {
            title: translate('login'),
            path: paths.landing,
            icon: ICONS.login,
          },
          {
            title: translate('production.submit_production_record'),
            path: paths.production.submit_production_record,
            icon: ICONS.submit_production_record,
          },
          {
            title: translate('logistic.create_delivery_bill'),
            path: paths.logistic.create_delivery_bill,
            icon: ICONS.create_delivery_bill,
          },
          {
            title: translate('warehouse.accept_item'),
            path: paths.warehouse.accept_items,
            icon: ICONS.accept_item,
          },
          {
            title: translate('maintenance.create_maintenance_job'),
            path: paths.maintenance.create_job,
            icon: ICONS.create_maintenance_job,
          },
          {
            title: translate('title.quick_request'),
            path: paths.general_warehouse.quick_request,
            icon: ICONS.quick_request,
          },
          {
            path: paths.logistic.car_time_stamp,
            title: 'จัดส่งบันทึกเข้า - ออก',
            icon: ICONS.create_delivery_bill,
          },
        ],
      },
    ];
  }, [translate]);
  if (user !== null) {
    mainMenu = [
      {
        subheader: translate('factory'),
        items: [
          {
            title: translate('dashboard'),
            path: paths.dashboard.main,
            icon: ICONS.dashboard,
          },
          {
            title: translate('management.title'),
            path: paths.management.root,
            icon: ICONS.management,
            roles: [ManagementUserTypeId],
            children: [
              {
                title: translate('dashboard'),
                path: paths.management.dashboard,
                roles: [ManagementUserTypeId],
              },
              {
                title: translate('management.approve_special_price_title'),
                path: paths.management.special_discount_list,
                roles: [ManagementUserTypeId],
              },
            ],
          },
          {
            title: translate('sales.title'),
            path: paths.sales.root,
            icon: ICONS.sales,
            roles: [SalesUserTypeId, AccountingUserTypeId, AdminUserTypeId],
            children: [
              {
                path: paths.sales.dashboard,
                title: translate('dashboard'),
              },
              {
                path: paths.sales.customer_list,
                title: translate('sales.customer_list'),
              },
              {
                path: paths.sales.create_product_order,
                title: translate('production.create_new_product_order'),
              },
              {
                path: paths.sales.create_order_from_logistic,
                title: translate('sales.create_order_from_logistic'),
              },
              {
                path: paths.sales.create_product_order_quotation,
                title: translate('sales.create_quotation_title'),
              },
              {
                path: paths.sales.active_quotation_list,
                title: translate('sales.wait_quotation_list'),
              },
              {
                path: paths.sales.print_customer_report,
                title: translate('sales.print_customer_report_page_title'),
              },
              {
                path: paths.sales.print_sales_value_report,
                title: translate('sales.print_sales_value_report_page_title'),
              },
              {
                path: paths.sales.create_additional_cost,
                title: translate('sales.create_additional_service_cost'),
              },
              {
                path: paths.sales.search,
                title: translate('search_page'),
              },
            ],
          },
          {
            title: translate('accounting.title'),
            path: paths.accounting.root,
            icon: ICONS.accounting,
            roles: [SalesUserTypeId, AccountingUserTypeId, AdminUserTypeId],
            children: [
              {
                path: paths.accounting.create_billing_note,
                title: translate('accounting.create_billing_note_title'),
                roles: [AccountingUserTypeId],
              },
              {
                path: paths.accounting.create_customer,
                title: translate('accounting.add_new_customer'),
                roles: [AccountingUserTypeId, AdminUserTypeId],
              },
              {
                path: paths.accounting.search,
                title: translate('accounting.search_bill'),
              },
              {
                path: paths.accounting.create_claim_record,
                title: translate('accounting.create_claim_record'),
                roles: [AccountingUserTypeId],
              },
              {
                path: paths.accounting.print_delivery_bill_report,
                title: translate('accounting.print_delivery_summary_report_page_title'),
              },
              {
                path: paths.accounting.print_product_sell_report,
                title: translate('accounting.print_product_sell_summary_report_page_title'),
              },
              {
                path: paths.accounting.archive_customer,
                title: translate('accounting.disable_customer'),
                roles: [AccountingUserTypeId],
              },
            ],
          },
          {
            title: translate('finance.title'),
            path: paths.finance.root,
            icon: ICONS.finance,
            roles: [FinanceUserTypeId, AccountingUserTypeId],
            children: [
              {
                path: paths.finance.cash_delivery_bill_list,
                title: translate('finance.cash_delivery_bill_list'),
              },
              {
                path: paths.finance.search,
                title: translate('button.search'),
              },
              {
                path: paths.finance.print_cash_delivery_bill_summary_report,
                title: translate('finance.print_cash_delivery_bill_summary_report_page_title'),
              },
            ],
          },
          {
            title: translate('production.title'),
            path: paths.production.root,
            icon: ICONS.production,
            roles: [
              SalesUserTypeId,
              AccountingUserTypeId,
              AdminUserTypeId,
              QualityAssuranceUserTypeId,
              LogisticUserTypeId,
              HumanResourceUserTypeId,
              ProductionControlUserTypeId,
            ],
            children: [
              {
                path: paths.production.dashboard,
                title: translate('dashboard'),
                roles: [
                  QualityAssuranceUserTypeId,
                  ProductionControlUserTypeId,
                  SalesUserTypeId,
                  HumanResourceUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  SecretaryUserTypeId,
                  LogisticUserTypeId,
                ],
              },
              {
                path: paths.production.employee_dashboard,
                title: translate('production.employee_dashboard'),
                roles: [
                  AccountingUserTypeId,
                  ProductionControlUserTypeId,
                  HumanResourceUserTypeId,
                  AdminUserTypeId,
                  SecretaryUserTypeId,
                ],
              },
              {
                path: paths.production.employee_performance,
                title: translate('production.employee_performance'),
                roles: [
                  ProductionControlUserTypeId,
                  HumanResourceUserTypeId,
                  AdminUserTypeId,
                  SecretaryUserTypeId,
                ],
              },
              {
                path: paths.production.product_order_list,
                title: translate('production.product_order_list'),
                roles: [
                  AccountingUserTypeId,
                  QualityAssuranceUserTypeId,
                  ProductionControlUserTypeId,
                  SalesUserTypeId,
                  HumanResourceUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  SecretaryUserTypeId,
                  LogisticUserTypeId,
                ],
              },
              {
                path: paths.production.submit_production_record,
                title: translate('production.submit_production_record'),
              },
              {
                path: paths.production.create_production_process,
                title: translate('production.create_production_process_title'),
                roles: [AccountingUserTypeId, AdminUserTypeId],
              },
              {
                path: paths.production.machine_production_report,
                title: translate('production.print_machine_production_report_title'),
                roles: [
                  AccountingUserTypeId,
                  ProductionControlUserTypeId,
                  SalesUserTypeId,
                  HumanResourceUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  SecretaryUserTypeId,
                  LogisticUserTypeId,
                  QualityAssuranceUserTypeId,
                ],
              },
              {
                path: paths.production.search,
                title: translate('button.search'),
              },
            ],
          },
          {
            title: translate('product.title'),
            path: paths.product.root,
            icon: ICONS.product,
            roles: [
              AdminUserTypeId,
              SalesUserTypeId,
              ProductionControlUserTypeId,
              QualityAssuranceUserTypeId,
              AccountingUserTypeId,
            ],
            children: [
              {
                path: paths.product.product_list,
                title: translate('product.list'),
              },
              {
                path: paths.product.create_product,
                title: translate('product.create_new'),
                roles: [AdminUserTypeId, AccountingUserTypeId],
              },
              {
                path: paths.product.create_material,
                title: translate('product.create_material'),
                roles: [AdminUserTypeId, AccountingUserTypeId],
              },
              {
                path: paths.product.product_bom_list,
                title: translate('product.bom_list'),
                roles: [AdminUserTypeId, AccountingUserTypeId],
              },
              {
                path: paths.product.create_bom,
                title: translate('product.create_bom'),
                roles: [AdminUserTypeId, AccountingUserTypeId],
              },
            ],
          },
          {
            title: translate('maintenance.title'),
            path: paths.maintenance.root,
            icon: ICONS.maintenance,
            children: [
              {
                path: paths.maintenance.dashboard,
                title: translate('dashboard'),
                roles: [
                  HumanResourceUserTypeId,
                  MaintenanceControlUserTypeId,
                  MaintenanceUserTypeId,
                  ProductionControlUserTypeId,
                  AdminUserTypeId,
                ],
              },
              {
                path: paths.maintenance.machine_dashboard,
                title: translate('maintenance.machine_dashboard'),
                roles: [
                  HumanResourceUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProductionControlUserTypeId,
                  AdminUserTypeId,
                ],
              },
              {
                path: paths.maintenance.personal_dashboard,
                title: translate('maintenance.assign_maintenance_jobs'),
                roles: [
                  HumanResourceUserTypeId,
                  MaintenanceControlUserTypeId,
                  MaintenanceUserTypeId,
                  ProductionControlUserTypeId,
                  AdminUserTypeId,
                ],
              },
              {
                path: paths.maintenance.job_list,
                title: translate('maintenance.maintenance_job_list'),
                roles: [
                  HumanResourceUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProductionControlUserTypeId,
                  AdminUserTypeId,
                ],
              },
              {
                path: paths.maintenance.create_job,
                title: translate('maintenance.create_maintenance_job'),
              },
              {
                path: paths.maintenance.print_job_report,
                title: translate('maintenance.print_maintenance_job_report_page_title'),
                roles: [
                  HumanResourceUserTypeId,
                  MaintenanceControlUserTypeId,
                  MaintenanceUserTypeId,
                  ProductionControlUserTypeId,
                  AdminUserTypeId,
                ],
              },
              {
                path: paths.maintenance.search,
                title: translate('button.search'),
                roles: [
                  HumanResourceUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProductionControlUserTypeId,
                  AdminUserTypeId,
                ],
              },
            ],
          },
          {
            title: translate('planning.title'),
            path: paths.planning.root,
            icon: ICONS.planning,
            roles: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
            children: [
              {
                path: paths.planning.dashboard,
                title: translate('dashboard'),
              },
              {
                path: paths.planning.customer_list,
                title: translate('sales.customer_list'),
              },
              {
                path: paths.planning.production_planning,
                title: translate('planning.production_planning'),
              },
              {
                path: paths.planning.production_planning_old,
                title: translate('planning.production_planning_old'),
              },
              {
                path: paths.planning.approve_exceed_amount,
                title: translate('planning.approve_exceed_amount'),
              },
              {
                path: paths.planning.add_quality_process_page,
                title: translate('planning.add_quality_process'),
              },
              {
                path: paths.sales.create_product_order,
                title: translate('production.create_new_product_order'),
              },
              {
                path: paths.sales.print_customer_report,
                title: translate('sales.print_customer_report_page_title'),
              },
              {
                path: paths.planning.create_rework_order,
                title: translate('planning.create_rework_order'),
              },
              {
                path: paths.planning.edit_pile_reference,
                title: translate('planning.edit_pile_reference'),
              },
              {
                path: paths.planning.print_report_planning,
                title: translate('planning.print_report_planning_title'),
              },
              {
                path: paths.planning.edit_pile_good_amount_page,
                title: translate('admin.edit_pile_good_amount_page_title'),
              },
              {
                path: paths.planning.edit_size,
                title: translate('admin.edit_size'),
              },
              {
                path: paths.planning.report_product_order_problem,
                title: translate('button.add_problem_flag'),
              },
            ],
          },
          {
            title: translate('qa.title'),
            path: paths.qa.root,
            icon: ICONS.qa,
            roles: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
            children: [
              {
                path: paths.qa.dashboard,
                title: translate('qa.quality_assurance_dashboard_title'),
              },
              {
                path: paths.qa.quality_dashboard,
                title: translate('qa.quality_assurance_check_dashboard_title'),
              },
              {
                path: paths.qa.all_active_problem,
                title: translate('qa.all_active_problem_title'),
              },
              {
                path: paths.qa.approve_quality,
                title: translate('qa.approve_pile_quality_title'),
              },
              {
                path: paths.qa.approve_quality_process,
                title: translate('qa.approve_pile_quality_process_title'),
              },
              {
                path: paths.qa.hold_pile,
                title: translate('qa.hold_pile_title'),
              },
              {
                path: paths.qa.un_hold_pile,
                title: translate('qa.un_hold_pile_title'),
              },
              {
                path: paths.qa.adjust_pile_amount,
                title: translate('admin.edit_pile_good_amount_page_title'),
              },
              {
                path: paths.qa.report_product_order_problem,
                title: translate('button.add_problem_flag'),
              },
            ],
          },
          {
            title: translate('logistic.title'),
            path: paths.logistic.root,
            icon: ICONS.logistic,
            children: [
              {
                path: paths.logistic.create_delivery_bill,
                title: translate('logistic.create_delivery_bill'),
              },
              {
                path: paths.logistic.create_car,
                title: translate('logistic.add_car'),
              },
              {
                path: paths.logistic.car_time_stamp,
                title: 'จัดส่งบันทึกเข้า - ออก',
              },
            ],
          },
          {
            title: translate('warehouse.parts'),
            path: paths.general_warehouse.root,
            icon: ICONS.general_warehouse,
            children: [
              {
                path: paths.general_warehouse.store,
                title: translate('warehouse.store'),
              },
              {
                path: paths.general_warehouse.quick_request,
                title: translate('title.quick_request'),
              },
              {
                path: paths.general_warehouse.add_items,
                title: translate('warehouse.accept_inventory'),
                roles: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.release_items,
                title: translate('warehouse.request_list'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.accept_items,
                title: translate('warehouse.accept_item'),
              },
              {
                path: paths.general_warehouse.return_items,
                title: translate('warehouse.return_item'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.move_items,
                title: translate('warehouse.move_shelf'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.manage_safety_stock,
                title: translate('warehouse.safety_stock_management_title'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.create_master,
                title: translate('warehouse.create_item_template_title'),
                roles: [
                  SecretaryUserTypeId,
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.create_unit,
                title: translate('warehouse.create_new_unit_title'),
                roles: [
                  SecretaryUserTypeId,
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.create_inventory,
                title: translate('warehouse.create_new_store'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.create_shelf,
                title: translate('warehouse.create_new_shelf'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.print_warehouse_data,
                title: translate('warehouse.print_warehouse_data_page_title'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.print_stock_transaction,
                title: translate('warehouse.print_item_transaction_data_page_title'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
              {
                path: paths.general_warehouse.search,
                title: translate('button.search'),
                roles: [
                  WarehouseControlUserTypeId,
                  ManagementUserTypeId,
                  StoreUserTypeId,
                  AdminUserTypeId,
                  MaintenanceControlUserTypeId,
                  ProcurementUserTypeId,
                  HumanResourceUserTypeId,
                ],
              },
            ],
          },
          {
            title: translate('warehouse.title'),
            path: paths.warehouse.root,
            icon: ICONS.warehouse,
            children: [
              {
                path: paths.warehouse.dashboard,
                title: translate('dashboard'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.store,
                title: translate('warehouse.request_item'),
              },
              {
                path: paths.warehouse.add_items,
                title: translate('warehouse.accept_inventory'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.create_master,
                title: translate('warehouse.create_item_master_title'),
                allowUserTypeId: [
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                  ProcurementUserTypeId,
                ],
              },
              {
                path: paths.warehouse.release_items,
                title: translate('warehouse.request_list'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.accept_items,
                title: translate('warehouse.accept_item'),
              },
              {
                path: paths.warehouse.return_items,
                title: translate('warehouse.return_item'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.create_inventory,
                title: translate('warehouse.create_new_store'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.create_shelf,
                title: translate('warehouse.create_new_shelf'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.move_items,
                title: translate('warehouse.move_shelf'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.admin_create_lot,
                title: translate('warehouse.add_lot'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.return_film,
                title: translate('warehouse.create_return_film_lot_title'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.manage_safety_stock,
                title: translate('warehouse.safety_stock_management_title'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.create_unit,
                title: translate('warehouse.create_new_unit'),
                allowUserTypeId: [ManagementUserTypeId],
              },
              {
                path: paths.warehouse.upload_item_price,
                title: translate('warehouse.upload_item_price'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                  ProcurementUserTypeId,
                ],
              },
              {
                path: paths.warehouse.print_warehouse_data,
                title: translate('warehouse.print_warehouse_data_page_title'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.print_stock_transaction,
                title: translate('warehouse.print_item_transaction_data_page_title'),
                allowUserTypeId: [
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                ],
              },
              {
                path: paths.warehouse.search,
                title: translate('button.search'),
              },
            ],
          },
          {
            title: translate('procurement.title'),
            path: paths.procurement.root,
            icon: ICONS.procurement,
            children: [
              {
                path: paths.procurement.search,
                title: translate('button.search'),
                allowUserTypeId: [
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  StoreUserTypeId,
                  WarehouseControlUserTypeId,
                ],
              },
              {
                path: paths.procurement.create_purchase_requisition,
                title: translate('procurement.request_buy_material_title'),
              },
              {
                path: paths.procurement.create_parts_purchase_requisition,
                title: translate('procurement.request_buy_parts_title'),
              },
              {
                path: paths.procurement.pr_list,
                title: translate('procurement.purchase_requisition_list_title'),
                allowUserTypeId: [
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.parts_pr_list,
                title: translate('procurement.part_purchase_requisition_list_title'),
                allowUserTypeId: [
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.po_list,
                title: translate('procurement.purchase_order_list_title'),
                allowUserTypeId: [
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.parts_po_list,
                title: translate('procurement.part_purchase_order_list_title'),
                allowUserTypeId: [
                  SecretaryUserTypeId,
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.create_po,
                title: translate('procurement.create_po_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.create_parts_po,
                title: translate('procurement.create_po_parts_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                ],
              },
              {
                path: paths.procurement.create_supplier,
                title: translate('procurement.create_supplier_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.supplier_list,
                title: translate('procurement.supplier_material_list_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.supplier_parts_list,
                title: translate('procurement.supplier_parts_list_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.spo_list,
                title: translate('procurement.procurement_service_purchase_order_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
              {
                path: paths.procurement.print_supplier_report,
                title: translate('procurement.print_supplier_report_page_title'),
                allowUserTypeId: [
                  AdminUserTypeId,
                  ProcurementUserTypeId,
                  WarehouseControlUserTypeId,
                  StoreUserTypeId,
                ],
              },
            ],
          },
          {
            title: translate('hr.title'),
            path: paths.hr.root,
            icon: ICONS.hr,
            children: [
              {
                path: paths.hr.employee_list,
                title: translate('hr.employee_list'),
              },
              {
                path: paths.hr.add_employee,
                title: translate('hr.add_employee'),
              },
              {
                path: paths.hr.remove_employee,
                title: translate('hr.remove_employee'),
              },
              {
                path: paths.hr.add_penalty_record,
                title: translate('hr.add_penalty_record'),
              },
              {
                path: paths.hr.add_employee_record,
                title: translate('hr.add_employee_record'),
              },
            ],
          },
          {
            title: translate('it.title'),
            path: paths.it.root,
            icon: ICONS.it,
            children: [
              {
                path: paths.it.create_it_job,
                title: translate('it.create_job'),
              },
              {
                path: paths.it.it_job_list,
                title: translate('it.it_job_list'),
              },
              {
                path: paths.it.create_it_asset,
                title: translate('it.create_asset'),
              },
              {
                path: paths.it.create_it_asset_category,
                title: translate('it.create_asset_category'),
              },
              {
                path: paths.it.create_user,
                title: translate('it.create_user'),
              },
              {
                path: paths.it.change_user_password,
                title: translate('admin.change_new_password_title'),
              },
            ],
          },
          {
            title: translate('admin.title'),
            path: paths.admin.root,
            icon: ICONS.admin,
            roles: [AdminUserTypeId, ManagementUserTypeId],
            children: [
              {
                path: paths.admin.edit_pile_amount,
                title: translate('admin.edit_pile_amount_title'),
              },
              {
                path: paths.admin.edit_product_order_amount,
                title: translate('admin.edit_product_order_amount_title'),
              },
              {
                path: paths.admin.edit_size,
                title: translate('admin.edit_size'),
              },
              {
                path: paths.admin.edit_pile_good_amount,
                title: translate('admin.edit_pile_good_amount_page_title'),
              },
              {
                path: paths.admin.edit_price,
                title: translate('admin.edit_sell_price_title'),
                roles: [ManagementUserTypeId],
              },
              {
                path: paths.admin.edit_po_price,
                title: translate('admin.edit_sell_price_po_title'),
                allowUserTypeId: [ManagementUserTypeId],
              },
              {
                path: paths.admin.change_price_to_bundle,
                title: translate('admin.edit_price_to_bundle_title'),
                allowUserTypeId: [ManagementUserTypeId],
              },
            ],
          },
        ],
      },
    ];
  }

  return useMemo(() => {
    return mainMenu;
  }, [mainMenu]);
}
