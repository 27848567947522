import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { changePileAmount } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useForm } from 'react-hook-form';
import useAuth from '../../../auth/hooks/useAuth';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { useMutation } from '@tanstack/react-query';

export default function ChangePileAmountPage() {
  const { translate } = useLocales();
  const { user } = useAuth();

  const changePileAmountMutation = useMutation({
    mutationFn: changePileAmount,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const changeAmount = async (values) => {
    await changePileAmountMutation.mutateAsync({
      PileId: values.PileId,
      Amount: parseInt(values.Amount),
      EditEmployeeId: user.EmployeeId,
    });
  };

  const changePileAmountSchema = Yup.object().shape({
    PileId: Yup.number().required(translate('error.detail_require')),
    Amount: Yup.number().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(changePileAmountSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_pile_amount_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.edit_pile_amount_title') },
      ]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(changeAmount)}>
          <Stack spacing={3}>
            <RHFTextField name={'PileId'} label={translate('production.pile_id')} />
            <RHFTextField name={'Amount'} label={translate('title.amount')} />
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.save')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}
