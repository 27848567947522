import CreateSupplierPage from '../../components/pages/ProcurementServiceModule/CreateSupplierPage';
import SupplierListPage from '../../components/pages/ProcurementServiceModule/SupplierListPage';
import SupplierDetailPage from '../../components/pages/ProcurementServiceModule/SupplierDetailPage';
import CreateSupplierSellItemPage from '../../components/pages/ProcurementServiceModule/CreateSupplierSellItemPage';
import SearchItemMasterToCreateSellContainerPage from '../../components/pages/ProcurementServiceModule/SearchItemMasterToCreateSellContainerPage';
import EditSupplierSellItemDetailPage from '../../components/pages/ProcurementServiceModule/EditSupplierSellItemDetailPage';
import PurchaseRequisitionListPage from '../../components/pages/ProcurementServiceModule/PurchaseRequisitionListPage';
import PurchaseOrderListPage from '../../components/pages/ProcurementServiceModule/PurchaseOrderListPage';
import SearchProcurementPage from '../../components/pages/ProcurementServiceModule/SearchProcurementPage';
import CreatePurchaseRequisitionPage from '../../components/pages/ProcurementServiceModule/CreatePurchaseRequisitionPage';
import ProcurementStorePage from '../../components/pages/ProcurementServiceModule/ProcurementStorePage';
import PurchaseRequisitionDetailPage from '../../components/pages/ProcurementServiceModule/PurchaseRequisitionDetailPage';
import CreatePurchaseOrderPage from '../../components/pages/ProcurementServiceModule/CreatePurchaseOrderPage';
import SearchSupplierSellItemPage from '../../components/pages/ProcurementServiceModule/SearchSupplierSellItemPage';
import CheckoutPurchaseOrderPage from '../../components/pages/ProcurementServiceModule/CheckoutPurchaseOrderPage';
import CheckoutSupplierPurchaseOrderPage from '../../components/pages/ProcurementServiceModule/CheckoutSupplierPurchaseOrderPage';
import PurchaseOrderPageDetail from '../../components/pages/ProcurementServiceModule/PurchaseOrderDetailPage';
import ServicePurchaseOrderListPage from '../../components/pages/ProcurementServiceModule/ServicePurchaseOrderListPage';
import AcceptServicePurchaseOrderPage from '../../components/pages/ProcurementServiceModule/AcceptServicePurchaseOrderPage';
import ServicePurchaseOrderDetailPage from '../../components/pages/ProcurementServiceModule/ServicePurchaseOrderDetailPage';
import ProcurementPartStorePage from '../../components/pages/ProcurementServiceModule/ProcurementPartStorePage';
import CreatePartsPurchaseRequisitionPage from '../../components/pages/ProcurementServiceModule/CreatePartsPurchaseRequisitionPage';
import PartsPurchaseRequisitionDetailPage from '../../components/pages/ProcurementServiceModule/PartsPurchaseRequisitionDetailPage';
import CreatePartsPurchaseOrderPage from '../../components/pages/ProcurementServiceModule/CreatePartsPurchaseOrderPage';
import SearchItemTemplateToCreateSellItemTemplatePage from '../../components/pages/ProcurementServiceModule/SearchItemTemplateToCreateSellItemTemplatePage';
import CreateSupplierSellItemTemplatePage from '../../components/pages/ProcurementServiceModule/CreateSupplierSellItemTemplatePage';
import EditSupplierSellItemTemplatePage from '../../components/pages/ProcurementServiceModule/EditSupplierSellItemTemplatePage';
import CheckoutPurchaseOrderPartsPage from '../../components/pages/ProcurementServiceModule/CheckoutPurchaseOrderPartsPage';
import CheckoutSupplierPurchaseOrderPartsPage from '../../components/pages/ProcurementServiceModule/CheckoutSupplierPurchaseOrderPartsPage';
import PartsPurchaseOrderDetailPage from '../../components/pages/ProcurementServiceModule/PartsPurchaseOrderDetailPage';
import PartSupplierDetailPage from '../../components/pages/ProcurementServiceModule/PartSupplierDetailPage';
import {
  AdminUserTypeId,
  ProcurementUserTypeId,
  SecretaryUserTypeId,
  StoreUserTypeId,
  WarehouseControlUserTypeId,
} from '../../utilities/Constants';
import ProcurementSupplierReportPage from '../../components/pages/ProcurementServiceModule/ProcurementSupplierReportPage';
import PartsPurchaseRequisitionListPage from '../../components/pages/ProcurementServiceModule/PartsPurchaseRequisitionListPage';
import PartsPurchaseOrderListPage from '../../components/pages/ProcurementServiceModule/PartsPurchaseOrderListPage';
import SupplierPartsListPage from '../../components/pages/ProcurementServiceModule/SupplierPartsListPage';
import { paths } from '../paths';
import SearchSupplierSellItemTemplatePage from '../../components/pages/ProcurementServiceModule/SearchSupplierSellItemTemplatePage';

const procurementModuleRouteDetail = [
  {
    path: paths.procurement.create_supplier,
    component: <CreateSupplierPage />,
    isPrivate: false,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.supplier_list,
    component: <SupplierListPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.supplier_parts_list,
    component: <SupplierPartsListPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.part_supplier_detail(':id'),
    component: <PartSupplierDetailPage />,
    isPrivate: false,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.supplier_detail(':id'),
    component: <SupplierDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.create_supplier_sell_item,
    component: <CreateSupplierSellItemPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.create_supplier_sell_item_template,
    component: <CreateSupplierSellItemTemplatePage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.search_item_master_to_create_sell_container(':supplier'),
    component: <SearchItemMasterToCreateSellContainerPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.search_item_template_to_create_sell_template(':supplier'),
    component: <SearchItemTemplateToCreateSellItemTemplatePage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },

  {
    path: paths.procurement.edit_supplier_sell_item(':id'),
    component: <EditSupplierSellItemDetailPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.edit_supplier_sell_item_template(':id'),
    component: <EditSupplierSellItemTemplatePage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.pr_list,
    component: <PurchaseRequisitionListPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.po_list,
    component: <PurchaseOrderListPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.search,
    component: <SearchProcurementPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.create_purchase_requisition,
    component: <CreatePurchaseRequisitionPage />,
    isPrivate: false,
  },
  {
    path: paths.procurement.create_parts_purchase_requisition,
    component: <CreatePartsPurchaseRequisitionPage />,
    isPrivate: false,
  },
  {
    path: paths.procurement.store,
    component: <ProcurementStorePage />,
    isPrivate: true,
  },
  {
    path: paths.procurement.part_store,
    component: <ProcurementPartStorePage />,
    isPrivate: true,
    queryParameter: '/:id',
  },
  {
    path: paths.procurement.pr_detail(':id'),
    component: <PurchaseRequisitionDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.parts_pr_detail(':id'),
    component: <PartsPurchaseRequisitionDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.create_po,
    component: <CreatePurchaseOrderPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.create_parts_po,
    component: <CreatePartsPurchaseOrderPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  // {
  //   path: CreateItemMasterFromTempPageURL,
  //   component: <CreateItemMasterFromTempPage />,
  //   isPrivate: true,
  //   queryParameter: '/:id',
  //   allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  // },
  // {
  //   path: CreateItemTemplateFromTempPageURL,
  //   component: <CreateItemTemplateFromTempPage />,
  //   isPrivate: true,
  //   queryParameter: '/:id',
  //   allowUserTypeId: [
  //     AdminUserTypeId,
  //     ProcurementUserTypeId,
  //     WarehouseControlUserTypeId,
  //     StoreUserTypeId,
  //   ],
  // },
  {
    path: paths.procurement.search_supplier_sell_item(':id'),
    component: <SearchSupplierSellItemPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.search_supplier_sell_item_template(':id'),
    component: <SearchSupplierSellItemTemplatePage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.search_item_template_to_create_sell_template('/:supplier/:id'),
    component: <SearchItemTemplateToCreateSellItemTemplatePage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.check_out_po,
    component: <CheckoutPurchaseOrderPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.check_out_part_po,
    component: <CheckoutPurchaseOrderPartsPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.check_out_supplier_po(':id'),
    component: <CheckoutSupplierPurchaseOrderPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ProcurementUserTypeId, WarehouseControlUserTypeId],
  },
  {
    path: paths.procurement.check_out_part_supplier_po(':id'),
    component: <CheckoutSupplierPurchaseOrderPartsPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.po_detail(':id'),
    component: <PurchaseOrderPageDetail />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.parts_po_detail(':id'),
    component: <PartsPurchaseOrderDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.spo_list,
    component: <ServicePurchaseOrderListPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.accept_spo(':id'),
    component: <AcceptServicePurchaseOrderPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.service_po_detail(':id'),
    component: <ServicePurchaseOrderDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.print_supplier_report,
    component: <ProcurementSupplierReportPage />,
    isPrivate: true,
    allowUserTypeId: [
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.parts_pr_list,
    component: <PartsPurchaseRequisitionListPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
  {
    path: paths.procurement.parts_po_list,
    component: <PartsPurchaseOrderListPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
    ],
  },
];
export default procurementModuleRouteDetail;
