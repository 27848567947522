import React from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import {
  OpenNotificationThenReloadPage,
  OpenTranslateWarningConfirmNotification,
} from 'utilities/Helper/NotificationHelper';
import { createCustomer } from 'requests/ProductionServerHandler';
import CreateCustomerForm from 'components/forms/CreateCustomerForm';
import { useMutation } from '@tanstack/react-query';

function CreateCustomerPage() {
  const { translate } = useLocales();
  const createCustomerMutation = useMutation({
    mutationFn: createCustomer,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const onSubmit = async (value) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_create_customer',
      async () => {
        await createCustomerMutation.mutateAsync(value);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.add_new_customer')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.add_new_customer') },
      ]}
    >
      <CreateCustomerForm onFormSubmit={onSubmit} />
    </ProjectPageContainer>
  );
}

export default CreateCustomerPage;
