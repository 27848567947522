import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { acceptStockReport } from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import AcceptItemReceiptForm from '../../forms/AcceptItemReceiptForm';
import { useMutation } from '@tanstack/react-query';

const AcceptItemPage = () => {
  const { translate } = useLocales();

  const acceptStockReportMutation = useMutation({
    mutationFn: acceptStockReport,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const findReportWithId = async (id, employeeId, isTemp) => {
    await acceptStockReportMutation.mutateAsync({
      StockReportId: id,
      AcceptEmployeeId: employeeId,
      IsTemp: isTemp,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.accept_item')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.accept_item') },
      ]}
    >
      <AcceptItemReceiptForm handleFindReport={findReportWithId} />
    </ProjectPageContainer>
  );
};
export default AcceptItemPage;
