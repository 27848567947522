import React from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

GridDetailSkeleton.propsTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
function GridDetailSkeleton({ title, children }) {
  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GridDetailSkeleton;
