import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  createAdditionalCostBill,
  getProductOrderDetailById,
} from '../../../requests/ProductionServerHandler';
import { PrintMultiplePDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import {
  OpenErrorNotification,
  OpenNotificationThenCallback,
} from '../../../utilities/Helper/NotificationHelper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import CreateProductOrderAdditionalCostBillForm from '../../forms/CreateProductOrderAdditionalCostBillForm';
import { paths } from '../../../routes/paths';
import { ProductOrderScanner } from '../../barcode-reader';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLoading } from '../../loading';

export default function CreateAdditionalCostBillPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const [productOrderId, setProductOrderId] = useState(searchParams.get('productOrderId'));
  const navigate = useNavigate();

  const { data: productOrder, refetch } = useQuery({
    queryKey: ['getProductOrderDetailById', productOrderId],
    queryFn: () => getProductOrderDetailById(productOrderId),
    enabled: productOrderId !== undefined && productOrderId !== null,
    initialData: null,
  });

  const createProductOrderAdditionalCostBillMutation = useMutation({
    mutationFn: createAdditionalCostBill,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument(response.PDF, 'DeliveryNoteAdditionalCost.pdf');
      hideLoadingScreen();
      await OpenNotificationThenCallback(
        'Success',
        `คุณสร้างบิลค่าบริการเพิ่มเติมของเลขที่ ${response.Id.toString()}`,
        () => {
          navigate(paths.accounting.product_order_additional_cost_bill_detail(response.Id));
        }
      );
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const searchProductOrderById = async (productOrderId) => {
    setProductOrderId(productOrderId);
    setSearchParams({ productOrderId: productOrderId });
    await refetch();
  };

  const onSubmit = async (values) => {
    createProductOrderAdditionalCostBillMutation.mutate({
      ProductOrderId: productOrderId,
      SelectAdditionalCost: values.AdditionalCost.map((additionalCost) => {
        return {
          Id: additionalCost.AdditionalCostDetail.Id,
          Value: additionalCost.Value,
        };
      }),
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.create_additional_service_cost')}
      breadcrumbs={[
        { name: translate('production.title') },
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate(
            'title.id'
          )} : ${productOrderId}`,
          href: paths.production.product_order_detail(productOrderId),
        },
        { name: translate('sales.create_additional_service_cost') },
      ]}
    >
      {productOrder !== null ? (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('production.product_order_id')}
                value={productOrder?.Id}
              />
              <GridDetailText title={translate('title.name')} value={productOrder?.Name} />
              <GridDetailText
                title={translate('title.customer')}
                value={productOrder?.Customer.Name}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <CreateProductOrderAdditionalCostBillForm onFormSubmit={onSubmit} />
          </ProjectPaper>
        </Stack>
      ) : (
        <ProductOrderScanner onSearchCallBack={searchProductOrderById} />
      )}
    </ProjectPageContainer>
  );
}
