//Local Storage Module for Save Access token
const preloadState = {
    interfaceData: {
        isShowLoading: false,
        isShowError: false,
        errorText: "",
    },
    procurementData: {
        PurchaseOrderItemCart: [],
    },
    inventoryData: {
        PurchaseCart: [],
        RequestCart: [],
        selectItemMaintenanceId:null,
        selectItemProductOrderId:null
    },
    salesData:{
        currentState:0,
        selectCustomer:null,
        adjustPriceDetail:[],
        selectItems:[],
        selectProducts:[],
        createOrderDetail:null,
        searchParameter:null,
        searchPageNumber:0,
        searchCategory:1,
        searchItemPerPage:10,
        confirmSelectItems: false
    }
};

export const loadState = () => {
    try {
        //Get Save State From Local Storage

        let serializedState = JSON.parse(localStorage.getItem("state"));
        if (serializedState === null) {
            serializedState = {
                authData:
                    {
                        uid:null,
                        employeeCode:null,
                        name:null,
                        employeeId:null,
                        department:null,
                        userTypeId:null,
                    },
            }
        }

        return {...serializedState, ...preloadState};
    } catch (e) {
        return undefined;
    }
};

export const saveState = state => {
    //Save State To Local Storage
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("state", serializedState);
    } catch (e) {
        console.log("Error Save State");
    }
};

// ----------------------------------------------------------------------

export function localStorageAvailable() {
    try {
        const key = '__some_random_key_you_are_not_going_to_use__';
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}

export function localStorageGetItem(key, defaultValue = '') {
    const storageAvailable = localStorageAvailable();

    let value;

    if (storageAvailable) {
        value = localStorage.getItem(key) || defaultValue;
    }

    return value;
}

