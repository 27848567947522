import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  approveItemRequisitionId,
  cancelItemRequisitionId,
  createItemRequisition,
  getItemRequisitionDetailById,
  getItemRequisitionPDF,
} from '../WarehouseServerHandler';
import { useLoading } from '../../components/loading';
import {
  PrintMultiplePDFMakeDocument,
  PrintPDFMakeDocument,
} from '../../utilities/Helper/PrintHelper';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { useLocales } from '../../locales';
import { item_requisition_pdf_prefix } from '../../utilities/Strings/Prefix';

export const useItemRequisition = (id) => {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['item-requisition', id],
    queryFn: () => getItemRequisitionDetailById(id),
    initialData: null,
    enabled: id !== null,
  });

  const printReportMutation = useMutation({
    mutationFn: getItemRequisitionPDF,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(response, `${item_requisition_pdf_prefix}.pdf`);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const createMutation = useMutation({
    mutationFn: createItemRequisition,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await printReportMutation.mutateAsync(response.ItemRequisitionReportId);
      await OpenSuccessNotification(
        `${translate('warehouse.request_id')} : ${response.ItemRequisitionReportId}`
      );
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const approveItemRequisitionMutation = useMutation({
    mutationFn: approveItemRequisitionId,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await OpenSuccessNotification();
      await PrintMultiplePDFMakeDocument(
        [response.ReportPDF, response.ItemPDF],
        `stock_report.pdf`
      );
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const cancelMutation = useMutation({
    mutationFn: cancelItemRequisitionId,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await queryClient.invalidateQueries(['item-requisition', id]);
      await OpenSuccessNotification();
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  return {
    itemRequisition: data,
    createReport: createMutation,
    printReport: printReportMutation,
    approveItemRequisition: approveItemRequisitionMutation,
    cancel: cancelMutation,
  };
};
