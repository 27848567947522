import React from 'react';
import { useLocales } from 'locales';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsShowProductOrderDetail,
  selectShowProductOrder,
  toggleCloseProductOrderDetail,
} from '../../../redux/reducers/planning';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ProductOrderDetail from '../product_order/ProductOrderDetail';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';

function ProductOrderDetailModal() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const isShow = useSelector(selectIsShowProductOrderDetail);
  const productOrderDetail = useSelector(selectShowProductOrder);

  const handleDialogClose = async () => {
    await dispatch(toggleCloseProductOrderDetail());
  };

  return (
    <Dialog
      open={isShow && productOrderDetail !== null}
      onClose={handleDialogClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-po"
      aria-describedby="scroll-dialog-po-description"
    >
      <DialogTitle id="scroll-dialog-po">{translate('title.po_detail')}</DialogTitle>
      <DialogContent dividers>
        {productOrderDetail !== null && (
          <ProductOrderDetail productOrder={productOrderDetail} isShortMode={true} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} onClick={handleDialogClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProductOrderDetailModal;
