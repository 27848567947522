import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouterLink } from 'routes/components';
import {
  MaintenanceControlUserTypeId,
  MaintenanceJobStatus,
  ManagementUserTypeId,
  SecretaryUserTypeId,
} from '../../../utilities/Constants';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import useTabs from '../../../hooks/useTabs';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import useAuth from '../../../auth/hooks/useAuth';
import Button from '@mui/material/Button';
import { useResponsive } from '../../../hooks/useResponsive';
import MaintenanceJobDetail from '../../details/maintenance/MaintenanceJobDetail';
import SelectMaintenanceTechnicianForm from '../../forms/SelectMaintenanceTechnicianForm';
import { useMaintenanceJob } from '../../../requests/query/useMaintenanceJob';
import CompleteMaintenanceJobDetail from '../../details/maintenance/CompleteMaintenanceJobDetail';
import { paths } from '../../../routes/paths';
import MaintenanceJobPartDetail from '../../details/maintenance/MaintenanceJobPartDetail';
import MaintenanceJobOutSourceDetail from '../../details/maintenance/MaintenanceJobOutSourceDetail';
import MaintenanceJobEvaluateDetail from '../../details/maintenance/MaintenanceJobEvaluateDetail';

function MaintenanceJobDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const [isChangeSpecialist, setIsChangeSpecialist] = useState(false);
  const { currentTab, onChangeTab } = useTabs('detail');
  const { user } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const {
    jobDetail,
    jobServicePO,
    acceptJob,
    completeJob,
    changeTechnician,
    requestExternalProvider,
    archivedJob,
    changeJobStatus,
    holdJob,
  } = useMaintenanceJob(id);

  const handleHold = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_hold_job',
      async () => {
        await holdJob.mutateAsync({
          Id: jobDetail?.Id,
        });
      }
    );
  };
  const handleResume = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_resume_mt_job',
      async () => {
        await changeJobStatus.mutateAsync({
          Id: jobDetail?.Id,
          MaintenanceJobStatusId: MaintenanceJobStatus.WorkInProcess,
        });
      }
    );
  };
  const handleCancelJob = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_job',
      async () => {
        await archivedJob.mutateAsync({
          Id: jobDetail?.Id,
        });
      }
    );
  };
  const handleRequestExternalProvider = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_request_outsource_for_job',
      async () => {
        await requestExternalProvider.mutateAsync({
          MaintenanceJobId: jobDetail?.Id,
          Reason: `${translate('button.request_external_provider')} by ${user.Name} รหัสงาน ${
            jobDetail.Id
          }`,
          DeadlineDurations: 3,
        });
      }
    );
  };
  const handleToggleChangeSpecialist = async () => {
    setIsChangeSpecialist(true);
  };
  const handleChangeTechnician = async (technician) => {
    await changeTechnician.mutateAsync({
      MaintenanceJobId: jobDetail.Id,
      TechnicianId: technician.Id,
    });
    setIsChangeSpecialist(false);
  };
  const handleAcceptJob = async (values) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_assign_job',
      async () => {
        await acceptJob.mutateAsync({
          Id: jobDetail.Id,
          AssignEmployeeId: values.Employee.Id,
          MaintenanceJobTypeId: values.MaintenanceJobType.Id,
        });
      }
    );
  };
  const handleCompleteJob = async (values) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_complete_mt_job',
      async () => {
        await completeJob.mutateAsync({
          MaintenanceJobId: jobDetail.Id,
          Detail: values.Detail,
          FinishHourMeter: values.HourMeter,
          CompleteEmployeeId: user.EmployeeId,
        });
      }
    );
  };

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: (
        <MaintenanceJobDetail
          jobDetail={jobDetail}
          onAcceptJobCallback={handleAcceptJob}
          onCompleteJobCallback={handleCompleteJob}
        />
      ),
    },
    {
      value: 'complete_detail',
      title: translate('maintenance.maintenance_detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: <CompleteMaintenanceJobDetail jobDetail={jobDetail} />,
      disabled: [
        MaintenanceJobStatus.WaitForAccept,
        MaintenanceJobStatus.WorkInProcess,
        MaintenanceJobStatus.Delete,
      ].includes(jobDetail?.MaintenanceJobStatus.Id),
    },
    {
      value: 'material',
      title: translate('maintenance.parts'),
      icon: <Iconify icon={'eva:cube-outline'} width={20} height={20} />,
      component: <MaintenanceJobPartDetail jobDetail={jobDetail} />,
      disabled: [MaintenanceJobStatus.WaitForAccept].includes(jobDetail?.MaintenanceJobStatus.Id),
    },
    {
      value: 'outsource',
      title: translate('maintenance.outsource'),
      icon: <Iconify icon={'fa-solid:people-carry'} width={20} height={20} />,
      component: (
        <MaintenanceJobOutSourceDetail
          jobDetail={jobDetail}
          jobServicePO={jobServicePO}
          onRequestServiceCallback={handleRequestExternalProvider}
        />
      ),
      disabled: [MaintenanceJobStatus.WaitForAccept].includes(jobDetail?.MaintenanceJobStatus.Id),
    },
    {
      value: 'evaluation',
      title: translate('maintenance.evaluate_job'),
      icon: <Iconify icon={'bi:clipboard-check'} width={20} height={20} />,
      component: <MaintenanceJobEvaluateDetail jobDetail={jobDetail} />,
      disabled: ![MaintenanceJobStatus.Complete].includes(jobDetail?.MaintenanceJobStatus.Id),
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('maintenance.maintenance_job_detail')}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: translate('maintenance.maintenance_job_list'), href: paths.maintenance.job_list },
        {
          name: `${translate('maintenance.maintenance_job_detail')} ${translate(
            'title.id'
          )} : ${id}`,
        },
      ]}
      action={
        <Stack spacing={2} direction={isDesktop ? 'row' : 'column'}>
          <RoleBasedGuard
            roles={[ManagementUserTypeId, MaintenanceControlUserTypeId, SecretaryUserTypeId]}
          >
            {![
              MaintenanceJobStatus.Delete,
              MaintenanceJobStatus.Complete,
              MaintenanceJobStatus.WaitForEvaluation,
            ].includes(jobDetail?.MaintenanceJobStatus.Id) && (
              <Button
                variant="contained"
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={handleCancelJob}
              >
                {translate('button.cancel')}
              </Button>
            )}
            {![
              MaintenanceJobStatus.WaitForAccept,
              MaintenanceJobStatus.WaitForEvaluation,
              MaintenanceJobStatus.Hold,
              MaintenanceJobStatus.WaitForSupplier,
              MaintenanceJobStatus.WaitForParts,
              MaintenanceJobStatus.Complete,
              MaintenanceJobStatus.Delete,
            ].includes(jobDetail?.MaintenanceJobStatus.Id) && (
              <>
                <Button
                  variant="contained"
                  color={'warning'}
                  startIcon={<Iconify icon={'tabler:status-change'} />}
                  onClick={handleToggleChangeSpecialist}
                >
                  {translate('maintenance.change_technician')}
                </Button>
                <Button
                  variant="contained"
                  color={'warning'}
                  startIcon={<Iconify icon={'ant-design:stop-outlined'} />}
                  onClick={handleHold}
                >
                  {translate('maintenance.hold_job')}
                </Button>
              </>
            )}
            {[
              MaintenanceJobStatus.Hold,
              MaintenanceJobStatus.WaitForParts,
              MaintenanceJobStatus.WaitForSupplier,
            ].includes(jobDetail?.MaintenanceJobStatus.Id) && (
              <Button
                variant="contained"
                color={'info'}
                startIcon={<Iconify icon={'radix-icons:resume'} />}
                onClick={handleResume}
              >
                {translate('maintenance.resume_job')}
              </Button>
            )}
          </RoleBasedGuard>
          {[MaintenanceJobStatus.WaitForEvaluation].includes(
            jobDetail?.MaintenanceJobStatus.Id
          ) && (
            <Button
              variant="contained"
              color={'success'}
              startIcon={<Iconify icon={'bi:clipboard-check'} />}
              component={RouterLink}
              href={paths.maintenance.evaluate_job(jobDetail?.Id)}
            >
              {translate('button.evaluate')}
            </Button>
          )}
        </Stack>
      }
    >
      {isChangeSpecialist ? (
        <SelectMaintenanceTechnicianForm onChangeTechnicianCallback={handleChangeTechnician} />
      ) : jobDetail !== null ? (
        <>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {INFORMATION_TABS.map((tab, index) => (
              <Tab
                disableRipple
                key={index}
                label={tab.title}
                icon={tab.icon}
                value={tab.value}
                disabled={tab.disabled}
              />
            ))}
          </Tabs>
          <Box sx={{ mb: 3 }} />
          {INFORMATION_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && tab.component;
          })}
        </>
      ) : null}
    </ProjectPageContainer>
  );
}

export default MaintenanceJobDetailPage;
