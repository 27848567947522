import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { completeServicePurchaseOrderRequest } from '../../../requests/WarehouseServerHandler';
import { useForm } from 'react-hook-form';
import { useLocales } from 'locales';
import { Button, Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OpenSuccessNotification } from '../../../utilities/Helper/NotificationHelper';
import { useServicePurchaseOrder } from '../../../requests/query/useServicePurchaseOrder';
import { useAllSupplier } from '../../../requests/query/DropdownData';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../../minimal/hook-form';
import { paths } from '../../../routes/paths';
import { useMaintenanceJob } from '../../../requests/query/useMaintenanceJob';
import { useMutation } from '@tanstack/react-query';

function AcceptServicePurchaseOrderPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { id } = useParams();
  const { servicePurchaseOrder } = useServicePurchaseOrder(id);
  const { allSupplier } = useAllSupplier(true);
  const { jobDetail } = useMaintenanceJob(
    servicePurchaseOrder?.MaintenanceJobHasServicePurchaseOrders[0].MaintenanceJobId
  );

  const acceptSPOSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    ReferenceId: Yup.string().required(translate('error.invalid_input')),
    Supplier: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.detail_require')),
    Value: Yup.number()
      .min(1, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(acceptSPOSchema),
  });

  const { handleSubmit } = methods;

  const completeSPOMutation = useMutation({
    mutationFn: completeServicePurchaseOrderRequest,
    onSuccess: async () => {
      await OpenSuccessNotification();
      navigate(paths.procurement.service_po_detail(id));
    },
  });
  const completeReport = async (values) => {
    await completeSPOMutation.mutateAsync({
      Id: servicePurchaseOrder.Id,
      Detail: values.Detail,
      ReferenceId: values.ReferenceId,
      SupplierId: values.Supplier.Id,
      Price: values.Value,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.accept_spo')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.procurement_service_purchase_order_title'),
          href: paths.procurement.spo_list,
        },
        { name: translate('procurement.accept_spo') },
      ]}
    >
      <Stack spacing={3}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText title={translate('title.id')} value={id} />
            <GridDetailText
              title={translate('title.maintenance_job_id')}
              value={jobDetail?.Id}
              link={paths.maintenance.job_detail(jobDetail?.Id)}
            />
            <GridDetailText
              title={translate('title.detail')}
              value={servicePurchaseOrder?.Reason}
            />
          </Grid>
        </ProjectPaper>
        <ProjectPaper>
          <FormProvider methods={methods} onSubmit={handleSubmit(completeReport)}>
            <Stack spacing={3}>
              <RHFTextField name="Detail" label={translate('title.detail')} />
              <RHFTextField
                name="ReferenceId"
                label={translate('procurement.po_reference_id_title')}
              />
              <RHFAutoCompleteShort
                name={'Supplier'}
                label={translate('procurement.supplier')}
                data={allSupplier.filter((supplier) => supplier.Id !== 2)}
              />
              <RHFTextField name="Value" label={translate('title.value')} />
              <Button variant={'contained'} fullWidth type="submit" color={'primary'}>
                {translate('button.save')}
              </Button>
            </Stack>
          </FormProvider>
        </ProjectPaper>
      </Stack>
    </ProjectPageContainer>
  );
}

export default AcceptServicePurchaseOrderPage;
