import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Alert } from '@mui/material';
import { useLocales } from 'locales';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from 'components/customs/ProjectPaper';

const DatePicker = (props) => {
  const { defaultDateRange, buttonDateRanges, onSearchDate } = props;
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isError, setIsError] = useState(false);

  const onClearDate = () => {
    onSearchDate(defaultDateRange.fromDate, defaultDateRange.toDate);
    setFromDate(null);
    setToDate(null);
    setIsSearch(false);
  };

  const handleSearchData = () => {
    if (fromDate !== null && toDate !== null && toDate > fromDate) {
      onSearchDate(fromDate, toDate);
      setIsSearch(true);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const handleFromChange = (time) => {
    setIsSearch(false);
    setIsError(false);
    setFromDate(time);
  };

  const handleToChange = (time) => {
    setIsSearch(false);
    setIsError(false);
    setToDate(time);
  };

  const { translate } = useLocales();

  return (
    <ProjectPaper>
      {isError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {translate('error.wrong_date_range')}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <MUIDateTimePicker
            ampm={false}
            format="dd/MM/yyyy HH:mm"
            label={translate('title.start_date')}
            value={fromDate}
            onChange={handleFromChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <MUIDateTimePicker
            ampm={false}
            format="dd/MM/yyyy HH:mm"
            label={translate('title.end_date')}
            value={toDate}
            onChange={handleToChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {!isSearch ? (
            <Button
              fullWidth
              startIcon={<Iconify icon={'eva:search-fill'} />}
              color={'primary'}
              variant={'contained'}
              sx={{ height: 55 }}
              onClick={handleSearchData}
            >
              {translate('button.search')}
            </Button>
          ) : (
            <Button
              fullWidth
              startIcon={<Iconify icon={'eva:close-fill'} />}
              variant={'contained'}
              color={'error'}
              sx={{ height: 55 }}
              onClick={onClearDate}
            >
              {translate('button.clear_search')}
            </Button>
          )}
        </Grid>
        {buttonDateRanges.map((button, index) => {
          return (
            <Grid key={index} item xs={12} sm={12} md={3} lg={3}>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={() => {
                  setFromDate(button.fromDate);
                  setToDate(button.toDate);
                  onSearchDate(button.fromDate, button.toDate);
                  setIsSearch(true);
                  setIsError(false);
                }}
              >
                {button.title}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </ProjectPaper>
  );
};

DatePicker.propTypes = {
  title: PropTypes.string,
  onSearchDate: PropTypes.func.isRequired,
  defaultDateRange: PropTypes.object.isRequired,
  buttonDateRanges: PropTypes.array,
};

export default DatePicker;
