import { useQuery } from '@tanstack/react-query';
import { getMonthPO } from '../ProductionServerHandler';

export const usePlanningData = () => {
  const { data: monthsPO } = useQuery({
    queryKey: ['month-po'],
    queryFn: getMonthPO,
    initialData: {
      ActiveOrder: [],
      CompleteOrder: [],
    },
  });

  return {
    unPlanPO: monthsPO.ActiveOrder.filter((po) => po.ProductOrderPlannings.length === 0),
    planPO: monthsPO.ActiveOrder.filter((po) => po.ProductOrderPlannings.length > 0),
    completePO: monthsPO.CompleteOrder,
  };
};
