// i18n
import './locales/i18n';
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
import SnackbarProvider from 'components/minimal/snackbar/snackbar-provider';
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './Root';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { AuthProvider } from './auth';
import { SettingsProvider } from 'components/minimal/settings';
import ThemeProvider from './theme';
import { LocalizationProvider } from 'locales';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryProvider } from './components/react-query';
import { MotionLazy } from 'components/minimal/animate/motion-lazy';
import { LoadingProvider } from './components/loading';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ReactQueryProvider>
    <Root>
      <AuthProvider>
        <LocalizationProvider>
          <HelmetProvider>
            <SettingsProvider
              defaultSettings={{
                themeMode: 'light', // 'light' | 'dark'
                themeDirection: 'ltr', //  'rtl' | 'ltr'
                themeContrast: 'default', // 'default' | 'bold'
                themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                themeStretch: false,
              }}
            >
              <ThemeProvider>
                <LoadingProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <App />
                    </SnackbarProvider>
                  </MotionLazy>
                </LoadingProvider>
              </ThemeProvider>
            </SettingsProvider>
          </HelmetProvider>
        </LocalizationProvider>
      </AuthProvider>
    </Root>
  </ReactQueryProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
