import React from "react";
import {ProjectPageContainer} from "components/container/ProjectPageContainer";
import useAuth from "auth/hooks/useAuth";
import {Box, Card, Grid} from "@mui/material";
import MyAvatar from "components/customs/MyAvatar";
import GridDetailText from "components/customs/GridDetailText";
import {useLocales} from "locales";
import Button from "@mui/material/Button";
import {paths} from "routes/paths";

export default function UserDetailPage() {

    const {user, logout} = useAuth();
    const {translate} = useLocales();

    const onLogoutPressed = async () => {
        await logout();
    };

    return (
        <ProjectPageContainer menu_title={translate('user.title')} breadcrumbs={[
            { name: translate('dashboard'), href: paths.dashboard.main },
            { name: translate('user.profile')},
        ]}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{py: 10, px: 3, textAlign: 'center'}}>
                        <MyAvatar
                            sx={{
                                mx: 'auto',
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderColor: 'common.white',
                                width: {xs: 80, md: 128},
                                height: {xs: 80, md: 128},
                            }}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Card sx={{p: 3}}>
                        <Grid container spacing={3}>
                            <GridDetailText title={translate('user.employee_uid')} value={user?.EmployeeId}/>
                            <GridDetailText title={translate('user.username')} value={user?.Username}/>
                            <GridDetailText title={translate('user.name')} value={user?.Name}/>
                            <GridDetailText title={translate('user.employee_code')} value={user?.EmployeeCode}/>
                            <GridDetailText title={translate('user.department')} value={user?.Department.Name}/>
                        </Grid>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                m: 3,
                            }}
                        >
                            <Button color={"error"} variant={'contained'} onClick={onLogoutPressed}>{translate('button.logout')}</Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </ProjectPageContainer>
    );

}
