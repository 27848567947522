import React, { useMemo } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  acceptItemContainer,
  getPurchaseOrderItemContainerById,
} from '../../../requests/WarehouseServerHandler';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import Typography from '@mui/material/Typography';
import LoadingComponent from '../../customs/LoadingComponent';
import AcceptItemForm from '../../forms/AcceptItemForm';
import { paths } from '../../../routes/paths';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useItemLotDetail } from '../../../requests/query/useItemLotDetail';
import { AcceptItemDetail } from '../../../classes/AcceptItemDetail';

function AcceptStoreItemPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { id } = useParams();
  const { printWholeQRPDF } = useItemLotDetail(null);

  const { data } = useQuery({
    queryKey: ['purchaseOrderItemContainer', id],
    queryFn: () => getPurchaseOrderItemContainerById(id),
    enabled: !!id,
    initialData: null,
  });

  const acceptItemDetail = useMemo(() => {
    if (data !== null) {
      return new AcceptItemDetail(data);
    } else {
      return null;
    }
  }, [data]);

  const acceptItemMutation = useMutation({
    mutationFn: acceptItemContainer,
    onSuccess: async (response) => {
      await OpenTranslateWarningConfirmNotification(
        translate,
        'warning.confirm_print_barcode',
        async () => {
          await printWholeQRPDF.mutateAsync(response);
        }
      );
      navigate(paths.warehouse.add_items);
    },
  });
  const createAcceptStoreItem = async (data) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_add_item_inventory',
      async () => {
        acceptItemMutation.mutate({
          PurchaseOrderItemContainerId: acceptItemDetail.POItemContainer.Id,
          ItemContainerId: data.ItemContainer.Id,
          Amount: data.Amount,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.accept_inventory')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.accept_inventory'), href: paths.warehouse.add_items },
        {
          name: `${translate('warehouse.accept_inventory')} ${translate(
            'procurement.po_number'
          )}: ${acceptItemDetail?.PurchaseOrderId}`,
        },
      ]}
    >
      {acceptItemDetail !== null ? (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('warehouse.item_master_name')}
                value={acceptItemDetail.ItemMaster.Name}
                link={paths.warehouse.item_master_detail(acceptItemDetail.ItemMaster.Id)}
              />
              <GridDetailText
                title={translate('warehouse.container_detail_title')}
                value={acceptItemDetail.ItemContainer.Name}
                link={paths.warehouse.item_container_detail(acceptItemDetail.ItemContainer.Id)}
              />
              <GridDetailText
                title={translate('procurement.po_number')}
                value={acceptItemDetail.PurchaseOrderId}
                link={paths.procurement.purchase_order_detail(acceptItemDetail.PurchaseOrderId)}
              />
              <GridDetailText
                title={translate('procurement.amount_purchase')}
                value={`${acceptItemDetail.POItemContainer.Amount} ${acceptItemDetail.SupplierSellItemContainer.SellUnit.Name}`}
              />
              <GridDetailText
                title={translate('warehouse.master_amount')}
                value={`${acceptItemDetail.ItemMasterAmount} ${acceptItemDetail.ItemMaster.ItemUnit.Name} (${acceptItemDetail.ItemContainer.ItemMasterSize} ${acceptItemDetail.ItemMaster.ItemUnit.Name} ต่อ ${acceptItemDetail.ItemContainer.ItemUnit.Name})`}
              />
              <GridDetailText
                title={translate('warehouse.container_size_title')}
                value={`${acceptItemDetail.ItemContainerAmount} ${acceptItemDetail.ItemContainer.ItemUnit.Name}`}
              />
              <GridDetailText
                title={translate('warehouse.already_accept_amount')}
                value={`${acceptItemDetail.AlreadyAcceptAmount} ${acceptItemDetail.ItemContainer.ItemUnit.Name}`}
              />
            </Grid>
          </ProjectPaper>
          <Typography variant={'h5'} color={'error'}>{`*** ${translate(
            'warning.error_master_amount_warning'
          )}`}</Typography>
          <ProjectPaper>
            <AcceptItemForm
              acceptItemDetail={acceptItemDetail}
              allItemContainer={acceptItemDetail.ItemMaster.ItemContainers.map((container) => {
                return { Id: container.Id, Name: container.Name };
              })}
              onFormSubmit={createAcceptStoreItem}
            />
          </ProjectPaper>
        </Stack>
      ) : (
        <LoadingComponent />
      )}
    </ProjectPageContainer>
  );
}

export default AcceptStoreItemPage;
