import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { Box, Button, IconButton } from '@mui/material';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import ItemLotDetail from '../../details/warehouse/ItemLotDetail';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useTabs from '../../../hooks/useTabs';
import { OPEN_ICON } from '../../../config-global';
import { useItemLotDetail } from '../../../requests/query/useItemLotDetail';
import { paths } from '../../../routes/paths';
import { useItemDetail } from '../../../requests/query/useItemDetail';
import Link from "@mui/material/Link";

function ItemLotDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const { itemLot, printWholeQRPDF } = useItemLotDetail(id);
  const { printQRCode, deleteItem } = useItemDetail(null);
  const [isShowUseItem, setIsShowUseItem] = useState(false);
  const { currentTab, onChangeTab } = useTabs('detail');

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: <ItemLotDetail itemLotDetail={itemLot} />,
    },
    {
      value: 'lots',
      title: translate('warehouse.item_in_lot_detail'),
      icon: <Iconify icon={'fe:list-order'} width={20} height={20} />,
      component: (
        <ProjectPaper>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
            <Button
              variant="contained"
              color={isShowUseItem ? 'warning' : 'info'}
              startIcon={<Iconify icon={isShowUseItem ? 'bx:hide' : 'bx:show'} />}
              onClick={() => setIsShowUseItem(!isShowUseItem)}
            >
              {translate(
                isShowUseItem ? 'button.hide_use_item_title' : 'button.show_use_item_title'
              )}
            </Button>
          </Box>
          <ReactTable
            data={
              !isShowUseItem
                ? itemLot?.Items.filter((item) => item.RemainAmount > 0).map((item) => {
                    return {
                      id: item.Id,
                      bar_code: item.BarcodeId,
                      shelf: item.WarehouseShelf,
                      remain: item.RemainAmount,
                      seller_item_name:
                        itemLot.PurchaseOrderItemContainer !== null
                          ? itemLot.PurchaseOrderItemContainer.SupplierSellItemContainer.Name
                          : translate('warehouse.create_by_store_employee'),
                      actions: item.Id,
                    };
                  })
                : itemLot?.Items.map((item) => {
                    return {
                      id: item.Id,
                      bar_code: item.BarcodeId,
                      shelf: item.WarehouseShelf,
                      remain: item.RemainAmount,
                      seller_item_name:
                        itemLot.PurchaseOrderItemContainer !== null
                          ? itemLot.PurchaseOrderItemContainer.SupplierSellItemContainer.Name
                          : translate('warehouse.create_by_store_employee'),
                      actions: item.Id,
                    };
                  })
            }
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
              },
              {
                header: translate('warehouse.barcode_id'),
                accessorKey: 'bar_code',
              },
              {
                header: translate('warehouse.shelf'),
                accessorKey: 'shelf',
                cell: (info) => (
                  <Link href={paths.warehouse.shelf_detail(info.getValue().Id)}>
                    {info.getValue().Name}
                  </Link>
                ),
              },
              {
                header: translate('warehouse.remain_amount_title'),
                accessorKey: 'remain',
              },
              {
                header: translate('warehouse.supplier_name'),
                accessorKey: 'seller_item_name',
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <RoleBasedGuard roles={[ManagementUserTypeId]}>
                      <IconButton color={'error'} onClick={() => onDeleteMaterial(info.getValue())}>
                        <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                      </IconButton>
                    </RoleBasedGuard>
                    <IconButton onClick={() => printItemQR(info.getValue())}>
                      <Iconify icon={'ic:outline-print'} width={20} height={20} />
                    </IconButton>
                    <IconButton
                      component={Link}
                      href={paths.warehouse.item_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </ProjectPaper>
      ),
    },
  ];

  const printWholeInventoryQR = async () => {
    await printWholeQRPDF.mutateAsync(itemLot.Id);
  };
  const printItemQR = async (itemId) => {
    await printQRCode.mutateAsync(itemId);
  };
  const onDeleteMaterial = async (itemId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_delete_data',
      async () => {
        await deleteItem.mutateAsync(itemId);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.lot_detail')}
      breadcrumbs={[
        { name: translate('warehouse.material_store'), href: paths.warehouse.store },
        {
          name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${
            itemLot?.ItemContainer.ItemMaster.Id
          }`,
          href: paths.warehouse.item_master_detail(itemLot?.ItemContainer.ItemMaster.Id),
        },
        {
          name: `${translate('warehouse.item_container_detail')} ${translate('title.id')} : ${
            itemLot?.ItemContainer.Id
          }`,
          href: paths.warehouse.item_container_detail(itemLot?.ItemContainer.Id),
        },
        {
          name: `${translate('warehouse.lot_detail')} ${translate('title.id')} : ${itemLot?.Id}`,
        },
      ]}
      action={
        <Button
          variant="contained"
          color={'info'}
          startIcon={<Iconify icon={'ic:outline-print'} />}
          onClick={printWholeInventoryQR}
        >
          {translate('button.print_item_qr_whole')}
        </Button>
      }
    >
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {INFORMATION_TABS.map((tab, index) => (
          <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>
      <Box sx={{ mb: 3 }} />
      {INFORMATION_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && tab.component;
      })}
    </ProjectPageContainer>
  );
}

export default ItemLotDetailPage;
