import React from 'react';
import { useLocales } from '../../../locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import ProductionAdjustPileForm from '../../forms/ProductionAdjustPileForm';
import { useEditPileData } from '../../../requests/query/useEditPileData';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';

function PlanningAdjustPileAmountPage() {
  const { translate } = useLocales();
  const { editPile } = useEditPileData();

  const handleAdjustPileAmount = async (data) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      translate('warning.confirm_change_pile_amount'),
      () => {
        editPile.mutate({
          PileId: data.Id,
          GoodAmount: data.GoodAmount,
          DefectAmount: data.DefectAmount,
          AdjustAmount: data.AdjustAmount,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_pile_good_amount_page_title')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('admin.edit_pile_good_amount_page_title') },
      ]}
    >
      <ProductionAdjustPileForm onSubmitCallback={handleAdjustPileAmount} />
    </ProjectPageContainer>
  );
}

export default PlanningAdjustPileAmountPage;
