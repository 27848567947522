import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/lab';
import { Grid } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import ProjectPaper from '../customs/ProjectPaper';
import { useLocales } from '../../locales';

DatePickerWithTypeSelection.propTypes = {
  selectionNode: PropTypes.node,
  selectionActions: PropTypes.node,
  onFromChange: PropTypes.func.isRequired,
  onToChange: PropTypes.func.isRequired,
};

function DatePickerWithTypeSelection({
  selectionNode,
  selectionActions,
  onFromChange,
  onToChange,
}) {
  const { translate } = useLocales();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleFromChange = (time) => {
    setIsError(false);
    setFromDate(time);
    onFromChange(time);
  };
  const handleToChange = (time) => {
    setIsError(false);
    setToDate(time);
    onToChange(time);
  };

  return (
    <ProjectPaper>
      {isError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {translate('error.wrong_date_range')}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MUIDateTimePicker
            ampm={false}
            format="dd/MM/yyyy HH:mm"
            label={translate('title.start_date')}
            value={fromDate}
            onChange={handleFromChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MUIDateTimePicker
            ampm={false}
            format="dd/MM/yyyy HH:mm"
            label={translate('title.end_date')}
            value={toDate}
            onChange={handleToChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {selectionNode}
        </Grid>
        {selectionActions}
      </Grid>
    </ProjectPaper>
  );
}

export default DatePickerWithTypeSelection;
