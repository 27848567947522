import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotificationThenReloadPage,
  OpenNotificationThenReloadPage,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import useAuth from '../../../auth/hooks/useAuth';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { approveAmountProblemPile } from '../../../requests/ProductionServerHandler';
import ProjectPaper from '../../customs/ProjectPaper';
import { Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import Button from '@mui/material/Button';
import PileScanner from '../../barcode-reader/PileScanner';
import { useMutation } from '@tanstack/react-query';

const ApproveAmountMeasurePage = () => {
  const { translate } = useLocales();
  const { user } = useAuth();
  const [approvePile, setApprovePile] = useState(null);
  const searchPileDetail = async (pile) => {
    setApprovePile(pile);
  };

  const approveAmountProblemPileMutation = useMutation({
    mutationFn: approveAmountProblemPile,
    onSuccess: async (response) => {
      if (response.IsError) {
        await OpenErrorNotificationThenReloadPage(response.IsError);
      } else {
        await OpenNotificationThenReloadPage();
      }
    },
  });

  const onSubmit = async (value) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_approve_pile',
      async () => {
        await approveAmountProblemPileMutation.mutateAsync({
          PileId: approvePile.Id,
          ApproveHeight: value.Height,
        });
      }
    );
  };

  const approvePileSchema = Yup.object().shape({
    Height: Yup.number()
      .min(1, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(approvePileSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('planning.approve_exceed_amount')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('planning.approve_exceed_amount') },
      ]}
    >
      {approvePile !== null ? (
        <>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={approvePile.Id} />
              <GridDetailText
                title={translate('title.name')}
                value={approvePile.ProductOrder.Name}
              />
              <GridDetailText
                title={translate('title.customer')}
                value={approvePile.ProductOrder.Customer.Name}
              />
              <GridDetailText title={translate('title.amount')} value={approvePile.Amount} />
            </Grid>
          </ProjectPaper>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <ProjectPaper>
              <Stack spacing={3}>
                <RHFTextField name={'Height'} label={translate('title.height')} />
                <Button variant={'contained'} color={'primary'} fullWidth type={'submit'}>
                  {translate('button.release')}
                </Button>
              </Stack>
            </ProjectPaper>
          </FormProvider>
        </>
      ) : (
        <PileScanner
          onSearchCallBack={searchPileDetail}
          disableInput={user.UserTypeId !== ManagementUserTypeId}
          isPreFetchData
        />
      )}
    </ProjectPageContainer>
  );
};

export default ApproveAmountMeasurePage;
