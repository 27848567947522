import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Stack, Typography, Button } from '@mui/material';
import { useLocales } from '../../locales';
import { checkBundlePrice } from '../../utilities/Helper/UtilitiesHelper';
import { getAllProductSalesHistory } from '../../requests/ProductionServerHandler';
import ReactTable from '../react-table/ReactTable';
import { fCurrency } from '../../utilities/formatNumber';
import { fDateTimeDMY } from '../../utilities/formatTime';
import Iconify from '../minimal/iconify';
import { useQuery } from '@tanstack/react-query';

SelectProductForm.propTypes = {
  customerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productOrderAmount: PropTypes.number.isRequired,
  onSubmitProducts: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
};

function SelectProductForm({
  customerId,
  productOrderAmount,
  onSubmitProducts,
  onBackPress,
  selectedProducts,
}) {
  const { translate } = useLocales();
  const [selectProducts, setSelectProducts] = useState(selectedProducts);
  const { data: allProduct } = useQuery({
    queryKey: ['all-customer-product-history', customerId],
    queryFn: () => getAllProductSalesHistory(customerId),
    initialData: [],
    enabled: customerId !== null,
  });

  const handleSelectProducts = () => {
    onSubmitProducts(selectProducts);
  };

  const handleAddProduct = (product) => {
    setSelectProducts([...selectProducts, product]);
  };

  return (
    <Card>
      <CardHeader title={translate('sales.select_product')} />
      <CardContent>
        <ReactTable
          search
          defaultPageSize={25}
          data={allProduct.map((product) => {
            let pricePerSheet = product.BasePrice;
            if (product.ProductSellHistories.length > 0) {
              pricePerSheet = product.ProductSellHistories[0].SellPrice;
            }

            const isBundle = checkBundlePrice(productOrderAmount, product.MinimumAmount);
            return {
              id: product.Id,
              name: product.Name,
              sku: product.SKU,
              type: product.ProductType.Name,
              price: {
                IsBundle: isBundle,
                BundlePrice: product.BundlePrice,
                Price: pricePerSheet,
              },
              history:
                product.ProductSellHistories.length > 0 ? product.ProductSellHistories[0] : null,
              actions: {
                Detail: product,
                History:
                  product.ProductSellHistories.length > 0 ? product.ProductSellHistories[0] : null,
                Price: {
                  IsBundle: isBundle,
                  BundlePrice: product.BundlePrice <= 0 ? 999999: product.BundlePrice,
                  Price: pricePerSheet <= 0 ? 999 : pricePerSheet,
                },
              },
            };
          })}
          header={[
            {
              header: translate('title.amount'),
              accessorKey: 'actions',
              size: 50,
              cell: (info) => (
                <Button
                  variant={'contained'}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                  color={'primary'}
                  onClick={() => handleAddProduct(info.getValue())}
                >
                  {`${translate('button.selected')} ${
                    selectProducts.filter(
                      (selectProduct) => selectProduct.Detail.Id === info.getValue().Detail.Id
                    ).length > 0
                      ? `(${
                          selectProducts.filter(
                            (selectProduct) => selectProduct.Detail.Id === info.getValue().Detail.Id
                          ).length
                        } ${translate('button.selected')})`
                      : ''
                  }`}
                </Button>
              ),
            },
            {
              header: translate('title.id'),
              accessorKey: 'id',
              size: 50,
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
              size: 100,
            },
            {
              header: translate('title.sku'),
              accessorKey: 'sku',
              size: 50,
            },
            {
              header: translate('title.price_per_sheet'),
              accessorKey: 'price',
              size: 50,
              cell: (info) =>
                info.getValue().IsBundle
                  ? `${translate('title.bundle_price')} : ${info.getValue().BundlePrice}`
                  : fCurrency(info.getValue().Price),
            },
            {
              header: translate('sales.price_history'),
              accessorKey: 'history',
              size: 50,
              cell: (info) =>
                info.getValue() !== null ? (
                  <Stack>
                    <Typography variant="subtitle2" sx={{ cursor: 'pointer' }} noWrap>
                      {info.getValue().SellPrice}
                    </Typography>
                    <Typography noWrap variant="body2" sx={{ color: 'text.disabled' }}>
                      {fDateTimeDMY(info.getValue().createdAt)}
                    </Typography>
                  </Stack>
                ) : (
                  translate('sales.no_transaction')
                ),
            },
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3,
          }}
        >
          <Button color={'primary'} onClick={onBackPress}>
            {translate('button.back')}
          </Button>
          <Button
            color={'primary'}
            disabled={selectProducts.length <= 0}
            variant={'contained'}
            onClick={handleSelectProducts}
          >
            {translate('button.next')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default SelectProductForm;
