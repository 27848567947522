import InventoryStorePage from '../../components/pages/GeneralWarehouseModule/StorePage';
import CreateItemTemplatePage from '../../components/pages/GeneralWarehouseModule/CreateItemTemplatePage';
import ItemTemplateDetailPage from '../../components/pages/GeneralWarehouseModule/ItemTemplateDetailPage';
import CreateInventoryShelfPage from '../../components/pages/GeneralWarehouseModule/CreateInventoryShelfPage';
import CreateInventoryPage from '../../components/pages/GeneralWarehouseModule/CreateInventoryPage';
import InventoryCheckoutPage from '../../components/pages/GeneralWarehouseModule/InventoryCheckoutPage';
import ItemTemplateRequestReportDashboardPage from '../../components/pages/GeneralWarehouseModule/ItemTemplateRequestReportDashboardPage';
import ItemTemplateRequestReportDetailPage from '../../components/pages/GeneralWarehouseModule/ItemTemplateRequestReportDetailPage';
import SearchPage from '../../components/pages/GeneralWarehouseModule/SearchPage';
import ReturnItemPage from '../../components/pages/GeneralWarehouseModule/ReturnItemPage';
import AcceptItemPage from '../../components/pages/GeneralWarehouseModule/AcceptItemPage';
import StockReportDetailPage from '../../components/pages/GeneralWarehouseModule/StockReportDetailPage';
import MoveInventoryShelfPage from '../../components/pages/GeneralWarehouseModule/MoveInventoryShelfPage';
import PrintInventoryDataPage from '../../components/pages/GeneralWarehouseModule/PrintInventoryDataPage';
import PrintStockTransactionReportPage from '../../components/pages/GeneralWarehouseModule/PrintStockTransactionReportPage';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  HumanResourceUserTypeId,
  MaintenanceControlUserTypeId,
  ManagementUserTypeId,
  ProcurementUserTypeId,
  ProductionControlUserTypeId,
  ProductionUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
  StoreUserTypeId,
  WarehouseControlUserTypeId,
} from '../../utilities/Constants';
import AddItemTemplateToStorePage from '../../components/pages/GeneralWarehouseModule/AddItemTemplateToStorePage';
import PartSafetyStockManagementPage from '../../components/pages/GeneralWarehouseModule/PartSafetyStockManagementPage';
import QuickRequestPage from '../../components/pages/GeneralWarehouseModule/QuickRequestPage';
import CreateItemTemplateUnitPage from '../../components/pages/GeneralWarehouseModule/CreateItemTemplateUnitPage';
import { paths } from '../paths';
import InventoryDetailPage from '../../components/pages/GeneralWarehouseModule/InventoryDetailPage';

const generalWarehouseModuleRouteDetails = [
  {
    path: paths.general_warehouse.create_master,
    component: <CreateItemTemplatePage />,
    isPrivate: true,
    allowUserTypeId: [
      HumanResourceUserTypeId,
      SecretaryUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.add_items,
    component: <AddItemTemplateToStorePage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.store,
    component: <InventoryStorePage />,
    isPrivate: true,
    allowUserTypeId: [
      SalesUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      HumanResourceUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      ProcurementUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.item_template_detail(':id'),
    component: <ItemTemplateDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.general_warehouse.inventory_detail(':id'),
    component: <InventoryDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.general_warehouse.create_inventory,
    component: <CreateInventoryPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      ProcurementUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.create_shelf,
    component: <CreateInventoryShelfPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      ProcurementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.check_out_items,
    component: <InventoryCheckoutPage />,
    isPrivate: true,
    allowUserTypeId: [
      HumanResourceUserTypeId,
      ProductionUserTypeId,
      ProductionControlUserTypeId,
      QualityAssuranceUserTypeId,
      SecretaryUserTypeId,
      SalesUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      MaintenanceControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.release_items,
    component: <ItemTemplateRequestReportDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [
      SalesUserTypeId,
      ProductionControlUserTypeId,
      HumanResourceUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.request_report_detail(':id'),
    component: <ItemTemplateRequestReportDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      ProductionUserTypeId,
      SecretaryUserTypeId,
      SalesUserTypeId,
      ProductionControlUserTypeId,
      HumanResourceUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.search,
    component: <SearchPage />,
    isPrivate: true,
    allowUserTypeId: [
      SecretaryUserTypeId,
      SalesUserTypeId,
      ProductionControlUserTypeId,
      HumanResourceUserTypeId,
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.return_items,
    component: <ReturnItemPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.move_items,
    component: <MoveInventoryShelfPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.accept_items,
    component: <AcceptItemPage />,
    isPrivate: false,
    allowUserTypeId: [
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.print_warehouse_data,
    component: <PrintInventoryDataPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.print_stock_transaction,
    component: <PrintStockTransactionReportPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.stock_report_detail(':id'),
    component: <StockReportDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      WarehouseControlUserTypeId,
      ManagementUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      MaintenanceControlUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.manage_safety_stock,
    component: <PartSafetyStockManagementPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      AccountingUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.create_unit,
    component: <CreateItemTemplateUnitPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      AccountingUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.general_warehouse.quick_request,
    component: <QuickRequestPage />,
    isPrivate: false,
  },
];
export default generalWarehouseModuleRouteDetails;
