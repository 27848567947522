import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from '../../../locales';

ItAssetDetail.propTypes = {
  asset: PropTypes.object.isRequired,
};

function ItAssetDetail({ asset }) {
  const { translate } = useLocales();

  return (
    <Grid container spacing={2}>
      <GridDetailText title={translate('title.id')} value={asset.id} />
      <GridDetailText title={translate('it.code')} value={asset.code} />
      <GridDetailText title={translate('it.serial_number')} value={asset.serial_number} />
      <GridDetailText title={translate('it.brand')} value={asset.brand} />
      <GridDetailText title={translate('it.type')} value={asset.type} />
      <GridDetailText title={translate('it.spec')} value={asset.spec} />
      <GridDetailText title={translate('it.color')} value={asset.color} />
      <GridDetailText title={translate('it.note')} value={asset.note} />
    </Grid>
  );
}

export default ItAssetDetail;
