import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { adjustPartsPurchaseCart, adjustPartsRequestCart } from '../../redux/reducers/inventory';
import Hidden from '@mui/material/Hidden';
import { getAllItemTemplateByCategoryId } from '../../requests/ProductionServerHandler';
import { RouterLink } from 'routes/components';
import ProjectPaper from './ProjectPaper';
import ReactTable from '../react-table/ReactTable';
import { useLocales } from 'locales';
import { IconButton } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import Label from 'components/minimal/label';
import { useTheme } from '@mui/material/styles';
import { OPEN_ICON } from '../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../routes/paths';

StoreComponent.PropType = {
  page_history: PropTypes.object.isRequired,
  table_data: PropTypes.array.isRequired,
  category_id: PropTypes.number.isRequired,
  onSearchUpdateDataCallBack: PropTypes.func.isRequired,
  is_request: PropTypes.bool.isRequired,
};

function StoreComponent({ category_id, is_request }) {
  const { translate } = useLocales();
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const { data: allItemTemplate } = useQuery({
    queryKey: ['allItemTemplate', category_id],
    queryFn: () => getAllItemTemplateByCategoryId(category_id),
    enabled: category_id !== undefined,
    initialData: [],
  });

  const handleAddItemToCart = async (itemTemplate) => {
    await Swal.fire({
      title: is_request
        ? translate('warehouse.request_item_amount_title')
        : translate('warehouse.request_buy_item_amount_title'),
      input: 'text',
      inputLabel: `${itemTemplate.Name} ${translate('warehouse.have')} ${
        itemTemplate.CurrentAmount
      } ${itemTemplate.ItemTemplateUnit.Name} ${translate('warehouse.in_store')}`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('button.confirm'),
      cancelButtonText: translate('button.cancel'),
      inputValidator: async (value) => {
        if (!value) {
          return translate('error.error_invalid_amount');
        } else {
          const amount = parseFloat(value);
          if (isNaN(amount)) {
            return translate('error.error_invalid_amount');
          } else {
            if (amount <= 0) {
              return translate('error.error_invalid_amount');
            } else {
              if (is_request && amount > itemTemplate.CurrentAmount) {
                return translate('error.insufficient_amount');
              }
            }
          }
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (is_request) {
          dispatch(
            adjustPartsRequestCart({
              Id: itemTemplate.Id,
              Name: itemTemplate.Name,
              Amount: result.value,
              MaxAmount: itemTemplate.CurrentAmount,
            })
          );
        } else {
          dispatch(
            adjustPartsPurchaseCart({
              Id: itemTemplate.Id,
              Name: itemTemplate.Name,
              Amount: result.value,
            })
          );
        }
      }
    });
  };
  const getTableItem = (isMobile) => {
    if (isMobile) {
      return allItemTemplate.map((itemTemplate) => {
        return {
          id: itemTemplate.Id,
          name: itemTemplate.Name,
          amount: itemTemplate.CurrentAmount,
          actions: itemTemplate,
        };
      });
    }
    return allItemTemplate.map((itemTemplate) => {
      return {
        id: itemTemplate.Id,
        name: itemTemplate.Name,
        type: itemTemplate.ItemTemplateType.Name,
        safety: itemTemplate.SafetyAmount,
        amount: itemTemplate.CurrentAmount,
        unit: itemTemplate.ItemTemplateUnit.Name,
        is_safety: {
          ItemId: itemTemplate.Id,
          IsSafety: isNeedSafety(itemTemplate.SafetyAmount, itemTemplate.CurrentAmount),
        },
        actions: itemTemplate,
      };
    });
  };
  const isNeedSafety = (safetyAmount, remainAmount) => {
    if (safetyAmount > 0) {
      return remainAmount <= safetyAmount;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <Hidden lgDown>
        <ProjectPaper>
          <ReactTable
            search
            defaultPageSize={25}
            data={getTableItem(false)}
            header={[
              {
                header: 'Actions',
                accessorKey: 'actions',
                size: 50,
                cell: (info) => (
                  <>
                    <IconButton
                      component={RouterLink}
                      to={paths.general_warehouse.item_template_detail(info.getValue().Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton
                      color={'success'}
                      onClick={() => handleAddItemToCart(info.getValue())}
                    >
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
              },
              {
                header: translate('title.id'),
                accessorKey: 'id',
                size: 50,
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
                size: 200,
              },
              {
                header: translate('title.safety_amount'),
                accessorKey: 'safety',
                size: 20,
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
                size: 50,
              },
              {
                header: translate('unit.title'),
                accessorKey: 'unit',
                size: 50,
              },
              {
                header: translate('title.notification'),
                accessorKey: 'is_safety',
                size: 50,
                cell: (info) =>
                  info.getValue().IsSafety ? (
                    <Label
                      variant={theme === 'light' ? 'filled' : 'filled'}
                      color={'error'}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {translate('warehouse.need_safety_stock')}
                    </Label>
                  ) : (
                    ''
                  ),
              },
            ]}
          />
        </ProjectPaper>
      </Hidden>
      <Hidden lgUp>
        <ProjectPaper>
          <ReactTable
            search
            data={getTableItem(true)}
            header={[
              {
                header: 'Actions',
                accessorKey: 'actions',
                size: 50,
                cell: (info) => (
                  <>
                    <IconButton
                      component={RouterLink}
                      to={paths.general_warehouse.item_template_detail(info.getValue().Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton
                      color={'success'}
                      onClick={() => handleAddItemToCart(info.getValue())}
                    >
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
              },
              {
                header: translate('title.id'),
                accessorKey: 'id',
                size: 50,
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
                size: 200,
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
                size: 50,
              },
            ]}
          />
        </ProjectPaper>
      </Hidden>
    </React.Fragment>
  );
}

export default StoreComponent;
