import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  ManagementUserTypeId,
  SalesUserTypeId,
} from 'utilities/Constants';
import { paths } from 'routes/paths';
import { RouterLink } from 'routes/components';
import { OpenConfirmNotification } from 'utilities/Helper/NotificationHelper';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import ErrorPage from '../MainModule/ErrorPage';
import GridDetailText from 'components/customs/GridDetailText';
import { fCurrency } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import { useTheme } from '@mui/material/styles';
import ReactTable from 'components/react-table/ReactTable';
import { useBillingNote } from 'requests/query/useBillingNote';
import { OPEN_ICON } from 'config-global';

export default function BillingNoteDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const { billingNoteDetail, cancelBill, printBill } = useBillingNote(id);
  const theme = useTheme();
  const onPrintBill = async () => {
    await printBill.mutateAsync(id);
  };

  const onCancelBill = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_cancel_data'),
      'Confirm',
      '',
      async () => {
        await cancelBill.mutateAsync(id);
      }
    );
  };

  const getBillStatus = (billingNote) => {
    if (billingNote.ArchivedAt !== null) {
      return (
        <GridDetailText
          title={translate('title.status')}
          value={translate('button.cancel')}
          color={theme.palette.error.main}
        />
      );
    }

    return (
      <GridDetailText
        title={translate('title.status')}
        value={translate('accounting.already_bill')}
        color={theme.palette.success.main}
      />
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.billing_note_detail')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.search_bill'), href: paths.accounting.search },
        { name: `${translate('accounting.billing_note_detail')} : ${id}` },
      ]}
      action={
        billingNoteDetail !== null && billingNoteDetail.ArchivedAt === null ? (
          <>
            <RoleBasedGuard
              roles={[ManagementUserTypeId, AdminUserTypeId, AccountingUserTypeId, SalesUserTypeId]}
            >
              <Button
                variant="contained"
                color={'info'}
                startIcon={<Iconify icon={'ic:outline-print'} />}
                onClick={onPrintBill}
              >
                {translate('button.print')}
              </Button>
            </RoleBasedGuard>
            <RoleBasedGuard roles={[ManagementUserTypeId]}>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={onCancelBill}
              >
                {translate('button.cancel')}
              </Button>
            </RoleBasedGuard>
          </>
        ) : null
      }
    >
      {billingNoteDetail !== null ? (
        <Stack spacing={3}>
          <Card>
            <CardHeader title={translate('title.detail')} />
            <CardContent>
              <Grid container spacing={2}>
                <GridDetailText
                  title={translate('title.delivery_by')}
                  value={getEmployeeFullName(billingNoteDetail?.CreateEmployee)}
                />
                <GridDetailText
                  title={translate('title.customer')}
                  value={billingNoteDetail?.Customer.Name}
                />
                <GridDetailText
                  title={translate('title.value')}
                  value={fCurrency(billingNoteDetail?.TotalValue)}
                />
                <GridDetailText
                  title={translate('title.billing_at')}
                  value={
                    billingNoteDetail?.BillingAt !== null
                      ? fDateTimeText(billingNoteDetail.BillingAt)
                      : 'N/A'
                  }
                />
                <GridDetailText
                  title={translate('title.create_at')}
                  value={fDateTimeText(billingNoteDetail?.createdAt)}
                />
                {getBillStatus(billingNoteDetail)}
                {billingNoteDetail?.ArchivedAt !== null && (
                  <GridDetailText
                    title={translate('title.canceled_at')}
                    value={fDateTimeText(billingNoteDetail?.ArchivedAt)}
                  />
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('sales.delivery_bill')} />
            <CardContent>
              <ReactTable
                data={billingNoteDetail?.DeliveryBills.map((bill) => {
                  return {
                    id: bill.Id,
                    amount: bill.TotalValue,
                    action: bill.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                  },
                  {
                    header: translate('title.value'),
                    accessorKey: 'amount',
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'action',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        to={paths.accounting.delivery_bill_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                  },
                ]}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('sales.additional_cost_bill')} />
            <CardContent>
              <ReactTable
                data={billingNoteDetail?.ProductOrderAdditionalCostBills.map((bill) => {
                  return {
                    id: `A-${bill.Id}`,
                    amount: bill.TotalValue,
                    action: bill.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                  },
                  {
                    header: translate('title.value'),
                    accessorKey: 'amount',
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'action',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        to={paths.accounting.product_order_additional_cost_bill_detail(
                          info.getValue()
                        )}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <ErrorPage />
      )}
    </ProjectPageContainer>
  );
}
