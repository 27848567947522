import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { IconButton } from '@mui/material';
import { getAllEmployee } from '../../../requests/ProductionServerHandler';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import ReactTable from '../../react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

function EmployeeListPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { data: allEmployee } = useQuery({
    queryKey: ['getAllEmployee'],
    queryFn: getAllEmployee,
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('hr.employee_list')}
      breadcrumbs={[{ name: translate('hr.title') }, { name: translate('hr.employee_list') }]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={allEmployee.map((employee) => {
            return {
              id: employee.Id,
              name: employee,
              employee_code: employee.EmployeeCode,
              department: employee.Department.Name,
              actions: employee.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('user.employee_code'),
              accessorKey: 'employee_code',
            },
            {
              header: translate('user.department'),
              accessorKey: 'department',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton onClick={() => navigate(paths.hr.employee_detail(info.getValue()))}>
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default EmployeeListPage;
