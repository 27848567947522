import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenErrorNotification } from '../../../utilities/Helper/NotificationHelper';
import Swal from 'sweetalert2';
import { getItemTemplateDetailById } from '../../../requests/ProductionServerHandler';
import { useForm } from 'react-hook-form';
import { useLocales } from 'locales';
import { Button, IconButton, Stack } from '@mui/material';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import ProjectPaper from '../../customs/ProjectPaper';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import { useMutation } from '@tanstack/react-query';
import { usePartStockReport } from '../../../requests/query/usePartStockReport';
import { DetailScanner } from '../../barcode-reader';

export default function ReturnItemPage() {
  const [returnItems, setReturnItems] = useState([]);
  const { translate } = useLocales();
  const { returnItemsToStock } = usePartStockReport(null);
  const searchItemTemplateMutation = useMutation({
    mutationFn: getItemTemplateDetailById,
    onSuccess: async (data) => {
      const { value: amount } = await Swal.fire({
        title: translate('warehouse.amount_to_return'),
        input: 'number',
        inputLabel: `${data.Name}`,
        inputPlaceholder: translate('title.amount'),
        inputValidator: async (value) => {
          if (!value) {
            return translate('error.error_invalid_amount');
          } else {
            const amount = parseFloat(value);
            if (isNaN(amount)) {
              return translate('error.error_invalid_amount');
            } else {
              if (amount <= 0) {
                return translate('error.error_invalid_amount');
              }
            }
          }
        },
      });

      if (amount) {
        await onAddItem(amount, data);
      }
    },
    onError: async () => {
      await OpenErrorNotification('ไม่พบของ');
    },
  });

  const inventoryCheckoutSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(inventoryCheckoutSchema),
  });

  const { handleSubmit } = methods;
  const onItemBarcodeRead = async (code) => {
    await searchItemTemplateMutation.mutateAsync(code);
  };
  const onAddItem = async (amount, returnItem) => {
    setReturnItems([
      ...returnItems.filter((addItem) => addItem.Id !== returnItem.Id),
      {
        Id: returnItem.Id,
        Name: returnItem.Name,
        Amount: amount,
      },
    ]);
  };
  const onRemoveItem = (removeItem) => {
    let adjustReturnItems = returnItems.filter((item) => item.Id !== removeItem.Id);
    setReturnItems(adjustReturnItems);
  };
  const handleSubmitReturn = async (values) => {
    if (returnItems.length > 0) {
      await returnItemsToStock.mutateAsync({
        ReturnItems: returnItems,
        Detail: values.Detail,
      });
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.return_item')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.return_item') },
      ]}
    >
      <Stack spacing={3}>
        <ProjectPaper>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: '#' },
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'title.id' },
              { align: 'left', title: 'title.amount' },
              { align: 'left', title: '' },
            ]}
            tableData={returnItems.map((item, index) => {
              return [
                index + 1,
                item.Name,
                item.Id,
                item.Amount,
                [
                  <IconButton color={'error'} onClick={() => onRemoveItem(item)}>
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>,
                ],
              ];
            })}
          />
        </ProjectPaper>
        {returnItems.length > 0 && (
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitReturn)}>
              <Stack spacing={3}>
                <RHFTextField name="Detail" label={translate('title.detail')} />
                <Button variant={'contained'} fullWidth type={'submit'}>
                  {translate('button.submit')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        )}
        <DetailScanner
          onScanCallback={onItemBarcodeRead}
          textFieldLabel={translate('warehouse.item_template_id')}
        />
      </Stack>
    </ProjectPageContainer>
  );
}
