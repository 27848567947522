import React, { useMemo, useState } from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { DatePickerInterval } from 'components/date-picker';
import { getManagementSalesDashboard } from 'requests/ProductionServerHandler';
import { useLocales } from 'locales';
import ReactTable from '../../react-table/ReactTable';
import { fCurrency } from 'utilities/formatNumber';
import DetailWidget from 'components/customs/DetailWidget';
import Stack from '@mui/material/Stack';
import BarGraph from 'components/graph/BarGraph';
import { useTheme } from '@mui/material/styles';
import LineGraph from 'components/graph/LineGraph';
import { thaiMonths } from 'utilities/Constants';
import { useQuery } from '@tanstack/react-query';

function ManagementDashboardPage() {
  const { translate } = useLocales();
  const theme = useTheme();
  const [dateRange, setDateRange] = useState({
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    toDate: new Date(),
  });
  const { data: dashboardData, refetch } = useQuery({
    queryKey: ['managementSalesDashboard', dateRange],
    queryFn: () => getManagementSalesDashboard(dateRange),
  });

  const fetchDashboardDataByDate = async (fromDate, toDate) => {
    setDateRange({ fromDate, toDate });
    await refetch();
  };

  const barChartData = useMemo(() => {
    if (dashboardData !== undefined) {
      let label = [];
      let data = [];
      let totalData = 10;
      if (dashboardData.CustomerCreateOrders.length < 10) {
        totalData = dashboardData.CustomerCreateOrders.length;
      }
      for (let i = 0; i < totalData; i++) {
        label.push(dashboardData.CustomerCreateOrders[i].Name);
        data.push(dashboardData.CustomerCreateOrders[i].TotalEarn);
      }
      if (dashboardData.CustomerCreateOrders.length > 10) {
        const remain = dashboardData.CustomerCreateOrders.slice(
          10,
          dashboardData.CustomerCreateOrders.length
        ).reduce((totalValue, customer) => {
          return totalValue + customer.TotalEarn;
        }, 0);

        label.push('ที่เหลือ');
        data.push(remain);
      }

      return {
        colors: [theme.palette.primary.main],
        categories: label,
        series: [
          {
            type: 'มูลค่างานที่เปิด',
            data: [
              {
                name: 'มูลค่างานที่เปิด',
                data: data,
              },
            ],
          },
        ],
      };
    } else {
      return null;
    }
  }, [dashboardData, theme.palette.primary.main]);
  const lineChartData = useMemo(() => {
    if (dashboardData !== undefined) {
      let data = [];

      for (const [value] of Object.entries(dashboardData.SalesValueRecord)) {
        data.push(value);
      }

      return {
        categories: thaiMonths,
        series: [
          {
            year: 'Line',
            data: [
              {
                name: 'Line',
                data: data,
              },
            ],
          },
        ],
      };
    } else {
      return null;
    }
  }, [dashboardData]);
  return (
    <ProjectPageContainer menu_title={translate('dashboard')}>
      {dashboardData !== undefined && (
        <Stack spacing={2}>
          <DatePickerInterval onFetchData={fetchDashboardDataByDate} />
          <LineGraph title={translate('sales.total_sales')} chart={lineChartData} />
          <BarGraph title={translate('sales.total_sales_customer')} chart={barChartData} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <DetailWidget
                color={'success'}
                title={translate('sales.sales_value')}
                total={dashboardData.SalesData.SalesAmount}
                icon={'dashicons:money-alt'}
                isSimple
                isCurrency
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <DetailWidget
                color={'success'}
                title={translate('accounting.already_billing')}
                total={dashboardData.SalesData.ModifyBillAmount}
                icon={'dashicons:money-alt'}
                isSimple
                isCurrency
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <DetailWidget
                color={'success'}
                title={translate('accounting.accounting_sales_value')}
                total={dashboardData.SalesData.SalesAmountAcc}
                icon={'dashicons:money-alt'}
                isSimple
                isCurrency
              />
            </Grid>
          </Grid>
          <Card>
            <CardHeader title={translate('title.customer')} />
            <CardContent>
              <ReactTable
                search
                header={[
                  { align: 'left', accessorKey: 'Name', header: translate('title.name') },
                  {
                    align: 'left',
                    accessorKey: 'Value',
                    header: translate('title.value'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'PONumber',
                    header: translate('title.product_order_number'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'L',
                    header: translate('sil.p_laminate'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'UV',
                    header: translate('sil.p_uv'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'V',
                    header: translate('sil.p_vanishing'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'HS',
                    header: translate('sil.p_hot_stamping'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'HO',
                    header: translate('sil.p_hologram'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'EM',
                    header: translate('sil.p_embossing'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'CE',
                    header: translate('sil.p_calendering'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'SK',
                    header: translate('sil.p_silk_screen'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'F',
                    header: translate('sil.p_free'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'RE',
                    header: translate('sil.p_rework'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'EX',
                    header: translate('sil.p_example'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'SE',
                    header: translate('sil.p_service'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                ]}
                data={dashboardData.CustomerCreateOrders.map((customer) => {
                  return {
                    Name: customer.Name,
                    Value: customer.TotalEarn,
                    PONumber: customer.TotalOrder,
                    L: customer.Laminate,
                    UV: customer.UV,
                    V: customer.Vanishing,
                    HS: customer.HotStamping,
                    HO: customer.Hologram,
                    EM: customer.Embossing,
                    CE: customer.Calendering,
                    SK: customer.Silk,
                    F: customer.Free,
                    RE: customer.Rework,
                    EX: customer.Example,
                    SE: customer.Service,
                  };
                })}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('title.value')} />
            <CardContent>
              <ReactTable
                search
                header={[
                  {
                    header: translate('title.name'),
                    accessorKey: 'name',
                  },
                  {
                    header: translate('title.value'),
                    accessorKey: 'value',
                    cell: (info) => fCurrency(info.getValue()),
                  },
                ]}
                data={dashboardData.CustomerDetails.map((customer) => {
                  return {
                    name: customer.Name,
                    value: customer.SalesValues,
                  };
                })}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('sales.sales_per_employee')} />
            <CardContent>
              <ReactTable
                search
                header={[
                  { align: 'left', accessorKey: 'Name', header: translate('title.name') },
                  {
                    align: 'left',
                    accessorKey: 'Value',
                    header: translate('title.value'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'PONumber',
                    header: translate('title.product_order_number'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'L',
                    header: translate('sil.p_laminate'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'UV',
                    header: translate('sil.p_uv'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'V',
                    header: translate('sil.p_vanishing'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'HS',
                    header: translate('sil.p_hot_stamping'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'HO',
                    header: translate('sil.p_hologram'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'EM',
                    header: translate('sil.p_embossing'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'CE',
                    header: translate('sil.p_calendering'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'SK',
                    header: translate('sil.p_silk_screen'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'F',
                    header: translate('sil.p_free'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'RE',
                    header: translate('sil.p_rework'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'EX',
                    header: translate('sil.p_example'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    align: 'left',
                    accessorKey: 'SE',
                    header: translate('sil.p_service'),
                    cell: (info) => fCurrency(info.getValue()),
                  },
                ]}
                data={dashboardData.SalesDetails.map((sales) => {
                  return {
                    Name: sales.Name,
                    Value: sales.TotalEarn,
                    PONumber: sales.TotalOrder,
                    L: sales.Laminate,
                    UV: sales.UV,
                    V: sales.Vanishing,
                    HS: sales.HotStamping,
                    HO: sales.Hologram,
                    EM: sales.Embossing,
                    CE: sales.Calendering,
                    SK: sales.Silk,
                    F: sales.Free,
                    RE: sales.Rework,
                    EX: sales.Example,
                    SE: sales.Service,
                  };
                })}
              />
            </CardContent>
          </Card>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default ManagementDashboardPage;
