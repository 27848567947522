import CreateCustomerPage from '../../components/pages/AccountingModule/CreateCustomerPage';
import CreateBillingNotePage from '../../components/pages/AccountingModule/CreateBillingNotePage';
import SearchAccountingPage from '../../components/pages/AccountingModule/SearchAccountingPage';
import PrintDeliverySummaryReportPage from '../../components/pages/AccountingModule/PrintDeliverySummaryReportPage';
import PrintProductSellSummaryPage from '../../components/pages/AccountingModule/PrintProductSellSummaryPage';
import CreateClaimRecordPage from '../../components/pages/AccountingModule/CreateClaimRecordPage';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
} from '../../utilities/Constants';
import ArchivedCustomerPage from '../../components/pages/AccountingModule/ArchivedCustomerPage';
import DeliveryBillDetailPage from '../../components/pages/AccountingModule/DeliveryBillDetailPage';
import BillingNoteDetailPage from '../../components/pages/AccountingModule/BillingNoteDetailPage';
import { paths } from '../paths';
import ProductOrderAdditionalCostBillDetailPage from '../../components/pages/AccountingModule/ProductOrderAdditionalCostBillDetailPage';
import CreateAdditionalCostBillPage from '../../components/pages/AccountingModule/CreateAdditionalCostBillPage';

const accountingModuleRouteDetails = [
  {
    path: paths.accounting.create_customer,
    component: <CreateCustomerPage />,
    isPrivate: false,
    allowUserTypeId: [SalesUserTypeId, AccountingUserTypeId, AdminUserTypeId],
  },
  {
    path: paths.accounting.create_billing_note,
    component: <CreateBillingNotePage />,
    isPrivate: false,
    allowUserTypeId: [AccountingUserTypeId],
  },
  {
    path: paths.accounting.search,
    component: <SearchAccountingPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, AccountingUserTypeId, AdminUserTypeId],
  },
  {
    path: paths.accounting.create_claim_record,
    component: <CreateClaimRecordPage />,
    isPrivate: true,
    allowUserTypeId: [AccountingUserTypeId],
  },
  {
    path: paths.accounting.print_delivery_bill_report,
    component: <PrintDeliverySummaryReportPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, AccountingUserTypeId, AdminUserTypeId],
  },
  {
    path: paths.accounting.print_product_sell_report,
    component: <PrintProductSellSummaryPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, AccountingUserTypeId, AdminUserTypeId],
  },
  {
    path: paths.accounting.archive_customer,
    component: <ArchivedCustomerPage />,
    isPrivate: true,
    allowUserTypeId: [AccountingUserTypeId],
  },
  {
    path: paths.accounting.delivery_bill_detail(':id'),
    component: <DeliveryBillDetailPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.accounting.billing_note_detail(':id'),
    component: <BillingNoteDetailPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.accounting.product_order_additional_cost_bill_detail(':id'),
    component: <ProductOrderAdditionalCostBillDetailPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.accounting.create_product_order_additional_cost_bill,
    component: <CreateAdditionalCostBillPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
];
export default accountingModuleRouteDetails;
