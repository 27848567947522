import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { createEmployeeRecord } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from 'locales';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { useMutation } from '@tanstack/react-query';

function CreateEmployeeRecordPage() {
  const { translate } = useLocales();
  const createEmployeeRecordMutation = useMutation({
    mutationFn: createEmployeeRecord,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const createEmployeeRecordSchema = Yup.object().shape({
    EmployeeCode: Yup.string().required(translate('error.detail_require')),
    AbsentRecord: Yup.number()
      .min(1, translate('error.detail_require'))
      .required(translate('error.detail_require')),
    LeaveRecord: Yup.number()
      .min(1, translate('error.detail_require'))
      .required(translate('error.detail_require')),
    SickLeaveRecord: Yup.number()
      .min(1, translate('error.detail_require'))
      .required(translate('error.detail_require')),
    LateRecord: Yup.number()
      .min(1, translate('error.detail_require'))
      .required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(createEmployeeRecordSchema),
  });

  const { handleSubmit } = methods;

  const createRecord = async (data) => {
    await createEmployeeRecordMutation.mutateAsync({
      EmployeeCode: data.EmployeeCode,
      AbsentRecord: data.AbsentRecord,
      LeaveRecord: data.LeaveRecord,
      SickLeaveRecord: data.SickLeaveRecord,
      LateRecord: data.LateRecord,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('hr.add_employee_record')}
      breadcrumbs={[{ name: translate('hr.title') }, { name: translate('hr.add_employee_record') }]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(createRecord)}>
          <Stack spacing={3}>
            <RHFTextField name={'EmployeeCode'} label={translate('user.employee_code')} />
            <RHFTextField name={'AbsentRecord'} label={translate('hr.absent_number')} />
            <RHFTextField name={'LeaveRecord'} label={translate('hr.leave_number')} />
            <RHFTextField name={'SickLeaveRecord'} label={translate('hr.sick_leave_number')} />
            <RHFTextField name={'LateRecord'} label={translate('hr.late_number_in_min')} />
            <Button variant={'contained'} fullWidth type={'submit'}>
              {translate('button.save')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateEmployeeRecordPage;
