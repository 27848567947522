import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf',
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf',
  },
};

const PrintPDFMakeDocument = (pdfStructure, documentName) => {
  pdfMake.createPdf(pdfStructure).download(documentName);
};

const PrintMultiplePDFMakeDocument = async (pdfStructures, documentName) => {
  return await Promise.resolve(
    pdfStructures.forEach((pdf) => pdfMake.createPdf(pdf).download(documentName))
  );
};

export { PrintPDFMakeDocument, PrintMultiplePDFMakeDocument };
