import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, Grid, IconButton } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { fNumber } from '../../../utilities/formatNumber';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import { paths } from '../../../routes/paths';
import { usePlanningMachineDetail } from '../../../requests/query/usePlanningMachineDetail';
import Link from "@mui/material/Link";

const PlanningMachineQueueDetailPage = () => {
  const { translate } = useLocales();
  const { id } = useParams();
  const { machine, moveQueue, removeQueue, printPlan } = usePlanningMachineDetail(id);

  const handlePrintPlan = async () => {
    await printPlan.mutateAsync(id);
  };

  const handleMoveQueue = async (queueToMove, moveType) => {
    let queueToSwap;
    if (moveType === 1) {
      //Move Up
      queueToSwap = machine.MachineQueues.find(
        (queue) => parseInt(queue.QueueNumber) === parseInt(queueToMove.QueueNumber) - 1
      );
    } else {
      //Move Down
      queueToSwap = machine.MachineQueues.find(
        (queue) => parseInt(queue.QueueNumber) === parseInt(queueToMove.QueueNumber) + 1
      );
    }

    await moveQueue.mutateAsync({
      MachineQueueId_A: queueToMove.Id,
      MachineQueueId_B: queueToSwap.Id,
    });
  };
  const handleRemoveQueue = async (queueId) => {
    await removeQueue.mutateAsync(queueId);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('planning.machine_plan')}
      breadcrumbs={[
        { name: translate('planning.title') },
        {
          name: translate('planning.production_planning'),
          href: paths.planning.production_planning_old,
        },
        { name: `${translate('planning.machine_plan')} : ${machine !== null && machine.Name}` },
      ]}
      action={
        <Button
          variant="contained"
          color={'info'}
          startIcon={<Iconify icon={'ic:outline-print'} />}
          onClick={handlePrintPlan}
        >
          {translate('button.print_production_plan')}
        </Button>
      }
    >
      {machine !== null ? (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('planning.machine_plan')} value={machine.Name} />
              <GridDetailText
                title={translate('title.target_per_day')}
                value={fNumber(machine.ExpectedResult)}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.width' },
                { align: 'left', title: 'title.length' },
                { align: 'left', title: 'production.amount_to_production' },
                { align: 'left', title: '' },
              ]}
              tableData={machine.MachineQueues.map((queue, index) => {
                const completeAmount = queue.ProductOrder.Piles.reduce((accumulator, pile) => {
                  return accumulator + pile.GoodAmount;
                }, 0);
                return [
                  index + 1,
                  <Link href={paths.production.product_order_detail(queue.ProductOrderId)}>
                    {queue.ProductOrder.Name}
                  </Link>,
                  queue.ProductOrder.Width,
                  queue.ProductOrder.Length,
                  queue.ProductOrder.Amount - completeAmount,
                  <>
                    <IconButton
                      disabled={index === 0}
                      color={'info'}
                      onClick={() => handleMoveQueue(queue, 1)}
                    >
                      <Iconify icon={'ant-design:arrow-up-outlined'} width={20} height={20} />
                    </IconButton>
                    <IconButton
                      disabled={index === machine.MachineQueues.length - 1}
                      color={'info'}
                      onClick={() => handleMoveQueue(queue, 2)}
                    >
                      <Iconify icon={'ant-design:arrow-down-outlined'} width={20} height={20} />
                    </IconButton>
                    <IconButton color={'error'} onClick={() => handleRemoveQueue(queue.Id)}>
                      <Iconify icon={'ant-design:close-outlined'} width={20} height={20} />
                    </IconButton>
                  </>,
                ];
              })}
            />
          </ProjectPaper>
        </React.Fragment>
      ) : null}
    </ProjectPageContainer>
  );
};

export default PlanningMachineQueueDetailPage;
