import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  cancelPartPO,
  editPartPODetail,
  editPartPOReferenceId,
  editPOItemTemplate,
  getPartPODetailByID,
  getPartPOPDFFile,
  uploadApprovePartsPODocument,
} from '../ProductionServerHandler';
import {
  OpenNotificationThenCallback,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { po_prefix } from '../../utilities/Strings/Prefix';
import { useLocales } from '../../locales';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/paths';

export const usePartPurchaseOrder = (id) => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['part-purchaseOrder', id],
    queryFn: () => getPartPODetailByID(id),
    enabled: !!id,
    initialData: null,
  });

  const printPOMutation = useMutation({
    mutationFn: getPartPOPDFFile,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', async () => {
        await PrintMultiplePDFMakeDocument([response.PDF], `${po_prefix}_${id}.pdf`);
      });
    },
  });

  const cancelPOMutation = useMutation({
    mutationFn: cancelPartPO,
    onSuccess: async () => {
      await OpenSuccessNotification();
      navigate(paths.procurement.po_list);
    },
  });

  const uploadApproveMutation = useMutation({
    mutationFn: uploadApprovePartsPODocument,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-purchaseOrder', id]);
    },
  });

  const editPOItemsMutation = useMutation({
    mutationFn: editPOItemTemplate,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-purchaseOrder', id]);
    },
  });

  const editPODetailMutation = useMutation({
    mutationFn: editPartPODetail,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-purchaseOrder', id]);
    },
  });

  const editPOReferenceIdMutation = useMutation({
    mutationFn: editPartPOReferenceId,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-purchaseOrder', id]);
    },
  });

  return {
    purchaseOrder: data,
    printPO: printPOMutation,
    cancelPO: cancelPOMutation,
    uploadApprove: uploadApproveMutation,
    editPOItems: editPOItemsMutation,
    editPODetail: editPODetailMutation,
    editPOReferenceId: editPOReferenceIdMutation,
  };
};
