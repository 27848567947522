import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/lab';
import { Grid, Button } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import ProjectPaper from 'components/customs/ProjectPaper';
import { useLocales } from 'locales';
import MachineDropdown from '../customs/dropdown/MachineDropdown';

DatePickerMachine.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      callBack: PropTypes.func,
    })
  ),
  includeAllMachineOption: PropTypes.bool,
};

function DatePickerMachine({ actions, includeAllMachineOption }) {
  const { translate } = useLocales();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectMachine, setSelectMachine] = useState(null);
  const [isError, setIsError] = useState(false);

  const handleFromChange = (time) => {
    setIsError(false);
    setFromDate(time);
  };
  const handleToChange = (time) => {
    setIsError(false);
    setToDate(time);
  };
  const handleMachineChange = (selectMachine) => {
    setSelectMachine(selectMachine);
    setIsError(false);
  };

  return (
    <ProjectPaper>
      {isError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {translate('error.wrong_date_range')}
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MUIDateTimePicker
            ampm={false}
            format="dd/MM/yyyy HH:mm"
            label={translate('title.start_date')}
            value={fromDate}
            onChange={handleFromChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MUIDateTimePicker
            ampm={false}
            format="dd/MM/yyyy HH:mm"
            label={translate('title.end_date')}
            value={toDate}
            onChange={handleToChange}
            slotProps={{
              textField: {
                fullWidth: true,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MachineDropdown
            onMachineChange={handleMachineChange}
            label={translate('title.machine')}
            includeAllOptions={includeAllMachineOption}
          />
        </Grid>
        {actions.map((action, index) => {
          return (
            <Grid item key={index} xs={12} sm={12}>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={() => action.callBack(fromDate, toDate, selectMachine)}
              >
                {action.title}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </ProjectPaper>
  );
}

export default DatePickerMachine;
