import React from 'react';
import PropTypes from 'prop-types';
import { adjustPurchaseCart } from '../../../redux/reducers/inventory';
import { useLocales } from '../../../locales';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { RouterLink } from '../../../routes/components';
import Iconify from '../../minimal/iconify';
import ReactTable from '../../react-table/ReactTable';
import { showAddItemToCartInput } from '../../../utilities/Helper/NotificationHelper';
import { OPEN_ICON } from '../../../config-global';
import { paths } from '../../../routes/paths';
import Label from '../../minimal/label';

MaterialPurchaseStore.propTypes = {
  itemMasterData: PropTypes.array.isRequired,
};

function MaterialPurchaseStore({ itemMasterData }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const handleAddItemToCart = async (itemMaster) => {
    await showAddItemToCartInput(translate, itemMaster, true).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(
          adjustPurchaseCart({ Id: itemMaster.Id, Name: itemMaster.Name, Amount: result.value })
        );
      }
    });
  };

  return (
    <ReactTable
      search
      data={itemMasterData}
      header={[
        {
          header: 'Actions',
          accessorKey: 'actions',
          cell: (info) => (
            <>
              <IconButton
                component={RouterLink}
                to={paths.warehouse.item_master_detail(info.getValue().Id)}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>
              <IconButton color={'success'} onClick={() => handleAddItemToCart(info.getValue())}>
                <Iconify icon={'ant-design:shopping-cart-outlined'} width={20} height={20} />
              </IconButton>
            </>
          ),
          size: 20,
        },
        {
          header: translate('title.id'),
          accessorKey: 'id',
          size: 20,
        },
        {
          header: translate('title.name'),
          accessorKey: 'name',
          size: 200,
        },
        {
          header: translate('title.type'),
          accessorKey: 'type',
          size: 20,
        },
        {
          header: translate('title.safety_amount'),
          accessorKey: 'safety',
          size: 20,
        },
        {
          header: translate('title.amount'),
          accessorKey: 'amount',
          size: 20,
        },
        {
          header: translate('title.amount_defect'),
          accessorKey: 'defect',
          size: 20,
        },
        {
          header: translate('warehouse.average_use'),
          accessorKey: 'average_use',
          size: 20,
        },
        {
          header: translate('unit.title'),
          accessorKey: 'unit',
          size: 20,
        },
        {
          header: translate('title.notification'),
          accessorKey: 'is_safety',
          size: 20,
          cell: (info) =>
            info.getValue().IsSafety ? (
              <Label color={'error'}>{translate('warehouse.need_safety_stock')}</Label>
            ) : (
              ''
            ),
        },
      ]}
    />
  );
}

export default MaterialPurchaseStore;
