import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { Button } from '@mui/material';
import DatePickerWithTypeSelection from '../../date-picker/DatePickerWithTypeSelection';
import { useLocales } from 'locales';
import { useLoading } from '../../loading';
import { useMutation } from '@tanstack/react-query';
import { getSupplierPurchaseExcel } from '../../../requests/WarehouseServerHandler';
import { saveAs } from 'file-saver';

export default function ProcurementSupplierReportPage() {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printSupplierPurchaseExcelMutation = useMutation({
    mutationFn: getSupplierPurchaseExcel,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'supplier_transaction.xlsx');
      await OpenSuccessNotification();
    },
  });
  const onPrintExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printSupplierPurchaseExcelMutation.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.print_supplier_report_page_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.print_supplier_report_page_title') },
      ]}
    >
      <DatePickerWithTypeSelection
        onFromChange={setFromDate}
        onToChange={setToDate}
        selectionActions={
          <Button variant={'contained'} color={'primary'} fullWidth onClick={onPrintExcel}>
            {translate('button.print')}
          </Button>
        }
      />
    </ProjectPageContainer>
  );
}
