export const scanEmployeeIdBurma = 'ကျေးဇူးပြု၍ သင်၏ ၀ န်ထမ်းကဒ် (သို့) ၀ န်ထမ်း ID ကိုစကင်ဖတ်ပါ';
export const scanPileIdBurma = 'ကျေးဇူးပြု. စာရွက်စာတမ်းရဲ့ထိပ်ပိုင်းညာဖက်အပေါ်စကင်ဘားကုဒ်ကို';
export const please_scan_pile_document_title_br =
  'ကျေးဇူးပြု. စာရွက်စာတမ်းရဲ့ထိပ်ပိုင်းညာဖက်အပေါ်စကင်ဘားကုဒ်ကို';
export const please_scan_employee_id_br =
  'ကျေးဇူးပြု၍ သင်၏ ၀ န်ထမ်းကဒ် (သို့) ၀ န်ထမ်း ID ကိုစကင်ဖတ်ပါ';
export const error_not_found = 'အမှား၊ မတွေ့ပါ';
export const employee_title_br = 'ဝန်ထမ်း';
export const details_title_br = 'အသေးစိတ်';
export const production_process_title_br = 'ထုတ်လုပ်မှုလုပ်ငန်းစဉ်';
export const process_title_br = 'လုပ်ငန်းစဉ်';
export const machine_title_br = 'စက်';
export const status_title_br = 'အခြေအနေ';
export const submit_detail_title_br = 'အသေးစိတ်တင်ပြပါ';
export const good_amount_title_br = 'ကောင်းသောပမာဏ';
export const defect_amount_title_br = 'အပြစ်အနာအဆာငွေပမာဏ';
export const submit_title_br = 'တင်ပြသည်';
