import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import useAuth from '../../auth/hooks/useAuth';
import { getEmployeeFullName } from '../../utilities/Helper/UtilitiesHelper';
import { Typography } from '@mui/material';
import { DetailScanner, EmployeeScanner } from '../barcode-reader';
import { ManagementUserTypeId } from '../../utilities/Constants';

AcceptItemReceiptForm.propTypes = {
  handleFindReport: PropTypes.func.isRequired,
};

function AcceptItemReceiptForm({ handleFindReport }) {
  const { translate } = useLocales();
  const { user } = useAuth();

  const [submitEmployee, setSubmitEmployee] = useState(
    user !== null
      ? {
          Id: user.EmployeeId,
          Name: user.Name,
          Department: user.Department,
          EmployeeCode: user.EmployeeCode,
          IsTemp: false,
        }
      : null
  );

  const handleGetEmployeeDetail = (employeeDetail) => {
    setSubmitEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };

  const findReportWithId = async (id) => {
    handleFindReport(id, submitEmployee.Id, submitEmployee.IsTemp);
  };

  const onScan = async (scan) => {
    const dataJson = JSON.parse(scan);
    if (dataJson.ReportId !== undefined) {
      await findReportWithId(dataJson.ReportId);
    }
  };

  return (
    <>
      {submitEmployee !== null ? (
        <DetailScanner
          onScanCallback={onScan}
          onEnterTextField={findReportWithId}
          isShowTooltips
          placeholderTexts={
            <>
              <Typography variant={'h2'}>
                {translate('placeholder.please_scan_stock_report_id')}
              </Typography>
            </>
          }
          textFieldLabel={translate('warehouse.stock_report_id')}
        />
      ) : (
        <EmployeeScanner
          isPreFetchData
          isShowTooltips
          disableInput={user === null || user.UserTypeId !== ManagementUserTypeId}
          onGetEmployeeDetail={handleGetEmployeeDetail}
        />
      )}
    </>
  );
}

export default AcceptItemReceiptForm;
