import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  editCustomerName,
  getCustomerAllProductPricing,
  getCustomerDetailById,
  getCustomerFinanceDetailById,
  getCustomerPODetailByDate,
} from '../ProductionServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useCustomerDetail = (id) => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['customer-detail', id],
    queryFn: () => getCustomerDetailById(id),
  });

  const editCustomerNameMutation = useMutation({
    mutationFn: editCustomerName,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['customer-detail', id]);
      await OpenSuccessNotification();
    },
  });

  return {
    customerDetail: data,
    editCustomer: editCustomerNameMutation,
  };
};

export const useCustomerProductOrders = (id, date) => {
  const { data, refetch } = useQuery({
    queryKey: ['customer-po-detail', id, date],
    queryFn: () => getCustomerPODetailByDate(id, date),
    initialData: [],
  });

  return {
    customerProductOrders: data,
    refetch,
  };
};

export const useCustomerFinanceDetail = (id) => {
  const { data } = useQuery({
    queryKey: ['customer-finance-detail', id],
    queryFn: () => getCustomerFinanceDetailById(id),
    initialData: null,
  });

  return {
    customerFinanceDetail: data,
  };
};

export const useCustomerPricing = (id) => {
  const { data } = useQuery({
    queryKey: ['customer-pricing-details', id],
    queryFn: () => getCustomerAllProductPricing(id),
    initialData: [],
  });

  return {
    customerPricingDetail: data,
  };
};
