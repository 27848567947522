import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { adjustPurchaseCart, adjustRequestCart } from '../../redux/reducers/inventory';
import Hidden from '@mui/material/Hidden';
import { RouterLink } from '../../routes/components';
import { IconButton } from '@mui/material';
import Iconify from '../minimal/iconify';
import ReactTable from '../react-table/ReactTable';
import { useLocales } from '../../locales';
import ProjectPaper from './ProjectPaper';
import { getItemMasterItemsByCategory } from '../../requests/WarehouseServerHandler';
import { OPEN_ICON } from '../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../routes/paths';
import Label from '../minimal/label';

MaterialStoreComponent.PropType = {
  category_id: PropTypes.number.isRequired,
  is_request: PropTypes.bool.isRequired,
};

function MaterialStoreComponent({ category_id, is_request }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();

  const { data: allItemMaster } = useQuery({
    queryKey: ['itemMaster', 'all'],
    queryFn: () => getItemMasterItemsByCategory(category_id),
    enabled: category_id !== undefined,
    initialData: [],
  });

  const isNeedSafety = (safetyAmount, remainAmount) => {
    if (safetyAmount > 0) {
      return remainAmount <= safetyAmount;
    } else {
      return false;
    }
  };
  const handleAddItemToCart = async (itemMaster) => {
    await Swal.fire({
      title: is_request
        ? translate('warehouse.request_item_amount_title')
        : translate('warehouse.request_buy_item_amount_title'),
      input: 'text',
      inputLabel: `${itemMaster.Name} ${translate('warehouse.have')} ${itemMaster.RemainAmount} ${
        itemMaster.ItemUnit
      } ในคลัง`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('button.confirm'),
      cancelButtonText: translate('button.cancel'),
      inputValidator: async (value) => {
        if (!value) {
          return translate('error.error_invalid_amount');
        } else {
          const amount = parseFloat(value);
          if (isNaN(amount)) {
            return translate('error.error_invalid_amount');
          } else {
            if (amount <= 0) {
              return translate('error.error_invalid_amount');
            } else {
              if (is_request && amount > itemMaster.RemainAmount) {
                return translate('error.insufficient_amount');
              }
            }
          }
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (is_request) {
          dispatch(
            adjustRequestCart({ Id: itemMaster.Id, Name: itemMaster.Name, Amount: result.value })
          );
        } else {
          dispatch(
            adjustPurchaseCart({ Id: itemMaster.Id, Name: itemMaster.Name, Amount: result.value })
          );
        }
      }
    });
  };
  const getTableItem = (isMobile) => {
    if (isMobile) {
      return allItemMaster.map((itemMaster) => {
        return {
          id: itemMaster.Id,
          name: itemMaster.Name,
          amount: itemMaster.CurrentAmount,
          actions: itemMaster,
        };
      });
    }
    return allItemMaster.map((itemMaster) => {
      return {
        id: itemMaster.Id,
        name: itemMaster.Name,
        type: itemMaster.ItemType,
        safety: itemMaster.SafetyAmount,
        amount: itemMaster.RemainAmount,
        defect: itemMaster.DefectAmount,
        unit: itemMaster.ItemUnit,
        average_use: itemMaster.AverageUsage,
        is_safety: {
          ItemId: itemMaster.Id,
          IsSafety: isNeedSafety(itemMaster.SafetyAmount, itemMaster.RemainAmount),
        },
        actions: itemMaster,
      };
    });
  };

  return (
    <React.Fragment>
      <Hidden lgDown>
        <ProjectPaper>
          <ReactTable
            search
            defaultPageSize={25}
            data={getTableItem(false)}
            header={[
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <IconButton
                      component={RouterLink}
                      to={paths.warehouse.item_master_detail(info.getValue().Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton
                      color={'success'}
                      onClick={() => handleAddItemToCart(info.getValue())}
                    >
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
                size: 20,
              },
              {
                header: translate('title.id'),
                accessorKey: 'id',
                size: 20,
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
                size: 200,
              },
              {
                header: translate('title.type'),
                accessorKey: 'type',
                size: 20,
              },
              {
                header: translate('title.safety_amount'),
                accessorKey: 'safety',
                size: 20,
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
                size: 20,
              },
              {
                header: translate('title.amount_defect'),
                accessorKey: 'defect',
                size: 20,
              },
              {
                header: translate('warehouse.average_use'),
                accessorKey: 'average_use',
                size: 50,
              },
              {
                header: translate('unit.title'),
                accessorKey: 'unit',
                size: 50,
              },
              {
                header: translate('title.notification'),
                accessorKey: 'is_safety',
                size: 50,
                cell: (info) =>
                  info.getValue().IsSafety ? (
                    <Label color={'error'}>{translate('warehouse.need_safety_stock')}</Label>
                  ) : (
                    ''
                  ),
              },
            ]}
          />
        </ProjectPaper>
      </Hidden>
      <Hidden lgUp>
        <ProjectPaper>
          <ReactTable
            search
            data={getTableItem(true)}
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <IconButton
                      component={RouterLink}
                      to={paths.warehouse.item_master_detail(info.getValue().Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton onClick={() => handleAddItemToCart(info.getValue())}>
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </ProjectPaper>
      </Hidden>
    </React.Fragment>
  );
}

export default MaterialStoreComponent;
