import React from 'react';
import {useRoutes, Navigate} from "react-router-dom";
import {ManagementUserTypeId} from "../utilities/Constants";
import routeDetails from "./routeDetails";
import AuthGuard from "auth/guard/AuthGuard";
import useAuth from "../auth/hooks/useAuth";
import ErrorPage from "../components/pages/MainModule/ErrorPage";
import {paths} from "./paths";

export const checkAllowPath = (allowRule, userTypeId) => {
    if (userTypeId === ManagementUserTypeId) {
        return true
    }
    if (allowRule !== undefined) {
        return allowRule.includes(userTypeId);
    } else {
        return true
    }
};

function MainRouter() {
    const {user, isInitialized, isAuthenticated} = useAuth();
    const routing = useRoutes([...routeDetails.map((route, index) => {
        let path = route.path;
        if (route.isPrivate) {
            const isAllowed = isAuthenticated && checkAllowPath(route.allowUserTypeId, user.UserTypeId)
            return {path: path,
                element: isAuthenticated && isAllowed ? <AuthGuard>{route.component}</AuthGuard> :
                    <Navigate to={paths.login}/>
            }
        }
        return {path: path, element: route.component}
    }), {path: '*', element: <ErrorPage/>}]);

    return (
        <>
            {isInitialized && routing}
        </>
    )
}

export default MainRouter;
