import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouterLink } from '../../../routes/components';
import { useLocales } from 'locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Typography from '@mui/material/Typography';
import { IconButton, Stack } from '@mui/material';
import { searchProductOrderByName } from '../../../requests/ProductionServerHandler';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import { fDateTimeText } from '../../../utilities/formatTime';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';

function SearchProductOrderResultPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { data: searchResult, refetch } = useQuery({
    queryKey: ['searchProductOrderByName', search],
    queryFn: () => searchProductOrderByName(search),
    enabled: !!search,
    initialData: [],
  });

  const searchType = [
    { value: 1, Name: translate('production.search_product_order') },
    { value: 2, Name: translate('production.search_pile') },
    { value: 3, Name: translate('production.search_by_name') },
  ];

  const onSearch = async (searchString, type) => {
    if (type === 1) {
      let searchId = searchString;
      if (searchString.includes('PileId')) {
        const dataJson = JSON.parse(searchString);
        searchId = dataJson.PileId;
      }
      navigate(paths.production.pile_detail(searchId));
    } else if (type === 2) {
      let searchId = searchString;
      if (searchString.includes('OrderId')) {
        const dataJson = JSON.parse(searchString);
        searchId = dataJson.OrderId;
      }
      navigate(paths.production.product_order_detail(searchId));
    } else if (type === 3) {
      setSearchParams({ search: searchString });
      await refetch();
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[{ name: translate('production.title') }, { name: translate('button.search') }]}
    >
      <Stack spacing={3}>
        <React.Fragment>
          <SearchBarWithSelectType
            search_label={translate('button.search')}
            select_options={searchType}
            onSearchCallBack={onSearch}
          />
        </React.Fragment>
        <Typography variant={'h4'}>{`${translate('title.search_result')} : ${search}`}</Typography>
        <Typography variant={'h4'} color={'error'}>
          {translate('warning.search_po_result_warning')}
        </Typography>
        {searchResult.length > 0 && (
          <ProjectPaper>
            <Stack spacing={3}>
              <ReactTable
                search
                defaultPageSize={25}
                data={searchResult.map((data) => {
                  return {
                    id: data.Id,
                    name: data.Name,
                    createdAt: data.createdAt,
                    customer: data.Customer.Name,
                    actions: data.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                  },
                  {
                    header: translate('title.name'),
                    accessorKey: 'name',
                  },
                  {
                    header: translate('title.create_at'),
                    accessorKey: 'createdAt',
                    cell: (info) => fDateTimeText(info.getValue()),
                  },
                  {
                    header: translate('title.customer'),
                    accessorKey: 'customer',
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'actions',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        href={paths.production.product_order_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                  },
                ]}
              />
            </Stack>
          </ProjectPaper>
        )}
      </Stack>
    </ProjectPageContainer>
  );
}

export default SearchProductOrderResultPage;
