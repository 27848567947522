import React, {useState} from 'react';
import {useLocales} from 'locales';
import {ProjectPageContainer} from 'components/container/ProjectPageContainer';
import {useNavigate, useParams} from 'react-router-dom';
import {Card, CardContent, CardHeader, Grid, Stack, Button, Typography} from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import {fDateTimeText} from 'utilities/formatTime';
import {fCurrency, fNumber, fPercent} from 'utilities/formatNumber';
import {
    ManagementUserTypeId,
    ProductionControlUserTypeId,
    SalesUserTypeId,
} from 'utilities/Constants';
import ProjectPaper from 'components/customs/ProjectPaper';
import Iconify from 'components/minimal/iconify';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import ReactTable from 'components/react-table/ReactTable';
import {OpenConfirmNotification} from 'utilities/Helper/NotificationHelper';
import {getEmployeeFullName} from 'utilities/Helper/UtilitiesHelper';
import CreateProductOrderFromQuotationFrom from '../../forms/CreateProductOrderFromQuotationForm';
import {useProductOrderQuotation} from 'requests/query/useProductOrderQuotation';
import {paths} from 'routes/paths';
import {calculateProductPerSheet} from "../../../utilities/Helper/DataHelper";

function ProductOrderQuotationDetailPage() {
    const {translate} = useLocales();
    const {id} = useParams();
    const {
        productOrderQuotationDetail,
        printProductOrderQuotation,
        approveProductOrderQuotation,
        deleteProductOrderQuotation,
        createProductOrder,
    } = useProductOrderQuotation(id);

    const [isCreatePO, setIsCreatePO] = useState(false);
    const navigate = useNavigate();
    const calculateProductOrderValue = (amount, products) =>
        products.reduce((totalValue, productOrderQuotationHasProduct) => {
            if (productOrderQuotationHasProduct.IsBundle) {
                totalValue += productOrderQuotationHasProduct.BundlePrice;
            } else {
                totalValue += amount * productOrderQuotationHasProduct.Price;
            }
            return totalValue;
        }, 0);
    const onApprovePressed = async () => {
        await OpenConfirmNotification(
            translate('warning.confirm_approve_pricing'),
            translate('button.confirm'),
            '',
            async () => {
                await approveProductOrderQuotation.mutateAsync(id);
            }
        );
    };
    const onCancelPressed = async () => {
        await OpenConfirmNotification(
            translate('warning.confirm_delete_pricing'),
            translate('button.confirm'),
            '',
            async () => {
                await deleteProductOrderQuotation.mutateAsync(id);
                navigate(paths.sales.customer_detail(productOrderQuotationDetail.Customer.Id));
            }
        );
    };
    const onPrintPressed = async () => {
        await printProductOrderQuotation.mutateAsync(id);
    };
    const onCreatePOPressed = () => {
        setIsCreatePO(true);
    };
    const onConvertToProductOrderPressed = async (values) => {
        await OpenConfirmNotification(
            translate('warning.confirm_create_po_from_quotation'),
            translate('button.confirm'),
            '',
            async () => {
                await createProductOrder.mutateAsync({
                    POQId: id,
                    Name: values.Name,
                    Detail: values.Detail,
                    Amount: values.Amount,
                    DueDatedAt: values.DueDatedAt,
                    ProductOrderSubTypeId: values.ProductOrderSubType.Id,
                });
                setIsCreatePO(false);
            }
        );
    };
    const getDifferencePercent = (amount, isBundle, bundlePrice, base, to_sell) => {
        let percent = 0;
        if (isBundle) {
            const real = amount * base;
            percent = ((bundlePrice - real) / real) * 100;
        } else {
            percent = ((to_sell - base) / base) * 100;
        }

        if (percent < 0) {
            return (
                <Typography variant="body1" sx={{color: 'error.main'}}>
                    {fPercent(percent)}
                </Typography>
            );
        }
        if (percent === 0) {
            return (
                <Typography variant="body1" sx={{color: 'warning.main'}}>
                    {fPercent(percent)}
                </Typography>
            );
        } else {
            return (
                <Typography variant="body1" sx={{color: 'success.main'}}>
                    {fPercent(percent)}
                </Typography>
            );
        }
    };

    return (
        <ProjectPageContainer
            menu_title={translate('sales.customer_po_quotation')}
            breadcrumbs={[
                {name: translate('sales.title')},
                {name: translate('sales.customer_list'), href: paths.sales.customer_list},
                {
                    name: `${translate('sales.customer_detail')} : ${
                        productOrderQuotationDetail !== null ? productOrderQuotationDetail.Customer.Name : 0
                    }`,
                    href: paths.sales.customer_detail(
                        productOrderQuotationDetail !== null ? productOrderQuotationDetail.Customer.Id : 0
                    ),
                },
                {name: translate('sales.wait_quotation_list'), href: paths.sales.active_quotation_list},
                {name: `${translate('sales.customer_po_quotation')} : ${id}`},
            ]}
            action={
                <>
                    {
                        productOrderQuotationDetail !== null ?
                            productOrderQuotationDetail.ArchivedAt !== null ? null : <>
                                <RoleBasedGuard roles={[ManagementUserTypeId, SalesUserTypeId]}>
                                    {productOrderQuotationDetail.ProductOrderId === null ? (
                                        <>
                                            <Button
                                                sx={{ml: 2}}
                                                variant="contained"
                                                color={'error'}
                                                startIcon={<Iconify icon={'eva:close-fill'}/>}
                                                onClick={onCancelPressed}
                                            >
                                                {translate('button.cancel')}
                                            </Button>
                                            <Button
                                                sx={{ml: 2}}
                                                variant="contained"
                                                color={'info'}
                                                startIcon={<Iconify icon={'ic:outline-print'}/>}
                                                onClick={onPrintPressed}
                                            >
                                                {translate('button.print')}
                                            </Button>
                                        </>
                                    ) : null}
                                </RoleBasedGuard>
                                <RoleBasedGuard
                                    roles={[ManagementUserTypeId, SalesUserTypeId, ProductionControlUserTypeId]}
                                >
                                    {productOrderQuotationDetail.ApprovedEmployeeId !== null &&
                                    productOrderQuotationDetail.ProductOrderId === null ? (
                                        <Button
                                            sx={{ml: 2}}
                                            variant="contained"
                                            color={'primary'}
                                            startIcon={<Iconify icon={'material-symbols:add'}/>}
                                            onClick={onCreatePOPressed}
                                        >
                                            {translate('sales.create_po_from_quotation')}
                                        </Button>
                                    ) : null}
                                </RoleBasedGuard></>
                            : null
                    }
                </>
            }
        >
            {productOrderQuotationDetail !== null ? (
                isCreatePO ? (
                    <CreateProductOrderFromQuotationFrom
                        productOrderQuotationDetail={{
                            Name: productOrderQuotationDetail.Name,
                            Detail: productOrderQuotationDetail.Detail,
                            Amount: productOrderQuotationDetail.Amount,
                        }}
                        onBackPress={() => setIsCreatePO(false)}
                        onSubmitProductOrderDetail={onConvertToProductOrderPressed}
                    />
                ) : (
                    <Stack spacing={2}>
                        <Card>
                            <CardHeader title={translate('title.detail')}/>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <GridDetailText
                                        title={translate('title.name')}
                                        value={productOrderQuotationDetail.Name}
                                    />
                                    <GridDetailText
                                        title={translate('title.detail')}
                                        value={productOrderQuotationDetail.Detail}
                                    />
                                    <GridDetailText
                                        title={translate('title.width')}
                                        value={productOrderQuotationDetail.Width}
                                    />
                                    <GridDetailText
                                        title={translate('title.length')}
                                        value={productOrderQuotationDetail.Length}
                                    />
                                    <GridDetailText
                                        title={translate('title.amount')}
                                        value={productOrderQuotationDetail.Amount}
                                    />
                                    <GridDetailText
                                        title={translate('title.expire_at')}
                                        value={fDateTimeText(productOrderQuotationDetail.ExpiredAt)}
                                    />
                                    <GridDetailText
                                        title={translate('title.customer')}
                                        value={productOrderQuotationDetail.Customer.Name}
                                    />
                                    <GridDetailText
                                        title={translate('title.value')}
                                        value={fCurrency(
                                            calculateProductOrderValue(
                                                productOrderQuotationDetail.Amount,
                                                productOrderQuotationDetail.ProductOrderQuotationHasProducts
                                            )
                                        )}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title={translate('title.approve_status')}/>
                            <CardContent>
                                <Grid container spacing={3}>
                                    {
                                        productOrderQuotationDetail.ArchivedAt !== null ?
                                            <GridDetailText
                                                title={translate('title.status')}
                                                color={'error.main'}
                                                value={translate('status.cancel')}
                                            />
                                            :
                                            <GridDetailText
                                                title={translate('title.status')}
                                                color={
                                                    productOrderQuotationDetail.ApprovedEmployeeId !== null
                                                        ? 'success.main'
                                                        : 'warning.main'
                                                }
                                                value={
                                                    productOrderQuotationDetail.ApprovedEmployeeId !== null
                                                        ? translate('status.approve')
                                                        : translate('status.wait_for_approve')
                                                }
                                            />
                                    }
                                    <GridDetailText
                                        title={translate('title.approve_by')}
                                        value={
                                            productOrderQuotationDetail.ApprovedEmployeeId !== null
                                                ? getEmployeeFullName(productOrderQuotationDetail.ApprovedEmployee)
                                                : '-'
                                        }
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title={translate('production.product_order_detail')}/>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <GridDetailText
                                        title={translate('title.status')}
                                        color={
                                            productOrderQuotationDetail.ProductOrderId !== null
                                                ? 'success.main'
                                                : 'error.main'
                                        }
                                        value={
                                            productOrderQuotationDetail.ProductOrderId !== null
                                                ? translate('status.already_create')
                                                : translate('status.not_create')
                                        }
                                    />
                                    {productOrderQuotationDetail.ProductOrderId !== null ? (
                                        <GridDetailText
                                            title={translate('title.detail')}
                                            link={paths.production.product_order_detail(
                                                productOrderQuotationDetail.ProductOrderId
                                            )}
                                            value={productOrderQuotationDetail.ProductOrder.Name}
                                        />
                                    ) : (
                                        <GridDetailText title={translate('title.detail')} value={'-'}/>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardHeader title={'การคำนวนราคา'}/>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <GridDetailText
                                        title={'พื้นที่'}
                                        value={`กว้าง ${productOrderQuotationDetail.Width} นิ้ว x ยาว ${productOrderQuotationDetail.Length} นิ้ว = ${fNumber(productOrderQuotationDetail.Width*productOrderQuotationDetail.Length)} ตร.นิ้ว`}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                <ReactTable
                                    data={productOrderQuotationDetail.ProductOrderQuotationHasProducts.sort((a, b) => a.ProcessNumber - b.ProcessNumber).map((productOrderQuotationHasProduct) => {
                                        return {
                                            index: productOrderQuotationHasProduct.ProcessNumber+1,
                                            id: productOrderQuotationHasProduct.Product.Id,
                                            name: productOrderQuotationHasProduct.Product.Name,
                                            base_price: calculateProductPerSheet(productOrderQuotationDetail.Width, productOrderQuotationDetail.Length, productOrderQuotationHasProduct.Product.PricePerSquareInch),
                                            base_price_per_sq: productOrderQuotationHasProduct.Product.PricePerSquareInch,
                                            diff: {
                                                IsBundle: productOrderQuotationHasProduct.IsBundle,
                                                BundlePrice: productOrderQuotationHasProduct.BundlePrice,
                                                Price: productOrderQuotationHasProduct.Price,
                                                BasePrice: productOrderQuotationHasProduct.Product.BasePrice,
                                                PricePerSquareInch: productOrderQuotationHasProduct.Product.PricePerSquareInch,
                                            },
                                            price: productOrderQuotationHasProduct.IsBundle
                                                ? productOrderQuotationHasProduct.BundlePrice
                                                : productOrderQuotationHasProduct.Price,
                                            total: productOrderQuotationHasProduct.IsBundle
                                                ? productOrderQuotationHasProduct.BundlePrice
                                                : productOrderQuotationHasProduct.Price *
                                                productOrderQuotationDetail.Amount,
                                        };
                                    })}
                                    header={[
                                        {
                                            header: translate('#'),
                                            accessorKey: 'index',
                                            size: 20,
                                        },
                                        {
                                            header: translate('title.id'),
                                            accessorKey: 'id',
                                            size: 20,
                                        },
                                        {
                                            header: translate('title.name'),
                                            accessorKey: 'name',
                                            size: 20,
                                        },
                                        {
                                            header: `ราคาต่อตารางนิ้ว`,
                                            accessorKey: 'base_price_per_sq',
                                            size: 20,
                                        },
                                        {
                                            header: `${translate('sales.middle_price')} ต่อแผ่น`,
                                            accessorKey: 'base_price',
                                            size: 20,
                                            cell: (info) => fCurrency(info.getValue()),
                                        },
                                        {
                                            header: translate('sales.sell_price'),
                                            accessorKey: 'price',
                                            size: 20,
                                            cell: (info) => fCurrency(info.getValue()),
                                        },
                                        {
                                            header: translate('sales.difference_percent'),
                                            accessorKey: 'diff',
                                            size: 20,
                                            cell: (info) =>
                                                getDifferencePercent(
                                                    productOrderQuotationDetail.Amount,
                                                    info.getValue().IsBundle,
                                                    info.getValue().BundlePrice,
                                                    calculateProductPerSheet(productOrderQuotationDetail.Width, productOrderQuotationDetail.Length, info.getValue().PricePerSquareInch),
                                                    info.getValue().Price
                                                ),
                                        },
                                        {
                                            header: translate('procurement.total_price_title'),
                                            accessorKey: 'total',
                                            size: 20,
                                            cell: (info) => fCurrency(info.getValue()),
                                        },
                                    ]}
                                />
                            </CardContent>
                        </Card>
                        <RoleBasedGuard roles={[ManagementUserTypeId]}>
                            {productOrderQuotationDetail.ApprovedEmployeeId === null ? (
                                <ProjectPaper>
                                    <Stack spacing={2} direction="row">
                                        <Button
                                            variant={'contained'}
                                            color={'success'}
                                            fullWidth
                                            startIcon={<Iconify icon={'eva:check-fill'}/>}
                                            onClick={onApprovePressed}
                                        >
                                            {translate('title.approve')}
                                        </Button>
                                        <Button
                                            variant={'contained'}
                                            color={'error'}
                                            fullWidth
                                            startIcon={<Iconify icon={'eva:close-fill'}/>}
                                            onClick={onCancelPressed}
                                        >
                                            {translate('button.cancel')}
                                        </Button>
                                    </Stack>
                                </ProjectPaper>
                            ) : null}
                        </RoleBasedGuard>
                    </Stack>
                )
            ) : null}
        </ProjectPageContainer>
    );
}

export default ProductOrderQuotationDetailPage;
