import React, { useState } from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { changeProductSellHistoryPrice } from '../../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { changePriceSchema } from '../../forms/FormSchema';
import { ProductOrderScanner } from '../../barcode-reader';
import { useMutation } from '@tanstack/react-query';

function ChangeProductOrderPricePage() {
  const { translate } = useLocales();
  const [editProductOrder, setEditProductOrder] = useState(null);

  const handleFindProductOrder = (productOrder) => {
    setEditProductOrder(productOrder);
  };

  const methods = useForm({
    resolver: yupResolver(changePriceSchema(translate)),
  });

  const { handleSubmit, control } = methods;

  const selectProductSellHistoryWatch = useWatch({
    control,
    name: 'SelectProductSellHistory',
    defaultValue: null,
  });

  const changeProductSellPriceMutation = useMutation({
    mutationFn: changeProductSellHistoryPrice,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const changeProductSellPrice = async (values) => {
    if (editProductOrder !== null) {
      await changeProductSellPriceMutation.mutateAsync({
        DeliveryBillId: null,
        ProductSellHistoryId: values.SelectProductSellHistory.Id,
        NewPrice: values.NewPrice,
      });
    } else {
      await OpenErrorNotification('Please Select Delivery Bill Id');
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_sell_price_po_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.edit_sell_price_po_title') },
      ]}
    >
      {editProductOrder !== null ? (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={editProductOrder.Id} />
              <GridDetailText title={translate('title.name')} value={editProductOrder.Name} />
              {selectProductSellHistoryWatch !== null ? (
                <GridDetailText
                  title={translate('accounting.current_sell_price')}
                  value={selectProductSellHistoryWatch.SellPrice}
                />
              ) : null}
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(changeProductSellPrice)}>
              <Stack spacing={3}>
                <RHFAutoCompleteShort
                  name={'SelectProductSellHistory'}
                  label={translate('title.process')}
                  data={editProductOrder.ProductSellHistories.map((priceDetail) => {
                    return {
                      Id: priceDetail.Id,
                      Name: priceDetail.Product.Name,
                      SellPrice: priceDetail.SellPrice,
                    };
                  })}
                />
                <RHFTextField name={'NewPrice'} label={translate('title.price')} />
                <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
                  {translate('button.save')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </Stack>
      ) : (
        <ProductOrderScanner isPreFetchData onSearchCallBack={handleFindProductOrder} />
      )}
    </ProjectPageContainer>
  );
}

export default ChangeProductOrderPricePage;
