import React from 'react';
import { paths } from '../../../routes/paths';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from '../../../locales';
import CreateBOMForm from '../../forms/CreateBOMForm';
import { useLoadingMutation } from '../../../requests/utility';
import { createBOM } from '../../../requests/WarehouseServerHandler';
import { OpenConfirmNotification, OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';

function CreateBomPage() {
  const { translate } = useLocales();
  const createBOMMutation = useLoadingMutation({
    mutationFn: createBOM,
    onSuccess: async (createBOMId) => {
      await OpenNotificationThenReloadPage(`คุณสร้าง BOM รหัส : ${createBOMId}`);
    },
  });
  const onCreateBOM = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_bom'),
      translate('button.confirm'),
      '',
      async () => {
        await createBOMMutation.mutateAsync({
          Name: values.Name,
          Details: values.Details !== '' ? values.Details : 'ไม่มี',
          BOMTypeId: values.BOMType.Id,
          Materials: values.Materials.map((material) => {
            return {
              Id: material.Material.Id,
              AmountPerSquareMeter: material.AmountPerSquareMeter,
            };
          }),
        });
      }
    );

  };
  return (
    <ProjectPageContainer
      menu_title={translate('product.create_bom')}
      breadcrumbs={[
        { name: translate('product.title') },
        { name: translate('product.bom_list'), href: paths.product.product_bom_list },
        { name: translate('product.create_bom') },
      ]}
    >
      <CreateBOMForm onSubmitCallback={onCreateBOM} />
    </ProjectPageContainer>
  );
}

export default CreateBomPage;