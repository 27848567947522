import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Iconify from '../../minimal/iconify';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import thLocale from 'date-fns/locale/th';
import { useDispatch, useSelector } from 'react-redux';
import { useLocales } from '../../../locales';
import UnPlanProductOrderList from './UnPlanProductOrderList';
import PrePlanProductOrderList from './PrePlanProductOrderList';
import MachinePlanningList from './MachinePlanningList';
import {
  selectInMachineAmount,
  selectPlanningStatus,
  toggleForceRefreshUnPlanPO,
  toggleMachineList,
  toggleRefreshInMachinePlan,
  toggleRefreshPlanningCard,
} from '../../../redux/reducers/planning';

function TodayPlanning() {
  const currentDate = new Date();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const planningStatus = useSelector(selectPlanningStatus);
  const inMachineAmount = useSelector(selectInMachineAmount);
  const [selectState, setSelectState] = useState(0);

  const [prePlanAmount, setPrePlanAmount] = useState(0);

  const handleToggleStateChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      if (newAlignment === 0) {
        dispatch(toggleRefreshPlanningCard(true));
        dispatch(toggleForceRefreshUnPlanPO(true));
      } else {
        dispatch(toggleRefreshPlanningCard(true));
        dispatch(toggleRefreshInMachinePlan(true));
        dispatch(toggleMachineList(true));
      }
      setSelectState(newAlignment);
    }
  };

  useEffect(() => {
    dispatch(toggleRefreshPlanningCard(true));
    dispatch(toggleForceRefreshUnPlanPO(true));
  }, [dispatch]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <Grid item xs={12}>
          <Stack spacing={2} direction="row">
            <Iconify icon={'ant-design:calendar-outlined'} width={30} height={30} />
            <Typography variant={'h5'}>
              {format(currentDate, 'PPP', { locale: thLocale })}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            sx={{ mt: 4, mb: 4 }}
            spacing={2}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography variant={'body2'} textAlign="center" color={'#1b68a8'}>{`${
              planningStatus.allPO
            } ${translate('planning.all_work')} `}</Typography>
            <Typography variant={'body2'} textAlign="center" color={'#bf572b'}>{`${
              planningStatus.notComplete
            } ${translate('planning.all_un_finish_work')} `}</Typography>
            <Typography variant={'body2'} textAlign="center" color={'#2eb8a4'}>{`${
              planningStatus.complete
            } ${translate('planning.all_finish_work')} `}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={selectState}
              exclusive
              onChange={handleToggleStateChange}
              aria-label="Platform"
            >
              <ToggleButton value={0}>{`${translate(
                'planning.pre_plan'
              )} (${prePlanAmount})`}</ToggleButton>
              <ToggleButton value={1}>{`${translate(
                'planning.in_machine_plan'
              )} (${inMachineAmount})`}</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {selectState === 0 ? (
            <PrePlanProductOrderList onSetAmountPrePlan={setPrePlanAmount} />
          ) : (
            <MachinePlanningList />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        {selectState === 0 ? (
          <UnPlanProductOrderList />
        ) : (
          <PrePlanProductOrderList onSetAmountPrePlan={setPrePlanAmount} isVertical />
        )}
      </Grid>
    </Grid>
  );
}

export default TodayPlanning;
