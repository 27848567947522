import React from 'react';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import ProjectPaper from '../customs/ProjectPaper';
import PropTypes from 'prop-types';

CompleteMaintenanceJobForm.propTypes = {
  onCompleteJobCallback: PropTypes.func.isRequired,
};

function CompleteMaintenanceJobForm({ onCompleteJobCallback }) {
  const { translate } = useLocales();
  const completeJobSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    HourMeter: Yup.number().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(completeJobSchema),
  });

  const { handleSubmit } = methods;

  const onComplete = async (data) => {
    onCompleteJobCallback(data);
  };

  return (
    <ProjectPaper>
      <FormProvider methods={methods} onSubmit={handleSubmit(onComplete)}>
        <Stack spacing={3}>
          <RHFTextField name={'Detail'} label={translate('title.detail')} />
          <RHFTextField name={'HourMeter'} label={translate('maintenance.hour_meter')} />
          <Button variant={'contained'} type={'submit'} color={'primary'}>
            {translate('button.save')}
          </Button>
        </Stack>
      </FormProvider>
    </ProjectPaper>
  );
}

export default CompleteMaintenanceJobForm;
