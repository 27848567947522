import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { Card, CardContent, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import GridDetailText from 'components/customs/GridDetailText';
import DetailWidget from 'components/customs/DetailWidget';
import CustomStepper from 'components/customs/CustomStepper';
import ProjectPaper from 'components/customs/ProjectPaper';

import { fDateTimeText } from 'utilities/formatTime';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { fNumber } from 'utilities/formatNumber';

PileDetail.propTypes = {
  pile: PropTypes.object.isRequired,
};

function PileDetail({ pile }) {
  const { translate } = useLocales();
  const theme = useTheme();

  const getStatusText = (pileStatus) => {
    if (pileStatus.Id === 1) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.info.main}
          value={pileStatus.Name}
        />
      );
    } else if (pileStatus.Id === 2) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.warning.main}
          value={pileStatus.Name}
        />
      );
    } else if (pileStatus.Id === 3) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.warning.main}
          value={pileStatus.Name}
        />
      );
    } else if (pileStatus.Id === 4) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.success.main}
          value={pileStatus.Name}
        />
      );
    } else if (pileStatus.Id === 5) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.error.main}
          value={pileStatus.Name}
        />
      );
    } else if (pileStatus.Id === 6) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.warning.main}
          value={pileStatus.Name}
        />
      );
    } else if (pileStatus.Id === 7) {
      return (
        <GridDetailText
          title={translate('title.status')}
          color={theme.palette.error.main}
          value={pileStatus.Name}
        />
      );
    }
  };

  const processStep = () => {
    const productionRecord = [...pile?.ProductionRecords];
    productionRecord.sort((a, b) =>
      a.CompletedAt > b.CompletedAt ? 1 : b.CompletedAt > a.CompletedAt ? -1 : 0
    );

    const qaStepName =
      pile?.PileStatusId === 5
        ? translate('title.hold')
        : translate('qa.approve_pile_quality_title');
    let steps = [
      translate('production.create_pile'),
      ...productionRecord.map((process) => process.ProductionProcess.Name),
      qaStepName,
      translate('button.submit'),
    ];

    let activeStep = 1;
    if (pile?.PileStatusId > 3) {
      if (pile?.PileStatusId === 3 || pile?.PileStatusId === 5) {
        //Wait for QA Check to Complete or Hold
        activeStep = productionRecord.length + 1;
      }
      if (pile?.PileStatusId === 4) {
        //Complete
        activeStep = productionRecord.length + 3;
      }
      if (pile?.PileStatusId === 6) {
        //Complete
        activeStep = productionRecord.length + 2;
      }
      if (pile?.PileStatusId === 7) {
        //Complete
        activeStep = 1;
        steps = [translate('production.create_pile'), translate('button.cancel')];
      }
    } else {
      productionRecord.forEach((step) => {
        if (step.CompletedAt !== null) {
          activeStep += 1;
        }
      });
    }

    return {
      Steps: steps,
      ActiveStep: activeStep,
    };
  };

  const stepDetail = processStep();

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={3}>
            <GridDetailText title={translate('title.id')} value={pile?.Id} />
            <GridDetailText
              title={translate('title.external_reference_id')}
              value={pile?.ReferenceId}
            />
            <GridDetailText title={translate('title.name')} value={pile?.ProductOrder.Name} />
            <GridDetailText
              title={translate('title.create_at')}
              value={fDateTimeText(pile?.createdAt)}
            />
            <GridDetailText
              title={translate('title.create_by')}
              value={getEmployeeFullName(pile?.CreateEmployee)}
            />
            {getStatusText(pile?.PileStatus)}
            <GridDetailText title={translate('title.amount')} value={fNumber(pile?.Amount)} />
            <GridDetailText title={translate('title.height')} value={fNumber(pile?.Height)} />
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <ProjectPaper>
            <CustomStepper currentStep={stepDetail.ActiveStep} steps={stepDetail.Steps} />
          </ProjectPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <DetailWidget
            color={'info'}
            title={translate('title.complete_height')}
            total={pile?.CompleteHeight}
            icon={'gis:layer-stack'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DetailWidget
            color={'success'}
            title={translate('title.amount_good')}
            total={pile?.GoodAmount}
            icon={'eva:checkmark-fill'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DetailWidget
            color={'error'}
            title={translate('title.amount_defect')}
            total={pile?.DefectAmount}
            icon={'eva:close-fill'}
            isSimple
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PileDetail;
