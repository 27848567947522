import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  breakMachine,
  getAllAdditionalCost,
  getAllAdditionalCostType,
  getAllCarType,
  getAllCreateItemTemplateData,
  getAllCustomer,
  getAllInventoryStore,
  getAllMachine,
  getAllMaintenanceJobType,
  getAllMaintenanceTechnician,
  getAllPaymentTypeOptions,
  getAllProductionMachine,
  getAllProductionProcessType,
  resumeMachine,
  getAllItemTemplateUnit,
  getAllPartsSupplier,
  getAllEmployeeDepartment,
  getAllPenaltyLevel, getAllProduct,
} from '../ProductionServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';
import {
  getAllCreateItemMasterData,
  getAllSupplier,
  getAllWarehouse,
  getAllCreatePurchaseRequisitionData,
  getAllItemUnit,
  getAllMaterialType,
  getAllMaterialUnit,
  getAllItemMasterList,
  getAllBOMType,
  getAllMaterial,
  getAllBOM, getAllProductBOMs, getAllAttributeStrings, getAllAttributeNumbers,
} from '../WarehouseServerHandler';

export const useCustomers = () => {
  const { data } = useQuery({
    queryKey: ['all-customer-data'],
    queryFn: getAllCustomer,
    initialData: [],
  });

  return {
    customers: data,
  };
};

export const useAdditionalCostType = () => {
  const { data } = useQuery({
    queryKey: ['all-additional-cost-type-data'],
    queryFn: getAllAdditionalCostType,
    initialData: [],
  });

  return {
    additionalCostTypes: data,
  };
};

export const usePaymentType = () => {
  const { data } = useQuery({
    queryKey: ['all-payment-type'],
    queryFn: getAllPaymentTypeOptions,
    initialData: [],
  });

  return {
    paymentTypeOptions: data,
  };
};

export const useAdditionalCost = () => {
  const { data } = useQuery({
    queryKey: ['all-additional-cost-data'],
    queryFn: getAllAdditionalCost,
    initialData: [],
  });

  return {
    additionalCosts: data.map((additionalCost) => {
      return {
        Id: additionalCost.Id,
        Name: additionalCost.Name,
      };
    }),
  };
};

export const useProductionProcessType = () => {
  const { data } = useQuery({
    queryKey: ['all-production-process-type'],
    queryFn: getAllProductionProcessType,
    initialData: [],
  });

  return {
    productionProcessTypeOptions: data,
  };
};

export const useMachine = (isTemp = false, isProduction = true) => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['all-machine-data'],
    queryFn: isProduction ? () => getAllProductionMachine(isTemp) : () => getAllMachine(isTemp),
    initialData: [],
  });

  const breakMachineMutation = useMutation({
    mutationFn: breakMachine,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['all-machine-data']);
      await OpenSuccessNotification();
    },
  });

  const resumeMachineMutation = useMutation({
    mutationFn: resumeMachine,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['all-machine-data']);
      await OpenSuccessNotification();
    },
  });

  return {
    machines: data,
    resumeMachine: resumeMachineMutation,
    breakMachine: breakMachineMutation,
  };
};

export const useMaintenanceJobType = () => {
  const { data } = useQuery({
    queryKey: ['all-maintenance-job-type'],
    queryFn: getAllMaintenanceJobType,
    initialData: [],
  });

  return {
    allMaintenanceJobType: data,
  };
};

export const useMaintenanceTechnicianList = () => {
  const { data } = useQuery({
    queryKey: ['all-maintenance-technician-list'],
    queryFn: () => getAllMaintenanceTechnician(),
    initialData: [],
  });

  return {
    allTechnician: data.map((technician) => {
      return {
        Id: technician.Id,
        Name: `${technician.FirstName} ${technician.LastName}`,
      };
    }),
  };
};

export const useCarType = () => {
  const { data } = useQuery({
    queryKey: ['all-car-type'],
    queryFn: getAllCarType,
    initialData: [],
  });

  return {
    carTypes: data,
  };
};

export const useItemTemplateCreateData = () => {
  const { data } = useQuery({
    queryKey: ['all-item-template-create-data'],
    queryFn: getAllCreateItemTemplateData,
    initialData: {
      ItemTemplateType: [],
      ItemTemplateUnit: [],
      ItemTemplateCategory: [],
      InventoryStore: [],
      InventoryShelf: [],
    },
  });
  return {
    itemTypeOptions: data.ItemTemplateType.filter((type) => type.Id > 1),
    itemUnitOptions: data.ItemTemplateUnit,
    itemCategoryOptions: data.ItemTemplateCategory,
    inventoryStoreOptions: data.InventoryStore,
    allInventoryShelfOptions: data.InventoryShelf,
  };
};

export const useInventoryStoreOptions = () => {
  const { data } = useQuery({
    queryKey: ['all-inventory-store-options'],
    queryFn: getAllInventoryStore,
    initialData: [],
  });

  return {
    inventoryStoreOptions: data,
  };
};

export const useItemMasterCreateData = () => {
  const { data } = useQuery({
    queryKey: ['all-item-master-create-data'],
    queryFn: getAllCreateItemMasterData,
    initialData: {
      ItemType: [],
      ItemUnit: [],
    },
  });
  return {
    itemTypeOptions: data.ItemType.filter((type) => type.ItemCategoryId === 1),
    itemUnitOptions: data.ItemUnit,
  };
};

export const useWarehouseOptions = () => {
  const { data } = useQuery({
    queryKey: ['all-warehouse-options'],
    queryFn: getAllWarehouse,
    initialData: [],
  });

  return {
    warehouseOptions: data,
  };
};

export const useSupplierOptions = () => {
  const { data } = useQuery({
    queryKey: ['all-supplier-options'],
    queryFn: getAllSupplier,
    initialData: [],
  });

  return {
    supplierOptions: data.map((supplier) => {
      return {
        Id: supplier.Id,
        Name: supplier.Name,
      };
    }),
  };
};

export const useCreatePRData = () => {
  const { data } = useQuery({
    queryKey: ['all-create-pr-data'],
    queryFn: getAllCreatePurchaseRequisitionData,
    initialData: {
      PriorityLevel: [],
    },
  });
  return {
    priorityOptions: data.PriorityLevel,
  };
};
export const useItemUnitOptions = () => {
  const { data: allItemUnit } = useQuery({
    queryKey: ['getAllItemUnit'],
    queryFn: getAllItemUnit,
    initialData: [],
  });

  return {
    itemUnitOptions: allItemUnit,
  };
};

export const useAllSupplier = (optionOnly) => {
  const { data: allSupplier } = useQuery({
    queryKey: ['getAllSupplier'],
    queryFn: getAllSupplier,
    initialData: [],
  });

  return {
    allSupplier: optionOnly
      ? allSupplier.map((supplier) => {
          return {
            Id: supplier.Id,
            Name: supplier.Name,
          };
        })
      : allSupplier,
  };
};

export const useAllPartSupplier = (optionOnly) => {
  const { data: allSupplier } = useQuery({
    queryKey: ['getAllSupplier'],
    queryFn: getAllPartsSupplier,
    initialData: [],
  });

  return {
    allSupplier: optionOnly
      ? allSupplier.map((supplier) => {
          return {
            Id: supplier.Id,
            Name: supplier.Name,
          };
        })
      : allSupplier,
  };
};

export const useItemTemplateUnitOptions = () => {
  const { data: allItemUnit } = useQuery({
    queryKey: ['getAllItemUnit'],
    queryFn: getAllItemTemplateUnit,
    initialData: [],
  });

  return {
    itemTemplateUnitOptions: allItemUnit,
  };
};

export const useAllEmployeeDepartment = () => {
  const { data } = useQuery({
    queryKey: ['getAllEmployeeDepartment'],
    queryFn: getAllEmployeeDepartment,
    initialData: [],
  });

  return {
    allEmployeeDepartmentOptions: data,
  };
};

export const useAllPenaltyOptions = () => {
  const { data } = useQuery({
    queryKey: ['getAllPenaltyLevel'],
    queryFn: getAllPenaltyLevel,
    initialData: [],
  });

  return {
    allPenaltyLevelOptions: data,
  };
};

export const useMaterialTypes = () => {
  const { data } = useQuery({
    queryKey: ['all-material-type'],
    queryFn: getAllMaterialType,
    initialData: [],
  });

  return {
    materialTypes: data,
  };
};

export const useMaterialUnits = () => {
  const { data } = useQuery({
    queryKey: ['all-material-unit'],
    queryFn: getAllMaterialUnit,
    initialData: [],
  });

  return {
    materialUnits: data,
  };
};

export const useItemMasters = () => {
  const { data } = useQuery({
    queryKey: ['all-item-master'],
    queryFn: getAllItemMasterList,
    initialData: [],
  });

  return {
    itemMasters: data,
  };
};

export const useProducts = () => {
  const { data } = useQuery({
    queryKey: ['all-product'],
    queryFn: getAllProduct,
    initialData: [],
  });

  return {
    products: data.map((product) => {
      return {
        Id: product.Id,
        Name: product.Name,
      }
    }),
  };
};

export const useMaterial = () => {
  const { data } = useQuery({
    queryKey: ['all-material'],
    queryFn: getAllMaterial,
    initialData: [],
  });

  return {
    materials: data,
  };
};

export const useBOMType = () => {
  const { data } = useQuery({
    queryKey: ['all-bom-type'],
    queryFn: getAllBOMType,
    initialData: [],
  });

  return {
    bomTypes: data,
  };
};

export const useBOMs = () => {
  const { data } = useQuery({
    queryKey: ['all-bom'],
    queryFn: getAllProductBOMs,
    initialData: [],
  });

  return {
    bom: data,
  };
};

export const useAttributeStrings = () => {
  const { data } = useQuery({
    queryKey: ['all-attribute-strings'],
    queryFn: getAllAttributeStrings,
    initialData: [],
  });

  return {
    attributeStrings: data,
  };
};

export const useAttributeNumbers = () => {
  const { data } = useQuery({
    queryKey: ['all-attribute-numbers'],
    queryFn: getAllAttributeNumbers,
    initialData: [],
  });

  return {
    attributeNumbers: data,
  };
};