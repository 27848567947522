import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import Link from "@mui/material/Link";
import { allowToUploadUserTypeId } from '../../../utilities/Constants';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, Grid, Stack, Typography } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import useAuth from '../../../auth/hooks/useAuth';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import GeneralTable from '../../customs/GeneralTable';
import LoadingComponent from '../../customs/LoadingComponent';
import Iconify from 'components/minimal/iconify';
import { usePurchaseRequisition } from '../../../requests/query/usePurchaseRequisition';
import { paths } from '../../../routes/paths';
import UploadFileForm from '../../forms/UploadFileForm';

function PurchaseRequisitionPageDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useAuth();
  const { translate } = useLocales();
  const { prDetail, printPR, uploadPRFile, cancelPR } = usePurchaseRequisition(id);

  const handlePrintPR = async () => {
    await printPR.mutateAsync(id);
  };
  const handleApprovePR = async (values) => {
    await uploadPRFile.mutateAsync({
      Id: id,
      Document: values.File[0],
      UploadEmployeeId: user.EmployeeId,
    });
  };
  const handleCancelPR = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancelPR.mutateAsync(id);
        navigate(paths.procurement.pr_list);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.purchase_requisition_detail')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.purchase_requisition_list_title'),
          href: paths.procurement.pr_list,
        },
        { name: `${translate('procurement.purchase_requisition_detail')} : ${id}` },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant={'contained'}
            color={'error'}
            startIcon={<Iconify icon={'eva:close-fill'} />}
            onClick={handleCancelPR}
          >
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={handlePrintPR}
          >
            {translate('button.print_pr')}
          </Button>
        </Stack>
      }
    >
      {prDetail !== null ? (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={prDetail.Id} />
              <GridDetailText title={translate('title.detail')} value={prDetail.Detail} />
              <GridDetailText
                title={translate('title.type')}
                value={translate('procurement.request_buy_material_title')}
              />
              <GridDetailText
                title={translate('procurement.request_to_by_by')}
                value={getEmployeeFullName(prDetail.CreateEmployee)}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
              ]}
              tableData={prDetail.PurchaseRequisitionItemMasters.map((item) => {
                return [
                  <Link href={paths.warehouse.item_master_detail(item.ItemMaster.Id)}>
                    {item.ItemMaster.Name}
                  </Link>,
                  item.Amount,
                  item.ItemMaster.ItemUnit.Name,
                ];
              })}
            />
          </ProjectPaper>
          <ProjectPaper>
            {prDetail.ApproveAt !== null ? (
              <Grid container spacing={3}>
                <GridDetailText
                  title={translate('title.approve_at')}
                  value={fDateTimeText(prDetail.ApproveAt)}
                />
                {allowToUploadUserTypeId.includes(user.UserTypeId) ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        {translate('procurement.approve_document_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant={'contained'}
                        href={`https://sil-space.sgp1.digitaloceanspaces.com/ApprovePurchaseRequisition/PR_Aprrove_${id}.pdf`}
                      >
                        {translate('procurement.open_document')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <GridDetailText
                    title={translate('procurement.approve_document_title')}
                    value={translate('procurement.already_upload')}
                  />
                )}
                <GridDetailText
                  title={translate('procurement.upload_by_title')}
                  value={getEmployeeFullName(prDetail.UploadApproveEmployee)}
                />
              </Grid>
            ) : (
              <RoleBasedGuard roles={allowToUploadUserTypeId}>
                <UploadFileForm isImage onSubmitForm={handleApprovePR} />
              </RoleBasedGuard>
            )}
          </ProjectPaper>
        </React.Fragment>
      ) : (
        <LoadingComponent />
      )}
    </ProjectPageContainer>
  );
}

export default PurchaseRequisitionPageDetail;
