import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ProjectPaper from 'components/customs/ProjectPaper';
import { Button, Stack } from '@mui/material';
import FormProvider, {
  RHFTextField,
  RHFDatePicker,
  RHFAutoCompleteShort,
} from 'components/minimal/hook-form';
import React from 'react';
import PropTypes from 'prop-types';
import { getAllEmployeePerformanceData } from 'requests/ProductionServerHandler';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { useQuery } from '@tanstack/react-query';

export const EditEmployeePerformanceForm = ({ recordToEdit, onSaveEditDetailCallBack }) => {
  const { translate } = useLocales();

  const { data } = useQuery({
    queryKey: ['edit-employee-performance-require-info'],
    queryFn: () => getAllEmployeePerformanceData(),
    initialData: {
      Employee: [],
      Shift: [],
      ProductType: [],
      Machine: [],
    },
  });

  const editEmployeeFormSchema = Yup.object().shape({
    CreateEmployee: Yup.object().required(translate('error.detail_require')),
    Machine: Yup.object().required(translate('error.detail_require')),
    Shift: Yup.object().required(translate('error.detail_require')),
    ProductType: Yup.object().required(translate('error.detail_require')),
    StartTimeAt: Yup.date().required(translate('error.detail_require')),
    EndTimeAt: Yup.date().required(translate('error.detail_require')),
    GoodAmount: Yup.number().required(translate('error.error_invalid_amount')),
    DefectAmount: Yup.number().required(translate('error.error_invalid_amount')),
    TotalPileAmount: Yup.number().required(translate('error.error_invalid_amount')),
    TotalPileCount: Yup.number().required(translate('error.error_invalid_amount')),
    WorkingTime: Yup.number().required(translate('error.error_invalid_amount')),
    AveragePerHour: Yup.number().required(translate('error.error_invalid_amount')),
  });

  const defaultValue =
    recordToEdit !== undefined && recordToEdit !== null
      ? {
          CreateEmployee: {
            Id: recordToEdit.CreateEmployee.Id,
            Name: getEmployeeFullName(recordToEdit.CreateEmployee),
          },
          Machine: recordToEdit.Machine,
          Shift: recordToEdit.Shift,
          ProductType: recordToEdit.ProductType,
          StartTimeAt: new Date(recordToEdit.StartTimeAt),
          EndTimeAt: new Date(recordToEdit.EndTimeAt),
          GoodAmount: recordToEdit.GoodAmount,
          DefectAmount: recordToEdit.DefectAmount,
          TotalPileAmount: recordToEdit.TotalPileAmount,
          TotalPileCount: recordToEdit.TotalPileCount,
          WorkingTime: recordToEdit.WorkingTime,
          AveragePerHour: recordToEdit.AveragePerHour,
        }
      : {
          GoodAmount: 0,
          DefectAmount: 0,
          TotalPileAmount: 0,
          TotalPileCount: 0,
          WorkingTime: 0,
          AveragePerHour: 0,
        };

  const methods = useForm({
    resolver: yupResolver(editEmployeeFormSchema),
    defaultValues: defaultValue,
  });

  const { handleSubmit } = methods;

  const onSaveEditDetail = (data) => {
    onSaveEditDetailCallBack(recordToEdit.Id, data);
  };

  return (
    <>
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSaveEditDetail)}>
          <Stack spacing={3}>
            <RHFAutoCompleteShort
              name={'CreateEmployee'}
              label={translate('title.name')}
              data={data.Employee}
            />
            <RHFAutoCompleteShort
              name={'Machine'}
              label={translate('title.machine')}
              data={data.Machine}
            />
            <RHFAutoCompleteShort name={'Shift'} label={translate('sil.shift')} data={data.Shift} />
            <RHFAutoCompleteShort
              name={'ProductType'}
              label={translate('title.product_order_subtype')}
              data={data.ProductType}
            />
            <RHFDatePicker
              name="StartTimeAt"
              label={translate('title.start_date')}
              includeTime
              inputFormat={'PPPP p'}
            />
            <RHFDatePicker
              name="EndTimeAt"
              label={translate('title.end_date')}
              includeTime
              inputFormat={'PPPP p'}
            />
            <RHFTextField name={'GoodAmount'} label={translate('title.amount_good')} />
            <RHFTextField name={'DefectAmount'} label={translate('title.amount_defect')} />
            <RHFTextField
              name={'TotalPileAmount'}
              label={translate('title.amount_to_production')}
            />
            <RHFTextField name={'TotalPileCount'} label={translate('title.pile_amount')} />
            <RHFTextField name={'WorkingTime'} label={translate('title.total_time')} />
            <RHFTextField name={'AveragePerHour'} label={translate('title.avg_per_hour')} />
            <Button variant={'contained'} fullWidth type="submit" color={'primary'}>
              {translate('button.save')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </>
  );
};

EditEmployeePerformanceForm.PropType = {
  recordToEdit: PropTypes.object.isRequired,
  onSaveEditDetailCallBack: PropTypes.func.isRequired,
};
