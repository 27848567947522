import { useLoading } from '../../components/loading';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteItemById, getItemDetailById, getItemQRCode } from '../WarehouseServerHandler';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';

export const useItemDetail = (id) => {
  const queryClient = useQueryClient();
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['item-detail', id],
    queryFn: () => getItemDetailById(id),
    enabled: !!id,
  });
  const printQRPDFMutation = useMutation({
    mutationFn: getItemQRCode,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument([response.PDF], `item_qr_code_${id}.pdf`);
      await hideLoadingScreen();
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
      await hideLoadingScreen();
    },
  });
  const deleteItemMutation = useMutation({
    mutationFn: deleteItemById,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['item-detail', id]);
    },
  });

  return { itemDetail: data, printQRCode: printQRPDFMutation, deleteItem: deleteItemMutation };
};
