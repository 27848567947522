import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  LinearProgress,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useLocales } from '../../../locales';
import Scrollbar from '../../minimal/scrollbar';
import { fNumber, fPercent } from '../../../utilities/formatNumber';
import { getCompletePercentage, getTotalComplete } from '../../../utilities/Helper/DataHelper';

MachineQueueDetail.propTypes = {
  machineDetail: PropTypes.object.isRequired,
};

function MachineQueueDetail({ machineDetail }) {
  const { translate } = useLocales();
  const getTotalInThisMachine = (plans, capacity) => {
    return (
      capacity -
      plans.reduce((totalAmount, plan) => {
        return totalAmount + plan.ProductOrder.Amount;
      }, 0)
    );
  };
  const activeProductOrderPlan = machineDetail.ProductOrderPlannings.find(
    (plan) => plan.QueueNumber === 1
  );

  return (
    <Stack spacing={2} sx={{ width: 500 }}>
      <Typography variant={'h4'}>{machineDetail.Name}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant={'h6'}>
          {`${translate('planning.number_of_queue')} : ${
            machineDetail.ProductOrderPlannings.length
          }`}
        </Typography>
        <Typography variant={'h6'}>
          {`${translate('title.capacity')} ${getTotalInThisMachine(
            machineDetail.ProductOrderPlannings,
            machineDetail.ExpectedResult
          )}/${machineDetail.ExpectedResult}`}
        </Typography>
      </Box>
      <Divider />
      <Scrollbar sx={{ mt: 2, maxHeight: 500 }}>
        <Stack spacing={2}>
          {activeProductOrderPlan !== undefined && (
            <Card>
              <CardHeader
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack direction={'row'}>
                      <AutorenewIcon sx={{ color: '#FF4842' }} />
                      <Typography sx={{ ml: 1 }} variant={'h6'}>{`${translate(
                        'title.work_in_process'
                      )} : ${activeProductOrderPlan.ProductOrder.Id}`}</Typography>
                    </Stack>
                    <Chip label="อยู่ในแผน" color="primary" variant="outlined" />
                  </Box>
                }
              />
              <CardContent>
                <Stack spacing={1}>
                  <Typography variant={'body2'}>
                    {activeProductOrderPlan.ProductOrder.Name}
                  </Typography>
                  <Typography variant={'body2'}>
                    {activeProductOrderPlan.ProductOrder.Customer.Name}
                  </Typography>
                  <Typography variant={'body2'}>{`${translate('title.amount')} : ${fNumber(
                    activeProductOrderPlan.ProductOrder.Amount
                  )}`}</Typography>
                  <Typography variant={'body2'}>เวลาเสร็จตามแผน: N/A</Typography>
                  <Card>
                    <CardContent>
                      <Stack spacing={1}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant={'h6'}>Progress</Typography>
                          <Typography variant={'h6'}>
                            {fPercent(getCompletePercentage(activeProductOrderPlan))}
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={getCompletePercentage(activeProductOrderPlan)}
                        />
                        <Typography variant={'body2'}>{`${translate(
                          'title.complete_amount'
                        )} ${getTotalComplete(activeProductOrderPlan)}/${
                          activeProductOrderPlan.ProductOrder.Amount
                        }`}</Typography>
                      </Stack>
                    </CardContent>
                  </Card>
                </Stack>
              </CardContent>
            </Card>
          )}
          {machineDetail.ProductOrderPlannings.filter((plan) => plan.QueueNumber > 1).map(
            (plan) => {
              return (
                <Card>
                  <CardHeader
                    title={
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Stack direction={'row'}>
                          <AccessTimeIcon sx={{ color: '#FF4842' }} />
                          <Typography sx={{ ml: 1 }} variant={'h6'}>{`${translate(
                            'planning.wait_for_queue'
                          )} : ${plan.ProductOrder.Id}`}</Typography>
                        </Stack>
                        <Chip label="อยู่ในแผน" color="primary" variant="outlined" />
                      </Box>
                    }
                  />
                  <CardContent>
                    <Stack spacing={1}>
                      <Typography variant={'body2'}>{plan.ProductOrder.Name}</Typography>
                      <Typography variant={'body2'}>{plan.ProductOrder.Customer.Name}</Typography>
                      <Typography variant={'body2'}>{`${translate('title.amount')} : ${fNumber(
                        plan.ProductOrder.Amount
                      )}`}</Typography>
                      <Typography variant={'body2'}>เวลาเสร็จตามแผน: N/A</Typography>
                    </Stack>
                  </CardContent>
                </Card>
              );
            }
          )}
        </Stack>
      </Scrollbar>
    </Stack>
  );
}

export default MachineQueueDetail;
