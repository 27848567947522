import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import {
  adjustRequestCart,
  resetPurchaseCart,
  resetRequestCart,
  selectItemForMaintenanceId,
  selectItemForProductOrder,
  selectPurchaseCart,
  selectRequestCart,
} from 'redux/reducers/inventory';
import { useLocales } from 'locales';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ProjectPaper from '../../customs/ProjectPaper';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import useAuth from '../../../auth/hooks/useAuth';
import { useItemRequisition } from '../../../requests/query/useItemRequisition';
import { paths } from '../../../routes/paths';
import useQueryParameter from '../../../hooks/useQueryParameter';
import Link from "@mui/material/Link";

const ItemRequisitionCheckoutPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { user } = useAuth();
  const RequestCart = useSelector(selectRequestCart);
  const PurchaseCart = useSelector(selectPurchaseCart);
  const searchParams = useQueryParameter();
  const itemProductOrderId = searchParams.get('ProductOrderId');
  const itemMaintenanceId = searchParams.get('MaintenanceJobId');
  const { createReport } = useItemRequisition(null);

  const inventoryCheckoutSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(inventoryCheckoutSchema),
  });

  const { handleSubmit } = methods;

  const onCheckoutItems = async (values) => {
    let type = null;
    let bindId = 0;
    if (itemProductOrderId !== null && itemProductOrderId !== 'null') {
      type = 1;
      bindId = itemProductOrderId;
    } else if (itemMaintenanceId !== null   && itemMaintenanceId !== 'null') {
      type = 2;
      bindId = itemMaintenanceId;
    }
    let body = {
      RequestItems: RequestCart.map((item) => {
        return { Id: item.Id, Amount: item.Amount };
      }),
      PurchaseItems: PurchaseCart.map((item) => {
        return { Id: item.Id, Amount: item.Amount };
      }),
      CreateEmployeeId: user.EmployeeId,
      Detail: values.Detail,
      IsTemp: false,
    };
    if (type === 1) {
      body = { ...body, ProductOrderId: bindId };
    } else if (type === 2) {
      body = { ...body, MaintenanceId: bindId };
    }
    await createReport.mutateAsync(body);
    dispatch(selectItemForMaintenanceId(null));
    dispatch(selectItemForProductOrder(null));
    dispatch(resetRequestCart());
    dispatch(resetPurchaseCart());
    navigate(paths.warehouse.store);
  };
  const onAddItem = (itemDetail) => {
    dispatch(
      adjustRequestCart({ Id: itemDetail.Id, Name: itemDetail.Name, Amount: itemDetail.Amount + 1 })
    );
  };
  const onRemoveItem = (itemDetail) => {
    dispatch(
      adjustRequestCart({ Id: itemDetail.Id, Name: itemDetail.Name, Amount: itemDetail.Amount - 1 })
    );
  };
  const onDeleteItem = (itemDetail) => {
    dispatch(adjustRequestCart({ Id: itemDetail.Id, Name: itemDetail.Name, Amount: 0 }));
  };

  if (PurchaseCart.length + RequestCart.length > 0) {
    return (
      <ProjectPageContainer
        menu_title={translate('warehouse.request_item')}
        breadcrumbs={[
          { name: translate('warehouse.material_store') },
          { name: translate('warehouse.request_item') },
        ]}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onCheckoutItems)}>
          <Stack spacing={3}>
            {(itemProductOrderId !== null && itemProductOrderId !== 'null') && (
              <Card>
                <CardHeader title={translate('production.product_order_id')} />
                <CardContent>
                  <Grid>
                    <GridDetailText
                      title={translate('warehouse.for_purchase_order_id')}
                      value={itemProductOrderId}
                      link={paths.production.product_order_detail(itemProductOrderId)}
                    />
                  </Grid>
                </CardContent>
              </Card>
            )}
            {itemMaintenanceId !== null && (
              <Card>
                <CardHeader title={translate('maintenance.maintenance_job_detail')} />
                <CardContent>
                  <Grid>
                    <GridDetailText
                      title={translate('warehouse.for_maintenance_job_id')}
                      value={itemMaintenanceId}
                      link={paths.maintenance.job_detail(itemMaintenanceId)}
                    />
                  </Grid>
                </CardContent>
              </Card>
            )}
            <ProjectPaper>
              <GeneralTable
                tableHeaders={[
                  { align: 'left', title: '#' },
                  { align: 'left', title: 'title.id' },
                  { align: 'left', title: 'title.name' },
                  { align: 'left', title: 'title.amount' },
                  { align: 'left', title: '' },
                ]}
                tableData={RequestCart.map((item, index) => {
                  return [
                    index + 1,
                    item.Id,
                    <Link href={paths.warehouse.item_master_detail(item.Id)}>{item.Name}</Link>,
                    item.Amount,
                    <>
                      <IconButton
                        disabled={item.Amount >= item.MaxAmount}
                        color={'success'}
                        onClick={() => onAddItem(item)}
                      >
                        <Iconify icon={'ant-design:plus-outlined'} width={20} height={20} />
                      </IconButton>
                      <IconButton color={'error'} onClick={() => onRemoveItem(item)}>
                        <Iconify icon={'ant-design:minus-outlined'} width={20} height={20} />
                      </IconButton>
                      <IconButton color={'error'} onClick={() => onDeleteItem(item)}>
                        <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                      </IconButton>
                    </>,
                  ];
                })}
              />
            </ProjectPaper>
            <ProjectPaper>
              <Stack spacing={3}>
                <RHFTextField name="Detail" label={translate('title.detail')} />
                <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
                  {translate('button.submit')}
                </Button>
              </Stack>
            </ProjectPaper>
          </Stack>
        </FormProvider>
      </ProjectPageContainer>
    );
  } else {
    return <div>{navigate(-1)}</div>;
  }
};

export default ItemRequisitionCheckoutPage;
