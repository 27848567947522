import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, Grid, IconButton } from "@mui/material";
import GeneralTable from "../../customs/GeneralTable";
import { fCurrency } from "../../../utilities/formatNumber";
import { useLocales } from "../../../locales";
import DetailWidget from "../../customs/DetailWidget";
import useAuth from "../../../auth/hooks/useAuth";
import { ManagementUserTypeId } from "../../../utilities/Constants";
import Stack from "@mui/material/Stack";
import GridDetailText from "../../customs/GridDetailText";
import { paths } from "../../../routes/paths";
import CardHeader from "@mui/material/CardHeader";
import Iconify from "../../minimal/iconify";
import EditProductOrderPriceForm from "../../forms/EditProductOrderPriceForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  declineChangePrice, getPriceChangeApproveForm, getProductOrderDeliveryReport,
  getProductOrderPriceHistoryByProductOrderId
} from "../../../requests/ProductionServerHandler";
import { getEmployeeFullName } from "../../../utilities/Helper/UtilitiesHelper";
import { fDateTimeTextShort } from "../../../utilities/formatTime";
import RoleBasedGuard from "../../../auth/guard/RoleBaseGuard";
import { OPEN_ICON } from "../../../config-global";
import { RouterLink } from "../../../routes/components";
import Label from "../../minimal/label";
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification
} from "../../../utilities/Helper/NotificationHelper";
import { useNavigate } from "react-router-dom";
import { PrintPDFMakeDocument } from "../../../utilities/Helper/PrintHelper";
import { useLoading } from "../../loading";

ProductOrderPriceDetail.propTypes = {
  priceDetails: PropTypes.array,
  productOrder: PropTypes.object
};

function ProductOrderPriceDetail({ priceDetails, productOrder }) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data: ProductOrderPriceHistory } = useQuery({
    queryKey: ["product-order-price-history", productOrder.Id],
    queryFn: () => getProductOrderPriceHistoryByProductOrderId(productOrder.Id),
    initialData: []
  });
  const cancelMutation = useMutation({
    mutationFn: declineChangePrice,
    onSuccess: async () => {
      await OpenSuccessNotification("Decline Success");
      navigate(paths.production.product_order_detail(ProductOrderPriceHistory.ProductOrder.Id));
      await queryClient.invalidateQueries(["product-order-price-history", productOrder.Id]);
    }
  });
  const printPriceChangeApproveForm = useMutation({
    mutationFn: getPriceChangeApproveForm,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response, variables) => {
      console.log(variables)
      hideLoadingScreen();
      await PrintPDFMakeDocument(response, `ChangePriceForm_${1}.pdf`);
      await OpenSuccessNotification();
    },
  });
  const [isEditPrice, setIsEditPrice] = React.useState(false);

  const getGoodAmount = () =>
    productOrder.Piles.reduce((accumulator, pile) => accumulator + pile.GoodAmount, 0);
  const getDefectAmount = () =>
    productOrder.Piles.reduce((accumulator, pile) => accumulator + pile.DefectAmount, 0);
  const totalValue = priceDetails.reduce((totalVal, priceDetail) => {
    if (priceDetail.IsBundle) {
      totalVal += priceDetail.BundlePrice;
    } else {
      totalVal += priceDetail.SellPrice * productOrder.Amount;
    }
    return totalVal;
  }, 0);

  const totalValueGood = priceDetails.reduce((totalVal, priceDetail) => {
    if (priceDetail.IsBundle) {
      totalVal += priceDetail.BundlePrice;
    } else {
      totalVal += priceDetail.SellPrice * getGoodAmount();
    }
    return totalVal;
  }, 0);

  const totalValueDefect = priceDetails.reduce((totalVal, priceDetail) => {
    if (priceDetail.IsBundle) {
      totalVal += priceDetail.BundlePrice;
    } else {
      totalVal += priceDetail.SellPrice * getDefectAmount();
    }
    return totalVal;
  }, 0);

  const onEditPrice = () => {
    setIsEditPrice(true);
  };
  const onEditPriceSuccess = async () => {
    setIsEditPrice(false);
    await queryClient.invalidateQueries(["product-order-price-history", productOrder.Id]);
  };
  const onCancelChangePrice = async (id) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      "warning.confirm_cancel_data",
      async () => {
        await cancelMutation.mutate({
          ProductPriceHistoryId: id
        });
      }
    );
  };

  const onPrintChangePriceForm = async (id) => {
    await printPriceChangeApproveForm.mutateAsync(id);
  };

  return (
    <>
      {
        isEditPrice ? <EditProductOrderPriceForm productOrder={productOrder} onSuccess={onEditPriceSuccess} /> :
          <Stack spacing={2}>
            {
              productOrder.ProductOrderQuotations.length > 0 && <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <GridDetailText title={translate("title.quotation_number")}
                                    value={productOrder?.ProductOrderQuotations[0].Id}
                                    link={paths.sales.product_order_quotation_detail(productOrder?.ProductOrderQuotations[0].Id)} />
                  </Grid>
                </CardContent>
              </Card>
            }
            <Card>
              <CardHeader title={translate("title.detail")} action={<Button
                variant="contained"
                color={"primary"}
                startIcon={<Iconify icon={"material-symbols:edit"} />}
                onClick={onEditPrice}
              >
                {translate("button.edit_price")}
              </Button>} />
              <CardContent>
                <GeneralTable
                  tableHeaders={[
                    { align: "left", title: "title.name" },
                    { align: "left", title: "title.price_per_square_inch" },
                    { align: "left", title: "title.price_per_sheet" },
                    { align: "left", title: "title.total" }
                  ]}
                  tableData={priceDetails.map((priceDetail) => [
                    priceDetail.Product.Name,
                    user.UserTypeId === ManagementUserTypeId
                      ? priceDetail.Product.PricePerSquareInch
                      : "N/A",
                    priceDetail.IsBundle
                      ? fCurrency(priceDetail.BundlePrice)
                      : fCurrency(priceDetail.SellPrice),
                    priceDetail.IsBundle
                      ? fCurrency(priceDetail.BundlePrice)
                      : fCurrency(priceDetail.SellPrice * productOrder.Amount)
                  ])}
                />
              </CardContent>
            </Card>
            {
              ProductOrderPriceHistory.length > 0 && (
                <Card>
                  <CardHeader title={translate("button.edit_price")} />
                  <CardContent>
                    {
                      <GeneralTable
                        tableHeaders={[
                          { align: "left", title: "title.id" },
                          { align: "left", title: "title.price_that_want_to_change" },
                          { align: "left", title: "title.create_by" },
                          { align: "left", title: "title.create_at" },
                          { align: "left", title: "title.status" },
                          { align: "left", title: "Action" }
                        ]}
                        tableData={ProductOrderPriceHistory.map((history) => [
                          history.Id,
                          fCurrency(history.PricePerSheet),
                          getEmployeeFullName(history.EditEmployee),
                          fDateTimeTextShort(history.createdAt),
                          history.ApprovedAt !== null ? <Label
                            variant={"filled"}
                            color={"success"}
                            sx={{ textTransform: "capitalize" }}
                          >
                            อนุมัติแล้ว
                          </Label> : <Label
                            variant={"filled"}
                            color={"error"}
                            sx={{ textTransform: "capitalize" }}
                          >
                            ยังไม่อนุมัติ
                          </Label>,
                          <>
                            {
                              history.ApprovedAt === null && (
                                <>
                                  <IconButton color={"info"} onClick={() => onPrintChangePriceForm(history.Id)}>
                                    <Iconify icon={"ic:print"} width={20} height={20} />
                                  </IconButton>
                                  <IconButton color={"error"} onClick={() => onCancelChangePrice(history.Id)}>
                                    <Iconify icon={"ic:baseline-close"} width={20} height={20} />
                                  </IconButton>
                                </>
                              )
                            }
                            <RoleBasedGuard roles={[ManagementUserTypeId]}>
                              <IconButton
                                color={"primary"}
                                component={RouterLink}
                                to={paths.production.approve_change_price_detail(history.Id)}
                              >
                                <Iconify icon={OPEN_ICON} />
                              </IconButton>
                            </RoleBasedGuard>
                          </>
                        ])}
                      />
                    }
                  </CardContent>
                </Card>
              )
            }
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <DetailWidget
                  color={"info"}
                  title={translate("sales.sales_value")}
                  total={totalValue}
                  icon={"dashicons:money-alt"}
                  isSimple
                  isCurrency
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DetailWidget
                  color={"success"}
                  title={translate("title.amount_good")}
                  total={totalValueGood}
                  icon={"eva:checkmark-fill"}
                  isSimple
                  isCurrency
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DetailWidget
                  color={"error"}
                  title={translate("title.amount_defect")}
                  total={-totalValueDefect}
                  icon={"eva:close-fill"}
                  isSimple
                  isCurrency
                />
              </Grid>
            </Grid>
          </Stack>
      }
    </>
  );
}

export default ProductOrderPriceDetail;
