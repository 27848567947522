import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addVatToPO,
  cancelPurchaseOrder,
  editPODetail,
  editPOItemContainer,
  editPOReferenceId,
  getPurchaseOrderDetailById,
  getPurchaseOrderPDF, removeItemFromPO, removeVatFromPO,
  uploadApprovePODocument,
} from '../WarehouseServerHandler';
import {
  OpenNotificationThenCallback,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { po_prefix } from '../../utilities/Strings/Prefix';
import { useLocales } from '../../locales';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/paths';

export const usePurchaseOrder = (id) => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['purchaseOrder', id],
    queryFn: () => getPurchaseOrderDetailById(id),
    enabled: !!id,
    initialData: null,
  });

  const printPOMutation = useMutation({
    mutationFn: getPurchaseOrderPDF,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', async () => {
        await PrintMultiplePDFMakeDocument([response.PDF], `${po_prefix}_${id}.pdf`);
      });
    },
  });

  const cancelPOMutation = useMutation({
    mutationFn: cancelPurchaseOrder,
    onSuccess: async () => {
      await OpenSuccessNotification();
      navigate(paths.procurement.po_list);
    },
  });

  const uploadApproveMutation = useMutation({
    mutationFn: uploadApprovePODocument,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  const editPOItemsMutation = useMutation({
    mutationFn: editPOItemContainer,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  const editPODetailMutation = useMutation({
    mutationFn: editPODetail,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  const editPOReferenceIdMutation = useMutation({
    mutationFn: editPOReferenceId,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  const removeVatMutation = useMutation({
    mutationFn: removeVatFromPO,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  const addVatMutation = useMutation({
    mutationFn: addVatToPO,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  const removeItemFromPOMutation = useMutation({
    mutationFn: removeItemFromPO,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['purchaseOrder', id]);
    },
  });

  return {
    purchaseOrder: data,
    printPO: printPOMutation,
    cancelPO: cancelPOMutation,
    uploadApprove: uploadApproveMutation,
    editPOItems: editPOItemsMutation,
    editPODetail: editPODetailMutation,
    editPOReferenceId: editPOReferenceIdMutation,
    removeVat:removeVatMutation,
    addVat:addVatMutation,
    removeItem:removeItemFromPOMutation
  };
};
