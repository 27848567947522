import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { getAllActiveProblems } from '../../../requests/ProductionServerHandler';
import Link from "@mui/material/Link";
import ReactTable from '../../react-table/ReactTable';
import { useLocales } from 'locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import ProjectPaper from '../../customs/ProjectPaper';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useQuery } from '@tanstack/react-query';
import { IconButton } from '@mui/material';
import { RouterLink } from '../../../routes/components';
import { paths } from '../../../routes/paths';
import Iconify from '../../minimal/iconify';
import { OPEN_ICON } from '../../../config-global';

function AllActiveQualityProblemPage() {
  const { translate } = useLocales();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });
  const { data: allPileWithProblem, refetch } = useQuery({
    queryKey: ['quality-problem-pile', dateRange],
    queryFn: () =>
      getAllActiveProblems({
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
      }),
    initialData: [],
  });

  const searchActiveProblemByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };

  const GenerateTableData = (piles) => {
    let returnData = [];
    piles.forEach((pile) => {
      pile.PileQualityProblemHistories.forEach((history) => {
        returnData.push({
          id: pile.Id,
          name: pile.ProductOrder,
          holdAt: history.HoldAt,
          holdBy: history.HoldEmployee,
          problem: history.QualityProblem.Name,
          actions: pile.Id,
        });
      });
    });

    return returnData;
  };

  return (
    <ProjectPageContainer
      menu_title={translate('qa.all_active_problem_title')}
      breadcrumbs={[
        { name: translate('qa.title') },
        { name: translate('qa.all_active_problem_title') },
      ]}
    >
      <SILDatePicker onSearchDate={searchActiveProblemByDate} />
      <ProjectPaper>
        <ReactTable
          defaultPageSize={25}
          search
          data={GenerateTableData(allPileWithProblem)}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
              cell: (info) => (
                <Link href={paths.production.product_order_detail(info.getValue().Id)}>
                  {info.getValue().Name}
                </Link>
              ),
            },
            {
              header: translate('qa.hold_at'),
              accessorKey: 'holdAt',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('qa.hold_by'),
              accessorKey: 'holdBy',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('qa.quality_problem_title'),
              accessorKey: 'problem',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  component={RouterLink}
                  to={paths.production.pile_detail(info.getValue())}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default AllActiveQualityProblemPage;
