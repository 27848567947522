import React from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/minimal/scrollbar';
import { Box, Table, TableBody, TableContainer, TableHead, TablePagination } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { flexRender } from '@tanstack/react-table';
import EmptyContent from '../customs/EmptyContent';
import { useLocales } from 'locales';

ReactTableSkeleton.propTypes = {
  table: PropTypes.object.isRequired,
};

function ReactTableSkeleton({ table }) {
  const { translate } = useLocales();

  const handlePageChange = (e, page) => {
    table.setPageIndex(page);
  };
  const handleRowChange = (e) => {
    table.setPageSize(Number(e.target.value));
  };

  return (
    <>
      {table.getRowModel().rows.length > 0 ? (
        <>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header, index) => (
                        <TableCell
                          key={index}
                          sortDirection={header.column.getIsSorted()}
                          align={header.column.columnDef.align || 'left'}
                        >
                          <TableSortLabel
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            direction={
                              header.column.getIsSorted() ? header.column.getIsSorted() : 'asc'
                            }
                            active={!!header.column.getIsSorted()}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header, header.getContext())}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell key={cell.id} sx={{ minWidth: cell.column.columnDef.size }}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={table.getPrePaginationRowModel().rows.length}
              rowsPerPage={table.getState().pagination.pageSize}
              page={table.getState().pagination.pageIndex}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowChange}
            />
          </Box>
        </>
      ) : (
        <EmptyContent
          title={translate('title.empty')}
          description={translate('placeholder.empty_table')}
          img="/assets/illustrations/illustration_empty_content.svg"
        />
      )}
    </>
  );
}

export default ReactTableSkeleton;
