import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { paths } from 'routes/paths';

const SearchProcurementPage = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const onSearch = async (searchString, selectType) => {
    if (selectType === 1) {
      if (searchString.includes('ReportId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ReportId !== undefined) {
          navigate(paths.procurement.po_detail(dataJson.ReportId));
        }
      } else {
        navigate(paths.procurement.po_detail(searchString));
      }
    } else if (selectType === 2) {
      if (searchString.includes('ReportId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ReportId !== undefined) {
          navigate(paths.procurement.pr_detail(dataJson.ReportId));
        }
      } else {
        navigate(paths.procurement.pr_detail(searchString));
      }
    } else if (selectType === 3) {
      if (searchString.includes('ReportId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ReportId !== undefined) {
          navigate(paths.procurement.service_po_detail(dataJson.ReportId));
        }
      } else {
        navigate(paths.procurement.service_po_detail(searchString));
      }
    } else if (selectType === 4) {
      if (searchString.includes('ReportId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ReportId !== undefined) {
          const searchId = dataJson.ReportId.substring(1);
          navigate(paths.procurement.parts_po_detail(searchId));
        }
      } else {
        const searchId = searchString.substring(1);
        navigate(paths.procurement.parts_po_detail(searchId));
      }
    } else if (selectType === 3) {
      if (searchString.includes('ReportId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ReportId !== undefined) {
          const searchId = dataJson.ReportId.substring(1);
          navigate(paths.procurement.parts_pr_detail(searchId));
        }
      } else {
        const searchId = searchString.substring(1);
        navigate(paths.procurement.parts_pr_detail(searchId));
      }
    }
  };

  const searchType = [
    { value: 1, Name: translate('procurement.search_po') },
    { value: 2, Name: translate('procurement.search_pr') },
    { value: 3, Name: translate('procurement.search_po_service') },
    { value: 4, Name: translate('procurement.search_po_parts') },
    { value: 5, Name: translate('procurement.search_pr_parts') },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[{ name: translate('procurement.title') }, { name: translate('button.search') }]}
    >
      <SearchBarWithSelectType
        search_label={translate('button.search')}
        select_options={searchType}
        onSearchCallBack={onSearch}
      />
    </ProjectPageContainer>
  );
};

export default SearchProcurementPage;
