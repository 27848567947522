import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@mui/material';
import GeneralTable from '../../customs/GeneralTable';

ProductOrderProcessDetail.propTypes = {
  processDetails: PropTypes.array,
};

function ProductOrderProcessDetail({ processDetails }) {
  return (
    <>
      <Card>
        <CardContent>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'title.amount_good' },
              { align: 'left', title: 'title.amount_defect' },
              { align: 'left', title: 'title.complete_piles' },
              { align: 'left', title: 'title.un_complete_piles' },
            ]}
            tableData={processDetails.map((process) => [
              process.Name,
              process.GoodAmount,
              process.DefectAmount,
              process.CompletePile,
              process.UnCompletePile,
            ])}
          />
        </CardContent>
      </Card>
    </>
  );
}

export default ProductOrderProcessDetail;
