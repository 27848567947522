import React, { useState } from 'react';
import defaultImage from '../../assets/img/image_placeholder.jpg';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardMedia, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Incrementer from './Incrementer';

export const QuickRequestItemCard = ({ onAmountChange, itemTemplate }) => {
  const onImageLoadError = (e) => {
    e.target.src = defaultImage;
  };

  const [amount, setAmount] = useState(0);

  const increaseAmount = () => {
    const adjustAmount = amount + 1;
    setAmount(adjustAmount);
    onAmountChange(adjustAmount, itemTemplate);
  };

  const decreaseAmount = () => {
    let adjustAmount = amount - 1;
    if (adjustAmount < 0) {
      adjustAmount = 0;
      setAmount(0);
    } else {
      setAmount(amount - 1);
    }
    onAmountChange(adjustAmount, itemTemplate);
  };

  return (
    <Card>
      <CardMedia>
        <img
          data-src="holder.js/100px180/"
          alt="100%x180"
          style={{ height: '180px', width: '100%', display: 'block' }}
          src={
            process.env.REACT_APP_IMAGE_END_POINT +
            process.env.REACT_APP_BUCKET_IMAGE_FOLDER_NAME +
            process.env.REACT_APP_IMAGE_PREFIX +
            itemTemplate.Id
          }
          data-holder-rendered="true"
          onError={onImageLoadError}
          onClick={increaseAmount}
        />
      </CardMedia>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant={'h6'}>{itemTemplate.Name}</Typography>
          <Typography variant={'body2'}>{'จำนวนที่ต้องการเบิก'}</Typography>
          <Typography variant={'body2'}>{amount}</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              m: 1,
              bgcolor: 'background.paper',
              borderRadius: 1,
            }}
          >
            <Incrementer
              onDecrementQuantity={decreaseAmount}
              onIncrementQuantity={increaseAmount}
              quantity={amount}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

QuickRequestItemCard.PropType = {
  itemTemplate: PropTypes.object.isRequired,
  onAmountChange: PropTypes.func.isRequired,
};
