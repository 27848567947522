import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenErrorNotification } from '../../../utilities/Helper/NotificationHelper';
import { Grid, Stack, Button } from '@mui/material';
import DatePickerWithTypeSelection from '../../date-picker/DatePickerWithTypeSelection';
import { useLocales } from 'locales';
import { usePrintPlanningReport } from '../../../requests/query/usePrintPlanningReport';

export default function PlanningPrintReportPage() {
  const {
    printDeliverySummaryReportExcel,
    printApprovePileExcel,
    printCreatePileExcel,
    printPrintItemMasterRequisitionExcel,
    printPrintItemRequisitionExcel,
  } = usePrintPlanningReport();

  const handlePrintCreateProductOrderExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printDeliverySummaryReportExcel.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.error_contact_admin'));
    }
  };
  const handlePrintCreatePileExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printCreatePileExcel.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.error_contact_admin'));
    }
  };
  const handlePrintApprovePileExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printApprovePileExcel.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.error_contact_admin'));
    }
  };
  const onPrintItemRequisitionExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printPrintItemRequisitionExcel.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.error_contact_admin'));
    }
  };
  const onPrintItemMasterRequisitionExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printPrintItemMasterRequisitionExcel.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.error_contact_admin'));
    }
  };

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { translate } = useLocales();

  return (
    <ProjectPageContainer
      menu_title={translate('planning.print_report_planning_title')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('planning.print_report_planning_title') },
      ]}
    >
      <DatePickerWithTypeSelection
        onFromChange={setFromDate}
        onToChange={setToDate}
        selectionActions={
          <Grid item xs={12} sm={12}>
            <Stack spacing={3}>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={handlePrintCreateProductOrderExcel}
              >{`${translate('production.create_new_product_order')} (Excel)`}</Button>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={handlePrintCreatePileExcel}
              >{`${translate('production.create_pile')} (Excel)`}</Button>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={handlePrintApprovePileExcel}
              >{`${translate('planning.approve_exceed_amount')} (Excel)`}</Button>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={onPrintItemRequisitionExcel}
              >{`${translate('button.request_material_for_po')} (Excel)`}</Button>
              <Button
                fullWidth
                color={'info'}
                variant={'contained'}
                onClick={onPrintItemMasterRequisitionExcel}
              >{`${translate('planning.material_consuming')} (Excel)`}</Button>
            </Stack>
          </Grid>
        }
      />
    </ProjectPageContainer>
  );
}
