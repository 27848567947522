import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { createDeliveryBillFromPiles } from '../../../requests/ProductionServerHandler';
import { PrintMultiplePDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import useAuth from '../../../auth/hooks/useAuth';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from 'locales';
import { delivery_bill_prefix } from '../../../utilities/Strings/Prefix';
import Button from '@mui/material/Button';
import ProjectPaper from '../../customs/ProjectPaper';
import { pileDataTable } from '../../../utilities/Helper/DataHelper';
import { EmployeeScanner } from '../../barcode-reader';
import PileScanner from '../../barcode-reader/PileScanner';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';

function CreateDeliveryBillPage() {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { user } = useAuth();
  const { translate } = useLocales();
  const [checkoutPile, setCheckoutPiles] = useState([]);
  const [submitEmployee, setSubmitEmployee] = useState(
    user !== null
      ? {
          Id: user.EmployeeId,
          Name: user.Name,
          Department: user.Department,
          EmployeeCode: user.EmployeeCode,
          IsTemp: false,
        }
      : null
  );

  const createDeliveryBillMutation = useMutation({
    mutationFn: createDeliveryBillFromPiles,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument(
        response.PDF,
        `${delivery_bill_prefix}_${response.Id}.pdf`
      );
      hideLoadingScreen();
      await OpenNotificationThenReloadPage(
        `${translate('accounting.you_create_delivery_bill')} ${response.Id}`
      );
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  const handleGetEmployeeDetail = (employeeDetail) => {
    setSubmitEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };

  const handleSearchPileId = async (pile) => {
    if (pile !== null) {
      if (checkoutPile.find((selectPile) => selectPile.Id === pile.Id) === undefined) {
        if (
          pile.PileQualityProblemHistories.filter((history) => history.UnHoldAt === null).length ===
          0
        ) {
          if (pile.PileStatus.Id === 6) {
            setCheckoutPiles([...checkoutPile, pile]);
          } else {
            await OpenErrorNotification(
              `${translate('error.error_pile_status')} : ${pile.PileStatus.Name}`
            );
          }
        } else {
          await OpenErrorNotification(translate('error.error_pile_hold_by_qa'));
        }
      } else {
        await OpenErrorNotification(translate('error.pile_already_add'));
      }
    }
  };
  const onRemoveItem = (removeIndex) => {
    setCheckoutPiles(checkoutPile.filter((item, index) => index !== removeIndex));
  };
  const onCheckout = async () => {
    if (checkoutPile.length > 0) {
      let checkoutProductOrders = [];
      checkoutPile.forEach((pile) => {
        const findProductOrder = checkoutProductOrders.find(
          (productOrder) => productOrder.ProductOrderId === pile.ProductOrder.Id
        );
        if (findProductOrder !== undefined) {
          findProductOrder.Piles.push(pile.Id);
        } else {
          checkoutProductOrders.push({
            ProductOrderId: pile.ProductOrder.Id,
            Piles: [pile.Id],
          });
        }
      });

      await createDeliveryBillMutation.mutateAsync({
        CheckoutProductOrders: checkoutProductOrders,
        CreateEmployeeId: submitEmployee.Id,
        IsTemp: submitEmployee.IsTemp,
      });
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('logistic.create_delivery_bill')}
      breadcrumbs={[
        { name: translate('logistic.title') },
        { name: translate('logistic.create_delivery_bill') },
      ]}
    >
      {submitEmployee !== null ? (
        <Stack spacing={3}>
          <Card>
            <CardHeader title={translate('title.delivery_by')} />
            <CardContent>
              <Grid container spacing={3}>
                <GridDetailText title={translate('title.name')} value={submitEmployee.Name} />
                <GridDetailText
                  title={translate('user.employee_code')}
                  value={submitEmployee.EmployeeCode}
                />
                <GridDetailText
                  title={translate('user.department')}
                  value={submitEmployee.Department.Name}
                />
              </Grid>
            </CardContent>
          </Card>
          {checkoutPile.length > 0 && (
            <ProjectPaper>
              {pileDataTable(checkoutPile, onRemoveItem)}
              <Button color={'primary'} variant={'contained'} fullWidth onClick={onCheckout}>
                {translate('button.create')}
              </Button>
            </ProjectPaper>
          )}
          <PileScanner
            onSearchCallBack={handleSearchPileId}
            isPreFetchData
            isShowTooltips
            disableInput={user === null || user.UserTypeId !== ManagementUserTypeId}
          />
        </Stack>
      ) : (
        <EmployeeScanner
          disableInput={user === null || user.UserTypeId !== ManagementUserTypeId}
          onScanCallback={handleGetEmployeeDetail}
          isPreFetchData
          isShowTooltips
        />
      )}
    </ProjectPageContainer>
  );
}

export default CreateDeliveryBillPage;
