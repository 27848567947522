const th = {
  language: 'ไทย',
  login: 'เข้าสู่ระบบ',
  home: 'หน้าหลัก',
  dashboard: 'Dashboard',
  factory: 'โรงงาน',
  factory_employee_login: 'เข้าระบบด้วยบัตร',
  search_page: 'ค้นหา',
  status: {
    already_check: 'ตรวจแล้ว',
    problem: 'งานมีปัญหา',
    title: 'สถานะ',
    success: 'สำเร็จ',
    wait_for_rating: 'รอการประเมิน',
    already_create: 'สร้างแล้ว',
    not_create: 'ยังไม่ได้สร้าง',
    approve: 'อนุมัติแล้ว',
    wait_for_approve: 'รอการอนุมัติ',
    in_plan: 'อยู่ในแผน',
    not_in_plan: 'ไม่อยู่ในแผน',
    normal: 'ปรกติ',
    not_in_machine: 'ยังไม่ได้ลงเครื่องจักร',
    maintenance: 'กำลังซ่อม',
    breakdown: 'เสีย',
    pile_status_1: 'รอการผลิต',
    pile_status_2: 'กำลังผลิต',
    pile_status_3: 'รอตรวจ',
    pile_status_4: 'เสร็จ',
    pile_status_5: 'Hold',
    pile_status_6: 'รอส่ง',
    pile_status_7: 'ยกเลิก',
    mt_status_1: 'รอรับงาน',
    mt_status_2: 'กำลังปฎิบัติงาน',
    mt_status_3: 'รอประเมิน',
    mt_status_4: 'เสร็จ',
    mt_status_5: 'Hold',
    mt_status_6: 'รออะไหล่',
    mt_status_7: 'ยกเลิก',
    mt_status_8: 'รอช่างนอก',
    machine_status_1: 'ปรกติ',
    machine_status_2: 'เสีย',
    machine_status_3: 'กำลังซ่อม',
    machine_status_4: 'ยกเลิก',
    machine_status_5: 'ไม่ใช่ Production',
    cancel_example_bill: 'ยกเลิก (เป็นบิลตัวอย่าง)',
    wait_for_solve: 'รอการแก้ไข',
    solved: 'แก้ไขแล้ว',
    cancel: 'ยกเลิก',
  },
  date: {
    this_year: 'ปีนี้',
    this_month: 'เดือนนี้',
    this_week: 'สัปดาห์นี้',
    this_day: 'วันนี้',
    previous_month: 'เดือนที่แล้ว',
  },
  button: {
    edit_price:'แก้ไขราคา',
    remove_vat:'เอา Vat ออก',
    add_vat:'เพิ่ม Vat',
    select:'เลือก',
    front_camera: 'กล้องหน้า',
    rear_camera: 'กล้องหลัง',
    add_problem_flag: 'รายงานปัญหางาน',
    un_mark_as_focus_job: 'ปลดการเป็นงาน Focus',
    mark_as_focus_job: 'ตั้งเป็นงาน Focus',
    close: 'ปิด',
    view_all: 'ดูทั้งหมด',
    next: 'ต่อไป',
    back: 'ก่อนหน้า',
    create: 'สร้าง',
    change_product_order: 'เปลี่ยนคำสั่งการผลิต',
    search: 'ค้นหา',
    open_camera_for_scan: 'เปิดกล้องเพื่อ Scan',
    close_camera: 'ปิดกล้อง',
    open_front_camera: 'เปิดกล้องหน้า',
    open_rear_camera: 'เปิดกล้องหลัง',
    submit: 'ส่ง',
    logout: 'ออกจากระบบ',
    clear_search: 'ล้างผลการค้นหา',
    cancel: 'ยกเลิก',
    confirm: 'ตกลง',
    ok: 'รับทราบ',
    yes: 'ใช่',
    no: 'ไม่ใช่',
    save: 'บันทึก',
    print: 'พิมพ์',
    lock: 'ล๊อค',
    release: 'ปลด',
    mark_as_complete: 'งานเสร็จเรียบร้อย',
    print_delivery_summary: 'พิมพ์ใบสรุปการส่งงาน',
    print_pile_document: 'พิมพ์ใบเสียบข้างกอง',
    print_production_report: 'พิมพ์ใบสรุป',
    request_material_for_po: 'เบิกวัตถุดิบ',
    accept: 'รับ',
    decline: 'ไม่รับ',
    request_parts_for_mt: 'เบิกอะไหล่',
    request_external_provider: 'ขอใช้ช่างนอก',
    evaluate: 'ประเมิน',
    print_production_plan: 'พิมพ์แผนงาน',
    change: 'เปลี่ยน',
    request: 'เบิก',
    print_item_template_request_document: 'พิมพ์ใบหยิบของ',
    move: 'ย้าย',
    print_item_qr: 'พิมพ์ QR ของ',
    edit_data: 'แก้ไขข้อมูล',
    edit_amount: 'แก้ไขจำนวน',
    create_item_container: 'สร้างภาชนะบรรจุใหม่',
    print_item_qr_whole: 'พิมพ์ QR ทั้งหมดของลอทนี้',
    show_use_item_title: 'Show ของที่ใช้แล้ว',
    hide_use_item_title: 'Hide ของที่ใช้แล้ว',
    item_id: 'รหัสของ',
    print_item_master_excel: 'พิมพ์ใบสรุป Master Excel',
    print_item_container_excel: 'พิมพ์ใบสรุป Container Excel',
    print_item_master_amount_excel: 'พิมพ์ใบสรุปยอด Master Excel',
    add: 'เพิ่ม',
    print_pr: 'พิมพ์ใบ PR',
    print_po: 'พิมพ์ใบ PO',
    edit_address: 'แก้ไข เขต, อำเภอ, จังหวัด',
    create_additional_cost: 'เพิ่มรายการค่าใช้จ่ายเพิ่มเติม',
    selected: 'เลือก',
    already_selected: 'เลือกแล้ว',
    cancel_for_example: 'ยกเลิกบิล เพราะเป็นตัวอย่าง',
    print_snapshot_document_title: 'พิมพ์ใบเก็บข้อมูลวัตถุดิบ',
    not_accept: 'ไม่รับ',
    add_material: 'เพิ่มวัตถุดิบ',
    add_additional_cost: 'เพิ่มค่าใช้จ่ายเพิ่มเติม',
  },
  title: {
    price_that_want_to_change: 'ราคาที่ต้องการเปลี่ยน',
    deliver_at: 'ส่งเมื่อ',
    by:'โดย',
    unit:'หน่วย',
    total_price_title: 'มูลค่ารวม',
    price_per_unit: 'มูลค่าต่อหน่วย',
    quotation_number: 'เลขที่ใบเสนอราคา',
    submit_pile_detail: 'รายละเอียดการผลิต',
    complete_at: 'เสร็จเมื่อ',
    evaluate_at: 'ประเมินเมื่อ',
    score: 'คะแนน',
    max_sell_price: 'ราคาขายสูงสุด',
    min_sell_price: 'ราคาขายต่ำสุด',
    total_bundle_value: 'มูลค่าเหมา',
    total_bundle_amount: 'จำนวนเหมา',
    profile: 'ข้อมูลส่วนตัว',
    price_per_sheet_discount: 'ส่วนลดราคาต่อแผ่น',
    price_per_sqr_discount: 'ส่วนลดราคาต่อตารางนิ้ว',
    product: 'ผลิตภัณฑ์',
    note: 'Note',
    solve_problem: 'แก้ไขปัญหาการผลิต',
    change_value: 'มูลค่าที่เปลี่ยน',
    short_name: 'ชื่อย่อ',
    focus_job: 'งาน Focus',
    edit: 'แก้ไข',
    not_bill: 'ยังไม่ได้วางบิล',
    example_bill: 'บิลงานตัวอย่าง',
    invoice_at: 'วางบิลเมื่อ',
    invoice_by: 'วางบิลโดย',
    all_delivery_bill: 'บิลส่งของทั้งหมด',
    all_billing_note: 'ใบวางบิลทั้งหมด',
    all_po_amount: 'งานทั้งหมดที่เปิด',
    all_po_cancel_amount: 'งานทั้งหมดที่ยกเลิก',
    all_po_complete_amount: 'งานทั้งหมดที่เสร็จ',
    all_po: 'งานทั้งหมด',
    finance: 'การเงิน',
    credit_limit: 'มูลค่าเครดิต',
    address: 'ที่อยู่',
    plan_date: 'แผนของวันที่',
    select_date: 'เลือกวันที่',
    size: 'ขนาด',
    more_detail: 'ดูรายละเอียด',
    free: 'ว่าง',
    use: 'ใช้งาน',
    queue: 'คิว',
    speed: 'ความเร็ว',
    complete_amount: 'จำนวนที่เสร็จ',
    capacity: 'จำนวนที่รับได้',
    productive_rate: 'ProductiveRate',
    queue_amount: 'จำนวนงาน',
    add_machine: 'เพิ่มเครื่องจักร',
    select_all: 'เลือกทั้งหมด',
    po_detail: 'รายละเอียดคำสั่งงานลูกค้า',
    search_result: 'ผลการค้นหา',
    hello: 'สวัสดี',
    detail: 'รายละเอียด',
    performance: 'ประสิทธิภาพงาน',
    value: 'มูลค่า',
    id: 'รหัส',
    name: 'ชื่อ',
    status: 'สถานะ',
    all: 'ทั้งหมด',
    new: 'ใหม่',
    work_in_process: 'กำลังปฎิบัติงาน',
    hold: 'ระงับ',
    pass_due: 'เกินเวลาที่กำหนด',
    create_at: 'สร้างเมื่อ',
    due_at: 'ส่งเมื่อ',
    total_pile_in_amount: 'จำนวนที่เข้ามาแล้ว',
    amount: 'จำนวน',
    customer: 'ลูกค้า',
    start_date: 'วันที่ (เริ่ม)',
    end_date: 'วันที่ (จบ)',
    width: 'กว้าง',
    length: 'ยาว',
    height: 'สูง',
    product_order_subtype: 'ประเภทของงาน',
    sku: 'SKU',
    clear: 'ล้าง',
    price: 'ราคา',
    price_per_sheet: 'ราคาต่อแผ่น',
    price_per_square_inch: 'ราคาต่อตารางนิ้ว',
    bundle_price: 'ราคาเหมา',
    remove: 'ลบ',
    conclude: 'สรุป',
    amphur: 'อำเภอ',
    district: 'เขต',
    province: 'จังหวัด',
    additional_address: 'ที่อยู่เพิ่มเติม',
    tax_registration_number: 'หมายเลขผู้เสียภาษี',
    telephone_number: 'หมายเลขโทรศัพท์',
    create_by: 'สร้างโดย',
    amount_in: 'จำนวนที่เข้ามาแล้ว',
    amount_good: 'จำนวนดี',
    amount_defect: 'จำนวนเสีย',
    amount_adjust: 'จำนวนที่ปรับยอดมาแล้ว',
    pile: 'ขา',
    external_reference_id: 'รหัสอ้างอิงภายนอก',
    complete_height: 'ความสูงเมื่อเสร็จ',
    process: 'กระบวนการผลิต',
    complete_piles: 'ขาที่เสร็จแล้ว',
    un_complete_piles: 'ขาที่ยังไม่เสร็จ',
    total: 'รวม',
    complete_date: 'เสร็จวันที่',
    approve_date: 'อนุมัติวันที่',
    canceled_at: 'ยกเลิกเมื่อ',
    canceled_by: 'ยกเลิกโดย',
    canceled: 'ยกเลิก',
    complete: 'เสร็จ',
    pile_amount: 'จำนวนขางาน',
    delivery_by: 'ส่งโดย',
    empty: 'ไม่มี',
    billing_at: 'วางบิลเมื่อ',
    problem: 'ปัญหา',
    check_by: 'ตรวจโดย',
    solve_at: 'แก้ไขเมื่อ',
    employee_id: 'รหัสพนักงาน',
    machine: 'เครื่องจักร',
    quick_request: 'เบิกของด่วน',
    product_order_number: 'จำนวนคำสั่งลูกค้า',
    product_name: 'ชื่อ Product',
    duration: 'ระยะเวลา',
    minimum_price_per_sheet: 'ราคาต่ำสุดต่อแผ่น',
    minimum_amount_order: 'จำนวนต่ำสุดก่อนราคาเหมา',
    type: 'ประเภท',
    product_type: 'ประเภทผลิตภัณฑ์',
    target_per_month: 'จำนวนเป้าหมายต่อเดือน (แผ่น)',
    minimum_bundle_price: 'มูลค่าขั้นต่ำก่อนเป็นราคาเหมา',
    vat: 'ภาษี',
    discount: 'ส่วนลด',
    discountable: 'ลดได้หรือไม่',
    rows_selected: 'รายการถูกเลือก',
    credit_day: 'จำนวนวัน Credit',
    normal: 'ปรกติ',
    target_per_day: 'จำนวนเป้าหมายต่อวัน (แผ่น)',
    maintenance_job_number: 'จำนวนงานซ่อม',
    diff_amount: 'จำนวนที่เปลี่ยนไป',
    brand: 'ยี่ห้อ',
    safety_amount: 'จำนวน Safety Stock',
    notification: 'แจ้งเตือน',
    none: 'ไม่ได้ระบุ',
    not_complete: 'ยังไม่ครบ',
    category: 'จำพวก',
    priority_level_title: 'ความเร่งด่วน',
    or: 'หรือ',
    upload_file_title: 'อัพโหลดไฟล์เอกสาร',
    approve_at: 'อนุมัติเมื่อ',
    approve: 'อนุมัติ',
    example: 'ตัวอย่าง',
    maintenance_job_id: 'รหัสงานซ่อมบำรุงเลขที่',
    approve_by: 'อนุมัติโดย',
    container_id: 'รหัสภาชนะบรรจุ',
    avg_per_hour: 'จำนวนต่อชั่วโมง',
    total_time: 'เวลาทำงานทั้งหมด',
    by_employee: 'โดยพนักงาน',
    item_receipt_title: 'รายงานการเบิกของ',
    material_type_amount: 'จำนวนประเภทวัตถุดิบ',
    pricing: 'ราคา',
    expire_at: 'หมดอายุเมื่อ',
    minimum_amount: 'จำนวนขั้นต่ำ',
    wait_for_approve: 'รอการอนุมัติ',
    already_approve: 'อนุมัติแล้ว',
    approve_status: 'สถานะการอนุมัติ',
  },
  placeholder: {
    search_result: 'ผลการค้นหาพบ',
    result: 'ผลลัพธ์',
    search: 'ค้นหา...',
    search_product_order: 'ค้นหาด้วย ชื่อลูกค้า, ชื่องาน หรือ รหัสงาน...',
    search_product: 'ค้นหาด้วย ชื่อ, ประเภท หรือ SKU...',
    empty_table: 'ไม่มีขอมูลในตารางนี้',
    empty_data: 'ไม่มีขอมูล',
    please_scan_your_employee_id: 'กรุณา Scan หรือกรอกรหัสพนักงาน',
    camera_guide: 'Scan แค่ QR หรือ Barcode เท่านั้น',
    please_scan_pile_id: 'กรุณา Scan หรือกรอกรหัสขางาน',
    search_id: 'ค้นหาด้วย รหัส...',
    qa_scan: 'กรุณา Scan บนตำแหน่งสำหรับ QA บนใบเสียบข้างกอง',
    please_scan_pile_qr: 'กรุณา Scan QR Code บนใบเสียบข้างกอง',
    please_scan_stock_report_id: 'กรุณา Scan หรือกรอกรหัสใบรับของ',
    search_product_order_machine: 'ค้นหาด้วย ชื่อเครื่อง, ชื่อลูกค้า, ชื่องาน หรือ รหัสงาน...',
    please_scan_item_barcode_id: 'กรุณา Scan หรือกรอกรหัสของ',
  },
  home_menu: {
    title: `เมนู`,
    general: `ทั่วไป`,
  },
  it: {
    title: `IT`,
    create_asset: 'สร้าง Asset',
    create_asset_category: 'สร้าง Asset Category',
    code: 'รหัส Asset',
    serial_number: 'Serial Number',
    brand: 'ยี่ห้อ',
    type: 'ประเภท',
    spec: 'สเปค',
    color: 'สี',
    assign_user_code: 'รหัสผู้รับผิดชอบ',
    asset_category: 'Asset Category',
    warranty_expire: 'วันหมดประกัน',
    note: 'Note',
    create_job: 'แจ้งซ่อมอุปกรณ์ IT',
    asset_code: 'รหัสอุปกรณ์',
    it_job_list: 'รายการแจ้งซ่อมอุปกรณ์ IT',
    asset: 'Asset',
    job_detail: 'รายละเอียดงาน',
    accept_by: 'รับงานโดย',
    create_user: 'สร้าง User ใหม่',
  },
  finance: {
    title: `การเงิน`,
    cash_delivery_bill_list: 'รายการใบเสร็จรับเงินสด',
    cash_delivery_bill_detail: 'รายละเอียดใบเสร็จรับเงินสด',
    already_collect_payment: 'เก็บเงินแล้ว',
    wait_for_collect_payment: 'รอเก็บเงิน',
    payment_reference: 'เลขที่อ้างอิงการชำระเงิน',
    payment_method: 'วิธีการชำระเงิน',
    payment_detail: 'รายละเอียดการชำระเงิน',
    search_cash_delivery_bill_by_id: 'ค้นหาใบเสร็จรับเงินสดด้วยรหัส',
    print_cash_delivery_bill_summary_report_page_title: 'พิมพ์สรุปการเก็บเงินสด',
  },
  production: {
    bom_materials: 'วัตถุดิบใน BOM',
    add_bom: 'เพิ่ม BOM',
    bom: 'BOM',
    title: `การผลิต`,
    product_order_list: 'คำสั่งการผลิตทั้งหมด',
    create_new_product_order: 'สร้างคำสั่งการผลิตใหม่',
    product_order_detail: 'รายละเอียดคำสั่งการผลิต',
    rework_order_id: 'รหัสงานแก้ไข',
    rework_order: 'งานแก้ไข',
    pile_id: 'รหัสขางาน',
    create_pile: 'สร้างขางาน',
    product_order_id: 'เลขที่คำสั่งการผลิต',
    employee_dashboard: 'Dashboard พนักงานผลิต',
    employee_performance: 'Performance พนักงานผลิต',
    submit_production_record: `ส่งรายงานการผลิต`,
    create_production_process_title: 'สร้างกระบวนการผลิต',
    print_machine_production_report_title: 'พิมพ์รายงานการผลิตของเครื่องจักร',
    pile_detail: 'รายละเอียดขางาน',
    production_record: 'บันทึกการผลิต',
    search_product_order: 'ค้นหาด้วยเลขคำสั่งงานลูกค้า',
    search_pile: 'ค้นหาด้วยเลขที่ขางาน',
    amount_to_production: 'จำนวนต้องผลิต',
    product_order: 'คำสั่งงานลูกค้า',
    search_by_name: 'ค้นหาด้วยชื่อ',
    search_old_data: 'ค้นหาข้อมูลเก่า',
    rework_order_detail: 'รายละเอียดงานแก้ไข',
  },
  qa: {
    title: 'ประกันคุณภาพ',
    hold_employee: 'พนักงานที่ Hold',
    pile_quality_problem: 'ปัญหาขางาน',
    quality_assurance_dashboard_title: 'ภาพรวมประกันคุณภาพ',
    quality_assurance_check_dashboard_title: 'ภาพรวมการตรวจประกันคุณภาพ',
    all_active_problem_title: 'รวมงานที่กำลังมีปัญหา',
    approve_pile_quality_title: 'ตรวจคุณภาพขางาน',
    hold_pile_title: 'Hold ขางาน',
    un_hold_pile_title: 'ปลดขางาน',
    approve_quality_by: 'ตรวจคุณภาพโดย',
    approve_quality_at: 'ตรวจคุณภาพเมื่อ',
    problem_list: 'รวมปัญหาที่เกิดขึ้น',
    quality_problem_title: 'ปัญหางาน',
    hold_at: 'Hold เมื่อ',
    hold_by: 'Hold โดย',
    un_hold_at: 'ปลด Hold เมื่อ',
    un_hold_by: 'ปลด Hold โดย',
    all_pile: 'ขาทั้งหมด',
    number_of_quality_check: 'จำนวนที่ตรวจไปแล้ว',
    problem_ranking: 'ปัญหาเรียงตามลำดับ',
    check_complete_title: 'ตรวจเรียบร้อย',
    input_hold_problem_title: 'ใส่ปัญหางาน',
    approve_pile_quality_process_title: 'ตรวจขางานเพิ่มเติม',
  },
  auth: {
    sign_in_to_sil_service: 'เข้าสู้ระบบ SIL Service',
    enter_detail: 'กรุณาใส่รายละเอียดด้านล่าง.',
    tooltip: 'กรุณาติดต่อแผนก IT หากไม่มี Username',
    username: 'ชื่อผู้ใช้',
    password: 'รหัสผ่าน',
  },
  sales: {
    create_order_from_logistic:'สร้าง Order จากจัดส่ง',
    search_quotation: 'ค้นหาด้วยเลขที่ใบเสนอราคา',
    wait_quotation_list: 'รายการใบเสนอราคาที่รออนุมัติ',
    customer_detail: 'รายละเอียดลูกค้า',
    active_job: 'งานที่ Active อยู่',
    customer_list: 'ข้อมูลลูกค้า',
    title: 'การขาย',
    total_sales: 'ยอดขายรวม',
    total_sales_customer: 'ยอดขายรวมรายลูกค้า',
    monthly_sales: 'ยอดขายเดือนนี้',
    year_sales: 'ยอดขายปีนี้',
    total_active_product_order: 'คำสั่งลูกค้าทั้งหมด',
    compare_to_last_year: 'เมื่อเทียบกับปีก่อน',
    latest_product_order: 'คำสั่งลูกค้าล่าสุด',
    select_customer: 'เลือกลูกค้า',
    select_product: 'เลือก Product',
    price_history: 'ประวัติการขาย',
    no_transaction: 'ไม่เคยขาย',
    manage_price: 'จัดการราคาขาย',
    sales_value: 'มูลค่าการขาย',
    additional_cost_bill: 'บิลค่าใช้จ่ายเพิ่มเติม',
    delivery_bill: 'บิลส่งของ',
    billing_note: 'ใบวางบิล',
    create_quotation_title: 'ทำใบเสนอราคา',
    product_management_title: 'จัดการ Product',
    print_customer_report_page_title: 'พิมพ์รายงานลูกค้า',
    print_sales_value_report_page_title: 'พิมพ์รายงานยอดขาย',
    print_product_quotation_page_title: 'พิมพ์ใบเสนอราคาใหม่',
    create_additional_service_cost: 'สร้างรายการค่าใช้จ่ายเพิ่มเติม',
    sales_per_employee: 'ยอดขายตามพนักงาน',
    all_product_title: 'Product ทั้งหมด',
    product_detail: 'รายละเอียด Product',
    latest_sales_date: 'ขายครั้งล่าสุดเมื่อ',
    create_additional_cost_bill: 'สร้างบิลค่าใช้จ่ายเพิ่มเติม',
    additional_cost: 'ค่าใช้จ่ายเพิ่มเติม',
    selected_product_amount: 'จำนวน Product ที่เลือก',
    product_order_additional_cost_bill_detail_title: 'รายละเอียดบิลค่าบริการเพิ่มเติม',
    create_customer_pricing: 'สร้างใบเสนอราคา',
    customer_pricing: 'ใบเสนอราคา',
    middle_price: 'ราคากลาง',
    sell_price: 'ราคาที่ต้องการขาย',
    create_quotation: 'สร้างใบเสนอราคาแบบงาน',
    customer_po_quotation: 'ใบเสนอราคาแบบงาน',
    difference_percent: '% แตกต่าง',
    create_po_from_quotation: 'สร้าง Order จากใบเสนอราคา',
    create_discount_title: 'เพิ่มส่วนลด',
    discount_value: 'จำนวนที่จะลด',
  },
  product: {
    title: 'Product',
    create_new: 'สร้าง Product ใหม่',
    create_bom: 'สร้าง Product BOM ใหม่',
    list: 'รายการ Product',
    bom_list: 'รายการ Product BOM',
    detail: 'รายละเอียด Product',
    type: 'ประเภท Product',
    bom_detail: 'รายละเอียด Product BOM',
    create_material:'สร้างวัตถุดิบใหม่',
    material_list: 'รายการวัตถุดิบ',
    convert_to_amount: 'จำนวน Master ต่อหน่วยวัตถุดิบ',
    bom_type: 'ประเภท BOM',
    amount_per_square_meter: 'จำนวนต่อตารางเมตร',
    material: 'วัตถุดิบ',
    select_bom: 'เลือก BOM',
  },
  unit: {
    title: 'หน่วย',
    sheet: 'แผ่น',
    inch: 'นิ้ว',
    order: 'งาน',
  },
  error: {
    error_pile_id: 'ไม่พบรหัสขางานลูกค้า',
    error_item_id: 'ไม่พบรหัสของ',
    error_product_order_id: 'ไม่พบรหัสคำสั่งงานลูกค้า',
    username_require: 'กรุณาใส่ชื่อผู้ใช้',
    password_require: 'กรุณาใส่รหัสผ่าน',
    unable_to_login: 'ไม่สามารถเข้าสู่ระบบได้',
    error_re_login: 'ไม่ได้รับการอนุมัติ',
    error_contact_admin: 'กรุณาติดต่อ แผนกไอที',
    name_is_require: 'กรุณาใส่ชื่อ',
    width_is_require: 'ความกว้างต้องมากกว่า 5',
    length_is_require: 'ความยาวต้องมากกว่า 5',
    amount_is_require: 'จำนวนต้องมากกว่า 0',
    product_order_subtype_is_require: 'กรุณาเลือกประเภทของงาน',
    error_please_select_one_option: 'กรุณาเลือก 1 ตัวเลือก',
    price_too_low: 'ราคาต่ำเกินไป',
    due_date_require: 'กรุณาเลือกวันส่ง',
    address_require: 'กรุณากรอกที่อยู่',
    tax_registration_number_require: 'กรุณากรอกเลขผู้เสียภาษี',
    telephone_number_require: 'กรุณากรอกเบอร์ติดต่อ',
    product_order_already_complete: 'คำสั่งงานนี้เสร็จไปแล้ว',
    height_require: 'ความสูงต้องมากกว่า 10',
    product_order_id_require: 'กรุณาใส่รหัสคำสั่งลูกค้า',
    product_order_amount_exceed: 'จำนวนที่สร้างเกินจำนวนใน order',
    employee_not_found: 'ไม่พบพนักงานในระบบ',
    pile_not_found: 'ไม่พบขางานที่ต้องการหา',
    machine_is_require: 'กรุณาเลือกเครื่องจักร',
    wrong_date_range: 'วันที่ไม่ถูกต้อง',
    field_is_require: 'ต้องการข้อมูลที่ถูกต้อง',
    invalid_price: 'ราคาที่ใส่ไม่ถูกต้อง',
    invalid_input: 'ข้อมูลที่ใส่ไม่ถูกต้อง',
    detail_require: 'ต้องการรายละเอียด',
    invalid_job_evaluator: 'คุณไม่ใช่เจ้าของงานซ่อม',
    please_re_scan: 'เกิดข้อผิดพลาดกรุณา Scan ใหม่',
    error_qa_pile_already_hold: 'งานถูก Hold อยู่แล้ว',
    error_qa_pile_not_hold: 'งานไม่ถูก Hold อยู่แล้ว',
    error_not_complete_submit_amount: 'ยังกรอกยอดยังไม่ครบ',
    error_pile_status: 'ขางานยังไม่พร้อมส่ง สถานะปัจจุบัน',
    error_pile_hold_by_qa: 'ขางานถูก hold โดย QA',
    pile_already_add: 'ขางานถูกเลือกไปแล้ว',
    error_invalid_amount: 'จำนวนไม่ถูกต้อง',
    error_invalid_item: 'ไม่ใช่ของที่ถูกต้อง',
    insufficient_amount: 'จำนวนมีไม่พอ',
    image_is_require: 'กรุณาใส่รูป',
    invalid_shelf: 'ชั้นวางไม่ถูกต้อง',
    maximum_reach: 'จำนวนเกินกำหนด',
    item_not_found: 'ไม่พบของที่ต้องการหา',
    error_barcode_already_add: 'เพิ่ม Barcode นี้ไปแล้ว',
    not_a_file_type: 'ไม่ใช่ Film',
    error_please_select_address: 'กรุณาเพิ่มที่อยู่',
    invalid_customer: 'ลูกค้าไม่ตรง',
    invalid_barcode: 'Barcode ไม่ถูกต้อง',
    error_invalid_file: 'File ไม่ถูกต้อง',
  },
  notification: {
    success_find_employee: 'พบพนักงาน',
    update_success: 'อัพเดท สำเร็จ!',
    create_success: 'สร้าง สำเร็จ!',
    create_pile_id: 'คุณสร้างขางานเลขที่ : ',
    success_create_item_id_title: 'สร้างสินค้าใหม่สำเร็จด้วยรหัส',
    success_edit_item_id_title: 'แก้ไขสินค้ารหัส',
    drop_or_select_file: 'ลากวาง หรือ กดเลือกรูป',
    success_save_quick_request_item: 'เพิ่มลงในรายการเบิกด่วนสำเร็จ',
    success_remove_quick_request_item: 'ลบในรายการเบิกด่วนสำเร็จ',
    success_create_item_container_id_title: 'สร้างภาชนะสินค้าใหม่สำเร็จด้วยรหัส',
    your_request_has_been_accept: 'คำขอของคุณได้รับการตอบรับ',
    success_upload_purchase_requisition_doc_title: 'ไฟล์อนุมัติ PR Upload สำเร็จ',
    success_create_material_purchase_order_id_title: 'สร้าง PO วัตถุดิบสำเร็จเลขที่ :',
    success_create_parts_purchase_order_id_title: 'สร้าง PO อะไหล่เร็จเลขที่ :',
  },
  user: {
    title: 'ผู้ใช้งาน',
    profile: 'รายละเอียดผู้ใช้งาน',
    employee_uid: 'รหัสผู้ใช้',
    username: 'ชื่อผู้ใช้',
    name: 'ชื่อ',
    last_name: 'นามสกุล',
    employee_code: 'Code พนักงาน',
    department: 'แผนก',
    employee_detail: 'รายละเอียดพนักงาน',
  },
  warehouse: {
    title: 'คลังสินค้า',
    material: 'วัตถุดิบ',
    supplier_name: 'ชื่อที่ Supplier ใช้',
    shelf_detail: 'รายละเอียดชั้นวาง',
    item_requisition_report: 'ใบขอเบิกของ',
    item_receipt_report: 'ใบรับของ',
    parts: 'คลังอะไหล่ / ของทั่วไป',
    material_store: 'คลังวัตถุดิบ',
    store: 'คลัง',
    general_item: 'ของทั่วไป',
    part_item: 'อะไหล่ช่าง',
    accept_inventory: 'รับของเข้าคลัง',
    release_inventory: 'จ่ายของ',
    request_list: 'รายการที่ต้องจ่าย',
    accept_item: 'รับของ',
    return_item: 'คืนของ',
    move_shelf: 'ย้ายชั้นวาง',
    print_warehouse_data_page_title: 'พิมพ์ใบสรุปคลังสินค้า',
    print_item_transaction_data_page_title: 'พิมพ์ใบสรุปการเคลื่อนไหวคลังสินค้า',
    safety_stock_management_title: 'จัดการ Safety Stock',
    create_item_template_title: 'สร้าง Master อะไหล่ / ของทั่วไป',
    create_item_master_title: 'สร้าง Master วัตถุดิบ',
    create_new_unit_title: 'เพิ่มหน่วยใหม่',
    create_new_store: 'สร้างคลังใหม่',
    create_new_shelf: 'สร้างชั้นวางใหม่',
    request_item: 'เบิกของ',
    add_lot: 'เพิ่มลอท',
    create_return_film_lot_title: 'สร้างลอทใหม่จากเศษฟิล์ม',
    need_safety_stock: 'ต้องสั่งของแล้ว',
    items: 'รายการ',
    for_maintenance_job_id: 'สำหรับงานซ่อมบำรุงรหัส',
    request_id: 'รหัสเบิกเลขที่',
    request_item_list: 'รายการของที่จะเบิก',
    amount_to_accept: 'จำนวนที่ต้องการรับเข้า',
    success_create_item_lot_id_title: 'นำเข้าสินค้าสำเร็จเลขที่ลอท',
    item_template_request_report_detail: 'รายละเอียดการขอเบิกของ',
    item_position: 'ตำแหน่งที่วาง',
    current_amount_title: 'จำนวนที่มีปัจจุบัน',
    request_item_list_title: 'รายการของที่ต้องการจะเบิก',
    purchase_item_list_title: 'รายการของที่ต้องการจะสั่งซื้อเพิ่มเติม',
    request_item_amount_title: 'จำนวนที่ต้องการเบิก',
    release_item_amount_title: 'จำนวนที่ต้องการจ่าย',
    pickup_amount_title: 'จำนวนที่หยิบแล้ว',
    request_buy_item_amount_title: 'จำนวนที่ต้องการซื้อ',
    item_template_id: 'รหัส Master ของ',
    search_by_item_template_id: 'ค้นหาสินค้าด้วยรหัสของ',
    search_by_item_template_request_report_id: 'ค้นหาใบเบิกด้วยรหัส',
    search_by_stock_report_id: 'ค้นหาใบรับของด้วยรหัส',
    stock_report_id: 'รหัสใบรับของ',
    amount_to_return: 'จำนวนที่ต้องการคืน',
    shelf_id: 'รหัสชั้นวาง',
    shelf: 'ชั้นวาง',
    target_move_shelf: 'ชั้นวางที่ต้องการย้ายไป',
    items_to_move: 'รายการของที่ต้องการจะย้ายไป',
    order_all_title: 'สั่งทั้งหมด',
    amount_to_buy: 'จำนวนที่ต้องซื้อ',
    amount_in_store_title: 'จำนวนในคลัง',
    amount_need_to_order_title: 'จำนวนที่ควรสั่ง',
    amount_already_order_title: 'จำนวนที่เปิด PO แล้ว',
    order_item: 'สั่งของ',
    amount_to_order: 'จำนวนที่ต้องการสั่งซื้อ',
    have: 'มี',
    in_store: 'ในคลัง',
    item_template_detail: 'รายละเอียดของ',
    movement_history: 'ประวัติการเคลื่อนไหว',
    lot: 'ลอท',
    lot_id: 'รหัสลอท',
    supplier_title: 'Supplier',
    amount_to_print: 'ใส่จำนวนที่ต้องการพิมพ์',
    add_to_quick_request_title: 'เพิ่มของในรายการเบิกด่วน',
    remove_from_quick_request_title: 'ลบของในรายการเบิกด่วน',
    check_out_at_title: 'เบิกออกเมื่อ',
    fulfill_by_title: 'จ่ายของโดย',
    fulfill_at_title: 'จ่ายเมื่อ',
    accept_by_title: 'รับของโดย',
    accept_at_title: 'รับของเมื่อ',
    lot_detail: 'รายละเอียดลอท',
    barcode_id: 'รหัส Barcode',
    new_request_title: 'งานที่ต้องการเบิกใหม่',
    complete_request_title: 'งานที่จ่ายของแล้ว',
    transaction_at_title: 'ทำรายการเมื่อ',
    item_receipt_id: 'รหัสใบจ่ายของ',
    container_detail_title: 'รายละเอียดภาชนะบรรจุ',
    convert_to_master_amount: 'เป็นจำนวน Master',
    add_item_to_inventory: 'นำของเข้า',
    average_use: 'การใช้งานเฉลี่ย',
    for_purchase_order_id: 'สำหรับคำสั่งงานลูกค้ารหัส',
    container_size_title: 'ขนาดภาชนะบรรจุ',
    master_amount: 'จำนวน Master',
    container: 'ภาชนะบรรจุ',
    tag: 'Tag',
    item_container_detail: 'รายละเอียดภาชนะบรรจุ',
    item_master_name: 'ชื่อ Master',
    remain_amount_title: 'จำนวนคงเหลือ',
    create_by_store_employee: 'เพิ่มโดยพนักงานคลัง',
    item_in_lot_detail: 'รายการของในลอท',
    item_detail: 'รายละเอียดวัตถุดิบ',
    defect_item: 'ของเสีย',
    request_item_history: 'ประวัติการเบิก',
    add_to_warehouse_title: 'เพิ่มในคลังสินค้า',
    already_accept_amount: 'รับของแล้วจำนวน',
    request_amount_title: 'จำนวนที่ต้องการ',
    search_by_barcode: 'ค้นหาสินค้าด้วยรหัส Barcode',
    search_by_item_master_id: 'ค้นหาสินค้าด้วยรหัส Master ของ',
    search_item_receipt_by_id: 'ค้นหาใบรับของด้วยรหัส',
    search_lot_by_id: 'ค้นหาลอทด้วยรหัส',
    search_by_sku: 'ค้นหาสินค้าด้วยรหัส SKU ของ',
    create_new_unit: 'เพิ่มหน่วยใหม่',
    item_container_name: 'ชื่อ Container',
    item_master: 'Master วัตถุดิบ',
    item_container: 'Container วัตถุดิบ',
    create_item_request_by_title: 'ขอเบิกโดย',
    create_item_request_at_title: 'ขอเบิกเมื่อ',
    want_item: 'รายการของที่ต้องการ',
    release_item: 'รายการที่จ่ายมา',
    upload_item_price: 'อัพโหลดราคาสินค้า',
  },
  management: {
    title: 'บริหาร',
    approve_special_price_title: 'อนุมัติราคาพิเศษ',
    approve_special_price_history_title: 'ประวัติการอนุมัติราคาพิเศษ',
    create_special_discount_title: 'เพิ่มอนุมัติราคาพิเศษ',
  },
  accounting: {
    title: 'บัญชี',
    bill_type: 'ประเภทบิล',
    cash_bill: 'เงินสด',
    credit_bill: 'เครดิต',
    create_billing_note_title: 'ออกใบวางบิล',
    add_new_customer: 'เพิ่มลูกค้าใหม่',
    search_bill: 'ค้นหาบิล',
    create_claim_record: 'บันทึกการเคลม',
    print_delivery_summary_report_page_title: 'พิมพ์ใบสรุปการส่งของ',
    print_product_sell_summary_report_page_title: 'พิมพ์สรุปการขาย Product',
    disable_customer: 'ล๊อคลูกค้า',
    already_billing: 'วางบิลแล้ว',
    accounting_sales_value: 'ยอดขายจากบัญชี',
    select_delivery_bills: 'เลือกบิลส่งของ',
    select_billing_date: 'เลือกวันวางบิล',
    search_delivery_bill: 'ค้นหาใบส่งของ',
    search_billing_note: 'ค้นหาใบวางบิล',
    delivery_bill_detail: 'รายละเอียดบิลส่งของ',
    already_bill: 'วางบิลแล้ว',
    billing_by: 'วางบิลโดย',
    billing_note_detail: 'รายละเอียดใบวางบิล',
    archive_customer_title: '',
    you_create_delivery_bill: 'คุณสร้างใบส่งของเลขที่',
    delivery_bill_id: 'รหัสใบส่งของ',
    current_sell_price: 'ราคาขายปัจจุบัน',
  },
  admin: {
    title: 'ผู้ดูแลระบบ',
    change_new_password_title: 'เปลี่ยน Password ให้ User',
    print_pile_pdf_page_title: 'พิมพ์ใบเสียบข้างกองใหม่',
    print_delivery_bill_pdf_page_title: 'พิมพ์ใบส่งของใหม่',
    edit_pile_amount_title: 'แก้จำนวนขา',
    edit_product_order_amount_title: 'แก้จำนวนคำสั่งงานลูกค้า',
    edit_size: 'แก้ขนาดคำสั่งงานลูกค้า',
    edit_pile_good_amount_page_title: 'แก้ยอดดีขางาน',
    edit_sell_price_title: 'แก้ราคาขายคำสั่งงานลูกค้าด้วยใบส่งของ',
    edit_sell_price_po_title: 'แก้ราคาขายคำสั่งงานลูกค้าด้วยรหัสงาน',
    edit_price_to_bundle_title: 'แก้ราคาให้เป็นเหมา',
  },
  hr: {
    title: 'บุคคล',
    employee_list: 'รายชื่อพนักงงาน',
    add_employee: 'เพิ่มพนักงานใหม่',
    add_user: 'เพิ่ม user ใหม่',
    remove_employee: 'ปลดพนักงาน',
    add_penalty_record: 'บันทึกใบเตือน',
    add_employee_record: 'บันทึกข้อมูลพนักงาน',
    penalty_level_title: 'ระดับการเตือน',
    absent_number: 'จำนวนขาดงาน',
    leave_number: 'จำนวนลากิจ',
    sick_leave_number: 'จำนวนลาป่วย',
    late_number_in_min: 'จำนวนมาสาย (นาที)',
  },
  logistic: {
    title: 'จัดส่ง',
    create_delivery_bill: 'สร้างใบส่งของ',
    add_car: 'เพิ่มรถจัดส่งใหม่',
    car_registration_number_title: 'ทะเบียนรถ',
    accept_customer_order: 'รับ Order ลูกค้า',
    accept_logistic_pile: 'รับขางานลูกค้า',
  },
  maintenance: {
    title: 'ซ่อมบำรุง',
    change_technician: 'เปลี่ยนช่าง',
    machine_dashboard: 'Dashboard เครื่องจักร',
    assign_maintenance_jobs: 'รวมซ่อมงานที่ได้รับมอบหมาย',
    maintenance_job_list: 'รายการงานซ่อม',
    create_maintenance_job: 'สร้างงานซ่อมใหม่',
    print_maintenance_job_report_page_title: 'พิมพ์ใบสรุปข้อมูลซ่อม',
    complete_job: 'งานทั้งหมดที่เสร็จ',
    total_accept_job: 'งานที่รับแล้วทั้งหมด',
    total_wait_job: 'งานที่รอรับทั้งหมด',
    total_job: 'งานทั้งหมด',
    in_hand_job: 'งานในมือทั้งหมด',
    evaluate_job: 'ประเมินงาน',
    pm: 'บำรุงรักษา',
    electronic: 'ไฟฟ้า',
    mechanical: 'เครื่องกล',
    car: 'รถ',
    project: 'โปรเจค',
    general: 'ทั่วไป',
    machine_maintenance_position_title: 'จุดที่ต้องการแจ้ง',
    maintenance_job_detail: 'รายละเอียดงานซ่อม',
    specialist_title: 'ช่าง',
    parts: 'อะไหล่',
    outsource: 'ช่าง / บริการ ภายนอก',
    maintenance_detail: 'รายละเอียดการซ่อม',
    hour_meter: 'Hour Meter',
    hold_job: 'Hold งานซ่อม',
    resume_job: 'ปลด Hold งานซ่อม',
    evaluate_maintenance_job_detail: 'ประเมินการซ่อม',
    evaluator: 'ผู้ประเมิน',
    search_mt_job: 'ค้นหาด้วยรหัสงานซ่อม',
  },
  planning: {
    add_quality_process:'เพิ่มกระบวนการควบคุณภาพ',
    quality_process:'กระบวนการควบคุณภาพ',
    select_quality_process : 'เลือกกระบวนการควบคุณภาพ',
    wait_for_queue: 'กำลังรอ',
    machine_detail: 'รายละเอียดเครื่อจักร',
    title: 'วางแผน',
    production_planning: 'วางแผนงานผลิต',
    production_planning_old: 'วางแผนงานผลิต (เก่า)',
    approve_exceed_amount: 'อนุมัติยอดขา',
    create_rework_order: 'สร้างงานซ่อมผลิต',
    edit_pile_reference: 'แก้รายละเอียดเพิ่มเติมขางาน',
    print_report_planning_title: 'พิมพ์รายงาน',
    create_order_by_planning: 'สร้างงานโดยวางแผน',
    incoming_product_order: 'งานที่ถูกสร้างทั้งหมด',
    already_planning: 'วางแผนแล้ว',
    not_planning: 'ยังไม่ได้วางแผน',
    machine_planning_list: 'รายการแผนบนเครื่องจักร',
    number_of_queue: 'จำนวนคิวงาน',
    running_queue: 'กำลังวิ่งงานรหัส',
    free_of_queue: 'คิวว่างอยู่',
    assign_to_machine: 'วางคิวลงเครื่อง',
    machine_plan: 'แผนงานเครื่องจักร',
    material_consuming: 'การใช้วัติถุดิบ',
    overall_po: 'ภาพรวมงานทั้งหมด',
    today_work: 'งานวันนี้',
    overall_machine: 'ภาพรวมเครื่องจักร',
    notification: 'แจ้งเตือน',
    all_work: 'งานทั้งหมด',
    all_un_finish_work: 'งานที่ยังไม่เสร็จ',
    all_finish_work: 'งานเสร็จแล้ว',
    un_plan_work: 'งานยังไม่วางแผน',
    today_create_po: 'งานเข้าวันนี้',
    all_hold_po: 'งานค้าง / รอสรุป',
    in_date_desc: 'งานเข้า (ก่อนไปหลัง)',
    in_date_asc: 'งานเข้า (หลังไปก่อน)',
    amount_desc: 'จำนวน (มากไปน้อย)',
    amount_asc: 'จำนวน (น้อยไปมาก)',
    add_to_plan: 'จัดลงตารางงาน',
    pre_plan: 'งานลงตาราง',
    in_machine_plan: 'งานลงเครื่องจักร',
    select_machine: 'เลือกเครื่องจักร',
    order_view: 'มุมมองงาน',
    machine_view: 'มุมมองเครื่องจักร',
    not_in_machine: 'ยังไม่นำเข้าเครื่องจักร',
    add_all_to_plan: 'ลงแผนทั้งหมด',
  },
  procurement: {
    title: 'จัดซื้อ',
    supplier: 'Supplier',
    purchase_requisition_list_title: 'รายการ PR วัตถุดิบ',
    part_purchase_requisition_list_title: 'รายการ PR อะไหล่',
    material_store: 'คลังวัตถุดิบ จัดซื้อ',
    parts_store: 'คลังอะไหล่ / ของทั่วไป จัดซื้อ',
    request_buy_material_title: 'ขอซื้อวัถุดิบ',
    request_buy_parts_title: 'ขอซื้ออะไหล่ / ของทั่วไป',
    purchase_order_list_title: 'รายการ PO วัตถุดิบ',
    part_purchase_order_list_title: 'รายการ PO อะไหล่',
    supplier_report_detail_title: 'Supplier Report',
    create_po_title: 'สร้าง PO วัตถุดิบ',
    create_po_parts_title: 'สร้าง PO อะไหล่ / ของทั่วไป',
    po_reference_id_title: 'รหัสอ้างอิงจัดซื้อ',
    create_purchase_requisition_title: 'สร้าง PR วัตถุดิบ',
    create_parts_purchase_requisition_title: 'ขอซื้อ อะไหล่ / ของทั่วไป',
    success_create_purchase_requisition_id_title: 'สร้าง PR สำเร็จเลขที่',
    create_new_unit_material_title: 'เพิ่มหน่วยวัตถุดิบ',
    create_new_unit_part_title: 'เพิ่มหน่วยอะไหล่ / ของทั่วไป',
    purchase_value_title: 'ราคาซื้อ',
    po_number: 'PO เลขที่',
    amount_purchase: 'จำนวนที่ซื้อ',
    search_po: 'ค้นหา PO วัตถุดิบด้วยรหัส',
    search_po_parts: 'ค้นหา PO อะไหล่ด้วยรหัส',
    search_po_service: 'ค้นหา PO Service ด้วยรหัส',
    search_pr: 'ค้นหา PR วัตถุดิบด้วยรหัส',
    search_pr_parts: 'ค้นหา PR อะไหล่ด้วยรหัส',
    urgent: 'ด่วน',
    not_urgent: 'ไม่ด่วน',
    purchase_requisition_detail: 'รายละเอียดใบขอซื้อ',
    request_to_by_by: 'ขอซื้อโดย',
    approve_by: 'อนุมัติโดย',
    approve_document_title: 'เอกสารอนุมัติ',
    open_document: 'เปิดเอกสาร',
    already_upload: 'อัพโหลดแล้ว',
    upload_by_title: 'อัพโหลดเอกสารโดย',
    purchase_order_detail: 'รายละเอียดใบ PO',
    discount: 'ส่วนลด',
    vat_title: 'ภาษี',
    delivery_price_title: 'ค่าส่ง',
    total_price_title: 'รวมราคา',
    price_per_unit: 'ราคาต่อหน่วย',
    pr_id: 'เลขที่ PR',
    po_id: 'เลขที่ PO',
    price: 'ราคา',
    supplier_sell_item_name: 'ชื่อที่ Supplier ใช้',
    buy_item: 'ซื้อของ',
    search_purchase_item_title: 'ค้นหาของขาย',
    add_supplier_sell_item: 'เพิ่ม Supplier ที่จะขายของ',
    sell_unit: 'หน่วยขาย',
    price_average_detail_title: 'ราคาเฉลี่ย',
    recommend_to_buy_amount: 'จำนวนแนะนำให้ซื้อ',
    edit_supplier_sell_item_template: 'แก้ไขข้อมูลของขาย Master Supplier',
    edit_supplier_sell_item_container: 'แก้ไขข้อมูลของขาย Container Supplier',
    check_out_po_title: 'รายการ PO ที่รอเปิด',
    check_out_supplier_po_title: 'รายการ Supplier PO ที่รอเปิด',
    delivery_at: 'ส่งเมื่อ',
    convert_to_amount: 'ได้เป็นจำนวน',
    buy: 'ซื้อ',
    container_unit_title: 'หน่วยภาชนะบรรจุ',
    sell_unit_amount_per_item_container: 'จำนวนหน่วยขายต่อ 1 ภาชนะบรรจุ',
    price_detail: 'รายละเอียดราคา',
    create_supplier_title: 'สร้าง Supplier ใหม่',
    supplier_material_list_title: 'รายชื่อ Supplier วัตถุดิบทั้งหมด',
    supplier_parts_list_title: 'รายชื่อ Supplier อะไหล่ทั้งหมด',
    procurement_service_purchase_order_title: 'รายการขอช่างนอก',
    contact_name_title: 'ชื่อผู้ติดต่อ',
    sell_material_supplier: 'ขายวัตถุดิบ',
    sell_parts_supplier: 'ขายอะไหล่ / ของทั่วไป',
    print_supplier_report_page_title: 'พิมพ์ใบสรุป Supplier',
    add_sell_item_title: 'เพิ่มรายการขาย Supplier',
    supplier_detail: 'รายละเอียด Supplier',
    total_purchase_value: 'รวมมูลค่าที่ซื้อไป',
    select_item_master_to_create_sell: 'เลือก Master เพื่อเลือกสร้างรายการขาย',
    accept_spo: 'กรอกรายละเอียดการจัดจ้าง',
    spo_detail: 'รายละเอียดการจัดจ้าง',
    already_create_po: 'เปิด PO แล้ว',
    supplier_sell_item: 'ของขาย Supplier',
  },
  sil: {
    title: 'แสงอินเตอร์ลามิเนท',
    p_laminate: 'ลามิเนท',
    p_uv: 'ยูวี',
    p_vanishing: 'อาบ',
    p_hot_stamping: 'ปั้มทอง',
    p_hologram: 'โฮโลแกรม',
    p_embossing: 'อัดลาย',
    p_calendering: 'ขัดเงา',
    p_silk_screen: 'ซิ้ล สกรีน',
    p_free: 'Free',
    p_rework: 'งานซ่อม',
    p_example: 'ตัวอย่าง',
    p_service: 'Service',
    shift: 'กะ',
    yesterday_morning_shift: 'ดูกะเช้าเมื่อวาน (8:00 - 17:00)',
    yesterday_morning_sam_shift: 'ดูกะเช้า SAM เมื่อวาน (17:00 - 20:00)',
    yesterday_night_shift: 'ดูกะเมื่อคืน (20:00 - 4:00)',
    yesterday_night_sam_shift: 'ดูกะเมื่อคืน SAM (4:00 - 8:00)',
    current_shift: 'ดูกะปัจจุบัน (8:00 - ปัจจุบัน)',
  },
  warning: {
    title: 'คำเตือน',
    confirm_approve_quality_check: 'คุณต้องการจะตรวจคุณภาพ Process นี้ ?',
    confirm_un_mark_as_focus_job: 'ยกเลิกตั้งคำสั่งงานลูกค้านี้เป็น focus หรือไม่ ?',
    confirm_mark_as_focus_job: 'ตั้งคำสั่งงานลูกค้านี้เป็น focus หรือไม่ ?',
    remove_special_discount_history: 'คุณต้องการจะลบประวัติส่วนลดพิเศษนี้หรือไม่ ?',
    confirm_edit_data: 'คุณยืนยันที่จะแก้ไขข้อมูลนี้หรือไม่ ?',
    confirm_create_data: 'คุณยืนยันที่จะสร้างข้อมูลนี้หรือไม่ ?',
    confirm_create_billing_note: 'คุณยืนยันที่จะสร้างใบวางบิลนี้หรือไม่ ?',
    confirm_create_product_order: 'คุณยืนยันที่จะสร้างคำสั่งลูกค้านี้หรือไม่ ?',
    confirm_cancel_data: 'คุณยืนยันที่จะยกเลิกข้อมูลนี้หรือไม่ ?',
    confirm_cancel_po: 'คุณต้องการจะปิดคำสั่งงานลูกค้านี้หรือไม่ ?',
    confirm_complete_po: 'คำสั่งงานลูกค้านี้เสร็จแล้วหรือไม่ ?',
    confirm_break_machine: 'คุณต้องการจะเบรคเครื่องจักร ?',
    confirm_resume_machine: 'คุณต้องการจะยกเลิกการเบรคเครื่องจักร ?',
    break_machine_effect: 'การเบรคเครื่องจักรจะทำให้คิวงานทั้งหมดหลุดออก',
    confirm_approve_price: 'คุณต้องการจะอนุมัติการเปลี่ยนราคานี้ ?',
    confirm_approve_pile: 'คุณต้องการจะปลดล๊อคจำนวนขานี้ ?',
    confirm_request_item: 'คุณต้องการจะเบิกของตามรายการนี้ ?',
    confirm_accept_all_item: 'รับของครบแล้วใช่หรือไม่ ?',
    confirm_order_all_safety_stock_amount: 'คุณต้องการจะสั่ง Safety ทุกรายการ ?',
    confirm_delete_data: 'คุณยืนยันที่จะลบข้อมูลนี้หรือไม่ ?',
    error_master_amount_warning: 'กรณี Master ผิดให้แจ้งแผนก IT',
    confirm_add_item_inventory: 'คุณยืนยันที่จะนำของเข้า ?',
    confirm_print_barcode: 'คุณต้องการจะพิมพ์ Barcode ?',
    confirm_print_item_requisition: 'คุณต้องการจะพิมพ์ใบรับของ?',
    is_this_urgent: 'ด่วนหรือไม่',
    confirm_approve_pr: 'คุณต้องการจะอนุมัติ PR นี้หรือไม่',
    confirm_create_po: 'คุณต้องการจะสร้าง PO นี้หรือไม่',
    example_supplier_convert_1: 'เช่น ฟิล์ม 6000 เมตร 1 ม้วนขาย 37.5 กิโลกรัม ก็ใส่ 37.5',
    example_supplier_convert_2: 'เช่น 1 กิโลกรัมขาย 10 บาท ใส่ 10',
    example_supplier_convert_3: 'เช่น 1 แพค 10 บาท ใส่ 10',
    search_po_result_warning: '**** ช่องค้นหาข้างล่างสำหรับการค้นหา ในผลการค้นหาอีกทีเท่านั้น',
    search_po_warning: '**** หากค้นหางานไม่เจอกรุณาค้นหาจากข้อมูลเก่าด้านขวาบน ก่อนสร้างใหม่',
    confirm_cancel_example_bill: 'คุณยืนยันที่จะยกเลิกบิลเพราะเป็นงานตัวอย่างหรือไม่ ?',
    confirm_delete_planning: 'คุณต้องการจะลบการ์ดแผนงานนี้ออก ?',
    confirm_delete_planning_from_machine: 'คุณต้องการจะลบแผนงานนี้ออกจกาเครื่องนี้ ?',
    confirm_delete_product_from_pricing: 'คุณต้องการจะลบ Product นี้ออกจากใบราคา ?',
    confirm_create_pricing: 'คุณต้องการจะสร้างใบเสนอราคานี้ ?',
    confirm_delete_pricing: 'คุณต้องการยกเลิกใบราคานี้ ?',
    confirm_approve_pricing: 'คุณต้องการจะอนุมัติใบเสนอราคานี้ ?',
    confirm_create_po_from_quotation: 'คุณต้องการจะสร้าง order จากใบเสนอราคานี้ ?',
    confirm_approve_discount: 'คุณยืนยันที่จะสร้างส่วนลดนี้หรือไม่ ?',
    confirm_delete_discount: 'คุณยืนยันที่จะลบส่วนลดนี้หรือไม่ ?',
    confirm_payment: 'คุณยืนยันที่การจ่ายเงินนี้หรือไม่ ?',
    confirm_create_asset: 'คุณยืนยันที่จะสร้าง Asset นี้หรือไม่ ?',
    confirm_create_asset_category: 'คุณยืนยันที่จะสร้าง Asset Category นี้หรือไม่ ?',
    confirm_create_it_job: 'คุณยืนยันที่จะแจ้งซ่อมอุปกรณ์ IT นี้หรือไม่ ?',
    confirm_asset_job: 'คุณยืนยันที่จะรับงานนี้หรือไม่ ?',
    confirm_complete_job: 'คุณยืนยันที่จะ Complete งานนี้หรือไม่ ?',
    confirm_cancel_job: 'คุณยืนยันที่จะ Cancel งานนี้หรือไม่ ?',
    confirm_hold_job: 'คุณยืนยันที่จะ Hold งานนี้หรือไม่ ?',
    confirm_verify_job: 'คุณยืนยันที่จะ ประเมิน งานนี้ว่าผ่านหรือไม่ ?',
    confirm_decline_job: 'คุณยืนยันที่จะ ประเมิน งานนี้ว่าไม่ผ่าน ?',
    confirm_change_price: 'คุณยืนยันที่จะเปลี่ยนราคานี้หรือไม่ ?',
    confirm_add_problem: 'คุณยืนยันที่จะรายงานปัญหางานหรือไม่ ?',
    confirm_solve_problem: 'คุณยืนยันที่จะรายงานการแก้ปัญหานี้หรือไม่ ?',
    confirm_upload_file: 'คุณยืนยันที่จะ upload ไฟล์นี้หรือไม่ ?',
    confirm_delete_special_discount: 'คุณยืนยันที่จะลบราคาพิเศษนี้หรือไม่ ?',
    confirm_create_special_discount: 'คุณยืนยันที่จะสร้างราคาพิเศษนี้หรือไม่ ?',
    confirm_edit_customer_name: 'คุณยืนยันที่จะเปลี่ยนชื่อลูกค้านี้หรือไม่ ?',
    confirm_assign_job: 'คุณยืนยันที่จะจ่ายงานนี้ ?',
    confirm_resume_mt_job: 'คุณยืนยันที่จะปลด Hold งานซ่อมนี้?',
    confirm_complete_mt_job: 'คุณยืนยันที่จะปิดงาน งานซ่อมนี้?',
    confirm_request_outsource_for_job:
      'คุณยืนยันที่จะขอช่างนอกสำหรับงานนี้หรือไม่ ? โดยงานจะถูก Hold ไว้',
    confirm_create_maintenance_job: 'คุณยืนยันที่จะแจ้งซ่อมงานนี้ ?',
    confirm_create_customer: 'คุณยืนยันที่จะเพิ่มลูกค้าใหม่ ?',
    confirm_create_additional_cost: 'คุณยืนยันที่จะสร้างรายการใช้จ่ายเพิ่มเติมนี้ ?',
    confirm_cancel_billing_note: 'คุณยืนยันที่จะยกเลิกบิลนี้หรือไม่ ?',
    confirm_edit_employee_performance_data:
      'คุณยืนยันที่จะแก้ไขรายการ Performance พนักงานนี้หรือไม่ ?',
    confirm_change_pile_amount: 'คุณยืนยันที่จะเปลี่ยนจำนวนดีนี้หรือไม่ ?',
    confirm_create_bom: 'คุณยืนยันที่จะสร้าง BOM นี้หรือไม่ ?',
    confirm_create_material: 'คุณยืนยันที่จะสร้าง วัตถุดิบ นี้หรือไม่ ?',
    confirm_select_bom: 'คุณยืนยันที่จะเลือก BOM นี้หรือไม่ ?',
  },
  success: {
    change_technician: 'เปลี่ยนผู้รับผิดชอบเรียบร้อยแล้ว',
    create_asset: 'สร้าง Asset เรียบร้อยแล้ว',
    create_asset_category: 'สร้าง Asset Category เรียบร้อยแล้ว',
    create_it_job: 'แจ้งซ่อมอุปกรณ์ IT เรียบร้อยแล้ว',
    accept_job: 'รับงานเรียบร้อยแล้ว',
    complete_job: 'เสร็จงานเรียบร้อยแล้ว',
    cancel_job: 'ยกเลิกงานเรียบร้อยแล้ว',
    change_price: 'แก้ราคาเรียบร้อยแล้ว',
    approve_pile_quality:'ตรวจคุณภาพขางานเรียบร้อยแล้ว',
  },
};

export default th;
