import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { editSupplier } from '../../requests/WarehouseServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../utilities/Helper/NotificationHelper';
import { editPartSupplier } from '../../requests/ProductionServerHandler';
import ProjectPaper from '../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Grid, Stack } from '@mui/material';
import AddressSelector from './AddressSelector';
import GridDetailText from '../customs/GridDetailText';
import { supplierSchemaValidator } from '../../utilities/Helper/FormValidator';
import { useMutation } from '@tanstack/react-query';

EditSupplierForm.propTypes = {
  supplier: PropTypes.object.isRequired,
  onSaveEdit: PropTypes.func.isRequired,
};

function EditSupplierForm({ supplier, onSaveEdit, supplierType }) {
  const { translate } = useLocales();
  const [isEditDistrict, setIsEditDistrict] = useState(false);
  const editSupplierSchema = supplierSchemaValidator(translate);

  const methods = useForm({
    resolver: yupResolver(editSupplierSchema),
    defaultValues: {
      Name: supplier.Name,
      AdditionalAddress: supplier.AdditionAddress,
      TaxRegistrationNumber: supplier.TaxRegistrationNumber,
      TelephoneNumber: supplier.TelephoneNumber,
      ContactName: supplier.ContactName,
      District: supplier.District,
      CreditDays: supplier.CreditDays,
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const editSupplierDetailMutation = useMutation({
    mutationFn: editSupplier,
    onSuccess: async () => {
      await OpenSuccessNotification();
      onSaveEdit();
    },
  });

  const editPartSupplierDetailMutation = useMutation({
    mutationFn: editPartSupplier,
    onSuccess: async () => {
      await OpenSuccessNotification();
      onSaveEdit();
    },
  });

  const handleEditSupplier = async (values) => {
    if (values.District !== null) {
      await OpenTranslateWarningConfirmNotification(
        translate,
        'warning.confirm_edit_data',
        async () => {
          if (supplierType === 1) {
            await editSupplierDetailMutation.mutateAsync({
              Id: supplier.Id,
              Name: values.Name,
              AdditionAddress: values.AdditionalAddress,
              DistrictId: values.District.Id,
              TaxRegistrationNumber: values.TaxRegistrationNumber,
              TelephoneNumber: values.TelephoneNumber,
              ContactName: values.ContactName,
              CreditDays: values.CreditDays,
            });
          } else {
            await editPartSupplierDetailMutation.mutateAsync({
              Id: supplier.Id,
              Name: values.Name,
              AdditionAddress: values.AdditionalAddress,
              DistrictId: values.District.Id,
              TaxRegistrationNumber: values.TaxRegistrationNumber,
              TelephoneNumber: values.TelephoneNumber,
              ContactName: values.ContactName,
              CreditDays: values.CreditDays,
            });
          }
        }
      );
    } else {
      await OpenErrorNotification(translate('error.error_please_select_address'));
    }
  };

  return (
    <Stack spacing={3}>
      <ProjectPaper>
        <Grid container spacing={3}>
          <GridDetailText
            title={translate('title.type')}
            value={
              supplierType === 1
                ? translate('procurement.sell_material_supplier')
                : translate('procurement.sell_parts_supplier')
            }
          />
        </Grid>
      </ProjectPaper>
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleEditSupplier)}>
          <Stack spacing={3}>
            <RHFTextField name="Name" label={translate('title.name')} />
            <RHFTextField name="AdditionalAddress" label={translate('title.additional_address')} />
            <RHFTextField
              name="TaxRegistrationNumber"
              label={translate('title.tax_registration_number')}
            />
            <RHFTextField name="TelephoneNumber" label={translate('title.telephone_number')} />
            <RHFTextField name="ContactName" label={translate('procurement.contact_name_title')} />
            <RHFTextField name="CreditDays" label={translate('title.credit_day')} />
            {isEditDistrict ? (
              <AddressSelector
                name="District"
                onDistrictChange={setValue}
                error={errors.District !== undefined}
              />
            ) : (
              <Button
                variant={'contained'}
                color={'info'}
                fullWidth
                onClick={() => setIsEditDistrict(true)}
              >
                {translate('button.edit_address')}
              </Button>
            )}
            <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </Stack>
  );
}

export default EditSupplierForm;
