import PropTypes from 'prop-types';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// utils
import { fCurrency, fNumber, fPercent } from '../../utilities/formatNumber';
// theme
import { bgGradient } from '../../theme/css';
// components
import Iconify from '../minimal/iconify';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

export default function DetailCard({
  title,
  total,
  icon,
  percent,
  isDetail = false,
  color = 'primary',
  footer,
  sx,
  ...other
}) {
  const theme = useTheme();
  const { translate } = useLocales();
  return (
    <Stack
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette[color].light, 0.2),
          endColor: alpha(theme.palette[color].main, 0.2),
        }),
        width: 1,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        color: `${color}.darker`,
        backgroundColor: 'common.white',
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          p: 1.5,
          top: 24,
          right: 24,
          width: 48,
          height: 48,
          borderRadius: '50%',
          position: 'absolute',
          color: `${color}.lighter`,
          bgcolor: `${color}.dark`,
        }}
      />

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="h3">{isDetail ? fNumber(total) : fCurrency(total)}</Typography>
        {!isDetail ? (
          <Stack
            spacing={0.5}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            sx={{ typography: 'body2' }}
          >
            <Iconify icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'} />
            <Box sx={{ typography: 'subtitle2' }}>
              {percent > 0 && '+'}
              {fPercent(percent)}
            </Box>

            <Box sx={{ opacity: 0.8 }}>{translate('sales.compare_to_last_year')}</Box>
          </Stack>
        ) : footer ? (
          <Stack
            spacing={0.5}
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            sx={{ typography: 'body2' }}
          >
            <Box sx={{ typography: 'subtitle2' }}>{footer}</Box>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
}

DetailCard.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.string,
  total: PropTypes.number,
  isDetail: PropTypes.bool,
  footer: PropTypes.string,
};
