import React from 'react';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { paths } from 'routes/paths';

function SearchSalesPage() {
    const navigate = useNavigate();
    const { translate } = useLocales();

    const searchType = [
        { value: 1, Name: translate('sales.search_quotation') },
    ];

    const onSearch = async (searchString, type) => {
        if (type === 1) {
            navigate(paths.sales.product_order_quotation_detail(searchString));
        }
    };

    return (
        <ProjectPageContainer
            menu_title={translate('search_page')}
            breadcrumbs={[
                { name: translate('sales.title') },
                { name: translate('search_page') },
            ]}
        >
            <SearchBarWithSelectType
                search_label={translate('placeholder.search_id')}
                select_options={searchType}
                onSearchCallBack={onSearch}
            />
        </ProjectPageContainer>
    );
}

export default SearchSalesPage;
