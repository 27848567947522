import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, CardHeader, IconButton} from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from '../../minimal/iconify';
import GeneralTable from '../../customs/GeneralTable';
import {fDateTimeText} from '../../../utilities/formatTime';
import {RouterLink} from 'routes/components';
import {paths} from 'routes/paths';
import {OPEN_ICON} from '../../../config-global';
import {useLocales} from '../../../locales';
import {MaintenanceJobStatus} from '../../../utilities/Constants';
import Label from "../../minimal/label";
import Stack from "@mui/material/Stack";
import {fCurrency} from "../../../utilities/formatNumber";

MaintenanceJobPartDetail.propTypes = {
    jobDetail: PropTypes.object.isRequired,
};

function MaintenanceJobPartDetail({jobDetail}) {
    const {translate} = useLocales();
    return (
        <>
            {jobDetail !== null && (
                <Stack spacing={2}>
                    <Card>
                        <CardHeader
                            action={
                                <Button
                                    variant="contained"
                                    color={'primary'}
                                    components={RouterLink}
                                    startIcon={<Iconify icon={'ic:baseline-add'}/>}
                                    href={`${paths.general_warehouse.store}?maintenanceJobId=${jobDetail.Id}`}
                                    disabled={[
                                        MaintenanceJobStatus.WaitForAccept,
                                        MaintenanceJobStatus.Complete,
                                        MaintenanceJobStatus.WaitForEvaluation,
                                        MaintenanceJobStatus.Delete,
                                    ].includes(jobDetail?.MaintenanceJobStatus.Id)}
                                >
                                    {translate('button.request_parts_for_mt')}
                                </Button>
                            }
                        />
                        <CardContent>
                            <GeneralTable
                                tableHeaders={[
                                    {align: 'left', title: 'title.id'},
                                    {align: 'left', title: 'title.status'},
                                    {align: 'left', title: 'title.create_at'},
                                    'Actions',
                                ]}
                                tableData={jobDetail?.ItemTemplateRequestReports.map((requestReport) => {
                                    return [
                                        requestReport.Id,
                                        requestReport.CompletedAt !== null ?
                                            <Label color={'success'}>{'จ่ายแล้ว'}</Label> :
                                            <Label color={'error'}>{'ยังไม่จ่าย'}</Label>,
                                        fDateTimeText(requestReport.createdAt),
                                        <IconButton
                                            key={requestReport.Id}
                                            component={RouterLink}
                                            href={paths.general_warehouse.request_report_detail(requestReport.Id)}
                                        >
                                            <Iconify icon={OPEN_ICON}/>
                                        </IconButton>,
                                    ];
                                })}
                            />
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader title={'รายการอะไหล่ที่ขอ'}/>
                        <CardContent>
                            <GeneralTable
                                tableHeaders={[
                                    {align: 'left', title: 'title.id'},
                                    {align: 'left', title: 'ใบเบิกเลขที่'},
                                    {align: 'left', title: 'title.name'},
                                    {align: 'left', title: 'title.amount'},
                                    {align: 'left', title: 'title.price_per_unit'},
                                    {align: 'left', title: 'title.total_price_title'},
                                    'Actions',
                                ]}
                                tableData={jobDetail?.PartCosts.map((part) => {
                                    return [
                                        part.Id,
                                        part.ItemTemplateRequestReportId,
                                        part.Name,
                                        part.Amount,
                                        fCurrency(part.LatestPrice),
                                        fCurrency(part.Amount * part.LatestPrice),
                                    ];
                                })}
                            />
                        </CardContent>
                    </Card>
                </Stack>
            )}
        </>
    );
}

export default MaintenanceJobPartDetail;
