import sumBy from 'lodash/sumBy';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouterLink } from 'routes/components';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Divider,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
// hooks
import useTabs from 'hooks/useTabs';
import {
  getComparator,
  emptyRows,
  useTable,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from 'components/minimal/table';
// components
import Label from 'components/minimal/label';
import Iconify from 'components/minimal/iconify';
import Scrollbar from 'components/minimal/scrollbar';
// sections
import ProductOrderAnalyticCard from '../../custom-product-order-table/ProductOrderAnalyticCard';
import ProductOrderTableRow from '../../custom-product-order-table/ProductOrderTableRow';
import ProductOrderTableToolBar from '../../custom-product-order-table/ProductOrderTableToolBar';
import { useLocales } from 'locales';
import { getProductOrderTableData } from 'utilities/formatServerData';
import { stabilizedThis } from 'utilities/tableHelper';
import { getAllActiveProductOrder } from 'requests/ProductionServerHandler';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

const useAllProductOrder = () => {
  const { data } = useQuery({
    queryKey: ['all-product-order'],
    queryFn: getAllActiveProductOrder,
    initialData: [],
  });

  return {
    tableData: getProductOrderTableData(data),
    customerData: [
      {
        Id: 0,
        Name: 'All',
      },
      ...data
        .map((productOrder) => productOrder.Customer)
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.Id === value.Id && t.Name === value.Name)
        ),
    ],
  };
};

export default function ProductOrderListPage() {
  const theme = useTheme();
  const { translate } = useLocales();
  const { tableData, customerData } = useAllProductOrder();

  const TABLE_HEAD = [
    { id: 'Id', label: translate('title.id'), align: 'left' },
    { id: 'Name', label: translate('title.name'), align: 'left' },
    { id: 'createdAt', label: translate('title.create_at'), align: 'left' },
    { id: 'DueDatedAt', label: translate('title.due_at'), align: 'left', width: 50 },
    { id: 'Amount', label: translate('title.amount'), align: 'center', width: 140 },
    {
      id: 'TotalInAmount',
      label: translate('title.total_pile_in_amount'),
      align: 'center',
      width: 40,
    },
    { id: 'TotalGoodAmount', label: translate('status.pile_status_4'), align: 'center', width: 40 },
    { id: 'ProductOrderStatus', label: translate('title.status'), align: 'left' },
    { id: 'Actions', label: 'Actions' },
  ];

  const { page, order, orderBy, rowsPerPage, setPage, onSort, onChangePage, onChangeRowsPerPage } =
    useTable({ defaultOrderBy: 'createdAt', defaultOrder: 'desc', defaultRowsPerPage: 25 });

  let [searchParams, setSearchParams] = useSearchParams();

  const [filterName, setFilterName] = useState(
    searchParams.get('name') !== null && searchParams.get('name') !== 'null'
      ? searchParams.get('name')
      : ''
  );

  const [filterCustomer, setFilterCustomer] = useState(
    searchParams.get('customer') !== null && searchParams.get('customer') !== 'null'
      ? searchParams.get('customer')
      : 0
  );

  const [filterStartDate, setFilterStartDate] = useState(
    searchParams.get('from') !== null && searchParams.get('from') !== 'null'
      ? new Date(searchParams.get('from'))
      : null
  );

  const [filterEndDate, setFilterEndDate] = useState(
    searchParams.get('to') !== null && searchParams.get('to') !== 'null'
      ? new Date(searchParams.get('to'))
      : null
  );

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('all');

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
    setSearchParams({
      name: filterName,
      customer: searchParams.get('customer'),
      from: searchParams.get('from'),
      to: searchParams.get('to'),
    });
  };

  const handleFilterCustomer = (event) => {
    setFilterCustomer(event.target.value);
    setSearchParams({
      name: searchParams.get('name'),
      customer: event.target.value,
      from: searchParams.get('from'),
      to: searchParams.get('to'),
    });
  };

  const handleFromChange = (newDate) => {
    const timestamp = Date.parse(newDate);
    if (isNaN(timestamp) === false) {
      setSearchParams({
        name: searchParams.get('name'),
        customer: searchParams.get('customer'),
        from: newDate.toISOString(),
        to: searchParams.get('to'),
      });
    } else {
      setSearchParams({
        name: searchParams.get('name'),
        customer: searchParams.get('customer'),
        from: null,
        to: searchParams.get('to'),
      });
    }
    setFilterStartDate(newDate);
  };

  const handleToChange = (newDate) => {
    const timestamp = Date.parse(newDate);
    if (isNaN(timestamp) === false) {
      setSearchParams({
        name: searchParams.get('name'),
        customer: searchParams.get('customer'),
        from: searchParams.get('from'),
        to: newDate.toISOString(),
      });
    } else {
      setSearchParams({
        name: searchParams.get('name'),
        customer: searchParams.get('customer'),
        from: searchParams.get('from'),
        to: null,
      });
    }
    setFilterEndDate(newDate);
  };

  const handleClearFilter = () => {
    setFilterName('');
    setFilterCustomer(0);
    setFilterStartDate(null);
    setFilterEndDate(null);
    setSearchParams({});
  };

  const handleViewRow = (id) => {
    window.open(paths.production.product_order_detail(id), '_blank');
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStatus,
    filterCustomer,
    filterStartDate,
    filterEndDate,
  });

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterCustomer) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const getLengthByStatusId = (id) => {
    if (tableData.length > 0) {
      if (id === 3) {
        return tableData.filter((item) => item.TotalProblem > 0).length;
      }
      return tableData.filter((item) => item.ProductOrderStatus.Id === id).length;
    }

    return 0;
  };

  const getTotalAmountByStatusId = (id) => {
    if (tableData.length > 0) {
      if (id === 3) {
        return sumBy(
          tableData.filter((item) => item.TotalProblem > 0),
          'Amount'
        );
      }
      return sumBy(
        tableData.filter((item) => item.ProductOrderStatus.Id === id),
        'Amount'
      );
    }
    return 0;
  };

  const getPercentByStatusId = (id) => {
    if (tableData.length > 0) {
      return (getLengthByStatusId(id) / tableData.length) * 100;
    }
    return 0;
  };

  const TABS = [
    { value: 'all', label: translate('title.all'), color: 'info', count: tableData.length },
    { value: 1, label: translate('title.new'), color: 'success', count: getLengthByStatusId(1) },
    {
      value: 2,
      label: translate('title.work_in_process'),
      color: 'info',
      count: getLengthByStatusId(2),
    },
    { value: 6, label: translate('title.pass_due'), color: 'error', count: getLengthByStatusId(6) },
    {
      value: 3,
      label: translate('status.problem'),
      color: 'warning',
      count: getLengthByStatusId(3),
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('production.product_order_list')}
      breadcrumbs={[
        { name: translate('production.title') },
        { name: translate('production.product_order_list') },
      ]}
      action={
        <Stack spacing={3} direction="row">
          <Button
            variant="contained"
            color={'info'}
            component={RouterLink}
            href={paths.production.search}
            startIcon={<Iconify icon={'ant-design:search-outlined'} />}
          >
            {translate('production.search_old_data')}
          </Button>
          <Button
            variant="contained"
            component={RouterLink}
            to={paths.sales.create_product_order}
            color={'primary'}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {translate('production.create_new_product_order')}
          </Button>
        </Stack>
      }
    >
      <>
        <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <ProductOrderAnalyticCard
                title={translate('title.all')}
                total={tableData.length}
                percent={100}
                amount={sumBy(tableData, 'Amount')}
                icon="icon-park-outline:transaction-order"
                color={theme.palette.warning.dark}
              />
              <ProductOrderAnalyticCard
                title={translate('title.new')}
                total={getLengthByStatusId(1)}
                percent={getPercentByStatusId(1)}
                amount={getTotalAmountByStatusId(1)}
                icon="entypo:new"
                color={theme.palette.primary.main}
              />
              <ProductOrderAnalyticCard
                title={translate('title.work_in_process')}
                total={getLengthByStatusId(2)}
                percent={getPercentByStatusId(2)}
                amount={getTotalAmountByStatusId(2)}
                icon="carbon:in-progress"
                color={theme.palette.info.main}
              />
              <ProductOrderAnalyticCard
                title={translate('title.pass_due')}
                total={getLengthByStatusId(6)}
                percent={getPercentByStatusId(6)}
                amount={getTotalAmountByStatusId(6)}
                icon="fluent:clipboard-error-20-regular"
                color={theme.palette.error.main}
              />
              <ProductOrderAnalyticCard
                title={translate('status.problem')}
                total={getLengthByStatusId(3)}
                percent={getPercentByStatusId(3)}
                amount={getTotalAmountByStatusId(3)}
                icon="akar-icons:stop"
                color={theme.palette.warning.main}
              />
            </Stack>
          </Scrollbar>
        </Card>
        <Typography variant={'h3'} color={'error'}>
          {translate('warning.search_po_warning')}
        </Typography>
        <br />
        <Card>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={<Label color={tab.color}> {tab.count} </Label>}
                label={tab.label}
              />
            ))}
          </Tabs>

          <Divider />

          <ProductOrderTableToolBar
            filterName={filterName}
            filterCustomer={filterCustomer}
            filterStartDate={filterStartDate}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            onFilterCustomer={handleFilterCustomer}
            onFilterStartDate={handleFromChange}
            onFilterEndDate={handleToChange}
            onFilterClear={handleClearFilter}
            optionsCustomer={customerData}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  onSort={onSort}
                />
                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <ProductOrderTableRow
                        key={row.Id}
                        row={row}
                        onViewRow={() => handleViewRow(row.Id)}
                      />
                    ))}
                  <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
          </Box>
        </Card>
      </>
    </ProjectPageContainer>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  comparator,
  filterName,
  filterStatus,
  filterCustomer,
  filterStartDate,
  filterEndDate,
}) {
  tableData = stabilizedThis(tableData, comparator).map((el) => el[0]);
  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.Id.toString().toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.Name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.Customer.Name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    if (filterStatus === 3) {
      tableData = tableData.filter((item) => item.TotalProblem > 0);
    } else {
      tableData = tableData.filter((item) => item.ProductOrderStatus.Id === filterStatus);
    }
  }

  if (filterCustomer > 0) {
    tableData = tableData.filter((item) => item.Customer.Id === filterCustomer);
  }

  if (filterStartDate && filterEndDate) {
    tableData = tableData.filter(
      (item) =>
        item.createdAt.getTime() >= filterStartDate.getTime() &&
        item.createdAt.getTime() <= filterEndDate.getTime()
    );
  }

  return tableData;
}
