import { configureStore } from '@reduxjs/toolkit'
import interfaceReducer from 'redux/reducers/interface'
import userReducer from 'redux/reducers/user'
import inventoryReducer from 'redux/reducers/inventory'
import procurementReducer from 'redux/reducers/procurement'
import salesReducer from 'redux/reducers/sales'
import planningReducer from 'redux/reducers/planning'
import {persistReducer, persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";

export const rootReducer = combineReducers({
    interface: interfaceReducer,
    user: userReducer,
    inventory: inventoryReducer,
    procurement: procurementReducer,
    sales:salesReducer,
    planning: planningReducer
});
const persistConfig = {
    key: 'hubnova-sil-service',
    storage,
    whitelist: ['user']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer
})

export let persistor = persistStore(store);
