import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { editPileReferenceId } from '../../../requests/ProductionServerHandler';
import { Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { useLocales } from 'locales';
import Button from '@mui/material/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';

function EditPileReferenceIdPage() {
  const { translate } = useLocales();

  const editPileReferenceIdMutation = useMutation({
    mutationFn: editPileReferenceId,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const onSubmit = async (data) => {
    await editPileReferenceIdMutation.mutateAsync({
      PileId: data.PileId,
      ReferenceId: data.ReferenceId,
    });
  };
  const EditPileExternalReferenceSchema = Yup.object().shape({
    PileId: Yup.number().required(translate('error.invalid_input')),
    ReferenceId: Yup.string().required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(EditPileExternalReferenceSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('planning.edit_pile_reference')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('planning.edit_pile_reference') },
      ]}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name={'PileId'} label={translate('production.pile_id')} />
            <RHFTextField name={'ReferenceId'} label={translate('title.external_reference_id')} />
            <Button variant={'contained'} type={'submit'} color={'primary'}>
              {translate('button.change')}
            </Button>
          </Stack>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
}

export default EditPileReferenceIdPage;
