import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  approveCashDeliveryBill,
  getCashDeliveryBillDetail,
  getDeliveryBillPDFById,
} from '../ProductionServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';
import { useLoading } from '../../components/loading';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { delivery_bill_prefix } from '../../utilities/Strings/Prefix';

export const useCashDeliveryBill = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  const { data } = useQuery({
    queryKey: ['cash-delivery-bill', id],
    queryFn: () => getCashDeliveryBillDetail(id),
    initialData: null,
  });

  const approveCashDeliveryBillMutation = useMutation({
    mutationFn: approveCashDeliveryBill,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['cash-delivery-bill', id]);
      await OpenSuccessNotification();
    },
  });

  const printMutation = useMutation({
    mutationFn: getDeliveryBillPDFById,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (pdfStructure) => {
      hideLoadingScreen();
      await PrintMultiplePDFMakeDocument(pdfStructure, `${delivery_bill_prefix}_${id}.pdf`);
      await OpenSuccessNotification();
    },
  });

  return {
    cashDeliveryBill: data,
    approveCashDeliveryBill: approveCashDeliveryBillMutation,
    printPDF: printMutation,
  };
};
