import { RouterLink } from 'routes/components';
// @mui
import { Button, Typography, Box } from '@mui/material';
// components
import { MaintenanceIllustration } from 'assets';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useLocales } from 'locales';

export default function MaintenancePage() {
  const { translate } = useLocales();
  return (
    <ProjectPageContainer menu_title="Maintenance">
      <Box sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Sorry, This page is currently under maintenance
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          We are currently working hard on this page!
        </Typography>

        <MaintenanceIllustration sx={{ my: 10, height: 240 }} />

        <Button variant="contained" size="large" component={RouterLink} to="/">
          {translate('home')}
        </Button>
      </Box>
    </ProjectPageContainer>
  );
}
