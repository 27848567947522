import React from 'react';
import {IconButton} from '@mui/material';
import Iconify from 'components/minimal/iconify';
import GeneralTable from '../../components/customs/GeneralTable';
import imageCompression from 'browser-image-compression';

export const calculateProductPerSheet = (width, length, pricePerSqInch) => {
    const perSheet = width * length * pricePerSqInch
    return parseFloat(perSheet.toFixed(5));
}

export const covertSQInchToSQMeter = (squareInch) => {
    return squareInch * 0.00064516000000258;
};

export const isNeedSafety = (safetyAmount, remainAmount) => {
    if (safetyAmount > 0) {
        return remainAmount <= safetyAmount;
    } else {
        return false;
    }
};

export const pileDataTable = (piles, onRemove) => {
    return (
        <GeneralTable
            tableHeaders={[
                {align: 'left', title: '#'},
                {align: 'left', title: 'title.id'},
                {align: 'left', title: 'title.name'},
                {align: 'left', title: 'title.customer'},
                {align: 'left', title: 'title.amount_good'},
                {align: 'left', title: 'title.amount_defect'},
                {align: 'left', title: ''},
            ]}
            tableData={piles.map((pile, index) => {
                return [
                    index + 1,
                    pile.Id,
                    pile.ProductOrder.Name,
                    pile.ProductOrder.Customer.Name,
                    pile.GoodAmount,
                    pile.DefectAmount,
                    <IconButton key={pile.Id} color={'error'} onClick={() => onRemove(index)}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20}/>
                    </IconButton>,
                ];
            })}
        />
    );
};
export const getCompressImage = async (image) => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
    };

    return await imageCompression(image, options);
};

export const getItemTemplateStoreTableData = (isMobile, itemTemplates) => {
    if (isMobile) {
        return itemTemplates.map((itemTemplate) => {
            return {
                id: itemTemplate.Id,
                name: itemTemplate.Name,
                amount: itemTemplate.CurrentAmount,
                actions: itemTemplate,
            };
        });
    }
    return itemTemplates.map((itemTemplate) => {
        return {
            id: itemTemplate.Id,
            name: itemTemplate.Name,
            type: itemTemplate.ItemTemplateType.Name,
            safety: itemTemplate.SafetyAmount,
            amount: itemTemplate.CurrentAmount,
            unit: itemTemplate.ItemTemplateUnit.Name,
            is_safety: {
                ItemId: itemTemplate.Id,
                IsSafety: isNeedSafety(itemTemplate.SafetyAmount, itemTemplate.CurrentAmount),
            },
            actions: itemTemplate,
        };
    });
};

export const getRealItemMasterAmount = (itemMaster) => {
    return itemMaster.ItemContainers.reduce((total, itemContainer) => {
        const totalMasterInAllLot = itemContainer.ItemLots.reduce((totalLot, itemLot) => {
            return (
                totalLot +
                itemLot.Items.reduce((totalLot, item) => {
                    return totalLot + item.RemainAmount;
                }, 0)
            );
        }, 0);
        return total + totalMasterInAllLot;
    }, 0);
};

export const sortById = (a, b) => {
    return b.Id - a.Id;
};

export const getTotalInThisMachine = (plans, capacity) => {
    return (
        capacity -
        plans.reduce((totalAmount, plan) => {
            return totalAmount + plan.ProductOrder.Amount;
        }, 0)
    );
};
export const getTotalComplete = (plan) => {
    return plan.ProductOrder.Piles.reduce((totalComplete, pile) => {
        return (
            totalComplete +
            pile.ProductionRecords.reduce((totalProcessComplete, record) => {
                if (record.ProductionProcessId === plan.ProductionProcessId) {
                    return totalProcessComplete + record.GoodAmount;
                } else {
                    return totalProcessComplete;
                }
            }, 0)
        );
    }, 0);
};
export const getCompletePercentage = (plan) => {
    return (getTotalComplete(plan) * 100) / plan.ProductOrder.Amount;
};
export const calculateDiscountPrice = (price, discountPercent) => {
    return price - (price * discountPercent) / 100;
};

export const getPaperSizeText = (width, length) => {
    const size = width * length;
    return `${size}`;
};

export const getProductOrderPrice = (productOrderPriceDetails, amount) => {
    return productOrderPriceDetails.reduce((totalPrice, productOrderPriceDetail) => {
        const processPrice = productOrderPriceDetail.IsBundle
            ? productOrderPriceDetail.BundlePrice
            : productOrderPriceDetail.SellPrice * amount;
        return totalPrice + processPrice;
    }, 0);
};
