import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getAllItemTemplateByCategoryId } from '../../../requests/ProductionServerHandler';
import { Box, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import useTabs from '../../../hooks/useTabs';
import Iconify from 'components/minimal/iconify';
import ReactTable from '../../react-table/ReactTable';
import ProjectPaper from '../../customs/ProjectPaper';
import { paths } from '../../../routes/paths';
import { useQuery } from '@tanstack/react-query';

const SearchItemTemplateToCreateSellItemTemplatePage = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { supplier } = useParams();
  const { currentTab, onChangeTab } = useTabs(2);
  const { data } = useQuery({
    queryKey: ['getAllItemTemplateByCategoryId', currentTab],
    queryFn: () => getAllItemTemplateByCategoryId(currentTab),
    initialData: [],
    enabled: currentTab !== null,
  });
  const getItemTemplateTable = () => {
    return (
      <ProjectPaper>
        <ReactTable
          search
          defaultPageSize={25}
          data={data.map((itemTemplate) => {
            return {
              id: itemTemplate.Id,
              name: itemTemplate.Name,
              type: itemTemplate.ItemTemplateType.Name,
              safety: itemTemplate.SafetyAmount,
              amount: itemTemplate.CurrentAmount,
              unit: itemTemplate.ItemTemplateUnit.Name,
              actions: itemTemplate.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.safety_amount'),
              accessorKey: 'safety',
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
            },
            {
              header: translate('unit.title'),
              accessorKey: 'unit',
            },
            {
              header: '',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    color={'success'}
                    onClick={() =>
                      navigate(
                        paths.procurement.create_supplier_sell_item_template +
                          `?SupplierId=${supplier}&ItemTemplateId=${info.getValue()}`
                      )
                    }
                  >
                    <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    );
  };
  const INFORMATION_TABS = [
    {
      value: 2,
      title: translate('warehouse.part_item'),
      icon: <Iconify icon={'ant-design:setting-outlined'} width={20} height={20} />,
      component: getItemTemplateTable(),
    },
    {
      value: 3,
      title: translate('warehouse.general_item'),
      icon: <Iconify icon={'akar-icons:shipping-box-01'} width={20} height={20} />,
      component: getItemTemplateTable(),
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.select_item_master_to_create_sell')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.supplier_parts_list_title'),
          href: paths.procurement.supplier_parts_list,
        },
        {
          name: `${translate('procurement.supplier_detail')}${translate('title.id')} : ${supplier}`,
          href: paths.procurement.part_supplier_detail(supplier),
        },
        { name: translate('procurement.select_item_master_to_create_sell') },
      ]}
    >
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
};

export default SearchItemTemplateToCreateSellItemTemplatePage;
