import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Grid, Button } from '@mui/material';
import { RouterLink } from 'routes/components';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import EmptyContent from '../../customs/EmptyContent';
import { getProductOrderItemRequisitionDetailById } from '../../../requests/WarehouseServerHandler';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  ManagementUserTypeId,
  ProductionControlUserTypeId,
  SalesUserTypeId,
} from '../../../utilities/Constants';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

ProductOrderMaterialDetail.propTypes = {
  productOrderId: PropTypes.number,
};

function ProductOrderMaterialDetail({ productOrderId }) {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { data: allItemRequisition } = useQuery({
    queryKey: ['getProductOrderItemRequisitionDetailById', productOrderId],
    queryFn: () => getProductOrderItemRequisitionDetailById(productOrderId),
    initialData: [],
  });
  const getIcon = (itemRequisitionReport) => {
    if (itemRequisitionReport.CompletedAt !== null) {
      return (
        <Iconify
          icon={'eva:checkmark-circle-2-outline'}
          sx={{ color: 'success.main' }}
          width={40}
          height={40}
        />
      );
    }
    if (itemRequisitionReport.CanceledAt !== null) {
      return (
        <Iconify
          icon={'eva:close-circle-outline'}
          sx={{ color: 'error.main' }}
          width={40}
          height={40}
        />
      );
    }
    return (
      <Iconify icon={'eos-icons:loading'} sx={{ color: 'warning.main' }} width={40} height={40} />
    );
  };

  const getStatus = (itemRequisitionReport) => {
    if (itemRequisitionReport.CompletedAt !== null) {
      return (
        <GridDetailText
          title={translate('title.status')}
          value={translate('title.complete')}
          color={'success.main'}
        />
      );
    }
    if (itemRequisitionReport.CanceledAt !== null) {
      return (
        <GridDetailText
          title={translate('title.status')}
          value={translate('title.canceled')}
          color={'error.main'}
        />
      );
    }
    return (
      <GridDetailText
        title={translate('title.status')}
        value={translate('title.work_in_process')}
        color={'warning.main'}
      />
    );
  };

  const onRequestMaterialForJob = async () => {
    navigate(`${paths.warehouse.store}?ProductOrderId=${productOrderId}`);
  };

  return (
    <Card>
      <CardHeader
        title={translate('warehouse.item_requisition_report')}
        action={
          <RoleBasedGuard
            roles={[
              AdminUserTypeId,
              ManagementUserTypeId,
              SalesUserTypeId,
              AccountingUserTypeId,
              ProductionControlUserTypeId,
            ]}
          >
            <Button
              variant="contained"
              color={'primary'}
              startIcon={<Iconify icon={'ic:baseline-add'} />}
              onClick={onRequestMaterialForJob}
            >
              {translate('button.request_material_for_po')}
            </Button>
          </RoleBasedGuard>
        }
      />
      <CardContent>
        {allItemRequisition.length > 0 ? (
          allItemRequisition.map((report) => {
            const itemRequisitionReport = report.ItemRequisitionReport;
            return (
              <Card key={itemRequisitionReport.Id}>
                <CardHeader
                  title={`${translate('warehouse.item_requisition_report')}${translate(
                    'title.id'
                  )}: ${itemRequisitionReport.Id}`}
                  action={getIcon(itemRequisitionReport)}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <GridDetailText
                      title={translate('title.detail')}
                      value={itemRequisitionReport.Detail}
                    />
                    <GridDetailText
                      title={translate('title.create_by')}
                      value={getEmployeeFullName(itemRequisitionReport.CreateEmployee)}
                    />
                    <GridDetailText
                      title={translate('title.create_at')}
                      value={fDateTimeText(itemRequisitionReport.createdAt)}
                    />
                    {itemRequisitionReport.CanceledAt !== null ? (
                      <GridDetailText
                        title={translate('title.canceled_at')}
                        value={fDateTimeText(itemRequisitionReport.CanceledAt)}
                      />
                    ) : (
                      <GridDetailText
                        title={translate('title.complete_date')}
                        value={
                          itemRequisitionReport.CompletedAt !== null
                            ? fDateTimeText(itemRequisitionReport.CompletedAt)
                            : 'N/A'
                        }
                      />
                    )}
                    {getStatus(itemRequisitionReport)}
                  </Grid>
                </CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 1,
                    m: 1,
                  }}
                >
                  <Button
                    variant={'contained'}
                    color="info"
                    component={RouterLink}
                    href={paths.warehouse.item_requisition_report_detail(itemRequisitionReport.Id)}
                  >
                    {translate('title.detail')}
                  </Button>
                </Box>
              </Card>
            );
          })
        ) : (
          <EmptyContent
            title={translate('title.empty')}
            description={translate('placeholder.empty_table')}
            img="/assets/illustrations/illustration_empty_content.svg"
          />
        )}
      </CardContent>
    </Card>
  );
}

export default ProductOrderMaterialDetail;
