import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../locales';

AcceptItemForm.propTypes = {
  acceptItemDetail: PropTypes.object.isRequired,
  allItemContainer: PropTypes.array.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

function AcceptItemForm({ acceptItemDetail, allItemContainer, onFormSubmit }) {
  const { translate } = useLocales();

  const reworkOrderSchema = Yup.object().shape({
    ItemContainer: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    Amount: Yup.number()
      .min(1, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
  });

  const methods = useForm({
    resolver: yupResolver(reworkOrderSchema),
    defaultValues: {
      ItemContainer: {
        Id: acceptItemDetail.ItemContainer.Id,
        Name: acceptItemDetail.ItemContainer.Name,
      },
      Amount: acceptItemDetail.ItemContainerAmount - acceptItemDetail.AlreadyAcceptAmount,
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={3}>
        <RHFAutoCompleteShort
          name={'ItemContainer'}
          label={translate('warehouse.container')}
          data={allItemContainer}
        />
        <RHFTextField
          name={'Amount'}
          type={'number'}
          label={`${translate('title.amount')} (${acceptItemDetail.ItemContainer.ItemUnit.Name})`}
        />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('warehouse.add_item_to_inventory')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default AcceptItemForm;
