import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createItemLotFromAdmin,
  getItemLotById,
  getWholeItemLotQRCode,
} from '../WarehouseServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { useLoading } from '../../components/loading';

export const useItemLotDetail = (id) => {
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['item-lot-detail', id],
    queryFn: () => getItemLotById(id),
    enabled: !!id,
  });

  const createItemLotFromAdminMutation = useMutation({
    mutationFn: createItemLotFromAdmin,
    onSuccess: async () => {
      await OpenSuccessNotification();
    },
  });

  const printWholeQRPDFMutation = useMutation({
    mutationFn: getWholeItemLotQRCode,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument([response.PDF], `inventory_qr_code_${id}.pdf`);
      await hideLoadingScreen();
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
      await hideLoadingScreen();
    },
  });

  return {
    itemLot: data,
    createLotAdmin: createItemLotFromAdminMutation,
    printWholeQRPDF: printWholeQRPDFMutation,
  };
};
