import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from '../../../locales';
import { useQuery } from '@tanstack/react-query';
import { getAllLogisticOrder } from '../../../requests/ITServerHandler';
import ReactTable from '../../react-table/ReactTable';
import ProjectPaper from '../../customs/ProjectPaper';
import { fDateTimeTextShort } from '../../../utilities/formatTime';
import { IconButton } from '@mui/material';
import Link from '@mui/material/Link';
import { paths } from '../../../routes/paths';
import Iconify from '../../minimal/iconify';
import { OPEN_ICON } from '../../../config-global';

function CreateProductOrderFromLogisticPage() {
  const { translate } = useLocales();

  const { data } = useQuery({
    queryKey: ['logistic-orders'],
    queryFn: () => getAllLogisticOrder(),
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('sales.create_order_from_logistic')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('sales.create_order_from_logistic') },
      ]}
    >
      <ProjectPaper>
        <ReactTable search
                    defaultPageSize={10}
                    data={data.map((order)=>{
                      return {
                        name: order.name,
                        customer: order.customer.name,
                        size: `${order.width} x ${order.length}`,
                        amount: order.amount,
                        pile_amount: order.pileCount,
                        create_at: order.created_at,
                        action:order.id
                      }
                    })}
                    header={[
                      {
                        header: translate('title.name'),
                        accessorKey: 'name',
                      },
                      {
                        header: translate('title.customer'),
                        accessorKey: 'customer',
                      },
                      {
                        header: `${translate('title.size')} (นิ้ว)`,
                        accessorKey: 'size',
                      },
                      {
                        header: translate('title.amount'),
                        accessorKey: 'amount',
                        size:10
                      },
                      {
                        header: translate('title.pile_amount'),
                        accessorKey: 'pile_amount',
                        size:10
                      },
                      {
                        header: translate('title.create_at'),
                        accessorKey: 'create_at',
                        cell: (info) => fDateTimeTextShort(info.getValue()),
                      },
                      {
                        header: 'Actions',
                        accessorKey: 'action',
                        cell: (info) => (
                          <IconButton
                            component={Link}
                            href={paths.sales.create_order_from_logistic_by_id(info.getValue())}
                          >
                            <Iconify icon={OPEN_ICON} />
                          </IconButton>
                        ),
                      },
                    ]}/>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateProductOrderFromLogisticPage;