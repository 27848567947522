import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getAllProductionProcess } from '../../requests/ProductionServerHandler';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DebouncedInput, fuzzyFilter } from '../react-table/utils';
import { Alert, Box, Checkbox } from '@mui/material';
import { useLocales } from '../../locales';
import ProjectPaper from '../customs/ProjectPaper';
import ReactTableSkeleton from '../react-table/ReactTableSkeleton';
import Button from '@mui/material/Button';
import { useQuery } from '@tanstack/react-query';

ReworkOrderSelectProcessForm.propTypes = {
  defaultSelectProcess: PropTypes.object,
  onBackPressed: PropTypes.func.isRequired,
  onSelectProcess: PropTypes.func.isRequired,
};

function ReworkOrderSelectProcessForm({ defaultSelectProcess, onBackPressed, onSelectProcess }) {
  const { translate } = useLocales();
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState(defaultSelectProcess);

  const { data: allProductionProcess } = useQuery({
    queryKey: ['getAllProductionProcess'],
    queryFn: getAllProductionProcess,
    initialData: [],
  });

  const allProcessData = useMemo(() => {
    return allProductionProcess.map((process) => {
      return {
        id: process.Id,
        name: process.Name,
        type: process.ProductionProcessType.Name,
      };
    });
  }, [allProductionProcess]);

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            indeterminate={table.getIsSomeRowsSelected()}
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        ),
      },
      {
        header: translate('title.name'),
        accessorKey: 'name',
      },
      {
        header: translate('title.type'),
        accessorKey: 'type',
      },
    ],
    [translate]
  );

  const table = useReactTable({
    data: allProcessData,
    columns,
    state: {
      sorting,
      globalFilter,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    globalFilterFn: fuzzyFilter,
  });

  const handleCompleteSelectProductionProcess = () => {
    const selected = [];
    Object.keys(rowSelection).forEach((objectIndex) => {
      selected.push(allProductionProcess[objectIndex]);
    });
    onSelectProcess(selected, rowSelection);
  };

  return (
    <ProjectPaper>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
      />
      {Object.keys(rowSelection).length > 0 && (
        <Alert sx={{ mb: 2 }} onClose={() => table.toggleAllRowsSelected(false)}>
          {`${Object.keys(rowSelection).length}  ${translate('title.rows_selected')}`}
        </Alert>
      )}
      <ReactTableSkeleton table={table} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button color={'primary'} onClick={onBackPressed}>
          {translate('button.back')}
        </Button>
        <Button
          color={'primary'}
          disabled={Object.keys(rowSelection).length <= 0}
          variant={'contained'}
          onClick={handleCompleteSelectProductionProcess}
        >
          {translate('button.next')}
        </Button>
      </Box>
    </ProjectPaper>
  );
}

export default ReworkOrderSelectProcessForm;
