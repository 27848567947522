import ApproveAmountMeasurePage from '../../components/pages/PlanningModule/ApproveAmountMeasurePage';
import ProductionPlanningPage from '../../components/pages/PlanningModule/ProductionPlanningPage';
import PlanningMachineQueueDetailPage from '../../components/pages/PlanningModule/PlanningMachineQueueDetailPage';
import CreateReworkOrderPage from '../../components/pages/PlanningModule/CreateReworkOrderPage';
import EditPileReferenceIdPage from '../../components/pages/PlanningModule/EditPileReferenceIdPage';
import {
  AdminUserTypeId,
  QualityAssuranceUserTypeId,
  ProductionControlUserTypeId,
  SalesUserTypeId, ManagementUserTypeId,
} from '../../utilities/Constants';
import PlanningDashboardPage from '../../components/pages/PlanningModule/PlanningDashboardPage';
import PlanningPrintReportPage from '../../components/pages/PlanningModule/PlanningPrintReportPage';
import PlanningMainPage from '../../components/pages/PlanningModule/PlanningMainPage';
import { paths } from '../paths';
import ReportProductOrderProblemPage from '../../components/pages/PlanningModule/ReportProductOrderProblemPage';
import PlanningAdjustPileAmountPage from '../../components/pages/PlanningModule/PlanningAdjustPileAmountPage';
import AddQualityProcessPage from '../../components/pages/PlanningModule/AddQualityProcessPage';
import CustomerListPage from '../../components/pages/SalesModule/CustomerListPage';
import ChangeSizePage from '../../components/pages/AdminModule/ChangeSizePage';

const planningModuleRouteDetails = [
  {
    path: paths.planning.production_planning,
    component: <PlanningMainPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.customer_list,
    component: <CustomerListPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.production_planning_old,
    component: <ProductionPlanningPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.approve_exceed_amount,
    component: <ApproveAmountMeasurePage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.create_rework_order,
    component: <CreateReworkOrderPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.edit_pile_reference,
    component: <EditPileReferenceIdPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.machine_queue_detail(':id'),
    component: <PlanningMachineQueueDetailPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.dashboard,
    component: <PlanningDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.print_report_planning,
    component: <PlanningPrintReportPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.edit_pile_good_amount_page,
    component: <PlanningAdjustPileAmountPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.add_quality_process_page,
    component: <AddQualityProcessPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.planning.report_product_order_problem,
    component: <ReportProductOrderProblemPage />,
    isPrivate: true,
    allowUserTypeId: [
      ProductionControlUserTypeId,
      SalesUserTypeId,
      AdminUserTypeId,
      QualityAssuranceUserTypeId,
    ],
  },
  {
    path: paths.planning.edit_size,
    component: <ChangeSizePage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId,ManagementUserTypeId],
  },
];

export default planningModuleRouteDetails;
