import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { createSupplierSellItemTemplate } from '../../../requests/ProductionServerHandler';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  OpenErrorNotification,
  OpenNotificationThenCallback,
} from '../../../utilities/Helper/NotificationHelper';
import { useItemTemplateDetail } from '../../../requests/query/useItemTemplateDetail';
import { usePartSupplierDetail } from '../../../requests/query/useSupplierDetail';
import {
  useAllPartSupplier,
  useItemTemplateUnitOptions,
} from '../../../requests/query/DropdownData';
import { useMutation } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

function CreateSupplierSellItemTemplatePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const supplierId = searchParams.get('SupplierId');
  const itemTemplateId = searchParams.get('ItemTemplateId');
  const sellItemId = searchParams.get('SellItemId');
  const { translate } = useLocales();

  const { itemTemplateDetail } = useItemTemplateDetail(itemTemplateId);
  const { supplierDetail } = usePartSupplierDetail(supplierId !== 'null' ? supplierId : null);
  const { itemTemplateUnitOptions } = useItemTemplateUnitOptions();
  const { allSupplier } = useAllPartSupplier(true);
  const createSupplierSellItemTemplateMutation = useMutation({
    mutationFn: createSupplierSellItemTemplate,
    onSuccess: async () => {
      await OpenNotificationThenCallback(translate('title.complete'), '', () => {
        if (sellItemId !== undefined && sellItemId !== null) {
          navigate(paths.procurement.search_supplier_sell_item_template(sellItemId));
        } else {
          navigate(paths.procurement.part_supplier_detail(supplierId));
        }
      });
    },
  });
  const createSupplierSellItemTemplateSchema = Yup.object().shape({
    SellItemTemplateUnit: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    Name: Yup.string().required(translate('error.invalid_input')),
    PricePerSellUnit: Yup.number()
      .min(0, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(createSupplierSellItemTemplateSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    let sendSupplierId = supplierId !== 'null' ? supplierId : values.Supplier.Id;
    if (sendSupplierId !== null) {
      await createSupplierSellItemTemplateMutation.mutateAsync({
        Name: values.Name,
        SupplierId: sendSupplierId,
        ItemTemplateId: itemTemplateId,
        SellItemTemplateUnitId: values.SellItemTemplateUnit.Id,
        PricePerSellUnit: values.PricePerSellUnit,
      });
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.add_supplier_sell_item')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.create_po_parts_title'),
          href: paths.procurement.create_parts_po,
        },
        {
          name: `${translate('procurement.search_purchase_item_title')} : ${sellItemId}`,
          href: paths.procurement.search_supplier_sell_item_template(sellItemId),
        },
        { name: translate('procurement.add_supplier_sell_item') },
      ]}
    >
      {itemTemplateDetail !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              {supplierDetail !== null && (
                <GridDetailText
                  title={translate('procurement.supplier')}
                  value={supplierDetail.Detail.Name}
                />
              )}
              <GridDetailText
                title={translate('warehouse.item_master_name')}
                value={itemTemplateDetail.Name}
              />
              <GridDetailText
                title={translate('unit.title')}
                value={itemTemplateDetail.ItemTemplateUnit.Name}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                {supplierDetail === null && (
                  <RHFAutoCompleteShort
                    name={'Supplier'}
                    label={translate('procurement.supplier')}
                    data={allSupplier}
                  />
                )}
                <RHFTextField name={'Name'} label={translate('title.name')} />
                <RHFAutoCompleteShort
                  name={'SellItemTemplateUnit'}
                  label={translate('procurement.sell_unit')}
                  data={itemTemplateUnitOptions}
                />
                <RHFTextField
                  name={'PricePerSellUnit'}
                  label={`${translate('procurement.price_detail')} (${translate(
                    'warning.example_supplier_convert_3'
                  )})`}
                />
                <Button color={'primary'} variant={'contained'} fullWidth type="submit">
                  {translate('button.create')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default CreateSupplierSellItemTemplatePage;
