import { useMutation, useQuery } from '@tanstack/react-query';
import { createItemMasterContainer, getItemContainerId } from '../WarehouseServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useItemContainerDetail = (id) => {
  const { data } = useQuery({
    queryKey: ['item-container-detail', id],
    queryFn: () => getItemContainerId(id),
    enabled: !!id,
  });

  const createItemContainerMutation = useMutation({
    mutationFn: createItemMasterContainer,
    onSuccess: async () => {
      await OpenSuccessNotification();
    },
  });

  return {
    itemContainer: data,
    createContainer: createItemContainerMutation,
  };
};
