import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ProjectPageContainer} from '../../container/ProjectPageContainer';
import {useNavigate} from 'react-router-dom';
import {
    adjustPartsRequestCart,
    resetPartsPurchaseCart,
    resetPartsRequestCart,
    selectItemForMaintenanceId,
    selectItemForProductOrder,
    selectPartsRequestCart,
} from '../../../redux/reducers/inventory';
import {useLocales} from 'locales';
import {Card, CardContent, CardHeader, Grid, IconButton, Stack} from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import ProjectPaper from '../../customs/ProjectPaper';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import useAuth from '../../../auth/hooks/useAuth';
import {OpenTranslateWarningConfirmNotification} from '../../../utilities/Helper/NotificationHelper';
import {paths} from '../../../routes/paths';
import InventoryCheckoutForm from '../../forms/InventoryCheckoutForm';
import {useItemTemplateRequestReport} from '../../../requests/query/useItemTemplateRequestReport';
import useQueryParameter from "../../../hooks/useQueryParameter";
import Link from "@mui/material/Link";

const InventoryCheckoutPage = () => {
    const {translate} = useLocales();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {user} = useAuth();
    const PartsRequestCart = useSelector(selectPartsRequestCart);
    const searchParams = useQueryParameter();
    const maintenanceJobId = searchParams.get('maintenanceJobId');
    const {create} = useItemTemplateRequestReport(null);
    const onCheckoutItems = async (values) => {
        await OpenTranslateWarningConfirmNotification(
            translate,
            'warning.confirm_request_item',
            async () => {
                let submitData = {
                    RequestItems: PartsRequestCart.map((item) => {
                        return {Id: item.Id, Amount: item.Amount};
                    }),
                    PurchaseItems: [],
                    CreateEmployeeId: user.EmployeeId,
                    Detail: values.Detail,
                    IsTemp: false,
                };
                if (maintenanceJobId !== null) {
                    submitData = {
                        ...submitData,
                        MaintenanceId: maintenanceJobId,
                    };
                }
                await create.mutateAsync(submitData);
                await dispatch(selectItemForMaintenanceId(null));
                await dispatch(selectItemForProductOrder(null));
                await dispatch(resetPartsRequestCart());
                await dispatch(resetPartsPurchaseCart());
            }
        );
    };
    const onIncreaseItem = (itemDetail) => {
        dispatch(
            adjustPartsRequestCart({
                Id: itemDetail.Id,
                Name: itemDetail.Name,
                Amount: itemDetail.Amount + 1,
                MaxAmount: itemDetail.MaxAmount,
            })
        );
    };
    const onDecreaseItem = (itemDetail) => {
        dispatch(
            adjustPartsRequestCart({
                Id: itemDetail.Id,
                Name: itemDetail.Name,
                Amount: itemDetail.Amount - 1,
                MaxAmount: itemDetail.MaxAmount,
            })
        );
    };
    const onDeleteItem = (itemDetail) => {
        dispatch(
            adjustPartsRequestCart({
                Id: itemDetail.Id,
                Name: itemDetail.Name,
                Amount: 0,
                MaxAmount: itemDetail.MaxAmount,
            })
        );
    };

    if (PartsRequestCart.length > 0) {
        return (
            <ProjectPageContainer
                menu_title={translate('warehouse.request_item')}
                breadcrumbs={[
                    {name: translate('warehouse.parts')},
                    {name: translate('warehouse.request_item')},
                ]}
            >
                <Stack spacing={3}>
                    {maintenanceJobId !== null && (
                        <Card>
                            <CardHeader title={translate('maintenance.maintenance_job_detail')}/>
                            <CardContent>
                                <Grid>
                                    <GridDetailText
                                        title={translate('warehouse.for_maintenance_job_id')}
                                        value={maintenanceJobId}
                                        link={paths.maintenance.job_detail(maintenanceJobId)}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                    <ProjectPaper>
                        <GeneralTable
                            tableHeaders={[
                                {align: 'left', title: '#'},
                                {align: 'left', title: 'title.id'},
                                {align: 'left', title: 'title.name'},
                                {align: 'left', title: 'title.amount'},
                                {align: 'left', title: ''},
                            ]}
                            tableData={PartsRequestCart.map((item, index) => {
                                return [
                                    index + 1,
                                    item.Id,
                                    <Link href={paths.general_warehouse.item_template_detail(item.Id)}>
                                        {item.Name}
                                    </Link>,
                                    item.Amount,
                                    <>
                                        <IconButton
                                            disabled={item.Amount >= item.MaxAmount}
                                            color={'success'}
                                            onClick={() => onIncreaseItem(item)}
                                        >
                                            <Iconify icon={'ant-design:plus-outlined'} width={20} height={20}/>
                                        </IconButton>
                                        <IconButton color={'error'} onClick={() => onDecreaseItem(item)}>
                                            <Iconify icon={'ant-design:minus-outlined'} width={20} height={20}/>
                                        </IconButton>
                                        <IconButton color={'error'} onClick={() => onDeleteItem(item)}>
                                            <Iconify icon={'ant-design:delete-outlined'} width={20} height={20}/>
                                        </IconButton>
                                    </>,
                                ];
                            })}
                        />
                    </ProjectPaper>
                    <ProjectPaper>
                        <InventoryCheckoutForm onSubmitCallback={onCheckoutItems}/>
                    </ProjectPaper>
                </Stack>
            </ProjectPageContainer>
        );
    } else {
        return <div>{navigate(-1)}</div>;
    }
};

export default InventoryCheckoutPage;
