import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { PrintPDFMakeDocument } from 'utilities/Helper/PrintHelper';
import { OpenSuccessNotification } from 'utilities/Helper/NotificationHelper';
import { product_sell_report_prefix } from 'utilities/Strings/Prefix';
import { useLocales } from 'locales';
import DatePickerCustomer from 'components/date-picker/DatePickerCustomer';
import {
  getProductSummaryPDFFile,
  printProductSellReportExcel,
} from 'requests/ProductionServerHandler';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';

export default function PrintProductSellSummaryPage() {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printProductSellSummaryReportPDFMutation = useMutation({
    mutationFn: getProductSummaryPDFFile,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (pdfStructure) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(pdfStructure, `${product_sell_report_prefix}.pdf`);
      await OpenSuccessNotification();
    },
  });
  const printProductSellSummaryReportExcelMutation = useMutation({
    mutationFn: printProductSellReportExcel,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'product_sell_report.xlsx');
      await OpenSuccessNotification();
    },
  });
  const onPrint = async (fromDate, toDate, customer) => {
    await printProductSellSummaryReportPDFMutation.mutateAsync({
      customerId: customer.Id,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      isExcel: false,
    });
  };

  const onPrintExcel = async (fromDate, toDate, customer) => {
    await printProductSellSummaryReportExcelMutation.mutateAsync({
      customerId: customer.Id,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      isExcel: true,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.print_product_sell_summary_report_page_title')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.print_product_sell_summary_report_page_title') },
      ]}
    >
      <DatePickerCustomer
        includeAllCustomerOption
        actions={[
          {
            title: translate('accounting.print_product_sell_summary_report_page_title'),
            callBack: onPrint,
          },
          {
            title: `${translate(
              'accounting.print_product_sell_summary_report_page_title'
            )} (Excel)`,
            callBack: onPrintExcel,
          },
        ]}
      />
    </ProjectPageContainer>
  );
}
