import React, { useState } from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import ReactTable from '../../react-table/ReactTable';
import { fNumber } from '../../../utilities/formatNumber';
import { Button, IconButton } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeTextShort } from '../../../utilities/formatTime';
import { EditEmployeePerformanceForm } from '../../forms/EditEmployeePerformanceForm';
import { useProductionEmployeePerformance } from '../../../requests/query/useProductionEmployeePerformance';
import { SILDatePicker } from '../../date-picker';

function ProductionEmployeePerformancePage() {
  const { translate } = useLocales();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editDetail, setEditDetail] = useState(null);

  const fromDate = new Date();
  fromDate.setDate(fromDate.getDate() - 1);
  fromDate.setHours(20, 0, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: new Date(),
  });

  const { employeeDetails, editEmployeePerformance, handleRefetch, printReport } =
    useProductionEmployeePerformance(dateRange);

  const handleChangeDate = async (from, to) => {
    setDateRange({
      fromDate: from,
      toDate: to,
    });
    await handleRefetch();
  };

  const onEditDetail = (detailToEdit) => {
    setIsEditMode(true);
    setEditDetail(detailToEdit);
  };

  const onSaveEditDetail = async (editId, editDetail) => {
    await editEmployeePerformance.mutate({ Id: editId, ...editDetail });
    setIsEditMode(false);
    setEditDetail(null);
  };

  const onPrintReport = async () => {
    await printReport.mutateAsync({
      fromDate: dateRange.fromDate.toISOString(),
      toDate: dateRange.toDate.toISOString(),
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.employee_performance')}
      breadcrumbs={[
        { name: translate('production.title') },
        { name: translate('production.employee_performance') },
      ]}
      action={
        <Button
          variant="contained"
          color={'info'}
          startIcon={<Iconify icon={'ic:outline-print'} />}
          onClick={onPrintReport}
        >
          {translate('button.print')}
        </Button>
      }
    >
      {isEditMode && editDetail !== null ? (
        <>
          <EditEmployeePerformanceForm
            recordToEdit={editDetail}
            onSaveEditDetailCallBack={onSaveEditDetail}
          />
        </>
      ) : (
        <>
          <SILDatePicker onSearchDate={handleChangeDate} />
          <ProjectPaper>
            <ReactTable
              defaultPageSize={25}
              search
              data={employeeDetails.map((detail) => {
                return {
                  name: getEmployeeFullName(detail.CreateEmployee),
                  machine: detail.Machine.Name,
                  total_good: detail.GoodAmount,
                  total_bad: detail.DefectAmount,
                  total_pile: detail.TotalPileCount,
                  avg_amount: detail.AveragePerHour,
                  shift: {
                    name: detail.Shift.Name,
                    start: detail.StartTimeAt,
                    end: detail.EndTimeAt,
                  },
                  type: detail.ProductType.Name,
                  total_time: detail.WorkingTime,
                  actions: detail,
                };
              })}
              header={[
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <>
                      <IconButton
                        onClick={() => {
                          onEditDetail(info.getValue());
                        }}
                      >
                        <Iconify icon={'material-symbols:edit'} width={20} height={20} />
                      </IconButton>
                    </>
                  ),
                  size: 50,
                },
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                  size: 50,
                },
                {
                  header: translate('title.machine'),
                  accessorKey: 'machine',
                  size: 50,
                },
                {
                  header: translate('title.amount_good'),
                  accessorKey: 'total_good',
                  cell: (info) => fNumber(info.getValue()),
                  size: 50,
                },
                {
                  header: translate('title.amount_defect'),
                  accessorKey: 'total_bad',
                  cell: (info) => fNumber(info.getValue()),
                  size: 50,
                },
                {
                  header: translate('title.pile_amount'),
                  accessorKey: 'total_pile',
                  cell: (info) => fNumber(info.getValue()),
                  size: 50,
                },
                {
                  header: translate('title.avg_per_hour'),
                  accessorKey: 'avg_amount',
                  cell: (info) => fNumber(info.getValue()),
                  size: 50,
                },
                {
                  header: translate('sil.shift'),
                  accessorKey: 'shift',
                  cell: (info) =>
                    `${info.getValue().name} (${fDateTimeTextShort(
                      info.getValue().start
                    )} - ${fDateTimeTextShort(info.getValue().end)})`,
                  size: 50,
                },
                {
                  header: translate('title.product_order_subtype'),
                  accessorKey: 'type',
                  size: 50,
                },
                {
                  header: translate('title.total_time'),
                  accessorKey: 'total_time',
                  cell: (info) => fNumber(info.getValue()),
                  size: 50,
                },
              ]}
            />
          </ProjectPaper>
        </>
      )}
    </ProjectPageContainer>
  );
}

export default ProductionEmployeePerformancePage;
