import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Chip, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import FormProvider, { RHFAutocomplete } from 'components/minimal/hook-form';

CreateProductOrderProblemDetailForm.propTypes = {
  onSubmitValue: PropTypes.func.isRequired,
  allProblemDetails: PropTypes.array.isRequired,
};

function CreateProductOrderProblemDetailForm({ onSubmitValue, allProblemDetails }) {
  const { translate } = useLocales();
  const onCompleteJob = async (value) => {
    onSubmitValue(value);
  };

  const completeJobSchema = Yup.object().shape({
    Problems: Yup.array().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(completeJobSchema),
    initialValues: {
      Problems: [],
    },
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onCompleteJob)}>
      <Stack spacing={3}>
        <RHFAutocomplete
          defaultValue={[]}
          name="Problems"
          label={translate('title.problem')}
          placeholder={translate('title.problem')}
          multiple
          freeSolo
          options={allProblemDetails.map((option) => option)}
          getOptionLabel={(option) => {
            return typeof option === 'string' ? option : option.Name;
          }}
          renderOption={(props, option, index) => (
            <li {...props} key={index}>
              {typeof option === 'string' ? option : option.Name}
            </li>
          )}
          renderTags={(selected, getTagProps) =>
            selected.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                key={option}
                label={typeof option === 'string' ? option : option.Name}
                size="small"
                color="info"
                variant="soft"
              />
            ))
          }
        />
        <Button color={'primary'} variant={'contained'} type={'submit'}>
          {translate('button.save')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateProductOrderProblemDetailForm;
