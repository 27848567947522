import React from "react";
import {ProjectPageContainer} from "../../container/ProjectPageContainer";
import useAuth from "auth/hooks/useAuth";
import {Navigate} from "react-router-dom";
import {paths} from "routes/paths";
function LandingPage() {
    const {user} = useAuth();
    return (
        <ProjectPageContainer menu_title={'SIL Service'}>
            {
                user !== null ?
                    <Navigate to={paths.dashboard.main} replace={true} />
                    :
                    <Navigate to={paths.login} replace={true} />
            }
        </ProjectPageContainer>
    );
};
export default LandingPage;
