import React from 'react';
import { DataScanner } from './index';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { useLocales } from '../../locales';
import { Box, Stack, Typography } from '@mui/material';
import SearchMaterialForm from '../forms/SearchMaterialForm';
import { getItemDetailByBarcodeId, getItemDetailById } from '../../requests/WarehouseServerHandler';

MaterialScanner.propTypes = {
  onSearchCallBack: PropTypes.func.isRequired,
  isPreFetchData: PropTypes.bool,
  isShowTooltips: PropTypes.bool,
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
};

function MaterialScanner({
  onSearchCallBack,
  isPreFetchData,
  isShowTooltips,
  disableInput,
  disableScan,
}) {
  const { translate } = useLocales();
  const searchMaterialByIdMutation = useMutation({
    mutationFn: getItemDetailById,
    onSuccess: async (itemDetail) => {
      await OpenSuccessNotification();
      onSearchCallBack(itemDetail);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
    },
  });

  const searchMaterialByBarcodeMutation = useMutation({
    mutationFn: getItemDetailByBarcodeId,
    onSuccess: async (itemDetail) => {
      await OpenSuccessNotification();
      onSearchCallBack(itemDetail);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
    },
  });

  const handleScanCallback = async (data) => {
    try {
      if (data.includes('ItemId')) {
        const dataJson = JSON.parse(data);
        if (dataJson.ItemId !== undefined) {
          await handleSearchMaterial(dataJson.ItemId);
        }
      } else {
        await searchMaterialByBarcodeMutation.mutateAsync(data);
      }
    } catch (e) {
      await OpenErrorNotification(translate('error.error_item_id'));
    }
  };
  const handleSearchMaterial = async (id) => {
    if (!isNaN(id)) {
      if (isPreFetchData) {
        await searchMaterialByIdMutation.mutateAsync(id);
      } else {
        await OpenSuccessNotification();
        onSearchCallBack(id);
      }
    } else {
      await OpenErrorNotification(translate('error.error_item_id'));
    }
  };
  const searchMaterialForm = <SearchMaterialForm onSubmitCallBack={handleSearchMaterial} />;
  return (
    <Stack spacing={2}>
      {isShowTooltips && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt={'scan'}
              style={{ height: '200px', width: '300px' }}
              src={'/assets/illustrations/Scan_Image.jpg'}
              data-holder-rendered="true"
            />
          </Box>
          <Typography variant={'h3'}>
            {translate('placeholder.please_scan_item_barcode_id')}
          </Typography>
        </>
      )}
      <DataScanner
        disableInput={disableInput}
        disableScan={disableScan}
        onScanCallback={handleScanCallback}
        FormComponent={searchMaterialForm}
      />
    </Stack>
  );
}

export default MaterialScanner;
