import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { changeProductOrderAmount } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import { useLocales } from 'locales';
import { useMutation } from '@tanstack/react-query';

export default function ChangeProductOrderAmountPage() {
  const { translate } = useLocales();

  const changePOAmountMutation = useMutation({
    mutationFn: changeProductOrderAmount,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const changeProductAmount = async (values) => {
    await changePOAmountMutation.mutateAsync({
      ProductOrderId: values.ProductOrderId,
      Amount: values.Amount,
    });
  };

  const changeProductOrderAmountSchema = Yup.object().shape({
    ProductOrderId: Yup.number().required(translate('error.product_order_id')),
    Amount: Yup.number().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(changeProductOrderAmountSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_product_order_amount_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.edit_product_order_amount_title') },
      ]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(changeProductAmount)}>
          <Stack spacing={3}>
            <RHFTextField
              name={'ProductOrderId'}
              label={translate('production.product_order_id')}
            />
            <RHFTextField name={'Amount'} label={translate('title.amount')} />
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.save')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}
