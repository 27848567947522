import React from 'react';
import { Typography } from '@mui/material';
import { useLocales } from '../../../locales';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { ProductOrderScanner } from '../../barcode-reader';
import Stack from '@mui/material/Stack';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';

function ReportProductOrderProblemPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const handleSearchProductOrder = async (barcodeString) => {
    const dataJson = JSON.parse(barcodeString);
    if (dataJson.OrderId !== undefined) {
      navigate(
        `${paths.production.create_product_order_problem}?productOrderId=${dataJson.OrderId}`
      );
    } else {
      navigate(`${paths.production.create_product_order_problem}?productOrderId=${barcodeString}`);
    }
  };
  return (
    <ProjectPageContainer
      menu_title={translate('button.add_problem_flag')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('button.add_problem_flag') },
      ]}
    >
      <Stack spacing={2}>
        <Typography variant={'h4'}>{translate('placeholder.please_scan_pile_qr')}</Typography>
        <ProductOrderScanner onSearchCallBack={handleSearchProductOrder} />
      </Stack>
    </ProjectPageContainer>
  );
}

export default ReportProductOrderProblemPage;
