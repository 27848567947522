import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getMachineProductionQueue,
  moveMachineProductionQueue,
  printMachineProductionQueue,
  removeMachineProductionQueue,
} from '../ProductionServerHandler';
import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { useLoading } from '../../components/loading';
import { planning_pdf_prefix } from '../../utilities/Strings/Prefix';

export const usePlanningMachineDetail = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['usePlanningMachineDetail', id],
    queryFn: () => getMachineProductionQueue(id),
    initialData: null,
  });

  const moveQueueMutation = useMutation({
    mutationFn: moveMachineProductionQueue,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['usePlanningMachineDetail', id]);
    },
  });

  const removeQueueMutation = useMutation({
    mutationFn: removeMachineProductionQueue,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['usePlanningMachineDetail', id]);
    },
  });

  const printPlanMutation = useMutation({
    mutationFn: printMachineProductionQueue,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      PrintPDFMakeDocument(response, `${planning_pdf_prefix}_${data.Name}.pdf`);
    },
  });

  return {
    machine: data,
    moveQueue: moveQueueMutation,
    removeQueue: removeQueueMutation,
    printPlan: printPlanMutation,
  };
};
