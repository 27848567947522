import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from '../../locales';
import { Stack } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import Button from '@mui/material/Button';

SubmitPileForm.propTypes = {
  onSubmitCallBack: PropTypes.func.isRequired,
  machineOptions: PropTypes.array.isRequired,
};

function SubmitPileForm({ onSubmitCallBack, machineOptions }) {
  const { translate } = useLocales();
  const SubmitPileSchema = Yup.object().shape({
    GoodAmount: Yup.number().min(0).required(translate('error.amount_is_require')),
    DefectAmount: Yup.number().min(0).required(translate('error.amount_is_require')),
    Machine: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.machine_is_require')),
        Name: Yup.string().required(translate('error.machine_is_require')),
      })
      .required(translate('error.machine_is_require')),
    Height: Yup.number().min(1).required(translate('error.amount_is_require')),
  });

  const methods = useForm({
    resolver: yupResolver(SubmitPileSchema),
  });

  const onSubmit = (values) => {
    onSubmitCallBack(values);
  };
  const { handleSubmit } = methods;
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFAutoCompleteShort
          name={`Machine`}
          data={machineOptions}
          label={translate('title.machine')}
        />
        <RHFTextField name="GoodAmount" label={translate('title.amount_good')} />
        <RHFTextField name="DefectAmount" label={translate('title.amount_defect')} />
        <RHFTextField name="Height" label={translate('title.complete_height')} />
        <Button color={'primary'} variant="contained" fullWidth type={'submit'}>
          {translate('button.submit')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default SubmitPileForm;
