import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

GeneralSelect.propTypes = {
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onOptionChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

function GeneralSelect({ value, selectOptions, onOptionChange }) {
  const onSelectChange = (event) => {
    onOptionChange(event.target.value);
  };
  return (
    <div>
      <FormControl fullWidth>
        <Select value={value} onChange={onSelectChange}>
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default GeneralSelect;
