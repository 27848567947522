import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import { getIncomingItemContainer } from '../../../requests/WarehouseServerHandler';
import Iconify from 'components/minimal/iconify';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

function IncomingItemMasterPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { data: allItemContainer } = useQuery({
    queryKey: ['incoming-item-container'],
    queryFn: getIncomingItemContainer,
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.accept_inventory')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.accept_inventory') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={allItemContainer.map((POItemContainer) => {
            return {
              name: POItemContainer.SupplierSellItemContainer.ItemMaster.Name,
              container_name: POItemContainer.SupplierSellItemContainer.ItemContainer.Name,
              po_id: POItemContainer.PurchaseOrderId,
              supplier: POItemContainer.SupplierSellItemContainer.Supplier.Name,
              amount: `${POItemContainer.Amount} ${POItemContainer.SupplierSellItemContainer.SellItemUnit.Name}`,
              amount_to_accept: `${Math.round(
                (POItemContainer.Amount *
                  POItemContainer.SupplierSellItemContainer.ItemMasterUnitAmount) /
                  POItemContainer.SupplierSellItemContainer.ItemContainer.ItemMasterSize
              )} ${POItemContainer.SupplierSellItemContainer.ItemContainer.ItemUnit.Name}`,
              coming_at: POItemContainer.PurchaseOrder.DeliveredAt,
              actions: POItemContainer.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('warehouse.item_container_detail'),
              accessorKey: 'container_name',
            },
            {
              header: translate('procurement.po_reference_id_title'),
              accessorKey: 'po_id',
              cell: (info) => (
                <Link href={paths.procurement.po_detail(info.getValue())}>{`${translate('procurement.po_number')} ${info.getValue()}`}</Link>
              ),
              size: 100,
            },
            {
              header: translate('procurement.supplier'),
              accessorKey: 'supplier',
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
            },
            {
              header: translate('warehouse.amount_to_accept'),
              accessorKey: 'amount_to_accept',
              size: 60,
            },
            {
              header: translate('title.due_at'),
              accessorKey: 'coming_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  color={'primary'}
                  onClick={() => navigate(paths.warehouse.accept_store_items(info.getValue()))}
                >
                  <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                </IconButton>
              ),
              size: 20,
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default IncomingItemMasterPage;
