import React from 'react';
import * as Yup from 'yup';
import { Box, Card, CardContent, CardHeader, Stack, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import FormProvider, {
  RHFAutoCompleteShort,
  RHFDatePicker,
  RHFTextField,
} from 'components/minimal/hook-form';
import { useLocales } from 'locales';
import { getAllProductOrderSubType } from '../../requests/ProductionServerHandler';
import { useQuery } from '@tanstack/react-query';

ProductOrderDetailForm.propTypes = {
  productOrderDetail: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Detail: PropTypes.string,
    Width: PropTypes.number.isRequired,
    Length: PropTypes.number.isRequired,
    Amount: PropTypes.number.isRequired,
    ProductOrderSubType: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onSubmitProductOrderDetail: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
};

function ProductOrderDetailForm({ productOrderDetail, onSubmitProductOrderDetail, onBackPress }) {
  const { translate } = useLocales();
  const todayDate = new Date();
  const { data: allProductOrderSubType } = useQuery({
    queryKey: ['all-product-order-subtype'],
    queryFn: getAllProductOrderSubType,
    initialData: [],
  });
  const ProductOrderDetailSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    Width: Yup.number()
      .min(5, translate('error.width_is_require'))
      .required(translate('error.width_is_require')),
    Length: Yup.number()
      .min(5, translate('error.length_is_require'))
      .required(translate('error.length_is_require')),
    Amount: Yup.number()
      .min(1, translate('error.amount_is_require'))
      .required(translate('error.amount_is_require')),
    DueDatedAt: Yup.date().required(translate('error.due_date_require')),
    ProductOrderSubType: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.product_order_subtype_is_require')),
        Name: Yup.string().required(translate('error.product_order_subtype_is_require')),
        ProductOrderMainTypeId: Yup.number().required(
          translate('error.product_order_subtype_is_require')
        ),
      })
      .required(translate('error.product_order_subtype_is_require')),
  });

  const defaultValues = {
    Name: productOrderDetail?.Name || '',
    Detail: productOrderDetail?.Detail || '',
    Width: productOrderDetail?.Width || 0,
    Length: productOrderDetail?.Length || 0,
    Amount: productOrderDetail?.Amount || 0,
    ProductOrderSubType: productOrderDetail?.ProductOrderSubType || {
      Id: 0,
      Name: 'ไม่ได้เลือก',
    },
    DueDatedAt: new Date(todayDate.setDate(todayDate.getDate() + 1)),
  };

  const methods = useForm({
    resolver: yupResolver(ProductOrderDetailSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    onSubmitProductOrderDetail(value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={translate('title.detail')} />
        <CardContent>
          <Stack spacing={3} alignItems="flex-end">
            <RHFTextField name="Name" label={translate('title.name')} />
            <RHFTextField name="Detail" label={translate('title.detail')} />
          </Stack>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              mt: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            <RHFTextField name="Width" label={translate('title.width')} />
            <RHFTextField name="Length" label={translate('title.length')} />
          </Box>
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <RHFTextField name="Amount" label={translate('title.amount')} />
          </Stack>
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <RHFDatePicker name="DueDatedAt" label={translate('title.due_at')} includeTime />
          </Stack>
          <Box sx={{ mt: 3 }}>
            <RHFAutoCompleteShort
              data={allProductOrderSubType}
              name={'ProductOrderSubType'}
              label={translate('title.product_order_subtype')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
            }}
          >
            <Button color={'primary'} onClick={onBackPress}>
              {translate('button.back')}
            </Button>
            <LoadingButton
              color={'primary'}
              variant="contained"
              type={'submit'}
              loading={isSubmitting}
            >
              {translate('button.next')}
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

export default ProductOrderDetailForm;
