import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import { useLocales } from 'locales';

const SILDatePicker = ({ onSearchDate, defaultDateRange }) => {
  const { translate } = useLocales();
  const getYesterdayMorningShiftDate = () => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    fromDate.setHours(8, 0, 0, 0);
    const toDate = new Date();
    toDate.setDate(toDate.getDate() - 1);
    toDate.setHours(20, 0, 0, 0);

    return {
      fromDate,
      toDate,
      title: translate('sil.yesterday_morning_shift'),
    };
  };
  const getLastNightShiftDate = () => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    fromDate.setHours(20, 0, 0, 0);
    const toDate = new Date();
    toDate.setHours(8, 0, 0, 0);
    return {
      fromDate,
      toDate,
      title: translate('sil.yesterday_night_shift'),
    };
  };
  const getCurrentShiftDate = () => {
    const fromDate = new Date();
    fromDate.setHours(8, 0, 0, 0);
    const toDate = new Date();
    return {
      fromDate,
      toDate,
      title: translate('sil.current_shift'),
    };
  };

  return (
    <DatePicker
      onSearchDate={onSearchDate}
      defaultDateRange={
        defaultDateRange !== undefined
          ? defaultDateRange
          : {
              fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
              toDate: new Date(new Date().setHours(23, 59, 0, 0)),
            }
      }
      buttonDateRanges={[
        getYesterdayMorningShiftDate(),
        getLastNightShiftDate(),
        getCurrentShiftDate(),
      ]}
    />
  );
};

SILDatePicker.propTypes = {
  onSearchDate: PropTypes.func.isRequired,
  defaultDateRange: PropTypes.object,
};

export default SILDatePicker;
