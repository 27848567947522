import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormGroup,
  IconButton,
  ListItem,
  Paper,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import GeneralSelect from '../../customs/GeneralSelect';
import FormControlLabel from '@mui/material/FormControlLabel';
import Scrollbar from 'components/minimal/scrollbar';
import { fNumber } from '../../../utilities/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { useLocales } from 'locales';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPlanningForPO,
  getProductOrderDetailWithProcessById,
  getTodayPO,
} from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenCallback } from '../../../utilities/Helper/NotificationHelper';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  selectIsRefreshUnPlanPO,
  toggleForceRefreshUnPlanPO,
  toggleRefreshPlanningCard,
  toggleShowProductOrderDetail,
} from '../../../redux/reducers/planning';
import DateSelectorModal from '../../modal/DateSelectorModal';
import { useMutation } from '@tanstack/react-query';

function UnPlanProductOrderList() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const isRefreshUnPlanPO = useSelector(selectIsRefreshUnPlanPO);

  const refetchPlanningData = useMutation({
    mutationFn: getTodayPO,
    onSuccess: (data) => {
      setAllTodayProductOrder(data);
      setUnPlanProductOrder(data);
    },
  });

  const getProductOrderDetailMutation = useMutation({
    mutationFn: getProductOrderDetailWithProcessById,
    onSuccess: async (data) => {
      await dispatch(
        toggleShowProductOrderDetail({
          isShowProductOrderDetail: true,
          productOrderDetail: data.Detail,
        })
      );
    },
  });

  const createPlanningForPOMutation = useMutation({
    mutationFn: createPlanningForPO,
    onSuccess: async (data) => {
      await OpenNotificationThenCallback(
        translate('title.complete'),
        `สร้าง ${data.CreatePlans} แผนงาน`,
        async () => {
          await refreshPO();
        }
      );
    },
  });

  useEffect(() => {
    if (isRefreshUnPlanPO) {
      const refetchUnPlan = async () => await refetchPlanningData.mutateAsync();
      refetchUnPlan().then(async () => {
        await dispatch(toggleForceRefreshUnPlanPO(false));
      });
    }
  }, [dispatch, isRefreshUnPlanPO, refetchPlanningData]);

  const [allTodayProductOrder, setAllTodayProductOrder] = useState([]);
  const [unPlanProductOrder, setUnPlanProductOrder] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [unPlanPOFilerValue, setUnPlanPOFilerValue] = useState(0);
  const [unPlanPOSortValue, setUnPlanPOSortValue] = useState(0);
  const [selectUnPlanPO, setSelectUnPlanPO] = useState([]);
  const [toCreatePO, setToCreatePO] = useState([]);

  const refreshPO = async () => {
    await refetchPlanningData.mutateAsync();
    setIsSelectAll(false);
    setSelectUnPlanPO([]);
    await dispatch(toggleRefreshPlanningCard(true));
    setToCreatePO([]);
  };

  const filterUnPlanPOSelects = [
    { value: 0, name: translate('planning.all_work') },
    { value: 1, name: translate('planning.today_create_po') },
  ];
  const sortUnPlanPOSelects = [
    { value: 0, name: translate('planning.in_date_desc') },
    { value: 1, name: translate('planning.in_date_asc') },
    { value: 2, name: translate('planning.amount_desc') },
    { value: 3, name: translate('planning.amount_asc') },
  ];

  const handleOpenPODetailDialog = async (POId) => {
    await getProductOrderDetailMutation.mutateAsync(POId);
  };
  const handleAddPOToPlan = async (POId) => {
    setToCreatePO([POId]);
  };
  const handleAddAllPOToPlan = async () => {
    setToCreatePO(selectUnPlanPO);
  };
  const handleCreatePlanningForPO = async (poIds, date) => {
    await createPlanningForPOMutation.mutateAsync({
      ProductOrderIds: poIds,
      PlanDateAt: date,
    });
  };
  const handleCheckAll = () => {
    if (isSelectAll) {
      setSelectUnPlanPO([]);
      setIsSelectAll(false);
    } else {
      setSelectUnPlanPO(unPlanProductOrder.map((PO) => PO.Id));
      setIsSelectAll(!isSelectAll);
    }
  };
  const handleToggleSelectUnPlanPO = (check, Id) => {
    if (check) {
      setSelectUnPlanPO([...selectUnPlanPO, Id]);
    } else {
      setSelectUnPlanPO(selectUnPlanPO.filter((POId) => POId !== Id));
    }
  };
  const handleUnPlanPOFilterChange = (value) => {
    if (value === 0) {
      setUnPlanProductOrder(
        allTodayProductOrder.filter(
          (productOrder) => productOrder.ProductOrderPlannings.length === 0
        )
      );
    }
    if (value === 1) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setUnPlanProductOrder(
        allTodayProductOrder.filter((productOrder) => new Date(productOrder.createdAt) >= today)
      );
    }
    setUnPlanPOFilerValue(value);
    setUnPlanPOSortValue(0);
  };
  const handleSortUnPlanPO = (value) => {
    if (value === 0) {
      const sorted = [...unPlanProductOrder].sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setUnPlanProductOrder(sorted);
    }
    if (value === 1) {
      const sorted = [...unPlanProductOrder].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setUnPlanProductOrder(sorted);
    }
    if (value === 2) {
      const sorted = [...unPlanProductOrder].sort((a, b) => {
        return parseFloat(b.Amount) - parseFloat(a.Amount);
      });
      setUnPlanProductOrder(sorted);
    }
    if (value === 3) {
      const sorted = [...unPlanProductOrder].sort((a, b) => {
        return parseFloat(a.Amount) - parseFloat(b.Amount);
      });
      setUnPlanProductOrder(sorted);
    }
    setUnPlanPOSortValue(value);
  };
  const handleDateSelectorClose = () => {
    setToCreatePO([]);
  };
  const handlePlanDateSelected = async (date) => {
    await handleCreatePlanningForPO(toCreatePO, date);
  };

  return (
    <>
      <DateSelectorModal
        isOpen={toCreatePO.length > 0}
        onSelectDateComplete={handlePlanDateSelected}
        onClose={handleDateSelectorClose}
      />
      <Paper variant="outlined" sx={{ px: 2, bgcolor: 'grey.5008', overflow: 'auto', height: 700 }}>
        <Box
          sx={{
            height: 80,
            m: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontSize={50} sx={{ fontWeight: 'bold' }}>
            {unPlanProductOrder.length}
          </Typography>
          <Box sx={{ mt: 1 }}>
            <GeneralSelect
              value={unPlanPOFilerValue}
              selectOptions={filterUnPlanPOSelects}
              onOptionChange={handleUnPlanPOFilterChange}
            />
          </Box>
        </Box>
        <Divider />
        <Stack spacing={1} sx={{ pt: 1, pb: 1 }}>
          <Box>
            <GeneralSelect
              value={unPlanPOSortValue}
              selectOptions={sortUnPlanPOSelects}
              onOptionChange={handleSortUnPlanPO}
            />
          </Box>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={handleCheckAll} checked={isSelectAll} />}
              label={translate('title.select_all')}
            />
          </FormGroup>
          <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: isSelectAll ? 400 : 450 }}>
            <Stack spacing={2}>
              {unPlanProductOrder.map((unPlanPO) => {
                return (
                  <Box
                    key={unPlanPO.Id}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                    }}
                  >
                    {isSelectAll && (
                      <Checkbox
                        checked={selectUnPlanPO.find((PO) => PO === unPlanPO.Id) !== undefined}
                        onChange={(event) =>
                          handleToggleSelectUnPlanPO(event.target.checked, unPlanPO.Id)
                        }
                      />
                    )}
                    <Card sx={{ width: 400 }}>
                      <CardHeader
                        title={unPlanPO.Customer.Name}
                        titleTypographyProps={{
                          onClick: () => handleOpenPODetailDialog(unPlanPO.Id),
                          textOverflow: 'ellipsis',
                          noWrap: true,
                          maxWidth: isSelectAll ? 200 : 250,
                        }}
                        subheader={
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography variant={'body2'}>{`${translate('title.id')}: ${
                              unPlanPO.Id
                            }`}</Typography>
                            <Typography variant={'body2'}>{fNumber(unPlanPO.Amount)}</Typography>
                          </Box>
                        }
                        action={
                          <>
                            <IconButton onClick={() => handleAddPOToPlan(unPlanPO.Id)}>
                              <AddIcon />
                            </IconButton>
                          </>
                        }
                      />
                      <Divider sx={{ mt: 2 }} />
                      <List dense={true}>
                        {unPlanPO.ProductSellHistories.map((sellHistory) => {
                          return sellHistory.Product.ProductionProcesses.map((process) => {
                            const isPlan =
                              unPlanPO.ProductOrderPlannings.find(
                                (plan) =>
                                  plan.ProductHasProductionProcessId ===
                                  process.ProductHasProductionProcess.Id
                              ) !== undefined;
                            return (
                              <div
                                key={`${sellHistory.Id}-${sellHistory.Product.Id}-${process.Id}`}
                              >
                                <ListItem>
                                  <ListItemIcon>
                                    {isPlan ? (
                                      <CheckIcon color={'success'} />
                                    ) : (
                                      <CloseIcon color={'error'} />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          mt: 1,
                                          ml: 3,
                                          mr: 3,
                                          mb: 1,
                                        }}
                                      >
                                        <Typography
                                          variant={'body2'}
                                          color={isPlan ? '#919EAB' : null}
                                        >
                                          {process.Name}
                                        </Typography>
                                        <Typography
                                          variant={'body2'}
                                          color={isPlan ? '#919EAB' : null}
                                        >
                                          {fNumber(unPlanPO.Amount)}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                  <Divider />
                                </ListItem>
                              </div>
                            );
                          });
                        })}
                      </List>
                    </Card>
                  </Box>
                );
              })}
            </Stack>
          </Scrollbar>
          {isSelectAll && (
            <Button fullWidth variant={'contained'} onClick={handleAddAllPOToPlan}>
              {translate('planning.add_to_plan')}
            </Button>
          )}
        </Stack>
      </Paper>
    </>
  );
}

export default UnPlanProductOrderList;
