import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Stack } from '@mui/material';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';

CreateInventoryForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreateInventoryForm({ onFormSubmit }) {
  const { translate } = useLocales();

  const createInventoryStoreSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_require')),
  });

  const methods = useForm({
    resolver: yupResolver(createInventoryStoreSchema),
    defaultValues: {
      Name: '',
    },
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onFormSubmit(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="Name" label={translate('title.name')} />
        <Button variant={'contained'} type={'submit'} color={'primary'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateInventoryForm;
