import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { createProductionProcess } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import CreateProductionProcessForm from '../../forms/CreateProductionProcessForm';
import { useMutation } from '@tanstack/react-query';

function CreateProductionProcess() {
  const { translate } = useLocales();

  const createProductionProcessMutation = useMutation({
    mutationFn: createProductionProcess,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const handleCreateProductionProcess = async (value) => {
    await createProductionProcessMutation.mutateAsync({
      Name: value.Name,
      ProductionProcessTypeId: value.ProductionProcessType.Id,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.create_production_process_title')}
      breadcrumbs={[
        { name: translate('production.title') },
        { name: translate('production.create_production_process_title') },
      ]}
    >
      <ProjectPaper>
        <CreateProductionProcessForm onFormSubmit={handleCreateProductionProcess} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateProductionProcess;
