import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { OpenErrorNotification, OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, Stack } from '@mui/material';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  createItemLotFromAdmin,
  getItemMasterDetailById,
} from '../../../requests/WarehouseServerHandler';
import ItemMasterDetailForCreateLot from '../../details/warehouse/ItemMasterDetailForCreateLot';
import SearchItemMasterForm from '../../forms/SearchItemMasterForm';
import { useMutation } from '@tanstack/react-query';
import { useSupplierOptions } from '../../../requests/query/DropdownData';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../../minimal/hook-form';
import { useLoading } from '../../loading';

function AdminCreateItemLotPage() {
  const { translate } = useLocales();
  const [itemMaster, setItemMaster] = useState(null);
  const [itemContainerOptions, setItemContainerOptions] = useState([]);
  const { supplierOptions } = useSupplierOptions();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  const searchItemMasterMutation = useMutation({
    mutationFn: getItemMasterDetailById,
    onSuccess: (data) => {
      setItemMaster(data);
      setItemContainerOptions(
        data.ItemContainer.map((container) => {
          return {
            Id: container.Id,
            Name: `${container.Name} (${container.ItemMasterSize} ${data.ItemUnit.Name} ต่อ ${container.ItemUnit.Name})`,
          };
        })
      );
    },
  });

  const createItemLotMutation = useMutation({
    mutationFn: createItemLotFromAdmin,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async () => {
      hideLoadingScreen();
      await OpenNotificationThenReloadPage();
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const createItemLotSchema = Yup.object().shape({
    Amount: Yup.number()
      .min(1, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    Value: Yup.number()
      .min(0, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    ItemContainer: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    Supplier: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(createItemLotSchema),
  });

  const { handleSubmit, formState:{errors} } = methods;

  const onSearchItemMaster = async (itemMasterId) => {
    await searchItemMasterMutation.mutateAsync(itemMasterId);
  };
  const onCreateItemLot = async (values) => {
    await createItemLotMutation.mutateAsync({
      ItemContainerId: values.ItemContainer.Id,
      SupplierId: values.Supplier.Id,
      Amount: values.Amount,
      Value: values.Value,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.add_lot')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.add_lot') },
      ]}
    >
      {itemMaster !== null ? (
        <Stack spacing={3}>
          <ItemMasterDetailForCreateLot itemMaster={itemMaster} />
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(onCreateItemLot)}>
              <Stack spacing={3}>
                <RHFAutoCompleteShort
                  name={'ItemContainer'}
                  label={translate('warehouse.item_container_detail')}
                  data={itemContainerOptions}
                />
                <RHFAutoCompleteShort
                  name={'Supplier'}
                  label={translate('procurement.supplier')}
                  data={supplierOptions}
                />
                <RHFTextField name={'Amount'} type={'number'} label={translate('title.amount')} />
                <RHFTextField name={'Value'} type={'number'} label={translate('title.value')} />
                <Button color={'primary'} variant={'contained'} fullWidth type="submit">
                  {translate('button.create')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </Stack>
      ) : (
        <SearchItemMasterForm onSearchCallBack={onSearchItemMaster} />
      )}
    </ProjectPageContainer>
  );
}

export default AdminCreateItemLotPage;
