import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {useNavigate, useParams } from 'react-router-dom';
import Link from "@mui/material/Link";
import { allowToApprovePRItemUserTypeId } from '../../../utilities/Constants';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import GridDetailText from '../../customs/GridDetailText';
import ProjectPaper from '../../customs/ProjectPaper';
import { useTheme } from '@mui/material/styles';
import GeneralTable from '../../customs/GeneralTable';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { fDateTimeText } from '../../../utilities/formatTime';
import { usePartPurchaseRequisition } from '../../../requests/query/usePartPurchaseRequisition';
import { paths } from '../../../routes/paths';

function PartsPurchaseRequisitionDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { translate } = useLocales();
  const theme = useTheme();
  const { prDetail, printPR, cancelPR, approvePR, deleteItemFromPR } =
    usePartPurchaseRequisition(id);

  const printPRDocument = async () => {
    await printPR.mutateAsync(id);
  };

  const handleCancelPurchaseRequisition = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancelPR.mutateAsync(id);
        navigate(paths.procurement.parts_pr_list);
      }
    );
  };
  const handleApprovePurchaseRequisition = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_approve_pr',
      async () => {
        await approvePR.mutateAsync(id);
        navigate(paths.procurement.parts_pr_list);
      }
    );
  };
  const handleDeleteItemTemplateRequisitionItem = async (itemTemplateId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        const isCancelPR = prDetail.PurchaseRequisitionItemTemplates.length === 1;
        await deleteItemFromPR.mutateAsync({
          PurchaseRequisitionItemTemplateId: itemTemplateId,
          IsCancelPR: isCancelPR,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.purchase_requisition_detail')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: `${translate('procurement.purchase_requisition_list_title')} ${translate(
            'warehouse.parts'
          )}`,
          href: paths.procurement.parts_pr_list,
        },
        { name: `${translate('procurement.purchase_requisition_detail')} : P${id}` },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant={'contained'}
            color={'error'}
            startIcon={<Iconify icon={'eva:close-fill'} />}
            onClick={handleCancelPurchaseRequisition}
          >
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={printPRDocument}
          >
            {translate('button.print_pr')}
          </Button>
        </Stack>
      }
    >
      {prDetail !== null ? (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={`P${prDetail.Id}`} />
              <GridDetailText title={translate('title.detail')} value={prDetail.Detail} />
              <GridDetailText
                title={translate('title.type')}
                value={translate('procurement.request_buy_parts_title')}
              />
              {prDetail.IsUrgent ? (
                <GridDetailText
                  title={translate('title.priority_level_title')}
                  color={theme.palette.error.main}
                  value={translate('procurement.urgent')}
                />
              ) : (
                <GridDetailText
                  title={translate('title.priority_level_title')}
                  value={translate('procurement.not_urgent')}
                />
              )}
              <GridDetailText
                title={translate('procurement.request_to_by_by')}
                value={getEmployeeFullName(prDetail.CreateEmployee)}
              />
              {prDetail.ArchivedAt !== null && (
                <GridDetailText
                  title={translate('title.status')}
                  color={theme.palette.error.main}
                  value={translate('button.cancel')}
                />
              )}
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: 'procurement.already_create_po' },
                { align: 'left', title: 'procurement.po_id' },
                { align: 'left', title: '' },
              ]}
              tableData={prDetail.PurchaseRequisitionItemTemplates.map((item) => {
                if (prDetail.ApproveAt !== null) {
                  return [
                    <Link href={paths.general_warehouse.item_template_detail(item.ItemTemplate.Id)}>
                      {item.ItemTemplate.Name}
                    </Link>,
                    item.Amount,
                    item.ItemTemplate.ItemTemplateUnit.Name,
                    <Iconify
                      sx={{
                        color: item.IsPurchase
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                      }}
                      icon={item.IsPurchase ? 'ic:baseline-check' : 'ic:baseline-close'}
                      width={20}
                      height={20}
                    />,
                    item.PurchaseOrderItemTemplates.length > 0 ? (
                      <Link
                        to={paths.procurement.parts_po_detail(
                          item.PurchaseOrderItemTemplates[0].PurchaseOrderId
                        )}
                      >
                        {item.PurchaseOrderItemTemplates[0].PurchaseOrderId}
                      </Link>
                    ) : (
                      'N/A'
                    ),
                    '',
                  ];
                } else {
                  return [
                    <Link href={paths.general_warehouse.item_template_detail(item.ItemTemplate.Id)}>
                      {item.ItemTemplate.Name}
                    </Link>,
                    item.Amount,
                    item.ItemTemplate.ItemTemplateUnit.Name,
                    <Iconify
                      sx={item.IsPurchase ? theme.palette.success.main : theme.palette.error.main}
                      icon={item.IsPurchase ? 'ic:baseline-check' : 'ic:baseline-close'}
                      width={20}
                      height={20}
                    />,
                    'N/A',
                    [
                      <IconButton
                        color={'error'}
                        onClick={() => handleDeleteItemTemplateRequisitionItem(item.Id)}
                      >
                        <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                      </IconButton>,
                    ],
                  ];
                }
              })}
            />
          </ProjectPaper>
          {prDetail.ApproveAt !== null ? (
            <ProjectPaper>
              <Grid container spacing={3}>
                <GridDetailText
                  title={translate('title.approve_at')}
                  value={fDateTimeText(prDetail.ApproveAt)}
                />
                <GridDetailText
                  title={translate('title.approve_by')}
                  value={getEmployeeFullName(prDetail.ApproveEmployee)}
                />
              </Grid>
            </ProjectPaper>
          ) : (
            <RoleBasedGuard roles={allowToApprovePRItemUserTypeId}>
              <ProjectPaper>
                <Stack spacing={2} direction="row">
                  <Button
                    color={'success'}
                    variant={'contained'}
                    fullWidth
                    startIcon={<Iconify icon={'eva:check-fill'} />}
                    onClick={handleApprovePurchaseRequisition}
                  >
                    {translate('title.approve')}
                  </Button>
                  <Button
                    variant={'contained'}
                    color={'error'}
                    fullWidth
                    startIcon={<Iconify icon={'eva:close-fill'} />}
                    onClick={handleCancelPurchaseRequisition}
                  >
                    {translate('button.cancel')}
                  </Button>
                </Stack>
              </ProjectPaper>
            </RoleBasedGuard>
          )}
        </React.Fragment>
      ) : null}
    </ProjectPageContainer>
  );
}

export default PartsPurchaseRequisitionDetailPage;
