import numeral from 'numeral';

// ----------------------------------------------------------------------
numeral.localeData().currency.symbol = '฿';

export function fCurrencyDecimal(number) {
  const format = number ? numeral(number).format('$0,0.00000') : '';

  return result(format, '.00000');
}
export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fThreeDecimal(number) {
  return numeral(number).format('0.000');
}
export function fFourDecimal(number) {
  return numeral(number).format('0.0000');
}

export const numberTextWithCommas = (number, addTHB = false, skipDecimal = false) => {
  let returnNumber;

  if (skipDecimal) {
    returnNumber = number;
  } else {
    returnNumber = number.toFixed(2);
  }

  if (addTHB) {
    return returnNumber
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .concat(' ', 'บาท');
  }

  return returnNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
export function roundUpNumber(num, precision) {
  return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}
