import { rankItem } from '@tanstack/match-sorter-utils';
import React, { useEffect, useState } from 'react';
import { useLocales } from 'locales';
import { InputAdornment, TextField } from '@mui/material';
import Iconify from 'components/minimal/iconify';

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export function DebouncedInput({ value: initialValue, onChange, debounce = 300, ...props }) {
  const [value, setValue] = useState(initialValue);
  const { translate } = useLocales();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <TextField
      fullWidth
      value={value}
      sx={{ mb: 2 }}
      onChange={(e) => setValue(e.target.value)}
      placeholder={translate('button.search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
