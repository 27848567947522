import React from 'react';
import PropTypes from 'prop-types';
import ProjectPaper from '../customs/ProjectPaper';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import {
  useMaintenanceJobType,
  useMaintenanceTechnicianList,
} from '../../requests/query/DropdownData';
import FormProvider, { RHFAutoCompleteShort } from '../minimal/hook-form';

AcceptMaintenanceJobForm.PropType = {
  onAcceptJobCallback: PropTypes.func.isRequired,
};

function AcceptMaintenanceJobForm({ onAcceptJobCallback }) {
  const { translate } = useLocales();
  const { allMaintenanceJobType } = useMaintenanceJobType();
  const { allTechnician } = useMaintenanceTechnicianList();

  const acceptJob = async (values) => {
    onAcceptJobCallback(values);
  };

  const acceptJobSchema = Yup.object().shape({
    MaintenanceJobType: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    Employee: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(acceptJobSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPaper>
      <FormProvider methods={methods} onSubmit={handleSubmit(acceptJob)}>
        <Stack spacing={3}>
          <RHFAutoCompleteShort
            name={'MaintenanceJobType'}
            label={translate('title.type')}
            data={allMaintenanceJobType}
          />
          <RHFAutoCompleteShort
            name={'Employee'}
            label={translate('maintenance.specialist_title')}
            data={allTechnician}
          />
          <Button variant={'contained'} type={'submit'} color={'primary'}>
            {translate('button.accept')}
          </Button>
        </Stack>
      </FormProvider>
    </ProjectPaper>
  );
}

export default AcceptMaintenanceJobForm;
