import {
  cancelBillingNoteById,
  getBillingNoteDetailById,
  getBillingNotePDFById,
} from '../ProductionServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLoading } from '../../components/loading';
import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { billing_note_prefix } from '../../utilities/Strings/Prefix';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useBillingNote = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  const { data } = useQuery({
    queryKey: ['billing-note', id],
    queryFn: () => getBillingNoteDetailById(id),
    initialData: null,
  });

  const printBillMutation = useMutation({
    mutationFn: getBillingNotePDFById,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (pdfStructure) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(pdfStructure, `${billing_note_prefix}_P${id}.pdf`);
      await OpenSuccessNotification();
    },
  });

  const cancelBillMutation = useMutation({
    mutationFn: cancelBillingNoteById,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['billing-note', id]);
      await OpenSuccessNotification();
    },
  });

  return {
    billingNoteDetail: data,
    printBill: printBillMutation,
    cancelBill: cancelBillMutation,
  };
};
