import {covertSQInchToSQMeter} from "./Helper/DataHelper";
import {fThreeDecimal} from "./formatNumber";

export const getSnapshotDocumentForProductOrder = (productOrderDetail, processDetail) => {

    const date = new Date();
    const sid = `${productOrderDetail.Id}-${date.getDate()}-${date.getMonth()}-${date.getFullYear()}-${date.getMilliseconds()}`;
    const table = [[
        {text: 'Detail', fontSize: 17, style: 'detailBold'},
        {text: 'ข้อมูล', fontSize: 17, style: 'detailBold'},]];

    table.push([
        {text: 'เครื่องที่วิ่ง', fontSize: 16},
        {text: "", fontSize: 16},
    ]);
    table.push([
        {text: 'ผู้วิ่งงาน', fontSize: 16},
        {text: "", fontSize: 16},
    ]);
    table.push([
        {text: 'กะ', fontSize: 16},
        {text: "", fontSize: 16},
    ]);
    table.push([
        {text: 'เริ่ม (เวลา)', fontSize: 16},
        {text: "", fontSize: 16},
    ]);
    table.push([
        {text: 'จบ (เวลา)', fontSize: 16},
        {text: "", fontSize: 16},
    ]);
    table.push([
        {text: 'จำนวนที่วิ่ง (รวม ดี เสีย)', fontSize: 16},
        {text: "", fontSize: 16},
    ]);


    const processTable = [
        [
            {text: 'Process', fontSize: 17, style: 'detailBold'},
            {text: 'Note', fontSize: 17, style: 'detailBold'},
        ]
    ];

    processDetail.forEach((process) => {
        processTable.push([
            {text: process.Name, fontSize: 16},
            {text: "", fontSize: 16},
        ])
    })

    const materialTable = [
        [
            {text: 'รหัสวัตถุดิบ', fontSize: 17, style: 'detailBold'},
            {text: 'ชื่อ', fontSize: 17, style: 'detailBold'},
            {text: 'จำนวนที่ใช้', fontSize: 17, style: 'detailBold'},
            {text: 'หน่วย', fontSize: 17, style: 'detailBold'},
        ]
    ];

    for (let a = 0; a< 15; a++)
    {
        materialTable.push([
            {text: "", fontSize: 16, style: 'subheader'},
            {text: "", fontSize: 16, style: 'subheader'},
            {text: "", fontSize: 16, style: 'subheader'},
            {text: "", fontSize: 16, style: 'subheader'},
        ])
    }

    return {
        info: {
            title: `Snapshot_Report_${productOrderDetail.Id}-${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`,
            author: 'Saenginter Laminate Co.,LTD',
        },
        footer:  { text: `พิมพ์ ${new Date().toLocaleString()}`, alignment: 'right' , style:'footer'},
        content: [
            {
                alignment: 'justify',
                columns: [
                    {
                        text: 'ใบเก็บข้อมูลการใช้วัตถุดิบ',
                        style: 'header'
                    },
                    {
                        width: '*',
                        text: ''
                    },
                    {
                        qr: `{"ProductOrderId":${productOrderDetail.Id},"CustomerId":${productOrderDetail.Customer.Id}},"SID":${sid}`,
                        fit: '80'
                    },
                ]
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*'],
                    body: [
                        [{text: 'SID:', style: 'detailBold'}, {text: sid, style: 'detailBold'}],
                        [{text: 'รหัสคำสั่งลูกค้า (Order Id):', style: 'detailBold'}, {
                            text: productOrderDetail.Id,
                            style: 'detail'
                        }],
                        [{text: 'ชื่องาน (Name):', style: 'detailBold'}, {
                            text: productOrderDetail.Name,
                            style: 'detail'
                        }],
                        [{text: 'จำนวน (Amount):', style: 'detailBold'}, {
                            text: productOrderDetail.Amount,
                            style: 'detail'
                        }],
                        [{
                            text: 'ขนาด (กว้าง x ยาว) (Size Width x Length):',
                            style: 'detailBold'
                        }, {text: `${productOrderDetail.Width} x ${productOrderDetail.Length}`, style: 'detail'}],
                        [{
                            text: 'พื้นที่ ตร.นิ้ว :',
                            style: 'detailBold'
                        }, {
                            text: `${fThreeDecimal(productOrderDetail.Width * productOrderDetail.Length)}`,
                            style: 'detail'
                        }],
                        [{
                            text: 'พื้นที่ ตร.ม :',
                            style: 'detailBold'
                        }, {
                            text: `${fThreeDecimal(covertSQInchToSQMeter(productOrderDetail.Width * productOrderDetail.Length))}`,
                            style: 'detail'
                        }],
                        [{text: 'ลูกค้า (Customer):', style: 'detailBold'}, {
                            text: productOrderDetail.Customer.Name,
                            style: 'detail'
                        }],
                        [{
                            text: 'รายละเอียดเพิ่มเติม (Additional Information):',
                            style: 'detailBold'
                        }, {text: productOrderDetail.Detail, style: 'detail'}],
                    ]
                },
                layout: 'noBorders'
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*'],
                    headerRows: 1,
                    body: table
                }
            },
            {
                text: 'กระบวน การผลิต:',
                style: 'detailBold',
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*'],
                    headerRows: 1,
                    body: processTable
                }
            },
            {
                text: 'Note เพิ่มเติม:',
                style: 'detailBold',
                pageBreak: 'after'
            },
            {
                style: 'tableExample',
                table: {
                    widths: ['*', '*', '*','*'],
                    headerRows: 1,
                    body: materialTable
                }
            },
            {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*'],
                    body: [
                        [
                            {
                                text: `ผู้ผลิตงาน \n\n\n .......................................... \n วันที่ ..............................`,
                                style: 'detail_left_bold_small',
                                alignment: 'center',
                                colSpan: 2,
                            },
                            {},
                            {
                                text: `ผู้ตรวจสอบ \n\n\n .......................................... \n วันที่ ..............................`,
                                style: 'detail_left_bold_small',
                                alignment: 'center',
                            },
                            {
                                text: `ผู้อนุมัติ\n\n\n .......................................... \n วันที่ ..............................`,
                                style: 'detail_left_bold_small',
                                alignment: 'center',
                            },
                        ],
                    ]
                }
            }
            // {
            //     style: 'tableExample',
            //     table: {
            //         widths: ['*', '*'],
            //         body: [
            //             [{text: 'สร้างวันที่ (Created At):', style: 'detailBold'}, {text: `${Helper.getDateTimeString(new Date())}`, style: 'detail'}],
            //             [{text: 'ความสูงเมื่อเสร็จ (Finish Height):', style: 'detailBold'}, {
            //                 text: pile.CompleteHeight > 0 ? pile.CompleteHeight : '',
            //                 style: 'detail'
            //             }],
            //         ]
            //     },
            //     layout: 'noBorders'
            // },
        ],
        styles: {
            header: {
                fontSize: 42,
                bold: true
            },
            detail: {
                fontSize: 17,
            },
            detailBold: {
                fontSize: 18,
                bold: true
            },
            qr: {
                alignment: 'right'
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            },
            tableExample: {
                margin: [0, 0, 0, 0]
            },
            subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 20, 0, 5]
            },
            footer: {
                marginRight: 20
            },
            detail_left_bold_small: {
                fontSize: 12,
                bold: true,
                alignment: 'left'
            },
        },
        defaultStyle: {
            font: 'THSarabunNew'
        }
    }
}
