import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ProjectPaper from '../customs/ProjectPaper';
import { Button, Grid, Stack } from '@mui/material';
import GridDetailText from '../customs/GridDetailText';
import FormProvider, { RHFTextField } from '../minimal/hook-form';
import React from 'react';
import PropTypes from 'prop-types';

export const EditValueInPOForm = ({ itemToEdit, onSaveEditDetailCallBack, supplier }) => {
  const { translate } = useLocales();

  const EditItemDetailSchema = Yup.object().shape({
    Amount: Yup.number()
      .min(0, translate('error.detail_require'))
      .required(translate('error.detail_require')),
    Price: Yup.number()
      .min(0, translate('error.detail_require'))
      .required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(EditItemDetailSchema),
    defaultValues: {
      Detail:
        itemToEdit.Detail !== undefined &&
        itemToEdit.Detail !== null &&
        itemToEdit.Detail.trim() !== ''
          ? itemToEdit.Detail
          : '',
      Amount: itemToEdit.Amount,
      Price: itemToEdit.Price,
    },
  });

  const { handleSubmit } = methods;

  const onSaveEditDetail = (data) => {
    onSaveEditDetailCallBack({
      Item: {
        Id: itemToEdit.Id,
        Detail: data.Detail,
        Name: itemToEdit.Name,
        Unit: itemToEdit.Unit,
        Price: data.Price,
        Amount: data.Amount,
        IsTemp: false,
      },
      Supplier: supplier,
    });
  };

  return (
    <>
      <ProjectPaper>
        <Grid spacing={3}>
          <GridDetailText title={translate('title.name')} value={itemToEdit.Name} />
          <GridDetailText title={translate('unit.title')} value={itemToEdit.Unit} />
        </Grid>
      </ProjectPaper>
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSaveEditDetail)}>
          <Stack spacing={3}>
            <RHFTextField name={'Detail'} label={translate('title.detail')} />
            <RHFTextField name={'Amount'} label={translate('title.amount')} />
            <RHFTextField name={'Price'} label={translate('title.value')} />
            <Button color={'primary'} variant={'contained'} fullWidth type="submit">
              {translate('button.save')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </>
  );
};

EditValueInPOForm.PropType = {
  itemToEdit: PropTypes.object.isRequired,
  onSaveEditDetailCallBack: PropTypes.func.isRequired,
  supplier: PropTypes.object.isRequired,
};
