import { adminServerRequest } from '../utilities/Helper/NetworkHelper';
export async function verifyJob(data) {
  const options = {
    method: 'PATCH',
    url: `jobs/verify/${data.id}`,
    data: data,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function cancelJob(data) {
  const options = {
    method: 'PATCH',
    url: `jobs/cancel/${data.id}`,
    data: data,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function completeJob(data) {
  const options = {
    method: 'PATCH',
    url: `jobs/done/${data.id}`,
    data: data,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function acceptItJob(data) {
  const options = {
    method: 'PATCH',
    url: `jobs/accept/${data.id}`,
    data: data,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function getJobDetailById(id) {
  const options = {
    method: 'GET',
    url: `jobs/${id}`,
  };
  const response = await adminServerRequest(options);
  return response.data;
}

export async function getAllItJob(jobStatus) {
  const options = {
    method: 'GET',
    url: `jobs/status/${jobStatus}`,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function createItJob(data) {
  const options = {
    method: 'POST',
    url: `jobs`,
    data: data,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function createAsset(data) {
  const options = {
    method: 'POST',
    url: `assets`,
    data: data,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
export async function createAssetCategory(data) {
  const options = {
    method: 'POST',
    url: `categories/create`,
    data: data,
  };

  const response = await adminServerRequest(options);
  return response.data;
}

export async function getAllAssetCategories() {
  const options = {
    method: 'GET',
    url: `categories`,
  };
  const response = await adminServerRequest(options);
  return response.data;
}

export async function getAllAsset() {
  const options = {
    method: 'GET',
    url: `assets`,
  };
  const response = await adminServerRequest(options);
  return response.data;
}
