import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  editEmployeePerformanceData,
  getEmployeePerformanceExcel,
  getProductionEmployeePerformanceDashboardData,
} from '../ProductionServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';
import { saveAs } from 'file-saver';
import { useLoading } from '../../components/loading';
export const useProductionEmployeePerformance = (dateRange) => {
  const queryClient = useQueryClient();
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const { data, refetch } = useQuery({
    queryKey: ['production-employee-performance-dashboard', dateRange],
    queryFn: () => getProductionEmployeePerformanceDashboardData(dateRange),
    initialData: [],
  });

  const editEmployeePerformanceMutation = useMutation({
    mutationFn: editEmployeePerformanceData,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['production-employee-performance-dashboard', dateRange]);
    },
  });

  const printReportMutation = useMutation({
    mutationFn: getEmployeePerformanceExcel,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (report) => {
      await hideLoadingScreen();
      saveAs(report, 'รายงานPerformance.xlsx');
    },
  });

  return {
    employeeDetails: data,
    editEmployeePerformance: editEmployeePerformanceMutation,
    handleRefetch: refetch,
    printReport: printReportMutation,
  };
};
