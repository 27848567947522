import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenErrorNotificationThenReloadPage,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import Typography from '@mui/material/Typography';
import { useLocales } from 'locales';
import { approvePileQuality } from '../../../requests/ProductionServerHandler';
import { DataScanner } from '../../barcode-reader';
import { Box, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

function ApproveQualityPage() {
  const { translate } = useLocales();
  const approveQualityMutation = useMutation({
    mutationFn: approvePileQuality,
    onSuccess: async (data) => {
      if (data.isError) {
        await OpenErrorNotificationThenReloadPage(data.message);
      } else {
        await OpenNotificationThenReloadPage(
          `${translate('success.approve_pile_quality')} (${translate('title.amount_good')}: ${
            data.message
          })`
        );
      }
    },
  });

  const onScan = async (scan) => {
    try {
      const dataJson = JSON.parse(scan);
      if (dataJson.RecordId !== undefined && dataJson.PileId !== undefined) {
        await approveQualityMutation.mutateAsync({
          PileId: dataJson.PileId,
          ProductionRecordId: dataJson.RecordId,
        });
      }
    } catch (e) {
      await OpenErrorNotification(translate('error.please_re_scan'));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('qa.approve_pile_quality_title')}
      breadcrumbs={[
        { name: translate('qa.title') },
        { name: translate('qa.approve_pile_quality_title') },
      ]}
    >
      <Stack spacing={2}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img
            alt={'scan'}
            style={{ height: '200px', width: '300px' }}
            src={'/assets/illustrations/Scan_Image.jpg'}
            data-holder-rendered="true"
          />
        </Box>
        <Typography variant={'h3'}>{translate('placeholder.qa_scan')}</Typography>
        <DataScanner disableInput={true} onScanCallback={onScan} />
      </Stack>
    </ProjectPageContainer>
  );
}

export default ApproveQualityPage;
