import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import useAuth from '../../../auth/hooks/useAuth';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { EmployeeScanner } from '../../barcode-reader';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import CarScanner from '../../barcode-reader/CarScanner';
import { useMutation } from '@tanstack/react-query';
import { createCarActivity } from '../../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';

function CarTimeStampPage() {

  const { user } = useAuth();

  const [submitEmployee, setSubmitEmployee] = useState(
    user !== null
      ? {
        Id: user.EmployeeId,
        Name: user.Name,
        Department: user.Department,
        EmployeeCode: user.EmployeeCode,
        IsTemp: false,
      }
      : null,
  );

  const createCarActivityMutation = useMutation({
    mutationFn: createCarActivity,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage('คุณส่งข้อมูลสำเร็จ');
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
    },
  });

  const handleGetEmployeeDetail = (employeeDetail) => {
    setSubmitEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };

  const onStampCar = async (value) => {
    await createCarActivityMutation.mutate({
      CarId: value,
      EmployeeId: submitEmployee.Id,
      IsTemp: submitEmployee.IsTemp,
    });
  }

  return (
    <ProjectPageContainer menu_title={'จัดส่งบันทึกเข้า - ออก'}>
      {submitEmployee !== null ? <CarScanner onSearchCallBack={onStampCar} isShowTooltips /> : <EmployeeScanner
        disableInput={user === null || user.UserTypeId !== ManagementUserTypeId}
        onScanCallback={handleGetEmployeeDetail}
        isPreFetchData
        isShowTooltips
      />}
    </ProjectPageContainer>
  );
}

export default CarTimeStampPage;