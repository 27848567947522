import MaintenanceJobListPage from '../../components/pages/MaintenanceModule/MaintenanceJobListPage';
import CreateMaintenanceJobPage from '../../components/pages/MaintenanceModule/CreateMaintenanceJobPage';
import MaintenanceJobDetailPage from '../../components/pages/MaintenanceModule/MaintenanceJobDetailPage';
import MaintenanceMachineDashboardPage from '../../components/pages/MaintenanceModule/MaintenanceMachineDashboardPage';
import MaintenancePersonalDashboardPage from '../../components/pages/MaintenanceModule/MaintenancePersonalDashboardPage';
import EvaluateMaintenanceJobPage from '../../components/pages/MaintenanceModule/EvaluateMaintenanceJobPage';
import {
  AdminUserTypeId,
  HumanResourceUserTypeId,
  MaintenanceControlUserTypeId,
  MaintenanceUserTypeId,
  ProductionControlUserTypeId,
} from '../../utilities/Constants';
import PrintMaintenanceJobReportPage from '../../components/pages/MaintenanceModule/PrintMaintenanceJobReportPage';
import MaintenanceDashboardPage from '../../components/pages/MaintenanceModule/MaintenanceDashboardPage';
import SearchMaintenancePage from '../../components/pages/MaintenanceModule/SearchMaintenancePage';
import { paths } from '../paths';
const maintenanceModuleRouteDetails = [
  {
    path: paths.maintenance.search,
    component: <SearchMaintenancePage />,
    isPrivate: true,
  },
  {
    path: paths.maintenance.dashboard,
    component: <MaintenanceDashboardPage />,
    isPrivate: true,
  },
  {
    path: paths.maintenance.job_list,
    component: <MaintenanceJobListPage />,
    isPrivate: true,
    allowUserTypeId: [
      HumanResourceUserTypeId,
      MaintenanceControlUserTypeId,
      MaintenanceUserTypeId,
      ProductionControlUserTypeId,
      AdminUserTypeId,
    ],
  },
  {
    path: paths.maintenance.personal_dashboard,
    component: <MaintenancePersonalDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [
      HumanResourceUserTypeId,
      MaintenanceControlUserTypeId,
      MaintenanceUserTypeId,
      ProductionControlUserTypeId,
      AdminUserTypeId,
    ],
  },
  {
    path: paths.maintenance.create_job,
    component: <CreateMaintenanceJobPage />,
    isPrivate: false,
  },
  {
    path: paths.maintenance.print_job_report,
    component: <PrintMaintenanceJobReportPage />,
    isPrivate: true,
  },
  {
    path: paths.maintenance.job_detail(':id'),
    component: <MaintenanceJobDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.maintenance.evaluate_job(':id'),
    component: <EvaluateMaintenanceJobPage />,
    isPrivate: true,
  },
  {
    path: paths.maintenance.machine_dashboard,
    component: <MaintenanceMachineDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [
      HumanResourceUserTypeId,
      MaintenanceControlUserTypeId,
      MaintenanceUserTypeId,
      ProductionControlUserTypeId,
      AdminUserTypeId,
    ],
  },
];

export default maintenanceModuleRouteDetails;
