export const GeneralUserTypeId = 1;
export const ManagementUserTypeId = 2;
export const StoreUserTypeId = 3;
export const MaintenanceUserTypeId = 4;
export const MaintenanceControlUserTypeId = 5;
export const ProductionControlUserTypeId = 6;
export const ProductionUserTypeId = 7;
export const SalesUserTypeId = 8;
export const AdminUserTypeId = 9;
export const ProcurementUserTypeId = 10;
export const SecretaryUserTypeId = 11;
export const HumanResourceUserTypeId = 12;
export const AccountingUserTypeId = 13;
export const QualityAssuranceUserTypeId = 14;
export const LogisticUserTypeId = 15;
export const WarehouseControlUserTypeId = 16;
export const FinanceUserTypeId = 17;
export const superiorLevelUserTypeId = [
    ManagementUserTypeId,
    AdminUserTypeId,
];
export const MaintenanceJobStatus = {
    WaitForAccept: 1,
    WorkInProcess: 2,
    WaitForEvaluation: 3,
    Complete: 4,
    Hold: 5,
    WaitForParts: 6,
    Delete: 7,
    WaitForSupplier: 8,
};

export const allowToEditItemDetailUserTypeId = [
    ManagementUserTypeId,
    StoreUserTypeId,
    MaintenanceControlUserTypeId,
    AdminUserTypeId,
    ProcurementUserTypeId,
    AccountingUserTypeId,
];

export const allowToEditItemTemplateAmountUserTypeId = [
    ManagementUserTypeId,
    StoreUserTypeId,
    AdminUserTypeId,
    ProcurementUserTypeId,
    AccountingUserTypeId
];
export const allowToUploadUserTypeId = [ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId, SecretaryUserTypeId];
export const allowToApprovePRItemUserTypeId = [ManagementUserTypeId];

export const basePaperSize = 333.25
export const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
];
