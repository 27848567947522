import React from 'react';
import PropTypes from 'prop-types';
import { useAdditionalCostType } from 'requests/query/DropdownData';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from 'locales';
import { Stack, Button } from '@mui/material';
import FormProvider, { RHFTextField, RHFAutoCompleteShort } from 'components/minimal/hook-form';

CreateAdditionalCostForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreateAdditionalCostForm({ onFormSubmit }) {
  const { translate } = useLocales();
  const { additionalCostTypes } = useAdditionalCostType();

  const AdditionalCostSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    AdditionalCostType: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(AdditionalCostSchema),
  });

  const { handleSubmit } = methods;
  const onSubmitForm = (values) => {
    onFormSubmit(values);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={2}>
        <RHFTextField name="Name" label={translate('title.name')} />
        <RHFAutoCompleteShort
          name="AdditionalCostType"
          label={translate('title.type')}
          data={additionalCostTypes}
        />
        <Button fullWidth variant={'contained'} color={'primary'} type={'submit'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateAdditionalCostForm;
