const en = {
    language: 'English',
    login: 'Login',
    home: 'Home',
    dashboard: 'Dashboard',
    factory: 'Factory',
    button: {
        view_all: 'View All',
        next: 'Next',
        back: 'Back',
        create: 'Create',
        change_product_order: 'Change Product Order',
        search: 'Search',
        open_camera_for_scan: 'Open Camera for Scan',
        close_camera: 'Close Camera',
        open_front_camera: 'Open Front Camera',
        open_rear_camera: 'Open Rear Camera',
        submit:'Submit',
        logout:'Logout'
    },
    title:{
        hello:'Hello',
        detail: 'Detail',
        performance: 'Performance',
        value: 'Value',
        id: 'Id',
        name: 'Name',
        status: 'Status',
        all: 'All',
        new: 'New',
        work_in_process: 'Work in Process',
        hold: 'Hold',
        pass_due: 'Pass Due Date',
        create_at: 'Create At',
        due_at: 'Due Date At',
        total_pile_in_amount: 'Total In Amount',
        amount:'Amount' ,
        customer: 'Customer',
        start_date: 'Start Date',
        end_date: 'End Date',
        width: 'Width',
        length: 'Length',
        height: 'Height',
        product_order_subtype: 'Product Order Subtype',
        sku:'SKU',
        clear: 'Clear',
        price: 'Price',
        price_per_sheet: 'Price per sheet',
        price_per_square_inch: 'Price per square inch',
        bundle_price: 'Bundle Price',
        remove: 'Remove',
        conclude: 'Conclude',
        amphur: 'Amphur',
        district: 'District',
        province: 'Province',
        additional_address: 'Additional Address',
        tax_registration_number: 'Tax Registration Number',
        telephone_number: 'Telephone Number',
        create_by: 'Create By',
        amount_in: 'Amount in factory',
        amount_good: 'Good Amount',
        amount_defect: 'Defect Amount',
        pile: 'Pile',
        external_reference_id: 'External Reference Id',
        complete_height: 'Complete height',
        process: 'Process',
        complete_piles: 'Complete Piles',
        un_complete_piles: 'Uncompleted Piles',
        total: 'Total',
        complete_date: 'Complete Date',
        approve_date: 'Approve Date',
        canceled_at: 'Cancel At',
        canceled: 'Cancel',
        complete: 'Complete',
        pile_amount: 'Pile Amount',
        delivery_by: 'Delivery by',
        empty: 'Empty',
        billing_at: 'Billing At',
        problem: 'Problem',
        check_by: 'Check by',
        solve_at: 'Solve at',
        employee_id: 'Employee Id',
        machine: 'เครื่องจักร'
    },
    placeholder:{
        search_product_order: 'Search with Customer, Name or Product Order ID...',
        search_product: 'Search with Name, Type Name or SKU...',
        empty_table: 'Look like your table is empty',
        please_scan_your_employee_id: 'Please scan your employee id',
        camera_guide: 'Scan only QR or Barcode',
        please_scan_pile_id: 'Please scan Pile Id',
    },
    home_menu: {
        title: `Menu`,
        general: `General`,
        submit_production_record: `Submit Production Record`,
    },
    production: {
        title: `Production`,
        product_order_list: 'Product Order List',
        create_new_product_order: 'Create New Product Order',
        product_order_detail: 'Product Order Detail',
        rework_order_id: 'Rework Order Id',
        rework_order: 'Rework Order Id',
        pile_id: 'Pile Id',
        create_pile: 'Create Pile',
        employee_dashboard: 'Employee Dashboard',
        submit_production_record: `Submit Production Record`,
        create_production_process_title: 'Create Production Process',
        print_machine_production_report_title: 'Print Machine Production Reports'
    },
    qa:{
        title: 'Quality Assurance',
        pile_quality_problem: 'Pile Quality Problem',
    },
    auth: {
        sign_in_to_sil_service : 'Sign in to SIL Service',
        enter_detail : 'Enter details below.',
        tooltip : 'Contact IT Department for your account',
        username: 'Username',
        password: 'Password',
    },
    sales: {
        title: 'Sales',
        total_sales: 'Total Sales',
        monthly_sales: 'Monthly Sales',
        total_active_product_order: 'Total Active Product Order',
        compare_to_last_year: 'Compare to last year',
        latest_product_order: 'Latest Product Order',
        select_customer: 'Select customer',
        select_product: 'Select Product',
        price_history: 'Price History',
        no_transaction: 'No Transaction',
        manage_price: 'Manage price',
        additional_cost_bill: 'Additional Cost Bill',
        delivery_bill: 'Delivery Bill',
        billing_note: 'Billing Note',
        create_quotation_title: 'Create Quotation',
        product_management_title: 'Product Management',
        create_new_product_title: 'Create New Product',
        print_customer_report_page_title: 'Print Customer Sales Report',
        print_sales_value_report_page_title: 'Print Sales Value Report',
        print_product_quotation_page_title: 'Print Quotation',
        create_additional_service_cost:'Create Additional Service'
    },
    unit: {
        sheet: 'Sheet'
    },
    error: {
        username_require: 'Username is required',
        password_require: 'Password is required',
        unable_to_login: 'Unable to login',
        error_re_login: 'Unauthorized',
        error_contact_admin: 'Contact Administrator',
        name_is_require: 'Name is require',
        width_is_require: 'Width need to be greater than 5',
        length_is_require: 'Length need to be greater than 5',
        amount_is_require: 'Amount need to be greater than 0',
        product_order_subtype_is_require: 'Please select product order subtype',
        error_please_select_one_option: 'Please select one option',
        price_too_low: 'Price too low',
        address_require: 'Address require',
        tax_registration_number_require: 'Tax number require',
        telephone_number_require: 'Telephone number require',
        product_order_already_complete: 'Product order already complete',
        height_require: 'Height need to be greater than 10',
        product_order_id_require: 'Product order ID require',
        product_order_amount_exceed: 'Product order amount is exceed',
        employee_not_found: 'Employee not found',
        machine_is_require: 'Machine is require',
    },
    notification:{
        update_success: 'Update success!',
        create_success: 'Create success!',
        create_pile_id: 'You have create pile Id: '
    },
    user: {
        title: 'User',
        profile: 'User Profile',
        employee_uid : 'Employee-UID',
        username: 'Username',
        name: 'Name',
        employee_code : 'Employee-Code',
        department : 'Department'
    },
    warehouse:{
        title: 'Warehouse',
        material: 'Material',
        item_requisition_report: 'Item Requisition Report',
        item_receipt_report: 'Item Receipt Report'
    },
    management:{
        title:'Management',
        approve_special_price_title: 'Approve Special Price'
    },
    accounting:{
        title:'Accounting',
        create_billing_note_title: 'Create billing note',
        add_new_customer: 'Add new customer',
        search_bill:'Search Bill',
        create_claim_record: 'Create claim record',
        print_delivery_summary_report_page_title: 'Print Delivery bill summary',
        print_product_sell_summary_report_page_title: 'Print Product sell summary',
        disable_customer: 'Disable Customer'
    },
    admin:{
        title: 'Administrator',
        change_new_password_title: 'Change user password',
        print_pile_pdf_page_title: 'Print Pile Document',
        print_delivery_bill_pdf_page_title: 'Print Delivery Bill',
        edit_pile_amount_title: 'Edit Pile Amount',
        edit_product_order_amount_title: 'Edit Product Order Amount',
        edit_size: 'Edit Product Order Size',
        edit_pile_good_amount_page_title: 'Edit Pile Good Amount',
        edit_sell_price_title: 'Edit Product Order Sell Price'
    },
    hr: {
        title: 'บุคคล',
        employee_list: 'รายชื่อพนักงงาน',
        add_employee: 'เพิ่มพนักงานใหม่',
        add_user: 'เพิ่ม user ใหม่',
        remove_employee: 'ปลดพนักงาน',
        add_penalty_record: 'บันทึกใบเตือน',
        add_employee_record: 'บันทึกข้อมูลพนักงาน'
    },
    logistic: {
        title: 'จัดส่ง',
        create_delivery_bill: 'สร้างใบส่งของ',
        add_car: 'เพิ่มรถจัดส่งใหม่'
    },
    maintenance: {
        title: 'ซ่อมบำรุง',
        machine_dashboard: 'Dashboard เครื่องจักร',
        assign_maintenance_jobs: 'รวมซ่อมงานที่ได้รับมอบหมาย',
        maintenance_job_list: 'รายการงานซ่อม',
        create_maintenance_job: 'สร้างงานซ่อมใหม่',
        print_maintenance_job_report_page_title: "พิมพ์ใบสรุปข้อมูลซ่อม"
    },
    planning: {
        title: 'วางแผน',
        production_planning: 'วางแผนงานผลิต',
        approve_exceed_amount: 'อนุมัติยอดขา',
        create_rework_order: 'สร้างงานซ่อมผลิต',
        edit_pile_reference: 'แก้รายละเอียดเพิ่มเติมขางาน',
        print_report_planning_title: "พิมพ์รายงาน"
    },
    procurement: {
        title: 'จัดซื้อ',
        purchase_requisition_list_title: "รายการ PR",
        request_buy_material_title: "ขอซื้อวัถุดิบ",
        request_buy_parts_title: "ขอซื้ออะไหล่ / ของทั่วไป",
        purchase_order_list_title: "รายการ PO",
        supplier_report_detail_title: "Supplier Report",
        create_po_title: "สร้าง PO วัตถุดิบ",
        create_po_parts_title: "สร้าง PO อะไหล่ / ของทั่วไป",
        po_reference_id_title: "รหัสอ้างอิงจัดซื้อ",
        create_purchase_requisition_title: "สร้าง PR",
        create_parts_purchase_requisition_title: "สร้าง PR อะไหล่ / ของทั่วไป",
        success_create_purchase_requisition_id_title: "สร้าง PR สำเร็จเลขที่",
        create_new_unit_material_title:'เพิ่มหน่วยวัตถุดิบ',
        create_new_unit_part_title:'เพิ่มหน่วยอะไหล่ / ของทั่วไป'
    }
}

export default en;
