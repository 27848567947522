import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { changeUserPassword } from '../../../requests/LoginServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useForm } from 'react-hook-form';
import useAuth from '../../../auth/hooks/useAuth';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from '../../../locales';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

const ChangeUserPasswordPage = () => {
  const { user } = useAuth();
  const { translate } = useLocales();

  const editPasswordMutation = useMutation({
    mutationFn: changeUserPassword,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const editUserPassword = async (value) => {
    await editPasswordMutation.mutateAsync({
      Username: value.Username,
      Password: value.Password,
      ChangeUserTypeId: user.UserTypeId,
    });
  };

  const changePasswordSchema = Yup.object().shape({
    Username: Yup.string().required(translate('error.detail_require')),
    Password: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('admin.change_new_password_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.change_new_password_title') },
      ]}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(editUserPassword)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name="Username" label={translate('user.username')} />
            <RHFTextField name="Password" type={'password'} label={translate('auth.password')} />
            <Button variant={'contained'} type={'submit'} color={'primary'}>
              {translate('button.save')}
            </Button>
          </Stack>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
};

export default ChangeUserPasswordPage;
