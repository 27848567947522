import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import { useLocales } from 'locales';
import {
  getAllSubmitPileRequireInfo,
  submitProductionRecord,
} from '../../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenErrorNotificationThenReloadPage,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import useAuth from '../../../auth/hooks/useAuth';
import { useMutation } from '@tanstack/react-query';
import PileScanner from '../../barcode-reader/PileScanner';
import { EmployeeScanner } from '../../barcode-reader';
import SubmitPileForm from '../../forms/SubmitPileForm';
import { ManagementUserTypeId } from '../../../utilities/Constants';

function SubmitProductionReportPage() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const [submitEmployee, setSubmitEmployee] = useState(
    user !== null
      ? {
          Id: user.EmployeeId,
          Name: user.Name,
          Department: user.Department,
          EmployeeCode: user.EmployeeCode,
          IsTemp: false,
        }
      : null
  );
  const [submitPile, setSubmitPile] = useState(null);
  const [machineOptions, setMachineOptions] = useState([]);
  const isTemp = true;

  const searchPileMutation = useMutation({
    mutationFn: getAllSubmitPileRequireInfo,
    onSuccess: async (response) => {
      if ([1, 2].includes(response.PileDetail.PileStatus.Id)) {
        setMachineOptions(response.Machine);
        setSubmitPile(response.PileDetail);
      } else {
        await OpenErrorNotification(
          `ไม่สามารถส่งได้เพราะสถานะขางาน : ${response.PileDetail.PileStatus.Name}`
        );
      }
    },
    onError: async (error) => {
      await OpenErrorNotificationThenReloadPage(error);
    },
  });
  const submitPileMutation = useMutation({
    mutationFn: submitProductionRecord,
    onSuccess: async (response) => {
      if (response.Response.IsError !== null) {
        await OpenErrorNotificationThenReloadPage(response.Response.IsError);
      } else {
        await OpenNotificationThenReloadPage();
      }
    },
  });

  const handleGetEmployeeDetail = (employeeDetail) => {
    setSubmitEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };

  const searchPile = async (searchString) => {
    await searchPileMutation.mutateAsync({
      PileId: searchString,
      isTemp: isTemp,
    });
  };

  const onSubmitPile = async (value) => {
    await submitPileMutation.mutateAsync({
      PileId: submitPile.Id,
      GoodAmount: value.GoodAmount,
      DefectAmount: value.DefectAmount,
      Height: value.Height,
      MachineId: value.Machine.Id,
      SubmitEmployeeId: submitEmployee.Id,
      IsTemp: submitEmployee.IsTemp,
    });
  };

  const onRemoveEmployee = () => {
    setSubmitEmployee(null);
    setSubmitPile(null);
  };

  const onRemovePile = () => {
    setSubmitPile(null);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.submit_production_record')}
      breadcrumbs={[
        { name: translate('production.title') },
        { name: translate('production.submit_production_record') },
      ]}
    >
      {submitEmployee !== null ? (
        <Stack spacing={2}>
          <Card>
            <CardHeader
              title={translate('title.delivery_by')}
              action={
                submitEmployee.IsTemp && (
                  <IconButton color={'error'} onClick={onRemoveEmployee}>
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                )
              }
            />
            <CardContent>
              <Grid container spacing={3}>
                <GridDetailText title={translate('title.id')} value={submitEmployee.Id} />
                <GridDetailText title={translate('title.name')} value={submitEmployee.Name} />
                <GridDetailText
                  title={translate('user.employee_code')}
                  value={submitEmployee.EmployeeCode}
                />
                <GridDetailText
                  title={translate('user.department')}
                  value={submitEmployee.Department.Name}
                />
              </Grid>
            </CardContent>
          </Card>
          {submitPile !== null ? (
            <Stack spacing={2}>
              <Card>
                <CardHeader
                  title={translate('title.pile')}
                  action={
                    <IconButton color={'error'} onClick={onRemovePile}>
                      <Iconify icon={'eva:close-fill'} />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Grid container spacing={3}>
                    <GridDetailText title={translate('title.id')} value={submitPile.Id} />
                    <GridDetailText
                      title={translate('title.external_reference_id')}
                      value={submitPile.ReferenceId}
                    />
                    <Grid item xs={12}>
                      <GeneralTable
                        tableHeaders={[
                          { align: 'left', title: 'title.id' },
                          { align: 'left', title: 'title.process' },
                          { align: 'left', title: 'title.machine' },
                          { align: 'left', title: 'title.status' },
                        ]}
                        tableData={submitPile.ProductionRecords.map((record, index) => [
                          index + 1,
                          record.ProductionProcess.Name,
                          record.Machine !== null ? record.Machine.Name : 'N/A',
                          <Iconify
                            key={index}
                            sx={{
                              color: record.CompletedAt !== null ? 'success.main' : 'error.main',
                            }}
                            icon={
                              record.CompletedAt !== null ? 'eva:checkmark-fill' : 'eva:close-fill'
                            }
                            width={20}
                            height={20}
                          />,
                        ])}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={translate('title.submit_pile_detail')} />
                <CardContent>
                  <SubmitPileForm onSubmitCallBack={onSubmitPile} machineOptions={machineOptions} />
                </CardContent>
              </Card>
            </Stack>
          ) : (
            <PileScanner onSearchCallBack={searchPile} isShowTooltips />
          )}
        </Stack>
      ) : (
        <EmployeeScanner disableInput={user === null || user.UserTypeId !== ManagementUserTypeId} onScanCallback={handleGetEmployeeDetail} isShowTooltips isPreFetchData />
      )}
    </ProjectPageContainer>
  );
}

export default SubmitProductionReportPage;
