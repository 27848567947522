import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenNotificationThenReloadPage,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { createInventoryStore } from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import CreateInventoryForm from '../../forms/CreateInventoryForm';
import { useMutation } from '@tanstack/react-query';

const CreateInventoryPage = () => {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const search = useLocation().search;
  const redirect = new URLSearchParams(search).get('redirect');

  const createInventoryMutation = useMutation({
    mutationFn: createInventoryStore,
    onSuccess: async () => {
      if (redirect !== null) {
        await OpenSuccessNotification();
        navigate(redirect);
      } else {
        await OpenNotificationThenReloadPage();
      }
    },
  });
  const handleCreateInventoryStore = async (values) => {
    await createInventoryMutation.mutateAsync(values.Name);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_new_store')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.create_new_store') },
      ]}
    >
      <ProjectPaper>
        <CreateInventoryForm onFormSubmit={handleCreateInventoryStore} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default CreateInventoryPage;
