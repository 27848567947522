import React from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import useTabs from '../../../hooks/useTabs';
import OverallPlanning from '../../details/planning/OverallPlanning';
import TodayPlanning from '../../details/planning/TodayPlanning';
import MachineListPlanning from '../../details/planning/MachineListPlanning';
import ProductOrderDetailModal from '../../details/planning/ProductOrderDetailModal';

function PlanningMainPage() {
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs('overall');

  const INFORMATION_TABS = [
    {
      value: 'overall',
      title: translate('planning.overall_po'),
      component: <OverallPlanning key={'overall'} />,
    },
    {
      value: 'today',
      title: translate('planning.today_work'),
      component: <TodayPlanning key={'today'} />,
    },
    {
      value: 'machine',
      title: translate('planning.overall_machine'),
      component: <MachineListPlanning key={'machine'} />,
    },
  ];

  return (
    <ProjectPageContainer menu_title={translate('planning.production_planning')}>
      <ProductOrderDetailModal />
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
}

export default PlanningMainPage;
