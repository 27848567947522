import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StoreComponent from '../../customs/StoreComponent';
import { selectPartsRequestCart } from '../../../redux/reducers/inventory';
import { RouterLink } from 'routes/components';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import { Box, Button } from '@mui/material';
import useTabs from '../../../hooks/useTabs';
import { paths } from '../../../routes/paths';
import useQueryParameter from "../../../hooks/useQueryParameter";

const InventoryStore = () => {
  const PartsRequestCarts = useSelector(selectPartsRequestCart);
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs('parts');
  const searchParams = useQueryParameter();
  const maintenanceJobId = searchParams.get('maintenanceJobId');

  const INFORMATION_TABS = [
    {
      value: 'parts',
      title: translate('warehouse.part_item'),
      icon: <Iconify icon={'ant-design:setting-outlined'} width={20} height={20} />,
      component: <StoreComponent is_request={true} category_id={2} />,
    },
    {
      value: 'generals',
      title: translate('warehouse.general_item'),
      icon: <Iconify icon={'akar-icons:shipping-box-01'} width={20} height={20} />,
      component: <StoreComponent is_request={true} category_id={3} />,
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.title')}
      breadcrumbs={[{ name: translate('warehouse.parts') }, { name: translate('warehouse.title') }]}
      action={
        <Button
          variant="contained"
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={PartsRequestCarts.length === 0}
          color={'primary'}
          to={maintenanceJobId !== null ? `${paths.general_warehouse.check_out_items}?maintenanceJobId=${maintenanceJobId}` : paths.general_warehouse.check_out_items}
        >
          {`${translate('warehouse.request_item')} ${PartsRequestCarts.length} ${translate(
            'warehouse.items'
          )}`}
        </Button>
      }
    >
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
};

export default InventoryStore;
