import React from 'react';
import {ProjectPageContainer} from "../../container/ProjectPageContainer";
import ProjectPaper from "../../customs/ProjectPaper";
import ReactTable from "../../react-table/ReactTable";
import {IconButton} from "@mui/material";
import {RouterLink} from "../../../routes/components";
import {paths} from "../../../routes/paths";
import Iconify from "../../minimal/iconify";
import {OPEN_ICON} from "../../../config-global";
import {useLocales} from "../../../locales";
import {useQuery} from "@tanstack/react-query";
import {getAllActiveQuotation} from "../../../requests/ProductionServerHandler";

function ProductOrderQuotationListPage() {
    const { translate } = useLocales();
    const { data } = useQuery({
        queryKey: ['all-active-quotations'],
        queryFn: getAllActiveQuotation,
        initialData: [],
    });

    return (
        <ProjectPageContainer
            menu_title={translate('sales.wait_quotation_list')}
            breadcrumbs={[{ name: translate('sales.title') }, { name: translate('sales.wait_quotation_list') }]}
        >
            <ProjectPaper>
                <ReactTable
                    search
                    data={data.map((quotation) => {
                        return {
                            id: quotation.Id,
                            name: quotation.Name,
                            customer: quotation.Customer.Name,
                            status: quotation.ApprovedEmployeeId !== null ? 'รอสร้าง Order' : 'รออนุมัติ',
                            actions: quotation.Id,
                        };
                    })}
                    header={[
                        {
                            header: translate('title.id'),
                            accessorKey: 'id',
                        },
                        {
                            header: translate('title.name'),
                            accessorKey: 'name',
                        },
                        {
                            header: translate('title.customer'),
                            accessorKey: 'customer',
                        },
                        {
                            header: translate('title.status'),
                            accessorKey: 'status',
                        },
                        {
                            header: 'Actions',
                            accessorKey: 'actions',
                            cell: (info) => (
                                <>
                                    <IconButton
                                        component={RouterLink}
                                        to={paths.sales.product_order_quotation_detail(info.getValue())}
                                    >
                                        <Iconify icon={OPEN_ICON} />
                                    </IconButton>
                                </>
                            ),
                        },
                    ]}
                />
            </ProjectPaper>
        </ProjectPageContainer>
    );
}

export default ProductOrderQuotationListPage;