import { createContext, useContext } from 'react';

// ----------------------------------------------------------------------

export const LoadingContext = createContext({});

export const useLoading = () => {
  const context = useContext(LoadingContext);

  if (!context) throw new Error('useLoading must be use inside LoadingContextProvider');

  return context;
};
