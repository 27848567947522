import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@mui/material';
import SelectBomForm from '../../forms/SelectBOMForm';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import BOMDetail from './BOMDetail';

ProductBomDetail.propTypes = {
  productDetail: PropTypes.object.isRequired,
};

function ProductBomDetail({ productDetail }) {
  return (
    <Card>
      <CardHeader title="BOM"></CardHeader>
      <CardContent>
        {
          productDetail.ActiveBOMId !== null ? <BOMDetail bomId={productDetail.ActiveBOMId} /> :
            <RoleBasedGuard roles={[ManagementUserTypeId]} hasContent>
              <SelectBomForm productId={productDetail.Id} />
            </RoleBasedGuard>
        }
      </CardContent>
    </Card>
  );
}

export default ProductBomDetail;