import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { paths } from 'routes/paths';

const SearchFinancePage = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const onSearch = async (searchString, selectType) => {
    if (selectType === 1) {
      if (searchString.includes('C-')) {
        const id = searchString.split('C-')[1];
        navigate(paths.finance.cash_delivery_bill_detail(id));
      } else {
        navigate(paths.finance.cash_delivery_bill_detail(searchString));
      }
    }
  };

  const searchType = [{ value: 1, Name: translate('finance.search_cash_delivery_bill_by_id') }];

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[{ name: translate('finance.title') }, { name: translate('button.search') }]}
    >
      <React.Fragment>
        <SearchBarWithSelectType
          search_label={translate('button.search')}
          select_options={searchType}
          onSearchCallBack={onSearch}
        />
      </React.Fragment>
    </ProjectPageContainer>
  );
};

export default SearchFinancePage;
