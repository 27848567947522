import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, {
  RHFAutoCompleteShort,
  RHFDatePicker,
  RHFTextField,
} from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import { getAllAssetCategories } from '../../requests/AdminServerHandler';
import { useQuery } from '@tanstack/react-query';

CreateItAssetForm.propTypes = {
  onSubmitValue: PropTypes.func.isRequired,
};

function CreateItAssetForm({ onSubmitValue }) {
  const { translate } = useLocales();

  const { data } = useQuery({
    queryKey: ['getAllAssetCategories'],
    queryFn: getAllAssetCategories,
    initialData: {
      data: [],
    },
  });

  const createItAssetSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.detail_require')),
    Category: Yup.object().required(translate('error.detail_require')),
    Code: Yup.string().required(translate('error.detail_require')),
    SerialNumber: Yup.string().required(translate('error.detail_require')),
    Brand: Yup.string().required(translate('error.detail_require')),
    Type: Yup.string().required(translate('error.detail_require')),
    Spec: Yup.string().required(translate('error.detail_require')),
    Color: Yup.string().required(translate('error.detail_require')),
    AssignUserId: Yup.number().required(translate('error.detail_require')),
    WarrantyDate: Yup.date().required(translate('error.detail_require')),
  });
  const defaultValue = {
    Name: '',
    Category: null,
    Code: '',
    SerialNumber: '',
    Brand: '',
    Type: '',
    Spec: '',
    Color: '',
    AssignUserId: 0,
    Note: '',
    WarrantyDate: new Date(),
  };

  const methods = useForm({
    resolver: yupResolver(createItAssetSchema),
    defaultValues: defaultValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onSubmitValue(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name={'Name'} label={translate('title.name')} />
        <RHFAutoCompleteShort
          name={'Category'}
          label={translate('it.asset_category')}
          data={data.data.map((item) => {
            return {
              Id: item.id,
              Name: item.name,
            };
          })}
        />
        <RHFTextField name={'Code'} label={translate('it.code')} />
        <RHFTextField name={'SerialNumber'} label={translate('it.serial_number')} />
        <RHFTextField name={'Brand'} label={translate('it.brand')} />
        <RHFTextField name={'Type'} label={translate('it.type')} />
        <RHFTextField name={'Spec'} label={translate('it.spec')} />
        <RHFTextField name={'Color'} label={translate('it.color')} />
        <RHFTextField name={'AssignUserId'} label={translate('it.assign_user_code')} />
        <RHFTextField name={'Note'} label={translate('it.note')} />
        <RHFDatePicker name={'WarrantyDate'} label={translate('it.warranty_expire')} />
        <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateItAssetForm;
