import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Stack } from '@mui/material';
import FormProvider, { RHFTextField } from '../minimal/hook-form';

CreateItemTemplateUnitForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

function CreateItemTemplateUnitForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const createItemTemplateUnitSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_require')),
  });
  const methods = useForm({
    resolver: yupResolver(createItemTemplateUnitSchema),
    defaultValues: {
      Name: '',
    },
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onSubmitCallback(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="Name" label={translate('title.name')} />
        <Button variant={'contained'} type={'submit'} color={'primary'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateItemTemplateUnitForm;
