export class AcceptItemDetail {
  constructor(poItemContainer) {
    this.itemMaster = poItemContainer.SupplierSellItemContainer.ItemMaster;
    this.itemContainer = poItemContainer.SupplierSellItemContainer.ItemContainer;
    this.poItemContainer = {
      Id: poItemContainer.Id,
      Amount: poItemContainer.Amount,
      PurchaseOrderId: poItemContainer.PurchaseOrderId,
      AcceptAmount: poItemContainer.AcceptAmount,
      IsAcceptComplete: poItemContainer.IsAcceptComplete,
    };
    this.supplierSellItemContainer = {
      Id: poItemContainer.SupplierSellItemContainer.Id,
      Name: poItemContainer.SupplierSellItemContainer.Name,
      ItemMasterUnitAmount: poItemContainer.SupplierSellItemContainer.ItemMasterUnitAmount,
      SellUnitPerItemContainerUnitAmount:
        poItemContainer.SupplierSellItemContainer.SellUnitPerItemContainerUnitAmount,
      PricePerSellUnit: poItemContainer.SupplierSellItemContainer.PricePerSellUnit,
      SellUnit: poItemContainer.SupplierSellItemContainer.SellItemUnit,
    };
    this.containerAmount =
      poItemContainer.Amount /
      poItemContainer.SupplierSellItemContainer.SellUnitPerItemContainerUnitAmount;
    this.alreadyAccept =
      poItemContainer.AcceptAmount /
      poItemContainer.SupplierSellItemContainer.SellUnitPerItemContainerUnitAmount;
  }

  get ItemMaster() {
    return this.itemMaster;
  }

  get ItemContainer() {
    return this.itemContainer;
  }

  get SupplierSellItemContainer() {
    return this.supplierSellItemContainer;
  }

  get POItemContainer() {
    return this.poItemContainer;
  }

  get ItemContainerAmount() {
    return this.containerAmount;
  }

  get AlreadyAcceptAmount() {
    return this.alreadyAccept;
  }

  get ItemMasterAmount() {
    return this.containerAmount * this.itemContainer.ItemMasterSize;
  }

  get PurchaseOrderId() {
    return this.poItemContainer.PurchaseOrderId;
  }
}
