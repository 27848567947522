import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import {
  OpenConfirmNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import CreateItAssetForm from '../../forms/CreateItAssetForm';
import ProjectPaper from '../../customs/ProjectPaper';
import useAuth from '../../../auth/hooks/useAuth';
import { createAsset } from '../../../requests/AdminServerHandler';
import { useMutation } from '@tanstack/react-query';

function CreateItAssetPage() {
  const { translate } = useLocales();
  const { user } = useAuth();

  const createItAssetMutation = useMutation({
    mutationFn: createAsset,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage(translate('success.create_asset'));
    },
  });
  const onCreateItAsset = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_asset'),
      translate('button.confirm'),
      '',
      async () => {
        await createItAssetMutation.mutateAsync({
          categories_id: values.Category.Id,
          code: values.Code,
          serial_number: values.SerialNumber,
          brand: values.Brand,
          type: values.Type,
          spec: values.Spec,
          color: values.Color,
          user_employee_id: parseInt(values.AssignUserId),
          note: values.Note,
          staff_employee_id: parseInt(user.EmployeeCode),
          waranty_expires: values.WarrantyDate.toISOString(),
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('it.create_asset')}
      breadcrumbs={[{ name: translate('it.title') }, { name: translate('it.create_asset') }]}
    >
      <ProjectPaper>
        <CreateItAssetForm onSubmitValue={onCreateItAsset} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateItAssetPage;
