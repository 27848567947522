import React from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import ReactTable from '../../react-table/ReactTable';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import Iconify from 'components/minimal/iconify';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fCurrency, fNumber } from '../../../utilities/formatNumber';
import GridDetailText from '../../customs/GridDetailText';
import { fDateTimeText } from '../../../utilities/formatTime';
import ErrorPage from '../MainModule/ErrorPage';
import { useTheme } from '@mui/material/styles';
import ApproveCashBillForm from '../../forms/ApproveCashBillForm';
import { OpenConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
  ManagementUserTypeId,
  SalesUserTypeId,
} from '../../../utilities/Constants';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { useCashDeliveryBill } from '../../../requests/query/useCashDeliveryBill';
import { paths } from 'routes/paths';
import { OPEN_ICON } from '../../../config-global';
import Link from "@mui/material/Link";

function CashDeliveryBillDetailPage() {
  const { translate } = useLocales();
  const { id } = useParams();
  const theme = useTheme();
  const { cashDeliveryBill, approveCashDeliveryBill, printPDF } = useCashDeliveryBill(id);

  const getBillStatus = (cashDeliveryBillDetail) => {
    if (cashDeliveryBillDetail?.DeliveryBill.ArchivedAt !== null) {
      return (
        <GridDetailText
          title={translate('title.status')}
          value={translate('button.cancel')}
          color={theme.palette.error.main}
        />
      );
    }
    if (cashDeliveryBillDetail?.InvoicedAt !== null) {
      return (
        <GridDetailText
          title={translate('title.status')}
          value={translate('finance.already_collect_payment')}
          color={theme.palette.success.main}
        />
      );
    }
    return (
      <GridDetailText
        title={translate('title.status')}
        value={translate('finance.wait_for_collect_payment')}
        color={theme.palette.info.main}
      />
    );
  };

  const onApproveFormSubmit = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_payment'),
      translate('button.confirm'),
      '',
      async () => {
        await approveCashDeliveryBill.mutateAsync({
          CashDeliveryBillId: id,
          PaymentTypeId: values.PaymentType.Id,
          PaymentReference: values.PaymentReference,
        });
      }
    );
  };

  const onPrintBill = async () => {
    await printPDF.mutateAsync(cashDeliveryBill.DeliveryBill.Id);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('finance.cash_delivery_bill_list')}
      breadcrumbs={[
        { name: translate('finance.title') },
        {
          name: translate('finance.cash_delivery_bill_list'),
          href: paths.finance.cash_delivery_bill_list,
        },
        { name: `${translate('finance.cash_delivery_bill_detail')} : C-${id}` },
      ]}
      action={
        <RoleBasedGuard
          roles={[
            ManagementUserTypeId,
            AdminUserTypeId,
            AccountingUserTypeId,
            SalesUserTypeId,
            FinanceUserTypeId,
          ]}
        >
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={onPrintBill}
            disabled={cashDeliveryBill?.InvoicedAt === null}
          >
            {translate('button.print')}
          </Button>
        </RoleBasedGuard>
      }
    >
      {cashDeliveryBill !== null ? (
        <Stack spacing={3}>
          <Card>
            <CardHeader title={translate('title.detail')} />
            <CardContent>
              <Grid container spacing={2}>
                <GridDetailText
                  title={translate('accounting.delivery_bill_id')}
                  link={paths.production.pile_detail(cashDeliveryBill?.DeliveryBill.Id)}
                  value={cashDeliveryBill?.DeliveryBill.Id}
                />
                <GridDetailText
                  title={translate('title.delivery_by')}
                  value={getEmployeeFullName(cashDeliveryBill?.DeliveryBill.CreateEmployee)}
                />
                <GridDetailText
                  title={translate('title.customer')}
                  value={cashDeliveryBill?.DeliveryBill.Customer.Name}
                />
                <GridDetailText
                  title={translate('finance.payment_reference')}
                  value={
                    cashDeliveryBill?.PaymentReference !== null
                      ? cashDeliveryBill?.PaymentReference
                      : '-'
                  }
                />
                <GridDetailText
                  title={translate('title.name')}
                  link={paths.production.product_order_detail(
                    cashDeliveryBill?.DeliveryBill.Piles[0].ProductOrder.Id
                  )}
                  value={cashDeliveryBill?.DeliveryBill.Piles[0].ProductOrder.Name}
                />
                <GridDetailText
                  title={translate('title.amount')}
                  value={`${fNumber(
                    cashDeliveryBill?.DeliveryBill.Piles.reduce((accumulator, pile) => {
                      return accumulator + pile.GoodAmount;
                    }, 0)
                  )} ${translate('unit.sheet')}`}
                />
                <GridDetailText
                  title={translate('title.value')}
                  value={fCurrency(cashDeliveryBill?.DeliveryBill.TotalValue)}
                />
                <GridDetailText
                  title={translate('title.create_at')}
                  value={fDateTimeText(cashDeliveryBill?.DeliveryBill.createdAt)}
                />
                {getBillStatus(cashDeliveryBill)}
                {cashDeliveryBill?.DeliveryBill.ArchivedAt !== null && (
                  <>
                    <GridDetailText
                      title={translate('title.canceled_at')}
                      value={fDateTimeText(cashDeliveryBill?.DeliveryBill.ArchivedAt)}
                    />
                    <GridDetailText
                      title={translate('title.canceled_by')}
                      value={getEmployeeFullName(cashDeliveryBill?.DeliveryBill.CanceledEmployee)}
                    />
                  </>
                )}
                {cashDeliveryBill?.InvoicedAt !== null && (
                  <>
                    <>
                      <GridDetailText
                        title={translate('title.invoice_at')}
                        value={fDateTimeText(cashDeliveryBill?.InvoicedAt)}
                      />
                      <GridDetailText
                        title={translate('title.invoice_by')}
                        value={getEmployeeFullName(cashDeliveryBill?.InvoicedEmployee)}
                      />
                    </>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('title.pile')} />
            <CardContent>
              <ReactTable
                data={cashDeliveryBill?.DeliveryBill.Piles.map((pile) => {
                  return {
                    id: pile.Id,
                    amount: pile.GoodAmount,
                    action: pile.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                  },
                  {
                    header: translate('title.amount_good'),
                    accessorKey: 'amount',
                    cell: (info) => fNumber(info.getValue()),
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'action',
                    cell: (info) => (
                      <IconButton
                        component={Link}
                        href={paths.production.pile_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                  },
                ]}
              />
            </CardContent>
          </Card>
          {cashDeliveryBill?.InvoicedAt === null && (
            <Card>
              <CardHeader title={translate('finance.payment_detail')} />
              <CardContent>
                <ApproveCashBillForm onSubmitForm={onApproveFormSubmit} />
              </CardContent>
            </Card>
          )}
        </Stack>
      ) : (
        <ErrorPage />
      )}
    </ProjectPageContainer>
  );
}

export default CashDeliveryBillDetailPage;
