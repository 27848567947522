import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { createEmployee } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { createWarehouseEmployee } from '../../../requests/WarehouseServerHandler';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import { useForm } from 'react-hook-form';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { useAllEmployeeDepartment } from '../../../requests/query/DropdownData';
import { useMutation } from '@tanstack/react-query';

const CreateEmployeePage = () => {
  const { translate } = useLocales();
  const { allEmployeeDepartmentOptions } = useAllEmployeeDepartment();

  const createEmployeeMutation = useMutation({
    mutationFn: createEmployee,
    onSuccess: async () => {},
  });
  const createEmployeeWarehouseMutation = useMutation({
    mutationFn: createWarehouseEmployee,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const handleCreateEmployee = async (values) => {
    await createEmployeeMutation.mutateAsync({
      FirstName: values.FirstName,
      LastName: values.LastName,
      DepartmentId: values.Department.Id,
      EmployeeCode: values.EmployeeCode,
    });
    await createEmployeeWarehouseMutation.mutateAsync({
      FirstName: values.FirstName,
      LastName: values.LastName,
      DepartmentId: values.Department.Id,
      EmployeeCode: values.EmployeeCode,
    });
  };

  const createEmployeeSchema = Yup.object().shape({
    FirstName: Yup.string().required(translate('error.detail_require')),
    LastName: Yup.string().required(translate('error.detail_require')),
    Department: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
    EmployeeCode: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(createEmployeeSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('hr.add_employee')}
      breadcrumbs={[{ name: translate('hr.title') }, { name: translate('hr.add_employee') }]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleCreateEmployee)}>
          <Stack spacing={3}>
            <RHFTextField name={'FirstName'} label={translate('user.name')} />
            <RHFTextField name={'LastName'} label={translate('user.last_name')} />
            <RHFAutoCompleteShort
              name={'Department'}
              label={translate('user.department')}
              data={allEmployeeDepartmentOptions.filter((department) => department.Id !== 1)}
            />
            <RHFTextField name={'EmployeeCode'} label={translate('user.employee_code')} />
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default CreateEmployeePage;
