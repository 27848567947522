import React from 'react';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Search } from '@mui/icons-material';
import PropsTypes from 'prop-types';

SearchCarForm.propsType = {
  onSubmitCallBack: PropsTypes.func.isRequired,
};

function SearchCarForm({ onSubmitCallBack }) {
  const { translate } = useLocales();
  const onSearch = async (value) => {
    onSubmitCallBack(value.PileId);
    reset();
  };

  const searchPileSchema = Yup.object().shape({
    CarId: Yup.number()
      .min(1, translate('error'))
      .required(translate('error')),
  });

  const methods = useForm({
    resolver: yupResolver(searchPileSchema),
    defaultValues: {
      CarId: '',
    },
  });

  const { handleSubmit, reset } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSearch)}>
      <RHFTextField
        name={'CarId'}
        label={'Id รถ'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type={'submit'} edge="end">
                {<Search />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormProvider>
  );
}

export default SearchCarForm;
