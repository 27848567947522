import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';

SearchDeliveryBillForm.propTypes = {
  onSubmitValue: PropTypes.func.isRequired,
};

function SearchDeliveryBillForm({ onSubmitValue }) {
  const { translate } = useLocales();

  const searchDeliveryBillSchema = Yup.object().shape({
    DeliveryBillId: Yup.number().required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(searchDeliveryBillSchema),
    defaultValues: {
      DeliveryBillId: null,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onSubmitValue(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name={'DeliveryBillId'} label={translate('title.id')} />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('button.search')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default SearchDeliveryBillForm;
