import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { PrintPDFMakeDocument } from 'utilities/Helper/PrintHelper';
import { OpenSuccessNotification } from 'utilities/Helper/NotificationHelper';
import { delivery_bill_report_prefix } from 'utilities/Strings/Prefix';
import DatePickerCustomer from 'components/date-picker/DatePickerCustomer';
import { useLocales } from 'locales';
import {
  getDeliverySummaryPDFFile,
  printDeliverySummaryReportExcel,
} from 'requests/ProductionServerHandler';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';
export default function PrintDeliverySummaryReportPage() {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printDeliverySummaryReportPDFMutation = useMutation({
    mutationFn: getDeliverySummaryPDFFile,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (pdfStructure) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(pdfStructure, `${delivery_bill_report_prefix}.pdf`);
      await OpenSuccessNotification();
    },
  });
  const printDeliverySummaryReportExcelMutation = useMutation({
    mutationFn: printDeliverySummaryReportExcel,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'delivery_bill_sum_report.xlsx');
      await OpenSuccessNotification();
    },
  });
  const onPrint = async (fromDate, toDate, customer) => {
    await printDeliverySummaryReportPDFMutation.mutateAsync({
      customerId: customer.Id,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      isExcel: false,
    });
  };
  const onPrintExcel = async (fromDate, toDate, customer) => {
    await printDeliverySummaryReportExcelMutation.mutateAsync({
      customerId: customer.Id,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      isExcel: true,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.print_delivery_summary_report_page_title')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.print_delivery_summary_report_page_title') },
      ]}
    >
      <DatePickerCustomer
        includeAllCustomerOption
        actions={[
          {
            title: translate('accounting.print_delivery_summary_report_page_title'),
            callBack: onPrint,
          },
          {
            title: `${translate('accounting.print_delivery_summary_report_page_title')} (Excel)`,
            callBack: onPrintExcel,
          },
        ]}
      />
    </ProjectPageContainer>
  );
}
