import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { resetRequestCart } from 'redux/reducers/inventory';
import {
  OpenErrorNotification,
  OpenNotificationThenCallback,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import {
  AdminUserTypeId,
  ManagementUserTypeId,
  StoreUserTypeId,
} from '../../../utilities/Constants';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import GeneralTable from '../../customs/GeneralTable';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { getRealItemMasterAmount } from '../../../utilities/Helper/DataHelper';
import { useItemRequisition } from '../../../requests/query/useItemRequisition';
import { paths } from '../../../routes/paths';
import MaterialScanner from '../../barcode-reader/MaterialScanner';
import { useItemReceipt } from '../../../requests/query/useItemReceipt';
import Link from "@mui/material/Link";

export default function ItemRequisitionDetailPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { translate } = useLocales();
  const [addItems, setAddItems] = useState([]);
  const { itemRequisition, cancel, printReport } = useItemRequisition(id);
  const { createItemReceipt } = useItemReceipt(null);
  const handleCreateItemReceipt = async () => {
    let cutItems = [];
    let cutItemMasters = [];
    let cutItemContainers = [];

    addItems.forEach((item) => {
      cutItems.push({
        Id: item.Id,
        ItemMasterId: item.ItemMaster.Id,
        ItemContainer: item.ItemContainer.Id,
        Amount: item.ContainerSize,
      });

      const cutItemMaster = cutItemMasters.find(
        (itemMaster) => itemMaster.Id === item.ItemMaster.Id
      );
      if (cutItemMaster !== undefined) {
        cutItemMaster.Amount = cutItemMaster.Amount + item.ContainerSize;
      } else {
        cutItemMasters.push({
          Id: item.ItemMaster.Id,
          ItemContainerId: item.ItemContainer.Id,
          Amount: item.ContainerSize,
        });
      }

      const cutItemContainer = cutItemContainers.find(
        (itemContainer) => itemContainer.Id === item.ItemContainer.Id
      );
      if (cutItemContainer !== undefined) {
        cutItemContainer.Amount = cutItemContainer.Amount + 1;
      } else {
        cutItemContainers.push({
          Id: item.ItemContainer.Id,
          Amount: 1,
        });
      }
    });

    await createItemReceipt.mutateAsync({
      Items: cutItems,
      ItemMasters: cutItemMasters,
      ItemContainers: cutItemContainers,
      ItemRequisitionReportId: itemRequisition.Id,
    });

    await OpenNotificationThenCallback(
      translate('title.complete'),
      translate('notification.your_request_has_been_accept'),
      async () => {
        dispatch(resetRequestCart());
        navigate(paths.warehouse.release_items);
      }
    );
  };
  const handleSearchItem = async (item) => {
    if (item !== null) {
      if (item.RemainAmount > 0) {
        const duplicateItem = addItems.find((existItem) => existItem.Id === item.Id);
        if (duplicateItem === undefined) {
          setAddItems([
            ...addItems,
            {
              Id: item.Id,
              BarcodeId: item.BarcodeId,
              ItemContainer: item.ItemLot.ItemContainer,
              ItemMaster: item.ItemLot.ItemContainer.ItemMaster,
              ContainerSize: item.RemainAmount,
              Amount: 1,
            },
          ]);
        } else {
          await OpenErrorNotification(translate('error.error_barcode_already_add'));
        }
      } else {
        await OpenErrorNotification(translate('error.insufficient_amount'));
      }
    } else {
      await OpenErrorNotification(translate('error.item_not_found'));
    }
  };
  const handleCancel = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancel.mutateAsync(itemRequisition.Id);
        navigate(paths.warehouse.release_items);
      }
    );
  };
  const handlePrintBill = async () => {
    await printReport.mutateAsync(itemRequisition.Id);
  };
  const onRemoveItem = (removeIndex) => {
    setAddItems(addItems.filter((item, index) => index !== removeIndex));
  };
  const onPurchaseButtonPressed = () => {
    navigate(paths.procurement.part_purchase_store);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.request_item')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.request_item') },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant={'contained'}
            color={'error'}
            startIcon={<Iconify icon={'eva:close-fill'} />}
            onClick={handleCancel}
          >
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={handlePrintBill}
          >
            {translate('button.print_item_template_request_document')}
          </Button>
        </Stack>
      }
    >
      <Stack spacing={3}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText title={translate('title.id')} value={id} />
            <GridDetailText title={translate('title.detail')} value={itemRequisition?.Detail} />
          </Grid>
        </ProjectPaper>
        {itemRequisition !== null && (
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'warehouse.remain_amount_title' },
                { align: 'left', title: 'warehouse.request_amount_title' },
                { align: 'left', title: 'warehouse.pickup_amount_title' },
                { align: 'left', title: '' },
              ]}
              tableData={itemRequisition?.ItemMasters.map((itemMaster, index) => {
                return [
                  index + 1,
                  <Link href={paths.warehouse.item_master_detail(itemMaster.Id)}>
                    {itemMaster.Name}
                  </Link>,
                  getRealItemMasterAmount(itemMaster),
                  itemMaster.ItemRequisitionReportHasItemMaster.Amount,
                  addItems.reduce((total, item) => {
                    if (item.ItemMaster.Id === itemMaster.Id) {
                      return total + parseFloat(item.ContainerSize);
                    } else {
                      return total + 0;
                    }
                  }, 0),
                  itemRequisition.CanceledAt === null && itemMaster.Amount < itemMaster.ItemRequisitionReportHasItemMaster.Amount ? (
                    <IconButton color={'primary'} onClick={onPurchaseButtonPressed}>
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  ) : null,
                ];
              })}
            />
          </ProjectPaper>
        )}
        {addItems.length > 0 && (
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'warehouse.barcode_id' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'warehouse.master_amount' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: '' },
              ]}
              tableData={addItems.map((item, index) => {
                return [
                  item.Id,
                  item.BarcodeId,
                  item.ItemMaster.Name,
                  item.ContainerSize,
                  item.Amount,
                  <IconButton color={'error'} onClick={() => onRemoveItem(index)}>
                    <Iconify icon={'eva:close-fill'} width={20} height={20} />
                  </IconButton>,
                ];
              })}
            />
            <Button
              color={'primary'}
              variant={'contained'}
              fullWidth
              onClick={handleCreateItemReceipt}
            >
              {translate('button.request')}
            </Button>
          </ProjectPaper>
        )}
        <RoleBasedGuard roles={[ManagementUserTypeId, StoreUserTypeId, AdminUserTypeId]}>
          {
              itemRequisition !== null && itemRequisition.CanceledAt === null && <MaterialScanner onSearchCallBack={handleSearchItem} isPreFetchData />
          }
        </RoleBasedGuard>
      </Stack>
    </ProjectPageContainer>
  );
}
