import CreateDeliveryBillPage from '../../components/pages/LogisticModule/CreateDeliveryBillPage';
import CreateCarPage from '../../components/pages/LogisticModule/CreateCarPage';
import { paths } from '../paths';
import CarTimeStampPage from '../../components/pages/LogisticModule/CarTimeStampPage';

const logisticModuleRouteDetails = [
  {
    path: paths.logistic.create_delivery_bill,
    component: <CreateDeliveryBillPage />,
    isPrivate: false,
  },
  {
    path: paths.logistic.create_car,
    component: <CreateCarPage />,
    isPrivate: true,
  },
  {
    path: paths.logistic.car_time_stamp,
    component: <CarTimeStampPage />,
    isPrivate: false,
  },
];

export default logisticModuleRouteDetails;
