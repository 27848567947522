import React from "react";
import Container from "@mui/material/Container";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet-async';
import HeaderBreadcrumbs from "./HeaderBreadcrumbs";
import {useSettingsContext} from "../minimal/settings";


export const ProjectPageContainer = (props) => {

    const {menu_title, disable_container, meta, children, breadcrumbs, action} = props;
    const { themeStretch } = useSettingsContext();
    const render = <>
        {
            breadcrumbs !== undefined && breadcrumbs.length > 0 ?
                <HeaderBreadcrumbs
                    heading={menu_title}
                    links={breadcrumbs}
                    action={action}
                />
                :
                null
        }
        {children}
    </>

    return (
        <>
            <Helmet>
                <title>{`${menu_title} | SIL Service`}</title>
                {meta}
            </Helmet>
            {disable_container ? <div {...props}>{render}</div> : <Container maxWidth={themeStretch ? false : 'lg'} {...props} fixed>
                {render}
            </Container>}
        </>
    );
}

ProjectPageContainer.propTypes = {
    menu_title: PropTypes.string.isRequired,
    topBarComponents: PropTypes.object,
    additional_app_bar: PropTypes.func,
    disableContainer: PropTypes.bool,
    breadcrumbs: PropTypes.array,
    action: PropTypes.node
};
