import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  assignPlanToMachine,
  getAllPrePlanPO,
  getMachineRunPlanningId,
  getProductOrderDetailWithProcessById,
  removeProductOrderPlanning,
} from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Scrollbar from 'components/minimal/scrollbar';
import { fDateTimeTextShort } from '../../../utilities/formatTime';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import {
  selectIsRefreshPlanningCard,
  toggleForceRefreshUnPlanPO,
  toggleMachineList,
  toggleRefreshInMachinePlan,
  toggleRefreshPlanningCard,
} from '../../../redux/reducers/planning';
import ProductOrderDetailDialog from '../ProductOrderDetailDialog';
import Fuse from 'fuse.js';
import SearchField from '../../customs/SearchField';
import GeneralSelect from '../../customs/GeneralSelect';
import { fNumber } from '../../../utilities/formatNumber';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import SelectMachineDialog from '../SelectMachineDialog';
import { sortById } from '../../../utilities/Helper/DataHelper';

PrePlanProductOrderList.propTypes = {
  onSetAmountPrePlan: PropTypes.func.isRequired,
  isVertical: PropTypes.bool,
};

function PrePlanProductOrderList({ onSetAmountPrePlan, isVertical = false }) {
  const options = {
    keys: ['ProductOrder.Customer.Name', 'ProductOrder.Name', 'ProductOrder.Id'],
  };
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const isRefreshPlanningCard = useSelector(selectIsRefreshPlanningCard);
  const [allPrePlanData, setAllPrePlanData] = useState([]);
  const [filterAllPrePlanData, setFilterAllPrePlanData] = useState([]);

  const [showProductOrderDetail, setShowProductOrderDetail] = useState(false);
  const [productOrderDetail, setProductOrderDetail] = useState(null);

  const [showSelectMachineDetail, setShowSelectMachineDetail] = useState(false);
  const [machineListDetail, setMachineListDetail] = useState({
    PlanningId: 0,
    MachineList: [],
  });

  const [sortValue, setSortValue] = useState(0);
  const fuse = new Fuse(allPrePlanData, options);

  const sortTypes = [
    { value: 0, name: translate('title.new') },
    { value: 1, name: translate('title.due_at') },
    { value: 2, name: translate('title.amount') },
  ];

  useEffect(() => {
    if (isRefreshPlanningCard) {
      const getAllPrePlanPOData = async () => await dispatch(getAllPrePlanPO());

      getAllPrePlanPOData().then((response) => {
        const sortResponse = response.sort(sortById);
        setAllPrePlanData(sortResponse);
        setFilterAllPrePlanData(sortResponse);
        onSetAmountPrePlan(response.length);
        dispatch(toggleRefreshPlanningCard(false));
      });
    }
  }, [dispatch, onSetAmountPrePlan, isRefreshPlanningCard]);

  const handleOpenPODetailDialog = async (POId) => {
    const response = await dispatch(getProductOrderDetailWithProcessById(POId));
    if (response !== null) {
      setProductOrderDetail(response.Detail);
      setShowProductOrderDetail(true);
    }
  };
  const handleClosePODetailDialog = async () => {
    setShowProductOrderDetail(false);
    setProductOrderDetail(null);
  };
  const defaultSetting = () => {
    setShowProductOrderDetail(false);
    setProductOrderDetail(null);
    setShowSelectMachineDetail(false);
    setMachineListDetail({
      PlanningId: 0,
      MachineList: [],
    });
  };
  const refreshPlanning = async () => {
    const response = await dispatch(getAllPrePlanPO());
    if (response !== null) {
      const sortResponse = response.sort(sortById);
      setAllPrePlanData(sortResponse);
      setFilterAllPrePlanData(sortResponse);
      onSetAmountPrePlan(response.length);
      defaultSetting();
    }
  };
  const handleRemoveFromPlan = async (removeId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_delete_planning',
      async () => {
        const response = await dispatch(removeProductOrderPlanning(removeId));
        if (response !== null) {
          await OpenSuccessNotification();
          await refreshPlanning();
          dispatch(toggleForceRefreshUnPlanPO(true));
        }
      }
    );
  };
  const handleSearch = (searchText) => {
    if (searchText !== null && searchText !== '') {
      setFilterAllPrePlanData(fuse.search(searchText).map((result) => result.item));
    } else {
      setFilterAllPrePlanData(allPrePlanData);
    }
    handleSort(0);
  };
  const handleSort = (value) => {
    if (value === 0) {
      const sorted = [...filterAllPrePlanData].sort(sortById);

      setFilterAllPrePlanData(sorted);
    }
    if (value === 1) {
      const sorted = [...filterAllPrePlanData].sort((a, b) => {
        return new Date(a.ProductOrder.DueDatedAt) - new Date(b.ProductOrder.DueDatedAt);
      });

      setFilterAllPrePlanData(sorted);
    }
    if (value === 2) {
      const sorted = [...filterAllPrePlanData].sort((a, b) => {
        return b.ProductOrder.Amount - a.ProductOrder.Amount;
      });

      setFilterAllPrePlanData(sorted);
    }
    setSortValue(value);
  };
  const handleAddToMachine = async (planningId) => {
    const response = await dispatch(getMachineRunPlanningId(planningId));
    if (response !== null) {
      setMachineListDetail({
        PlanningId: planningId,
        MachineList: response,
      });
      setShowSelectMachineDetail(true);
    }
  };
  const handleCloseMachineDialog = async () => {
    setShowSelectMachineDetail(false);
  };
  const handleSelectMachine = async (machineId) => {
    setShowSelectMachineDetail(false);
    const response = await dispatch(assignPlanToMachine(machineListDetail.PlanningId, machineId));
    if (response !== null) {
      await OpenSuccessNotification();
      await refreshPlanning();
      await dispatch(toggleRefreshInMachinePlan(true));
      await dispatch(toggleMachineList(true));
    }
  };

  return (
    <>
      <ProductOrderDetailDialog
        isOpen={showProductOrderDetail}
        handleDialogClose={handleClosePODetailDialog}
        productOrderDetail={productOrderDetail}
      />
      <SelectMachineDialog
        isOpen={showSelectMachineDetail}
        handleDialogClose={handleCloseMachineDialog}
        handleSelectMachine={handleSelectMachine}
        machineList={machineListDetail.MachineList}
      />
      {isVertical ? (
        <>
          <Paper
            variant="outlined"
            sx={{ px: 2, bgcolor: 'grey.5008', overflow: 'auto', height: 700 }}
          >
            <Box
              sx={{
                height: 80,
                m: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography fontSize={50} sx={{ fontWeight: 'bold' }}>
                {allPrePlanData.length}
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Typography fontSize={28} sx={{ fontWeight: 'bold' }}>
                  {translate('planning.pre_plan')}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Stack spacing={1} sx={{ pt: 1, pb: 1 }}>
              <Box>
                <GeneralSelect
                  value={sortValue}
                  selectOptions={sortTypes}
                  onOptionChange={handleSort}
                />
              </Box>
              <Scrollbar sx={{ p: 1.5, pt: 0, maxHeight: 500 }}>
                <Stack spacing={2}>
                  {filterAllPrePlanData.map((planningData) => {
                    return (
                      <Box
                        key={planningData.Id}
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Card sx={{ width: 500 }}>
                          <CardHeader
                            title={planningData.ProductOrder.Customer.Name}
                            titleTypographyProps={{
                              onClick: () => handleOpenPODetailDialog(planningData.ProductOrder.Id),
                              textOverflow: 'ellipsis',
                              noWrap: true,
                              maxWidth: 250,
                            }}
                            subheader={
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography variant={'body2'}>{`${translate('title.id')}: ${
                                  planningData.ProductOrder.Id
                                }`}</Typography>
                                <Typography variant={'body2'}>
                                  {fNumber(planningData.ProductOrder.Amount)}
                                </Typography>
                              </Box>
                            }
                            action={
                              <>
                                <IconButton
                                  aria-label="settings"
                                  onClick={() => handleAddToMachine(planningData.Id)}
                                >
                                  <AddIcon />
                                </IconButton>
                              </>
                            }
                          />
                          <Divider sx={{ mt: 2 }} />
                          <List dense={true}>
                            <ListItem>
                              <ListItemText
                                primary={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      mt: 1,
                                      ml: 3,
                                      mr: 3,
                                      mb: 1,
                                    }}
                                  >
                                    <Typography variant={'body2'}>
                                      {planningData.ProductionProcess.Name}
                                    </Typography>
                                    <Typography variant={'body2'}>
                                      {fNumber(planningData.ProductOrder.Amount)}
                                    </Typography>
                                  </Box>
                                }
                              />
                            </ListItem>
                          </List>
                        </Card>
                      </Box>
                    );
                  })}
                </Stack>
              </Scrollbar>
            </Stack>
          </Paper>
        </>
      ) : (
        <>
          <Grid container sx={{ mt: 2 }} spacing={1}>
            <Grid item xs={12} lg={9}>
              <SearchField
                onSearchCallBack={handleSearch}
                placeHolderText={translate('placeholder.search_product_order')}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <GeneralSelect
                value={sortValue}
                selectOptions={sortTypes}
                onOptionChange={handleSort}
              />
            </Grid>
          </Grid>
          <Scrollbar sx={{ mt: 2, maxHeight: 500 }}>
            <Grid container spacing={2}>
              {filterAllPrePlanData.map((planningData) => {
                return (
                  <Grid item xs={12} lg={4}>
                    <Card sx={{ height: 400 }}>
                      <CardHeader
                        title={`${translate('title.id')} : ${planningData.ProductOrder.Id}`}
                        titleTypographyProps={{
                          color: '#1b68a8',
                          onClick: () => handleOpenPODetailDialog(planningData.ProductOrder.Id),
                        }}
                        action={
                          <IconButton
                            aria-label="settings"
                            onClick={() => handleRemoveFromPlan(planningData.Id)}
                          >
                            <CloseIcon />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                          }}
                        >
                          <Typography variant={'h6'} textAlign="left">
                            {`${planningData.ProductOrder.Piles.reduce((totalComplete, pile) => {
                              return totalComplete + pile.Amount;
                            }, 0)}/${planningData.ProductOrder.Amount}`}
                          </Typography>
                          <Typography sx={{ ml: 1, mt: 0.4 }} variant={'body2'} textAlign="left">
                            {translate('unit.sheet')}
                          </Typography>
                        </Box>
                        <Typography
                          variant={'body2'}
                          textAlign="left"
                          noWrap={true}
                          sx={{
                            textOverflow: 'ellipsis',
                            maxWidth: 200,
                          }}
                        >
                          {planningData.ProductOrder.Customer.Name}
                        </Typography>
                        <Typography
                          variant={'body2'}
                          textAlign="left"
                          noWrap={true}
                          color={'#1b68a8'}
                          sx={{
                            textOverflow: 'ellipsis',
                            maxWidth: 200,
                          }}
                        >
                          {planningData.ProductOrder.Name}
                        </Typography>
                        <Divider sx={{ mt: 1 }} />
                        <Stack sx={{ mt: 1, mb: 1 }} spacing={1}>
                          <Typography variant={'body2'} textAlign="left">{`${translate(
                            'title.due_at'
                          )} : ${fDateTimeTextShort(
                            planningData.ProductOrder.DueDatedAt
                          )}`}</Typography>
                          <Typography variant={'body2'} textAlign="left">{`${translate(
                            'title.plan_date'
                          )} : ${fDateTimeTextShort(planningData.PlanDateAt)}`}</Typography>
                          <Typography variant={'body2'} textAlign="left">{`${translate(
                            'title.size'
                          )} : ${planningData.ProductOrder.Width} x ${
                            planningData.ProductOrder.Length
                          }`}</Typography>
                          <Typography variant={'body2'} textAlign="left">{`${translate(
                            'title.status'
                          )} : ${planningData.ProductOrderPlanningStatus.Name}`}</Typography>
                        </Stack>
                        <Divider />
                        <Typography sx={{ mt: 1 }} variant={'h6'} textAlign="left">
                          {planningData.ProductionProcess.Name}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Scrollbar>
        </>
      )}
    </>
  );
}

export default PrePlanProductOrderList;
