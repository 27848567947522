import { useMutation } from '@tanstack/react-query';
import {
  getDownloadApprovePileExcelFileURL,
  getDownloadCreatePileExcelFileURL,
  getDownloadCreateProductOrderExcelFileURL,
} from '../ProductionServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';
import {
  getDownloadItemMasterRequisitionExcelFileURL,
  getDownloadItemRequisitionExcelFileURL,
} from '../WarehouseServerHandler';
import { saveAs } from 'file-saver';
import { useLoading } from '../../components/loading';

export const usePrintPlanningReport = () => {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printDeliverySummaryReportExcelMutation = useMutation({
    mutationFn: getDownloadCreateProductOrderExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'เอกสาร.xlsx');
      await OpenSuccessNotification();
    },
  });
  const printCreatePileExcelMutation = useMutation({
    mutationFn: getDownloadCreatePileExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'เอกสาร.xlsx');
      await OpenSuccessNotification();
    },
  });
  const printApprovePileExcelMutation = useMutation({
    mutationFn: getDownloadApprovePileExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'เอกสาร.xlsx');
      await OpenSuccessNotification();
    },
  });
  const printPrintItemRequisitionExcelMutation = useMutation({
    mutationFn: getDownloadItemRequisitionExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'เอกสาร.xlsx');
      await OpenSuccessNotification();
    },
  });
  const printPrintItemMasterRequisitionExcelMutation = useMutation({
    mutationFn: getDownloadItemMasterRequisitionExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'เอกสาร.xlsx');
      await OpenSuccessNotification();
    },
  });

  return {
    printDeliverySummaryReportExcel: printDeliverySummaryReportExcelMutation,
    printCreatePileExcel: printCreatePileExcelMutation,
    printApprovePileExcel: printApprovePileExcelMutation,
    printPrintItemRequisitionExcel: printPrintItemRequisitionExcelMutation,
    printPrintItemMasterRequisitionExcel: printPrintItemMasterRequisitionExcelMutation,
  };
};
