import React from "react";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import PropTypes from "prop-types";
import {store, persistor} from './redux/store'


export default function Root({children}) {
    return <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>;
}

Root.propTypes = {
    children: PropTypes.object,
    initialState: PropTypes.object
};
