import ApproveQualityPage from '../../components/pages/QualityAssuranceModule/ApproveQualityPage';
import HoldPilePage from '../../components/pages/QualityAssuranceModule/HoldPilePage';
import UnHoldPilePage from '../../components/pages/QualityAssuranceModule/UnHoldPilePage';
import AllActiveQualityProblemPage from '../../components/pages/QualityAssuranceModule/AllActiveQualityProblemPage';
import QualityAssuranceDashboardPage from '../../components/pages/QualityAssuranceModule/QualityAssuraceDashboardPage';
import QualityCheckDashboardPage from '../../components/pages/QualityAssuranceModule/QualityCheckDashboardPage';
import QualityAssuranceAdjustPileAmountPage from '../../components/pages/QualityAssuranceModule/QualityAssuranceAdjustPileAmountPage';
import {
  AdminUserTypeId,
  ProductionControlUserTypeId,
  QualityAssuranceUserTypeId,
} from '../../utilities/Constants';
import { paths } from '../paths';
import QualityAssuranceReportProductOrderPage from '../../components/pages/QualityAssuranceModule/QualityAssuranceReportProductOrderPage';
import ApproveQualityProcessPage from '../../components/pages/QualityAssuranceModule/ApproveQualityProcessPage';

const planningModuleRouteDetails = [
  {
    path: paths.qa.approve_quality,
    component: <ApproveQualityPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.hold_pile,
    component: <HoldPilePage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.un_hold_pile,
    component: <UnHoldPilePage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.all_active_problem,
    component: <AllActiveQualityProblemPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.dashboard,
    component: <QualityAssuranceDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.quality_dashboard,
    component: <QualityCheckDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.adjust_pile_amount,
    component: <QualityAssuranceAdjustPileAmountPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.report_product_order_problem,
    component: <QualityAssuranceReportProductOrderPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
  {
    path: paths.qa.approve_quality_process,
    component: <ApproveQualityProcessPage />,
    isPrivate: true,
    allowUserTypeId: [ProductionControlUserTypeId, AdminUserTypeId, QualityAssuranceUserTypeId],
  },
];

export default planningModuleRouteDetails;
