import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useLocales } from 'locales';
import { useNavigate } from 'react-router-dom';
import { createCustomerPricing } from 'requests/ProductionServerHandler';
import CreateCustomerPricingForm from 'components/forms/CreateCustomerPricingForm';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from 'utilities/Helper/NotificationHelper';
import useQueryParameter from 'hooks/useQueryParameter';
import { paths } from 'routes/paths';
import { useMutation } from '@tanstack/react-query';

function CreateCustomerPricingPage() {
  const { translate } = useLocales();
  const query = useQueryParameter();
  const customerId = query.get('customerId');
  const navigate = useNavigate();

  const createCustomerPricingMutation = useMutation({
    mutationFn: createCustomerPricing,
    onSuccess: async (pricingId) => {
      await OpenSuccessNotification();
      navigate(paths.sales.customer_pricing_detail(pricingId));
    },
  });

  const onCompleteSelectProduct = async (value) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_create_pricing',
      async () => {
        await createCustomerPricingMutation.mutateAsync({ ...value, CustomerId: customerId });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.customer_detail')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('sales.customer_list'), href: paths.sales.customer_list },
        {
          name: `${translate('sales.customer_detail')} : ${customerId}`,
          href: paths.sales.customer_detail(customerId),
        },
        { name: `${translate('sales.create_customer_pricing')} : ${customerId}` },
      ]}
    >
      <CreateCustomerPricingForm onComplete={onCompleteSelectProduct} />
    </ProjectPageContainer>
  );
}

export default CreateCustomerPricingPage;
