export const getItemTemplateQRCodeDocument = (itemTemplateId, name) => {
    return {
        info: {
            title: `QRCode ${itemTemplateId}`,
            author: 'Saenginter Laminate Co.,LTD',
        },
        content: [
            {
                text: `ID:${itemTemplateId}\n`,
                style: 'header'
            },
            {
                text: `${name}\n\n`,
                style: 'header'
            },
            {qr: `{"ItemTemplateId":${itemTemplateId}}`, fit: '350', style: 'qr_code'},
        ],
        styles: {
            header: {
                fontSize: 34,
                bold: true
            },
            qr_code: {
                alignment: 'center',
            },
        },
        defaultStyle: {
            font: 'THSarabunNew'
        }
    }
}
export const getItemMasterQRCodeDocument = (itemMasterId, name) => {
    return {
        info: {
            title: `QRCode ${itemMasterId}`,
            author: 'Saenginter Laminate Co.,LTD',
        },
        content: [
            {
                text: `ID:${itemMasterId}\n`,
                style: 'header'
            },
            {
                text: `${name}\n\n`,
                style: 'header'
            },
            {qr: `{"ItemMasterId":${itemMasterId}}`, fit: '350', style: 'qr_code'},
        ],
        styles: {
            header: {
                fontSize: 34,
                bold: true
            },
            qr_code: {
                alignment: 'center',

            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        }
    }
}
export const getItemTemplateQRCodeStickerDocument = (itemTemplateId, name, amount) => {

    let allContents = [];

    for (let i = 0; i < amount; i++) {
        allContents.push({
            style: 'tableExample',
            table: {
                widths: ['*'],
                body:[
                    [{
                        style: 'tableInner',
                        table: {
                            headerRows: 1,
                            body: [
                                [{text: [
                                        {text: 'ชื่อ : ', fontSize: 13, bold: true},
                                        {text: `${name}`, fontSize: 13,},
                                    ]}],
                            ]
                        },
                        layout: 'noBorders'
                    }],
                    [{qr: `{"ItemId":${itemTemplateId}`, margin: [0, 0, 20, 20],fit:140}]
                ]
            },
            layout: 'noBorders',
            pageBreak: 'after'
        });
    }
    return {
        info: {
            title: `Pickup_Report_${1}`,
            author: 'Saenginter Laminate Co.,LTD',
        },

        content: allContents,
        styles: {
            header: {
                fontSize: 48,
                bold: true
            },
            detail: {
                fontSize: 24,
            },
            detailBold: {
                fontSize: 24,
                bold: true
            },
            qr: {
                alignment: 'right'
            },
            quote: {
                italics: true
            },
            small: {
                fontSize: 8
            },
            tableExample: {
                margin: [0, 20, 20, 30]
            },
            tableInner: {
                margin: [0, 0,0,0]
            },
        },
        pageSize: 'A6',
        pageMargins: [ 5, 5, 0, 0 ],
        defaultStyle: {
            font: 'THSarabunNew'
        }
    }
}



