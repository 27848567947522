import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  approvedCustomerPricing,
  getCustomerPricingDetail,
  getCustomerPricingPDF,
  removeCustomerPricing,
  removeProductFromCustomerPricing,
} from '../ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';

export const useCustomerPricingData = (id) => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['customer-pricing-detail', id],
    queryFn: () => getCustomerPricingDetail(id),
    initialData: null,
  });

  const removeProductFromPricingMutation = useMutation({
    mutationFn: removeProductFromCustomerPricing,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['customer-pricing-detail', id]);
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const cancelCustomerPricingMutation = useMutation({
    mutationFn: removeCustomerPricing,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['customer-pricing-detail', id]);
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const approveCustomerPricingMutation = useMutation({
    mutationFn: approvedCustomerPricing,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['customer-pricing-detail', id]);
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const printProductPricingMutation = useMutation({
    mutationFn: getCustomerPricingPDF,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(['customer-pricing-detail', id]);
      await PrintPDFMakeDocument(data, `ใบเสนอราคา_${id}`);
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  return {
    customerPricingDetail: data,
    removeProductFromPricing: removeProductFromPricingMutation,
    cancelCustomerPricing: cancelCustomerPricingMutation,
    approveCustomerPricing: approveCustomerPricingMutation,
    printProductPricingPDF: printProductPricingMutation,
  };
};
