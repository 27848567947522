import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { useTheme } from '@mui/material/styles';
import ReactTable from '../react-table/ReactTable';
import { getItJobStatusCard } from '../../utilities/Helper/UserInterfaceHelper';
import { IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from './ProjectPaper';
import { getAllItJob } from '../../requests/AdminServerHandler';
import { OPEN_ICON } from '../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../routes/paths';

ItJobListTable.propTypes = {
  job_status_id: PropTypes.number.isRequired,
};

function ItJobListTable({ job_status_id }) {
  const { translate } = useLocales();
  const theme = useTheme();

  const { data: itJobData } = useQuery({
    queryKey: ['allItJob', job_status_id],
    queryFn: () => getAllItJob(job_status_id),
    initialData: {
      data: [],
    },
  });

  return (
    <ProjectPaper>
      <ReactTable
        search
        defaultPageSize={25}
        data={itJobData.data.map((job) => {
          return {
            id: job.id,
            detail: job.user_detail,
            asset: job.asset.code,
            status: job.status,
            action: job.id,
          };
        })}
        header={[
          {
            header: translate('title.id'),
            accessorKey: 'id',
          },
          {
            header: translate('title.detail'),
            accessorKey: 'detail',
          },
          {
            header: translate('it.asset'),
            accessorKey: 'asset',
          },
          {
            header: translate('title.status'),
            accessorKey: 'status',
            cell: (info) => getItJobStatusCard(theme.palette.mode, translate, info.getValue()),
          },
          {
            header: '',
            accessorKey: 'action',
            cell: (info) => (
              <IconButton component={RouterLink} to={paths.it.it_job_detail(info.getValue())}>
                <Iconify icon={OPEN_ICON} />
              </IconButton>
            ),
          },
        ]}
      />
    </ProjectPaper>
  );
}

export default ItJobListTable;
