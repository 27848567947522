import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardContent, Divider, Grid, Button} from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import {fCurrency} from 'utilities/formatNumber';
import {useLocales} from 'locales';
import {fDateTimeText} from 'utilities/formatTime';
import GeneralTable from 'components/customs/GeneralTable';
import {calculateProductPerSheet} from "../../utilities/Helper/DataHelper";

RecheckProductOrderQuotationDetail.propTypes = {
    products: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    productDetail: PropTypes.object.isRequired,
    onBackPress: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

function RecheckProductOrderQuotationDetail({
                                                products,
                                                customer,
                                                productDetail,
                                                onBackPress,
                                                onConfirm,
                                            }) {
    const {translate} = useLocales();
    const calculateProductOrderValue = (amount, products) =>
        products.reduce((totalValue, product) => {
            if (product.Price.IsBundle) {
                totalValue += product.Price.BundlePrice;
            } else {
                totalValue += amount * product.Price.Price;
            }
            return totalValue;
        }, 0);

    const calculateProductOrderRealValue = (amount, products) =>
        products.reduce((totalValue, product) => {
            if (product.Price.IsBundle) {
                totalValue += product.Price.BundlePrice;
            } else {
                totalValue += amount * calculateProductPerSheet(productDetail.Width, productDetail.Length, product.Detail.PricePerSquareInch);
            }
            return totalValue;
        }, 0);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={3}>
                    <GridDetailText title={translate('title.name')} value={productDetail.Name}/>
                    <GridDetailText title={translate('title.detail')} value={productDetail.Detail}/>
                    <GridDetailText title={translate('title.width')} value={productDetail.Width}/>
                    <GridDetailText title={translate('title.length')} value={productDetail.Length}/>
                    <GridDetailText title={translate('title.amount')} value={productDetail.Amount}/>
                    <GridDetailText
                        title={translate('title.expire_at')}
                        value={fDateTimeText(productDetail.ValidTill)}
                    />
                    <GridDetailText title={translate('title.customer')} value={customer.Name}/>
                    <GridDetailText
                        title={translate('title.value')}
                        value={fCurrency(calculateProductOrderValue(productDetail.Amount, products))}
                    />
                    <GridDetailText
                        title={translate('title.discount')}
                        value={fCurrency(calculateProductOrderRealValue(productDetail.Amount, products) - calculateProductOrderValue(productDetail.Amount, products) > 0 ? calculateProductOrderRealValue(productDetail.Amount, products) - calculateProductOrderValue(productDetail.Amount, products) : 0) }
                    />
                </Grid>
                <Divider sx={{mt: 3, mb: 3}}/>
                <GeneralTable
                    tableHeaders={[
                        {align: 'left', title: translate('title.name')},
                        {align: 'left', title: translate('title.price')},
                        {align: 'left', title: 'รวมราคา'},
                    ]}
                    tableData={products.map((product) => {
                        return [
                            product.Detail.Name,
                            product.Price.IsBundle
                                ? `${translate('title.bundle_price')} ${product.Price.BundlePrice}`
                                : product.Price.Price,
                            product.Price.IsBundle
                                ? fCurrency(product.Price.BundlePrice)
                                : fCurrency(product.Price.Price * productDetail.Amount),
                        ];
                    })}
                />
                <Divider sx={{mt: 3}}/>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 3,
                    }}
                >
                    <Button color={'primary'} onClick={onBackPress}>
                        {translate('button.back')}
                    </Button>
                    <Button color={'primary'} variant={'contained'} onClick={onConfirm}>
                        {translate('button.create')}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
}

export default RecheckProductOrderQuotationDetail;
