import { useMutation } from '@tanstack/react-query';
import { OpenNotificationThenReloadPage } from '../../utilities/Helper/NotificationHelper';
import { changePileGoodAmount } from '../ProductionServerHandler';

export const useEditPileData = () => {
  const editPileMutation = useMutation({
    mutationFn: changePileGoodAmount,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  return {
    editPile: editPileMutation,
  };
};
