import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { Button, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Link from "@mui/material/Link";
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import EditSupplierForm from '../../forms/EditSupplierForm';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import IconButton from '@mui/material/IconButton';
import SupplierDetail from '../../details/SupplierDetail';
import { OPEN_ICON } from '../../../config-global';
import { usePartSupplierDetail } from '../../../requests/query/useSupplierDetail';
import { paths } from '../../../routes/paths';

const PartSupplierDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { translate } = useLocales();
  const { supplierDetail, refetch } = usePartSupplierDetail(id);
  const [isEditMode, setIsEditMode] = useState(false);

  const onEditPressed = () => {
    setIsEditMode(true);
  };

  const onSaveEdit = async () => {
    await refetch();
    setIsEditMode(false);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.supplier_detail')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.supplier_parts_list_title'),
          href: paths.procurement.supplier_parts_list,
        },
        { name: `${translate('procurement.supplier_detail')}${translate('title.id')} : ${id}` },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant={'contained'}
            color={'info'}
            startIcon={<Iconify icon={'bx:edit'} />}
            onClick={onEditPressed}
          >
            {translate('button.edit_data')}
          </Button>
          <Button
            variant="contained"
            color={'primary'}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            component={Link}
            href={paths.procurement.search_item_template_to_create_sell_template(id)}
          >
            {translate('procurement.add_sell_item_title')}
          </Button>
        </Stack>
      }
    >
      {!isEditMode ? (
        supplierDetail !== null && (
          <Stack spacing={3}>
            <SupplierDetail supplier={supplierDetail} />
            <ProjectPaper>
              <ReactTable
                search
                data={supplierDetail.Detail.SupplierSellItemTemplates.map((sellItem) => {
                  return {
                    name: sellItem.Name,
                    item_master_name: sellItem.ItemTemplate.Name,
                    sell_unit: sellItem.SellItemTemplateUnit.Name,
                    actions: sellItem.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.name'),
                    accessorKey: 'name',
                  },
                  {
                    header: translate('warehouse.item_master_name'),
                    accessorKey: 'item_master_name',
                  },
                  {
                    header: translate('procurement.sell_unit'),
                    accessorKey: 'sell_unit',
                  },
                  {
                    header: '',
                    accessorKey: 'actions',
                    cell: (info) => (
                      <>
                        <IconButton
                          onClick={() =>
                            navigate(
                              paths.procurement.edit_supplier_sell_item_template(info.getValue())
                            )
                          }
                        >
                          <Iconify icon={OPEN_ICON} />
                        </IconButton>
                      </>
                    ),
                  },
                ]}
              />
            </ProjectPaper>
          </Stack>
        )
      ) : (
        <EditSupplierForm
          supplier={supplierDetail.Detail}
          onSaveEdit={onSaveEdit}
          supplierType={2}
        />
      )}
    </ProjectPageContainer>
  );
};

export default PartSupplierDetailPage;
