import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import {
  OpenNotificationThenCallback,
  OpenTranslateInputNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import {
  acceptPOItemTemplateToStore,
  getAllIncomingItemTemplate,
} from '../../../requests/ProductionServerHandler';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import { fDateTimeText } from '../../../utilities/formatTime';
import useAuth from '../../../auth/hooks/useAuth';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

function AddItemTemplateToStorePage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { data: allItemTemplate } = useQuery({
    queryKey: ['getAllIncomingItemTemplate'],
    queryFn: getAllIncomingItemTemplate,
    initialData: [],
  });
  const acceptItemMutation = useMutation({
    mutationFn: acceptPOItemTemplateToStore,
    onSuccess: async (data) => {
      await OpenNotificationThenCallback(
        translate('title.complete'),
        `${translate('warehouse.success_create_item_lot_id_title')}:${data.ItemLotId}`,
        async () => {
          if (data.Id !== null) {
            navigate(paths.general_warehouse.item_template_detail(data.Id));
          } else {
            await queryClient.invalidateQueries(['getAllIncomingItemTemplate']);
          }
        }
      );
    },
  });

  const onSelectItemTemplateToAdd = async (POItemTemplate, isAdmin) => {
    const inputLabel = `${POItemTemplate.SupplierSellItemTemplate.ItemTemplate.Name} (${POItemTemplate.SupplierSellItemTemplate.ItemTemplate.ItemTemplateUnit.Name})`;
    await OpenTranslateInputNotification(
      translate,
      translate('warehouse.accept_inventory'),
      inputLabel,
      'text',
      async (value) => {
        if (!value) {
          return translate('error.error_invalid_amount');
        } else {
          const amount = parseFloat(value);
          if (isNaN(amount)) {
            return translate('error.error_invalid_amount');
          } else {
            if (!isAdmin && amount <= 0) {
              return translate('error.error_invalid_amount');
            }
          }
        }
      },
      async (value) => await createIncomingStock(value, POItemTemplate)
    );
  };

  const createIncomingStock = async (addAmount, poItemTemplate) => {
    const amount = parseFloat(addAmount);
    let isComplete = false;
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_accept_all_item',
      () => {
        isComplete = true;
      }
    );

    await acceptItemMutation.mutateAsync({
      PurchaseOrderItemTemplateId: poItemTemplate.Id,
      Amount: amount,
      IsAcceptComplete: isComplete,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.accept_inventory')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.accept_inventory') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={allItemTemplate.map((POItemTemplate) => {
            return {
              id: POItemTemplate.PurchaseOrderId,
              name: POItemTemplate.SupplierSellItemTemplate.ItemTemplate.Name,
              supplier: POItemTemplate.SupplierSellItemTemplate.Supplier.Name,
              amount: `${POItemTemplate.Amount - POItemTemplate.AcceptAmount} ${
                POItemTemplate.SupplierSellItemTemplate.SellItemTemplateUnit.Name
              }`,
              coming_at: POItemTemplate.PurchaseOrder.DeliveredAt,
              actions: POItemTemplate,
            };
          })}
          header={[
            {
              header: translate('procurement.po_id'),
              accessorKey: 'id',
              cell: (info) => (
                <Link
                  href={paths.procurement.parts_po_detail(info.getValue())}
                >{`P${info.getValue()}`}</Link>
              ),
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('procurement.supplier'),
              accessorKey: 'supplier',
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
            },
            {
              header: translate('title.due_at'),
              accessorKey: 'coming_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    onClick={() =>
                      onSelectItemTemplateToAdd(
                        info.getValue(),
                        user.UserTypeId === ManagementUserTypeId
                      )
                    }
                  >
                    <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default AddItemTemplateToStorePage;
