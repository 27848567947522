export const getSalesDashboardData = (rawData) => {

    const currentDate = new Date();
    const salesValueChartData = rawData.SalesValue.map((yearData) => {
        return {
            name: yearData.Year,
            data: [
                yearData.January,
                yearData.February,
                yearData.March,
                yearData.April,
                yearData.May,
                yearData.June,
                yearData.July,
                yearData.August,
                yearData.September,
                yearData.October,
                yearData.November,
                yearData.December
            ]
        }
    });

    salesValueChartData[1].data[currentDate.getMonth()] = rawData.MonthlySalesValue
    const totalSalesValuePreviousYear = salesValueChartData[0].data.reduce((totalValue, value) => totalValue + value, 0);
    const totalSalesValuePreviousYearToCurrent = salesValueChartData[0].data.slice(0, currentDate.getMonth() + 1).reduce((totalValue, value) => totalValue + value, 0);
    const totalSalesValueCurrentYear = salesValueChartData[1].data.reduce((totalValue, value) => totalValue + value, 0);
    const percentDiff = (totalSalesValueCurrentYear * 100 / totalSalesValuePreviousYearToCurrent) - 100;
    const percentDiffMonthly = (rawData.MonthlySalesValue * 100 / salesValueChartData[0].data[currentDate.getMonth()]) - 100;
    const latestProductOrderData = rawData.LatestProductOrder.map((productOrder) => {

        let value = productOrder.BundlePrice;
        if (productOrder.BundlePrice === 0) {
            value = (productOrder.Price * productOrder.Amount)
        }

        return {
            Id: productOrder.Id,
            Name: productOrder.Name,
            Value: value,
            Amount: productOrder.Amount,
            CustomerName: productOrder.CustomerName,
            ProductOrderStatus: {
                Id: productOrder.ProductOrderStatusId,
                Name: productOrder.ProductOrderStatusName,
            }
        }
    });

    const focusProductOrder = rawData.FocusPO.map((productOrder) => {

        let value = productOrder.BundlePrice;
        if (productOrder.BundlePrice === 0) {
            value = (productOrder.Price * productOrder.Amount)
        }

        return {
            Id: productOrder.Id,
            Name: productOrder.Name,
            Value: value,
            Amount: productOrder.Amount,
            CustomerName: productOrder.CustomerName,
            ProductOrderStatus: {
                Id: productOrder.ProductOrderStatusId,
                Name: productOrder.ProductOrderStatusName,
            }
        }
    });

    return {
        TotalSalesValue: totalSalesValuePreviousYear,
        CurrentMonthSalesValue: totalSalesValueCurrentYear,
        CurrentDifferent: percentDiff,
        SalesChartData: salesValueChartData,
        FocusProductOrder: focusProductOrder,
        LatestProductOrder: latestProductOrderData,
        MonthlySalesValue: rawData.MonthlySalesValue,
        MonthlyDifferent: percentDiffMonthly,
        TotalProductOrder: rawData.TotalProductOrder,
        TotalProductOrderAmount: rawData.TotalProductOrderAmount,
    }
}

export const getProductOrderTableData = (rawData) => {
    return rawData.map((productOrder) => {

        const dueDate = new Date(productOrder.DueDatedAt);
        const currentDate = new Date();

        let productOrderStatus = productOrder.ProductOrderStatus;
        if(currentDate.getTime() > dueDate.getTime())
        {
            productOrderStatus = {
                Id:6,
                Name:'เลยเวลานัดส่ง'
            }
        }

        return {
            Id: productOrder.Id,
            Name: productOrder.Name,
            Customer: productOrder.Customer,
            Amount: productOrder.Amount,
            TotalInAmount: !Number.isNaN(productOrder.TotalInAmount) ? parseInt(productOrder.TotalInAmount, 10):0,
            TotalGoodAmount: !Number.isNaN(productOrder.TotalGoodAmount) ? parseInt(productOrder.TotalGoodAmount, 10):0,
            TotalProblem: !Number.isNaN(productOrder.TotalProblem) ? parseInt(productOrder.TotalProblem, 10):0,
            createdAt: new Date(productOrder.createdAt),
            DueDatedAt: dueDate,
            ProductOrderStatus: productOrderStatus
        }
    });
}
