import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { getWarehouseDashboard } from '../../../requests/WarehouseServerHandler';
import DetailWidget from '../../customs/DetailWidget';
import { useLocales } from 'locales';
import { Grid, IconButton } from '@mui/material';
import ReactTable from '../../react-table/ReactTable';
import ProjectPaper from '../../customs/ProjectPaper';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import Typography from '@mui/material/Typography';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import Label from '../../minimal/label';
import Link from "@mui/material/Link";

const WarehouseDashboardPage = () => {
  const { translate } = useLocales();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
  });
  const { data: dashboardData, refetch } = useQuery({
    queryKey: ['machine-dashboard-detail', dateRange],
    queryFn: () => getWarehouseDashboard(dateRange),
    initialData: {
      AllNewJobs: 0,
      AllCompleteJobs: 0,
      LatestItemMovement: [],
      AllAddItemLot: [],
    },
  });

  const fetchDashboardDataByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    });
    await refetch();
  };

  const getStockType = (transactionType) => {
    let color = 'primary';
    if (transactionType.Id === 1) {
      color = 'error';
    } else if (transactionType.Id === 2) {
      color = 'success';
    } else if (transactionType.Id === 3) {
      color = 'warning';
    } else if (transactionType.Id === 4) {
      color = 'error';
    } else if (transactionType.Id === 5) {
      color = 'success';
    }

    return <Label color={color}>{transactionType.Name}</Label>;
  };
  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('dashboard') },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SILDatePicker onSearchDate={fetchDashboardDataByDate} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <DetailWidget
            color={'info'}
            title={translate('warehouse.new_request_title')}
            total={dashboardData.AllNewJobs}
            icon={'ic:baseline-watch-later'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <DetailWidget
            color={'success'}
            title={translate('warehouse.complete_request_title')}
            total={dashboardData.AllCompleteJobs}
            icon={'ic:baseline-check'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ProjectPaper>
            <ReactTable
              data={dashboardData.LatestItemMovement.map((stockTransaction) => {
                return {
                  name: stockTransaction.ItemMaster.Name,
                  amount: stockTransaction.Amount,
                  item_receipt_id: stockTransaction.ItemReceiptId,
                  type: stockTransaction.ItemMovementType,
                  transaction_date: stockTransaction.createdAt,
                  actions: stockTransaction.ItemMaster.Id,
                };
              })}
              header={[
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('title.amount'),
                  accessorKey: 'amount',
                },
                {
                  header: translate('title.type'),
                  accessorKey: 'type',
                  cell: (info) => getStockType(info.getValue()),
                },
                {
                  header: translate('warehouse.transaction_at_title'),
                  accessorKey: 'transaction_date',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('warehouse.item_receipt_id'),
                  accessorKey: 'item_receipt_id',
                  cell: (info) =>
                    info.getValue() !== null ? (
                      <Link href={paths.warehouse.item_receipt_report_detail(info.getValue())}>
                        {info.getValue()}
                      </Link>
                    ) : (
                      'N/A'
                    ),
                },
                {
                  header: '',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      component={Link}
                      href={paths.warehouse.item_master_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ProjectPaper>
            <ReactTable
              data={dashboardData.AllAddItemLot.map((itemMovement) => {
                return {
                  name: itemMovement.ItemContainer.ItemMaster.Name,
                  container_name: itemMovement.ItemContainer,
                  amount: itemMovement.Amount,
                  convert_item_master_amount:
                    itemMovement.Amount * itemMovement.ItemContainer.ItemMasterSize,
                  inventory_id: itemMovement.Id,
                  type: translate('warehouse.add_item_to_inventory'),
                  date: itemMovement.createdAt,
                  actions: itemMovement.ItemContainer.ItemMaster.Id,
                };
              })}
              header={[
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('warehouse.container_detail_title'),
                  accessorKey: 'container_name',
                  cell: (info) => (
                    <Link href={paths.warehouse.item_container_detail(info.getValue().Id)}>
                      {info.getValue().Name}
                    </Link>
                  ),
                },
                {
                  header: translate('title.lot_detail'),
                  accessorKey: 'inventory_id',
                },
                {
                  header: translate('title.amount'),
                  accessorKey: 'amount',
                },
                {
                  header: translate('warehouse.convert_to_master_amount'),
                  accessorKey: 'convert_item_master_amount',
                },
                {
                  header: translate('title.type'),
                  accessorKey: 'type',
                  cell: (info) => (
                    <Typography color={'success'} variant={'body2'}>
                      {info.getValue()}
                    </Typography>
                  ),
                },
                {
                  header: translate('warehouse.transaction_at_title'),
                  accessorKey: 'date',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      component={Link}
                      href={paths.warehouse.item_master_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
};

export default WarehouseDashboardPage;
