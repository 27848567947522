import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {acceptItJob, cancelJob, completeJob, getJobDetailById, verifyJob} from '../AdminServerHandler';
import { OpenNotificationThenReloadPage } from '../../utilities/Helper/NotificationHelper';
import { useLocales } from '../../locales';

export const useItJobDetail = (id) => {
  const queryClient = useQueryClient();
  const { translate } = useLocales();
  const { data } = useQuery({
    queryKey: ['itJobDetail', id],
    queryFn: () => getJobDetailById(id),
    initialData: null,
    enabled: !!id,
  });

  const acceptJobMutation = useMutation({
    mutationFn: acceptItJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['itJobDetail', id]);
      await OpenNotificationThenReloadPage(translate('success.accept_job'));
    },
  });

  const cancelJobMutation = useMutation({
    mutationFn: cancelJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['itJobDetail', id]);
      await OpenNotificationThenReloadPage(translate('success.cancel_job'));
    },
  });

  const completeJobMutation = useMutation({
    mutationFn: completeJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['itJobDetail', id]);
      await OpenNotificationThenReloadPage(translate('success.complete_job'));
    },
  });
  const verifyJobMutation = useMutation({
    mutationFn: verifyJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['itJobDetail', id]);
      await OpenNotificationThenReloadPage(translate('success.complete_job'));
    },
  });

  return {
    itJobDetail: data !== null ? data.data : null,
    acceptJob: acceptJobMutation,
    cancelJob: cancelJobMutation,
    completeJob: completeJobMutation,
    verifyJob: verifyJobMutation,
  };
};
