import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import SearchDeliveryBillForm from '../../forms/SearchDeliveryBillForm';
import {
  createDiscountBill,
  getDeliveryBillDetailById,
  getProductOrderDetailById,
} from '../../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fCurrency, fNumber } from '../../../utilities/formatNumber';
import { fDateTimeText } from '../../../utilities/formatTime';
import CreateDiscountBillForm from '../../forms/CreateDiscountBillForm';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ProductOrderScanner } from '../../barcode-reader';
import { paths } from '../../../routes/paths';

function CreateDiscountBillPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [productOrderId, setProductOrderId] = useState(searchParams.get('productOrderId'));
  const [deliveryBillToDiscount, setDeliveryBillToDiscount] = useState(null);
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { data: productOrder, refetch } = useQuery({
    queryKey: ['getProductOrderDetailById', productOrderId],
    queryFn: () => getProductOrderDetailById(productOrderId),
    enabled: productOrderId !== undefined && productOrderId !== null,
    initialData: null,
  });

  const fetchDeliveryBillMutation = useMutation({
    mutationFn: getDeliveryBillDetailById,
    onSuccess: async (response) => {
      if (response.Customer.Id !== productOrder.Customer.Id) {
        await OpenErrorNotification(translate('error.invalid_customer'));
      } else {
        await OpenSuccessNotification();
        setDeliveryBillToDiscount(response);
      }
    },
  });

  const createDiscountBillMutation = useMutation({
    mutationFn: createDiscountBill,
    onSuccess: async () => {
      await OpenSuccessNotification();
      navigate(paths.production.product_order_detail(productOrderId));
    },
  });

  const searchProductOrderById = async (productOrderId) => {
    setProductOrderId(productOrderId);
    setSearchParams({ productOrderId: productOrderId });
    await refetch();
  };

  const onSearchDeliveryBill = async (values) => {
    await fetchDeliveryBillMutation.mutateAsync(values.DeliveryBillId);
  };

  const onSubmitValue = async (values) => {
    await createDiscountBillMutation.mutateAsync({
      ProductOrderId: productOrder.Id,
      DeliveryBillProductOrderId: deliveryBillToDiscount.Piles[0].ProductOrder.Id,
      DeliveryBillId: deliveryBillToDiscount.Id,
      Detail: values.Detail,
      DiscountValue: values.DiscountValue,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.create_discount_title')}
      breadcrumbs={[
        { name: translate('production.title') },
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate(
            'title.id'
          )} : ${productOrderId}`,
          href: paths.production.product_order_detail(productOrderId),
        },
        { name: translate('sales.create_discount_title') },
      ]}
    >
      {productOrder !== null ? (
        <Stack spacing={2}>
          <Card>
            <CardHeader title={translate('production.product_order_detail')} />
            <CardContent>
              <Grid container spacing={2}>
                <GridDetailText title={translate('title.id')} value={productOrder.Id} />
                <GridDetailText
                  title={translate('title.customer')}
                  value={productOrder.Customer.Name}
                />
                <GridDetailText
                  title={translate('title.name')}
                  link={paths.production.product_order_detail(productOrder.Id)}
                  value={productOrder.Name}
                />
              </Grid>
            </CardContent>
          </Card>
          {deliveryBillToDiscount !== null ? (
            <Stack spacing={2}>
              <Card>
                <CardHeader
                  title={`${translate('accounting.delivery_bill_id')} : ${
                    deliveryBillToDiscount.Id
                  }`}
                />
                <CardContent>
                  <Grid container spacing={2}>
                    <GridDetailText
                      title={translate('title.delivery_by')}
                      value={getEmployeeFullName(deliveryBillToDiscount.CreateEmployee)}
                    />
                    <GridDetailText
                      title={translate('title.customer')}
                      value={deliveryBillToDiscount.Customer.Name}
                    />
                    <GridDetailText
                      title={translate('title.name')}
                      link={paths.production.product_order_detail(
                        deliveryBillToDiscount.Piles[0].ProductOrder.Id
                      )}
                      value={deliveryBillToDiscount.Piles[0].ProductOrder.Name}
                    />
                    <GridDetailText
                      title={translate('title.amount')}
                      value={`${fNumber(
                        deliveryBillToDiscount.Piles.reduce((accumulator, pile) => {
                          return accumulator + pile.GoodAmount;
                        }, 0)
                      )} ${translate('unit.sheet')}`}
                    />
                    <GridDetailText
                      title={translate('title.value')}
                      value={fCurrency(deliveryBillToDiscount.TotalValue)}
                    />
                    <GridDetailText
                      title={translate('title.create_at')}
                      value={fDateTimeText(deliveryBillToDiscount.createdAt)}
                    />
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <CreateDiscountBillForm
                    onSubmitValue={onSubmitValue}
                    defaultDiscountValue={deliveryBillToDiscount.TotalValue}
                  />
                </CardContent>
              </Card>
            </Stack>
          ) : (
            <Card>
              <CardHeader title={translate('accounting.search_bill')} />
              <CardContent>
                <SearchDeliveryBillForm onSubmitValue={onSearchDeliveryBill} />
              </CardContent>
            </Card>
          )}
        </Stack>
      ) : (
        <ProductOrderScanner onSearchCallBack={searchProductOrderById} />
      )}
    </ProjectPageContainer>
  );
}

export default CreateDiscountBillPage;
