import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from '../../../locales';
import SelectQualityProcessForm from '../../forms/SelectQualityProcessForm';
import { Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import Iconify from '../../minimal/iconify';
import GridDetailText from '../../customs/GridDetailText';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import PileScanner from '../../barcode-reader/PileScanner';
import useAuth from '../../../auth/hooks/useAuth';
import { fNumber } from '../../../utilities/formatNumber';
import GeneralTable from '../../customs/GeneralTable';
import ProjectPaper from '../../customs/ProjectPaper';
import Button from '@mui/material/Button';
import { useMutation } from '@tanstack/react-query';
import { createPileQualityProcess } from '../../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenErrorNotificationThenReloadPage, OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { DELETE_ICON } from '../../../config-global';

function AddQualityProcessPage() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const [selectQualityProcess, setSelectQualityProcess] = useState(null);
  const [selectPiles, setSelectPiles] = useState([]);
  const submitPileMutation = useMutation({
    mutationFn: createPileQualityProcess,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage('Success');
    },
    onError: async (error) => {
      await OpenErrorNotificationThenReloadPage(error);
    },
  });
  const handleSelectQualityProcess = (qualityProcess) => {
    setSelectQualityProcess(qualityProcess);
  }

  const onRemoveQualityProcess = () => {
    setSelectQualityProcess(null);
  }

  const searchPileDetail = async (pile) => {
    if (pile.PileStatusId === 4){
      await OpenErrorNotification('ขางานส่งเสร็จไปแล้ว');
      return;
    }
    if (pile.PileStatusId === 7){
      await OpenErrorNotification('ขางานยกเลิกไปแล้ว');
      return;
    }
    //add pile to selectPiles without duplicate pile.Id
    if (selectPiles.filter((p) => p.Id === pile.Id).length === 0) {
      setSelectPiles([...selectPiles, pile]);
    }
  };

  const submitPiles = async () => {
    submitPileMutation.mutate({
      QualityProcessId: selectQualityProcess.Id,
      PileIds: selectPiles.map((pile) => pile.Id)
    });
  }

  const handleRemovePile = (pileId) => {
    setSelectPiles(selectPiles.filter((pile) => pile.Id !== pileId));
  }

  return (
    <ProjectPageContainer
      menu_title={translate('planning.add_quality_process')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('planning.add_quality_process') },
      ]}
    >
      {
        selectQualityProcess === null ?
          <SelectQualityProcessForm onSubmitCallback={handleSelectQualityProcess} /> :
          <Stack spacing={2}>
            <Card>
              <CardHeader
                title={translate('planning.quality_process')}
                action={
                  <IconButton color={'error'} onClick={onRemoveQualityProcess}>
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <GridDetailText
                    title={translate('title.name')}
                    value={selectQualityProcess.Name}
                  />
                </Grid>
              </CardContent>
            </Card>

            <ProjectPaper>
              <PileScanner
                onSearchCallBack={searchPileDetail}
                disableInput={user.UserTypeId !== ManagementUserTypeId}
                isPreFetchData
              />
              <GeneralTable
                tableHeaders={[
                  {align: 'left', title: translate('title.id')},
                  {align: 'left', title: translate('title.name')},
                  {align: 'left', title: translate('title.customer')},
                  {align: 'left', title: translate('title.amount')},
                  {align: 'left', title: translate('Actions')},
                ]}
                tableData={selectPiles.map((pile) => {
                  return [
                    pile.Id,
                    pile.ProductOrder.Name,
                    pile.ProductOrder.Customer.Name,
                    fNumber(pile.Amount),
                    <IconButton color={'error'} onClick={() => handleRemovePile(pile.Id)}>
                      <Iconify icon={DELETE_ICON} />
                    </IconButton>,
                  ];
                })}
              />
            </ProjectPaper>
            <Button color={'success'} disabled={selectPiles.length === 0} variant="contained" onClick={submitPiles}>
              {translate('button.submit')}
            </Button>
          </Stack>
      }
    </ProjectPageContainer>
  );
}

export default AddQualityProcessPage;