import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import { getFullAddressText } from 'utilities/Helper/UtilitiesHelper';
import ProjectPaper from 'components/customs/ProjectPaper';
import { useLocales } from 'locales';
import { AdminUserTypeId, ManagementUserTypeId, ProcurementUserTypeId } from 'utilities/Constants';
import EditableGridDetailText from 'components/customs/EditableGridDetailText';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { OpenTranslateWarningConfirmNotification } from 'utilities/Helper/NotificationHelper';
import { useCustomerDetail } from 'requests/query/useCustomerDetail';

CustomerDetail.propTypes = {
  customerId: PropTypes.string.isRequired,
};

function CustomerDetail({ customerId }) {
  const { translate } = useLocales();
  const { customerDetail, editCustomer } = useCustomerDetail(customerId);

  const handleEditCustomerName = async (name) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_edit_customer_name',
      async () => {
        await editCustomer.mutateAsync({
          CustomerId: customerId,
          Name: name,
        });
      }
    );
  };

  return (
    <div>
      {customerDetail !== undefined ? (
        <ProjectPaper>
          <Grid container spacing={2}>
            <GridDetailText title={translate('title.id')} value={customerDetail.Id} />
            <RoleBasedGuard
              roles={[ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId]}
              children={
                <EditableGridDetailText
                  title={translate('title.name')}
                  onEdit={handleEditCustomerName}
                  value={customerDetail.Name}
                />
              }
              otherComponent={
                <GridDetailText title={translate('title.name')} value={customerDetail.Name} />
              }
            />
            <GridDetailText
              title={translate('title.address')}
              value={getFullAddressText(customerDetail.District, customerDetail.AdditionAddress)}
            />
            <GridDetailText
              title={translate('title.credit_limit')}
              value={customerDetail.CreditLimit}
            />
            <GridDetailText
              title={translate('title.credit_day')}
              value={customerDetail.CreditDays}
            />
            <GridDetailText
              title={translate('title.tax_registration_number')}
              value={customerDetail.TaxRegistrationNumber}
            />
          </Grid>
        </ProjectPaper>
      ) : null}
    </div>
  );
}

export default CustomerDetail;
