import React from 'react';
import PropTypes from 'prop-types';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { useLocales } from '../../../locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import { IconButton } from '@mui/material';
import Iconify from '../../minimal/iconify';
import { OPEN_ICON } from '../../../config-global';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

ItemTemplateLotDetail.propTypes = {
  itemTemplateLot: PropTypes.array.isRequired,
};

function ItemTemplateLotDetail({ itemTemplateLot }) {
  const { translate } = useLocales();

  return (
    <ProjectPaper>
      <ReactTable
        search
        defaultPageSize={5}
        data={itemTemplateLot.map((lot) => {
          return {
            id: lot.Id,
            supplier: lot.Supplier,
            status: lot.InventoryStatus.Name,
            create_at: lot.createdAt,
            actions: lot.Id,
          };
        })}
        header={[
          {
            header: translate('warehouse.lot_id'),
            accessorKey: 'id',
          },
          {
            header: translate('warehouse.supplier_title'),
            accessorKey: 'supplier',
            cell: (info) => (
              <Link href={paths.procurement.part_supplier_detail(info.getValue().Id)}>
                {info.getValue().Name}
              </Link>
            ),
          },
          {
            header: translate('title.status'),
            accessorKey: 'status',
          },
          {
            header: translate('title.create_at'),
            accessorKey: 'create_at',
            cell: (info) => fDateTimeText(info.getValue()),
          },
          {
            header: 'Actions',
            accessorKey: 'actions',
            cell: (info) => (
              <IconButton
                component={Link}
                href={paths.general_warehouse.inventory_detail(info.getValue())}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>
            ),
          },
        ]}
      />
    </ProjectPaper>
  );
}

export default ItemTemplateLotDetail;
