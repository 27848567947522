import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { useResponsive } from '../../../hooks/useResponsive';
import Iconify from 'components/minimal/iconify';
import ItemTemplateDetailForm from '../../forms/ItemTemplateDetailForm';
import { paths } from '../../../routes/paths';

const CreateItemTemplatePage = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_item_template_title')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.create_item_template_title') },
      ]}
      action={
        <Stack spacing={2} direction={isDesktop ? 'row' : 'column'}>
          <Button
            startIcon={<Iconify icon={'ic:baseline-add'} />}
            variant={'contained'}
            onClick={() =>
              navigate(
                paths.general_warehouse.create_unit + '?redirect=/warehouse/create-item-template'
              )
            }
            color={'info'}
          >
            {translate('warehouse.create_new_unit_title')}
          </Button>
          <Button
            startIcon={<Iconify icon={'ic:baseline-add'} />}
            variant={'contained'}
            onClick={() =>
              navigate(
                paths.general_warehouse.create_inventory +
                  '?redirect=/warehouse/create-item-template'
              )
            }
            color={'info'}
          >
            {translate('warehouse.create_new_store')}
          </Button>
          <Button
            startIcon={<Iconify icon={'ic:baseline-add'} />}
            variant={'contained'}
            onClick={() =>
              navigate(
                paths.general_warehouse.create_shelf + '?redirect=/warehouse/create-item-template'
              )
            }
            color={'info'}
          >
            {translate('warehouse.create_new_shelf')}
          </Button>
        </Stack>
      }
    >
      <ItemTemplateDetailForm />
    </ProjectPageContainer>
  );
};

export default CreateItemTemplatePage;
