import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import AcceptItemReceiptForm from '../../forms/AcceptItemReceiptForm';
import { useItemReceipt } from '../../../requests/query/useItemReceipt';

const AcceptItemReceiptPage = () => {
  const { translate } = useLocales();
  const { acceptItemReceipt } = useItemReceipt(null);

  const findReportWithId = async (id, employeeId, isTemp) => {
    await acceptItemReceipt.mutateAsync({
      ItemReceiptId: id,
      AcceptEmployeeId: employeeId,
      IsTemp: isTemp,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.accept_item')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.accept_item') },
      ]}
    >
      <AcceptItemReceiptForm handleFindReport={findReportWithId} />
    </ProjectPageContainer>
  );
};
export default AcceptItemReceiptPage;
