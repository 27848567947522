import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import CreateItemMasterForm from '../../forms/CreateItemMasterForm';
import { useLocales } from 'locales';

function CreateMaterialItemMasterPage() {
  const { translate } = useLocales();
  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_item_master_title')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.create_item_master_title') },
      ]}
    >
      <CreateItemMasterForm />
    </ProjectPageContainer>
  );
}

export default CreateMaterialItemMasterPage;
