import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useLocales } from 'locales';
import GridDetailText from 'components/customs/GridDetailText';
import { fNumber } from 'utilities/formatNumber';
import Iconify from 'components/minimal/iconify';
import { PrintMultiplePDFMakeDocument } from 'utilities/Helper/PrintHelper';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  createPileFromProductOrderId,
  getProductOrderDetailById,
} from 'requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CreatePileForm from '../../forms/CreatePileForm';
import { ProductOrderScanner } from '../../barcode-reader';
import ProjectPaper from '../../customs/ProjectPaper';
import { paths } from '../../../routes/paths';
import { useLoading } from '../../loading';
function CreatePilePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { translate } = useLocales();
  const [productOrderId, setProductOrderId] = useState(searchParams.get('productOrderId'));
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const queryClient = useQueryClient();

  const { data: productOrder, refetch } = useQuery({
    queryKey: ['getProductOrderDetailById', productOrderId],
    queryFn: () => getProductOrderDetailById(productOrderId),
    enabled: productOrderId !== undefined && productOrderId !== null,
    initialData: null,
  });

  const createPileMutation = useMutation({
    mutationFn: createPileFromProductOrderId,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument([response.PDFFile], `Pile_Doc_${response.Id}.pdf`);
      await queryClient.invalidateQueries(['getProductOrderDetailById', productOrderId]);
      hideLoadingScreen();
      await OpenSuccessNotification(`${translate('notification.create_pile_id')} ${response.Id}`);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
      hideLoadingScreen();
    },
  });

  const searchProductOrderById = async (productOrderId) => {
    setProductOrderId(productOrderId);
    setSearchParams({ productOrderId: productOrderId });
    await refetch();
  };
  const clearProductOrder = async () => {
    setProductOrderId(null);
    setSearchParams({});
    await refetch();
  };

  const handleCreatePile = async (value) => {
    const totalIn = productOrder.Piles.reduce((totalAmount, pile) => {
      return totalAmount + pile.Amount;
    }, 0);

    if (totalIn + parseInt(value.Amount, 10) > productOrder.Amount) {
      await OpenErrorNotification(translate('error.product_order_amount_exceed'));
    } else {
      await createPileMutation.mutateAsync({
        ProductOrderId: productOrder.Id,
        ReferenceId: value.ReferenceId,
        Amount: value.Amount,
        Height: value.Height,
      });
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.create_pile')}
      breadcrumbs={
        productOrder !== null
          ? [
              { name: translate('production.title') },
              {
                name: translate('production.product_order_list'),
                href: paths.production.product_order_list,
              },
              {
                name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${
                  productOrder?.Id
                }`,
                href: paths.production.product_order_detail(productOrder?.Id),
              },
              { name: translate('production.create_pile') },
            ]
          : [
              { name: translate('production.title') },
              {
                name: translate('production.product_order_list'),
                href: paths.production.product_order_list,
              },
              { name: translate('production.create_pile') },
            ]
      }
    >
      {productOrder !== null ? (
        <Stack spacing={2}>
          <Card>
            <CardHeader
              title={translate('title.detail')}
              action={
                <Button
                  color={'error'}
                  variant={'contained'}
                  startIcon={<Iconify icon={'eva:close-fill'} />}
                  onClick={clearProductOrder}
                >
                  {translate('button.change_product_order')}
                </Button>
              }
            />
            <CardContent>
              <Grid container spacing={3}>
                <GridDetailText title={translate('title.id')} value={productOrder.Id} />
                <GridDetailText title={translate('title.name')} value={productOrder.Name} />
                <GridDetailText title={translate('title.detail')} value={productOrder.Detail} />
                <GridDetailText
                  title={`${translate('title.width')} x ${translate('title.length')}`}
                  value={`${productOrder.Width} x ${productOrder.Length}`}
                />
                <GridDetailText
                  title={translate('title.amount')}
                  value={fNumber(productOrder.Amount)}
                />
                <GridDetailText
                  title={translate('title.amount_in')}
                  value={fNumber(
                    productOrder.Piles.reduce((totalAmount, pile) => {
                      return totalAmount + pile.Amount;
                    }, 0)
                  )}
                />
                <GridDetailText
                  title={translate('title.customer')}
                  value={productOrder.Customer.Name}
                />
              </Grid>
            </CardContent>
          </Card>
          <ProjectPaper>
            <CreatePileForm onFormSubmit={handleCreatePile} />
          </ProjectPaper>
        </Stack>
      ) : (
        <ProductOrderScanner onSearchCallBack={searchProductOrderById} />
      )}
    </ProjectPageContainer>
  );
}

export default CreatePilePage;
