import React from 'react';
import { DataScanner } from './index';
import SearchProductOrderForm from '../forms/SearchProductOrderForm';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getProductOrderDetailById } from '../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';

ProductOrderScanner.propTypes = {
  onSearchCallBack: PropTypes.func.isRequired,
  isPreFetchData: PropTypes.bool,
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
};

function ProductOrderScanner({ onSearchCallBack, isPreFetchData, disableInput, disableScan }) {
  const searchProductOrderMutation = useMutation({
    mutationFn: getProductOrderDetailById,
    onSuccess: async (productOrder) => {
      await OpenSuccessNotification();
      onSearchCallBack(productOrder);
    },
    onError: async (error) => {
      await OpenErrorNotification(error.response.data);
    },
  });

  const handleScanCallback = async (data) => {
    if (data.includes('OrderId')) {
      const dataJson = JSON.parse(data);
      if (dataJson.OrderId !== undefined) {
        await handleSearchProductOrder(dataJson.OrderId);
      }
    } else {
      await handleSearchProductOrder(data);
    }
  };
  const handleSearchProductOrder = async (id) => {
    if (isPreFetchData) {
      searchProductOrderMutation.mutate(id);
    } else {
      await OpenSuccessNotification();
      onSearchCallBack(id);
    }
  };
  const searchProductOrderForm = (
    <SearchProductOrderForm onSubmitCallBack={handleSearchProductOrder} />
  );
  return (
    <DataScanner
      disableInput={disableInput}
      disableScan={disableScan}
      onScanCallback={handleScanCallback}
      FormComponent={searchProductOrderForm}
    />
  );
}

export default ProductOrderScanner;
