import React, { useMemo, useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getQualityDashboardData } from '../../../requests/ProductionServerHandler';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { useLocales } from 'locales';
import { Grid, IconButton } from '@mui/material';
import DetailWidget from '../../customs/DetailWidget';
import GeneralTable from '../../customs/GeneralTable';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { fDateTimeText } from '../../../utilities/formatTime';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

function QualityCheckDashboardPage() {
  const { translate } = useLocales();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const { data: dashboardData, refetch } = useQuery({
    queryKey: ['quality-dashboard', dateRange],
    queryFn: () =>
      getQualityDashboardData({
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
      }),
    initialData: [],
  });
  const qualityData = useMemo(() => {
    return dashboardData.map((report) => {
      return {
        pile_id: report.Pile.Id,
        check_at: report.ApprovedQualityAt,
        check_by: report.ApproveQualityEmployee,
        process: report.ProductionProcess.Name,
        name: report.Pile.ProductOrder.Name,
        actions: report.Pile.Id,
      };
    });
  }, [dashboardData]);

  const chartData = useMemo(() => {
    let data = [];
    dashboardData.forEach((report) => {
      const findEmployee = data.find(
        (employeeData) => employeeData.Employee.Id === report.ApproveQualityEmployee.Id
      );
      if (findEmployee !== undefined) {
        findEmployee.Amount += 1;
      } else {
        data.push({
          Employee: report.ApproveQualityEmployee,
          Amount: 1,
        });
      }
    });
    data.sort(function (a, b) {
      return b.Amount - a.Amount;
    });

    return data.map((employeeData, index) => {
      return [index + 1, getEmployeeFullName(employeeData.Employee), employeeData.Amount];
    });
  }, [dashboardData]);

  const getDashboardDataByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };

  return (
    <ProjectPageContainer
      menu_title={translate('qa.quality_assurance_check_dashboard_title')}
      breadcrumbs={[
        { name: translate('qa.title') },
        { name: translate('qa.quality_assurance_check_dashboard_title') },
      ]}
    >
      <SILDatePicker onSearchDate={getDashboardDataByDate} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
              ]}
              tableData={chartData}
            />
          </ProjectPaper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <DetailWidget
            color={'success'}
            title={translate('qa.number_of_quality_check')}
            total={qualityData.length}
            icon={'gis:layer-stack'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ProjectPaper>
            <ReactTable
              search
              data={qualityData}
              header={[
                {
                  header: translate('title.id'),
                  accessorKey: 'pile_id',
                },
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('title.process'),
                  accessorKey: 'process',
                },
                {
                  header: translate('qa.approve_quality_at'),
                  accessorKey: 'check_at',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('qa.approve_quality_by'),
                  accessorKey: 'check_by',
                  cell: (info) => getEmployeeFullName(info.getValue()),
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      component={RouterLink}
                      to={paths.production.pile_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
}

export default QualityCheckDashboardPage;
