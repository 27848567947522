import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Tab, Tabs } from '@mui/material';

import { useLocales } from 'locales';
import useTabs from '../../../hooks/useTabs';
import LoadingComponent from 'components/customs/LoadingComponent';
import Iconify from 'components/minimal/iconify';
import ProductOrderDetail from '../../details/product_order/ProductOrderDetail';
import ProductOrderPileDetail from '../../details/product_order/ProductOrderPileDetail';
import ProductOrderProcessDetail from '../../details/product_order/ProductOrderProcessDetail';
import ProductOrderPriceDetail from '../../details/product_order/ProductOrderPriceDetail';
import ProductOrderMaterialDetail from '../../details/product_order/ProductOrderMaterialDetail';
import ProductOrderDeliveryBillDetail from '../../details/product_order/ProductOrderDeliveryBillDetail';
import ProductOrderBillingNoteDetail from '../../details/product_order/ProductOrderBillingNoteDetail';
import ProductOrderAdditionalCostBillList from '../../details/product_order/ProductOrderAdditionalCostBillList';
import ProductOrderProblemDetail from '../../details/product_order/ProductOrderProblemDetail';
import ProductOrderDiscountDetail from 'components/details/ProductOrderDiscountDetail';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import EmptyContent from 'components/customs/EmptyContent';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  ManagementUserTypeId,
  ProductionControlUserTypeId,
  ProductionUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
} from 'utilities/Constants';
import { useResponsive } from 'hooks/useResponsive';
import { PrintMultiplePDFMakeDocument } from 'utilities/Helper/PrintHelper';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from 'utilities/Helper/NotificationHelper';

import { getSnapshotDocumentForProductOrder } from 'utilities/PDFDocument';
import { useProductOrderDetail } from 'requests/query/useProductOrderDetail';
import { paths } from 'routes/paths';

function ProductOrderDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const {
    productOrder,
    processDetails,
    billingNotes,
    deliveryBills,
    cancel,
    complete,
    markAsFocus,
    unMarkAsFocus,
    editName,
    editDetail,
    printDeliveryReportPDF,
  } = useProductOrderDetail(id);
  const { currentTab, onChangeTab } = useTabs('detail');
  const isDesktop = useResponsive('up', 'lg');
  const handleEditName = async (data) => {
    await editName.mutateAsync(data);
  };

  const handleEditDetail = async (data) => {
    await editDetail.mutateAsync(data);
  };

  const onCreateProblemDetail = () => {
    navigate(`${paths.production.create_product_order_problem}?productOrderId=${productOrder?.Id}`);
  };
  const onPrintSnapshotDocument = async () => {
    await PrintMultiplePDFMakeDocument(
      [getSnapshotDocumentForProductOrder(productOrder, processDetails)],
      `mss_${productOrder.Id}.pdf`
    );
    await OpenSuccessNotification();
  };
  const onPrintDeliveryReport = async () => {
    await printDeliveryReportPDF.mutateAsync(id);
  };
  const onCancelPressed = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_po',
      async () => {
        await cancel.mutateAsync(id);
      }
    );
  };
  const onCompletePressed = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_complete_po',
      async () => {
        await complete.mutateAsync(id);
      }
    );
  };
  const onMarkAsFocusJob = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_mark_as_focus_job',
      async () => {
        await markAsFocus.mutateAsync(id);
      }
    );
  };
  const onUnMarkAsFocusJob = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_un_mark_as_focus_job',
      async () => {
        await unMarkAsFocus.mutateAsync(id);
      }
    );
  };

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: (
        <ProductOrderDetail
          productOrder={productOrder}
          onEditNameCallback={handleEditName}
          onEditDetailCallback={handleEditDetail}
        />
      ),
    },
    {
      value: 'price',
      title: translate('title.price'),
      icon: <Iconify icon={'dashicons:money-alt'} width={20} height={20} />,
      component: (
        <RoleBasedGuard
          roles={[SalesUserTypeId, AccountingUserTypeId, ManagementUserTypeId]}
          hasContent={
            <EmptyContent
              title={translate('title.empty')}
              description={translate('placeholder.empty_table')}
              img="/assets/illustrations/illustration_empty_content.svg"
            />
          }
        >
          <ProductOrderPriceDetail
            priceDetails={productOrder?.ProductSellHistories}
            productOrder={productOrder}
          />
        </RoleBasedGuard>
      ),
    },
    {
      value: 'process',
      title: translate('title.process'),
      icon: <Iconify icon={'ant-design:ordered-list-outlined'} width={20} height={20} />,
      component: <ProductOrderProcessDetail processDetails={processDetails} />,
    },
    {
      value: 'pile',
      title: translate('title.pile'),
      icon: <Iconify icon={'gis:layer-stack'} width={20} height={20} />,
      component: (
        <ProductOrderPileDetail piles={productOrder?.Piles} productOrderId={productOrder?.Id} />
      ),
    },
    {
      value: 'problem',
      title: translate('title.problem'),
      icon: <Iconify icon={'ic:outline-report-problem'} width={20} height={20} />,
      component: <ProductOrderProblemDetail piles={productOrder?.Piles} productOrderId={id} />,
    },
    {
      value: 'material',
      title: translate('warehouse.material'),
      icon: <Iconify icon={'eva:cube-outline'} width={20} height={20} />,
      component: <ProductOrderMaterialDetail productOrderId={productOrder?.Id} />,
    },
    {
      value: 'additional_cost',
      title: translate('sales.additional_cost_bill'),
      icon: <Iconify icon={'ri:bill-line'} width={20} height={20} />,
      component: (
        <ProductOrderAdditionalCostBillList
          additionalCostBills={productOrder?.ProductOrderAdditionalCostBills}
          productOrderId={productOrder?.Id}
        />
      ),
    },
    {
      value: 'discount',
      title: translate('title.discount'),
      icon: <Iconify icon={'mdi:discount'} width={20} height={20} />,
      component: (
        <RoleBasedGuard
          roles={[SalesUserTypeId, AccountingUserTypeId, ManagementUserTypeId]}
          otherComponent={
            <EmptyContent
              title={translate('title.empty')}
              description={translate('placeholder.empty_table')}
              img="/assets/illustrations/illustration_empty_content.svg"
            />
          }
        >
          <ProductOrderDiscountDetail productOrderId={productOrder?.Id} />
        </RoleBasedGuard>
      ),
    },
    {
      value: 'delivery_bill',
      title: translate('sales.delivery_bill'),
      icon: <Iconify icon={'eva:car-outline'} width={20} height={20} />,
      component: <ProductOrderDeliveryBillDetail deliveryBill={deliveryBills} />,
    },
    {
      value: 'billing_note',
      title: translate('sales.billing_note'),
      icon: <Iconify icon={'uil:bill'} width={20} height={20} />,
      component: <ProductOrderBillingNoteDetail billingNote={billingNotes} />,
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('production.product_order_detail')}
      breadcrumbs={[
        { name: translate('production.title') },
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${id}`,
        },
      ]}
      action={
        <RoleBasedGuard roles={[ManagementUserTypeId, SalesUserTypeId]}>
          {![3, 4].includes(productOrder?.ProductOrderStatus.Id) && (
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color={'error'}
              startIcon={<Iconify icon={'eva:close-fill'} />}
              onClick={onCancelPressed}
            >
              {translate('button.cancel')}
            </Button>
          )}
        </RoleBasedGuard>
      }
    >
      <>
        <Stack sx={{ mb: 2 }} spacing={2} direction={isDesktop ? 'row' : 'column'}>
          <RoleBasedGuard
            roles={[ManagementUserTypeId, SalesUserTypeId, ProductionControlUserTypeId]}
          >
            {productOrder?.FocusProductOrders.length > 0 ? (
              <Button
                variant="contained"
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={onUnMarkAsFocusJob}
              >
                {translate('button.un_mark_as_focus_job')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color={'primary'}
                startIcon={<Iconify icon={'ic:baseline-add'} />}
                onClick={onMarkAsFocusJob}
              >
                {translate('button.mark_as_focus_job')}
              </Button>
            )}
          </RoleBasedGuard>
          <RoleBasedGuard
            roles={[
              ManagementUserTypeId,
              SalesUserTypeId,
              QualityAssuranceUserTypeId,
              ProductionControlUserTypeId,
            ]}
          >
            <Button
              variant="contained"
              color={'warning'}
              startIcon={<Iconify icon={'ic:sharp-report-problem'} />}
              onClick={onCreateProblemDetail}
            >
              {translate('button.add_problem_flag')}
            </Button>
          </RoleBasedGuard>
          <RoleBasedGuard
            roles={[
              ProductionUserTypeId,
              ManagementUserTypeId,
              AdminUserTypeId,
              AccountingUserTypeId,
              SalesUserTypeId,
              SecretaryUserTypeId,
              QualityAssuranceUserTypeId,
              ProductionControlUserTypeId,
            ]}
          >
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={onPrintSnapshotDocument}
            >
              {translate('button.print_snapshot_document_title')}
            </Button>
          </RoleBasedGuard>
          <RoleBasedGuard
            roles={[ManagementUserTypeId, AdminUserTypeId, AccountingUserTypeId, SalesUserTypeId]}
          >
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={onPrintDeliveryReport}
            >
              {translate('button.print_delivery_summary')}
            </Button>
            {![3, 4].includes(productOrder?.ProductOrderStatus.Id) && (
              <Button
                variant="contained"
                color={'success'}
                startIcon={<Iconify icon={'ic:baseline-check'} />}
                onClick={onCompletePressed}
              >
                {translate('button.mark_as_complete')}
              </Button>
            )}
          </RoleBasedGuard>
        </Stack>
        {productOrder !== null ? (
          <>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {INFORMATION_TABS.map((tab, index) => (
                <Tab
                  disableRipple
                  key={index}
                  label={tab.title}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Box sx={{ mb: 3 }} />
            {INFORMATION_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && tab.component;
            })}
          </>
        ) : (
          <LoadingComponent />
        )}
      </>
    </ProjectPageContainer>
  );
}

export default ProductOrderDetailPage;
