import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import {
  getInventoryShelfById,
  getItemTemplateDetailById,
  moveItemToShelf,
} from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import GeneralTable from '../../customs/GeneralTable';
import { DetailScanner } from '../../barcode-reader';
import { useMutation } from '@tanstack/react-query';

export default function MoveInventoryShelfPage() {
  const [targetShelf, setTargetShelf] = useState(null);
  const [moveItems, setMoveItems] = useState([]);
  const { translate } = useLocales();

  const findInventoryShelfMutation = useMutation({
    mutationFn: getInventoryShelfById,
    onSuccess: (data) => {
      setTargetShelf(data);
    },
    onError: async () => {
      await OpenErrorNotification('ไม่พบชั้นวางที่ต้องการย้าย');
    },
  });
  const findItemMasterMutation = useMutation({
    mutationFn: getItemTemplateDetailById,
    onSuccess: (data) => {
      setMoveItems([
        ...moveItems.filter((addItem) => addItem.Id !== data.Id),
        {
          Id: data.Id,
          Name: data.Name,
        },
      ]);
    },
    onError: async () => {
      await OpenErrorNotification('ไม่พบสิ่งของที่ต้องการย้าย');
    },
  });
  const moveItemMutation = useMutation({
    mutationFn: moveItemToShelf,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const findShelfWithId = async (id) => {
    await findInventoryShelfMutation.mutateAsync(id);
  };
  const findItemMasterFromBarcode = async (code) => {
    await findItemMasterMutation.mutateAsync(code);
  };
  const onShelfMove = async () => {
    await moveItemMutation.mutateAsync({
      TargetShelfId: targetShelf.Id,
      ItemTemplateIds: moveItems.map((item) => item.Id),
    });
  };
  const onBarcodeScan = async (scan) => {
    if (targetShelf !== null) {
      await findItemMasterFromBarcode(scan);
    } else {
      const dataJson = JSON.parse(scan);
      if (dataJson.Id !== undefined) {
        await findShelfWithId(dataJson.Id);
      } else {
        await findShelfWithId(scan);
      }
    }
  };

  const onDeleteItem = (itemId) => {
    setMoveItems(moveItems.filter((item) => item.Id !== itemId));
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.move_shelf')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.move_shelf') },
      ]}
    >
      {targetShelf !== null ? (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('warehouse.shelf_id')} value={targetShelf.Id} />
              <GridDetailText
                title={translate('warehouse.target_move_shelf')}
                value={targetShelf.Name}
              />
              <GridDetailText
                title={translate('warehouse.store')}
                value={targetShelf.InventoryStore.Name}
              />
            </Grid>
          </ProjectPaper>
          <Card>
            <CardHeader title={translate('warehouse.items_to_move')} />
            <CardContent>
              <Stack spacing={3}>
                <GeneralTable
                  tableHeaders={[
                    { align: 'left', title: '#' },
                    { align: 'left', title: 'title.name' },
                    { align: 'left', title: 'title.id' },
                    { align: 'left', title: 'title.amount' },
                    { align: 'left', title: '' },
                  ]}
                  tableData={moveItems.map((item, index) => {
                    return [
                      index + 1,
                      item.Name,
                      item.Id,
                      [
                        <IconButton color={'error'} onClick={() => onDeleteItem(item)}>
                          <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                        </IconButton>,
                      ],
                    ];
                  })}
                />
                <Button
                  variant={'contained'}
                  color={'primary'}
                  fullWidth
                  disabled={moveItems.length === 0}
                  onClick={onShelfMove}
                >
                  {translate('button.move')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
          <DetailScanner
            onScanCallback={onBarcodeScan}
            onEnterTextField={findItemMasterFromBarcode}
            textFieldLabel={translate('warehouse.item_template_id')}
          />
        </Stack>
      ) : (
        <DetailScanner
          onScanCallback={onBarcodeScan}
          onEnterTextField={findShelfWithId}
          textFieldLabel={translate('warehouse.shelf_id')}
        />
      )}
    </ProjectPageContainer>
  );
}
