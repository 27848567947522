import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { paths } from '../../../routes/paths';
import { useLocales } from '../../../locales';
import CreateMaterialForm from '../../forms/CreateMaterialForm';
import { OpenConfirmNotification, OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { createMaterial } from '../../../requests/WarehouseServerHandler';
import { useLoadingMutation } from '../../../requests/utility';

function CreateMaterialPage() {
  const { translate } = useLocales();
  const createMaterialMutation = useLoadingMutation({
    mutationFn: createMaterial,
    onSuccess: async (createMaterialId) => {
      await OpenNotificationThenReloadPage(`คุณสร้าง Material รหัส : ${createMaterialId}`);
    },
  });

  const onCreateMaterial = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_material'),
      translate('button.confirm'),
      '',
      async () => {
        await createMaterialMutation.mutateAsync({
          Name: values.Name,
          MaterialTypeId: values.MaterialType.Id,
          MaterialUnitId: values.MaterialUnit.Id,
          ItemMasters: values.ItemMasters.map((item) => {
            return {
              Id: item.ItemMaster.Id,
              ConvertAmount: item.ConvertAmount,
            };
          }),
        });
      }
    );

  };

  return (
    <ProjectPageContainer
      menu_title={translate('product.create_material')}
      breadcrumbs={[
        { name: translate('product.title') },
        { name: translate('product.material_list'), href: paths.product.material_list },
        { name: translate('product.create_material') },
      ]}
    >
      <CreateMaterialForm onSubmitCallback={onCreateMaterial} />
    </ProjectPageContainer>
  );
}

export default CreateMaterialPage;