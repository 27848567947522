import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import DetailWidget from '../../customs/DetailWidget';
import { useLocales } from '../../../locales';
import { fNumber } from '../../../utilities/formatNumber';
import GridDetailText from '../../customs/GridDetailText';
import EditableGridDetailText from '../../customs/EditableGridDetailText';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import {
  ManagementUserTypeId,
  ProductionControlUserTypeId,
  SalesUserTypeId,
} from '../../../utilities/Constants';
import { paths } from '../../../routes/paths';

ProductOrderDetail.propTypes = {
  productOrder: PropTypes.object,
  isShortMode: PropTypes.bool,
  onEditNameCallback: PropTypes.func,
  onEditDetailCallback: PropTypes.func,
};

function ProductOrderDetail({
  productOrder,
  isShortMode = false,
  onEditDetailCallback,
  onEditNameCallback,
}) {
  const theme = useTheme();
  const { translate } = useLocales();

  const getStatusTextColor = (statusId) => {
    if (statusId === 1) {
      return theme.palette.info.main.toString();
    }
    if (statusId === 2) {
      return theme.palette.warning.main.toString();
    }
    if (statusId === 3) {
      return theme.palette.success.main.toString();
    }
    if (statusId === 4) {
      return theme.palette.error.main.toString();
    }
    return theme.palette.common.black.toString();
  };
  const getAmountIn = () =>
    productOrder.Piles.reduce((accumulator, pile) => accumulator + pile.Amount, 0);
  const getGoodAmount = () =>
    productOrder.Piles.reduce((accumulator, pile) => accumulator + pile.GoodAmount, 0);
  const getDefectAmount = () =>
    productOrder.Piles.reduce((accumulator, pile) => accumulator + pile.DefectAmount, 0);
  const getAmountPercent = (amount) => (amount * 100) / productOrder.Amount;

  const onEditName = async (saveName) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_edit_data',
      async () => {
        if (onEditNameCallback !== undefined) {
          await onEditNameCallback({
            ProductOrderId: productOrder.Id,
            Name: saveName,
          });
        }
      }
    );
  };
  const onEditDetail = async (saveDetail) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_edit_data',
      async () => {
        if (onEditDetailCallback !== undefined) {
          await onEditDetailCallback({
            ProductOrderId: productOrder.Id,
            Detail: saveDetail,
          });
        }
      }
    );
  };
  if (isShortMode) {
    return (
      <>
        <Grid container spacing={3}>
          {productOrder?.ProductOrderProblemDetails.reduce((accumulator, problem) => {
            if (problem.SolvedAt === null) {
              return accumulator + 1;
            } else {
              return accumulator;
            }
          }, 0) > 0 ? (
            <GridDetailText
              title={translate('status.title')}
              value={translate('status.problem')}
              color={theme.palette.warning.main.toString()}
            />
          ) : null}
          <GridDetailText title={translate('title.id')} value={productOrder?.Id} />
          <RoleBasedGuard
            roles={[ManagementUserTypeId, SalesUserTypeId, ProductionControlUserTypeId]}
            otherComponent={
              <GridDetailText title={translate('title.name')} value={productOrder?.Name} />
            }
          >
            <GridDetailText title={translate('title.name')} value={productOrder?.Name} />
          </RoleBasedGuard>
          <RoleBasedGuard
            roles={[ManagementUserTypeId, SalesUserTypeId, ProductionControlUserTypeId]}
            otherComponent={
              <GridDetailText title={translate('title.detail')} value={productOrder?.Detail} />
            }
          >
            <GridDetailText title={translate('title.detail')} value={productOrder?.Detail} />
          </RoleBasedGuard>
          <GridDetailText
            title={`${translate('title.width')} x ${translate('title.length')}`}
            value={`${productOrder?.Width} x ${productOrder?.Length}`}
          />
          <GridDetailText title={translate('title.amount')} value={fNumber(productOrder?.Amount)} />
          <GridDetailText title={translate('title.customer')} value={productOrder?.Customer.Name} />
          <GridDetailText
            title={translate('title.create_by')}
            value={getEmployeeFullName(productOrder?.CreateEmployee)}
          />
          <GridDetailText
            title={translate('title.create_at')}
            value={fDateTimeText(productOrder?.createdAt)}
          />
          <GridDetailText
            title={translate('title.due_at')}
            value={fDateTimeText(productOrder?.DueDatedAt)}
          />
          <GridDetailText
            title={translate('title.status')}
            value={productOrder?.ProductOrderStatus.Name}
            color={getStatusTextColor(productOrder?.ProductOrderStatus.Id)}
          />
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Grid container spacing={3}>
              {productOrder?.ProductOrderProblemDetails.reduce((accumulator, problem) => {
                if (problem.SolvedAt === null) {
                  return accumulator + 1;
                } else {
                  return accumulator;
                }
              }, 0) > 0 ? (
                <GridDetailText
                  title={translate('status.title')}
                  value={translate('status.problem')}
                  color={theme.palette.warning.main.toString()}
                />
              ) : null}
              <GridDetailText title={translate('title.id')} value={productOrder?.Id} />
              <RoleBasedGuard
                roles={[ManagementUserTypeId, SalesUserTypeId, ProductionControlUserTypeId]}
                otherComponent={
                  <GridDetailText title={translate('title.name')} value={productOrder?.Name} />
                }
              >
                {isShortMode ? (
                  <GridDetailText title={translate('title.name')} value={productOrder?.Name} />
                ) : (
                  <EditableGridDetailText
                    title={translate('title.name')}
                    onEdit={onEditName}
                    value={productOrder?.Name}
                    isIgnoreCheck
                  />
                )}
              </RoleBasedGuard>
              <RoleBasedGuard
                roles={[ManagementUserTypeId, SalesUserTypeId, ProductionControlUserTypeId]}
                otherComponent={
                  <GridDetailText title={translate('title.detail')} value={productOrder?.Detail} />
                }
              >
                {isShortMode ? (
                  <GridDetailText title={translate('title.detail')} value={productOrder?.Detail} />
                ) : (
                  <EditableGridDetailText
                    title={translate('title.detail')}
                    onEdit={onEditDetail}
                    value={productOrder?.Detail}
                  />
                )}
              </RoleBasedGuard>
              <GridDetailText
                title={`${translate('title.width')} x ${translate('title.length')}`}
                value={`${productOrder?.Width} x ${productOrder?.Length}`}
              />
              <GridDetailText
                title={translate('title.amount')}
                value={fNumber(productOrder?.Amount)}
              />
              <GridDetailText
                title={translate('title.customer')}
                value={productOrder?.Customer.Name}
                link={paths.sales.customer_detail(productOrder?.Customer.Id)}
              />
              <GridDetailText
                title={translate('title.create_by')}
                value={getEmployeeFullName(productOrder?.CreateEmployee)}
              />
              <GridDetailText
                title={translate('title.create_at')}
                value={fDateTimeText(productOrder?.createdAt)}
              />
              <GridDetailText
                title={translate('title.due_at')}
                value={fDateTimeText(productOrder?.DueDatedAt)}
              />
              <GridDetailText
                title={translate('title.status')}
                value={productOrder?.ProductOrderStatus.Name}
                color={getStatusTextColor(productOrder?.ProductOrderStatus.Id)}
              />
            </Grid>
          </CardContent>
        </Card>
        {!isShortMode && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <DetailWidget
                color={'info'}
                title={translate('title.amount_in')}
                total={getAmountIn()}
                icon={'gis:layer-stack'}
                chart={{
                  series: getAmountPercent(getAmountIn()).toFixed(2),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DetailWidget
                color={'success'}
                title={translate('title.amount_good')}
                total={getGoodAmount()}
                icon={'eva:checkmark-fill'}
                chart={{
                  series: getAmountPercent(getGoodAmount()).toFixed(2),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DetailWidget
                color={'error'}
                title={translate('title.amount_defect')}
                total={getDefectAmount()}
                icon={'eva:close-fill'}
                chart={{
                  series: getAmountPercent(getDefectAmount()).toFixed(2),
                }}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default ProductOrderDetail;
