import { handleLoadingTask, productionServerRequest } from '../utilities/Helper/NetworkHelper';
export async function getPriceChangeApproveForm(approveId) {
  const options = {
    method: 'get',
    url: `management/price-change/approve-form/${approveId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
export async function declineChangePrice(data) {
  let endPoint = `management/price-change/decline`;
  const options = {
    method: 'DELETE',
    url: endPoint,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function approveChangePrice(data) {
  let endPoint = `management/price-change/approve`;
  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}


export async function getProductOrderPriceHistoryDetailById(id) {
  const options = {
    method: 'get',
    url: `sales/product-order/product-price-history/detail/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}


export async function getProductOrderPriceHistoryByProductOrderId(productOrderId) {
  const options = {
    method: 'get',
    url: `sales/product-order/price-history/${productOrderId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}


export async function createChangeProductOrderPriceHistory(data) {
  const options = {
    method: 'POST',
    url: `sales/product-order/request-change-price`,
    data: data
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createCarActivity(data) {
  let endPoint = `logistic/create-car-activity`;
  if (data.IsTemp) {
    endPoint = `quick-request/car/create-car-activity`;
  }
  const options = {
    method: 'POST',
    url: endPoint,
    data: data
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getCarDetailById(detail) {
  let endPoint = `logistic/car/detail/${detail.CarId}`;
  if (detail.IsTemp) {
    endPoint = `quick-request/car/detail/${detail.CarId}`;
  }
  const options = {
    method: 'GET',
    url: endPoint,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createPileQualityProcess(data) {
  const options = {
    method: 'post',
    url: `planning//create-quality-process-to-piles`,
    data: data
  };

  const response = await productionServerRequest(options);
  return response.data;
}
export async function getProductionPDF() {
  const options = {
    method: 'get',
    url: `ai/report`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
export async function selectBOMForProduct(data) {
  const options = {
    method: 'PATCH',
    url: `product/bom/select-bom`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllActiveQuotation() {
  const options = {
    method: 'GET',
    url: `sales/po-quotation/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
export async function getReworkDetailById(reworkId) {
  const options = {
    method: 'get',
    url: `production/rework-order/detail/${reworkId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}
export async function getPartStockTransaction(params) {
  const options = {
    method: 'GET',
    url: `warehouse/stock-transaction-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
}

export async function getPartInventoryExcelFileURL() {
  const options = {
    method: 'GET',
    url: `warehouse/item-template-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
}

export async function getDownloadCreatePileExcelFileURL(params) {
  const options = {
    method: 'GET',
    url: `planning/create-pile-report-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
}

export async function getDownloadCreateProductOrderExcelFileURL(params) {
  const options = {
    method: 'GET',
    url: `planning/create-product-order-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
}

export const getDownloadApprovePileExcelFileURL = async (params) => {
  const options = {
    method: 'GET',
    url: `planning/approve-pile-report-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};
export const getMachinePartCostReport = async (params) => {
  const options = {
    method: 'GET',
    url: `maintenance/machine-part-cost`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};
export const getMaintenanceJobReport = async (params) => {
  const options = {
    method: 'GET',
    url: `maintenance/maintenance-job-report`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};
export const getMachinePerformanceExcel = async (params) => {
  const options = {
    method: 'GET',
    url: `production/report/machine-productivity-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};
export const getEmployeePerformanceExcel = async (params) => {
  const options = {
    method: 'GET',
    url: `production/report/employee-performance`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};

export const printCashDeliveryBillReportExcel = async (params) => {
  const options = {
    method: 'GET',
    url: `finance/cash-delivery-bill/summary`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};

export const printProductSellReportExcel = async (params) => {
  const options = {
    method: 'GET',
    url: `accounting/product-sell/summary/pdf`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};
export const printDeliverySummaryReportExcel = async (params) => {
  const options = {
    method: 'GET',
    url: `accounting/delivery-bill/summary/pdf`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};

export async function getCustomerAllProductPricing(customerId) {
  const options = {
    method: 'GET',
    url: `sales/customer-pricing/customer/${customerId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export const getSalesValueReportExcel = async (detail) => {
  const options = {
    method: 'GET',
    url: `sales/sales-value-report`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: {
      fromDate: detail.fromDate.toISOString(),
      toDate: detail.toDate.toISOString(),
      customerId: detail.customerId,
    },
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};
export const getCustomerSalesReportExcel = async (detail) => {
  const options = {
    method: 'GET',
    url: `sales/customer-report`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: {
      fromDate: detail.fromDate.toISOString(),
      toDate: detail.toDate.toISOString(),
      customerId: detail.customerId,
    },
    responseType: 'blob',
  };

  const { data } = await productionServerRequest(options);
  return data;
};

export async function createCustomerPricing(pricingDetail) {
  const options = {
    method: 'POST',
    url: `sales/customer-pricing/create`,
    data: {
      Detail: pricingDetail.Detail,
      ExpiredAt: pricingDetail.ExpiredAt,
      PricingDetail: pricingDetail.Products.map((detail) => {
        return {
          ProductId: detail.Product.Id,
          PricePerSheet: detail.Product.BasePrice,
          DiscountPercent: detail.Discount.Value,
          MinimumAmount: detail.MinimumAmount,
        };
      }),
      CustomerId: pricingDetail.CustomerId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get customer finance detail by id
 * @param {number | string} customerId - customer id
 */
export async function getCustomerFinanceDetailById(customerId) {
  const options = {
    method: 'GET',
    url: `sales/customer/finance-detail/${customerId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get Customer po by date
 */
export async function getCustomerPODetailByDate(customerId, date) {
  const options = {
    method: 'GET',
    url: `sales/customer/po-by-date`,
    params: {
      customerId: customerId,
      fromDate: date.fromDate,
      toDate: date.toDate,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function editCustomerName(data) {
  const options = {
    method: 'PATCH',
    url: `accounting/customer/edit-name`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getCustomerDetailById(customerId) {
  const options = {
    method: 'GET',
    url: `sales/customer/detail/${customerId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getSalesDashboardData(date) {
  const options = {
    method: 'get',
    url: `sales/dashboard`,
    params: {
      fromDate: JSON.stringify(date.fromDate),
      toDate: JSON.stringify(date.toDate),
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * remove special discount history by id
 * @param {number} id - special discount id
 * @returns {Object} - special discount history
 */
export async function deActivateSpecialDiscountPriceHistory(id) {
  const options = {
    method: 'DELETE',
    url: `management/special-discount/delete`,
    data: {
      SpecialDiscountPriceHistoryId: id,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get All Special discount history
 * @returns {Object} - special discount history
 */
export async function getAllSpecialDiscountHistory() {
  const options = {
    method: 'GET',
    url: `management/special-discount/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get employee detail from production server
 * @param {number} userId - employee user id.
 * @returns {function(*): *} - dispatch function
 */
export async function getEmployeeDetailByUserId(userId) {
  const options = {
    method: 'GET',
    url: `employee/find-by-user/${userId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getCreateSpecialDiscountRequireData() {
  const options = {
    method: 'GET',
    url: `management/create-special-discount-require-data`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createSpecialDiscount(data) {
  const options = {
    method: 'post',
    url: `management/special-discount/create`,
    data: data,
  };
  const response = productionServerRequest(options);
  return response.data;
}

///@todo: remove this function

export async function getProductByProductIds(id) {
  const options = {
    method: 'get',
    url: `product/find-by-ids?productIds=${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductTypeDetailById(id) {
  const options = {
    method: 'get',
    url: `product/product-type/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllProductType() {
  const options = {
    method: 'get',
    url: `product/product-type/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function solveProductOrderProblems(data) {
  const options = {
    method: 'PATCH',
    url: `production/product-order/solve-problem`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderProblemDetailById(id) {
  const options = {
    method: 'GET',
    url: `production/product-order/problem/${id}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllProductOrderProblemDetail(id) {
  const options = {
    method: 'GET',
    url: `production/product-order/problems/${id}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createProductOrderProblems(data) {
  const options = {
    method: 'POST',
    url: `production/product-order/create-problems`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllProblemDetail() {
  const options = {
    method: 'GET',
    url: `production/problem-detail/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export function changeProductSellHistoryToBundlePrice(productSellHistoryId, newPrice) {
  const options = {
    method: 'PATCH',
    url: `sales/product-order/edit-sell-price-bundle`,
    data: {
      ProductSellHistoryId: productSellHistoryId,
      NewPrice: newPrice,
    },
  };
  return productionServerRequest(options);
}

export async function approveCashDeliveryBill(data) {
  const options = {
    method: 'PATCH',
    url: `finance/cash-delivery-bill/approve`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get payment type options
 */
export async function getAllPaymentTypeOptions() {
  const options = {
    method: 'GET',
    url: `finance/payment-type/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getCashDeliveryBillDetail(billId) {
  const options = {
    method: 'GET',
    url: `finance/cash-delivery-bill/detail/${billId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllActiveCashDeliveryBills() {
  const options = {
    method: 'GET',
    url: `finance/all-active-cash-delivery-bill`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createFocusPO(productOrderId) {
  const options = {
    method: 'post',
    url: `production/create-focus-po`,
    data: {
      ProductOrderId: productOrderId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function removeFocusPO(productOrderId) {
  const options = {
    method: 'delete',
    url: `production/remove-focus-po`,
    data: {
      ProductOrderId: productOrderId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function changeMaintenanceTechnician(data) {
  const options = {
    method: 'patch',
    url: `maintenance/technician/change`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllMaintenanceTechnician() {
  const options = {
    method: 'get',
    url: `maintenance/all/technician`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function approveDiscountBill(billId) {
  const options = {
    method: 'PATCH',
    url: `sales/discount-bill/approve`,
    data: {
      DiscountBillId: billId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function deleteDiscountBill(billId) {
  const options = {
    method: 'DELETE',
    url: `sales/discount-bill/delete`,
    data: {
      DiscountBillId: billId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderDiscountBills(productOrderId) {
  const options = {
    method: 'GET',
    url: `sales/discount-bill/${productOrderId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createDiscountBill(data) {
  const options = {
    method: 'POST',
    url: `sales/discount-bill/create`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderQuotationPDFFile(poqId) {
  const options = {
    method: 'GET',
    url: `sales/po-quotation/pdf/${poqId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createProductOrderFromQuotation(data) {
  const options = {
    method: 'POST',
    url: `sales/po-quotation/create-po`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function deleteProductOrderQuotationById(id) {
  const options = {
    method: 'DELETE',
    url: `sales/po-quotation/delete`,
    data: {
      POQId: id,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function approveProductOrderQuotationById(id) {
  const options = {
    method: 'PATCH',
    url: `sales/po-quotation/approve`,
    data: {
      POQId: id,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderQuotationDetailById(id) {
  const options = {
    method: 'GET',
    url: `sales/po-quotation/detail/${id}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get customer product order quotation by id
 * @param {number | string} customerId - customer id
 */
export async function getAllCustomerProductOrderQuotation(customerId) {
  const options = {
    method: 'GET',
    url: `sales/po-quotation/customer/${customerId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createProductOrderQuotation(productDetail) {
  const options = {
    method: 'POST',
    url: `sales/po-quotation/create`,
    data: {
      Name: productDetail.Name,
      Detail: productDetail.Detail,
      Width: productDetail.Width,
      Length: productDetail.Length,
      Amount: productDetail.Amount,
      ExpiredAt: productDetail.ExpiredAt.toISOString(),
      CustomerId: productDetail.CustomerId,
      Discount: productDetail.Discount,
      Products: productDetail.Products.map((selectProduct, index) => {
        return {
          Id: selectProduct.Detail.Id,
          Price: selectProduct.Price.Price,
          IsBundle: selectProduct.Price.IsBundle,
          ProcessNumber: index,
        };
      }),
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getCustomerPricingPDF(customerPricingId) {
  const options = {
    method: 'GET',
    url: `sales/customer-pricing/pdf/${customerPricingId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function approvedCustomerPricing(customerPricingId) {
  const options = {
    method: 'PATCH',
    url: `sales/customer-pricing/approve`,
    data: {
      CustomerPricingId: customerPricingId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function removeProductFromCustomerPricing(detail) {
  const options = {
    method: 'DELETE',
    url: `sales/customer-pricing/product`,
    data: detail,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function removeCustomerPricing(customerPricingId) {
  const options = {
    method: 'DELETE',
    url: `sales/customer-pricing/delete`,
    data: {
      CustomerPricingId: customerPricingId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getCustomerPricingDetail(id) {
  const options = {
    method: 'GET',
    url: `sales/customer-pricing/detail/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all product
 */
export async function getAllProduct() {
  const options = {
    method: 'GET',
    url: `utilities/all-product`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getStockReportById(id = 0) {
  const options = {
    method: 'get',
    url: `warehouse/stock-report/detail/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export function getStockReportPDFById(id = 0) {
  return async (dispatch) => {
    const options = {
      method: 'get',
      url: `warehouse/stock-report/pdf/${id}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get machine detail
 */
export async function getMachineDashboardDetail(params) {
  const options = {
    method: 'get',
    url: `production/machine/dashboard`,
    params: params,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * sort machine queue
 * @param {array} machineQueueDetails - browse machine id
 */
export function sortMachineQueues(machineQueueDetails) {
  return async (dispatch) => {
    const options = {
      method: 'PATCH',
      url: `planning/sort-machine-queue`,
      data: {
        QueueDetails: machineQueueDetails,
      },
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * save edit employee performance data
 */
export async function editEmployeePerformanceData(data) {
  const options = {
    method: 'patch',
    url: `production/save-edit-employee-performance-data`,
    data: {
      Id: data.Id,
      MachineId: data.Machine.Id,
      CreateEmployeeId: data.CreateEmployee.Id,
      GoodAmount: data.GoodAmount,
      DefectAmount: data.DefectAmount,
      TotalPileAmount: data.TotalPileAmount,
      TotalPileCount: data.TotalPileCount,
      WorkingTime: data.WorkingTime,
      AveragePerHour: data.AveragePerHour,
      ShiftId: data.Shift.Id,
      ProductTypeId: data.ProductType.Id,
      StartTimeAt: data.StartTimeAt.toISOString(),
      EndTimeAt: data.EndTimeAt.toISOString(),
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get edit employee performance record page
 */
export async function getAllEmployeePerformanceData() {
  const options = {
    method: 'get',
    url: `utilities/all-employee-performance-data`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all active
 * @returns {function(*): Promise<*>} - all customer
 */
export async function getAllActiveCustomerOptions() {
  const options = {
    method: 'GET',
    url: `sales/all-customer-options`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
export async function getAllQualityProcessOptions() {
  const options = {
    method: 'GET',
    url: `planning/all-quality-process-options`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
/**
 * print machine plan
 * @param {number} machineId - browse machine id
 */
export function printMachinePlan(machineId) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/machine-production-plan-pdf/${machineId}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get machine plan by id
 * @param {number} machineId - browse machine id
 */
export function getMachinePlanById(machineId) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/get-machine-plan/${machineId}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get all machine with production plan
 */
export function getAllMachineWithProductionPlan() {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/get-all-machine-with-plan`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * remove Product Order Planning from machine
 * @param {number} removeId - product order planning id
 */
export function removeProductOrderPlanningFromMachine(removeId) {
  return async (dispatch) => {
    const options = {
      method: 'PATCH',
      url: `planning/remove-pop-from-machine`,
      data: {
        RemoveProductOrderPlanningId: removeId,
      },
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * assign plan to machine
 * @param {number} productOrderId - product order id
 * @param {number} sellHistoryId - sell history id
 * @param {number} processId - process id
 * @param {number} productHasProcessId - product has process id
 * @param {number} assignMachineId - assign machine id
 */
export function createProductionPlanWithMachine(
  productOrderId,
  sellHistoryId,
  processId,
  productHasProcessId,
  assignMachineId
) {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      url: `planning/create-planning-for-po-with-machine`,
      data: {
        ProductOrderId: productOrderId,
        ProductSellHistoryId: sellHistoryId,
        ProductionProcessId: processId,
        ProductHasProductionProcessId: productHasProcessId,
        MachineId: assignMachineId,
      },
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get machine run process id
 * @param {number} processId - production process id
 */
export function getMachineRunProductionProcessId(processId) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/get-machine-run-process/${processId}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get Today PO
 */
export function getInMachinePlan() {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/today-po-machine-plan`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * assign plan to machine
 * @param {number} planningId - product order planning id
 * @param {number} machineId - machine id
 */
export function assignPlanToMachine(planningId, machineId) {
  return async (dispatch) => {
    const options = {
      method: 'PATCH',
      url: `planning/assign-plan-to-machine`,
      data: {
        PlanningId: planningId,
        MachineId: machineId,
      },
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get machine run Planning id
 * @param {number} planningId - product order planning id
 */
export function getMachineRunPlanningId(planningId) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/get-machine-run-planning/${planningId}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * remove Product Order Planning
 * @param {number} removeId - product order planning id
 */
export function removeProductOrderPlanning(removeId) {
  return async (dispatch) => {
    const options = {
      method: 'DELETE',
      url: `planning/delete-pop`,
      data: {
        DeleteProductOrderPlanningId: removeId,
      },
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get Today PO
 */
export function getAllPrePlanPO() {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/pre-plan-po`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * get Today PO
 */
export async function createPlanningForPO(data) {
  const options = {
    method: 'POST',
    url: `planning/create-planning-for-po`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get Today PO
 */
export async function getTodayPO() {
  const options = {
    method: 'GET',
    url: `planning/today-po`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * getMonth PO
 */
export async function getMonthPO() {
  const options = {
    method: 'GET',
    url: `planning/month-po`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function searchProductOrderByName(searchString) {
  const options = {
    method: 'GET',
    url: `production/search-po-name/${searchString}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderAdditionalCostBillPDF(id) {
  const options = {
    method: 'GET',
    url: `accounting/additional-cost-bill/pdf/${id}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelProductOrderAdditionalCostBillById(id) {
  const options = {
    method: 'DELETE',
    url: `accounting/additional-cost-bill/cancel`,
    data: {
      ProductOrderAdditionalCostBillId: id,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderAdditionalCostBillDetails(id) {
  const options = {
    method: 'GET',
    url: `accounting/additional-cost-bill/detail/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createAdditionalCostBill(data) {
  const options = {
    method: 'POST',
    url: `accounting/additional-cost-bill/create`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all additional cost
 */
export async function getAllAdditionalCost() {
  const options = {
    method: 'GET',
    url: `utilities/all-additional-cost`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * search number match
 * @param {string} searchString - searchString
 */
export function getNumberMatchSearch(searchString) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `utilities/search-number/${searchString}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * search string match
 * @param {string} searchString - searchString
 */
export function getStringMatchSearch(searchString) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `utilities/search/${searchString}`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

export async function editSupplierSellItemTemplateDetail(data) {
  const options = {
    method: 'PATCH',
    url: `supplier/supplier-sell-item-template/edit`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function changeProductSellHistoryPrice(data) {
  const options = {
    method: 'PATCH',
    url: `sales/product-order/edit-sell-price`,
    data: data
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function changeProductOrderSize(data) {
  const options = {
    method: 'PATCH',
    url: `sales/product-order/edit-size`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function changeProductOrderAmount(data) {
  const options = {
    method: 'PATCH',
    url: `sales/product-order/edit-amount`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createEmployeeRecord(data) {
  const options = {
    method: 'POST',
    url: `employee/create-employee-record`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function saveEmployeePenaltyRecord(data) {
  const options = {
    method: 'POST',
    url: `employee/create-penalty-report`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all penalty level
 */
export async function getAllPenaltyLevel() {
  const options = {
    method: 'GET',
    url: `utilities/all-penalty-level`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function deactivateEmployee(employeeCode) {
  const options = {
    method: 'PATCH',
    url: `employee/deactivate`,
    data: {
      EmployeeCode: employeeCode,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function bindEmployeeWithUserId(data) {
  const options = {
    method: 'PATCH',
    url: `employee/bind-user`,
    data: data
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createEmployee(data) {
  const options = {
    method: 'POST',
    url: `employee/create`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getPartSupplierDetailById(id) {
  const options = {
    method: 'GET',
    url: `supplier/detail/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function editPartSupplier(data) {
  const options = {
    method: 'PATCH',
    url: `supplier/edit`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllPartsSupplier() {
  const options = {
    method: 'GET',
    url: `utilities/all-supplier`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}
export async function createPartSupplier(data) {
  const options = {
    method: 'POST',
    url: `supplier/create`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getSupplierSellItemTemplateById(id = 0) {
  const options = {
    method: 'GET',
    url: `supplier/supplier-sell-item-template/detail/${id}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
export async function getAllItemTemplateUnit() {
  const options = {
    method: 'GET',
    url: `utilities/all-item-template-unit`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}
export async function getItemTemplateSellerList(itemTemplateId) {
  const options = {
    method: 'GET',
    url: `procurement/item-template-seller-list/${itemTemplateId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelPRItemTemplate(prItemTemplateId) {
  const options = {
    method: 'PATCH',
    url: `procurement/pr-item-template/cancel`,
    data: {
      PurchaseRequisitionItemTemplateId: prItemTemplateId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all part to purchase
 */
export async function getPartsToPurchases() {
  const options = {
    method: 'GET',
    url: `procurement/parts-to-purchase`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all part po
 */
export async function getAllActivePartPO() {
  const options = {
    method: 'GET',
    url: `procurement/po-parts/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all part purchase requisition
 */
export async function getAllPartsPurchaseRequisition() {
  const options = {
    method: 'GET',
    url: `procurement/pr-parts/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getItemTemplateStockTransactionData(itemTemplateId, params) {
  const options = {
    method: 'GET',
    url: `warehouse/item-template/stock-transaction/${itemTemplateId}`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * upload item template image
 */
export async function uploadItemTemplateImage(data) {
  const formData = new FormData();
  formData.append('Id', data.Id);
  formData.append('image', data.Image);
  const options = {
    method: 'POST',
    url: `warehouse/upload-image`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllItemTemplateBySafety() {
  const options = {
    method: 'GET',
    url: `warehouse/item-template/all/safety`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelRequestReport(itemTemplateRequestReportId) {
  const options = {
    method: 'PATCH',
    url: `warehouse/item-template-request-report/cancel`,
    data: {
      ItemTemplateRequestReportId: itemTemplateRequestReportId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllItemTemplateRequestReports() {
  const options = {
    method: 'GET',
    url: `warehouse/item-template-request-report/all`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get quick request items
 */
export async function getQuickRequestItems() {
  const options = {
    method: 'GET',
    url: `quick-request/all-quick-request-item-list`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createLogisticCar(data) {
  const options = {
    method: 'POST',
    url: `logistic/create-car`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all car type
 */
export async function getAllCarType() {
  const options = {
    method: 'GET',
    url: `utilities/all-car-type`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createDeliveryBillFromPiles(data) {
  let endPoint = `accounting/create-delivery-bill`;
  if (data.IsTemp) {
    endPoint = `quick-request/create-delivery-bill`;
  }

  const options = {
    method: 'POST',
    url: endPoint,
    data: {
      CheckoutProductOrders: data.CheckoutProductOrders,
      CreateEmployeeId: data.CreateEmployeeId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function unHoldPileWithProblems(data) {
  const options = {
    method: 'PATCH',
    url: `quality/un-hold-pile`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function holdPileWithProblems(data) {
  const options = {
    method: 'PATCH',
    url: `quality/hold-pile`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllPileProblemHistory(pileId) {
  const options = {
    method: 'GET',
    url: `quality/hold-problem-history/${pileId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllProblemOfPile(pileId) {
  const options = {
    method: 'GET',
    url: `quality/hold-problem-lists/${pileId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function approvePileQuality(data) {
  const options = {
    method: 'PATCH',
    url: `quality/check-pile-quality`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllActiveProblems(params) {
  const options = {
    method: 'GET',
    url: `quality/all-hold-piles`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getQualityDashboardData(params) {
  const options = {
    method: 'GET',
    url: `quality/dashboard`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get qa dashboard
 */
export async function getQualityYearDashboardData() {
  const options = {
    method: 'GET',
    url: `quality/dashboard-year`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createReworkOrderByPlanning(data) {
  const options = {
    method: 'POST',
    url: `planning/create-rework-order`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all production process
 */
export async function getAllProductionProcess() {
  const options = {
    method: 'GET',
    url: `utilities/all-production-process`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function approveAmountProblemPile(data) {
  const options = {
    method: 'PATCH',
    url: `planning/approve-pile-amount`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function printMachineProductionQueue(machineId) {
  const options = {
    method: 'GET',
    url: `planning/machine-plan-pdf/${machineId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function removeMachineProductionQueue(queueId) {
  const options = {
    method: 'PATCH',
    url: `planning/remove-queue-from-machine-queue`,
    data: {
      MachineQueueId: queueId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function moveMachineProductionQueue(data) {
  const options = {
    method: 'PATCH',
    url: `planning/swap-production-record-queue`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getMachineProductionQueue(machineId) {
  const options = {
    method: 'GET',
    url: `planning/machine-queue/detail/${machineId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function assignProductOrderToMachine(data) {
  const options = {
    method: 'POST',
    url: `planning/assign-product-order`,
    data: {
      ProductOrderId: data.ProductOrderId,
      MachineId: data.Machine.Id,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all planning info
 */
export async function getAllPlanningInfo() {
  const options = {
    method: 'GET',
    url: `planning/all-planning-information`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function evaluateMaintenanceJob(data) {
  const options = {
    method: 'PATCH',
    url: `maintenance/evaluate-job`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getMaintenanceJobDetailById(jobId) {
  const options = {
    method: 'GET',
    url: `maintenance/details/${jobId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function holdMaintenanceJobStatus(data) {
  const options = {
    method: 'PATCH',
    url: `maintenance/hold-job`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function archiveMaintenanceJob(data) {
  const options = {
    method: 'PATCH',
    url: `maintenance/archive-job`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function changeMaintenanceJobStatus(data) {
  const options = {
    method: 'PATCH',
    url: `maintenance/change-maintenance-job-status`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function completeMaintenanceJob(data) {
  const options = {
    method: 'PATCH',
    url: `maintenance/complete-job`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function acceptMaintenanceJob(data) {
  const options = {
    method: 'PATCH',
    url: `maintenance/accept-job`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all maintenance job type
 */
export async function getAllMaintenanceJobType() {
  const options = {
    method: 'GET',
    url: `utilities/all-maintenance-job-type`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllEmployeeFromDepartment(departmentId) {
  const options = {
    method: 'GET',
    url: `employee/find-by-employee-by-department/${departmentId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllMaintenanceJobWithStatusId(maintenanceJobStatusId) {
  const options = {
    method: 'GET',
    url: `maintenance/all/status?workStatusId=${maintenanceJobStatusId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllMaintenanceJobForEmployeeId(employeeId) {
  const options = {
    method: 'GET',
    url: `maintenance/all-job/${employeeId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function resumeMachine(machineId) {
  const options = {
    method: 'PATCH',
    url: `maintenance/machine/resume`,
    data: {
      MachineId: machineId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function breakMachine(machineId) {
  const options = {
    method: 'PATCH',
    url: `maintenance/machine/break`,
    data: {
      MachineId: machineId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get maintenance dashboard
 */
export async function getMaintenanceDashboard() {
  const options = {
    method: 'GET',
    url: `maintenance/dashboard`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createProductionProcess(data) {
  const options = {
    method: 'POST',
    url: `production/create-process`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function submitProductionRecord(data) {
  let endPoint = `production/pile/submit-production-record`;
  if (data.IsTemp) {
    endPoint = `quick-request/pile/submit-production-record`;
  }

  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllSubmitPileRequireInfo(data) {
  let endPoint = `production/pile/submit-production-record-detail/${data.PileId}`;
  if (data.isTemp) {
    endPoint = `quick-request/pile/submit-production-record-detail/${data.PileId}`;
  }

  const options = {
    method: 'get',
    url: endPoint,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function editProductOrderDetail(data) {
  const options = {
    method: 'PATCH',
    url: `sales/product-order/edit-detail`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function editProductOrderName(data) {
  const options = {
    method: 'PATCH',
    url: `sales/product-order/edit-name`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelProductOrder(productOrderId) {
  const options = {
    method: 'patch',
    url: `sales/product-order/cancel`,
    data: {
      ProductOrderId: productOrderId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * complete product order
 * @param {number} productOrderId - productOrderId
 * @returns {Object} - response
 */

export async function completeProductOrder(productOrderId) {
  const options = {
    method: 'patch',
    url: `sales/product-order/complete`,
    data: {
      ProductOrderId: productOrderId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductOrderDeliveryReport(productOrderId) {
  const options = {
    method: 'get',
    url: `production/product-order/print-delivery-report/${productOrderId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductionEmployeeDashboardData(params) {
  const options = {
    method: 'get',
    url: `production/employee/dashboard`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductionMachineDashboardData(params) {
  const options = {
    method: 'get',
    url: `production/dashboard/machine`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductionEmployeePerformanceDashboardData(params) {
  const options = {
    method: 'get',
    url: `production/dashboard/employee-performance`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function breakCustomer(data) {
  const options = {
    method: 'PATCH',
    url: `accounting/customer/break`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getProductSummaryPDFFile(params) {
  const options = {
    method: 'get',
    url: `accounting/product-sell/summary/pdf`,
    params: params,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getDeliverySummaryPDFFile(params) {
  const options = {
    method: 'get',
    url: `accounting/delivery-bill/summary/pdf`,
    params: params,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createClaimRecord(data) {
  const options = {
    method: 'post',
    url: `accounting/create-claim-record`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelBillingNoteById(billingNoteId) {
  const options = {
    method: 'PATCH',
    url: `accounting/billing-note/cancel`,
    data: {
      BillingNoteId: billingNoteId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getBillingNotePDFById(billingNoteId) {
  const options = {
    method: 'get',
    url: `accounting/billing-note/pdf/${billingNoteId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get billing note detail by id
 * @param {number| string} billingNoteId - billing note id
 * @returns {Object} - billing note detail
 */

export async function getBillingNoteDetailById(billingNoteId) {
  const options = {
    method: 'get',
    url: `accounting/billing-note/detail/${billingNoteId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelDeliveryBillById(deliveryBillId) {
  const options = {
    method: 'PATCH',
    url: `accounting/delivery-bill/cancel`,
    data: {
      DeliveryBillId: deliveryBillId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelExampleDeliveryBillById(deliveryBillId) {
  const options = {
    method: 'PATCH',
    url: `accounting/delivery-bill/cancel-example`,
    data: {
      DeliveryBillId: deliveryBillId,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get delivery bill PDF by id
 * @param {number| string} deliveryBillId - delivery bill id
 * @returns {Object} - delivery bill PDF structure
 */

export async function getDeliveryBillPDFById(deliveryBillId) {
  const options = {
    method: 'get',
    url: `accounting/delivery-bill/pdf/${deliveryBillId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get delivery bill detail by id
 * @param {number| string} deliveryBillId - delivery bill id
 * @returns {Object} - delivery bill detail
 */

export async function getDeliveryBillDetailById(deliveryBillId) {
  const options = {
    method: 'get',
    url: `accounting/delivery-bill/detail/${deliveryBillId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}
export async function cancelPile(pileId) {
  const options = {
    method: 'patch',
    url: `production/pile/cancel`,
    data:{
      PileId: pileId
    }
  };
  const response = await productionServerRequest(options);
  return response.data;
}
/**
 * get pile PDF by id
 * @param {number} pileId - pile id
 * @returns {Object} - pdf structure
 */

export async function getPilePDF(pileId) {
  const options = {
    method: 'get',
    url: `production/get-pile-pdf/${pileId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createBillingNote(data) {
  const options = {
    method: 'post',
    url: `accounting/billing-note/create`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all delivery bills of customer
 * @param {number} customerId - customer id
 * @returns {Object} - all delivery bill that not bill yet
 */

export async function getAllActiveDeliveryNoteByCustomerId(customerId) {
  const options = {
    method: 'get',
    url: `accounting/delivery-bills/customer/${customerId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createAdditionalCost(data) {
  const options = {
    method: 'post',
    url: `sales/create-additional-cost`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all additional cost type
 */
export async function getAllAdditionalCostType() {
  const options = {
    method: 'GET',
    url: `utilities/all-additional-cost-type`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all production process type
 */
export async function getAllProductionProcessType() {
  const options = {
    method: 'GET',
    url: `utilities/all-production-process-type`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createProduct(productDetail) {
  const options = {
    method: 'post',
    url: `product/create`,
    data: {
      Name: productDetail.Name,
      Detail: productDetail.Detail !== undefined ? productDetail.Detail : '',
      BasePrice: productDetail.BasePrice,
      TargetPerMonth: productDetail.TargetPerMonth,
      MinimumPrice: productDetail.MinimumPrice,
      IsTaxable: productDetail.IsTaxable,
      IsDiscountable: productDetail.IsDiscountable,
      ProductProcessIds: productDetail.Process.map((process) => process.Id),
      ProductTypeId: productDetail.ProductType.Id,
      MinimumAmount: productDetail.MinimumAmount,
      MinimumValue: productDetail.MinimumValue,
      BundlePrice: productDetail.BundlePrice,
      PricePerSquareInch: productDetail.PricePerSquareInch,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function editProductDetail(data) {
  const options = {
    method: 'patch',
    url: `product/edit`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all create product detail
 * @returns {Object} - details
 */
export async function getAllCreateProductPageRequireData() {
  const options = {
    method: 'get',
    url: `utilities/all-create-product`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get product detail by product id
 * @param {number || string} productId - product id
 * @returns {Object} - product detail
 */
export async function getProductDetailById(productId) {
  const options = {
    method: 'get',
    url: `sales/product/detail/${productId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get all customer sales history
 * @param {number} customerId - customer id
 * @returns {Object} - sales history
 */
export function getAllCustomerSellHistory(customerId) {
  return async (dispatch) => {
    const options = {
      method: 'get',
      url: `sales/product/all/${customerId}`,
    };
    return dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

export async function createCustomer(customerDetail) {
  const options = {
    method: 'POST',
    url: `accounting/create-customer`,
    data: {
      Name: customerDetail.Name,
      AdditionAddress: customerDetail.AdditionAddress,
      DistrictId: customerDetail.District.Id,
      TaxRegistrationNumber: customerDetail.TaxRegistrationNumber,
      CreditDays: 0,
      TelephoneNumber: customerDetail.TelephoneNumber,
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get Product Order detail by id
 * @param {number || string} productOrderId - product order id
 * @returns {Object} - create customer
 */
export async function getProductOrderDetailById(productOrderId) {
  const options = {
    method: 'get',
    url: `sales/product-order/detail/${productOrderId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createPileFromProductOrderId(data) {
  const options = {
    method: 'post',
    url: `production/create-pile`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all product sell history of customer
 * @param {number} customerId - customer id.
 * @returns {Object} - all product sell history
 */
export async function getAllProductSalesHistory(customerId) {
  const options = {
    method: 'GET',
    url: `sales/product/all/${customerId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all customer
 * @returns {function(*): Promise<*>} - all customer
 */
export async function getAllCustomer() {
  const options = {
    method: 'GET',
    url: `utilities/all-customer`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all customer without archive customer
 * @returns {function(*): Promise<*>} - all customer
 */
export function getAllCustomerWithoutArchive() {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `utilities/all-customer-without-break`,
    };

    return dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

/**
 * Get all product order subtype
 * @returns {Object} - all product order subtype
 */
export async function getAllProductOrderSubType() {
  const options = {
    method: 'GET',
    url: `utilities/all-product-order-sub-type`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all address in thailand
 * @returns {Object} - all product order subtype
 */
export async function getAllAddressDetail() {
  const options = {
    method: 'GET',
    url: `utilities/all-address-detail`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createProductOrder(productDetail) {
  const options = {
    method: 'POST',
    url: `sales/create-product-order`,
    data: {
      Name: productDetail.Name,
      Detail: productDetail.Detail,
      Width: productDetail.Width,
      Length: productDetail.Length,
      Amount: productDetail.Amount,
      DueDatedAt: productDetail.DueDatedAt.toISOString(),
      ProductOrderSubTypeId: productDetail.ProductOrderSubType.Id,
      CustomerId: productDetail.CustomerId,
      SelectProducts: productDetail.Products.map((selectProduct) => {
        return {
          Id: selectProduct.Detail.Id,
          Price: selectProduct.Price.Price,
          IsBundle: selectProduct.Price.IsBundle,
        };
      }),
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get Product Order detail by id
 * @param {number || string} productOrderId - product order id
 * @returns {Object} - create customer
 */
export async function getProductOrderDetailWithProcessById(productOrderId) {
  const options = {
    method: 'get',
    url: `sales/product-order/detail-with-process/${productOrderId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllActiveProductOrder() {
  const options = {
    method: 'GET',
    url: `production/product-order/all`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * @typedef {Object} MainDashboardData
 * @property {Object} SalesData - Sales Data
 * @property {Object} ProductionData - Production Data
 */
/**
 * Get main dashboard data
 */
export async function getMainProductionDashboardData() {
  const options = {
    method: 'GET',
    url: `production/dashboard/main`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get Management Sales Dashboard
 */
export async function getManagementSalesDashboard(detail) {
  const options = {
    method: 'GET',
    url: `analytic/sales/dashboard`,
    params: {
      fromDate: JSON.stringify(detail.fromDate),
      toDate: JSON.stringify(detail.toDate),
    },
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * get Planning Product Orders
 */
export function getPlanningProductOrder() {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `planning/plan`,
      params: {
        month: 5,
      },
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}

export async function getPlanningDashboard(params) {
  const options = {
    method: 'GET',
    url: `planning/planning-dashboard`,
    params: params,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function editPileReferenceId(data) {
  let endPoint = `planning/edit-pile-reference-id`;
  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function changePileAmount(data) {
  let endPoint = `production/pile/edit-amount`;
  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function changePileGoodAmount(data) {
  let endPoint = `production/pile/edit-good-amount`;
  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getPileDetailById(detail) {
  let endPoint = `production/pile/detail/${detail.PileId}`;
  if (detail.IsTemp) {
    endPoint = `quick-request/pile/detail/${detail.PileId}`;
  }
  const options = {
    method: 'GET',
    url: endPoint,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function createMaintenanceJob(data) {
  let endPoint = `maintenance/create`;
  if (data.IsTemp) {
    endPoint = `quick-request/create-mt-job`;
  }
  const options = {
    method: 'POST',
    url: endPoint,
    data: {
      Detail: data.Detail,
      MachineId: data.MachineId,
      MaintenanceDetailId: data.MaintenanceDetailId,
      MaintenanceJobTypeId: 5,
      CreateEmployeeId: data.CreateEmployeeId,
      BookedAt: new Date(),
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllMachineMaintenanceDetail(data) {
  let endPoint = `maintenance/all-maintenance-detail/${data.MachineId}`;
  if (data.IsTemp) {
    endPoint = `quick-request/all-maintenance-detail/${data.MachineId}`;
  }
  const options = {
    method: 'GET',
    url: endPoint,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllItemTemplateByCategoryId(categoryId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-template/all/category/${categoryId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getInventoryDetailById(inventoryId) {
  const options = {
    method: 'GET',
    url: `warehouse/inventory/detail/${inventoryId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function moveItemToShelf(data) {
  const options = {
    method: 'PATCH',
    url: `warehouse/move-shelf`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createSupplierSellItemTemplate(data) {
  const options = {
    method: 'POST',
    url: `supplier/create-sell-item-template`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getInventoryShelfById(inventoryShelfId) {
  const options = {
    method: 'GET',
    url: `warehouse/shelf/detail/${inventoryShelfId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function returnItemToWarehouse(data) {
  const options = {
    method: 'PATCH',
    url: 'warehouse/return-items',
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function acceptStockReport(data) {
  let endPoint = `warehouse/accept-stock-report`;
  if (data.IsTemp) {
    endPoint = `quick-request/accept-stock-report`;
  }
  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelItemTemplateRequestReport(itemTemplateRequestReportId) {
  const options = {
    method: 'PATCH',
    url: 'warehouse/item-template-request-report/cancel',
    data: {
      ItemTemplateRequestReportId: itemTemplateRequestReportId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function printStockReportPDF(stockReportId) {
  const options = {
    method: 'GET',
    url: `warehouse/stock-report/pdf/${stockReportId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createStockReport(data) {
  const options = {
    method: 'POST',
    url: 'warehouse/stock-report/create',
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getItemTemplateRequestReportPDF(itemTemplateRequestReportId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-template-request-report/pdf/${itemTemplateRequestReportId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getItemTemplateRequestReportDetailById(itemTemplateRequestReportId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-template-request-report/detail/${itemTemplateRequestReportId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function requestItemTemplateFromStore(data) {
  let endPoint = `warehouse/create-request-or-purchase-items`;
  if (data.IsTemp) {
    endPoint = `quick-request/create-request-purchase-items`;
  }

  const options = {
    method: 'POST',
    url: endPoint,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function editItemTemplateAmount(data) {
  const options = {
    method: 'PATCH',
    url: `warehouse/item-template/edit-amount`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function toggleQuickRequestItem(data) {
  const options = {
    method: 'PATCH',
    url: `warehouse/add-item-template-to-quick-request`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function editItemTemplateDetail(data) {
  const options = {
    method: 'PATCH',
    url: `warehouse/item-template/edit`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getItemTemplateDetailById(id = 0) {
  const options = {
    method: 'GET',
    url: `warehouse/item-template/detail/${id}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllIncomingItemTemplate() {
  const options = {
    method: 'GET',
    url: `warehouse/incoming-item-template`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function acceptPOItemTemplateToStore(data) {
  const options = {
    method: 'POST',
    url: `warehouse/accept-po-item-template`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function editPartPOReferenceId(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-parts/edit-reference-id`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function editPartPODetail(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-parts/edit-detail`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Upload part po
 */
export async function uploadApprovePartsPODocument(data) {
  const formData = new FormData();
  formData.append('Id', data.Id);
  formData.append('Document', data.Document);
  formData.append('UploadEmployeeId', data.UploadEmployeeId);

  const options = {
    method: 'PATCH',
    url: `procurement/po-parts/upload-approve-po-parts`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelPartPO(poId = 0) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-parts/cancel`,
    data: {
      PurchaseOrderId: poId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getPartPOPDFFile(poId) {
  const options = {
    method: 'GET',
    url: `procurement/po-parts/print/${poId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getPartPODetailByID(poId) {
  const options = {
    method: 'GET',
    url: `procurement/po-parts/detail/${poId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function editPOItemTemplate(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-edit-item`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createPartPO(data) {
  const options = {
    method: 'POST',
    url: `procurement/po-parts/create`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get part PR detail
 * @param {number|string} prId - PR Id.
 */
export async function getPartPRDetailByID(prId) {
  const options = {
    method: 'GET',
    url: `procurement/pr-parts/detail/${prId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getPartPRPDFFile(prId) {
  const options = {
    method: 'GET',
    url: `procurement/pr-parts/print/${prId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function approvePartPR(prId = 0) {
  const options = {
    method: 'PATCH',
    url: `procurement/pr-parts/approve`,
    data: {
      Id: prId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function cancelPartPR(prId = 0) {
  const options = {
    method: 'PATCH',
    url: `procurement/pr-parts/cancel`,
    data: {
      Id: prId,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createItemTemplatePR(data) {
  const options = {
    method: 'POST',
    url: `procurement/pr-parts/create`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllInventoryStore() {
  const options = {
    method: 'GET',
    url: `utilities/all-inventory-store`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createInventoryShelf(data) {
  const options = {
    method: 'POST',
    url: `warehouse/shelf/create`,
    data: data,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createInventoryStore(name) {
  const options = {
    method: 'POST',
    url: `warehouse/store/create`,
    data: {
      Name: name,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function createItemTemplateUnit(name) {
  const options = {
    method: 'POST',
    url: `warehouse/create-item-template-unit`,
    data: {
      Name: name,
    },
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Create new item template
 */
export async function createItemTemplate(data) {
  const options = {
    method: 'POST',
    url: `warehouse/item-template/create`,
    data: data,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all machine
 */
export async function getAllMachine(isTemp = false) {
  let endPoint = `utilities/all-machine`;
  if (isTemp) {
    endPoint = `quick-request/all-machine`;
  }

  const options = {
    method: 'GET',
    url: endPoint,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllProductionMachine(isTemp = false) {
  let endPoint = `utilities/all-production-machine`;
  if (isTemp) {
    endPoint = `quick-request/all-production-machine`;
  }

  const options = {
    method: 'GET',
    url: endPoint,
  };

  const response = await productionServerRequest(options);
  return response.data;
}
export async function getAllCreateItemTemplateData() {
  const options = {
    method: 'GET',
    url: `utilities/all-create-item-template-info`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getEmployeeDetailByEmployeeCode(employeeCode) {
  const options = {
    method: 'GET',
    url: `quick-request/find-by-employee-code/${employeeCode}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get all employee detail from production server
 */
export async function getAllEmployee() {
  const options = {
    method: 'GET',
    url: `utilities/all-employee`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getAllEmployeeDepartment() {
  const options = {
    method: 'GET',
    url: `utilities/all-employee-department`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

export async function getEmployeeDetailById(employeeId) {
  const options = {
    method: 'GET',
    url: `employee/detail/${employeeId}`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Get maintenance employee detail
 * @param {number} employeeId - employee id.
 */
export async function getMaintenanceEmployeeDetail(employeeId) {
  const options = {
    method: 'GET',
    url: `maintenance/get-maintenance-employee-detail/${employeeId}`,
  };

  const response = await productionServerRequest(options);
  return response.data;
}

export async function deletePRItemTemplate(data) {
  const options = {
    method: 'DELETE',
    data: data,
    url: `procurement/pr-parts/delete-item-template`,
  };
  const response = await productionServerRequest(options);
  return response.data;
}

/**
 * Delete pr temp item template from pr
 * @param {number} tempItemTemplateId - Temp item template id.
 */
export function deletePRTempItemTemplate(tempItemTemplateId) {
  return async (dispatch) => {
    const options = {
      method: 'DELETE',
      data: {
        PurchaseRequisitionTempItemTemplateId: tempItemTemplateId,
      },
      url: `procurement/pr-parts/delete-temp-item-template`,
    };
    return await dispatch(handleLoadingTask(productionServerRequest(options)));
  };
}
