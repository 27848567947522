import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import GeneralTable from '../customs/GeneralTable';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import {
  approveDiscountBill,
  deleteDiscountBill,
  getProductOrderDiscountBills,
} from '../../requests/ProductionServerHandler';
import { fDateTimeText } from '../../utilities/formatTime';
import {
  OpenConfirmNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { fCurrency } from '../../utilities/formatNumber';
import { paths } from '../../routes/paths';
import { OPEN_ICON } from '../../config-global';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

ProductOrderDiscountDetail.propTypes = {
  productOrderId: PropTypes.number.isRequired,
};

const useProductOrderDiscountBills = (productOrderId) => {
  const queryClient = useQueryClient();
  const { data: discountBills } = useQuery({
    queryKey: ['getProductOrderDiscountBills', productOrderId],
    queryFn: () => getProductOrderDiscountBills(productOrderId),
    initialData: [],
  });

  const deleteMutation = useMutation({
    mutationFn: deleteDiscountBill,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getProductOrderDiscountBills', productOrderId]);
      await OpenSuccessNotification();
    },
  });

  const approveMutation = useMutation({
    mutationFn: approveDiscountBill,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getProductOrderDiscountBills', productOrderId]);
      await OpenSuccessNotification();
    },
  });
  return {
    discountBills,
    deleteBill: deleteMutation,
    approveBill: approveMutation,
  };
};

function ProductOrderDiscountDetail({ productOrderId }) {
  const { translate } = useLocales();
  const { discountBills, deleteBill, approveBill } = useProductOrderDiscountBills(productOrderId);

  const onDeleteDiscountBill = async (billId) => {
    await OpenConfirmNotification(
      translate('warning.confirm_delete_discount'),
      translate('button.confirm'),
      '',
      async () => {
        await deleteBill.mutateAsync(billId);
      }
    );
  };
  const onApproveDiscountBill = async (billId) => {
    await OpenConfirmNotification(
      translate('warning.confirm_approve_discount'),
      translate('button.confirm'),
      '',
      async () => {
        await approveBill.mutateAsync(billId);
      }
    );
  };

  return (
    <div>
      <Card>
        <CardHeader
          action={
            <Button
              variant="contained"
              color={'primary'}
              component={RouterLink}
              to={`${paths.sales.create_discount_bill}?productOrderId=${productOrderId}`}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              {translate('sales.create_discount_title')}
            </Button>
          }
        />
        <CardContent>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: 'title.id' },
              { align: 'left', title: 'sales.delivery_bill' },
              { align: 'left', title: 'title.create_at' },
              { align: 'left', title: 'status.title' },
              { align: 'left', title: 'title.value' },
              { align: 'left', title: '' },
            ]}
            tableData={discountBills.map((bill) => [
              bill.Id,
              bill.DeliveryBill.Id,
              fDateTimeText(bill.createdAt),
              bill.ApprovedEmployeeId !== null ? (
                <Typography variant={'body'} color={'success.main'}>
                  {translate('status.approve')}
                </Typography>
              ) : (
                <Typography variant={'body'} color={'error.main'}>
                  {translate('status.wait_for_approve')}
                </Typography>
              ),
              fCurrency(bill.Value),
              <>
                {bill.ApprovedEmployeeId !== null ? null : (
                  <>
                    <IconButton color={'success'} onClick={() => onApproveDiscountBill(bill.Id)}>
                      <Iconify icon={'ic:baseline-check'} width={20} height={20} />
                    </IconButton>
                    <IconButton color={'error'} onClick={() => onDeleteDiscountBill(bill.Id)}>
                      <Iconify icon={'ic:baseline-close'} width={20} height={20} />
                    </IconButton>
                  </>
                )}
                <IconButton
                  key={bill.Id}
                  component={RouterLink}
                  to={paths.accounting.delivery_bill_detail(bill.DeliveryBill.Id)}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              </>,
            ])}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default ProductOrderDiscountDetail;
