import { useMutation, useQuery } from '@tanstack/react-query';
import { useLoading } from '../components/loading';

export function useLoadingQuery(queryOptions) {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  return useQuery({
    ...queryOptions,
    onMutate: async (...args) => {
      showLoadingScreen();
      if (queryOptions.onMutate) {
        return queryOptions.onMutate(...args);
      }
    },
    onSuccess: async (...args) => {
      hideLoadingScreen();
      if (queryOptions.onSuccess) {
        return queryOptions.onSuccess(...args);
      }
    },
    onError: async (...args) => {
      hideLoadingScreen();
      if (queryOptions.onError) {
        return queryOptions.onError(...args);
      }
    },
  });
}

export function useLoadingMutation(mutationOptions) {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  return useMutation({
    ...mutationOptions,
    onMutate: async (...args) => {
      showLoadingScreen();
      if (mutationOptions.onMutate) {
        return mutationOptions.onMutate(...args);
      }
    },
    onSuccess: async (...args) => {
      hideLoadingScreen();
      if (mutationOptions.onSuccess) {
        return mutationOptions.onSuccess(...args);
      }
    },
    onError: async (...args) => {
      hideLoadingScreen();
      if (mutationOptions.onError) {
        return mutationOptions.onError(...args);
      }
    },
  });
}