import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { unHoldPileWithProblems } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { useMutation } from '@tanstack/react-query';
import HoldProblemForm from '../../forms/HoldProblemForm';

function UnHoldPilePage() {
  const { translate } = useLocales();

  const unHoldPileMutation = useMutation({
    mutationFn: unHoldPileWithProblems,
    onSuccess: async (data, variables) => {
      await OpenNotificationThenReloadPage(
        `${translate('qa.un_hold_pile_title')} : ${translate('production.pile_id')} ${
          variables.PileId
        }`
      );
    },
  });

  const submitProblems = async (data) => {
    await unHoldPileMutation.mutateAsync(data);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('qa.un_hold_pile_title')}
      breadcrumbs={[{ name: translate('qa.title') }, { name: translate('qa.un_hold_pile_title') }]}
    >
      <HoldProblemForm onSubmitCallback={submitProblems} isUnHold />
    </ProjectPageContainer>
  );
}

export default UnHoldPilePage;
