import React from 'react';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { paths } from 'routes/paths';

function SearchAccountingPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const searchType = [
    { value: 1, Name: translate('accounting.search_delivery_bill') },
    { value: 2, Name: translate('accounting.search_billing_note') },
  ];

  const onSearch = async (searchString, type) => {
    if (type === 1) {
      let searchId = searchString;
      if (searchString.includes('A-')) {
        const additionalCostBillId = searchString.replace('A-', '');
        navigate(paths.accounting.product_order_additional_cost_bill_detail(additionalCostBillId));
      } else {
        if (searchString.includes('PileId')) {
          const dataJson = JSON.parse(searchString);
          searchId = dataJson.PileId;
        }
        navigate(paths.accounting.delivery_bill_detail(searchId));
      }
    } else if (type === 2) {
      let searchId = searchString;
      if (searchString.includes('OrderId')) {
        const dataJson = JSON.parse(searchString);
        searchId = dataJson.OrderId;
      }
      navigate(paths.accounting.billing_note_detail(searchId));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.search_bill')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.search_bill') },
      ]}
    >
      <SearchBarWithSelectType
        search_label={translate('placeholder.search_id')}
        select_options={searchType}
        onSearchCallBack={onSearch}
      />
    </ProjectPageContainer>
  );
}

export default SearchAccountingPage;
