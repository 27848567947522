import React, { useState } from 'react';
import { useLocales } from 'locales';
import ProductOrderDetailForm from 'components/forms/ProductOrderDetailForm';
import SelectProductForm from 'components/forms/SelectProductForm';
import ProductPriceDetailForm from 'components/forms/ProductPriceDetailForm';
import StepSelectCustomerForm from 'components/forms/StepSelectCustomerForm';
import RecheckCreateProductOrderForm from 'components/details/RecheckCreateProductOrderDetail';
import {
  OpenErrorNotification,
} from 'utilities/Helper/NotificationHelper';
import { createProductOrder } from 'requests/ProductionServerHandler';
import CustomStepper from 'components/customs/CustomStepper';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';

CreateProductOrderMainForm.propTypes = {
  customer: PropTypes.object,
  productOrderDetail: PropTypes.object,
  products: PropTypes.array,
  onCreateProductOrder: PropTypes.func.isRequired,
};

function CreateProductOrderMainForm(props) {
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const createProductOrderMutation = useMutation({
    mutationFn: createProductOrder,
    onSuccess: async (newProductOrder) => {
      props.onCreateProductOrder(newProductOrder);
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const STEPS = [
    translate('sales.select_customer'),
    translate('title.detail'),
    translate('sales.select_product'),
    translate('sales.manage_price'),
    translate('title.conclude'),
  ];
  const [selectCustomer, setSelectCustomer] = useState(props.customer !== undefined ? props.customer : null);
  const [productOrderDetail, setProductOrderDetail] = useState(props.productOrderDetail !== undefined ? props.productOrderDetail : {
    Name: '',
    Detail: '',
    Width: 0,
    Length: 0,
    Amount: 0,
    ProductOrderSubType: {
      Id: 0,
      Name: 'ไม่ได้เลือก',
    },
  });
  const [selectProducts, setSelectProducts] = useState(props.products !== undefined ? props.products : []);
  const [activeStep, setActiveStep] = useState(0);

  const handleSelectCustomer = (value) => {
    enqueueSnackbar(translate('notification.update_success'));
    setSelectCustomer(value);
    handleChangeStepNumber(1);
  };

  const handleSaveProductOrderDetail = (value) => {
    enqueueSnackbar(translate('notification.update_success'));
    setProductOrderDetail(value);
    handleChangeStepNumber(2);
  };

  const handleSelectProducts = (value) => {
    enqueueSnackbar(translate('notification.update_success'));
    console.log(value)
    setSelectProducts(value);
    handleChangeStepNumber(3);
  };

  const handleUpdatePriceDetail = (value) => {
    enqueueSnackbar(translate('notification.update_success'));
    setSelectProducts(value);
    handleChangeStepNumber(4);
  };

  const handleChangeStepNumber = (stepNumber) => {
    setActiveStep(stepNumber);
  };

  const handleCreateProductOrder = async () => {
    await createProductOrderMutation.mutateAsync({
      ...productOrderDetail,
      CustomerId: selectCustomer.Id,
      Products: selectProducts,
    });
  };

  return (
    <>
      <CustomStepper currentStep={activeStep} steps={STEPS} />
      <>
        {activeStep === 0 && (
          <StepSelectCustomerForm
            onSubmitCallback={handleSelectCustomer}
            defaultCustomer={selectCustomer}
          />
        )}
        {activeStep === 1 && (
          <ProductOrderDetailForm
            productOrderDetail={productOrderDetail}
            onBackPress={() => handleChangeStepNumber(0)}
            onSubmitProductOrderDetail={handleSaveProductOrderDetail}
          />
        )}
        {activeStep === 2 && (
          <SelectProductForm
            customerId={selectCustomer.Id}
            selectedProducts={selectProducts}
            onBackPress={() => handleChangeStepNumber(1)}
            onSubmitProducts={handleSelectProducts}
            productOrderAmount={productOrderDetail.Amount}
          />
        )}
        {activeStep === 3 && (
          <ProductPriceDetailForm
            width={productOrderDetail.Width}
            length={productOrderDetail.Length}
            onBackPress={() => handleChangeStepNumber(2)}
            onSubmitProductPriceDetail={handleUpdatePriceDetail}
            products={selectProducts}
            isCreateQuotation={false}
          />
        )}
        {activeStep === 4 && (
          <RecheckCreateProductOrderForm
            products={selectProducts}
            customer={selectCustomer}
            productDetail={productOrderDetail}
            onBackPress={() => handleChangeStepNumber(3)}
            onConfirm={handleCreateProductOrder}
          />
        )}
      </>
    </>
  );
}

export default CreateProductOrderMainForm;
