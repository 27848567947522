import React from 'react';
import PropTypes from 'prop-types';
//Template Component
import { Grid } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import Image from 'components/minimal/image';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from '../../../locales';
import { getItemInfoAmountText, getTagString } from '../../../utilities/Helper/UserInterfaceHelper';
import { useTheme } from '@mui/material/styles';
import { fCurrency } from '../../../utilities/formatNumber';

export const ItemMasterDetailComponent = ({ itemMaster }) => {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <ProjectPaper>
          <Image
            alt="large image"
            src={
              process.env.REACT_APP_IMAGE_END_POINT +
              process.env.REACT_APP_BUCKET_MASTER_IMAGE_FOLDER_NAME +
              process.env.REACT_APP_IMAGE_PREFIX +
              itemMaster.Id
            }
            ratio="1/1"
          />
        </ProjectPaper>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText title={translate('title.name')} value={itemMaster.Name} />
            <GridDetailText title={translate('title.detail')} value={itemMaster.Detail} />
            <GridDetailText title={translate('title.type')} value={itemMaster.ItemType.Name} />
            <GridDetailText title={translate('unit.title')} value={itemMaster.ItemUnit.Name} />
            <GridDetailText
              title={translate('title.price')}
              value={`${fCurrency(itemMaster.CurrentPrice)} ต่อ ${itemMaster.ItemUnit.Name}`}
            />
            <GridDetailText
              title={translate('title.value')}
              value={`${fCurrency(
                itemMaster.CurrentPrice *
                  itemMaster.ItemContainer.reduce((total, itemContainer) => {
                    const totalMasterInAllLot = itemContainer.ItemLots.reduce(
                      (totalLot, itemLot) => {
                        return totalLot + itemLot.MasterAmount;
                      },
                      0
                    );
                    return total + totalMasterInAllLot;
                  }, 0)
              )}`}
            />
            <GridDetailText
              title={translate('warehouse.tag')}
              value={getTagString(itemMaster.Tags)}
            />
            <GridDetailText
              title={translate('title.safety_amount')}
              value={itemMaster.SafetyAmount}
            />
            {getItemInfoAmountText(
              translate('warehouse.amount_in_store_title'),
              itemMaster.ItemContainer.reduce((total, itemContainer) => {
                const totalMasterInAllLot = itemContainer.ItemLots.reduce((totalLot, itemLot) => {
                  return totalLot + itemLot.MasterAmount;
                }, 0);
                return total + totalMasterInAllLot;
              }, 0),
              itemMaster.SafetyAmount,
              itemMaster.ItemUnit.Name,
              theme
            )}
            <GridDetailText
              title={translate('title.amount_defect')}
              value={itemMaster.DefectAmount}
            />
            {itemMaster.Attributes.map((attribute, index) => {
              return (
                <GridDetailText
                  key={index}
                  title={attribute.Name}
                  value={attribute.Value}
                />
              );
            })}
          </Grid>
        </ProjectPaper>
      </Grid>
    </Grid>
  );
};

ItemMasterDetailComponent.PropType = {
  itemMaster: PropTypes.object.isRequired,
};
