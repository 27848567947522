import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import ProjectPaper from '../customs/ProjectPaper';
import { Box, Button } from '@mui/material';
import React from 'react';

SearchItemMasterForm.propTypes = {
  onSearchCallBack: PropTypes.func.isRequired,
};

export default function SearchItemMasterForm(props) {
  const { onSearchCallBack } = props;
  const { translate } = useLocales();

  const findItemMasterSchema = Yup.object().shape({
    ItemMasterId: Yup.string().required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(findItemMasterSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    onSearchCallBack(values.ItemMasterId);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ProjectPaper>
        <RHFTextField name="ItemMasterId" label={translate('warehouse.search_by_item_master_id')} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            m: 3,
          }}
        >
          <Button variant={'contained'} type={'submit'} color={'primary'}>
            {translate('button.next')}
          </Button>
        </Box>
      </ProjectPaper>
    </FormProvider>
  );
}
