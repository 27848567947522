/** Class representing a user*/
export class User {
    /**
     * Create a point.
     * @param {number} id - user id.
     * @param {string} name - user full name.
     * @param {string} username - user username.
     * @param {Object} department - department object contain id, name.
     * @param {string} employeeCode - user employee code.
     * @param {number} employeeId - user employee id.
     * @param {number} userTypeId - user type id.
     */

    constructor(id,username,name,department,employeeCode,employeeId,userTypeId) {
        this.Id = id;
        this.Username= username;
        this.Name = name;
        this.Department = department;
        this.EmployeeCode = employeeCode;
        this.EmployeeId = employeeId;
        this.UserTypeId = userTypeId;
    }
}
