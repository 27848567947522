import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { changeProductOrderSize } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

export default function ChangeSizePage() {
  const { translate } = useLocales();

  const changeSizeMutation = useMutation({
    mutationFn: changeProductOrderSize,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const submitChangeSize = async (values) => {
    await changeSizeMutation.mutateAsync({
      ProductOrderId: values.ProductOrderId,
      Width: values.Width,
      Length: values.Length,
    });
  };

  const changeSizeSchema = Yup.object().shape({
    ProductOrderId: Yup.number().required(translate('error.detail_require')),
    Width: Yup.number().required(translate('error.detail_require')),
    Length: Yup.number().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(changeSizeSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_size')}
      breadcrumbs={[{ name: translate('admin.title') }, { name: translate('admin.edit_size') }]}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(submitChangeSize)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name="ProductOrderId" label={translate('production.product_order_id')} />
            <RHFTextField name="Width" label={translate('title.width')} />
            <RHFTextField name="Length" label={translate('title.length')} />
            <Button fullWidth variant={'contained'} type={'submit'} color={'primary'}>
              {translate('button.next')}
            </Button>
          </Stack>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
}
