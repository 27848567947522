import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from '../../../locales';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import PileScanner from '../../barcode-reader/PileScanner';
import useAuth from '../../../auth/hooks/useAuth';
import {
  OpenConfirmNotification,
  OpenErrorNotification,
  OpenErrorNotificationThenReloadPage, OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import Iconify from '../../minimal/iconify';
import GridDetailText from '../../customs/GridDetailText';
import { fNumber } from '../../../utilities/formatNumber';
import ProjectPaper from '../../customs/ProjectPaper';
import GeneralTable from '../../customs/GeneralTable';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useMutation } from '@tanstack/react-query';
import { approvePileQuality } from '../../../requests/ProductionServerHandler';
import { useResponsive } from '../../../hooks/useResponsive';

function ApproveQualityProcessPage(props) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const isDesktop = useResponsive('up', 'lg');
  const [selectPile, setSelectPile] = useState(null);
  const approveQualityMutation = useMutation({
    mutationFn: approvePileQuality,
    onSuccess: async (data) => {
      if (data.isError) {
        await OpenErrorNotificationThenReloadPage(data.message);
      } else {
        await OpenNotificationThenReloadPage(
          `${translate('success.approve_pile_quality')} (${translate('title.amount_good')}: ${
            data.message
          })`
        );
      }
    },
  });
  const searchPileDetail = async (pile) => {
    if (pile.ProductionRecords.map((record) => record.ProductId).some(id => [195, 160].includes(id))) {
      setSelectPile(pile);
    }else{
      await OpenErrorNotification('ไม่มีกระบวนการคุณภาพที่ต้องการอนุมัติ');
    }
  };

  const onCancelSelectPile = () => {
    setSelectPile(null);
  }

  const handleApproveProcess = async (recordId) => {
    await OpenConfirmNotification(
      translate('warning.confirm_approve_quality_check'),
      translate('button.confirm'),
      '',
      async () => {
        await approveQualityMutation.mutateAsync({
          PileId: selectPile.Id,
          ProductionRecordId: recordId,
        });
      }
    );
  }

  return (
    <ProjectPageContainer
      menu_title={translate('qa.approve_pile_quality_process_title')}
      breadcrumbs={[
        { name: translate('qa.title') },
        { name: translate('qa.approve_pile_quality_process_title') },
      ]}
    >
      {
        selectPile !== null ?
          <Stack spacing={2}>
            <Card>
              <CardHeader
                title={translate('title.detail')}
                action={
                  <IconButton color={'error'} onClick={onCancelSelectPile}>
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <GridDetailText
                    title={translate('title.id')}
                    value={selectPile.Id}
                  />
                  <GridDetailText
                    title={translate('title.name')}
                    value={selectPile.ProductOrder.Name}
                  />
                  <GridDetailText
                    title={translate('title.customer')}
                    value={selectPile.ProductOrder.Customer.Name}
                  />
                  <GridDetailText
                    title={translate('title.amount')}
                    value={fNumber(selectPile.Amount)}
                  />
                </Grid>
              </CardContent>
            </Card>
            <ProjectPaper>
              {
                isDesktop ?
                  <GeneralTable
                    tableHeaders={[
                      {align: 'left', title: translate('title.name')},
                      {align: 'left', title: translate('title.amount')},
                      {align: 'left', title: translate('title.machine')},
                      {align: 'left', title: translate('title.by')},
                      {align: 'left', title: translate('Actions')},
                    ]}
                    tableData={selectPile.ProductionRecords.filter((record) => record.ProductId === 195 || record.ProductId === 160).map((record) => {
                      return [
                        record.ProductionProcess.Name,
                        fNumber(record.GoodAmount),
                        record.Machine !== null ? record.Machine.Name : 'N/A' ,
                        getEmployeeFullName(record.CreateEmployee),
                        record.CreateEmployee !== null ? record.ApprovedQualityAt === null ? <IconButton color={'success'} onClick={() => handleApproveProcess(record.Id)}>
                          <Iconify icon={'material-symbols:check'} />
                        </IconButton>: <Typography variant={'body'} sx={{color: 'success.main'}}>{translate('status.already_check')}</Typography>: <Typography variant={'body'} sx={{color: 'error.main'}}>ยังไม่ได้ส่งยอด</Typography>,
                      ];
                    })}
                  />
                  :
                  <GeneralTable
                    tableHeaders={[
                      {align: 'left', title: translate('Actions')},
                      {align: 'left', title: translate('title.name')},
                      {align: 'left', title: translate('title.amount')},
                      {align: 'left', title: translate('title.machine')},
                      {align: 'left', title: translate('title.by')},
                    ]}
                    tableData={selectPile.ProductionRecords.filter((record) => record.ProductId === 195  || record.ProductId === 160).map((record) => {
                      return [
                        record.CreateEmployee !== null ? record.ApprovedQualityAt === null ? <IconButton color={'success'} onClick={() => handleApproveProcess(record.Id)}>
                          <Iconify icon={'material-symbols:check'} />
                        </IconButton>: <Typography variant={'body'} sx={{color: 'success.main'}}>{translate('status.already_check')}</Typography>: <Typography variant={'body'} sx={{color: 'error.main'}}>ยังไม่ได้ส่งยอด</Typography>,
                        record.ProductionProcess.Name,
                        fNumber(record.GoodAmount),
                        record.Machine !== null ? record.Machine.Name : 'N/A' ,
                        getEmployeeFullName(record.CreateEmployee),
                      ];
                    })}
                  />
              }
            </ProjectPaper>
          </Stack>
          :
          <Stack spacing={2}>
            <PileScanner
              onSearchCallBack={searchPileDetail}
              disableInput={user.UserTypeId !== ManagementUserTypeId}
              isPreFetchData
            />
          </Stack>
      }
    </ProjectPageContainer>
  );
}
export default ApproveQualityProcessPage;