import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { Button, IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import {
  getProductionMachineDashboardData,
  getProductionPDF,
} from '../../../requests/ProductionServerHandler';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';
import { fNumber } from '../../../utilities/formatNumber';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import { AdminUserTypeId, ManagementUserTypeId, SalesUserTypeId } from '../../../utilities/Constants';
import { PrintMultiplePDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import { OpenErrorNotification, OpenSuccessNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLoading } from '../../loading';

function ProductionDashboardPage() {
  const { translate } = useLocales();
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const theme = useTheme();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: new Date(),
  });

  const { data: dashboardData, refetch } = useQuery({
    queryKey: ['production-dashboard', dateRange],
    queryFn: () => getProductionMachineDashboardData(dateRange),
    initialData: [],
  });

  const printProductionReportPDF = useMutation({
    mutationFn: getProductionPDF,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument([response], `report.pdf`);
      await hideLoadingScreen();
      await OpenSuccessNotification();
    },
    onError: async () => {
      await hideLoadingScreen();
      await OpenErrorNotification();
    },
  });

  const handleChangeDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };
  const onPrintPileDocument = async () => {
    await printProductionReportPDF.mutateAsync();
  };

  const getMachineStatus = (machineStatusId) => {
    if (machineStatusId === 1) {
      return translate('status.normal');
    }
    if (machineStatusId === 2) {
      return (
        <Typography variant={'body2'} color={'error'}>
          {translate('status.breakdown')}
        </Typography>
      );
    }
    if (machineStatusId === 3) {
      return (
        <Typography variant={'body2'} color={'#FFC107'}>
          {translate('status.maintenance')}
        </Typography>
      );
    } else {
      return 'N/A';
    }
  };

  const getPerformanceIcon = (performance) => {
    if (performance >= 80) {
      return (
        <Iconify
          sx={{ color: theme.palette.success.main }}
          icon={'ant-design:arrow-up-outlined'}
          width={20}
          height={20}
        />
      );
    } else if (performance < 80 && performance >= 50) {
      return (
        <Iconify
          sx={{ color: theme.palette.warning.main }}
          icon={'ant-design:arrow-right-outlined'}
          width={20}
          height={20}
        />
      );
    } else {
      return (
        <Iconify
          sx={{ color: theme.palette.error.main }}
          icon={'ant-design:arrow-down-outlined'}
          width={20}
          height={20}
        />
      );
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[{ name: translate('production.title') }, { name: translate('dashboard') }]}
      action={<RoleBasedGuard roles={[AdminUserTypeId, ManagementUserTypeId, SalesUserTypeId]}>
        <Button
          variant="contained"
          color={'info'}
          startIcon={<Iconify icon={'ic:outline-print'} />}
          onClick={onPrintPileDocument}
        >
          {translate('button.print_production_report')}
        </Button>
      </RoleBasedGuard>}
    >
      <SILDatePicker onSearchDate={handleChangeDate} />
      <ProjectPaper>
        <ReactTable
          defaultPageSize={25}
          search
          data={dashboardData.map((machine) => {
            return {
              name: machine.Name,
              target: machine.ExpectedResult,
              status: machine.MachineStatusId,
              good: machine.Good,
              bad: machine.Defect,
              maintenance: machine.MaintenanceJob,
              performance: (parseFloat(machine.Good) * 100) / machine.ExpectedResult,
              actions: machine.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.status'),
              accessorKey: 'status',
              cell: (info) => getMachineStatus(info.getValue()),
            },
            {
              header: translate('title.target_per_day'),
              accessorKey: 'target',
              size: 30,
              cell: (info) => fNumber(info.getValue()),
            },
            {
              header: translate('title.amount_good'),
              accessorKey: 'good',
              size: 30,
              cell: (info) => fNumber(info.getValue()),
            },
            {
              header: translate('title.amount_defect'),
              accessorKey: 'bad',
              size: 30,
              cell: (info) => fNumber(info.getValue()),
            },
            {
              header: translate('title.performance'),
              accessorKey: 'performance',
              cell: (info) => getPerformanceIcon(info.getValue()),
              size: 30,
            },
            {
              header: translate('title.maintenance_job_number'),
              accessorKey: 'maintenance',
              cell: (info) => {
                if (info.getValue() === null) {
                  return 'N/A';
                }
                if (info.getValue() > 5) {
                  return (
                    <Typography variant={'body2'} color={'error'}>
                      {info.getValue()}
                    </Typography>
                  );
                } else {
                  return info.getValue();
                }
              },
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  component={RouterLink}
                  to={paths.production.machine_dashboard_detail(info.getValue())}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default ProductionDashboardPage;
