import * as Yup from "yup";

export const itemContainerValidator = (translate) => {
    return Yup.object().shape({
        Name: Yup.string().required(translate('error.name_is_require')),
        Size: Yup.number().min(1, translate('error.error_invalid_amount')).required(translate('error.error_invalid_amount')),
        ItemUnit: Yup.object().shape({
            Id: Yup.number().min(1, translate('error.invalid_input')),
            Name: Yup.string().required(translate('error.invalid_input')),
        }).required(translate('error.invalid_input')),
    });
}

export const supplierSchemaValidator = (translate) => {
    return Yup.object().shape({
        Name: Yup.string().required(translate('error.name_is_require')),
        AdditionalAddress: Yup.string().required(translate('error.address_require')),
        TaxRegistrationNumber: Yup.string().required(translate('error.tax_registration_number_require')),
        TelephoneNumber: Yup.string().required(translate('error.telephone_number_require')),
        ContactName: Yup.string().required(translate('error.name_is_require')),
    });
}
