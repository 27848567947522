import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ItemMasterDetailComponent } from '../../details/warehouse/ItemMasterDetailComponent';
import { ItemMasterHistoryDetailComponent } from '../../details/warehouse/ItemMasterHistoryDetailComponent';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Box, Button, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { allowToEditItemDetailUserTypeId } from '../../../utilities/Constants';
import LoadingComponent from '../../customs/LoadingComponent';
import useTabs from '../../../hooks/useTabs';
import ItemMasterContainerDetail from '../../details/warehouse/ItemMasterContainerDetail';
import CreateItemMasterForm from '../../forms/CreateItemMasterForm';
import { useItemMasterDetail } from '../../../requests/query/useItemMasterDetail';
import { paths } from '../../../routes/paths';

function ItemMasterDetail() {
  const { id } = useParams();
  const { translate } = useLocales();
  const [isEditMode, setIsEditMode] = useState(false);
  const { currentTab, onChangeTab } = useTabs('detail');

  const { itemMasterDetail, printItemMasterQRCode } = useItemMasterDetail(id);
  const changeEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: !isEditMode ? (
        <ItemMasterDetailComponent itemMaster={itemMasterDetail} />
      ) : (
        <CreateItemMasterForm itemMasterDetail={itemMasterDetail} />
      ),
    },
    {
      value: 'lots',
      title: translate('warehouse.container'),
      icon: <Iconify icon={'fe:list-order'} width={20} height={20} />,
      component: (
        <ItemMasterContainerDetail
          itemContainerDetail={itemMasterDetail?.ItemContainer}
          itemMasterId={itemMasterDetail?.Id}
          itemMasterUnit={itemMasterDetail?.ItemUnit.Name}
        />
      ),
    },
    {
      value: 'history',
      title: translate('warehouse.movement_history'),
      icon: <Iconify icon={'ant-design:history-outlined'} width={20} height={20} />,
      component: <ItemMasterHistoryDetailComponent itemMaster={itemMasterDetail} />,
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.item_template_detail')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.title'), href: paths.warehouse.store },
        { name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${id}` },
      ]}
      action={
        currentTab === 'detail' &&
        !isEditMode && (
          <Stack spacing={2} direction={'column'}>
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={printItemMasterQRCode}
            >
              {translate('button.print_item_qr')}
            </Button>
            <RoleBasedGuard roles={allowToEditItemDetailUserTypeId}>
              <Button
                variant="contained"
                color={'info'}
                startIcon={<Iconify icon={'bx:edit'} />}
                onClick={changeEditMode}
              >
                {translate('button.edit_data')}
              </Button>
            </RoleBasedGuard>
          </Stack>
        )
      }
    >
      {itemMasterDetail !== null ? (
        <>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {INFORMATION_TABS.map((tab, index) => (
              <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
          <Box sx={{ mb: 3 }} />
          {INFORMATION_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && tab.component;
          })}
        </>
      ) : (
        <LoadingComponent />
      )}
    </ProjectPageContainer>
  );
}

export default ItemMasterDetail;
