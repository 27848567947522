import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { selectPurchaseOrderItemPartsCart } from '../../../redux/reducers/procurement';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import {
  cancelPRItemTemplate,
  getPartsToPurchases,
} from '../../../requests/ProductionServerHandler';
import Typography from '@mui/material/Typography';
import ReactTable from '../../react-table/ReactTable';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import ProjectPaper from '../../customs/ProjectPaper';
import { OPEN_ICON } from '../../../config-global';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { RouterLink } from '../../../routes/components';
import Link from "@mui/material/Link";

function CreatePartsPurchaseOrderPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const PurchaseOrderItems = useSelector(selectPurchaseOrderItemPartsCart);

  const { data: itemToPurchase, refetch } = useQuery({
    queryKey: ['getPartsToPurchases'],
    queryFn: getPartsToPurchases,
    initialData: {
      ItemTemplate: [],
    },
  });

  const cancelPRItemMasterMutation = useMutation({
    mutationFn: cancelPRItemTemplate,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await refetch();
    },
  });

  const getPartPriorityText = (isUrgent) => {
    if (isUrgent) {
      return (
        <Typography variant={'body2'} color={'error'}>
          {translate('procurement.urgent')}
        </Typography>
      );
    } else {
      return <Typography variant={'body2'}>{translate('procurement.not_urgent')}</Typography>;
    }
  };

  const handleCancelPurchaseRequisition = async (prItemTemplateId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_delete_data',
      async () => {
        await cancelPRItemMasterMutation.mutateAsync(prItemTemplateId);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.create_po_parts_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.create_po_parts_title') },
      ]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={PurchaseOrderItems.length === 0}
          to={paths.procurement.create_parts_po}
        >
          {`${translate('procurement.buy_item')} ${PurchaseOrderItems.length} ${translate(
            'warehouse.items'
          )}`}
        </Button>
      }
    >
      <ProjectPaper>
        {itemToPurchase !== null && (
          <ReactTable
            search
            data={itemToPurchase.ItemTemplate.map((itemToPurchase) => {
              return {
                pr_id: `P${itemToPurchase.PurchaseRequisitionId}`,
                name: itemToPurchase.ItemTemplate.Name,
                type: itemToPurchase.ItemTemplate.ItemTemplateType.Name,
                priority: itemToPurchase.PurchaseRequisition.IsUrgent,
                create_by: itemToPurchase.PurchaseRequisition.CreateEmployee,
                create_department:
                  itemToPurchase.PurchaseRequisition.CreateEmployee.Department.Name,
                create_at: itemToPurchase.PurchaseRequisition.createdAt,
                amount: `${itemToPurchase.Amount - itemToPurchase.PurchaseAmount} ${
                  itemToPurchase.ItemTemplate.ItemTemplateUnit.Name
                }`,
                actions: {
                  Id: itemToPurchase.Id,
                  ItemTemplateId: itemToPurchase.ItemTemplateId,
                },
              };
            })}
            header={[
              {
                header: translate('procurement.pr_id'),
                accessorKey: 'pr_id',
                cell: (info) => (
                  <Link href={paths.procurement.parts_pr_detail(info.getValue().replace('P', ''))}>
                    {info.getValue()}
                  </Link>
                ),
                size: 20,
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
                size: 20,
              },
              {
                header: translate('title.type'),
                accessorKey: 'type',
                size: 50,
              },
              {
                header: translate('title.create_by'),
                accessorKey: 'create_by',
                cell: (info) => getEmployeeFullName(info.getValue()),
                size: 50,
              },
              {
                header: translate('user.department'),
                accessorKey: 'create_department',
                size: 50,
              },
              {
                header: translate('title.create_at'),
                accessorKey: 'create_at',
                cell: (info) => fDateTimeText(info.getValue()),
              },
              {
                header: translate('title.priority_level_title'),
                accessorKey: 'priority',
                cell: (info) => getPartPriorityText(info.getValue()),
                size: 20,
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <IconButton
                      onClick={() =>
                        navigate(
                          paths.general_warehouse.item_template_detail(
                            info.getValue().ItemTemplateId
                          )
                        )
                      }
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton
                      color={'error'}
                      onClick={() => handleCancelPurchaseRequisition(info.getValue().Id)}
                    >
                      <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                    </IconButton>
                    <IconButton
                      color={'success'}
                      onClick={() =>
                        navigate(
                          paths.procurement.search_supplier_sell_item_template(info.getValue().Id)
                        )
                      }
                    >
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
                size: 150,
              },
            ]}
          />
        )}
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreatePartsPurchaseOrderPage;
