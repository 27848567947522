// hooks
import useAuth from '../../auth/hooks/useAuth';
// utils
import createAvatar from 'utilities/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
    const { user } = useAuth();

    return (
        <Avatar
            src={`${process.env.REACT_APP_PROFILE_IMAGE_PREFIX}${user?.EmployeeCode}.jpg`}
            alt={user?.Name}
            color={user?.EmployeeCode ? 'default' : createAvatar(user?.Name).color}
            {...other}
        >
            {createAvatar(user?.Name).name}
        </Avatar>
    );
}
