import { createSlice } from '@reduxjs/toolkit'

export const planningSlice = createSlice({
    name: 'planning',
    initialState: {
        isShowProductOrderDetail: false,
        isShowMachineSelection: false,
        isShowDateSelector: false,
        showProductOrderDetail: null,
        machineList: [],
        selectPlanningId: 0,
        planningStatus: {
            allPO: 0,
            notComplete: 0,
            complete: 0
        },

        isRefreshUnPlanPO: true,
        isRefreshPlanningCard: true,
        isRefreshInMachinePlan: true,
        isRefreshMachineList: true,
        inMachineAmount: 0,
        unPlanAmount: 0
    },
    reducers: {
        setPlanningStatus:(state,action) => {
            state.planningStatus = action.payload
        },
        toggleShowDateSelector :(state,action) => {
            state.isShowDateSelector = action.payload
        },
        toggleShowProductOrderDetail :(state,action) => {
            state.isShowProductOrderDetail = action.payload.isShowProductOrderDetail
            state.showProductOrderDetail = action.payload.productOrderDetail
        },
        toggleCloseProductOrderDetail :(state) => {
            state.isShowProductOrderDetail = false
        },
        toggleShowMachineSelection :(state,action) => {
            state.isShowMachineSelection = action.payload.isShowMachineSelection
            state.machineList = action.payload.machineList
            state.selectPlanningId = action.payload.selectPlanningId
        },
        toggleCloseMachineSelection :(state) => {
            state.isShowMachineSelection = false
            state.selectPlanningId = 0
            state.machineList = []
        },

        toggleForceRefreshUnPlanPO: (state,action) => {
            state.isRefreshUnPlanPO = action.payload
        },
        toggleRefreshPlanningCard: (state,action) => {
            state.isRefreshPlanningCard = action.payload
        },
        toggleRefreshInMachinePlan: (state,action) => {
            state.isRefreshInMachinePlan = action.payload
        },
        toggleMachineList: (state,action) => {
            state.isRefreshMachineList = action.payload
        },
        setInMachineAmount: (state,action) => {
            state.inMachineAmount = action.payload
        },
        setUnPlanAmount: (state,action) => {
            state.unPlanAmount = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setPlanningStatus,
    toggleShowProductOrderDetail,
    toggleCloseProductOrderDetail,
    toggleShowMachineSelection,
    toggleCloseMachineSelection,

    toggleShowDateSelector,

    toggleForceRefreshUnPlanPO,
    toggleRefreshPlanningCard,
    toggleRefreshInMachinePlan,
    toggleMachineList,
    setInMachineAmount,
    setUnPlanAmount} = planningSlice.actions

export const selectIsShowProductOrderDetail = (state) => state.planning.isShowProductOrderDetail;
export const selectShowProductOrder = (state) => state.planning.showProductOrderDetail;
export const selectIsShowMachineSelection = (state) => state.planning.isShowMachineSelection;
export const selectMachineList = (state) => state.planning.machineList;
export const selectPlanningId = (state) => state.planning.selectPlanningId;
export const selectIsShowDateSelector = (state) => state.planning.isShowDateSelector;
export const selectPlanningStatus = (state) => state.planning.planningStatus;

export const selectIsRefreshUnPlanPO = (state) => state.planning.isRefreshUnPlanPO;
export const selectIsRefreshPlanningCard = (state) => state.planning.isRefreshPlanningCard;
export const selectIsRefreshInMachinePlan = (state) => state.planning.isRefreshInMachinePlan;
export const selectIsRefreshMachineList = (state) => state.planning.isRefreshMachineList;
export const selectInMachineAmount = (state) => state.planning.inMachineAmount;
export const selectUnPlanAmount = (state) => state.planning.unPlanAmount;

export default planningSlice.reducer
