import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import ProjectPaper from '../customs/ProjectPaper';
import { Stack, Button } from '@mui/material';
import { getAllCreateProductPageRequireData } from '../../requests/ProductionServerHandler';
import FormProvider, {
  RHFTags,
  RHFCheckbox,
  RHFTextField,
  RHFAutoCompleteShort,
} from '../minimal/hook-form';
import RoleBasedGuard from '../../auth/guard/RoleBaseGuard';
import { ManagementUserTypeId, SalesUserTypeId } from '../../utilities/Constants';
import { useQuery } from '@tanstack/react-query';

EditProductDetailFrom.propTypes = {
  productDetail: PropTypes.object,
  onUpdateCompleteCallback: PropTypes.func.isRequired,
  isCreated: PropTypes.bool,
};

function EditProductDetailFrom({ productDetail, onUpdateCompleteCallback, isCreated }) {
  const { translate } = useLocales();

  const { data } = useQuery({
    queryKey: ['edit-product-data'],
    queryFn: getAllCreateProductPageRequireData,
    initialData: {
      ProductionProcess: [],
      ProductType: [],
    },
  });

  const ProductDetailSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.field_is_require')),
    ProductType: Yup.object()
      .shape({
        Id: Yup.number(),
        Name: Yup.string(),
      })
      .nullable()
      .required(translate('error.field_is_require')),
    BasePrice: Yup.number()
      .min(0.01, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    MinimumValue: Yup.number()
      .min(10, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    MinimumAmount: Yup.number()
      .min(10, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    TargetPerMonth: Yup.number()
      .min(0, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    MinimumPrice: Yup.number()
      .min(0.01, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    PricePerSquareInch: Yup.number()
      .min(0.0001, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    BundlePrice: Yup.number()
      .min(0.0001, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
    Process: Yup.array(
      Yup.object().shape({
        Id: Yup.number(),
        Name: Yup.string(),
      })
    )
      .min(1, translate('error.field_is_require'))
      .required(translate('error.field_is_require')),
  });

  const defaultValues = isCreated
    ? {
        Name: '',
        Detail: '',
        BasePrice: 0,
        MinimumValue: 0,
        MinimumAmount: 0,
        TargetPerMonth: 0,
        IsTaxable: false,
        IsDiscountable: false,
        MinimumPrice: 0,
        PricePerSquareInch: 0,
        BundlePrice: 0,
        Process: [],
      }
    : {
        Name: productDetail?.Name || '',
        Detail: productDetail?.Detail || '',
        ProductType: productDetail?.ProductType || '',
        BasePrice: productDetail?.BasePrice || 0,
        MinimumValue: productDetail?.MinimumValue || 0,
        MinimumAmount: productDetail?.MinimumAmount || 0,
        TargetPerMonth: productDetail?.TargetPerMonth || 0,
        IsTaxable: productDetail?.IsTaxable || false,
        IsDiscountable: productDetail?.IsDiscountable || false,
        MinimumPrice: productDetail?.MinimumPrice || 0,
        PricePerSquareInch: productDetail?.PricePerSquareInch || 0,
        BundlePrice: productDetail?.BundlePrice || 0,
        Process:
          productDetail?.ProductHasProductionProcesses.map((process) => {
            return {
              Id: process.ProductionProcess.Id,
              Name: process.ProductionProcess.Name,
            };
          }) || [],
      };

  const methods = useForm({
    resolver: yupResolver(ProductDetailSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (value) => {
    onUpdateCompleteCallback(value);
  };

  return (
    <ProjectPaper>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <RHFTextField name="Name" label={translate('title.name')} />
          <RHFTextField name="Detail" label={translate('title.detail')} />
          <RHFAutoCompleteShort
            name="ProductType"
            label={translate('title.type')}
            data={data.ProductType}
          />
          <RHFTextField
            name="BasePrice"
            type={'number'}
            label={translate('title.price_per_sheet')}
          />
          <RHFTextField
            name="MinimumValue"
            type={'number'}
            label={translate('title.minimum_bundle_price')}
          />
          <RHFTextField
            name="MinimumAmount"
            type={'number'}
            label={translate('title.minimum_amount_order')}
          />
          <RHFTextField
            name="TargetPerMonth"
            type={'number'}
            label={translate('title.target_per_month')}
          />
          <RoleBasedGuard roles={[ManagementUserTypeId, SalesUserTypeId]}>
            <RHFCheckbox name="IsTaxable" label={translate('title.vat')} />
            <RHFCheckbox name="IsDiscountable" label={translate('title.discountable')} />
            <RHFTextField
              name="PricePerSquareInch"
              type={'number'}
              label={translate('title.price_per_square_inch')}
            />
            <RHFTextField
              name="MinimumPrice"
              type={'number'}
              label={translate('title.minimum_price_per_sheet')}
            />
            <RHFTextField
              name="BundlePrice"
              type={'number'}
              label={translate('title.bundle_price')}
            />
          </RoleBasedGuard>
          <RHFTags
            name={'Process'}
            label={translate('title.process')}
            options={data.ProductionProcess}
          />
          <Button color={'primary'} fullWidth variant={'contained'} type={'submit'}>
            {isCreated ? translate('button.create') : translate('button.save')}
          </Button>
        </Stack>
      </FormProvider>
    </ProjectPaper>
  );
}

export default EditProductDetailFrom;
