import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import GeneralTable from '../../customs/GeneralTable';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { useQuery } from '@tanstack/react-query';
import { getAllProductOrderProblemDetail } from '../../../requests/ProductionServerHandler';
import { useLocales } from '../../../locales';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeTextShort } from '../../../utilities/formatTime';
import { useResponsive } from '../../../hooks/useResponsive';
import { OPEN_ICON } from '../../../config-global';
import { paths } from '../../../routes/paths';

ProductOrderProblemDetailList.propTypes = {
  productOrderId: PropTypes.string.isRequired,
};

function ProductOrderProblemDetailList({ productOrderId }) {
  const { translate } = useLocales();
  const { data: problemDetails } = useQuery({
    queryKey: ['problemDetails', productOrderId],
    queryFn: () => getAllProductOrderProblemDetail(productOrderId),
    initialData: [],
  });
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Card>
      <CardHeader title={translate('button.add_problem_flag')} />
      <CardContent>
        {isDesktop ? (
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'title.create_by' },
              { align: 'left', title: 'title.create_at' },
              { align: 'left', title: 'title.status' },
              { align: 'left', title: 'title.approve_date' },
              { align: 'left', title: 'title.approve_by' },
              { align: 'left', title: '' },
            ]}
            tableData={problemDetails.map((problem) => [
              problem.ProblemDetail.Name,
              getEmployeeFullName(problem.CreateEmployee),
              fDateTimeTextShort(problem.createdAt),
              problem.SolvedAt !== null
                ? translate('status.solved')
                : translate('status.wait_for_solve'),
              getEmployeeFullName(problem.SolveEmployee),
              fDateTimeTextShort(problem.SolvedAt),
              <IconButton
                color={problem.SolvedAt !== null ? 'info' : 'success'}
                key={problem.Id}
                component={RouterLink}
                href={`${paths.production.solve_production_problem}?productOrderId=${productOrderId}&problemId=${problem.Id}`}
              >
                <Iconify
                  icon={problem.SolvedAt !== null ? OPEN_ICON : 'ic:baseline-check'}
                  width={20}
                  height={20}
                />
              </IconButton>,
            ])}
          />
        ) : (
          <GeneralTable
            isMobile
            tableHeaders={[
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'title.status' },
              { align: 'left', title: 'Action' },
            ]}
            tableData={problemDetails.map((problem) => [
              problem.ProblemDetail.Name,
              problem.SolvedAt !== null
                ? translate('status.solved')
                : translate('status.wait_for_solve'),
              <IconButton
                color={problem.SolvedAt !== null ? 'info' : 'success'}
                key={problem.Id}
                component={RouterLink}
                href={`${paths.production.solve_production_problem}?productOrderId=${productOrderId}&problemId=${problem.Id}`}
              >
                <Iconify
                  icon={problem.SolvedAt !== null ? OPEN_ICON : 'ic:baseline-check'}
                  width={20}
                  height={20}
                />
              </IconButton>,
            ])}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default ProductOrderProblemDetailList;
