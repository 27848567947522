import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  OpenNotificationThenCallback,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { pr_prefix } from '../../utilities/Strings/Prefix';
import { useLocales } from '../../locales';
import { useDispatch } from 'react-redux';
import { resetPartsPurchaseCart } from '../../redux/reducers/inventory';
import { paths } from '../../routes/paths';
import { useNavigate } from 'react-router-dom';
import {
  approvePartPR,
  cancelPartPR,
  createItemTemplatePR,
  deletePRItemTemplate,
  getPartPRDetailByID,
  getPartPRPDFFile,
} from '../ProductionServerHandler';

export const usePartPurchaseRequisition = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { translate } = useLocales();
  const { data } = useQuery({
    queryKey: ['part-pr-detail', id],
    queryFn: () => getPartPRDetailByID(id),
    initialData: null,
    enabled: !!id,
  });

  const printPRMutation = useMutation({
    mutationFn: getPartPRPDFFile,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', async () => {
        await PrintMultiplePDFMakeDocument([response.PDF], `${pr_prefix}_${id}.pdf`);
      });
    },
  });

  const createPRMutation = useMutation({
    mutationFn: createItemTemplatePR,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(
        translate('title.complete'),
        `${translate('procurement.success_create_purchase_requisition_id_title')}:${response.Id}`,
        async () => {
          await sessionStorage.removeItem('pr_detail');
          await sessionStorage.removeItem('pr_temp_item_master');
          await printPRMutation.mutateAsync(response.Id);
          await dispatch(resetPartsPurchaseCart());
          navigate(paths.procurement.parts_pr_detail(response.Id));
        }
      );
    },
  });

  const cancelPRMutation = useMutation({
    mutationFn: cancelPartPR,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-pr-detail', id]);
    },
  });

  const approvePRMutation = useMutation({
    mutationFn: approvePartPR,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-pr-detail', id]);
    },
  });

  const deleteItemFromPRMutation = useMutation({
    mutationFn: deletePRItemTemplate,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['part-pr-detail', id]);
    },
  });

  return {
    prDetail: data,
    createPR: createPRMutation,
    printPR: printPRMutation,
    cancelPR: cancelPRMutation,
    approvePR: approvePRMutation,
    deleteItemFromPR: deleteItemFromPRMutation,
  };
};
