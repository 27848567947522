import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Button, Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { fDateTime } from '../../../utilities/formatTime';
import Typography from '@mui/material/Typography';
import GeneralTable from '../../customs/GeneralTable';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  AdminUserTypeId,
  ManagementUserTypeId,
  WarehouseControlUserTypeId,
} from '../../../utilities/Constants';
import Iconify from 'components/minimal/iconify';
import { usePartStockReport } from '../../../requests/query/usePartStockReport';

const StockReportDetailPage = () => {
  const { id } = useParams();
  const { translate } = useLocales();

  const { itemTemplateRequestReportDetail, printReport } = usePartStockReport(id);
  const onPrintBill = async () => {
    await printReport.mutateAsync(id);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('title.item_receipt_title')}
      breadcrumbs={[
        { name: translate('warehouse.title') },
        { name: translate('title.item_receipt_title') },
      ]}
      action={
        <RoleBasedGuard roles={[ManagementUserTypeId, WarehouseControlUserTypeId, AdminUserTypeId]}>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={onPrintBill}
          >
            {translate('button.print')}
          </Button>
        </RoleBasedGuard>
      }
    >
      {itemTemplateRequestReportDetail !== null ? (
        <Stack spacing={2}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={id} />
              <GridDetailText
                title={translate('warehouse.create_item_request_by_title')}
                value={itemTemplateRequestReportDetail.CreateRequestEmployeeName}
              />
              <GridDetailText
                title={translate('warehouse.create_item_request_at_title')}
                value={fDateTime(itemTemplateRequestReportDetail.StockReport.createdAt)}
              />
              <GridDetailText
                title={translate('warehouse.fulfill_by_title')}
                value={itemTemplateRequestReportDetail.FulfillEmployeeName}
              />
              <GridDetailText
                title={translate('warehouse.fulfill_at_title')}
                value={fDateTime(itemTemplateRequestReportDetail.StockReport.createdAt)}
              />
              <GridDetailText
                title={translate('warehouse.accept_by_title')}
                value={itemTemplateRequestReportDetail.AcceptEmployeeName}
              />
              <GridDetailText
                title={translate('warehouse.accept_at_title')}
                value={fDateTime(itemTemplateRequestReportDetail.StockReport.AcceptedAt)}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <Typography variant={'body'}>{translate('warehouse.want_item')}</Typography>
            <br />
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
              ]}
              tableData={itemTemplateRequestReportDetail.RequestReport.ItemTemplates.map(
                (requestItem) => [
                  requestItem.Name,
                  requestItem.ItemTemplateRequestReportHasItemTemplates.Amount,
                  requestItem.ItemTemplateUnit.Name,
                ]
              )}
            />
          </ProjectPaper>
          <ProjectPaper>
            <Typography variant={'body'}>{translate('warehouse.release_item')}</Typography>
            <br />
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
              ]}
              tableData={itemTemplateRequestReportDetail.StockReport.StockTransactions.map(
                (transaction) => [
                  transaction.ItemTemplate.Name,
                  transaction.Amount,
                  transaction.ItemTemplate.ItemTemplateUnit.Name,
                ]
              )}
            />
          </ProjectPaper>
        </Stack>
      ) : null}
    </ProjectPageContainer>
  );
};

export default StockReportDetailPage;
