import React, { useEffect, useState } from 'react';
import Scrollbar from '../../minimal/scrollbar';
import GeneralSelect from '../../customs/GeneralSelect';
import { useLocales } from '../../../locales';
import Fuse from 'fuse.js';
import SearchField from '../../customs/SearchField';
import { Card, Divider, Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { fNumber } from '../../../utilities/formatNumber';
import {
  getAllMachineWithProductionPlan,
  getMachinePlanById,
} from '../../../requests/ProductionServerHandler';
import { useDispatch } from 'react-redux';
import MachineDetailCard from './MachineDetailCard';
import MachineDetailDialog from './MachineDetailDialog';

const sortMachineByPlanAmount = (a, b) => {
  return b.ProductOrderPlannings.length - a.ProductOrderPlannings.length;
};

function MachineListPlanning() {
  const options = {
    keys: [
      'Name',
      'Id',
      'ProductOrderPlannings.ProductOrder.Id',
      'ProductOrderPlannings.ProductOrder.Name',
      'ProductOrderPlannings.ProductOrder.Customer.Name',
    ],
  };

  const { translate } = useLocales();
  const dispatch = useDispatch();

  const [allMachine, setAllMachine] = useState([]);
  const [filterMachine, setFilterMachine] = useState([]);
  const [sortValue, setSortValue] = useState(2);
  const [showMachineDetail, setShowMachineDetail] = useState(false);
  const [machineDetail, setMachineDetail] = useState(null);
  const fuse = new Fuse(allMachine, options);
  const sortTypes = [
    { value: 0, name: translate('title.status') },
    { value: 1, name: translate('title.capacity') },
    { value: 2, name: translate('title.queue_amount') },
  ];
  const handleSearch = async (searchText) => {
    if (searchText !== null && searchText !== '') {
      setFilterMachine(fuse.search(searchText).map((result) => result.item));
    } else {
      setFilterMachine(allMachine);
    }
  };
  const handleSort = (value) => {
    if (value === 0) {
      const sorted = [...filterMachine].sort((a, b) => {
        return b.MachineStatusId - a.MachineStatusId;
      });
      setFilterMachine(sorted);
    }
    if (value === 1) {
      const sorted = [...filterMachine].sort((a, b) => {
        return b.ExpectedResult - a.ExpectedResult;
      });
      setFilterMachine(sorted);
    }
    if (value === 2) {
      const sorted = [...filterMachine].sort(sortMachineByPlanAmount);
      setFilterMachine(sorted);
    }
    setSortValue(value);
  };

  useEffect(() => {
    const allProductionMachineDetail = async () =>
      await dispatch(getAllMachineWithProductionPlan());

    allProductionMachineDetail().then(async (response) => {
      setAllMachine(response);
      setFilterMachine([...response].sort(sortMachineByPlanAmount));
    });
  }, [dispatch]);

  const handleOpenMachineDialog = async (machineId) => {
    const response = await dispatch(getMachinePlanById(machineId));
    if (response !== null) {
      setMachineDetail(response);
      setShowMachineDetail(true);
    }
  };
  const handleCloseMachineDialog = async () => {
    setShowMachineDetail(false);
  };

  return (
    <div>
      <MachineDetailDialog
        isOpen={showMachineDetail}
        handleDialogClose={handleCloseMachineDialog}
        machineDetail={machineDetail}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Stack spacing={2}>
            <GeneralSelect
              value={sortValue}
              selectOptions={sortTypes}
              onOptionChange={handleSort}
            />
            <SearchField
              onSearchCallBack={handleSearch}
              placeHolderText={translate('placeholder.search_product_order_machine')}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card sx={{ height: '100%' }}>
            <Stack
              sx={{ mt: 4, mb: 4 }}
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
            >
              <Stack width={1} textAlign="center">
                <Typography variant="h4" color={'#438dcb'}>
                  {fNumber(allMachine.length)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {translate('title.all')}
                </Typography>
              </Stack>
              <Stack width={1} textAlign="center">
                <Typography variant="h4" color={'#bf572b'}>
                  {fNumber(
                    allMachine.filter((machine) => machine.ProductOrderPlannings.length > 0).length
                  )}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {translate('title.use')}
                </Typography>
              </Stack>
              <Stack width={1} textAlign="center">
                <Typography variant="h4" color={'#2eb8a4'}>
                  {fNumber(
                    allMachine.filter(
                      (machine) =>
                        machine.ProductOrderPlannings.length === 0 && machine.BreakAt === null
                    ).length
                  )}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {translate('title.free')}
                </Typography>
              </Stack>
              <Stack width={1} textAlign="center">
                <Typography variant="h4" color={'error'}>
                  {fNumber(allMachine.filter((machine) => machine.BreakAt !== null).length)}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {translate('status.machine_status_2')}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Scrollbar sx={{ mt: 2, maxHeight: 500 }}>
        <Grid container spacing={2}>
          {filterMachine.map((machine) => {
            return (
              <Grid item xs={12} lg={4}>
                <MachineDetailCard
                  machineDetail={machine}
                  openMachineDetail={handleOpenMachineDialog}
                />
              </Grid>
            );
          })}
        </Grid>
      </Scrollbar>
    </div>
  );
}

export default MachineListPlanning;
