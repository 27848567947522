import {
  cancelProductOrderAdditionalCostBillById,
  getProductOrderAdditionalCostBillDetails,
  getProductOrderAdditionalCostBillPDF,
} from '../ProductionServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLoading } from '../../components/loading';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { additional_cost_bill_prefix } from '../../utilities/Strings/Prefix';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useProductOrderAdditionalCostBill = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  const { data } = useQuery({
    queryKey: ['po-additional-cost-bill', id],
    queryFn: () => getProductOrderAdditionalCostBillDetails(id),
    initialData: null,
  });

  const printBillMutation = useMutation({
    mutationFn: getProductOrderAdditionalCostBillPDF,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintMultiplePDFMakeDocument(response.PDF, `${additional_cost_bill_prefix}_P${id}.pdf`);
      await OpenSuccessNotification();
    },
  });

  const cancelBillMutation = useMutation({
    mutationFn: cancelProductOrderAdditionalCostBillById,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['po-additional-cost-bill', id]);
      await OpenSuccessNotification();
    },
  });

  return {
    productOrderAdditionalCostBill: data,
    printBill: printBillMutation,
    cancelBill: cancelBillMutation,
  };
};
