import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { Box, Button, IconButton } from '@mui/material';
import ItemDetail from '../../details/warehouse/ItemDetail';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useTabs from '../../../hooks/useTabs';
import { OPEN_ICON } from '../../../config-global';
import { useItemDetail } from '../../../requests/query/useItemDetail';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

export default function ItemMaterialDetailPage() {
  const { translate } = useLocales();
  const { id } = useParams();
  const { itemDetail, printQRCode } = useItemDetail(id);
  const { currentTab, onChangeTab } = useTabs('detail');

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: <ItemDetail itemDetail={itemDetail} />,
    },
    {
      value: 'lots',
      title: translate('warehouse.request_item_history'),
      icon: <Iconify icon={'fe:list-order'} width={20} height={20} />,
      component: (
        <ProjectPaper>
          <ReactTable
            data={itemDetail?.ItemReceipts.map((itemReceipt) => {
              return {
                id: itemReceipt.Id,
                create_at: itemReceipt.createdAt,
                actions: itemReceipt.Id,
              };
            })}
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
              },
              {
                header: translate('title.create_at'),
                accessorKey: 'create_at',
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <IconButton
                    component={Link}
                    href={paths.warehouse.item_receipt_report_detail(info.getValue())}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                ),
              },
            ]}
          />
        </ProjectPaper>
      ),
    },
  ];

  const printItemQRCode = async () => {
    await printQRCode.mutateAsync(itemDetail.Id);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.item_detail')}
      breadcrumbs={[
        { name: translate('warehouse.material_store'), href: paths.warehouse.store },
        {
          name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${
            itemDetail?.ItemLot.ItemContainer.ItemMaster.Id
          }`,
          href: paths.warehouse.item_master_detail(itemDetail?.ItemLot.ItemContainer.ItemMaster.Id),
        },
        {
          name: `${translate('warehouse.item_container_detail')} ${translate('title.id')} : ${
            itemDetail?.ItemLot.ItemContainer.Id
          }`,
          href: paths.warehouse.item_container_detail(itemDetail?.ItemLot.ItemContainer.Id),
        },
        {
          name: `${translate('warehouse.lot_detail')} ${translate('title.id')} : ${
            itemDetail?.ItemLot.Id
          }`,
          href: paths.warehouse.item_lot_detail(itemDetail?.ItemLot.Id),
        },
        {
          name: `${translate('warehouse.item_detail')} ${translate('title.id')} : ${
            itemDetail?.Id
          }`,
        },
      ]}
      action={
        <Button
          variant="contained"
          color={'info'}
          startIcon={<Iconify icon={'ic:outline-print'} />}
          onClick={printItemQRCode}
        >
          {translate('button.print_item_qr')}
        </Button>
      }
    >
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {INFORMATION_TABS.map((tab, index) => (
          <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>
      <Box sx={{ mb: 3 }} />
      {INFORMATION_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && tab.component;
      })}
    </ProjectPageContainer>
  );
}
