// ----------------------------------------------------------------------

function getFirstCharacter(name) {
  return name && name.charAt(0).toUpperCase();
}

function getAvatarColor() {
  // if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'primary';
  // if (INFO_NAME.includes(getFirstCharacter(name))) return 'info';
  // if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'success';
  // if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning';
  // if (ERROR_NAME.includes(getFirstCharacter(name))) return 'warning';
  return 'default';
}

export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
  };
}
