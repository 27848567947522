import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ItemTemplateDetailComponent } from '../../details/general-warehouse/ItemTemplateDetailComponent';
import { ItemTemplateHistoryDetailComponent } from '../../details/general-warehouse/ItemTemplateHistoryDetailComponent';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import LoadingComponent from '../../customs/LoadingComponent';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import useTabs from '../../../hooks/useTabs';
import ItemTemplateLotDetail from '../../details/general-warehouse/ItemTemplateLotDetail';
import Swal from 'sweetalert2';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  allowToEditItemDetailUserTypeId,
  allowToEditItemTemplateAmountUserTypeId,
  superiorLevelUserTypeId,
} from '../../../utilities/Constants';
import useAuth from '../../../auth/hooks/useAuth';
import ItemTemplateDetailForm from '../../forms/ItemTemplateDetailForm';
import { useItemTemplateDetail } from '../../../requests/query/useItemTemplateDetail';
import { paths } from '../../../routes/paths';

export default function ItemTemplateDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const { user } = useAuth();
  const [isEditMode, setIsEditMode] = useState(false);
  const { currentTab, onChangeTab } = useTabs('detail');
  const {
    itemTemplateDetail,
    toggleQuickRequestItem,
    editAmount,
    printItemTemplateQRCode,
    printItemTemplateQRStickerCode,
  } = useItemTemplateDetail(id);

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: !isEditMode ? (
        <ItemTemplateDetailComponent itemTemplate={itemTemplateDetail} />
      ) : (
        <ItemTemplateDetailForm itemTemplateDetail={itemTemplateDetail} />
      ),
    },
    {
      value: 'lots',
      title: translate('warehouse.lot'),
      icon: <Iconify icon={'fe:list-order'} width={20} height={20} />,
      component: <ItemTemplateLotDetail itemTemplateLot={itemTemplateDetail?.Inventory} />,
    },
    {
      value: 'history',
      title: translate('warehouse.movement_history'),
      icon: <Iconify icon={'ant-design:history-outlined'} width={20} height={20} />,
      component: <ItemTemplateHistoryDetailComponent itemTemplate={itemTemplateDetail} />,
    },
  ];
  const handleToggleQuickRequestItemTemplate = async () => {
    await toggleQuickRequestItem.mutateAsync({
      ItemTemplateId: itemTemplateDetail.Id,
      IsActive: !itemTemplateDetail.IsQuickRequestEnable,
    });
  };
  const handleOpenEditItemTemplateAmount = async () => {
    await Swal.fire({
      title: translate('button.edit_amount'),
      input: 'text',
      inputLabel: `${itemTemplateDetail.Name} (${itemTemplateDetail.ItemTemplateUnit.Name})`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      inputValue: itemTemplateDetail.CurrentAmount,
      confirmButtonText: translate('button.confirm'),
      cancelButtonText: translate('button.cancel'),
      inputValidator: async (value) => {
        if (!value) {
          return translate('error.error_invalid_amount');
        } else {
          const amount = parseFloat(value);
          if (isNaN(amount)) {
            return translate('error.error_invalid_amount');
          } else {
            if (!superiorLevelUserTypeId.includes(user.UserTypeId) && amount <= 0) {
              return translate('error.error_invalid_amount');
            }
          }
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await editAmount.mutateAsync({
          ItemTemplateId: itemTemplateDetail.Id,
          Amount: parseInt(result.value),
        });
      }
    });
  };
  const toggleEditMode = () => {
    setIsEditMode(true);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.item_template_detail')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.title'), href: paths.general_warehouse.store },
        { name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${id}` },
      ]}
      action={
        <Stack spacing={2} direction={'column'}>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={printItemTemplateQRCode}
          >
            {translate('button.print_item_qr')}
          </Button>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={printItemTemplateQRStickerCode}
          >
            {`${translate('button.print_item_qr')} (Sticker)`}
          </Button>
        </Stack>
      }
    >
      {itemTemplateDetail !== null ? (
        <>
          {currentTab === 'detail' && !isEditMode && (
            <Stack spacing={2} direction={'row'} sx={{ mb: 3 }}>
              <RoleBasedGuard roles={allowToEditItemDetailUserTypeId}>
                <Button
                  variant="contained"
                  color={itemTemplateDetail?.IsQuickRequestEnable ? 'error' : 'primary'}
                  startIcon={
                    <Iconify
                      icon={
                        itemTemplateDetail?.IsQuickRequestEnable
                          ? 'ic:baseline-close'
                          : 'ic:baseline-check'
                      }
                    />
                  }
                  onClick={handleToggleQuickRequestItemTemplate}
                >
                  {translate(
                    itemTemplateDetail?.IsQuickRequestEnable
                      ? 'warehouse.remove_from_quick_request_title'
                      : 'warehouse.add_to_quick_request_title'
                  )}
                </Button>
                <Button
                  variant="contained"
                  color={'info'}
                  startIcon={<Iconify icon={'bx:edit'} />}
                  onClick={toggleEditMode}
                >
                  {translate('button.edit_data')}
                </Button>
              </RoleBasedGuard>
              <RoleBasedGuard roles={allowToEditItemTemplateAmountUserTypeId}>
                <Button
                  variant="contained"
                  color={'info'}
                  startIcon={<Iconify icon={'bx:edit'} />}
                  onClick={handleOpenEditItemTemplateAmount}
                >
                  {translate('button.edit_amount')}
                </Button>
              </RoleBasedGuard>
            </Stack>
          )}
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {INFORMATION_TABS.map((tab, index) => (
              <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
          <Box sx={{ mb: 3 }} />
          {INFORMATION_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && tab.component;
          })}
        </>
      ) : (
        <LoadingComponent />
      )}
    </ProjectPageContainer>
  );
}
