import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId, ManagementUserTypeId,
  ProductionControlUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
} from '../../utilities/Constants';
import CreateProductPage from '../../components/pages/ProductModule/CreateProductPage';
import ProductListPage from '../../components/pages/ProductModule/ProductListPage';
import ProductDetailPage from '../../components/pages/ProductModule/ProductDetailPage';
import { paths } from '../paths';
import CreateMaterialPage from '../../components/pages/ProductModule/CreateMaterialPage';
import BOMListPage from '../../components/pages/ProductModule/BOMListPage';
import CreateBomPage from '../../components/pages/ProductModule/CreateBOMPage';

const productModuleRouteDetails = [
  {
    path: paths.product.product_detail(':id'),
    component: <ProductDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      SalesUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      FinanceUserTypeId,
    ],
  },
  {
    path: paths.product.create_product,
    component: <CreateProductPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.product.product_list,
    component: <ProductListPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.product.create_material,
    component: <CreateMaterialPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ManagementUserTypeId],
  },
  {
    path: paths.product.product_bom_list,
    component: <BOMListPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ManagementUserTypeId],
  },
  {
    path: paths.product.create_bom,
    component: <CreateBomPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, ManagementUserTypeId],
  },
];
export default productModuleRouteDetails;
