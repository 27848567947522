import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllUserType } from '../../../requests/LoginServerHandler';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from '../../../locales';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../../minimal/hook-form';
import {
  getEmployeeDetailByEmployeeCode,
  bindEmployeeWithUserId,
} from '../../../requests/ProductionServerHandler';
import { createUser } from '../../../requests/LoginServerHandler';
import { bindWarehouseEmployeeWithUserId } from '../../../requests/WarehouseServerHandler';

const CreateUserPage = () => {
  const { translate } = useLocales();
  const { data } = useQuery({
    queryKey: ['getAllUserType'],
    queryFn: getAllUserType,
    initialData: { UserType: [] },
  });
  const createUserMutation = useMutation(createUser);
  const bindEmployeeMutation = useMutation(bindEmployeeWithUserId);
  const checkEmployeeMutation = useMutation(getEmployeeDetailByEmployeeCode);
  const bindWarehouseEmployeeMutation = useMutation(bindWarehouseEmployeeWithUserId);


  const userTask = async (values) => {
    try {
      const checkEmployee = await checkEmployeeMutation.mutateAsync(values.EmployeeCode);
      if (checkEmployee !== null && checkEmployee.UserId === null) {
        const newUser = await createUserMutation.mutateAsync(values);
        if (newUser !== null) {
          const bindEmployee = await bindEmployeeMutation.mutateAsync({
            EmployeeId: checkEmployee.Id,
            UserId: newUser.Id,
          });
          const bindWarehouseEmployee = await bindWarehouseEmployeeMutation.mutateAsync({
            EmployeeId: checkEmployee.Id,
            UserId: newUser.Id,
          });
          await OpenNotificationThenReloadPage('สร้างสำเร็จ');
          if (bindEmployee !== null && bindWarehouseEmployee !== null) {
            return bindEmployee.Id;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        // Handle the case where the employee is already added
        await OpenErrorNotification('สร้างไปแล้ว');
        return null;
      }
    } catch (e) {
      await OpenErrorNotification('สร้างไปแล้ว');
    }
  };

  const createUserSchema = Yup.object().shape({
    Username: Yup.string().required(translate('error.detail_require')),
    Password: Yup.string().required(translate('error.detail_require')),
    UserType: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
    EmployeeCode: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(createUserSchema),
  });

  const { handleSubmit } = methods;

  const handleCreateUser = async (values) => {
    await userTask(values);

  };

  return (
    <ProjectPageContainer
      menu_title={translate('hr.add_user')}
      breadcrumbs={[{ name: translate('hr.title') }, { name: translate('hr.add_user') }]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleCreateUser)}>
          <Stack spacing={3}>
            <RHFTextField name={'Username'} label={translate('auth.username')} />
            <RHFTextField name={'Password'} label={translate('auth.password')} />
            <RHFTextField name={'EmployeeCode'} label={translate('user.employee_code')} />
            <RHFAutoCompleteShort
              name={'UserType'}
              label={translate('title.type')}
              data={data.UserType.filter((userType) => userType.Id !== ManagementUserTypeId)}
            />
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default CreateUserPage;
