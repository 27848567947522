import React from 'react';
import { RouterLink } from 'routes/components';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllProductSalesHistory } from '../../../requests/ProductionServerHandler';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { useLocales } from 'locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import { Button, IconButton } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';

function ProductListPage() {
  const { translate } = useLocales();

  const { data: allProduct } = useQuery({
    queryKey: ['getAllProductSalesHistory', 0],
    queryFn: () => getAllProductSalesHistory(0),
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('product.list')}
      breadcrumbs={[{ name: translate('product.title') }, { name: translate('product.list') }]}
      action={
        <RoleBasedGuard roles={[ManagementUserTypeId]}>
          <Button
            variant="contained"
            color={'primary'}
            component={RouterLink}
            href={paths.product.create_product}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {translate('product.create_new')}
          </Button>
        </RoleBasedGuard>
      }
    >
      <ProjectPaper>
        <ReactTable
          search
          data={allProduct.map((product) => {
            return {
              name: product.Name,
              type_name: product.ProductType.Name,
              create: product.createdAt,
              actions: product.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.product_type'),
              accessorKey: 'type_name',
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  component={RouterLink}
                  href={paths.product.product_detail(info.getValue())}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default ProductListPage;
