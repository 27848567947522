import React, { useEffect } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import {useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import Swal from 'sweetalert2';
import {
  adjustPartsPurchaseCart,
  selectPartsPurchaseCart,
} from '../../../redux/reducers/inventory';
import { OpenErrorNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import { Button, IconButton, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import GeneralTable from '../../customs/GeneralTable';
import { paths } from '../../../routes/paths';
import { usePartPurchaseRequisition } from '../../../requests/query/usePartPurchaseRequisition';

function CreatePurchasePartsRequisitionPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const PartsPurchaseCart = useSelector(selectPartsPurchaseCart);
  const { createPR } = usePartPurchaseRequisition(null);

  const createPartPRSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });
  const methods = useForm({
    resolver: yupResolver(createPartPRSchema),
  });
  const { handleSubmit, control, setValue } = methods;

  const detailWatch = useWatch({
    control,
    name: 'Detail',
    defaultValue: '',
  });

  useEffect(() => {
    const detailFromSessionStorage = sessionStorage.getItem('pr_parts_detail');
    if (detailFromSessionStorage !== null) {
      setValue('Detail', detailFromSessionStorage);
    }
  }, [setValue]);
  useEffect(() => {
    sessionStorage.setItem('pr_parts_detail', detailWatch);
  }, [dispatch, detailWatch]);

  const onAddItem = (itemDetail) => {
    dispatch(
      adjustPartsPurchaseCart({
        Id: itemDetail.Id,
        Name: itemDetail.Name,
        Amount: itemDetail.Amount + 1,
      })
    );
  };
  const onRemoveItem = (itemDetail) => {
    dispatch(
      adjustPartsPurchaseCart({
        Id: itemDetail.Id,
        Name: itemDetail.Name,
        Amount: itemDetail.Amount - 1,
      })
    );
  };
  const onDeleteItem = (itemDetail) => {
    dispatch(adjustPartsPurchaseCart({ Id: itemDetail.Id, Name: itemDetail.Name, Amount: 0 }));
  };

  const onCreatePurchaseRequisition = async (data) => {
    if (PartsPurchaseCart.length > 0) {
      Swal.fire({
        title: translate('warning.is_this_urgent'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: translate('procurement.urgent'),
        cancelButtonText: translate('procurement.not_urgent'),
      }).then(async (result) => {
        let priorityId = 1;
        if (result.isConfirmed) {
          priorityId = 2;
        }

        await createPR.mutateAsync({
          Detail: data.Detail,
          PriorityLevelId: priorityId,
          PurchaseRequisitionItemTemplate: PartsPurchaseCart,
        });
      });
    } else {
      await OpenErrorNotification('กรุณาเลือกของก่อน');
    }
  };
  const handleOpenRequestItemStorePage = () => {
    navigate(paths.procurement.part_store);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.create_parts_purchase_requisition_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.create_parts_purchase_requisition_title') },
      ]}
      action={
        <Stack spacing={3} direction={'row'}>
          <Button
            color={'primary'}
            variant={'contained'}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={handleOpenRequestItemStorePage}
          >
            {translate('button.add')}
          </Button>
        </Stack>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onCreatePurchaseRequisition)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name={'Detail'} label={translate('title.detail')} />
          </Stack>
        </ProjectPaper>
        <ProjectPaper>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: '#' },
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'title.amount' },
              { align: 'left', title: '' },
            ]}
            tableData={PartsPurchaseCart.map((item, index) => {
              return [
                index + 1,
                <Link href={paths.general_warehouse.item_template_detail(item.Id)}>{item.Name}</Link>,
                item.Amount,
                <>
                  <IconButton
                    disabled={item.Amount >= item.MaxAmount}
                    color={'success'}
                    onClick={() => onAddItem(item)}
                  >
                    <Iconify icon={'ant-design:plus-outlined'} width={20} height={20} />
                  </IconButton>
                  <IconButton color={'error'} onClick={() => onRemoveItem(item)}>
                    <Iconify icon={'ant-design:minus-outlined'} width={20} height={20} />
                  </IconButton>
                  <IconButton color={'error'} onClick={() => onDeleteItem(item)}>
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>
                </>,
              ];
            })}
          />
          <Button
            color={'primary'}
            disabled={PartsPurchaseCart.length === 0}
            variant={'contained'}
            fullWidth
            type="submit"
          >
            {translate('button.create')}
          </Button>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
}

export default CreatePurchasePartsRequisitionPage;
