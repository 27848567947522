import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useLocales } from 'locales';
import { fCurrency, fPercent } from 'utilities/formatNumber';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { getMainProductionDashboardData } from 'requests/ProductionServerHandler';
import DetailCard from 'components/dashboard/DetailCard';
import DashboardGraphCard from 'components/dashboard/DashboardGraphCard';
import { getSalesDashboardData } from 'utilities/formatServerData';
import Iconify from 'components/minimal/iconify';
import GeneralTable from 'components/customs/GeneralTable';
import Label from 'components/minimal/label';
import { useTheme } from '@mui/material/styles';
import { paths } from 'routes/paths';
import { RouterLink } from 'routes/components';
import { OPEN_ICON } from 'config-global';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  HumanResourceUserTypeId,
  ManagementUserTypeId,
  ProductionControlUserTypeId,
  thaiMonths,
} from '../../../utilities/Constants';

function MainDashboardPage() {
  const { translate } = useLocales();
  const theme = useTheme();
  const { data } = useQuery({
    queryKey: ['main-dashboard-data'],
    queryFn: getMainProductionDashboardData,
  });
  const salesDashboardData = useMemo(() => {
    if (data !== undefined) {
      return getSalesDashboardData(data.SalesData);
    }
  }, [data]);

  const getProductOrderStatusColor = (id) => {
    if (id === 1) {
      return 'info';
    }
    if (id === 2) {
      return 'warning';
    }
    if (id === 3) {
      return 'success';
    }
    return 'error';
  };
  return (
    <ProjectPageContainer menu_title={translate('dashboard')}>
      {salesDashboardData !== undefined ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {translate('dashboard')}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <DetailCard
              title={translate('sales.total_sales')}
              icon={'eva:diagonal-arrow-left-down-fill'}
              color={salesDashboardData.CurrentDifferent > 0 ? 'success' : 'error'}
              percent={salesDashboardData.CurrentDifferent}
              total={salesDashboardData.CurrentMonthSalesValue}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DetailCard
              title={translate('sales.monthly_sales')}
              icon={'eva:diagonal-arrow-left-down-fill'}
              color={salesDashboardData.MonthlyDifferent > 0 ? 'success' : 'error'}
              percent={salesDashboardData.MonthlyDifferent}
              total={salesDashboardData.MonthlySalesValue}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DetailCard
              title={translate('sales.total_active_product_order')}
              icon={'ic:baseline-work-outline'}
              color={'info'}
              isDetail
              footer={translate('unit.order')}
              total={salesDashboardData.TotalProductOrder}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <DashboardGraphCard
              title={translate('sales.total_sales')}
              subheader={`${fPercent(salesDashboardData.CurrentDifferent)} ${translate(
                'sales.compare_to_last_year'
              )}`}
              chart={{
                categories: thaiMonths,
                series: [
                  {
                    year: salesDashboardData.SalesChartData[0].name,
                    data: [
                      {
                        name: salesDashboardData.SalesChartData[0].name,
                        data: salesDashboardData.SalesChartData[0].data,
                      },
                      {
                        name: salesDashboardData.SalesChartData[1].name,
                        data: salesDashboardData.SalesChartData[1].data,
                      },
                    ],
                  },
                ],
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader title={translate('sales.latest_product_order')} />
              <CardContent>
                <GeneralTable
                  tableHeaders={[
                    { align: 'left', title: 'title.id' },
                    { align: 'left', title: 'title.name' },
                    { align: 'left', title: 'title.value' },
                    { align: 'left', title: 'title.status' },
                    { align: 'left', title: 'Action' },
                  ]}
                  tableData={salesDashboardData.LatestProductOrder.map((productOrder) => [
                    productOrder.Id,
                    productOrder.Name,
                    fCurrency(productOrder.Value),
                    <Label
                      variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                      color={getProductOrderStatusColor(productOrder.ProductOrderStatus.Id)}
                    >
                      {productOrder.ProductOrderStatus.Name}
                    </Label>,
                    <IconButton
                      component={RouterLink}
                      to={paths.production.product_order_detail(productOrder.Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>,
                  ])}
                />
                <Divider />
                <Box sx={{ p: 2, textAlign: 'right' }}>
                  <RoleBasedGuard
                    roles={[
                      ManagementUserTypeId,
                      AccountingUserTypeId,
                      AdminUserTypeId,
                      ProductionControlUserTypeId,
                      HumanResourceUserTypeId,
                    ]}
                  >
                    <Button
                      color="inherit"
                      endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
                      component={RouterLink}
                      href={paths.production.product_order_list}
                    >
                      {translate('button.view_all')}
                    </Button>
                  </RoleBasedGuard>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader title={translate('title.focus_job')} />
              <CardContent>
                <GeneralTable
                  tableHeaders={[
                    { align: 'left', title: 'title.id' },
                    { align: 'left', title: 'title.name' },
                    { align: 'left', title: 'title.value' },
                    { align: 'left', title: 'title.status' },
                    { align: 'left', title: 'Action' },
                  ]}
                  tableData={salesDashboardData.FocusProductOrder.map((productOrder) => [
                    productOrder.Id,
                    productOrder.Name,
                    fCurrency(productOrder.Value),
                    <Label
                      variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                      color={getProductOrderStatusColor(productOrder.ProductOrderStatus.Id)}
                    >
                      {productOrder.ProductOrderStatus.Name}
                    </Label>,
                    <IconButton
                      component={RouterLink}
                      to={paths.production.product_order_detail(productOrder.Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>,
                  ])}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : null}
    </ProjectPageContainer>
  );
}

export default MainDashboardPage;
