import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, IconButton } from '@mui/material';
import { RouterLink } from '../../../routes/components';
import GeneralTable from '../../customs/GeneralTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from '../../minimal/iconify';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';

ProductOrderDeliveryBillDetail.propTypes = {
  deliveryBill: PropTypes.array,
};

function ProductOrderDeliveryBillDetail({ deliveryBill }) {
  return (
    <Card>
      <CardContent>
        <GeneralTable
          tableHeaders={[
            { align: 'left', title: 'title.id' },
            { align: 'left', title: 'title.delivery_by' },
            { align: 'left', title: 'title.create_at' },
            { align: 'left', title: 'title.canceled_at' },
            { align: 'left', title: 'title.pile_amount' },
            { align: 'left', title: '' },
          ]}
          tableData={deliveryBill.map((bill) => [
            bill.Id,
            bill.CreateEmployee,
            fDateTimeText(bill.createdAt),
            fDateTimeText(bill.ArchivedAt),
            bill.PileAmount,
            <IconButton
              key={bill.Id}
              component={RouterLink}
              href={paths.accounting.delivery_bill_detail(bill.Id)}
            >
              <Iconify icon={OPEN_ICON} />
            </IconButton>,
          ])}
        />
      </CardContent>
    </Card>
  );
}

export default ProductOrderDeliveryBillDetail;
