import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoadingModal from './components/customs/LoadingComponent';
import './App.css';
import MainRouter from './routes/MainRouter';
import DashboardLayout from 'layouts/dashboard';
import { SettingsDrawer } from './components/minimal/settings';
import ProgressBar from 'components/minimal/progress-bar';
import { GlobalLoadingComponent } from './components/loading';

function App() {
  return (
    <BrowserRouter>
      <GlobalLoadingComponent />
      <SettingsDrawer />
      <ProgressBar />
      <LoadingModal />
      <DashboardLayout>
        <MainRouter />
      </DashboardLayout>
    </BrowserRouter>
  );
}

export default App;
