import ChangePileAmountPage from '../../components/pages/AdminModule/ChangePileAmountPage';
import ChangeProductOrderAmountPage from '../../components/pages/AdminModule/ChangeProductOrderAmountPage';
import ChangeSizePage from '../../components/pages/AdminModule/ChangeSizePage';
import ChangePileGoodAmountPage from '../../components/pages/AdminModule/ChangePileGoodAmountPage';
import ChangeProductSellHistoryPricePage from '../../components/pages/AdminModule/ChangeProductSellHistoryPricePage';
import { ManagementUserTypeId } from '../../utilities/Constants';
import ChangeProductOrderPricePage from '../../components/pages/AdminModule/ChangeProductOrderPricePage';
import EditProductOrderPriceToBundlePage from '../../components/pages/AdminModule/EditProductOrderPriceToBundlePage';
import { paths } from '../paths';

const adminModuleRouteDetails = [
  {
    path: paths.admin.edit_pile_amount,
    component: <ChangePileAmountPage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
  {
    path: paths.admin.edit_product_order_amount,
    component: <ChangeProductOrderAmountPage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
  {
    path: paths.admin.edit_size,
    component: <ChangeSizePage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
  {
    path: paths.admin.edit_pile_good_amount,
    component: <ChangePileGoodAmountPage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
  {
    path: paths.admin.edit_price,
    component: <ChangeProductSellHistoryPricePage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
  {
    path: paths.admin.edit_po_price,
    component: <ChangeProductOrderPricePage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
  {
    path: paths.admin.change_price_to_bundle,
    component: <EditProductOrderPriceToBundlePage />,
    isPrivate: true,
    allowUserTypeId: [ManagementUserTypeId],
  },
];
export default adminModuleRouteDetails;
