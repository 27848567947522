import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { getItemMasterStockTransactionData } from '../../../requests/WarehouseServerHandler';
import ReactTable from '../../react-table/ReactTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import Typography from '@mui/material/Typography';
import ProjectPaper from '../../customs/ProjectPaper';
import { useLocales } from '../../../locales';
import { useTheme } from '@mui/material/styles';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useQuery } from '@tanstack/react-query';

export const ItemMasterHistoryDetailComponent = ({ itemMaster }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const { data, refetch } = useQuery({
    queryKey: ['item-master-stock-transaction', itemMaster.Id, dateRange],
    queryFn: () =>
      getItemMasterStockTransactionData(itemMaster.Id, {
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
      }),
    initialData: [],
  });

  const handleTableData = (rawData) => {
    return rawData.map((transaction) => {
      return {
        report_id: transaction.ItemReceiptId !== null ? transaction.ItemReceiptId : 'N/A',
        amount: transaction.Amount,
        unit: transaction.ItemMaster.ItemUnit.Name,
        cut_at: transaction.createdAt,
        fulfill_by: transaction.CreateEmployee,
        accept_by:
          transaction.ItemReceipt !== null && transaction.ItemReceipt.AcceptEmployee !== undefined
            ? transaction.ItemReceipt.AcceptEmployee
            : 'N/A',
        accepted_at:
          transaction.ItemReceipt !== null && transaction.ItemReceipt.AcceptedAt !== null
            ? transaction.ItemReceipt.AcceptedAt
            : null,
        create_at: transaction.createdAt,
        transaction_type: transaction.ItemMovementType,
        actions: null,
      };
    });
  };
  const fetchHistoryDataByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };
  return (
    <React.Fragment>
      <SILDatePicker onSearchDate={fetchHistoryDataByDate} />
      <ProjectPaper>
        <ReactTable
          data={handleTableData(data)}
          header={[
            {
              header: translate('warehouse.stock_report_id'),
              accessorKey: 'report_id',
              size: 20,
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
              size: 20,
            },
            {
              header: translate('unit.title'),
              accessorKey: 'unit',
              size: 20,
            },
            {
              header: translate('warehouse.check_out_at_title'),
              accessorKey: 'cut_at',
              cell: (info) => fDateTimeText(info.getValue()),
              size: 20,
            },
            {
              header: translate('warehouse.fulfill_by_title'),
              accessorKey: 'fulfill_by',
              cell: (info) => getEmployeeFullName(info.getValue()),
              size: 20,
            },
            {
              header: translate('warehouse.accept_at_title'),
              accessorKey: 'accepted_at',
              cell: (info) => {
                const report = info.getValue();
                return report !== null ? fDateTimeText(report.AcceptedAt) : 'N/A';
              },
            },
            {
              header: translate('warehouse.accept_by_title'),
              accessorKey: 'accept_by',
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('title.type'),
              accessorKey: 'transaction_type',
              cell: (info) => {
                return (
                  <Typography
                    variant={'body2'}
                    color={
                      info.getValue().Id === 1
                        ? theme.palette.error.main
                        : theme.palette.success.main
                    }
                  >
                    {info.getValue().Name}
                  </Typography>
                );
              },
            },
          ]}
        />
      </ProjectPaper>
    </React.Fragment>
  );
};

ItemMasterHistoryDetailComponent.PropType = {
  itemMaster: PropTypes.object.isRequired,
};
