import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { fCurrency, fNumber } from '../../../utilities/formatNumber';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { useLocales } from '../../../locales';

ProductDetail.propTypes = {
  productDetail: PropTypes.object.isRequired,
};

function ProductDetail({ productDetail }) {
  const { translate } = useLocales();

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <GridDetailText title={translate('title.id')} value={productDetail?.Id} />
            <GridDetailText title={translate('title.name')} value={productDetail?.Name} />
            <GridDetailText title={translate('title.detail')} value={productDetail?.Detail} />
            <GridDetailText
              title={translate('title.product_type')}
              value={productDetail?.ProductType.Name}
            />
            <GridDetailText title={translate('title.sku')} value={productDetail?.SKU} />
            <GridDetailText
              title={translate('title.target_per_month')}
              value={fNumber(productDetail?.TargetPerMonth)}
            />
            <GridDetailText
              title={translate('title.price_per_sheet')}
              value={fCurrency(productDetail?.BasePrice)}
            />
            <GridDetailText
              title={translate('title.bundle_price')}
              value={fCurrency(productDetail?.BundlePrice)}
            />
            <GridDetailText
              title={translate('title.minimum_bundle_price')}
              value={fCurrency(productDetail?.MinimumValue)}
            />
            <GridDetailText
              title={translate('title.minimum_amount_order')}
              value={fNumber(productDetail?.MinimumAmount)}
            />
            <RoleBasedGuard roles={[ManagementUserTypeId]}>
              <GridDetailText
                title={translate('title.vat')}
                value={productDetail.IsTaxable ? translate('button.yes') : translate('button.no')}
              />
              <GridDetailText
                title={translate('title.discountable')}
                value={
                  productDetail?.IsDiscountable ? translate('button.yes') : translate('button.no')
                }
              />
              <GridDetailText
                title={translate('title.minimum_price_per_sheet')}
                value={productDetail?.MinimumPrice}
              />
              <GridDetailText
                title={translate('title.price_per_square_inch')}
                value={productDetail?.PricePerSquareInch}
              />
            </RoleBasedGuard>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardHeader title={translate('title.process')} />
        <CardContent>
          {productDetail?.ProductHasProductionProcesses.map((productProcess, index) => {
            return (
              <Typography key={index} variant="body1">
                {`${index + 1}. ${productProcess.ProductionProcess.Name} (${
                  productProcess.ProductionProcess.ProductionProcessType.Name
                })`}
              </Typography>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
}

export default ProductDetail;
