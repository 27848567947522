import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import IconButton from '@mui/material/IconButton';
import { selectPurchaseOrderItemCart } from '../../../redux/reducers/procurement';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { useLocales } from 'locales';
import Typography from '@mui/material/Typography';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import { getItemToPurchases, cancelPRItemMaster } from '../../../requests/WarehouseServerHandler';
import { OPEN_ICON } from '../../../config-global';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { RouterLink } from '../../../routes/components';

function CreatePurchaseOrderPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const PurchaseOrderItems = useSelector(selectPurchaseOrderItemCart);

  const { data: itemToPurchase, refetch } = useQuery({
    queryKey: ['getItemToPurchases'],
    queryFn: getItemToPurchases,
    initialData: {
      ItemMaster: [],
    },
  });

  const cancelPRItemMasterMutation = useMutation({
    mutationFn: cancelPRItemMaster,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await refetch();
    },
  });

  const getPriorityText = (priorityLevel) => {
    if (priorityLevel.Id === 1) {
      return <Typography variant={'body2'}>{priorityLevel.Name}</Typography>;
    } else if (priorityLevel.Id === 2) {
      return (
        <Typography variant={'body2'} color={'warning'}>
          {priorityLevel.Name}
        </Typography>
      );
    } else if (priorityLevel.Id === 3) {
      return (
        <Typography variant={'body2'} color={'error'}>
          {priorityLevel.Name}
        </Typography>
      );
    }
  };
  const handleCancelItem = async (prItemMasterId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_delete_data',
      async () => {
        await cancelPRItemMasterMutation.mutateAsync(prItemMasterId);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.create_po_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.create_po_title') },
      ]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={PurchaseOrderItems.length === 0}
          to={paths.procurement.check_out_po}
        >
          {`${translate('procurement.buy_item')} ${PurchaseOrderItems.length} ${translate(
            'warehouse.items'
          )}`}
        </Button>
      }
    >
      <ProjectPaper>
        {itemToPurchase !== null && (
          <ReactTable
            search
            data={itemToPurchase.ItemMaster.map((itemToPurchase) => {
              return {
                template_id: itemToPurchase.ItemMasterId,
                pr_id: itemToPurchase.PurchaseRequisitionId,
                name: itemToPurchase.ItemMaster.Name,
                type: itemToPurchase.ItemMaster.ItemType.Name,
                priority: itemToPurchase.PurchaseRequisition.PriorityLevel,
                create_by: itemToPurchase.PurchaseRequisition.CreateEmployee,
                create_department:
                  itemToPurchase.PurchaseRequisition.CreateEmployee.Department.Name,
                create_at: itemToPurchase.PurchaseRequisition.createdAt,
                amount: `${itemToPurchase.Amount - itemToPurchase.PurchaseAmount} ${
                  itemToPurchase.ItemMaster.ItemUnit.Name
                }`,
                actions: {
                  Id: itemToPurchase.Id,
                  ItemMasterId: itemToPurchase.ItemMasterId,
                },
              };
            })}
            header={[
              {
                header: translate('title.name'),
                accessorKey: 'name',
              },
              {
                header: translate('procurement.pr_id'),
                accessorKey: 'pr_id',
                cell: (info) => (
                  <Link href={paths.procurement.pr_detail(info.getValue())}>{info.getValue()}</Link>
                ),
                size: 50,
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
              },
              {
                header: translate('title.type'),
                accessorKey: 'type',
                size: 50,
              },
              {
                header: translate('title.create_by'),
                accessorKey: 'create_by',
                cell: (info) => getEmployeeFullName(info.getValue()),
                size: 50,
              },
              {
                header: translate('user.department'),
                accessorKey: 'create_department',
                size: 50,
              },
              {
                header: translate('title.create_at'),
                accessorKey: 'create_at',
                cell: (info) => fDateTimeText(info.getValue()),
              },
              {
                header: translate('title.priority_level_title'),
                accessorKey: 'priority',
                cell: (info) => getPriorityText(info.getValue()),
                size: 20,
              },
              {
                header: '',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <IconButton
                      onClick={() =>
                        navigate(paths.warehouse.item_master_detail(info.getValue().ItemMasterId))
                      }
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton
                      color={'error'}
                      onClick={() => handleCancelItem(info.getValue().Id)}
                    >
                      <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                    </IconButton>
                    <IconButton
                      color={'success'}
                      onClick={() =>
                        navigate(paths.procurement.search_supplier_sell_item(info.getValue().Id))
                      }
                    >
                      <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
                size: 150,
              },
            ]}
          />
        )}
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreatePurchaseOrderPage;
