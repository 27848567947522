import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { Box, Stack } from '@mui/material';
import { CameraScanner } from './index';
import Button from '@mui/material/Button';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../customs/ProjectPaper';
import BarcodeReader from 'react-barcode-reader';
import { OpenErrorNotification } from '../../utilities/Helper/NotificationHelper';

DataScanner.propTypes = {
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
  onScanCallback: PropTypes.func.isRequired,
  FormComponent: PropTypes.node.isRequired,
};

function DataScanner({ disableScan, disableInput, onScanCallback, FormComponent }) {
  const { translate } = useLocales();
  const [isOpenCameraReader, setIsOpenCameraReader] = useState(false);

  const handleTriggerCameraBarCodeReader = (value) => {
    setIsOpenCameraReader(value);
  };
  const onCameraBarCodeScan = async (data) => {
    setIsOpenCameraReader(false);
    onScanCallback(data);
  };

  const onBarCodeError = async (err) => {
    if (err.toString().trim() !== '') {
      await OpenErrorNotification(err.toString());
      setIsOpenCameraReader(false);
    }
  };
  return (
    <div>
      {isOpenCameraReader ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CameraScanner onScanCallback={onCameraBarCodeScan} />
          <Button
            sx={{ mt: 2 }}
            onClick={() => handleTriggerCameraBarCodeReader(false)}
            color={'error'}
            startIcon={<Iconify icon={'ant-design:camera-outlined'} />}
            variant={'contained'}
          >
            {translate('button.close_camera')}
          </Button>
        </Box>
      ) : (
        <ProjectPaper>
          <Stack spacing={2}>
            {!disableInput && FormComponent}
            {!disableScan && (
              <Button
                onClick={() => handleTriggerCameraBarCodeReader(true)}
                color={'primary'}
                startIcon={<Iconify icon={'ant-design:camera-outlined'} />}
                variant={'contained'}
              >
                {translate('button.open_camera_for_scan')}
              </Button>
            )}
          </Stack>
          <BarcodeReader onError={onBarCodeError} onScan={onCameraBarCodeScan} />
        </ProjectPaper>
      )}
    </div>
  );
}

export default DataScanner;
