import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getProductOrderDetailWithProcessById,
  cancelProductOrder,
  getProductOrderDeliveryReport,
  completeProductOrder,
  createFocusPO,
  removeFocusPO,
  editProductOrderName,
  editProductOrderDetail,
} from '../ProductionServerHandler';
import { useLoading } from '../../components/loading';
import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useProductOrderDetail = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['product-order-detail', id],
    queryFn: () => getProductOrderDetailWithProcessById(id),
    initialData: {
      Detail: null,
      ProcessDetail: [],
      BillingNotes: [],
      DeliveryBills: [],
    },
  });

  const cancelMutation = useMutation({
    mutationFn: cancelProductOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-detail', id]);
      await OpenSuccessNotification();
    },
  });

  const getDeliveryReportPDFMutation = useMutation({
    mutationFn: getProductOrderDeliveryReport,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(response, `DeliveryReport_${id}.pdf`);
      await OpenSuccessNotification();
    },
  });

  const completeMutation = useMutation({
    mutationFn: completeProductOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-detail', id]);
      await OpenSuccessNotification();
    },
  });

  const markFocusMutation = useMutation({
    mutationFn: createFocusPO,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-detail', id]);
      await OpenSuccessNotification();
    },
  });

  const unMarkFocusMutation = useMutation({
    mutationFn: removeFocusPO,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-detail', id]);
      await OpenSuccessNotification();
    },
  });

  const editNameMutation = useMutation({
    mutationFn: editProductOrderName,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-detail', id]);
      await OpenSuccessNotification();
    },
  });

  const editProductOrderDetailMutation = useMutation({
    mutationFn: editProductOrderDetail,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-detail', id]);
      await OpenSuccessNotification();
    },
  });

  return {
    productOrder: data.Detail,
    processDetails: data.ProcessDetail,
    billingNotes: data.BillingNotes,
    deliveryBills: data.DeliveryBills,
    cancel: cancelMutation,
    complete: completeMutation,
    printDeliveryReportPDF: getDeliveryReportPDFMutation,
    markAsFocus: markFocusMutation,
    unMarkAsFocus: unMarkFocusMutation,
    editName: editNameMutation,
    editDetail: editProductOrderDetailMutation,
  };
};
