import CreateEmployeePage from '../../components/pages/HumanResourceModule/CreateEmployeePage';
import DeactivateEmployeePage from '../../components/pages/HumanResourceModule/DeactivateEmployeePage';
import CreatePenaltyRecordPage from '../../components/pages/HumanResourceModule/CreatePenaltyRecordPage';
import CreateEmployeeRecordPage from '../../components/pages/HumanResourceModule/CreateEmployeeRecordPage';
import { AdminUserTypeId, HumanResourceUserTypeId } from '../../utilities/Constants';
import EmployeeListPage from '../../components/pages/HumanResourceModule/EmployeeListPage';
import EmployeeDetailPage from '../../components/pages/HumanResourceModule/EmployeeDetailPage';
import { paths } from '../paths';
const humanResourceModuleRouteDetails = [
  {
    path: paths.hr.add_employee,
    component: <CreateEmployeePage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, HumanResourceUserTypeId],
  },
  {
    path: paths.hr.remove_employee,
    component: <DeactivateEmployeePage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, HumanResourceUserTypeId],
  },
  {
    path: paths.hr.add_penalty_record,
    component: <CreatePenaltyRecordPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, HumanResourceUserTypeId],
  },
  {
    path: paths.hr.add_employee_record,
    component: <CreateEmployeeRecordPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, HumanResourceUserTypeId],
  },
  {
    path: paths.hr.employee_list,
    component: <EmployeeListPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, HumanResourceUserTypeId],
  },
  {
    path: paths.hr.employee_detail(':id'),
    component: <EmployeeDetailPage />,
    isPrivate: true,
  },
];
export default humanResourceModuleRouteDetails;
