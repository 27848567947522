import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from './DatePicker';
import { useLocales } from 'locales';

DatePickerInterval.propTypes = {
  onFetchData: PropTypes.func.isRequired,
  defaultDateRange: PropTypes.object,
};

function DatePickerInterval({ onFetchData, defaultDateRange }) {
  const { translate } = useLocales();

  const getStartDate = () => {
    let returnDate = new Date();
    returnDate.setHours(0, 0, 0, 0);
    return returnDate;
  };

  return (
    <DatePicker
      onSearchDate={onFetchData}
      defaultDateRange={
        defaultDateRange !== undefined
          ? defaultDateRange
          : {
              fromDate: new Date(new Date().setHours(0, 0, 0, 0)),
              toDate: new Date(new Date().setHours(23, 59, 0, 0)),
            }
      }
      buttonDateRanges={[
        {
          fromDate: new Date(new Date().getFullYear(), 0, 1),
          toDate: new Date(),
          title: translate('date.this_year'),
        },
        {
          fromDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          toDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
          title: translate('date.this_month'),
        },
        {
          fromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          toDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          title: translate('date.previous_month'),
        },
        {
          fromDate: getStartDate(),
          toDate: new Date(),
          title: translate('date.this_day'),
        },
      ]}
    />
  );
}

export default DatePickerInterval;
