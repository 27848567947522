import React from 'react';
import { RouterLink } from 'routes/components';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllMaintenanceJobWithStatusId } from '../../../requests/ProductionServerHandler';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import { Box, IconButton } from '@mui/material';
import useTabs from '../../../hooks/useTabs';
import PropTypes from 'prop-types';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import {
  getMaintenanceDetail,
  getMaintenanceJobStatusCard,
} from '../../../utilities/Helper/UserInterfaceHelper';
import { useTheme } from '@mui/material/styles';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

MaintenanceJobTable.propTypes = {
  maintenanceJobStatusId: PropTypes.number,
};

function MaintenanceJobTable({ maintenanceJobStatusId }) {
  const { translate } = useLocales();
  const theme = useTheme();

  const { data: maintenanceJobs } = useQuery({
    queryKey: ['all-maintenance-job-with-status', maintenanceJobStatusId],
    queryFn: () => getAllMaintenanceJobWithStatusId(maintenanceJobStatusId),
    initialData: [],
  });

  return (
    <ProjectPaper>
      <ReactTable
        search
        defaultPageSize={25}
        data={maintenanceJobs.map((job) => {
          return {
            id: job.Id,
            detail: getMaintenanceDetail(job.Detail, job.DetailTags),
            machine: job.Machine,
            status: job.JobStatus.Id,
            action: job.Id,
          };
        })}
        header={[
          {
            header: translate('title.id'),
            accessorKey: 'id',
          },
          {
            header: translate('title.detail'),
            accessorKey: 'detail',
          },
          {
            header: translate('title.machine'),
            accessorKey: 'machine',
          },
          {
            header: translate('title.status'),
            accessorKey: 'status',
            cell: (info) =>
              getMaintenanceJobStatusCard(theme.palette.mode, translate, info.getValue()),
          },
          {
            header: 'Actions',
            accessorKey: 'action',
            cell: (info) => (
              <IconButton component={RouterLink} to={paths.maintenance.job_detail(info.getValue())}>
                <Iconify icon={OPEN_ICON} />
              </IconButton>
            ),
          },
        ]}
      />
    </ProjectPaper>
  );
}

function MaintenanceJobListPage() {
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs('new');

  const INFORMATION_TABS = [
    {
      value: 'new',
      title: translate('maintenance.total_wait_job'),
      icon: <Iconify icon={'entypo:new'} width={20} height={20} />,
      component: <MaintenanceJobTable maintenanceJobStatusId={1} />,
    },
    {
      value: 'wip',
      title: translate('title.work_in_process'),
      icon: <Iconify icon={'carbon:in-progress'} width={20} height={20} />,
      component: <MaintenanceJobTable maintenanceJobStatusId={2} />,
    },
    {
      value: 'mt_status_6',
      title: translate('status.mt_status_6'),
      icon: <Iconify icon={'akar-icons:shipping-box-01'} width={20} height={20} />,
      component: <MaintenanceJobTable maintenanceJobStatusId={6} />,
    },
    {
      value: 'mt_status_8',
      title: translate('status.mt_status_8'),
      icon: <Iconify icon={'fa-solid:people-carry'} width={20} height={20} />,
      component: <MaintenanceJobTable maintenanceJobStatusId={8} />,
    },
    {
      value: 'mt_status_5',
      title: translate('status.mt_status_5'),
      icon: <Iconify icon={'ant-design:stop-outlined'} width={20} height={20} />,
      component: <MaintenanceJobTable maintenanceJobStatusId={5} />,
    },
    {
      value: 'mt_status_3',
      title: translate('status.mt_status_3'),
      icon: <Iconify icon={'bi:clipboard-check'} width={20} height={20} />,
      component: <MaintenanceJobTable maintenanceJobStatusId={3} />,
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('maintenance.maintenance_job_list')}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: translate('maintenance.maintenance_job_list') },
      ]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          component={RouterLink}
          to={paths.maintenance.create_job}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
        >
          {translate('maintenance.create_maintenance_job')}
        </Button>
      }
    >
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
}

export default MaintenanceJobListPage;
