import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  cancelServicePO,
  getServicePurchaseOrderDetailById,
  getServicePurchaseOrderPDF,
  uploadApproveSPODocument,
} from '../WarehouseServerHandler';
import {
  OpenNotificationThenCallback,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { spo_prefix } from '../../utilities/Strings/Prefix';
import { useLocales } from '../../locales';

export const useServicePurchaseOrder = (id) => {
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['service-po', id],
    queryFn: () => getServicePurchaseOrderDetailById(id),
    enabled: id !== null,
  });
  const printMutation = useMutation({
    mutationFn: getServicePurchaseOrderPDF,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', async () => {
        await PrintMultiplePDFMakeDocument([response.PDF], `${spo_prefix}_${id}.pdf`);
      });
    },
  });
  const cancelMutation = useMutation({
    mutationFn: cancelServicePO,
    onSuccess: async () => {
      await OpenSuccessNotification();
    },
  });

  const uploadApproveMutation = useMutation({
    mutationFn: uploadApproveSPODocument,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['service-po', id]);
    },
  });

  return {
    servicePurchaseOrder: data ?? null,
    print: printMutation,
    cancel: cancelMutation,
    uploadApprove: uploadApproveMutation,
  };
};
