import React, {useEffect, useState} from 'react';
import {ProjectPageContainer} from 'components/container/ProjectPageContainer';
import {useLocales} from 'locales';
import CustomStepper from 'components/customs/CustomStepper';
import SelectProductForm from 'components/forms/SelectProductForm';
import ProductPriceDetailForm from 'components/forms/ProductPriceDetailForm';
import ProductOrderQuotationDetailForm from 'components/forms/ProductOrderQuotationDetailForm';
import StepSelectCustomerForm from 'components/forms/StepSelectCustomerForm';
import RecheckProductOrderQuotationDetail from 'components/details/RecheckProductOrderQuotationDetail';
import {
    OpenConfirmNotification,
    OpenErrorNotification,
    OpenNotificationThenCallback,
} from 'utilities/Helper/NotificationHelper';
import {
    createProductOrderQuotation,
    getCustomerDetailById,
} from 'requests/ProductionServerHandler';
import {useNavigate} from 'react-router-dom';
import useQueryParameter from 'hooks/useQueryParameter';
import {useMutation, useQuery} from '@tanstack/react-query';
import {paths} from 'routes/paths';
import {useSnackbar} from 'notistack';
import {calculateProductPerSheet} from "../../../utilities/Helper/DataHelper";

function CreateQuotationPage() {
    const {translate} = useLocales();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const query = useQueryParameter();
    const customerId = query.get('customerId');

    const {data: preSelectCustomer} = useQuery({
        queryKey: ['customer', customerId],
        queryFn: () => getCustomerDetailById(customerId),
        enabled: customerId !== null,
    });

    const createQuotation = useMutation({
        mutationFn: createProductOrderQuotation,
        onSuccess: async (newProductOrderQuotationId) => {
            await OpenNotificationThenCallback(
                translate('notification.create_success'),
                `${translate('title.id')} : ${newProductOrderQuotationId}`,
                () => {
                    navigate(paths.sales.product_order_quotation_detail(newProductOrderQuotationId));
                }
            );
        },
        onError: async () => {
            await OpenErrorNotification();
        },
    });

    useEffect(() => {
        if (preSelectCustomer !== undefined && preSelectCustomer !== null) {
            setSelectCustomer({
                Id: preSelectCustomer.Id,
                Name: preSelectCustomer.Name,
            });
            handleChangeStepNumber(1);
        }
    }, [preSelectCustomer]);
    const [activeStep, setActiveStep] = useState(0);
    const [selectCustomer, setSelectCustomer] = useState(null);
    const [productOrderDetail, setProductOrderDetail] = useState({
        Name: '',
        Detail: '',
        Width: 0,
        Length: 0,
        Amount: 0,
        ProductOrderSubType: {
            Id: 0,
            Name: 'ไม่ได้เลือก',
        },
    });
    const [selectProducts, setSelectProducts] = useState([]);

    const STEPS = [
        translate('sales.select_customer'),
        translate('title.detail'),
        translate('sales.select_product'),
        translate('sales.manage_price'),
        translate('title.conclude'),
    ];

    const handleChangeStepNumber = (stepNumber) => {
        setActiveStep(stepNumber);
    };
    const handleSelectCustomer = (value) => {
        enqueueSnackbar(translate('notification.update_success'));
        setSelectCustomer(value);
        handleChangeStepNumber(1);
    };
    const handleSaveProductOrderDetail = (value) => {
        enqueueSnackbar(translate('notification.update_success'));
        setProductOrderDetail(value);
        handleChangeStepNumber(2);
    };
    const handleSelectProducts = (value) => {
        enqueueSnackbar(translate('notification.update_success'));
        setSelectProducts(value);
        handleChangeStepNumber(3);
    };
    const handleUpdatePriceDetail = (value) => {
        enqueueSnackbar(translate('notification.update_success'));
        setSelectProducts(value);
        handleChangeStepNumber(4);
    };

    const handleCreateProductOrderQuotation = async () => {
        await OpenConfirmNotification(
            translate('warning.confirm_create_product_order'),
            translate('button.confirm'),
            '',
            async () => {

                const totalRealValueToSell = selectProducts.reduce((acc, product) => {
                    if (product.Price.IsBundle) {
                        return acc + product.Price.BundlePrice;
                    }
                    return acc + (calculateProductPerSheet(productOrderDetail.Width, productOrderDetail.Length, product.Detail.PricePerSquareInch) * productOrderDetail.Amount);
                },0);
                const totalValueToSell = selectProducts.reduce((acc, product) => {
                    if (product.Price.IsBundle) {
                        return acc + product.Price.BundlePrice;
                    }
                    return acc + (product.Price.Price * productOrderDetail.Amount);
                },0);
                const discount = totalRealValueToSell - totalValueToSell;


                await createQuotation.mutateAsync({
                    ...productOrderDetail,
                    CustomerId: selectCustomer.Id,
                    Products: selectProducts,
                    Discount: discount,
                });
            }
        );
    };

    return (
        <ProjectPageContainer
            menu_title={translate('sales.create_quotation')}
            breadcrumbs={[
                {name: translate('sales.title')},
                {name: translate('sales.create_quotation')},
            ]}
        >
            <CustomStepper currentStep={activeStep} steps={STEPS}/>
            {activeStep === 0 && (
                <StepSelectCustomerForm
                    onSubmitCallback={handleSelectCustomer}
                    defaultCustomer={selectCustomer}
                />
            )}
            {activeStep === 1 && (
                <ProductOrderQuotationDetailForm
                    productOrderDetail={productOrderDetail}
                    onBackPress={() => handleChangeStepNumber(0)}
                    onSubmitProductOrderDetail={handleSaveProductOrderDetail}
                />
            )}
            {activeStep === 2 && (
                <SelectProductForm
                    customerId={selectCustomer.Id}
                    selectedProducts={selectProducts}
                    onBackPress={() => handleChangeStepNumber(1)}
                    onSubmitProducts={handleSelectProducts}
                    productOrderAmount={productOrderDetail.Amount}
                />
            )}
            {activeStep === 3 && (
                <ProductPriceDetailForm
                    width={productOrderDetail.Width}
                    length={productOrderDetail.Length}
                    onBackPress={() => handleChangeStepNumber(2)}
                    onSubmitProductPriceDetail={handleUpdatePriceDetail}
                    products={selectProducts}
                    isCreateQuotation={true}
                />
            )}
            {activeStep === 4 && (
                <RecheckProductOrderQuotationDetail
                    products={selectProducts}
                    customer={selectCustomer}
                    productDetail={productOrderDetail}
                    onBackPress={() => handleChangeStepNumber(3)}
                    onConfirm={handleCreateProductOrderQuotation}
                />
            )}
        </ProjectPageContainer>
    );
}

export default CreateQuotationPage;
