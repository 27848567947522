import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { useLoading } from '../../components/loading';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  cancelItemTemplateRequestReport,
  getItemTemplateRequestReportDetailById,
  getItemTemplateRequestReportPDF,
  requestItemTemplateFromStore,
} from '../ProductionServerHandler';
import {
  OpenErrorNotification, OpenNotificationThenReloadPage,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { useLocales } from '../../locales';

export const useItemTemplateRequestReport = (reportId) => {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['item-template-request-report', reportId],
    queryFn: () => getItemTemplateRequestReportDetailById(reportId),
    initialData: null,
    enabled: reportId !== null,
  });

  const printReportMutation = useMutation({
    mutationFn: getItemTemplateRequestReportPDF,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintMultiplePDFMakeDocument([response], `ใบเบิกของ.pdf`);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const createMutation = useMutation({
    mutationFn: requestItemTemplateFromStore,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response, variables) => {
      hideLoadingScreen();
      if(!variables.IsTemp)
      {
        await printReportMutation.mutateAsync(response.ItemTemplateRequestReportId);
      }
      await OpenNotificationThenReloadPage(
        `${translate('warehouse.request_id')} : ${response.ItemTemplateRequestReportId}`
      );
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  const cancelMutation = useMutation({
    mutationFn: cancelItemTemplateRequestReport,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['item-template-request-report', reportId]);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  return {
    itemTemplateRequestReportDetail: data,
    printReport: printReportMutation,
    create: createMutation,
    cancel: cancelMutation,
  };
};
