import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import EditProductDetailFrom from '../../forms/EditProductDetailFrom';
import { useLocales } from 'locales';
import useAuth from '../../../auth/hooks/useAuth';
import { createProduct } from '../../../requests/ProductionServerHandler';
import { paths } from '../../../routes/paths';
import { useMutation } from '@tanstack/react-query';

function CreateProductPage() {
  const { translate } = useLocales();
  const { user } = useAuth();

  const createProductMutation = useMutation({
    mutationFn: createProduct,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const handleCreateProduct = async (values) => {
    let isPriceCorrect =
      values.BasePrice >=
      Math.round((parseFloat(values.PricePerSquareInch) * 666.66 + Number.EPSILON) * 100) / 100;
    if (user.UserTypeId === ManagementUserTypeId) {
      isPriceCorrect = true;
    }

    if (isPriceCorrect) {
      await OpenTranslateWarningConfirmNotification(
        translate,
        'warning.confirm_create_data',
        async () => {
          await createProductMutation.mutateAsync(values);
        }
      );
    } else {
      await OpenErrorNotification(translate('error.invalid_price'));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('product.create_new')}
      breadcrumbs={[
        { name: translate('product.title') },
        { name: translate('product.list'), href: paths.product.product_list },
        { name: translate('product.create_new') },
      ]}
    >
      <EditProductDetailFrom
        productDetail={null}
        onUpdateCompleteCallback={handleCreateProduct}
        isCreated
      />
    </ProjectPageContainer>
  );
}

export default CreateProductPage;
