import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import IconButton from '@mui/material/IconButton';
import { deleteSupplierPO, selectPurchaseOrderItemCart } from '../../../redux/reducers/procurement';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import GeneralTable from '../../customs/GeneralTable';
import { fCurrency } from '../../../utilities/formatNumber';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import { paths } from '../../../routes/paths';

function CheckoutPurchaseOrderPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const PurchaseOrderItems = useSelector(selectPurchaseOrderItemCart);

  if (PurchaseOrderItems.length > 0) {
    return (
      <ProjectPageContainer
        menu_title={translate('procurement.check_out_supplier_po_title')}
        breadcrumbs={[
          { name: translate('procurement.title') },
          { name: translate('procurement.check_out_po_title'), href: paths.procurement.create_po },
          { name: translate('procurement.check_out_supplier_po_title') },
        ]}
      >
        <ProjectPaper>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: 'procurement.supplier' },
              { align: 'left', title: 'title.value' },
              { align: 'left', title: '' },
            ]}
            tableData={PurchaseOrderItems.map((supplier) => {
              return [
                supplier.SupplierDetail.Name,
                fCurrency(
                  supplier.ItemInCart.reduce((accumulator, item) => {
                    const totalValue = item.Amount * item.Price;
                    return accumulator + totalValue;
                  }, 0)
                ),
                [
                  <IconButton
                    color={'error'}
                    onClick={() => dispatch(deleteSupplierPO(supplier.SupplierDetail))}
                  >
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>,
                  <IconButton
                    color={'success'}
                    onClick={() =>
                      navigate(paths.procurement.check_out_supplier_po(supplier.SupplierDetail.Id))
                    }
                  >
                    <Iconify icon={'ic:baseline-attach-money'} width={20} height={20} />
                  </IconButton>,
                ],
              ];
            })}
          />
        </ProjectPaper>
      </ProjectPageContainer>
    );
  } else {
    return <Navigate to={paths.procurement.create_po} />;
  }
}

export default CheckoutPurchaseOrderPage;
