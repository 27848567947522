import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from '../../react-table/ReactTable';
import Label from '../../minimal/label';
import { IconButton } from '@mui/material';
import { RouterLink } from '../../../routes/components';
import Iconify from '../../minimal/iconify';
import { useLocales } from '../../../locales';
import { useTheme } from '@mui/material/styles';
import { adjustPartsPurchaseCart } from '../../../redux/reducers/inventory';
import { useDispatch } from 'react-redux';
import { getAllItemTemplateByCategoryId } from '../../../requests/ProductionServerHandler';
import { getItemTemplateStoreTableData } from '../../../utilities/Helper/DataHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import { showAddItemToCartInput } from '../../../utilities/Helper/NotificationHelper';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

PartsPurchaseStore.propTypes = {
  category_id: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

function PartsPurchaseStore({ category_id, isMobile }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { data: allItemTemplate } = useQuery({
    queryKey: ['item-template-by-category-id', category_id],
    queryFn: () => getAllItemTemplateByCategoryId(category_id),
    initialData: [],
    enabled: !!category_id,
  });

  const handleAddItemToCart = async (itemTemplate) => {
    await showAddItemToCartInput(translate, itemTemplate, false).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          adjustPartsPurchaseCart({
            Id: itemTemplate.Id,
            Name: itemTemplate.Name,
            Amount: result.value,
          })
        );
      }
    });
  };

  const getHeader = () => {
    if (isMobile) {
      return [
        {
          header: 'Actions',
          accessorKey: 'actions',
          cell: (info) => (
            <>
              <IconButton
                component={RouterLink}
                to={paths.general_warehouse.item_template_detail(info.getValue().Id)}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>
              <IconButton color={'success'} onClick={() => handleAddItemToCart(info.getValue())}>
                <Iconify icon={'ant-design:shopping-cart-outlined'} width={20} height={20} />
              </IconButton>
            </>
          ),
          size: 20,
        },
        {
          header: translate('title.id'),
          accessorKey: 'id',
        },
        {
          header: translate('title.name'),
          accessorKey: 'name',
        },
        {
          header: translate('title.amount'),
          accessorKey: 'amount',
        },
      ];
    } else {
      return [
        {
          header: 'Actions',
          accessorKey: 'actions',
          cell: (info) => (
            <>
              <IconButton
                component={RouterLink}
                to={paths.general_warehouse.item_template_detail(info.getValue().Id)}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>
              <IconButton color={'success'} onClick={() => handleAddItemToCart(info.getValue())}>
                <Iconify icon={'ant-design:shopping-cart-outlined'} width={20} height={20} />
              </IconButton>
            </>
          ),
          size: 20,
        },
        {
          header: translate('title.id'),
          accessorKey: 'id',
          size: 20,
        },
        {
          header: translate('title.name'),
          accessorKey: 'name',
        },
        {
          header: translate('title.safety_amount'),
          accessorKey: 'safety',
          size: 20,
        },
        {
          header: translate('title.amount'),
          accessorKey: 'amount',
          size: 20,
        },
        {
          header: translate('unit.title'),
          accessorKey: 'unit',
        },
        {
          header: translate('title.notification'),
          accessorKey: 'is_safety',
          cell: (info) =>
            info.getValue().IsSafety ? (
              <Label
                variant={theme === 'light' ? 'filled' : 'filled'}
                color={'error'}
                sx={{ textTransform: 'capitalize' }}
              >
                {translate('warehouse.need_safety_stock')}
              </Label>
            ) : (
              ''
            ),
        },
      ];
    }
  };

  return (
    <ProjectPaper>
      <ReactTable
        search
        defaultPageSize={25}
        data={getItemTemplateStoreTableData(isMobile, allItemTemplate)}
        header={getHeader()}
      />
    </ProjectPaper>
  );
}

export default PartsPurchaseStore;
