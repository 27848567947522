import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { Button, Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { itemContainerValidator } from '../../../utilities/Helper/FormValidator';
import { OpenNotificationThenCallback } from '../../../utilities/Helper/NotificationHelper';
import useAuth from '../../../auth/hooks/useAuth';
import useQueryParameter from '../../../hooks/useQueryParameter';
import { useItemMasterDetail } from '../../../requests/query/useItemMasterDetail';
import { useItemMasterCreateData } from '../../../requests/query/DropdownData';
import { paths } from '../../../routes/paths';
import { useItemContainerDetail } from '../../../requests/query/useItemContainerDetail';

function CreateItemContainerPage() {
  const navigate = useNavigate();
  const query = useQueryParameter();
  const id = query.get('itemMasterId');
  const { translate } = useLocales();
  const { user } = useAuth();
  const { itemMasterDetail } = useItemMasterDetail(id);
  const { itemUnitOptions } = useItemMasterCreateData();
  const { createContainer } = useItemContainerDetail(null);

  const onSubmit = async (values) => {
    await createContainer.mutateAsync({
      Name: values.Name,
      Size: values.Size,
      ItemUnitId: values.ItemUnit.Id,
      ItemMasterId: itemMasterDetail.Id,
      CreateEmployeeId: user.EmployeeId,
    });
    await OpenNotificationThenCallback(translate('title.complete'), ``, () => {
      navigate(paths.warehouse.item_master_detail(itemMasterDetail.Id));
    });
  };
  const methods = useForm({
    resolver: yupResolver(itemContainerValidator(translate)),
  });

  const { handleSubmit } = methods;
  return (
    <ProjectPageContainer
      menu_title={translate('button.create_item_container')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.title'), href: paths.warehouse.store },
        {
          name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${id}`,
          href: paths.warehouse.item_master_detail(id),
        },
        { name: translate('button.create_item_container') },
      ]}
    >
      <Stack spacing={3}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText
              title={translate('title.id')}
              value={itemMasterDetail !== null ? itemMasterDetail.Id : 'N/A'}
            />
            <GridDetailText
              title={translate('title.name')}
              value={itemMasterDetail !== null ? itemMasterDetail.Name : 'N/A'}
            />
            <GridDetailText
              title={translate('warehouse.amount_in_store_title')}
              value={itemMasterDetail !== null ? itemMasterDetail.Amount : 'N/A'}
            />
            <GridDetailText
              title={translate('unit.title')}
              value={itemMasterDetail !== null ? itemMasterDetail.ItemUnit.Name : 'N/A'}
            />
          </Grid>
        </ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <ProjectPaper>
            <Stack spacing={3}>
              <RHFTextField name="Name" label={translate('title.name')} />
              <RHFTextField
                name="Size"
                type="number"
                label={translate('warehouse.master_amount')}
              />
              <RHFAutoCompleteShort
                name="ItemUnit"
                label={translate('unit.title')}
                data={itemUnitOptions}
              />
              <Button variant={'contained'} fullWidth type="submit" color={'primary'}>
                {translate('button.create')}
              </Button>
            </Stack>
          </ProjectPaper>
        </FormProvider>
      </Stack>
    </ProjectPageContainer>
  );
}

export default CreateItemContainerPage;
