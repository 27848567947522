import ManagementDashboardPage from "../../components/pages/ManagementModule/ManagementDashboardPage";
import {ManagementUserTypeId} from "../../utilities/Constants";
import SpecialDiscountListPage from "../../components/pages/ManagementModule/SpecialDiscountListPage";
import CreateSpecialDiscountPage from "../../components/pages/ManagementModule/CreateSpecialDiscountPage";
import {paths} from "../paths";

const managementModuleRouteDetails = [
    {
        path: paths.management.dashboard,
        component: <ManagementDashboardPage/>,
        isPrivate: true,
        allowUserTypeId: [ManagementUserTypeId]
    },
    {
        path: paths.management.special_discount_list,
        component: <SpecialDiscountListPage/>,
        isPrivate: true,
        allowUserTypeId: [ManagementUserTypeId]
    },
    {
        path: paths.management.create_special_discount,
        component: <CreateSpecialDiscountPage/>,
        isPrivate: true,
        allowUserTypeId: [ManagementUserTypeId]
    },
];
export default managementModuleRouteDetails;
