import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import ListItemText from '@mui/material/ListItemText';
import { Button, Grid, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { QuickRequestItemCard } from '../../customs/QuickRequestItemCard';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useLocales } from 'locales';
import useAuth from '../../../auth/hooks/useAuth';
import ProjectPaper from '../../customs/ProjectPaper';
import { getQuickRequestItems } from '../../../requests/ProductionServerHandler';
import { useQuery } from '@tanstack/react-query';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { EmployeeScanner } from '../../barcode-reader';
import { useItemTemplateRequestReport } from '../../../requests/query/useItemTemplateRequestReport';

const QuickRequestPage = () => {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { create } = useItemTemplateRequestReport(null);
  const [submitEmployee, setSubmitEmployee] = useState(
    user !== null
      ? {
          Id: user.EmployeeId,
          Name: user.Name,
          Department: user.Department,
          EmployeeCode: user.EmployeeCode,
          IsTemp: false,
        }
      : null
  );

  const { data: quickRequestItems } = useQuery({
    queryKey: ['quickRequestItems'],
    queryFn: getQuickRequestItems,
    initialData: [],
  });

  const handleGetEmployeeDetail = (employeeDetail) => {
    setSubmitEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };

  const onRequestPressed = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_request_item',
      async () => {
        await create.mutateAsync({
          RequestItems: selectItems.map((item) => {
            return { Id: item.Id, Amount: item.Amount };
          }),
          PurchaseItems: [],
          CreateEmployeeId: submitEmployee.Id,
          Detail: `รายการเบิกของด่วนจาก ${submitEmployee.Name} แผนก ${submitEmployee.Department.Name}`,
          IsTemp: submitEmployee.IsTemp,
        });
      }
    );
  };

  const onItemAmountChange = (amount, itemTemplate) => {
    let isAlreadyAdd = false;
    let updateItems = [];
    if (amount > 0) {
      let updateItems = selectItems.map((item) => {
        if (item.Id === itemTemplate.Id) {
          isAlreadyAdd = true;
          return {
            Id: itemTemplate.Id,
            Name: itemTemplate.Name,
            Amount: amount,
          };
        } else {
          return item;
        }
      });

      if (!isAlreadyAdd) {
        updateItems.push({
          Id: itemTemplate.Id,
          Name: itemTemplate.Name,
          Amount: amount,
        });
      }
      setSelectItems(updateItems);
    } else {
      updateItems = selectItems.filter((item) => item.Id !== itemTemplate.Id);
      setSelectItems(updateItems);
    }
  };
  const [selectItems, setSelectItems] = useState([]);
  return (
    <ProjectPageContainer menu_title={translate('title.quick_request')}>
      {submitEmployee !== null ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={8}>
            <Grid container spacing={2}>
              {quickRequestItems.length > 0
                ? quickRequestItems.map((itemList, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <QuickRequestItemCard
                          itemTemplate={itemList.ItemTemplate}
                          onAmountChange={onItemAmountChange}
                        />
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ProjectPaper>
              <Stack spacing={2}>
                <ListItemText primary={translate('warehouse.request_item_list')} />
                <Divider />
                <Table>
                  <TableBody>
                    {selectItems.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">{item.Name}</TableCell>
                          <TableCell align="right">{item.Amount}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Button
                  variant={'contained'}
                  fullWidth
                  color={'success'}
                  disabled={selectItems.length === 0}
                  onClick={onRequestPressed}
                >
                  {translate('button.request')}
                </Button>
              </Stack>
            </ProjectPaper>
          </Grid>
        </Grid>
      ) : (
        <EmployeeScanner
          disableInput={user === null || user.UserTypeId !== ManagementUserTypeId}
          onScanCallback={handleGetEmployeeDetail}
          isPreFetchData
          isShowTooltips
        />
      )}
    </ProjectPageContainer>
  );
};

export default QuickRequestPage;
