import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Chip, Stack } from '@mui/material';
import { useLocales } from 'locales';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CardActionArea from '@mui/material/CardActionArea';
import PropTypes from 'prop-types';
import { fNumber } from '../../../utilities/formatNumber';
import {
  printMachinePlan,
  removeProductOrderPlanningFromMachine,
  sortMachineQueues,
} from '../../../requests/ProductionServerHandler';
import { useDispatch } from 'react-redux';
import { PrintPDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import { planning_pdf_prefix } from '../../../utilities/Strings/Prefix';
import Iconify from 'components/minimal/iconify';
import { toggleMachineList, toggleRefreshPlanningCard } from '../../../redux/reducers/planning';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';

MachinePlanningCard.propTypes = {
  machineDetail: PropTypes.object.isRequired,
  onOpenProductOrderDetail: PropTypes.func.isRequired,
  onOpenMachineDetail: PropTypes.func.isRequired,
};

function MachinePlanningCard({ machineDetail, onOpenProductOrderDetail, onOpenMachineDetail }) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);
  const [queueDetail, setQueueDetail] = useState([]);

  useEffect(() => {
    setQueueDetail(
      machineDetail.ProductOrderPlannings.sort((a, b) => {
        return a.QueueNumber - b.QueueNumber;
      })
    );
  }, [machineDetail]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const reOrderPlan = reorder(queueDetail, result.source.index, result.destination.index);

    const response = await dispatch(
      sortMachineQueues(
        reOrderPlan.map((plan, index) => {
          return {
            Id: plan.Id,
            QueueNumber: index + 1,
          };
        })
      )
    );

    if (response !== null) {
      setQueueDetail(reOrderPlan);
      await dispatch(toggleMachineList(true));
    }
  };
  const onPrintPlan = async (machineId) => {
    const response = await dispatch(printMachinePlan(machineId));
    if (response !== null) {
      PrintPDFMakeDocument(response, `${planning_pdf_prefix}_${machineId}.pdf`);
    }
  };

  const handleRemoveFromPlan = async (removeId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_delete_planning',
      async () => {
        const response = await dispatch(removeProductOrderPlanningFromMachine(removeId));
        if (response !== null) {
          await OpenSuccessNotification();
          await dispatch(toggleMachineList(true));
          await dispatch(toggleRefreshPlanningCard(true));
        }
      }
    );
  };

  return (
    <Card>
      <CardHeader
        sx={{ mb: 2 }}
        title={
          <Stack direction="row">
            <Typography
              onClick={() => onOpenMachineDetail(machineDetail.Id)}
              variant={'h6'}
              sx={{ width: 150 }}
            >{`${translate('title.machine')}: ${machineDetail.Name}`}</Typography>
            {machineDetail.ProductOrderPlannings.length > 0 ? (
              <>
                <AutorenewIcon sx={{ ml: 5, mt: 0.4, color: '#FF4842' }} />
                <Typography sx={{ ml: 1 }} variant={'h6'}>{`${translate(
                  'title.work_in_process'
                )} : ${machineDetail.ProductOrderPlannings[0].ProductOrderId} (${
                  machineDetail.ProductOrderPlannings.length
                } ${translate('title.queue')})`}</Typography>
              </>
            ) : (
              <>
                <AutorenewIcon sx={{ ml: 5, mt: 0.4, color: '#54D62C' }} />
                <Typography sx={{ ml: 1 }} variant={'h6'}>
                  {translate('planning.free_of_queue')}
                </Typography>
              </>
            )}
          </Stack>
        }
        action={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Typography variant={'body2'} sx={{ textAlign: 'right', mt: 1, mr: 1 }}>{`${translate(
              'title.capacity'
            )} ${fNumber(machineDetail.ExpectedResult)} ${translate('unit.sheet')}`}</Typography>
            <IconButton
              disabled={machineDetail.ProductOrderPlannings.length === 0}
              onClick={() => setIsExpand(!isExpand)}
            >
              {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        }
      />
      <Collapse in={isExpand}>
        <CardContent>
          <Box sx={{ m: 1, display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={() => onPrintPlan(machineDetail.Id)}
            >
              {translate('button.print_production_plan')}
            </Button>
          </Box>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={machineDetail.Name} type="task">
              {(provided) => (
                <Stack ref={provided.innerRef} {...provided.droppableProps} spacing={2}>
                  {queueDetail.map((plan, index) => {
                    return (
                      <Draggable key={plan.Id} draggableId={`machine-${plan.Id}`} index={index}>
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <Card>
                              <CardContent>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <CardActionArea
                                    onClick={() => onOpenProductOrderDetail(plan.ProductOrder.Id)}
                                  >
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Typography variant={'h6'}>{plan.ProductOrderId}</Typography>
                                      <Typography
                                        variant={'body2'}
                                        sx={{
                                          width: 250,
                                          textAlign: 'left',
                                          textOverflow: 'ellipsis',
                                        }}
                                        noWrap={true}
                                      >
                                        {plan.ProductOrder.Customer.Name}
                                      </Typography>
                                      <Typography variant={'body2'}>
                                        {plan.ProductOrder.Amount}
                                      </Typography>
                                      <Chip label="อยู่ในแผน" color="primary" variant="outlined" />
                                    </Stack>
                                  </CardActionArea>
                                  <IconButton onClick={() => handleRemoveFromPlan(plan.Id)}>
                                    <CloseIcon />
                                  </IconButton>
                                </Stack>
                              </CardContent>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default MachinePlanningCard;
