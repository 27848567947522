import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { selectRequestCart } from '../../../redux/reducers/inventory';
import MaterialStoreComponent from '../../customs/MaterialStoreComponent';
import { RouterLink } from 'routes/components';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import { Button } from '@mui/material';
import { paths } from 'routes/paths';
import useQueryParameter from '../../../hooks/useQueryParameter';

const ItemMasterStorePage = () => {
  const RequestCart = useSelector(selectRequestCart);
  const { translate } = useLocales();
  const searchParams = useQueryParameter();
  const ProductOrderId = searchParams.get('ProductOrderId');

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.material_store')}
      breadcrumbs={[{ name: translate('warehouse.material_store') }]}
      action={
        <Button
          variant='contained'
          color={'primary'}
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={RequestCart.length === 0}
          to={`${paths.warehouse.check_out_items}?ProductOrderId=${ProductOrderId}`}
        >
          {`${translate('warehouse.request_item')} ${RequestCart.length} ${translate(
            'warehouse.items',
          )}`}
        </Button>
      }
    >
      <MaterialStoreComponent is_request={true} category_id={1} />
    </ProjectPageContainer>
  );
};

export default ItemMasterStorePage;
