import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import SearchField from '../../customs/SearchField';
import GeneralSelect from '../../customs/GeneralSelect';
import { useLocales } from 'locales';
import Scrollbar from 'components/minimal/scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsRefreshInMachinePlan,
  setInMachineAmount,
  toggleMachineList,
  toggleRefreshInMachinePlan,
  toggleRefreshPlanningCard,
} from '../../../redux/reducers/planning';
import {
  createProductionPlanWithMachine,
  getInMachinePlan,
  getMachineRunProductionProcessId,
  removeProductOrderPlanningFromMachine,
} from '../../../requests/ProductionServerHandler';
import SelectMachineDialog from '../SelectMachineDialog';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import Fuse from 'fuse.js';
import { sortById } from '../../../utilities/Helper/DataHelper';
import ProductOrderPlanningCard from './ProductOrderPlanningCard';

function MachinePlanProductOrderView() {
  const options = {
    keys: ['Customer.Name', 'Name', 'Id'],
  };
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [sortValue, setSortValue] = useState(0);
  const [allProductOrder, setAllProductOrder] = useState([]);
  const [filterProductOrder, setFilterProductOrder] = useState([]);
  const isRefreshInMachinePlan = useSelector(selectIsRefreshInMachinePlan);
  const sortTypes = [
    { value: 0, name: translate('title.status') },
    { value: 1, name: translate('title.due_at') },
    { value: 2, name: translate('title.amount') },
  ];

  const [showSelectMachineDetail, setShowSelectMachineDetail] = useState(false);
  const [machineListDetail, setMachineListDetail] = useState({
    ProductOrderId: 0,
    ProductSellHistoryId: 0,
    ProductHasProductionProcessId: 0,
    ProcessId: 0,
    MachineList: [],
  });

  const fuse = new Fuse(allProductOrder, options);

  useEffect(() => {
    if (isRefreshInMachinePlan) {
      const getTodayMachinePlan = async () => await dispatch(getInMachinePlan());

      getTodayMachinePlan().then((response) => {
        setAllProductOrder(response);
        setFilterProductOrder(response.sort(sortById));
        dispatch(toggleRefreshInMachinePlan(false));
        dispatch(
          setInMachineAmount(
            response.reduce((totalPlan, productOrder) => {
              return (
                totalPlan +
                productOrder.ProductOrderPlannings.reduce((totalInMachine, plan) => {
                  if (plan.MachineId !== null) {
                    return totalInMachine + 1;
                  } else {
                    return totalInMachine;
                  }
                }, 0)
              );
            }, 0)
          )
        );
        defaultSetting();
      });
    }
  }, [dispatch, isRefreshInMachinePlan]);
  const defaultSetting = () => {
    setShowSelectMachineDetail(false);
    setMachineListDetail({
      ProductOrderId: 0,
      ProductSellHistoryId: 0,
      ProductHasProductionProcessId: 0,
      ProcessId: 0,
      MachineList: [],
    });
    setSortValue(0);
  };
  const handleSearch = (searchText) => {
    if (searchText !== null && searchText !== '') {
      setFilterProductOrder(fuse.search(searchText).map((result) => result.item));
    } else {
      setFilterProductOrder(allProductOrder);
    }
  };
  const handleSort = (value) => {
    if (value === 0) {
      const sorted = [...filterProductOrder].sort(sortById);

      setFilterProductOrder(sorted);
    }
    if (value === 1) {
      const sorted = [...filterProductOrder].sort((a, b) => {
        return new Date(a.DueDatedAt) - new Date(b.DueDatedAt);
      });

      setFilterProductOrder(sorted);
    }
    if (value === 2) {
      const sorted = [...filterProductOrder].sort((a, b) => {
        return b.Amount - a.Amount;
      });

      setFilterProductOrder(sorted);
    }
    setSortValue(value);
  };
  const handleRemoveFromMachine = async (planId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_delete_planning_from_machine',
      async () => {
        const response = await dispatch(removeProductOrderPlanningFromMachine(planId));
        if (response !== null) {
          await OpenSuccessNotification();
          await dispatch(toggleRefreshInMachinePlan(true));
          await dispatch(toggleRefreshPlanningCard(true));
        }
      }
    );
  };
  const handleGetMachineList = async (
    productOrderId,
    productSellHistoryId,
    productHasProductionProcessId,
    processId
  ) => {
    const response = await dispatch(getMachineRunProductionProcessId(processId));
    if (response !== null) {
      setMachineListDetail({
        ProductOrderId: productOrderId,
        ProductSellHistoryId: productSellHistoryId,
        ProductHasProductionProcessId: productHasProductionProcessId,
        ProcessId: processId,
        MachineList: response.filter(
          (machine) =>
            machine.ProductOrderPlannings.filter(
              (planning) => planning.ProductOrderId === productOrderId
            ).length === 0
        ),
      });
      setShowSelectMachineDetail(true);
    }
  };

  const handleCloseMachineDialog = async () => {
    setShowSelectMachineDetail(false);
  };
  const handleSelectMachine = async (machineId) => {
    setShowSelectMachineDetail(false);
    const response = await dispatch(
      createProductionPlanWithMachine(
        machineListDetail.ProductOrderId,
        machineListDetail.ProductSellHistoryId,
        machineListDetail.ProcessId,
        machineListDetail.ProductHasProductionProcessId,
        machineId
      )
    );
    if (response !== null) {
      await OpenSuccessNotification();
      await dispatch(toggleRefreshInMachinePlan(true));
      await dispatch(toggleRefreshPlanningCard(true));
      await dispatch(toggleMachineList(true));
    }
  };

  return (
    <div>
      <SelectMachineDialog
        isOpen={showSelectMachineDetail}
        handleDialogClose={handleCloseMachineDialog}
        handleSelectMachine={handleSelectMachine}
        machineList={machineListDetail.MachineList}
      />
      <Grid container sx={{ mt: 2 }} spacing={1}>
        <Grid item xs={12} lg={9}>
          <SearchField
            onSearchCallBack={handleSearch}
            placeHolderText={translate('placeholder.search_product_order')}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <GeneralSelect value={sortValue} selectOptions={sortTypes} onOptionChange={handleSort} />
        </Grid>
        <Grid item xs={12}>
          <Scrollbar sx={{ mt: 2, maxHeight: 500 }}>
            <Stack spacing={2}>
              {filterProductOrder.map((productOrder) => {
                return (
                  <ProductOrderPlanningCard
                    productOrderDetail={productOrder}
                    onRemoveMachine={handleRemoveFromMachine}
                    onShowEligibleMachineList={handleGetMachineList}
                  />
                );
              })}
            </Stack>
          </Scrollbar>
        </Grid>
      </Grid>
    </div>
  );
}

export default MachinePlanProductOrderView;
