import {
  loginServerRequest,
} from '../utilities/Helper/NetworkHelper';

export async function loginWithoutToken(username, password) {
  const loginOptions = {
    method: 'POST',
    url: `users/login`,
    data: {
      username: username,
      password: password,
    },
  };
  const response = await loginServerRequest(loginOptions);

  const { token } = response.data;

  return token;
}
export async function getAllUserType() {
  const options = {
    method: 'GET',
    url: `utilities/all-user-type`,
  };

  const response = await loginServerRequest(options);
  return response.data;
}

export async function createUser(values) {
  const options = {
    method: 'POST',
    url: `users/create`,
    data: {
      Username: values.Username,
      Password: values.Password,
      UserTypeId: values.UserType.Id,
    },
  };
  const response = await loginServerRequest(options);
  return response.data;
}

export async function deactivateUser(data) {
  const options = {
    method: 'PATCH',
    url: `users/archive-user`,
    data: data,
  };

  const response = await loginServerRequest(options);
  return response.data;
}

export async function changeUserPassword(data) {
  const options = {
    method: 'PATCH',
    url: `users/change-password`,
    data: data,
  };
  const response = await loginServerRequest(options);
  return response.data;
}
