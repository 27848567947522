import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import Button from '@mui/material/Button';
import { Card, CardContent, IconButton } from '@mui/material';
import ReactTable from '../../react-table/ReactTable';
import { fCurrency } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import {
  deActivateSpecialDiscountPriceHistory,
  getAllSpecialDiscountHistory,
} from 'requests/ProductionServerHandler';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from 'utilities/Helper/NotificationHelper';
import { ADD_ICON, DELETE_ICON } from 'config-global';
import { RouterLink } from 'routes/components';
import { paths } from 'routes/paths';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const useSpecialDiscountData = () => {
  const queryClient = useQueryClient();
  const { data: specialDiscounts } = useQuery({
    queryKey: ['specialDiscount'],
    queryFn: getAllSpecialDiscountHistory,
  });

  const removeDiscountMutation = useMutation({
    mutationFn: deActivateSpecialDiscountPriceHistory,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['specialDiscount']);
      await OpenSuccessNotification();
    },
  });

  return {
    specialDiscounts,
    removeDiscount: removeDiscountMutation.mutateAsync,
  };
};

export default function SpecialDiscountListPage() {
  const { translate } = useLocales();
  const { specialDiscounts, removeDiscount } = useSpecialDiscountData();
  const handleDeactivateSpecialDiscountPrice = async (id) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.remove_special_discount_history',
      async () => {
        await removeDiscount(id);
      }
    );
  };

  const generateTableData = () => {
    return specialDiscounts.map((history) => {
      return {
        customer: history.Customer.Name,
        product: history.Product.Name,
        price: history.Price,
        pricePerSquareInch: history.PricePerSquareInch,
        create: history.createdAt,
        end: history.EndDatedAt,
        createEmployee: getEmployeeFullName(history.CreateEmployee),
        actions: history.Id,
      };
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('management.approve_special_price_history_title')}
      breadcrumbs={[
        { name: translate('management.title') },
        { name: translate('management.approve_special_price_history_title') },
      ]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          component={RouterLink}
          to={paths.management.create_special_discount}
          startIcon={<Iconify icon={ADD_ICON} />}
        >
          {translate('management.create_special_discount_title')}
        </Button>
      }
    >
      <Card>
        <CardContent>
          {specialDiscounts !== undefined && (
            <ReactTable
              search
              header={[
                {
                  header: translate('title.customer'),
                  accessorKey: 'customer',
                },
                {
                  header: translate('title.product_name'),
                  accessorKey: 'product',
                },
                {
                  header: translate('title.value'),
                  accessorKey: 'price',
                  cell: (info) => fCurrency(info.getValue()),
                  size: 20,
                },
                {
                  header: translate('title.price_per_square_inch'),
                  accessorKey: 'pricePerSquareInch',
                  cell: (info) => fCurrency(info.getValue()),
                  size: 20,
                },
                {
                  header: translate('title.create_at'),
                  accessorKey: 'create',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('title.complete_date'),
                  accessorKey: 'end',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('title.create_by'),
                  accessorKey: 'createEmployee',
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      color={'error'}
                      onClick={() => handleDeactivateSpecialDiscountPrice(info.getValue())}
                    >
                      <Iconify icon={DELETE_ICON} />
                    </IconButton>
                  ),
                },
              ]}
              data={generateTableData()}
            />
          )}
        </CardContent>
      </Card>
    </ProjectPageContainer>
  );
}
