import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { Button } from '@mui/material';
import { useLocales } from 'locales';
import DatePickerWithTypeSelection from '../../date-picker/DatePickerWithTypeSelection';
import { getPartStockTransaction } from '../../../requests/ProductionServerHandler';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';

export default function PrintStockTransactionReportPage(props) {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printStockTransactionExcelMutation = useMutation({
    mutationFn: getPartStockTransaction,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'part_stock_transaction.xlsx');
      await OpenSuccessNotification();
    },
  });

  const onPrintExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await printStockTransactionExcelMutation.mutateAsync({
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      });
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.print_item_transaction_data_page_title')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.print_item_transaction_data_page_title') },
      ]}
    >
      <DatePickerWithTypeSelection
        onFromChange={setFromDate}
        onToChange={setToDate}
        selectionActions={
          <Button color={'primary'} variant={'contained'} fullWidth onClick={onPrintExcel}>
            {translate('button.print')}
          </Button>
        }
      />
    </ProjectPageContainer>
  );
}
