import CreateMaterialItemMasterPage from '../../components/pages/WarehouseServiceModule/CreateMaterialItemMasterPage';
import ItemMasterDetailPage from '../../components/pages/WarehouseServiceModule/ItemMasterDetailPage';
import CreateItemContainerPage from '../../components/pages/WarehouseServiceModule/CreateItemContainerPage';
import ItemContainerDetailPage from '../../components/pages/WarehouseServiceModule/ItemContainerDetailPage';
import ItemMasterStorePage from '../../components/pages/WarehouseServiceModule/ItemMasterStorePage';
import IncomingItemMasterPage from '../../components/pages/WarehouseServiceModule/IncomingItemMasterPage';
import ItemLotDetailPage from '../../components/pages/WarehouseServiceModule/ItemLotDetailPage';
import WarehouseShelfDetailPage from '../../components/pages/WarehouseServiceModule/WarehouseShelfDetailPage';
import ItemMaterialDetailPage from '../../components/pages/WarehouseServiceModule/ItemMaterialDetailPage';
import ItemRequisitionCheckoutPage from '../../components/pages/WarehouseServiceModule/ItemRequisitionCheckoutPage';
import ItemRequisitionListPage from '../../components/pages/WarehouseServiceModule/ItemRequisitionListPage';
import ItemRequisitionDetailPage from '../../components/pages/WarehouseServiceModule/ItemRequisitionDetailPage';
import CreateWarehousePage from '../../components/pages/WarehouseServiceModule/CreateWarehousePage';
import CreateWarehouseShelfPage from '../../components/pages/WarehouseServiceModule/CreateWarehouseShelfPage';
import ReturnItemPage from '../../components/pages/WarehouseServiceModule/ReturnItemPage';
import SearchWarehousePage from '../../components/pages/WarehouseServiceModule/SearchWarehousePage';
import MoveItemShelfPage from '../../components/pages/WarehouseServiceModule/MoveItemShelfPage';
import PrintWarehouseDataReportPage from '../../components/pages/WarehouseServiceModule/PrintWarehouseDataReportPage';
import PrintItemMovementDataReportPage from '../../components/pages/WarehouseServiceModule/PrintItemMovementDataReportPage';
import WarehouseDashboardPage from '../../components/pages/WarehouseServiceModule/WarehouseDashboardPage';
import ItemReceiptDetailPage from '../../components/pages/WarehouseServiceModule/ItemReceiptDetailPage';
import AdminCreateItemLotPage from '../../components/pages/WarehouseServiceModule/AdminCreateItemLotPage';
import ReturnFilmPage from '../../components/pages/WarehouseServiceModule/ReturnFilmPage';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  MaintenanceControlUserTypeId,
  ProcurementUserTypeId,
  ProductionControlUserTypeId,
  SecretaryUserTypeId,
  StoreUserTypeId,
  WarehouseControlUserTypeId,
} from '../../utilities/Constants';
import AcceptItemReceiptPage from '../../components/pages/WarehouseServiceModule/AcceptItemReceiptPage';
import SafetyStockManagementPage from '../../components/pages/WarehouseServiceModule/SafetyStockManagementPage';
import AcceptStoreItemPage from '../../components/pages/WarehouseServiceModule/AcceptStoreItemPage';
import UploadItemMasterPricePage from '../../components/pages/WarehouseServiceModule/UploadItemMasterPricePage';
import { paths } from '../paths';
import CreateItemUnitPage from '../../components/pages/WarehouseServiceModule/CreateItemUnitPage';
const warehouseModuleRouteDetails = [
  {
    path: paths.warehouse.create_master,
    component: <CreateMaterialItemMasterPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.item_master_detail(':id'),
    component: <ItemMasterDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.warehouse.create_container,
    component: <CreateItemContainerPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.item_container_detail(':id'),
    component: <ItemContainerDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.warehouse.store,
    component: <ItemMasterStorePage />,
    isPrivate: true,
  },
  {
    path: paths.warehouse.add_items,
    component: <IncomingItemMasterPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.item_lot_detail(':id'),
    component: <ItemLotDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.item_receipt_report_detail(':id'),
    component: <ItemReceiptDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.shelf_detail(':id'),
    component: <WarehouseShelfDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.item_detail(':id'),
    component: <ItemMaterialDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.warehouse.check_out_items,
    component: <ItemRequisitionCheckoutPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      ProductionControlUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.release_items,
    component: <ItemRequisitionListPage />,
    isPrivate: true,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      ProductionControlUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.item_requisition_report_detail(':id'),
    component: <ItemRequisitionDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.warehouse.create_inventory,
    component: <CreateWarehousePage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.create_shelf,
    component: <CreateWarehouseShelfPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.return_items,
    component: <ReturnItemPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.search,
    component: <SearchWarehousePage />,
    isPrivate: true,
  },
  {
    path: paths.warehouse.move_items,
    component: <MoveItemShelfPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.print_stock_transaction,
    component: <PrintItemMovementDataReportPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.print_warehouse_data,
    component: <PrintWarehouseDataReportPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.dashboard,
    component: <WarehouseDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.admin_create_lot,
    component: <AdminCreateItemLotPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.return_film,
    component: <ReturnFilmPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.manage_safety_stock,
    component: <SafetyStockManagementPage />,
    isPrivate: true,
    allowUserTypeId: [
      StoreUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
      WarehouseControlUserTypeId,
    ],
  },
  {
    path: paths.warehouse.accept_items,
    component: <AcceptItemReceiptPage />,
    isPrivate: false,
  },
  {
    path: paths.warehouse.accept_store_items(':id'),
    component: <AcceptStoreItemPage />,
    isPrivate: false,
    allowUserTypeId: [
      MaintenanceControlUserTypeId,
      WarehouseControlUserTypeId,
      StoreUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
    ],
  },
  {
    path: paths.warehouse.upload_item_price,
    component: <UploadItemMasterPricePage />,
    isPrivate: true,
    allowUserTypeId: [
      WarehouseControlUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
    ],
  },
  {
    path: paths.warehouse.create_unit,
    component: <CreateItemUnitPage />,
    isPrivate: true,
    allowUserTypeId: [
      WarehouseControlUserTypeId,
      StoreUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      ProcurementUserTypeId,
    ],
  },
];
export default warehouseModuleRouteDetails;
