import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import IconButton from '@mui/material/IconButton';
import Iconify from 'components/minimal/iconify';
import { OPEN_ICON } from '../../../config-global';
import { useAllSupplier } from '../../../requests/query/DropdownData';
import { paths } from '../../../routes/paths';

function SupplierListPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { allSupplier } = useAllSupplier();

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.supplier_material_list_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.supplier_material_list_title') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={allSupplier.map((supplier) => {
            return {
              name: supplier.Name,
              actions: supplier.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    onClick={() => navigate(paths.procurement.supplier_detail(info.getValue()))}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default SupplierListPage;
