import React from 'react';
import { useLocales } from 'locales';
import { useParams } from 'react-router-dom';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import GridDetailText from '../../customs/GridDetailText';
import Grid from '@mui/material/Grid';
import { Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ItAssetDetail from '../../details/it/ItAssetDetail';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import useAuth from '../../../auth/hooks/useAuth';
import { OpenConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { getEmployeeDetailByEmployeeCode } from '../../../requests/ProductionServerHandler';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import CompleteItJobForm from '../../forms/CompleteItJobForm';
import { useItJobDetail } from '../../../requests/query/useItJobDetail';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';
function ItJobDetailPage() {
  const { translate } = useLocales();
  const theme = useTheme();
  const { id } = useParams();
  const { user } = useAuth();
  const { itJobDetail, acceptJob, cancelJob, completeJob, verifyJob } = useItJobDetail(id);
  const { data: staffEmployee } = useQuery({
    queryKey: ['staffEmployee', itJobDetail?.staff_employee_id],
    queryFn: () => getEmployeeDetailByEmployeeCode(itJobDetail?.staff_employee_id),
    initialData: null,
  });

  const { data: userEmployee } = useQuery({
    queryKey: ['userEmployee', itJobDetail?.user_employee_id],
    queryFn: () => getEmployeeDetailByEmployeeCode(itJobDetail?.user_employee_id),
    initialData: null,
  });

  const getJobStatusText = (jobStatus) => {
    if (!jobStatus) return null;
    let color = theme.palette.info.main;
    let text = '';
    if (jobStatus.id === 1) {
      color = theme.palette.info.main;
      text = translate('status.mt_status_1');
    }
    if (jobStatus.id === 2) {
      color = theme.palette.warning.main;
      text = translate('status.mt_status_2');
    }
    if (jobStatus.id === 3) {
      color = theme.palette.success.main;
      text = translate('status.mt_status_4');
    }
    if (jobStatus.id === 4) {
      color = theme.palette.warning.main;
      text = translate('status.mt_status_3');
    }
    if (jobStatus.id === 5) {
      color = theme.palette.success.main;
      text = translate('status.mt_status_4');
    }
    return <GridDetailText title={translate('title.status')} value={text} color={color} />;
  };

  const handleAcceptJob = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_asset_job'),
      translate('button.confirm'),
      '',
      async () => {
        await acceptJob.mutateAsync({
          id: itJobDetail.id,
          accept_staff_employee_id: parseInt(user.EmployeeCode),
          accept_status: 1,
        });
      }
    );
  };

  const handleCancelJob = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_cancel_job'),
      translate('button.confirm'),
      '',
      async () => {
        await cancelJob.mutateAsync({
          id: itJobDetail.id,
          cancel_staff_employee_id: user.EmployeeCode,
        });
      }
    );
  };

  const handleCompleteJob = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_complete_job'),
      translate('button.confirm'),
      '',
      async () => {
        await completeJob.mutateAsync({
          id: itJobDetail.id,
          staff_employee_id: parseInt(user.EmployeeCode),
          done_staff_detail: values.Detail,
          done_status: 1,
        });
      }
    );
  };

  const handleVerifyJob = async (score) => {
    await OpenConfirmNotification(
      score === 1
        ? translate('warning.confirm_verify_job')
        : translate('warning.confirm_decline_job'),
      translate('button.confirm'),
      '',
      async () => {
        await verifyJob.mutateAsync({
          id: itJobDetail.id,
          user_employee_id: parseInt(user.EmployeeCode),
          is_acceptable: score,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('it.job_detail')}
      breadcrumbs={[
        { name: translate('it.title') },
        { name: translate('it.it_job_list'), href: paths.it.it_job_list },
        { name: `${translate('it.job_detail')}${translate('title.id')}: ${id}` },
      ]}
    >
      <Stack spacing={2}>
        <Card>
          <CardHeader title={translate('it.job_detail')} />
          <CardContent>
            <Grid container spacing={2}>
              <GridDetailText title={translate('title.id')} value={itJobDetail?.id} />
              <GridDetailText title={translate('title.detail')} value={itJobDetail?.user_detail} />
              <GridDetailText
                title={translate('title.create_by')}
                value={getEmployeeFullName(userEmployee)}
              />
              <>{getJobStatusText(itJobDetail?.status)}</>
              {itJobDetail?.status.id === 2 && (
                <GridDetailText
                  title={translate('it.accept_by')}
                  value={getEmployeeFullName(staffEmployee)}
                />
              )}
              {itJobDetail?.status.id === 3 && (
                <GridDetailText
                  title={translate('title.detail')}
                  value={itJobDetail?.staff_detail}
                />
              )}
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={translate('it.asset')} />
          <CardContent>
            {itJobDetail?.asset && <ItAssetDetail asset={itJobDetail?.asset} />}
          </CardContent>
        </Card>
        {itJobDetail?.status.id === 1 && (
          <ProjectPaper>
            <Stack spacing={2} direction="row">
              <Button
                variant={'contained'}
                color={'primary'}
                fullWidth
                startIcon={<Iconify icon={'eva:check-fill'} />}
                onClick={handleAcceptJob}
              >
                {translate('button.accept')}
              </Button>
              <Button
                variant={'contained'}
                color={'error'}
                fullWidth
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={handleCancelJob}
              >
                {translate('button.cancel')}
              </Button>
            </Stack>
          </ProjectPaper>
        )}
        {itJobDetail?.status.id === 2 && (
          <ProjectPaper>
            <CompleteItJobForm onSubmitValue={handleCompleteJob} />
          </ProjectPaper>
        )}
        {itJobDetail?.status.id === 4 && user.EmployeeCode === itJobDetail?.user_employee_id && (
          <ProjectPaper>
            <Stack spacing={2} direction="row">
              <Button
                variant={'contained'}
                color={'success'}
                fullWidth
                startIcon={<Iconify icon={'eva:check-fill'} />}
                onClick={() => handleVerifyJob(1)}
              >
                {translate('button.accept')}
              </Button>
              <Button
                variant={'contained'}
                color={'error'}
                fullWidth
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={() => handleVerifyJob(0)}
              >
                {translate('button.not_accept')}
              </Button>
            </Stack>
          </ProjectPaper>
        )}
      </Stack>
    </ProjectPageContainer>
  );
}

export default ItJobDetailPage;
