import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { isNeedSafety } from '../../../utilities/Helper/DataHelper';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getItemMasterItemsByCategory } from '../../../requests/WarehouseServerHandler';
import Iconify from 'components/minimal/iconify';
import ReactTable from '../../react-table/ReactTable';
import ProjectPaper from '../../customs/ProjectPaper';
import { useLocales } from 'locales';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import Label from '../../minimal/label';

const SearchItemMasterToCreateSellContainerPage = () => {
  const navigate = useNavigate();
  const { supplier } = useParams();
  const { translate } = useLocales();
  const { data } = useQuery({
    queryKey: ['getItemMasterItemsByCategory', 1],
    queryFn: () => getItemMasterItemsByCategory(1),
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.select_item_master_to_create_sell')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.supplier_material_list_title'),
          href: paths.procurement.supplier_list,
        },
        {
          name: `${translate('procurement.supplier_detail')}${translate('title.id')} : ${supplier}`,
          href: paths.procurement.supplier_detail(supplier),
        },
        { name: translate('procurement.select_item_master_to_create_sell') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          defaultPageSize={25}
          data={data.map((itemMaster) => {
            return {
              id: itemMaster.Id,
              name: itemMaster.Name,
              type: itemMaster.ItemType,
              safety: itemMaster.SafetyAmount,
              amount: itemMaster.RemainAmount,
              defect: itemMaster.DefectAmount,
              unit: itemMaster.ItemUnit,
              average_use: itemMaster.AverageUsage,
              is_safety: {
                ItemId: itemMaster.Id,
                IsSafety: isNeedSafety(itemMaster.SafetyAmount, itemMaster.RemainAmount),
              },
              actions: itemMaster.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
              size: 20,
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
              size: 200,
            },
            {
              header: translate('title.type'),
              accessorKey: 'type',
              size: 50,
            },
            {
              header: translate('title.safety_amount'),
              accessorKey: 'safety',
              size: 50,
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
              size: 50,
            },
            {
              header: translate('title.amount_defect'),
              accessorKey: 'defect',
              size: 50,
            },
            {
              header: translate('warehouse.average_use'),
              accessorKey: 'average_use',
              size: 50,
            },
            {
              header: translate('unit.title'),
              accessorKey: 'unit',
              size: 50,
            },
            {
              header: translate('title.notification'),
              accessorKey: 'is_safety',
              size: 50,
              cell: (info) =>
                info.getValue().IsSafety ? (
                  <Label color={'error'}>{translate('warehouse.need_safety_stock')} </Label>
                ) : (
                  ''
                ),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    color={'success'}
                    onClick={() =>
                      navigate(
                        paths.procurement.create_supplier_sell_item +
                          `?SupplierId=${supplier}&ItemMasterId=${info.getValue()}`
                      )
                    }
                  >
                    <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default SearchItemMasterToCreateSellContainerPage;
