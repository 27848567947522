import React from 'react';
import { DataScanner } from 'components/barcode-reader';
import { useLocales } from 'locales';
import { Box, Stack, Typography } from '@mui/material';
import EmployeeCodeForm from 'components/forms/EmployeeCodeForm';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getEmployeeDetailByEmployeeCode } from 'requests/ProductionServerHandler';
import { OpenSuccessNotification } from 'utilities/Helper/NotificationHelper';
import { scanEmployeeIdBurma } from '../../locales/BurmaStrings';

EmployeeScanner.propsTypes = {
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
  onScanCallback: PropTypes.func.isRequired,
  isShowTooltips: PropTypes.bool,
  isPreFetchData: PropTypes.bool,
};

function EmployeeScanner({
  disableScan,
  disableInput,
  onScanCallback,
  isShowTooltips,
  isPreFetchData,
}) {
  const { translate } = useLocales();
  const mutation = useMutation(getEmployeeDetailByEmployeeCode, {
    onSuccess: async (data) => {
      await OpenSuccessNotification(translate('notification.success_find_employee'));
      onScanCallback(data);
    },
  });
  const onCameraBarCodeScan = async (data) => {
    if (data.length === 10) {
      if (isPreFetchData) {
        await mutation.mutateAsync(data);
      } else {
        return onScanCallback(data);
      }
    }
  };
  const employeeCodeForm = <EmployeeCodeForm onSubmitCallBack={onCameraBarCodeScan} />;
  return (
    <Stack spacing={2}>
      {isShowTooltips && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt={'scan'}
              style={{ height: '250px', width: '250px' }}
              src={'/assets/illustrations/scan_employee.png'}
              data-holder-rendered="true"
            />
          </Box>
          <Typography variant={'h3'}>
            {translate('placeholder.please_scan_your_employee_id')}
          </Typography>
          <Typography variant={'h3'}>{scanEmployeeIdBurma}</Typography>
        </>
      )}
      <DataScanner
        disableInput={disableInput}
        disableScan={disableScan}
        onScanCallback={onCameraBarCodeScan}
        FormComponent={employeeCodeForm}
      />
    </Stack>
  );
}

export default EmployeeScanner;
