import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getQualityYearDashboardData } from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import DetailWidget from '../../customs/DetailWidget';
import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import GeneralTable from '../../customs/GeneralTable';
import { useQuery } from '@tanstack/react-query';
import LineGraph from '../../graph/LineGraph';
import { thaiMonths } from '../../../utilities/Constants';

const QualityAssuranceDashboardPage = () => {
  const { translate } = useLocales();

  const { data: dashboardData } = useQuery({
    queryKey: ['getQualityYearDashboardData'],
    queryFn: getQualityYearDashboardData,
    initialData: {
      ProblemDetails: [],
      QualityStatic: [],
      TotalProblem: 0,
      TotalPile: 0,
      TotalPileCheck: 0,
    },
  });

  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[{ name: translate('qa.title') }, { name: translate('dashboard') }]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DetailWidget
            color={'info'}
            title={translate('qa.all_pile')}
            total={dashboardData.TotalPile}
            icon={'gis:layer-stack'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DetailWidget
            color={'success'}
            title={translate('qa.number_of_quality_check')}
            total={dashboardData.TotalPileCheck}
            icon={'gis:layer-stack'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DetailWidget
            color={'warning'}
            title={translate('qa.problem_list')}
            total={dashboardData.TotalProblem}
            icon={'gis:layer-stack'}
            isSimple
          />
        </Grid>
        <Grid item xs={12}>
          <LineGraph
            title={translate('qa.quality_assurance_check_dashboard_title')}
            chart={{
              categories: thaiMonths,
              series: [
                {
                  year: 'Line',
                  data: [
                    {
                      name: 'จำนวนปัญหา',
                      data: dashboardData.QualityStatic.map((information) => {
                        return information.ProblemAmount;
                      }),
                    },
                  ],
                },
              ],
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={translate('qa.problem_ranking')} />
            <CardContent>
              <GeneralTable
                tableHeaders={[
                  { align: 'left', title: '#' },
                  { align: 'left', title: 'title.name' },
                  { align: 'left', title: 'title.amount' },
                ]}
                tableData={dashboardData.ProblemDetails.map((problem, index) => {
                  return [index + 1, problem.Name, problem.Amount];
                })}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
};

export default QualityAssuranceDashboardPage;
