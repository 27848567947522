import React from 'react';
import { useLocales } from 'locales';
import { Box } from '@mui/material';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ItJobListTable from '../../customs/ItJobListTable';
import useTabs from '../../../hooks/useTabs';
import { paths } from '../../../routes/paths';

function ItJobListPage() {
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs('new');

  const INFORMATION_TABS = [
    {
      value: 'new',
      title: translate('maintenance.total_wait_job'),
      icon: <Iconify icon={'entypo:new'} width={20} height={20} />,
      component: <ItJobListTable job_status_id={1} />,
    },
    {
      value: 'wip',
      title: translate('title.work_in_process'),
      icon: <Iconify icon={'carbon:in-progress'} width={20} height={20} />,
      component: <ItJobListTable job_status_id={2} />,
    },
    {
      value: 'rating',
      title: translate('status.wait_for_rating'),
      icon: <Iconify icon={'mdi:success'} width={20} height={20} />,
      component: <ItJobListTable job_status_id={4} />,
    },
    {
      value: 'success',
      title: translate('status.success'),
      icon: <Iconify icon={'mdi:success'} width={20} height={20} />,
      component: <ItJobListTable job_status_id={5} />,
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('it.it_job_list')}
      breadcrumbs={[{ name: translate('it.title') }, { name: translate('it.it_job_list') }]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          component={RouterLink}
          to={paths.it.create_it_job}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
        >
          {translate('it.create_job')}
        </Button>
      }
    >
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
}

export default ItJobListPage;
