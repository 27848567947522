import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { createWarehouseShelf } from '../../../requests/WarehouseServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import { useLocales } from 'locales';
import CreateInventoryShelfForm from '../../forms/CreateInventoryShelfForm';
import { useWarehouseOptions } from '../../../requests/query/DropdownData';
import { useMutation } from '@tanstack/react-query';

function CreateWarehouseShelfPage() {
  const { translate } = useLocales();
  const { warehouseOptions } = useWarehouseOptions();

  const createWarehouseShelfMutation = useMutation({
    mutationFn: createWarehouseShelf,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const handleCreateWarehouseShelf = async (values) => {
    await createWarehouseShelfMutation.mutateAsync({
      Name: values.Name,
      WarehouseId: values.InventoryStore.Id,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_new_shelf')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.create_new_shelf') },
      ]}
    >
      <ProjectPaper>
        <CreateInventoryShelfForm
          onSubmitCallback={handleCreateWarehouseShelf}
          storeOptions={warehouseOptions}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateWarehouseShelfPage;
