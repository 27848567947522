import {
  acceptMaintenanceJob,
  changeMaintenanceJobStatus,
  changeMaintenanceTechnician,
  completeMaintenanceJob,
  evaluateMaintenanceJob,
  getMaintenanceJobDetailById,
  holdMaintenanceJobStatus,
  archiveMaintenanceJob,
} from '../ProductionServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createRequestExternalProvider,
  getMaintenanceJobServicePurchaseOrder,
} from '../WarehouseServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';
import { useLocales } from '../../locales';
import { MaintenanceJobStatus } from '../../utilities/Constants';

export const useMaintenanceJob = (id) => {
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['maintenanceJob', id],
    queryFn: () => getMaintenanceJobDetailById(id),
    initialData: null,
  });

  const { data: service_po } = useQuery({
    queryKey: ['maintenanceJob-service-po', id],
    queryFn: () => getMaintenanceJobServicePurchaseOrder(id),
    initialData: null,
  });

  const acceptJobMutation = useMutation({
    mutationFn: acceptMaintenanceJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification();
    },
  });

  const completeJobMutation = useMutation({
    mutationFn: completeMaintenanceJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification();
    },
  });

  const changeTechnicianMutation = useMutation({
    mutationFn: changeMaintenanceTechnician,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification(translate('success.change_technician'));
    },
  });

  const evaluateJobMutation = useMutation({
    mutationFn: evaluateMaintenanceJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification();
    },
  });

  const changeJobStatusMutation = useMutation({
    mutationFn: changeMaintenanceJobStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification();
    },
  });

  const requestExternalProviderMutation = useMutation({
    mutationFn: createRequestExternalProvider,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob-service-po', id]);
      await changeJobStatusMutation.mutate({
        Id: id,
        MaintenanceJobStatusId: MaintenanceJobStatus.WaitForSupplier,
      });
    },
  });

  const archivedJobMutation = useMutation({
    mutationFn: archiveMaintenanceJob,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification();
    },
  });

  const holdJobMutation = useMutation({
    mutationFn: holdMaintenanceJobStatus,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['maintenanceJob', id]);
      await OpenSuccessNotification();
    },
  });

  return {
    jobDetail: data,
    jobServicePO: service_po,
    acceptJob: acceptJobMutation,
    completeJob: completeJobMutation,
    changeTechnician: changeTechnicianMutation,
    evaluateJob: evaluateJobMutation,
    requestExternalProvider: requestExternalProviderMutation,
    changeJobStatus: changeJobStatusMutation,
    archivedJob: archivedJobMutation,
    holdJob: holdJobMutation,
  };
};
