import { handleLoadingTask, warehouseServerRequest } from '../utilities/Helper/NetworkHelper';
export async function getAllAttributeNumbers() {
  const options = {
    method: 'GET',
    url: `warehouse/attribute-number/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllAttributeStrings() {
  const options = {
    method: 'GET',
    url: `warehouse/attribute-string/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getBOMDetailById(id) {
  const options = {
    method: 'GET',
    url: `bom/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function createBOM(data) {
  const options = {
    method: 'POST',
    url: `bom/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllBOMType() {
  const options = {
    method: 'GET',
    url: `bom/bom-type/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getAllMaterial(data) {
  const options = {
    method: 'GET',
    url: `bom/material/all`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllProductBOMs() {
  const options = {
    method: 'GET',
    url: `bom/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function createMaterial(data) {
  const options = {
    method: 'POST',
    url: `bom/material/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllItemMasterList() {
  const options = {
    method: 'GET',
    url: `bom/all-item-master`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllMaterialType() {
  const options = {
    method: 'GET',
    url: `bom/all-material-type`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllMaterialUnit() {
  const options = {
    method: 'GET',
    url: `bom/all-material-unit`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}


export async function bindWarehouseEmployeeWithUserId(data) {
  const options = {
    method: 'PATCH',
    url: `employee/bind-user`,
    data: data
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getSupplierPurchaseExcel(params) {
  const options = {
    method: 'GET',
    url: `procurement/supplier-purchase-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}
export async function getStockTransactionExcelFile(params) {
  const options = {
    method: 'GET',
    url: `warehouse/stock-transaction-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}

export async function getItemContainerExcelFileURL() {
  const options = {
    method: 'GET',
    url: `warehouse/item-container-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}

export async function getDownloadItemMasterTransactionExcelFileURL(start) {
  const options = {
    method: 'GET',
    url: `warehouse/item-master-history-excel?fromDate=${start}`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}

export async function getItemMasterExcelFileURL() {
  const options = {
    method: 'GET',
    url: `warehouse/item-master-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}

export async function getDownloadItemRequisitionExcelFileURL(params) {
  const options = {
    method: 'GET',
    url: `warehouse/item-requisition-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}

export async function getDownloadItemMasterRequisitionExcelFileURL(params) {
  const options = {
    method: 'GET',
    url: `warehouse/item-master-requisition-excel`,
    header: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    params: params,
    responseType: 'blob',
  };

  const { data } = await warehouseServerRequest(options);
  return data;
}

export async function uploadItemMasterPriceFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  const options = {
    method: 'POST',
    url: `warehouse/upload-item-master-price-file`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemReceiptBarcodePDFById(itemReceiptId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-receipt/print-barcode/${itemReceiptId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemReceiptPDFById(itemReceiptId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-receipt/print/${itemReceiptId}`,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get item receipt by id
 * @param {number|string} itemReceiptId - itemReceiptId
 */
export async function getItemReceiptById(itemReceiptId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-receipt/detail/${itemReceiptId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export function getWarehouseNumberMatchSearch(searchString) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `utilities/search-number/${searchString}`,
    };
    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}

/**
 * search string match
 * @param {string} searchString - searchString
 */
export function getWarehouseStringMatchSearch(searchString) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `utilities/search/${searchString}`,
    };
    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}

export async function createWarehouseEmployee(data) {
  const options = {
    method: 'POST',
    url: `employee/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function cancelServicePO(id) {
  const options = {
    method: 'PATCH',
    url: `procurement/spo/cancel`,
    data: {
      PurchaseOrderId: id,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getServicePurchaseOrderPDF(id) {
  const options = {
    method: 'GET',
    url: `procurement/spo/print/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function uploadApproveSPODocument(data) {
  const formData = new FormData();
  formData.append('Id', data.Id);
  formData.append('Document', data.Document);
  formData.append('UploadEmployeeId', data.UploadEmployeeId);

  const options = {
    method: 'POST',
    url: `procurement/spo/upload-approve-spo`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getServicePurchaseOrderDetailById(id) {
  const options = {
    method: 'GET',
    url: `procurement/spo/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function completeServicePurchaseOrderRequest(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/spo/complete`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function editSupplier(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/supplier/edit`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getSupplierDetailById(supplierId) {
  const options = {
    method: 'GET',
    url: `procurement/supplier/detail/${supplierId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getAllServicePurchaseOrder() {
  const options = {
    method: 'GET',
    url: `procurement/spo/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createSupplier(data) {
  const options = {
    method: 'POST',
    url: `procurement/supplier/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createSupplierSellItemContainer(data) {
  const options = {
    method: 'POST',
    url: `procurement/supplier/create-sell-item-container`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createMaterialPO(data) {
  const options = {
    method: 'POST',
    url: `procurement/po/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function editSupplierSellItemContainerDetail(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/supplier/supplier-sell-item-container/edit`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemMasterSellerList(itemMasterId) {
  const options = {
    method: 'GET',
    url: `procurement/item-master-seller-list/${itemMasterId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * cancel item in PR
 * @param {number|string} prItemMasterId - pr item master id
 */
export async function cancelPRItemMaster(prItemMasterId) {
  const options = {
    method: 'PATCH',
    url: `procurement/pr-item-master/cancel`,
    data: {
      PurchaseRequisitionItemMasterId: prItemMasterId,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemToPurchases() {
  const options = {
    method: 'GET',
    url: `procurement/item-to-purchase`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function editPODetail(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-edit-detail`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function editPOReferenceId(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-edit-reference-id`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function addVatToPO(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po/add-vat`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function removeVatFromPO(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po/remove-vat`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function removeItemFromPO(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po/remove-item`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
/**
 * upload PO Document
 */
export async function uploadApprovePODocument(data) {
  const formData = new FormData();
  formData.append('Id', data.Id);
  formData.append('Document', data.Document);
  formData.append('UploadEmployeeId', data.UploadEmployeeId);

  const options = {
    method: 'POST',
    url: `procurement/po/upload-approve-po`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function editPOItemContainer(data) {
  const options = {
    method: 'PATCH',
    url: `procurement/po-edit-item`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function cancelPurchaseOrder(poId) {
  const options = {
    method: 'PATCH',
    url: `procurement/po/cancel`,
    data: {
      PurchaseOrderId: poId,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getPurchaseOrderPDF(poId) {
  const options = {
    method: 'GET',
    url: `procurement/po/print/${poId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getPurchaseOrderDetailById(poId) {
  const options = {
    method: 'GET',
    url: `procurement/po/detail/${poId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get all active material PO
 */
export async function getAllActivePO() {
  const options = {
    method: 'GET',
    url: `procurement/po/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * upload PR Document
 */
export async function uploadPRDocument(data) {
  const formData = new FormData();
  formData.append('Id', data.Id);
  formData.append('Document', data.Document);
  formData.append('UploadEmployeeId', data.UploadEmployeeId);

  const options = {
    method: 'POST',
    url: `procurement/pr/upload-approve-pr`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function printPurchaseRequisition(prId) {
  const options = {
    method: 'GET',
    url: `procurement/pr/print/${prId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function cancelPurchaseRequisition(prId) {
  const options = {
    method: 'PATCH',
    url: `procurement/pr/cancel`,
    data: {
      Id: prId,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getPurchaseRequisitionDetail(prId) {
  const options = {
    method: 'GET',
    url: `procurement/pr/detail/${prId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getAllMaterialPurchaseRequisition() {
  const options = {
    method: 'GET',
    url: `procurement/pr/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createPurchaseRequisition(data) {
  const options = {
    method: 'POST',
    url: `procurement/pr/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getAllCreatePurchaseRequisitionData() {
  const options = {
    method: 'GET',
    url: `utilities/all-create-pr-data`,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * create new unit
 * @param {string} name - unit name
 */
export function createItemUnit(name) {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      url: `warehouse/create-item-unit`,
      data: {
        Name: name,
      },
    };

    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}
export async function getSupplierSellItemById(id = 0) {
  const options = {
    method: 'GET',
    url: `procurement/supplier/supplier-sell-item-container/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllItemUnit() {
  const options = {
    method: 'GET',
    url: `utilities/all-item-unit`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function getAllItemMasterBySafety() {
  const options = {
    method: 'GET',
    url: `warehouse/item-master/all/safety`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function returnFilm(data) {
  const options = {
    method: 'POST',
    url: `warehouse/return-film`,
    data: data,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createItemLotFromAdmin(data) {
  const options = {
    method: 'POST',
    url: `warehouse/admin-create-item-lot`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get all supplier
 */
export async function getAllSupplier() {
  const options = {
    method: 'GET',
    url: `procurement/supplier/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function moveShelf(data) {
  const options = {
    method: 'PATCH',
    url: `warehouse/move-shelf`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getWarehouseShelfDetail(shelfId) {
  const options = {
    method: 'GET',
    url: `warehouse/shelf/detail/${shelfId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createWarehouseShelf(data) {
  const options = {
    method: 'POST',
    url: `warehouse/shelf/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get all warehouse
 */
export async function getAllWarehouse() {
  const options = {
    method: 'GET',
    url: `utilities/all-warehouse`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createWarehouse(name) {
  const options = {
    method: 'POST',
    url: `warehouse/create`,
    data: {
      Name: name,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get return item report pdf
 * @param {number | string} returnReportId - returnReportId
 */
export async function getItemReturnReportPDF(returnReportId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-return-report/print/${returnReportId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function returnItemToWarehouse(data) {
  const options = {
    method: 'PATCH',
    url: 'warehouse/return-items',
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function acceptItemReceiptById(data) {
  let endPoint = `warehouse/accept-item-receipt`;
  if (data.IsTemp) {
    endPoint = `quick-request/accept-item-receipt`;
  }

  const options = {
    method: 'PATCH',
    url: endPoint,
    data: data,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemContainerBySKU(sku) {
  const options = {
    method: 'GET',
    url: `warehouse/item-container/sku/${sku}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemDetailByBarcodeId(barcodeId) {
  const options = {
    method: 'GET',
    url: `warehouse/item/search/qr?Code=${barcodeId}`,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}
export async function cancelItemRequisitionId(id) {
  const options = {
    method: 'PATCH',
    url: `warehouse/item-requisition-report/cancel`,
    data: {
      ItemRequisitionReportId: id,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createItemReceipt(data) {
  const options = {
    method: 'POST',
    url: `warehouse/item-receipt/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * approve item requisition
 * @param {number | string} id - item requisition id
 * @param {number} employeeId - approve employee id
 */
export async function approveItemRequisitionId(id, employeeId) {
  const options = {
    method: 'PATCH',
    url: `warehouse/approve-item-requisition`,
    data: {
      ItemRequisitionReportId: id,
      ApproveEmployeeId: employeeId,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get all item requisition reports
 */
export async function getAllItemRequisitionReport() {
  const options = {
    method: 'GET',
    url: `warehouse/item-requisition-report/all`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get incoming item
 */
export async function getIncomingItemContainer() {
  const options = {
    method: 'GET',
    url: `warehouse/incoming-item-container-list`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemDetailById(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item/detail/${id}`,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function deleteItemById(id) {
  const options = {
    method: 'DELETE',
    url: `warehouse/item/delete`,
    data: {
      ItemId: id,
    },
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemQRCode(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item/print/qr/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getWholeItemLotQRCode(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item-lot/print/whole-item-qr/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get item lot detail
 * @param {number | string} id - ItemLotId id
 */
export async function getItemLotById(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item-lot/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemContainerId(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item-container/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createItemMasterContainer(data) {
  const options = {
    method: 'POST',
    url: `warehouse/create-item-container`,
    data: data,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemMasterStockTransactionData(itemMasterId, params) {
  const options = {
    method: 'GET',
    url: `warehouse/item-master/transaction/${itemMasterId}`,
    params: params,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function editItemMaster(data) {
  const options = {
    method: 'PATCH',
    url: `warehouse/item-master/edit`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * save item master image
 */
export async function uploadItemMasterImage(data) {
  const formData = new FormData();
  formData.append('Id', data.Id);
  formData.append('image', data.Image);

  const options = {
    method: 'POST',
    url: `warehouse/upload-item-master-image`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createItemMaster(data) {
  const options = {
    method: 'POST',
    url: `warehouse/create-item-master`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get create item master require info
 */
export async function getAllCreateItemMasterData() {
  const options = {
    method: 'get',
    url: `utilities/all-create-item-master-data`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemMasterDetailById(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item-master/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * get item requisition pdf
 * @param {number | string} id - ItemRequisition id
 */
export async function getItemRequisitionPDF(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item-requisition-report/print/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createItemRequisition(data) {
  let endPoint = `warehouse/create-request-or-purchase-items`;
  if (data.IsTemp) {
    endPoint = `quick-request/create-request-purchase-items`;
  }

  const options = {
    method: 'POST',
    url: endPoint,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemRequisitionDetailById(id) {
  const options = {
    method: 'GET',
    url: `warehouse/item-requisition-report/detail/${id}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getItemMasterItemsByCategory(categoryId) {
  const options = {
    method: 'GET',
    url: `warehouse/item-master-store/all/category/${categoryId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getWarehouseDashboard(params) {
  const options = {
    method: 'GET',
    url: `warehouse/dashboard`,
    params: params,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getMaintenanceJobServicePurchaseOrder(maintenanceJobId) {
  const options = {
    method: 'get',
    url: `procurement/spo/get-mt/${maintenanceJobId}`,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function createRequestExternalProvider(data) {
  const options = {
    method: 'post',
    url: `procurement/spo/create`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getProductOrderItemRequisitionDetailById(productOrderId) {
  const options = {
    method: 'get',
    url: `warehouse/product-order-item-requisition/${productOrderId}`,
  };

  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function acceptItemContainer(data) {
  const options = {
    method: 'POST',
    url: `warehouse/accept-item-container`,
    data: data,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

export async function getPurchaseOrderItemContainerById(poItemContainerId) {
  const options = {
    method: 'GET',
    url: `warehouse/po-item-container-by-id/${poItemContainerId}`,
  };
  const response = await warehouseServerRequest(options);
  return response.data;
}

/**
 * Get all item master name and id
 */
export function getAllItemMasterName() {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: 'warehouse/all-item-master-name',
    };
    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}

/**
 * Get all item container name of item master
 * @param {number} itemMasterId - item master id
 */
export function getAllItemContainerByMasterId(itemMasterId) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `warehouse/all-item-container-name-by-master-id/${itemMasterId}`,
    };
    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}

/**
 * Get all item container po
 * @param {number} itemContainerId - item container id
 */
export function getAllItemContainerPOByContainerId(itemContainerId) {
  return async (dispatch) => {
    const options = {
      method: 'GET',
      url: `warehouse/all-item-container-po-by-container-id/${itemContainerId}`,
    };
    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}

/**
 * change container unit
 * @param {number} itemContainerId - container id
 * @param {number} itemUnitId - unit id
 */
export function changeItemContainerUnit(itemContainerId, itemUnitId) {
  return async (dispatch) => {
    const options = {
      method: 'PATCH',
      url: `warehouse/change-container-unit`,
      data: {
        ItemContainerId: itemContainerId,
        ItemUnitId: itemUnitId,
      },
    };
    return await dispatch(handleLoadingTask(warehouseServerRequest(options)));
  };
}
