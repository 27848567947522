import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import { useItemMasters, useMaterialTypes, useMaterialUnits } from '../../requests/query/DropdownData';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';
import { Card, CardContent, CardHeader, IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { ADD_ICON, DELETE_ICON } from '../../config-global';
import Iconify from '../minimal/iconify';
import GeneralTable from '../customs/GeneralTable';

CreateMaterialForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

function CreateMaterialForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const { materialTypes } = useMaterialTypes();
  const { materialUnits } = useMaterialUnits();
  const { itemMasters } = useItemMasters();

  const createMaterialSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.detail_require')),
    MaterialType: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
    MaterialUnit: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
    ItemMasters: Yup.array().of(
      Yup.object()
        .shape({
          ItemMaster: Yup.object()
            .shape({
              Id: Yup.number().min(1).required(translate('error.detail_require')),
              Name: Yup.string().required(translate('error.detail_require')),
              ConvertToAmount: Yup.number().min(0, translate('error.detail_require')),
            })
            .required(translate('error.price_too_low')),
          ConvertAmount: Yup.number().min(0, translate('error.detail_require')),
        })
        .required(translate('error.price_too_low')),
    ),
  });
  const methods = useForm({
    resolver: yupResolver(createMaterialSchema),
    defaultValues: {
      Name: '',
      MaterialType: null,
      MaterialUnit: null,
      ItemMasters: [],
    },
  });

  const { handleSubmit, control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ItemMasters',
  });

  const onSubmit = async (values) => {
    onSubmitCallback(values);
  };
  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Card>
          <CardHeader title={translate('title.detail')} />
          <CardContent>
            <Stack spacing={2}>
              <RHFTextField name="Name" label={translate('title.name')} />
              <RHFAutoCompleteShort name="MaterialType" label={translate('title.type')} data={materialTypes} />
              <RHFAutoCompleteShort name="MaterialUnit" label={translate('title.unit')} data={materialUnits} />
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={translate('warehouse.item_master')}
                      action={<Button color={'info'} startIcon={<Iconify icon={ADD_ICON} />} variant={'contained'}
                                      onClick={() => append({ ItemMaster: null, ConvertAmount: 0 })}
                                      fullWidth>
                        {translate('button.add')}
                      </Button>} />
          <CardContent>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: translate('title.name') },
                { align: 'left', title: translate('product.convert_to_amount') },
                { align: 'left', title: translate('Actions') },
              ]}
              tableData={fields.map((itemMaster, index) => {
                return [
                  <RHFAutoCompleteShort name={`ItemMasters[${index}].ItemMaster`}
                                        label={translate('warehouse.item_master')}
                                        data={itemMasters} />,
                  <RHFTextField name={`ItemMasters[${index}].ConvertAmount`}
                                label={translate('product.convert_to_amount')} />,
                  <IconButton color={'error'} onClick={() => handleRemove(index)}>
                    <Iconify icon={DELETE_ICON} />
                  </IconButton>,
                ];
              })} />
          </CardContent>
        </Card>
        <Button color={'primary'} variant={'contained'} fullWidth type="submit">
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateMaterialForm;