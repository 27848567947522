import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, IconButton } from '@mui/material';
import { RouterLink } from '../../../routes/components';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from '../../minimal/iconify';
import GeneralTable from '../../customs/GeneralTable';
import { OPEN_ICON } from '../../../config-global';
import { paths } from '../../../routes/paths';

ProductOrderBillingNoteDetail.propTypes = {
  billingNote: PropTypes.array,
};

function ProductOrderBillingNoteDetail({ billingNote }) {
  return (
    <Card>
      <CardContent>
        <GeneralTable
          tableHeaders={[
            { align: 'left', title: 'title.id' },
            { align: 'left', title: 'title.create_by' },
            { align: 'left', title: 'title.create_at' },
            { align: 'left', title: 'title.canceled_at' },
            { align: 'left', title: 'title.billing_at' },
            { align: 'left', title: '' },
          ]}
          tableData={billingNote.map((bill) => [
            bill.Id,
            bill.CreateEmployee,
            fDateTimeText(bill.createdAt),
            fDateTimeText(bill.ArchivedAt),
            fDateTimeText(bill.BillingAt),
            <IconButton
              key={bill.Id}
              component={RouterLink}
              href={paths.accounting.billing_note_detail(bill.Id)}
            >
              <Iconify icon={OPEN_ICON} />
            </IconButton>,
          ])}
        />
      </CardContent>
    </Card>
  );
}

export default ProductOrderBillingNoteDetail;
