import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getItemTemplateStockTransactionData } from '../../../requests/ProductionServerHandler';
import SILDatePicker from '../../date-picker/SILDatePicker';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { useLocales } from '../../../locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';

export const ItemTemplateHistoryDetailComponent = ({ itemTemplate }) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const { data, refetch } = useQuery({
    queryKey: ['item-template-stock-transaction', itemTemplate.Id, dateRange],
    queryFn: () =>
      getItemTemplateStockTransactionData(itemTemplate.Id, {
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
      }),
    initialData: {
      Transactions: [],
    },
  });

  const fetchHistoryDataByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };

  const handleTableData = (rawData) => {
    return rawData.Transactions.map((transaction) => {
      return {
        report_id: transaction.StockReport !== null ? transaction.StockReport.Id : 'N/A',
        amount: transaction.Amount,
        cut_at: transaction.CreatedAt,
        fulfill_by: transaction.CreateEmployee,
        accept_by: transaction.AcceptEmployee,
        accepted_at: transaction.StockReport,
        transaction_type: transaction.StockTransactionType,
      };
    });
  };

  return (
    <>
      <SILDatePicker onSearchDate={fetchHistoryDataByDate} />
      <ProjectPaper>
        <ReactTable
          data={handleTableData(data)}
          header={[
            {
              header: translate('warehouse.stock_report_id'),
              accessorKey: 'report_id',
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
            },
            {
              header: translate('warehouse.check_out_at_title'),
              accessorKey: 'cut_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('warehouse.fulfill_by_title'),
              accessorKey: 'fulfill_by',
            },
            {
              header: translate('warehouse.accept_at_title'),
              accessorKey: 'accepted_at',
              cell: (info) => {
                const report = info.getValue();
                return report !== null
                  ? report.AcceptedAt !== null
                    ? fDateTimeText(report.AcceptedAt)
                    : 'N/A'
                  : 'N/A';
              },
            },
            {
              header: translate('warehouse.accept_by_title'),
              accessorKey: 'accept_by',
            },
            {
              header: translate('title.type'),
              accessorKey: 'transaction_type',
              cell: (info) => {
                return (
                  <Typography
                    variant={'body2'}
                    color={
                      info.getValue().Id === 1
                        ? theme.palette.error.main
                        : theme.palette.success.main
                    }
                  >
                    {info.getValue().Name}
                  </Typography>
                );
              },
            },
          ]}
        />
      </ProjectPaper>
    </>
  );
};

ItemTemplateHistoryDetailComponent.PropType = {
  itemTemplate: PropTypes.object.isRequired,
};
