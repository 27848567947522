import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { paths } from 'routes/paths';
import { RouterLink } from 'routes/components';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
  ManagementUserTypeId,
  SalesUserTypeId,
} from 'utilities/Constants';
import { OpenConfirmNotification } from 'utilities/Helper/NotificationHelper';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { useLocales } from 'locales';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import { fCurrency, fNumber } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import { useTheme } from '@mui/material/styles';
import Iconify from 'components/minimal/iconify';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import ReactTable from 'components/react-table/ReactTable';
import ErrorPage from '../MainModule/ErrorPage';
import { useDeliveryBill } from 'requests/query/useDeliveryBill';
import { OPEN_ICON } from '../../../config-global';

export default function DeliveryBillDetailPage(props) {
  const { id } = useParams();
  const { translate } = useLocales();
  const theme = useTheme();

  const { deliveryBillDetail, printDeliveryBill, cancelDeliveryBill, cancelExampleDeliveryBill } =
    useDeliveryBill(id);

  const onPrintBill = async () => {
    await printDeliveryBill.mutateAsync(id);
  };

  const onCancelBill = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_cancel_data'),
      translate('button.confirm'),
      '',
      async () => {
        await cancelDeliveryBill.mutateAsync(id);
      },
    );
  };

  const onCancelExampleBill = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_cancel_example_bill'),
      translate('button.confirm'),
      '',
      async () => {
        await cancelExampleDeliveryBill.mutateAsync(id);
      },
    );
  };

  const getBillStatus = (deliveryBillDetail) => {
    if (deliveryBillDetail?.ArchivedAt !== null) {
      if (deliveryBillDetail?.IsExample) {
        return (
          <GridDetailText
            title={translate('title.status')}
            value={translate('status.cancel_example_bill')}
            color={theme.palette.error.main}
          />
        );
      } else {
        return (
          <GridDetailText
            title={translate('title.status')}
            value={translate('button.cancel')}
            color={theme.palette.error.main}
          />
        );
      }
    }
    if (deliveryBillDetail.CashDeliveryBills.length > 0) {
      if (deliveryBillDetail.CashDeliveryBills[0].InvoicedAt !== null) {
        return (
          <GridDetailText
            title={translate('title.status')}
            value={translate('finance.already_collect_payment')}
            color={theme.palette.success.main}
          />
        );
      } else {
        return (
          <GridDetailText
            title={translate('title.status')}
            value={translate('finance.wait_for_collect_payment')}
            color={theme.palette.info.main}
          />
        );
      }
    } else {
      if (deliveryBillDetail.BillingNotes.length > 0) {
        return (
          <GridDetailText
            title={translate('title.status')}
            value={translate('accounting.already_bill')}
            color={theme.palette.success.main}
          />
        );
      }
    }
    return (
      <GridDetailText
        title={translate('title.status')}
        value={translate('title.normal')}
        color={theme.palette.info.main}
      />
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.delivery_bill_detail')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.search_bill'), href: paths.accounting.search },
        { name: `${translate('accounting.delivery_bill_detail')} : ${id}` },
      ]}
      action={
        deliveryBillDetail !== null && deliveryBillDetail.ArchivedAt === null ? (
          <>
            {deliveryBillDetail.IsCash &&
            deliveryBillDetail.CashDeliveryBills[0].InvoicedAt === null ? null : (
              <RoleBasedGuard
                roles={[
                  ManagementUserTypeId,
                  AdminUserTypeId,
                  AccountingUserTypeId,
                  SalesUserTypeId,
                  FinanceUserTypeId,
                ]}
              >
                <Button
                  variant="contained"
                  color={'info'}
                  startIcon={<Iconify icon={'ic:outline-print'} />}
                  onClick={onPrintBill}
                >
                  {translate('button.print')}
                </Button>
              </RoleBasedGuard>
            )}
            <RoleBasedGuard roles={[AccountingUserTypeId, ManagementUserTypeId]}>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={onCancelBill}
              >
                {translate('button.cancel')}
              </Button>
            </RoleBasedGuard>
            {deliveryBillDetail.BillingNotes.length === 0 && (
              <RoleBasedGuard roles={[ManagementUserTypeId, AccountingUserTypeId]}>
                <Button
                  sx={{ ml: 2 }}
                  variant="contained"
                  color={'error'}
                  startIcon={<Iconify icon={'eva:close-fill'} />}
                  onClick={onCancelExampleBill}
                >
                  {translate('button.cancel_for_example')}
                </Button>
              </RoleBasedGuard>
            )}
          </>
        ) : null
      }
    >
      {deliveryBillDetail !== null ? (
        <Stack spacing={3}>
          <Card>
            <CardHeader title={translate('title.detail')} />
            <CardContent>
              <Grid container spacing={2}>
                <GridDetailText
                  title={translate('title.delivery_by')}
                  value={getEmployeeFullName(deliveryBillDetail?.CreateEmployee)}
                />
                <GridDetailText
                  title={translate('title.customer')}
                  value={deliveryBillDetail?.Customer.Name}
                />
                <GridDetailText
                  title={translate('title.name')}
                  link={paths.production.product_order_detail(
                    deliveryBillDetail?.Piles[0].ProductOrder.Id,
                  )}
                  value={deliveryBillDetail?.Piles[0].ProductOrder.Name}
                />
                <GridDetailText
                  title={translate('title.amount')}
                  value={`${fNumber(
                    deliveryBillDetail?.Piles.reduce((accumulator, pile) => {
                      return accumulator + pile.GoodAmount;
                    }, 0),
                  )} ${translate('unit.sheet')}`}
                />
                <GridDetailText
                  title={translate('title.value')}
                  value={fCurrency(deliveryBillDetail?.TotalValue)}
                />
                <GridDetailText
                  title={translate('title.create_at')}
                  value={fDateTimeText(deliveryBillDetail?.createdAt)}
                />
                <GridDetailText
                  title={translate('accounting.bill_type')}
                  value={
                    deliveryBillDetail?.IsCash
                      ? translate('accounting.cash_bill')
                      : translate('accounting.credit_bill')
                  }
                />
                {getBillStatus(deliveryBillDetail)}
                {deliveryBillDetail?.ArchivedAt !== null && (
                  <>
                    <GridDetailText
                      title={translate('title.canceled_at')}
                      value={fDateTimeText(deliveryBillDetail?.ArchivedAt)}
                    />
                    <GridDetailText
                      title={translate('title.canceled_by')}
                      value={getEmployeeFullName(deliveryBillDetail?.CanceledEmployee)}
                    />
                  </>
                )}
                {deliveryBillDetail?.BillingNotes.length > 0 && (
                  <>
                    <GridDetailText
                      title={translate('sales.billing_note')}
                      link={paths.accounting.billing_note_detail(
                        deliveryBillDetail?.BillingNotes[0].Id,
                      )}
                      value={deliveryBillDetail?.BillingNotes[0].Id}
                    />
                    <GridDetailText
                      title={translate('accounting.billing_by')}
                      value={getEmployeeFullName(
                        deliveryBillDetail?.BillingNotes[0].CreateEmployee,
                      )}
                    />
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('title.pile')} />
            <CardContent>
              <ReactTable
                data={deliveryBillDetail?.Piles.map((pile) => {
                  return {
                    id: pile.Id,
                    amount: pile.GoodAmount,
                    action: pile.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                  },
                  {
                    header: translate('title.amount_good'),
                    accessorKey: 'amount',
                    cell: (info) => fNumber(info.getValue()),
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'action',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        href={paths.production.pile_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Stack>
      ) : (
        <ErrorPage />
      )}
    </ProjectPageContainer>
  );
}
