import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { getPlanningDashboard } from '../../../requests/ProductionServerHandler';
import DetailWidget from '../../customs/DetailWidget';
import { fNumber } from '../../../utilities/formatNumber';
import { Grid } from '@mui/material';
import { useLocales } from 'locales';
import { useQuery } from '@tanstack/react-query';

function PlanningDashboardPage() {
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const { translate } = useLocales();
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const { data: dashboardData, refetch } = useQuery({
    queryKey: ['planning-dashboard', dateRange],
    queryFn: () =>
      getPlanningDashboard({
        fromDate: dateRange.fromDate.toISOString(),
        toDate: dateRange.toDate.toISOString(),
      }),
    initialData: {
      CreatePile: 0,
      ApprovePile: 0,
      CreateProductOrder: 0,
      CreateProductOrderByPlanning: 0,
    },
  });

  const fetchDashboardDataByDate = async (fromDate, toDate) => {
    await setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };

  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[{ name: translate('planning.title') }, { name: translate('dashboard') }]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SILDatePicker onSearchDate={fetchDashboardDataByDate} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <DetailWidget
            color={'info'}
            title={translate('planning.incoming_product_order')}
            total={fNumber(dashboardData.CreateProductOrder)}
            icon={'clarity:sign-in-line'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <DetailWidget
            color={'success'}
            title={translate('planning.create_order_by_planning')}
            total={fNumber(dashboardData.CreateProductOrderByPlanning)}
            icon={'carbon:add'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <DetailWidget
            color={'warning'}
            title={translate('production.create_pile')}
            total={fNumber(dashboardData.CreatePile)}
            icon={'carbon:add'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <DetailWidget
            color={'error'}
            title={translate('planning.approve_exceed_amount')}
            total={fNumber(dashboardData.ApprovePile)}
            icon={'bi:clipboard-check'}
            isSimple
          />
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
}

export default PlanningDashboardPage;
