import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useLocales } from 'locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Alert } from '@mui/lab';
import PropTypes from 'prop-types';

DateSelectorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSelectDateComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
function DateSelectorModal({ isOpen, onSelectDateComplete, onClose }) {
  const { translate } = useLocales();
  const [selectDate, setSelectDate] = useState(new Date());
  const [isError, setIsError] = useState(false);
  const handleSelectDateChange = (time) => {
    setIsError(false);
    setSelectDate(time);
  };
  const handleDialogClose = async () => {
    onClose();
  };
  const handleSelectDate = async () => {
    await handleDialogClose();
    onSelectDateComplete(selectDate);
    setSelectDate(new Date());
  };

  return (
    <Dialog open={isOpen} onClose={handleDialogClose} scroll={'paper'}>
      <DialogTitle>{translate('title.select_date')}</DialogTitle>
      <DialogContent dividers>
        {isError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {translate('error.wrong_date_range')}
          </Alert>
        )}
        <DatePicker
          inputFormat="dd/MM/yyyy"
          label={translate('title.start_date')}
          value={selectDate}
          onChange={handleSelectDateChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} onClick={handleDialogClose}>
          {translate('button.close')}
        </Button>
        <Button variant={'contained'} onClick={handleSelectDate}>
          {translate('button.selected')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateSelectorModal;
