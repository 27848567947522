import React from 'react';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import GridDetailSkeleton from './GridDetailSkeleton';
import { RouterLink } from 'routes/components';
import PropTypes from 'prop-types';

GridDetailText.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  link: PropTypes.string,
};
function GridDetailText({ title, value, color, link }) {
  const valueText = value !== undefined && value !== '' && value !== null ? value : 'N/A';

  const renderComponent =
    color !== undefined ? (
      <Typography variant="body1" sx={{ color }}>
        {valueText}
      </Typography>
    ) : (
      <Typography variant="body1">{valueText}</Typography>
    );

  return (
    <GridDetailSkeleton title={title}>
      {link !== undefined ? (
        <Link component={RouterLink} href={link}>
          {renderComponent}
        </Link>
      ) : (
        renderComponent
      )}
    </GridDetailSkeleton>
  );
}

export default GridDetailText;
