import React, { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import SearchField from '../../customs/SearchField';
import GeneralSelect from '../../customs/GeneralSelect';
import Scrollbar from 'components/minimal/scrollbar';
import { useLocales } from 'locales';
import { useDispatch, useSelector } from 'react-redux';
import MachinePlanningCard from './MachinePlanningCard';
import {
  getAllMachineWithProductionPlan,
  getMachinePlanById,
  getProductOrderDetailWithProcessById,
} from '../../../requests/ProductionServerHandler';
import ProductOrderDetailDialog from '../ProductOrderDetailDialog';
import Fuse from 'fuse.js';
import MachineDetailDialog from './MachineDetailDialog';
import { selectIsRefreshMachineList, toggleMachineList } from '../../../redux/reducers/planning';

function MachinePlanMachineView() {
  const options = {
    keys: [
      'Name',
      'Id',
      'ProductOrderPlannings.ProductOrder.Id',
      'ProductOrderPlannings.ProductOrder.Name',
      'ProductOrderPlannings.ProductOrder.Customer.Name',
    ],
  };

  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [sortValue, setSortValue] = useState(2);
  const isRefreshMachineList = useSelector(selectIsRefreshMachineList);
  const [allMachineDetail, setAllMachineDetail] = useState([]);
  const [filterMachineDetail, setFilterMachineDetail] = useState([]);

  const [showProductOrderDetail, setShowProductOrderDetail] = useState(false);
  const [productOrderDetail, setProductOrderDetail] = useState(null);
  const [showMachineDetail, setShowMachineDetail] = useState(false);
  const [machineDetail, setMachineDetail] = useState(null);

  const fuse = new Fuse(allMachineDetail, options);

  const sortTypes = [
    { value: 0, name: translate('title.status') },
    { value: 1, name: translate('title.capacity') },
    { value: 2, name: translate('title.queue_amount') },
  ];

  useEffect(() => {
    if (isRefreshMachineList) {
      const allProductionMachineDetail = async () =>
        await dispatch(getAllMachineWithProductionPlan());

      allProductionMachineDetail().then(async (response) => {
        setAllMachineDetail(response);
        setFilterMachineDetail(
          [...response].sort((a, b) => {
            return b.ProductOrderPlannings.length - a.ProductOrderPlannings.length;
          })
        );
        await dispatch(toggleMachineList(false));
      });
    }
  }, [dispatch, isRefreshMachineList]);

  const handleSearch = async (searchText) => {
    if (searchText !== null && searchText !== '') {
      setFilterMachineDetail(fuse.search(searchText).map((result) => result.item));
    } else {
      setFilterMachineDetail(allMachineDetail);
    }
  };
  const handleSort = (value) => {
    if (value === 0) {
      const sorted = [...filterMachineDetail].sort((a, b) => {
        return b.MachineStatusId - a.MachineStatusId;
      });
      setFilterMachineDetail(sorted);
    }
    if (value === 1) {
      const sorted = [...filterMachineDetail].sort((a, b) => {
        return b.ExpectedResult - a.ExpectedResult;
      });
      setFilterMachineDetail(sorted);
    }
    if (value === 2) {
      const sorted = [...filterMachineDetail].sort((a, b) => {
        return b.ProductOrderPlannings.length - a.ProductOrderPlannings.length;
      });
      setFilterMachineDetail(sorted);
    }
    setSortValue(value);
  };
  const handleOpenPODetailDialog = async (POId) => {
    const response = await dispatch(getProductOrderDetailWithProcessById(POId));
    if (response !== null) {
      setProductOrderDetail(response.Detail);
      setShowProductOrderDetail(true);
    }
  };
  const handleClosePODetailDialog = async () => {
    setShowProductOrderDetail(false);
  };

  const handleOpenMachineDialog = async (machineId) => {
    const response = await dispatch(getMachinePlanById(machineId));
    if (response !== null) {
      setMachineDetail(response);
      setShowMachineDetail(true);
    }
  };
  const handleCloseMachineDialog = async () => {
    setShowMachineDetail(false);
  };

  return (
    <div>
      <ProductOrderDetailDialog
        isOpen={showProductOrderDetail}
        handleDialogClose={handleClosePODetailDialog}
        productOrderDetail={productOrderDetail}
      />
      <MachineDetailDialog
        isOpen={showMachineDetail}
        handleDialogClose={handleCloseMachineDialog}
        machineDetail={machineDetail}
      />
      <Grid container sx={{ mt: 2 }} spacing={1}>
        <Grid item xs={12} lg={9}>
          <SearchField
            onSearchCallBack={handleSearch}
            placeHolderText={translate('placeholder.search_product_order_machine')}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <GeneralSelect value={sortValue} selectOptions={sortTypes} onOptionChange={handleSort} />
        </Grid>
        <Grid item xs={12}>
          <Scrollbar sx={{ mt: 2, maxHeight: 500 }}>
            <Stack spacing={2}>
              {filterMachineDetail.map((machine) => {
                return (
                  <MachinePlanningCard
                    key={machine.Id}
                    machineDetail={machine}
                    onOpenProductOrderDetail={handleOpenPODetailDialog}
                    onOpenMachineDetail={handleOpenMachineDialog}
                  />
                );
              })}
            </Stack>
          </Scrollbar>
        </Grid>
      </Grid>
    </div>
  );
}

export default MachinePlanMachineView;
