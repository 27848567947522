import React from 'react';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import { useLocales } from "locales";
import EmptyContent from "./EmptyContent";

GeneralTable.propTypes = {
    tableHeaders: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired,
    isMobile: PropTypes.bool
};

function GeneralTable({tableHeaders, tableData, isMobile}) {
    const { translate } = useLocales();

    return (
        <TableContainer>
            {
                tableData.length > 0 ?
                    <Table sx={{ minWidth: isMobile ? 0 : 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    tableHeaders.map((header,index) => <TableCell key={index} align={header.align}>{translate(header.title)}</TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                tableData.map((tableCell, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {
                                            tableCell.map((cell,index) => <TableCell key={index} component="th" scope="row">{cell}</TableCell>)
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                    :
                    <EmptyContent
                        title={translate('title.empty')}
                        description={translate('placeholder.empty_table')}
                        img="/assets/illustrations/illustration_empty_content.svg"
                    />
            }
        </TableContainer>
    );
}

export default GeneralTable;
