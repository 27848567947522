import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { getAllAddressDetail } from 'requests/ProductionServerHandler';
import { useQuery } from '@tanstack/react-query';

const AddressSelector = ({ onDistrictChange, name, error }) => {
  const { translate } = useLocales();

  const { data: addressData } = useQuery({
    queryKey: ['address-data'],
    queryFn: getAllAddressDetail,
    initialData: {
      Province: [],
      Amphur: [],
      District: [],
    },
  });

  const [amphurOptions, setAmphurOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  const [province, setProvince] = useState(null);
  const [amphur, setAmphur] = useState(null);
  const [district, setDistrict] = useState(null);

  const onProvinceChange = (event, value) => {
    if (value !== null) {
      setProvince(value);
      setAmphur(null);
      setDistrict(null);
      setAmphurOptions(addressData.Amphur.filter((amphur) => amphur.ProvinceId === value.Id));
    } else {
      setProvince(null);
      setAmphur(null);
      setDistrict(null);
      setAmphurOptions(addressData.Amphur);
    }
  };

  const onAmphurChange = (event, value) => {
    if (value !== null) {
      setAmphur(value);
      setDistrict(null);
      setDistrictOptions(addressData.District.filter((amphur) => amphur.AmphurId === value.Id));
    } else {
      setAmphur(null);
      setDistrict(null);
      setDistrictOptions(addressData.District);
    }
  };

  const handleDistrictChange = (event, value) => {
    if (value !== null) {
      setDistrict(value);
      onDistrictChange(name, value);
    } else {
      setDistrict(null);
      onDistrictChange(name, null);
    }
  };

  return (
    <>
      <Autocomplete
        options={addressData.Province}
        getOptionLabel={(option) => option.Name}
        disabled={false}
        onChange={onProvinceChange}
        value={province}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('title.province')}
            variant="outlined"
            error={error}
          />
        )}
      />
      <Autocomplete
        options={amphurOptions}
        getOptionLabel={(option) => option.Name}
        disabled={province === null}
        onChange={onAmphurChange}
        value={amphur}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('title.amphur')}
            variant="outlined"
            error={error}
          />
        )}
      />
      <Autocomplete
        options={districtOptions}
        getOptionLabel={(option) => option.Name}
        disabled={amphur === null}
        onChange={handleDistrictChange}
        value={district}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate('title.district')}
            variant="outlined"
            error={error}
          />
        )}
      />
    </>
  );
};

AddressSelector.propType = {
  onDistrictChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
};

export default AddressSelector;
