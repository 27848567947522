import React from 'react';
import { RouterLink } from 'routes/components';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { useLocales } from 'locales';
import { Button, IconButton } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';
import { getAllProductBOMs } from '../../../requests/WarehouseServerHandler';
import { useLoadingQuery } from '../../../requests/utility';

function BOMListPage() {
  const { translate } = useLocales();

  const { data: allBOM } = useLoadingQuery({
    queryKey: ['all-product-bom'],
    queryFn: () => getAllProductBOMs(),
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('product.bom_list')}
      breadcrumbs={[{ name: translate('product.title') }, { name: translate('product.bom_list') }]}
      action={
        <RoleBasedGuard roles={[ManagementUserTypeId]}>
          <Button
            variant="contained"
            color={'primary'}
            component={RouterLink}
            href={paths.product.create_bom}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {translate('product.create_bom')}
          </Button>
        </RoleBasedGuard>
      }
    >  
      <ProjectPaper>
        <ReactTable
          search
          data={allBOM.map((BOM) => {
            return {
              name: BOM.Name,
              type_name: BOM.BOMType.Name,
              actions: BOM.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.product_type'),
              accessorKey: 'type_name',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  component={RouterLink}
                  href={paths.product.bom_detail(info.getValue())}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default BOMListPage;
