import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, IconButton, Stack } from '@mui/material';
import { DatePickerInterval } from 'components/date-picker';
import ReactTable from 'components/react-table/ReactTable';
import { sortById } from 'utilities/Helper/DataHelper';
import { fCurrency, fNumber } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import Label from 'components/minimal/label';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import { useTheme } from '@mui/material/styles';
import OverallDetailGrid from '../OverallDetailGrid';
import { useCustomerProductOrders } from 'requests/query/useCustomerDetail';
import { paths } from 'routes/paths';
import { OPEN_ICON } from 'config-global';

CustomerProductOrderDetail.propTypes = {
  customerId: PropTypes.string.isRequired,
};

function CustomerProductOrderDetail({ customerId }) {
  const firstDayOfYear = new Date();
  firstDayOfYear.setDate(1);
  firstDayOfYear.setMonth(1);
  firstDayOfYear.setHours(0, 0, 0, 0);
  const { translate } = useLocales();
  const [date, setDate] = useState({ fromDate: firstDayOfYear, toDate: new Date() });
  const { customerProductOrders, refetch } = useCustomerProductOrders(customerId, date);
  const theme = useTheme();
  const filterPOByDate = async (fromDate, toDate) => {
    setDate({ fromDate, toDate });
    await refetch();
  };
  const getTotalSalesValue = () => {
    return customerProductOrders
      .filter((po) => po.CanceledAt === null)
      .reduce((totalValue, productOrder) => {
        return (
          totalValue +
          productOrder.ProductSellHistories.reduce((totalVal, priceDetail) => {
            if (priceDetail.IsBundle) {
              totalVal += priceDetail.BundlePrice;
            } else {
              totalVal += priceDetail.SellPrice * productOrder.Amount;
            }
            return totalVal;
          }, 0)
        );
      }, 0);
  };
  const productOrderValue = (po) => {
    return po.ProductSellHistories.reduce((totalVal, priceDetail) => {
      if (priceDetail.IsBundle) {
        totalVal += priceDetail.BundlePrice;
      } else {
        totalVal += priceDetail.SellPrice * po.Amount;
      }
      return totalVal;
    }, 0);
  };
  return (
    <Stack spacing={2}>
      <DatePickerInterval onFetchData={filterPOByDate} defaultDateRange={date} />
      <OverallDetailGrid
        data={[
          {
            primary_text: fCurrency(getTotalSalesValue()),
            secondary_text: translate('sales.year_sales'),
            primary_text_color: 'primary',
          },
          {
            primary_text: fNumber(customerProductOrders.length),
            secondary_text: translate('title.all_po_cancel_amount'),
            primary_text_color: '#bf572b',
          },
          {
            primary_text: fNumber(
              customerProductOrders.filter((po) => po.CompletedAt !== null).length
            ),
            secondary_text: translate('title.all_po_complete_amount'),
            primary_text_color: 'primary',
          },
          {
            primary_text: fNumber(
              customerProductOrders.filter((po) => po.CanceledAt !== null).length
            ),
            secondary_text: translate('title.all_po_cancel_amount'),
            primary_text_color: 'error',
          },
        ]}
      />
      <Card>
        <CardHeader title={translate('title.all_po')} subheader={''} />
        <CardContent>
          <ReactTable
            search
            data={customerProductOrders.sort(sortById).map((productOrder) => {
              return {
                id: productOrder.Id,
                name: productOrder.Name,
                amount: productOrder.Amount,
                createAt: productOrder.createdAt,
                complete_amount: productOrder.TotalGoodAmount,
                value: productOrderValue(productOrder),
                status: productOrder.ProductOrderStatus,
                actions: productOrder.Id,
              };
            })}
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
                size: 20,
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
                cell: (info) => fNumber(info.getValue()),
              },
              {
                header: translate('title.complete_amount'),
                accessorKey: 'complete_amount',
                cell: (info) => fNumber(info.getValue()),
              },
              {
                header: translate('title.value'),
                accessorKey: 'value',
                cell: (info) => fCurrency(info.getValue()),
              },
              {
                header: translate('title.create_at'),
                accessorKey: 'createAt',
                cell: (info) => fDateTimeText(info.getValue()),
              },
              {
                header: translate('title.status'),
                accessorKey: 'status',
                cell: (info) => {
                  let color;
                  if (info.getValue().Id === 1) {
                    color = 'info';
                  } else if (info.getValue().Id === 2) {
                    color = 'warning';
                  } else if (info.getValue().Id === 3) {
                    color = 'success';
                  } else {
                    color = 'error';
                  }

                  return (
                    <Label
                      variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                      color={color}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {info.getValue().Name}
                    </Label>
                  );
                },
                size: 50,
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <IconButton
                      component={RouterLink}
                      to={paths.production.product_order_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  </>
                ),
                size: 20,
              },
            ]}
          />
        </CardContent>
      </Card>
    </Stack>
  );
}

export default CustomerProductOrderDetail;
