import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import PileScanner from '../../barcode-reader/PileScanner';
import { useEditPileData } from '../../../requests/query/useEditPileData';

export default function ChangePileGoodAmountPage() {
  const { translate } = useLocales();
  const [editPile, setEditPile] = useState(null);
  const { editPile: handleEditPile } = useEditPileData();
  const handleSearchPile = (pile) => {
    setEditPile(pile);
  };

  const searchPileSchema = Yup.object().shape({
    GoodAmount: Yup.number()
      .min(0, translate('error.detail_require'))
      .required(translate('error.detail_require')),
    DefectAmount: Yup.number()
      .min(0, translate('error.detail_require'))
      .required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(searchPileSchema),
  });

  const { handleSubmit } = methods;

  const submitChangeSize = async (values) => {
    const totalOld = editPile.GoodAmount + editPile.DefectAmount + editPile.AdjustAmount;
    const totalNew = parseInt(values.GoodAmount) + parseInt(values.DefectAmount);

    await handleEditPile.mutateAsync({
      PileId: editPile.Id,
      GoodAmount: values.GoodAmount,
      DefectAmount: values.DefectAmount,
      AdjustAmount: totalNew - totalOld,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_pile_good_amount_page_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.edit_pile_good_amount_page_title') },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          {editPile !== null ? (
            <Stack spacing={3}>
              <ProjectPaper>
                <Grid container spacing={3}>
                  <GridDetailText
                    title={translate('title.name')}
                    value={editPile.ProductOrder.Name}
                  />
                  <GridDetailText title={translate('title.amount_in')} value={editPile.Amount} />
                  <GridDetailText
                    title={translate('title.amount_good')}
                    value={editPile.GoodAmount}
                  />
                  <GridDetailText
                    title={translate('title.amount_defect')}
                    value={editPile.DefectAmount}
                  />
                  <GridDetailText
                    title={translate('title.amount_adjust')}
                    value={editPile.AdjustAmount}
                  />
                </Grid>
              </ProjectPaper>
              <ProjectPaper>
                <FormProvider methods={methods} onSubmit={handleSubmit(submitChangeSize)}>
                  <Stack spacing={3}>
                    <RHFTextField name="GoodAmount" label={translate('title.amount_good')} />
                    <RHFTextField name="DefectAmount" label={translate('title.amount_defect')} />
                    <Button variant={'contained'} type={'submit'} color={'primary'}>
                      {translate('button.submit')}
                    </Button>
                  </Stack>
                </FormProvider>
              </ProjectPaper>
            </Stack>
          ) : (
            <PileScanner onSearchCallBack={handleSearchPile} isPreFetchData />
          )}
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
}
