import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {getReworkDetailById} from "../../../requests/ProductionServerHandler";
import {useParams} from "react-router-dom";
import {paths} from "../../../routes/paths";
import {ProjectPageContainer} from "../../container/ProjectPageContainer";
import {useLocales} from "../../../locales";
import Stack from "@mui/material/Stack";
import {Card, CardContent, CardHeader, Grid, IconButton} from "@mui/material";
import GridDetailText from "../../customs/GridDetailText";
import {getEmployeeFullName} from "../../../utilities/Helper/UtilitiesHelper";
import GeneralTable from "../../customs/GeneralTable";
import {RouterLink} from "../../../routes/components";
import Iconify from "../../minimal/iconify";
import {OPEN_ICON} from "../../../config-global";

ReworkDetailPage.propTypes = {};

function ReworkDetailPage(props) {
    const {id} = useParams();
    const { translate } = useLocales();
    const {data} = useQuery({
        queryKey: ['rework-detail', id],
        queryFn: () => getReworkDetailById(id),
        initialData: null,
    });
    return (
        <ProjectPageContainer
            menu_title={translate('production.rework_order_detail')}
            breadcrumbs={[
                {name: translate('production.title')},
                {
                    name: translate('production.product_order_list'),
                    href: paths.production.product_order_list,
                },
                {
                    name: `${translate('production.rework_order_detail')} ${translate('title.id')} : ${id}`,
                },
            ]}>
            {
                data !== null && <Stack spacing={2}>
                    <Card>
                        <CardHeader title={translate('title.detail')} />
                        <CardContent>
                            <Grid container spacing={3}>
                                <GridDetailText title={translate('title.id')} value={id} />
                                <GridDetailText title={translate('title.create_by')} value={getEmployeeFullName(data.ApproveCreateEmployee)} />
                                <GridDetailText title={translate('title.detail')} value={data.Details} />
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader title={translate('title.pile')} />
                        <CardContent>
                            <GeneralTable
                                tableHeaders={[
                                    { align: 'left', title: 'title.id' },
                                    { align: 'left', title: 'Action' },
                                ]}
                                tableData={data.ReworkOrderPiles.map((record) => [
                                    record.PileId,
                                    <IconButton
                                        component={RouterLink}
                                        href={paths.production.pile_detail(record.PileId)}
                                    >
                                        <Iconify icon={OPEN_ICON} />
                                    </IconButton>
                                ])}
                            />
                        </CardContent>
                    </Card>
                    <Card>
                        <CardHeader title={translate('title.process')} />
                        <CardContent>
                            <GeneralTable
                                tableHeaders={[
                                    { align: 'left', title: 'title.name' },
                                ]}
                                tableData={data.ReworkOrderHasProductionProcesses.map((record) => [
                                    record.ProductionProcess.Name,
                                ])}
                            />
                        </CardContent>
                    </Card>
                </Stack>
            }
        </ProjectPageContainer>
    );
}

export default ReworkDetailPage;