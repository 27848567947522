import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  approveProductOrderQuotationById,
  createProductOrderFromQuotation,
  deleteProductOrderQuotationById,
  getProductOrderQuotationDetailById, getProductOrderQuotationPDFFile,
} from '../ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from 'utilities/Helper/NotificationHelper';
import { useLoading } from '../../components/loading';
import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { poq_prefix } from '../../utilities/Strings/Prefix';
import { useLocales } from '../../locales';

export const useProductOrderQuotation = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { translate } = useLocales();

  const { data } = useQuery({
    queryKey: ['product-order-quotation-detail', id],
    queryFn: () => getProductOrderQuotationDetailById(id),
    initialData: null,
  });

  const printProductOrderQuotationMutation = useMutation({
    mutationFn: getProductOrderQuotationPDFFile,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(response.PDF, `${poq_prefix}_P${id}.pdf`);
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const createProductOrderMutation = useMutation({
    mutationFn: createProductOrderFromQuotation,
    onSuccess: async (createId) => {
      await queryClient.invalidateQueries(['product-order-quotation-detail', id]);
      await OpenSuccessNotification(`${translate('title.id')} : ${createId}`);
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const approveProductOrderQuotationMutation = useMutation({
    mutationFn: approveProductOrderQuotationById,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-order-quotation-detail', id]);
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const deleteProductOrderQuotationMutation = useMutation({
    mutationFn: deleteProductOrderQuotationById,
    onSuccess: async () => {
      await OpenSuccessNotification();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  return {
    productOrderQuotationDetail: data,
    approveProductOrderQuotation: approveProductOrderQuotationMutation,
    deleteProductOrderQuotation: deleteProductOrderQuotationMutation,
    printProductOrderQuotation: printProductOrderQuotationMutation,
    createProductOrder: createProductOrderMutation,
  };
};
