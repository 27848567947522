import React from 'react';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

CompleteItJobForm.propTypes = {
  onSubmitValue: PropTypes.object.isRequired,
};

function CompleteItJobForm({ onSubmitValue }) {
  const { translate } = useLocales();
  const onCompleteJob = async (value) => {
    onSubmitValue(value);
  };

  const completeJobSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(completeJobSchema),
  });

  const { handleSubmit } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onCompleteJob)}>
      <Stack spacing={3}>
        <RHFTextField name={'Detail'} label={translate('title.detail')} />
        <Button variant={'contained'} type={'submit'} color={'primary'}>
          {translate('button.save')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CompleteItJobForm;
