import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Stack } from '@mui/material';
import Iconify from '../../minimal/iconify';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import thLocale from 'date-fns/locale/th';
import SearchField from '../../customs/SearchField';
import { fNumber } from '../../../utilities/formatNumber';
import { useDispatch } from 'react-redux';
import { useLocales } from '../../../locales';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useTabs from '../../../hooks/useTabs';
import OverallDetailGrid from '../OverallDetailGrid';
import Scrollbar from '../../minimal/scrollbar';
import OverallProductOrderDetailCard from './OverallProductOrderDetailCard';
import GeneralSelect from '../../customs/GeneralSelect';
import Fuse from 'fuse.js';
import { setPlanningStatus } from '../../../redux/reducers/planning';
import { usePlanningData } from '../../../requests/query/usePlanningData';

OverallPlanningDetails.propTypes = {
  productOrders: PropTypes.array.isRequired,
};

function OverallPlanningDetails({ productOrders }) {
  const { translate } = useLocales();

  const sortPOOptions = [
    { value: 0, name: translate('planning.in_date_desc') },
    { value: 1, name: translate('planning.in_date_asc') },
    { value: 2, name: translate('planning.amount_desc') },
    { value: 3, name: translate('planning.amount_asc') },
  ];

  const [sortProductOrderValue, setSortProductOrderValue] = useState(0);
  const [filterProductOrders, setFilterProductOrders] = useState([]);

  const handleSortProductOrder = (value) => {
    if (value === 0) {
      const sorted = [...productOrders].sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setFilterProductOrders(sorted);
    }
    if (value === 1) {
      const sorted = [...productOrders].sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setFilterProductOrders(sorted);
    }
    if (value === 2) {
      const sorted = [...productOrders].sort((a, b) => {
        return parseFloat(b.Amount) - parseFloat(a.Amount);
      });
      setFilterProductOrders(sorted);
    }
    if (value === 3) {
      const sorted = [...productOrders].sort((a, b) => {
        return parseFloat(a.Amount) - parseFloat(b.Amount);
      });
      setFilterProductOrders(sorted);
    }
    setSortProductOrderValue(value);
  };

  useEffect(() => {
    setFilterProductOrders(productOrders);
  }, [productOrders]);

  return (
    <>
      <GeneralSelect
        value={sortProductOrderValue}
        selectOptions={sortPOOptions}
        onOptionChange={handleSortProductOrder}
      />
      <Scrollbar sx={{ mt: 2, maxHeight: 500 }}>
        <Stack spacing={2}>
          {filterProductOrders.map((po) => {
            return <OverallProductOrderDetailCard key={po.Id} productOrderDetail={po} />;
          })}
        </Stack>
      </Scrollbar>
    </>
  );
}

function OverallPlanning() {
  const options = {
    keys: ['Customer.Name', 'Name', 'Id'],
  };

  const currentDate = new Date();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs('not_plan');

  const [isSearch, setIsSearch] = useState(false);
  const [searchPO, setSearchPO] = useState([]);
  const { unPlanPO, completePO, planPO } = usePlanningData();
  const fuse = new Fuse([...unPlanPO, ...planPO, ...completePO], options);

  const INFORMATION_TABS = [
    {
      value: 'not_plan',
      title: `${translate('planning.not_planning')} (${unPlanPO.length})`,
      component: <OverallPlanningDetails key={'not_plan'} productOrders={unPlanPO} />,
    },
    {
      value: 'already_plan',
      title: `${translate('planning.already_planning')} (${planPO.length})`,
      component: <OverallPlanningDetails key={'already_plan'} productOrders={planPO} />,
    },
    {
      value: 'complete',
      title: `${translate('planning.all_finish_work')} (${completePO.length})`,
      component: <OverallPlanningDetails key={'complete'} productOrders={completePO} />,
    },
  ];

  useEffect(() => {
    const fetchMonthPO = async () =>
      await dispatch(
        setPlanningStatus({
          allPO: unPlanPO.length + planPO.length + completePO.length,
          notComplete: unPlanPO.length + planPO.length,
          complete: completePO.length,
        })
      );

    fetchMonthPO().then((r) => {});
  }, [completePO.length, dispatch, planPO.length, unPlanPO.length]);

  const handleSearch = (searchText) => {
    if (searchText !== null && searchText !== '') {
      setSearchPO(fuse.search(searchText).map((result) => result.item));
      setIsSearch(true);
    } else {
      setSearchPO([]);
      setIsSearch(false);
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Stack spacing={4}>
            <Stack spacing={2} direction="row">
              <Iconify icon={'ant-design:calendar-outlined'} width={30} height={30} />
              <Typography variant={'h5'}>
                {format(currentDate, 'MMMM Y', { locale: thLocale })}
              </Typography>
            </Stack>
            <SearchField
              onSearchCallBack={handleSearch}
              placeHolderText={translate('placeholder.search_product_order')}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}>
          <OverallDetailGrid
            data={[
              {
                primary_text: fNumber(unPlanPO.length + planPO.length + completePO.length),
                secondary_text: translate('planning.all_work'),
                primary_text_color: '#438dcb',
              },
              {
                primary_text: fNumber(unPlanPO.length + planPO.length),
                secondary_text: translate('planning.all_un_finish_work'),
                primary_text_color: '#bf572b',
              },
              {
                primary_text: fNumber(completePO.length),
                secondary_text: translate('planning.all_finish_work'),
                primary_text_color: '#2eb8a4',
              },
            ]}
          />
        </Grid>
      </Grid>
      {isSearch ? (
        <>
          <Typography sx={{ mt: 2, mb: 2 }} variant="h4" color={'text.secondary'}>
            {`${translate('placeholder.search_result')} ${searchPO.length} ${translate(
              'placeholder.result'
            )}...`}
          </Typography>
          <OverallPlanningDetails productOrders={searchPO} />
        </>
      ) : (
        <>
          <Tabs
            sx={{ mt: 2 }}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {INFORMATION_TABS.map((tab, index) => (
              <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
          <Box sx={{ mb: 4 }} />
          {INFORMATION_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && tab.component;
          })}
        </>
      )}
    </div>
  );
}

export default OverallPlanning;
