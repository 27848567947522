import CreateProductionProcessPage from '../../components/pages/ProductionModule/CreateProductionProcessPage';
import ProductOrderListPage from '../../components/pages/ProductionModule/ProductOrderListPage';
import ProductOrderDetailPage from '../../components/pages/ProductionModule/ProductOrderDetailPage';
import PileDetailPage from '../../components/pages/ProductionModule/PileDetailPage';
import SubmitProductionRecordPage from '../../components/pages/ProductionModule/SubmitProductionRecordPage';
import ProductionDashboardPage from '../../components/pages/ProductionModule/ProductionDashboardPage';
import MachineDashboardDetailPage from '../../components/pages/ProductionModule/MachineDashboardDetailPage';
import SearchProductionDetailPage from '../../components/pages/ProductionModule/SearchProductionDetailPage';
import PrintMachineProductionReportPage from '../../components/pages/ProductionModule/PrintMachineProductionReportPage';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
  HumanResourceUserTypeId,
  LogisticUserTypeId, ManagementUserTypeId,
  ProcurementUserTypeId,
  ProductionControlUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
} from '../../utilities/Constants';
import ProductionEmployeeDashboardPage from '../../components/pages/ProductionModule/ProductionEmployeeDashboardPage';
import CreatePilePage from '../../components/pages/ProductionModule/CreatePilePage';
import SearchProductOrderResultPage from '../../components/pages/ProductionModule/SearchProductOrderResultPage';
import ProductionEmployeePerformancePage from '../../components/pages/ProductionModule/ProductionEmployeePerformancePage';
import CreateProductOrderProblemDetailPage from '../../components/pages/ProductionModule/CreateProductOrderProblemDetailPage';
import SolveProductionProblemPage from '../../components/pages/ProductionModule/SolveProductionProblemPage';
import { paths } from '../paths';
import ReworkDetailPage from "../../components/pages/ProductionModule/ReworkDetailPage";
import CreatePileFromLogisticPilePage from '../../components/pages/ProductionModule/CreatePileFromLogisticPilePage';
import ApproveChangePricePage from '../../components/pages/ProductionModule/ApproveChangePricePage';

const productionModuleRouteDetails = [
  {
    path: paths.production.create_pile_from_logistic_pile,
    component: <CreatePileFromLogisticPilePage />,
    isPrivate: true,
    allowUserTypeId: [
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.create_pile,
    component: <CreatePilePage />,
    isPrivate: true,
    allowUserTypeId: [
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.create_production_process,
    component: <CreateProductionProcessPage />,
    isPrivate: true,
    allowUserTypeId: [
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.product_order_list,
    component: <ProductOrderListPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.product_order_detail(':id'),
    component: <ProductOrderDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
      FinanceUserTypeId,
    ],
  },
  {
    path: paths.production.rework_order_detail(':id'),
    component: <ReworkDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
      FinanceUserTypeId,
    ],
  },
  {
    path: paths.production.pile_detail(':id'),
    component: <PileDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.production.submit_production_record,
    component: <SubmitProductionRecordPage />,
    isPrivate: false,
  },
  {
    path: paths.production.dashboard,
    component: <ProductionDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.machine_dashboard_detail(':id'),
    component: <MachineDashboardDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.search,
    component: <SearchProductionDetailPage />,
    isPrivate: true,
  },
  {
    path: paths.production.machine_production_report,
    component: <PrintMachineProductionReportPage />,
    isPrivate: true,
    allowUserTypeId: [
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      ProcurementUserTypeId,
      SecretaryUserTypeId,
      LogisticUserTypeId,
    ],
  },
  {
    path: paths.production.employee_dashboard,
    component: <ProductionEmployeeDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [
      AccountingUserTypeId,
      ProductionControlUserTypeId,
      SalesUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      SecretaryUserTypeId,
    ],
  },
  {
    path: paths.production.employee_performance,
    component: <ProductionEmployeePerformancePage />,
    isPrivate: true,
    allowUserTypeId: [
      ProductionControlUserTypeId,
      HumanResourceUserTypeId,
      AdminUserTypeId,
      SecretaryUserTypeId,
    ],
  },
  {
    path: paths.production.search_product_order_result,
    component: <SearchProductOrderResultPage />,
    isPrivate: true,
  },
  {
    path: paths.production.create_product_order_problem,
    component: <CreateProductOrderProblemDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      ProductionControlUserTypeId,
      SalesUserTypeId,
      AdminUserTypeId,
      QualityAssuranceUserTypeId,
    ],
  },
  {
    path: paths.production.solve_production_problem,
    component: <SolveProductionProblemPage />,
    isPrivate: true,
    allowUserTypeId: [
      ProductionControlUserTypeId,
      SalesUserTypeId,
      AdminUserTypeId,
      QualityAssuranceUserTypeId,
    ],
  },
  {
    path: paths.production.approve_change_price_detail(':id'),
    component: <ApproveChangePricePage />,
    isPrivate: true,
    allowUserTypeId: [
      ManagementUserTypeId
    ],
  },
];
export default productionModuleRouteDetails;
