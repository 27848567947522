import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from '../minimal/hook-form';
import { IconButton } from '@mui/material';
import GeneralTable from '../customs/GeneralTable';
import Iconify from '../minimal/iconify';

CreatePileFromLogisticPileForm.propTypes = {
  logisticPiles: PropTypes.array.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

function CreatePileFromLogisticPileForm(props) {
  const {logisticPiles, onFormSubmit} = props
  const { translate } = useLocales();

  const pileSchema = Yup.object().shape({
    Piles: Yup.array()
      .of(
        Yup.object().shape({
          Amount: Yup.number()
            .min(1, translate('error.invalid_input'))
            .required(translate('error.invalid_input')),
          Height: Yup.number()
            .min(1, translate('error.invalid_input'))
            .required(translate('error.invalid_input')),
        })
      )
      .min(1, 'error.invalid_input'),
  });

  const methods = useForm({
    resolver: yupResolver(pileSchema),
    defaultValues: {
      Piles: logisticPiles.map((logisticPile) => {
        return {
          Id: logisticPile.id,
          ReferenceId: logisticPile.reference_id,
          Amount: logisticPile.amount,
          Height: logisticPile.height,
        }
      })
    },
  });

  const { handleSubmit, control,reset } = methods;
  const { fields } = useFieldArray({
    control,
    name: 'Piles',
  });
  const onSubmit = (values) => {
    console.log('Submitting entire form data:', values);
  };

  useEffect(() => {
    reset({
      Piles: logisticPiles.map((logisticPile) => ({
        Id: logisticPile.id,
        ReferenceId: logisticPile.reference_id,
        Amount: logisticPile.amount,
        Height: logisticPile.height,
      })),
    });
  }, [logisticPiles, reset]);

  const onSubmitRow = (index) =>{
    handleSubmit((values) => {
      const rowData = values.Piles[index];
      onFormSubmit(rowData)
    })();
  }


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <GeneralTable
        tableHeaders={[
          { align: 'left', title: '#' },
          { align: 'left', title: 'title.external_reference_id' },
          { align: 'left', title: 'title.amount' },
          { align: 'left', title: 'title.height' },
          { align: 'left', title: 'Actions' },
        ]}
        tableData={fields.map((item, index) => {
          return [
            index + 1,
            <RHFTextField name={`Piles[${index}].ReferenceId`} type={'text'} />,
            <RHFTextField name={`Piles[${index}].Amount`} type={'text'} />,
            <RHFTextField name={`Piles[${index}].Height`} type={'text'} />,
            <IconButton color={'success'} onClick={() => onSubmitRow(index)}>
              <Iconify icon={'eva:plus-fill'} width={20} height={20} />
            </IconButton>,
          ];
        })}
      />
    </FormProvider>
  );
}

export default CreatePileFromLogisticPileForm;