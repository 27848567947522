import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { saveEmployeePenaltyRecord } from '../../../requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { useAllPenaltyOptions } from '../../../requests/query/DropdownData';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../../minimal/hook-form';
import { useMutation } from '@tanstack/react-query';

function CreatePenaltyRecordPage() {
  const { allPenaltyLevelOptions } = useAllPenaltyOptions();
  const saveEmployeePenaltyRecordMutation = useMutation({
    mutationFn: saveEmployeePenaltyRecord,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const { translate } = useLocales();

  const savePenaltyRecordSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    PenaltyLevel: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
    EmployeeCode: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(savePenaltyRecordSchema),
  });

  const { handleSubmit } = methods;

  const createRecord = async (value) => {
    await saveEmployeePenaltyRecordMutation.mutateAsync({
      Detail: value.Detail,
      EmployeeCode: value.EmployeeCode,
      PenaltyLevelId: value.PenaltyLevel.Id,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('hr.add_penalty_record')}
      breadcrumbs={[{ name: translate('hr.title') }, { name: translate('hr.add_penalty_record') }]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(createRecord)}>
          <Stack spacing={3}>
            <RHFTextField name={'Detail'} label={translate('title.detail')} />
            <RHFAutoCompleteShort
              name={'PenaltyLevel'}
              label={translate('hr.penalty_level_title')}
              data={allPenaltyLevelOptions}
            />
            <RHFTextField name={'EmployeeCode'} label={translate('user.employee_code')} />
            <Button variant={'contained'} fullWidth type={'submit'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreatePenaltyRecordPage;
