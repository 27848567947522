import React from 'react';
import { RHFAutocomplete } from '../hook-form';
import PropTypes from 'prop-types';

RHFAutoCompleteShort.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
};

export default function RHFAutoCompleteShort({
  name,
  label,
  placeholder,
  helperText,
  data,
  disabled,
}) {
  return (
    <RHFAutocomplete
      name={name}
      label={label}
      options={[{ Id: 0, Name: '' }, ...data]}
      renderOption={(props, option) => {
        if (option.Id > 0) {
          return (
            <li {...props} key={option.Id}>
              {option.Name}
            </li>
          );
        }
        return null;
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.Name;
      }}
      isOptionEqualToValue={(option, value) => option.Id === value.Id}
      placeholder={placeholder}
      helperText={helperText}
      disabled={disabled}
    />
  );
}
