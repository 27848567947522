import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { IconButton } from '@mui/material';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { ManagementUserTypeId, ProcurementUserTypeId } from '../../../utilities/Constants';
import { useNavigate } from 'react-router-dom';
import {
  cancelPurchaseRequisition,
  getAllMaterialPurchaseRequisition,
} from '../../../requests/WarehouseServerHandler';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import Typography from '@mui/material/Typography';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import Iconify from 'components/minimal/iconify';
import { OPEN_ICON } from '../../../config-global';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

function PurchaseRequisitionListPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { data: allActivePR, refetch } = useQuery({
    queryKey: ['getAllMaterialPurchaseRequisition'],
    queryFn: getAllMaterialPurchaseRequisition,
    initialData: [],
  });

  const cancelPRMutation = useMutation({
    mutationFn: cancelPurchaseRequisition,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await refetch();
    },
  });

  const getPartPriorityText = (isUrgent) => {
    if (isUrgent) {
      return (
        <Typography variant={'body2'} color={'error'}>
          {translate('procurement.urgent')}
        </Typography>
      );
    } else {
      return <Typography variant={'body2'}>{translate('procurement.not_urgent')}</Typography>;
    }
  };

  const handleCancelPurchaseRequisition = async (prId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancelPRMutation.mutateAsync(prId);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={`${translate('procurement.purchase_requisition_list_title')} ${translate(
        'warehouse.material_store'
      )}`}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: `${translate('procurement.purchase_requisition_list_title')} ${translate(
            'warehouse.material_store'
          )}`,
        },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          defaultPageSize={25}
          data={allActivePR.map((pr) => {
            return {
              pr_id: pr.Id,
              create_by: pr.CreateEmployee,
              create_department: pr.CreateEmployee.Department.Name,
              create_at: pr.createdAt,
              priority: pr.PriorityLevel,
              actions: pr.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'pr_id',
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'create_by',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('user.department'),
              accessorKey: 'create_department',
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('title.priority_level_title'),
              accessorKey: 'priority',
              cell: (info) => getPartPriorityText(info.getValue()),
            },
            {
              header: '',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    onClick={() => navigate(paths.procurement.pr_detail(info.getValue()))}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                  <RoleBasedGuard roles={[ManagementUserTypeId, ProcurementUserTypeId]}>
                    <IconButton
                      color={'error'}
                      onClick={() => handleCancelPurchaseRequisition(info.getValue())}
                    >
                      <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                    </IconButton>
                  </RoleBasedGuard>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default PurchaseRequisitionListPage;
