import React from "react";
import { Button, CardContent, CardHeader, Grid, IconButton, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import { useLocales } from "../../../locales";
import { ProjectPageContainer } from "../../container/ProjectPageContainer";
import { paths } from "../../../routes/paths";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  approveChangePrice, declineChangePrice,
  getProductOrderPriceHistoryDetailById
} from "../../../requests/ProductionServerHandler";
import { useNavigate, useParams } from "react-router-dom";
import GridDetailText from "../../customs/GridDetailText";
import {
  fCurrency,
  fFourDecimal,
  fNumber,
  fShortenNumber
} from "../../../utilities/formatNumber";
import { useTheme } from "@mui/material/styles";
import { ManagementUserTypeId } from "../../../utilities/Constants";
import ProjectPaper from "../../customs/ProjectPaper";
import Iconify from "../../minimal/iconify";
import RoleBasedGuard from "../../../auth/guard/RoleBaseGuard";
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification
} from "../../../utilities/Helper/NotificationHelper";

function ApproveChangePricePage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: ProductOrderPriceHistory } = useQuery({
    queryKey: ["product-order-price-history-detail", id],
    queryFn: () => getProductOrderPriceHistoryDetailById(id),
    initialData: null
  });

  const approveMutation = useMutation({
    mutationFn: approveChangePrice,
    onSuccess: async () => {
      await OpenSuccessNotification("Approve Success");
      navigate(paths.production.product_order_detail(ProductOrderPriceHistory.ProductOrder.Id));
    }
  });

  const cancelMutation = useMutation({
    mutationFn: declineChangePrice,
    onSuccess: async () => {
      await OpenSuccessNotification("Decline Success");
      navigate(paths.production.product_order_detail(ProductOrderPriceHistory.ProductOrder.Id));
    }
  });

  const calculatePercentage = () => {
    const oldPrice = ProductOrderPriceHistory.AprrovedAt !== null ?
      ProductOrderPriceHistory.OldIsBundle ?
        ProductOrderPriceHistory.OldBundlePrice :
        ProductOrderPriceHistory.OldPricePerSheet * ProductOrderPriceHistory.ProductOrder.Amount
      :
      ProductOrderPriceHistory.ProductSellHistory.IsBundle ?
        ProductOrderPriceHistory.ProductSellHistory.BundlePrice :
        ProductOrderPriceHistory.ProductSellHistory.SellPrice * ProductOrderPriceHistory.ProductOrder.Amount
    const newPrice = ProductOrderPriceHistory.IsBundle ?
      ProductOrderPriceHistory.BundlePrice
      :
      ProductOrderPriceHistory.PricePerSheet * ProductOrderPriceHistory.ProductOrder.Amount;
    if (oldPrice === 0) {
      return 0;
    }
    const percent = ((newPrice - oldPrice) / oldPrice) * 100;
    return <GridDetailText title={translate("Percentage")}
                           color={percent > 0 ? theme.palette.success.main : theme.palette.error.main}
                           value={percent > 0 ? `+${fShortenNumber(percent)} %` : `${fShortenNumber(percent)} %`} />;
  };

  const handleApprovePriceChange = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      "warning.confirm_approve_price",
      async () => {
        await approveMutation.mutate({
          ProductPriceHistoryId: id
        });
      }
    );
  };
  const handleDeclinePriceChange = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      "warning.confirm_cancel_data",
      async () => {
        await cancelMutation.mutate({
          ProductPriceHistoryId: id
        });
      }
    );
  };

  const ChangePriceDetail = () => {
    return <Card>
      <CardHeader title={`${translate("procurement.price_detail")} ที่ต้องการเปลี่ยน`} />
      <CardContent>
        <Grid container spacing={3}>
          <GridDetailText title={translate("title.price_per_sheet")}
                          value={ProductOrderPriceHistory.IsBundle ? `เหมา ${ProductOrderPriceHistory.BundlePrice}` : ProductOrderPriceHistory.PricePerSheet} />
          <GridDetailText title={translate("title.price_per_sheet")}
                          value={ProductOrderPriceHistory.IsBundle ?
                            fFourDecimal((ProductOrderPriceHistory.BundlePrice / ProductOrderPriceHistory.ProductOrder.Amount) / (ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length)) :
                            fFourDecimal(ProductOrderPriceHistory.PricePerSheet / (ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length))} />
          <GridDetailText title={translate("procurement.total_price_title")}
                          value={ProductOrderPriceHistory.IsBundle ? `เหมา ${ProductOrderPriceHistory.BundlePrice}` : fCurrency(ProductOrderPriceHistory.PricePerSheet * ProductOrderPriceHistory.ProductOrder.Amount)} />
          {calculatePercentage()}
        </Grid>
      </CardContent>
    </Card>
  }
  return (
    <ProjectPageContainer
      menu_title={translate("production.product_order_detail")}
      breadcrumbs={[
        { name: translate("production.title") },
        {
          name: translate("production.product_order_list"),
          href: paths.production.product_order_list
        },
        {
          name: `${translate("production.product_order_detail")} ${translate("title.id")} : ${ProductOrderPriceHistory !== null ? ProductOrderPriceHistory.ProductOrder.Id : "N/A"}`
        }
      ]}
      action={<IconButton color={"error"} onClick={() => navigate(paths.production.product_order_detail(ProductOrderPriceHistory.ProductOrder.Id))}>
        <Iconify icon={"ic:baseline-close"} width={20} height={20} />
      </IconButton>}
    >
      {
        ProductOrderPriceHistory !== null ? <Stack spacing={2}>
            <Card>
              <CardHeader title={translate("title.detail")} />
              <CardContent>
                <Grid container spacing={3}>
                  <GridDetailText title={translate("title.name")}
                                  value={ProductOrderPriceHistory.ProductOrder.Name}
                                  link={paths.production.product_order_detail(ProductOrderPriceHistory.ProductOrder.Id)} />
                  <GridDetailText title={translate("title.customer")}
                                  value={ProductOrderPriceHistory.ProductOrder.Customer.Name} />
                  <GridDetailText title={translate("title.size")}
                                  value={`${ProductOrderPriceHistory.ProductOrder.Width} X ${ProductOrderPriceHistory.ProductOrder.Length} นิ้ว (${fNumber(ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length)} ตร.นิ้ว)`} />
                  <GridDetailText title={translate("title.amount")}
                                  value={fNumber(ProductOrderPriceHistory.ProductOrder.Amount)} />
                  <GridDetailText title={translate("title.name")}
                                  value={ProductOrderPriceHistory.Product.Name} />
                </Grid>
              </CardContent>
            </Card>
            <>
              {
                ProductOrderPriceHistory.ApprovedAt !== null ?
                  <>
                    <Card>
                      <CardHeader title={`${translate("procurement.price_detail")} เดิม`} />
                      <CardContent>
                        <Grid container spacing={3}>
                          <GridDetailText title={translate("title.price_per_sheet")}
                                          value={ProductOrderPriceHistory.OldIsBundle ? `เหมา ${ProductOrderPriceHistory.OldBundlePrice}` : ProductOrderPriceHistory.OldPricePerSheet} />
                          <GridDetailText title={translate("title.price_per_sheet")}
                                          value={ProductOrderPriceHistory.OldIsBundle ?
                                            fFourDecimal((ProductOrderPriceHistory.OldBundlePrice / ProductOrderPriceHistory.ProductOrder.Amount) / (ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length)) :
                                            fFourDecimal(ProductOrderPriceHistory.OldPricePerSheet / (ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length))} />
                          <GridDetailText title={translate("procurement.total_price_title")}
                                          value={ProductOrderPriceHistory.OldBundlePrice ? `เหมา ${ProductOrderPriceHistory.OldBundlePrice}` : fCurrency(ProductOrderPriceHistory.OldPricePerSheet * ProductOrderPriceHistory.ProductOrder.Amount)} />
                        </Grid>
                      </CardContent>
                    </Card>
                    {ChangePriceDetail()}
                  </>
                  :
                  <>
                    <Card>
                      <CardHeader title={`${translate("procurement.price_detail")} เดิม`} />
                      <CardContent>
                        <Grid container spacing={3}>
                          <GridDetailText title={translate("title.price_per_sheet")}
                                          value={ProductOrderPriceHistory.ProductSellHistory.IsBundle ? `เหมา ${ProductOrderPriceHistory.ProductSellHistory.BundlePrice}` : ProductOrderPriceHistory.ProductSellHistory.SellPrice} />
                          <GridDetailText title={translate("title.price_per_sheet")}
                                          value={ProductOrderPriceHistory.ProductSellHistory.IsBundle ?
                                            fFourDecimal((ProductOrderPriceHistory.ProductSellHistory.BundlePrice / ProductOrderPriceHistory.ProductOrder.Amount) / (ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length)) :
                                            fFourDecimal(ProductOrderPriceHistory.ProductSellHistory.SellPrice / (ProductOrderPriceHistory.ProductOrder.Width * ProductOrderPriceHistory.ProductOrder.Length))} />
                          <GridDetailText title={translate("procurement.total_price_title")}
                                          value={ProductOrderPriceHistory.ProductSellHistory.IsBundle ? `เหมา ${ProductOrderPriceHistory.ProductSellHistory.BundlePrice}` : fCurrency(ProductOrderPriceHistory.ProductSellHistory.SellPrice * ProductOrderPriceHistory.ProductOrder.Amount)} />
                        </Grid>
                      </CardContent>
                    </Card>
                    {ChangePriceDetail()}
                  </>
              }
            </>
            <RoleBasedGuard roles={[ManagementUserTypeId]}>
              {
                ProductOrderPriceHistory.ApprovedAt === null ?
                  <ProjectPaper>
                    <Stack spacing={2} direction="row">
                      <Button
                        color={"success"}
                        variant={"contained"}
                        fullWidth
                        startIcon={<Iconify icon={"eva:check-fill"} />}
                        onClick={handleApprovePriceChange}
                      >
                        {translate("title.approve")}
                      </Button>
                      <Button
                        variant={"contained"}
                        color={"error"}
                        fullWidth
                        startIcon={<Iconify icon={"eva:close-fill"} />}
                        onClick={handleDeclinePriceChange}
                      >
                        {translate("button.cancel")}
                      </Button>
                    </Stack>
                  </ProjectPaper>
                  :
                  null
              }
            </RoleBasedGuard>
          </Stack>
          : null
      }
    </ProjectPageContainer>
  );
}

export default ApproveChangePricePage;
