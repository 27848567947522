import React from 'react';
import { useLocales } from '../../../locales';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getLogisticOrderById, markLogisticOrderAsCreate } from '../../../requests/ITServerHandler';
import { getProductDetailById } from '../../../requests/ProductionServerHandler';
import CreateProductOrderMainForm from '../../forms/CreateProductOrderMainForm';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenErrorNotification, OpenNotificationThenCallback } from '../../../utilities/Helper/NotificationHelper';
import { paths } from '../../../routes/paths';

const useLogisticOrder = (id) => {
  return useQuery(['logistic-order_detail', id], () => getLogisticOrderById(id), {
    initialData: null,
  });
};

const useProductDetail = (productId) => {
  return useQuery(
    ['product_detail_of_logistic', productId],
    () => getProductDetailById(productId),
    {
      initialData: null,
      enabled: !!productId,
    },
  );
};

function CreateProductOrderFromLogisticOrderIdPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: logisticOrder } = useLogisticOrder(id);
  const productId = logisticOrder?.product?.id;
  const { data: product } = useProductDetail(productId);
  const markLogisticOrderAsCreateMutation = useMutation({
    mutationFn: markLogisticOrderAsCreate,
    onSuccess: async (data, variables) => {
      await OpenNotificationThenCallback(
        translate('notification.create_success'),
        `${translate('title.id')} : ${variables.ProductOrderId}`,
        async () => {
          navigate(paths.production.product_order_detail(variables.ProductOrderId));
        },
      );
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const handleCreateProductOrderDetail = async (newProductOrder) => {
    await markLogisticOrderAsCreateMutation.mutate({
      LogisticOrderId: parseInt(id) ,
      ProductOrderId: newProductOrder.Id,
    })
  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.create_new_product_order')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('production.create_new_product_order') },
      ]}
    >
      {
        product !== null ?
          <CreateProductOrderMainForm
            onCreateProductOrder={handleCreateProductOrderDetail}
            customer={{
              Id: logisticOrder.customer.id,
              Name: logisticOrder.customer.name,
            }}
            products={[{
              Detail: product,
              History: null,
              Price: {
                IsBundle: false,
                BundlePrice: 0,
                Price: product.BasePrice,
              },
            },]}
            productOrderDetail={{
              Name: logisticOrder.name,
              Detail: '',
              Width: logisticOrder.width,
              Length: logisticOrder.length,
              Amount: logisticOrder.amount,
              ProductOrderSubType: {
                Id: 0,
                Name: 'ไม่ได้เลือก',
              },
            }} />
          :
          null
      }
    </ProjectPageContainer>
  );
}

export default CreateProductOrderFromLogisticOrderIdPage;