import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack } from '@mui/material';
import { useLocales } from 'locales';

SearchField.propTypes = {
  onSearchCallBack: PropTypes.func.isRequired,
  searchLabel: PropTypes.string,
  placeHolderText: PropTypes.string,
  autoFocus: PropTypes.bool,
};

function SearchField(props) {
  const { onSearchCallBack, searchLabel, placeHolderText, autoFocus = false } = props;
  const { translate } = useLocales();
  const [searchString, setSearchString] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  const onSearch = (searchString) => {
    onSearchCallBack(searchString);
    setIsSearch(true);
  };
  const onClearSearch = () => {
    setSearchString('');
    setIsSearch(false);
    onSearchCallBack(null);
  };
  const handleTextChange = (event) => {
    setSearchString(event.target.value);
  };
  const onEnterKeyPressed = async (event) => {
    if (event.key === 'Enter') {
      onSearch(searchString);
    }
  };

  const onSearchButtonPressed = () => {
    onSearch(searchString);
  };

  return (
    <Stack spacing={2} direction="row">
      <TextField
        value={searchString}
        onChange={handleTextChange}
        onKeyDown={onEnterKeyPressed}
        fullWidth
        autoFocus={autoFocus}
        label={searchLabel}
        placeholder={placeHolderText}
        InputProps={{
          endAdornment: isSearch ? (
            <InputAdornment position="end">
              <IconButton aria-label="clear-title" onClick={onClearSearch} size="large">
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      <Button color={'primary'} variant={'contained'} onClick={onSearchButtonPressed}>
        {translate('button.search')}
      </Button>
    </Stack>
  );
}

export default SearchField;
