import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createItemMaster,
  editItemMaster,
  getItemMasterDetailById,
  uploadItemMasterImage,
} from '../WarehouseServerHandler';
import { getItemMasterQRCodeDocument } from '../../utilities/Helper/DocumentStructure';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { item_qr_prefix } from '../../utilities/Strings/Prefix';
import {
  OpenErrorNotification,
  OpenPrintSuccessNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { useLoading } from '../../components/loading';

export const useItemMasterDetail = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['itemMasterDetail', id],
    queryFn: () => getItemMasterDetailById(id),
    enabled: id !== null,
    initialData: null,
  });

  const createMutation = useMutation({
    mutationFn: createItemMaster,
    onMutate: () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await OpenSuccessNotification();
      return response.data;
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    }
  });

  const editDetailMutation = useMutation({
    mutationFn: editItemMaster,
    onMutate: () => {
      showLoadingScreen();
    },
    onSuccess: async () => {
      hideLoadingScreen();
      await queryClient.invalidateQueries(['itemMasterDetail', id]);
      await OpenSuccessNotification();
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    }
  });

  const uploadImageMutation = useMutation({
    mutationFn: uploadItemMasterImage,
    onMutate: () => {
      showLoadingScreen();
    },
    onSuccess: async () => {
      hideLoadingScreen();
      await queryClient.invalidateQueries(['itemMasterDetail', id]);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    }
  });

  const printItemMasterQRCode = async () => {
    const pdfStructure = getItemMasterQRCodeDocument(data.Id, data.Name);
    const printing = await PrintMultiplePDFMakeDocument(
      [pdfStructure],
      `${item_qr_prefix}${data.Id}.pdf`
    );
    if (printing === 'OK') {
      await OpenPrintSuccessNotification();
    }
  };
  return {
    itemMasterDetail: data,
    uploadImage: uploadImageMutation,
    createItemMaster: createMutation,
    editItemMasterDetail: editDetailMutation,
    printItemMasterQRCode,
  };
};
