import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { useLocales } from '../../locales';
import FormProvider, { RHFTextField } from '../minimal/hook-form';
import Iconify from '../minimal/iconify';
import { fDateTimeDMY } from '../../utilities/formatTime';
import { isAllowToSellPrice } from '../../utilities/Helper/UtilitiesHelper';
import GeneralTable from '../customs/GeneralTable';
import { DELETE_ICON } from '../../config-global';

ProductPriceDetailForm.propTypes = {
  products: PropTypes.array.isRequired,
  onSubmitProductPriceDetail: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  isCreateQuotation: PropTypes.bool.isRequired,
};

function ProductPriceDetailForm({
  products,
  width,
  length,
  onBackPress,
  onSubmitProductPriceDetail,
  isCreateQuotation,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const [allProduct, setAllProduct] = useState(products);

  const productPriceDetailSchema = Yup.object().shape({
    ProductPrice: Yup.array().of(
      Yup.object()
        .shape({
          Price: Yup.object()
            .shape({
              Price: Yup.number().min(0.009, translate('error.price_too_low')),
            })
            .required(translate('error.price_too_low')),
        })
        .required(translate('error.price_too_low'))
    ),
  });

  const methods = useForm({
    resolver: yupResolver(productPriceDetailSchema),
    defaultValues: {
      ProductPrice: products,
    },
  });

  const { setError, handleSubmit, control, setValue } = methods;

  const { fields, remove } = useFieldArray({
    control,
    name: 'ProductPrice',
  });

  const productPrice = useWatch({
    control,
    name: 'ProductPrice',
  });

  useEffect(() => {
    setAllProduct(products);
  }, [products]);

  useEffect(() => {
    setValue('ProductPrice', allProduct);
  }, [setValue, allProduct]);

  const handleRemove = (index) => {
    remove(index);
  };

  const onSubmit = (value) => {
    if (isPriceAllow()) {
      onSubmitProductPriceDetail(value.ProductPrice);
    } else {
      enqueueSnackbar(translate('error.price_too_low'), { variant: 'error' });
    }
  };

  const isPriceAllow = () => {
    let isAllow = true;
    if (!isCreateQuotation) {
      productPrice.forEach((product, index) => {
        if (!product.Price.IsBundle) {
          const discount = product.Detail.SpecialDiscountPriceHistories;
          const minPrice =
            discount !== null && discount.length > 0 ? discount[0].Price : product.Detail.MinPrice;
          const minPricePerSquareInch =
            discount !== null && discount.length > 0
              ? discount[0].PricePerSquareInch
              : product.Detail.PricePerSquareInch;

          if (
            !isAllowToSellPrice(
              width,
              length,
              parseFloat(product.Price.Price),
              minPrice,
              minPricePerSquareInch
            )
          ) {
            setError(`ProductPrice[${index}].Price.Price`, {
              type: 'PriceError',
              message: translate('error.price_too_low'),
            });
            isAllow = false;
          }
        }
      });
    }
    return isAllow;
  };

  return (
    <Card>
      <CardHeader title={translate('sales.manage_price')} />
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: translate('title.name') },
              { align: 'left', title: translate('sales.price_history') },
              { align: 'left', title: translate('title.price') },
              { align: 'left', title: 'Actions' },
            ]}
            tableData={fields.map((product, index) => {
              return [
                product.Detail.Name,
                product.History !== null ? (
                  <>
                    <Typography noWrap variant="body2">
                      {`${fDateTimeDMY(product.History.createdAt)}`}
                    </Typography>
                    <Typography noWrap variant="body2">
                      {`${translate('title.price')} ${
                        product.History.isBundle
                          ? `${translate('title.bundle')} ${product.Detail.BundlePrice}`
                          : product.History.SellPrice
                      }`}
                    </Typography>
                  </>
                ) : (
                  translate('sales.no_transaction')
                ),
                product.Price.IsBundle ? (
                  `${translate('title.bundle_price')} : ${product.Price.BundlePrice}`
                ) : (
                  <RHFTextField
                    size="small"
                    name={`ProductPrice[${index}].Price.Price`}
                    label={translate('title.price')}
                    InputLabelProps={{ shrink: true }}
                  />
                ),
                <IconButton color={'error'} onClick={() => handleRemove(index)}>
                  <Iconify icon={DELETE_ICON} />
                </IconButton>,
              ];
            })}
          />
          <Divider sx={{ mt: 3 }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
            }}
          >
            <Button color={'primary'} onClick={onBackPress}>
              {translate('button.back')}
            </Button>
            <Button color={'primary'} variant={'contained'} type={'submit'}>
              {translate('button.next')}
            </Button>
          </Box>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default ProductPriceDetailForm;
