import React from 'react';
import PropTypes from 'prop-types';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { AdminUserTypeId, ManagementUserTypeId, SalesUserTypeId } from 'utilities/Constants';
import Iconify from 'components/minimal/iconify';
import { Button, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { useLocales } from 'locales';
import ReactTable from 'components/react-table/ReactTable';
import { fDateTimeText } from 'utilities/formatTime';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { RouterLink } from 'routes/components';
import { paths } from 'routes/paths';
import { OPEN_ICON } from 'config-global';
import { useCustomerPricing } from 'requests/query/useCustomerDetail';

CustomerPricingDetail.propTypes = {
  customerId: PropTypes.string,
};

function CustomerPricingDetail({ customerId }) {
  const { translate } = useLocales();
  const { customerPricingDetail } = useCustomerPricing(customerId);

  return (
    <Card>
      <CardHeader
        title={translate('sales.customer_pricing')}
        action={
          <RoleBasedGuard roles={[AdminUserTypeId, ManagementUserTypeId, SalesUserTypeId]}>
            <Button
              variant="contained"
              color={'primary'}
              startIcon={<Iconify icon={'ic:baseline-add'} />}
              href={`${paths.sales.create_customer_pricing}?customerId=${customerId}`}
            >
              {translate('sales.create_customer_pricing')}
            </Button>
          </RoleBasedGuard>
        }
      />
      <CardContent>
        <ReactTable
          data={customerPricingDetail.map((pricing) => {
            return {
              id: pricing.Id,
              create_date: pricing.createdAt,
              expire_date: pricing.ExpiredAt,
              detail: pricing.Detail,
              create_by: pricing.CreatedEmployee,
              approve_employee: pricing.ApprovedEmployee,
              product_amount: pricing.Products.length,
              actions: pricing.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
              size: 10,
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create_date',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('title.expire_at'),
              accessorKey: 'expire_date',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('title.detail'),
              accessorKey: 'detail',
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'create_by',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'approve_employee',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('title.amount'),
              accessorKey: 'product_amount',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  component={RouterLink}
                  href={paths.sales.customer_pricing_detail(info.getValue())}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
              size: 10,
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

export default CustomerPricingDetail;
