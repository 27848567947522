import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../customs/ProjectPaper';
import { useMaintenanceTechnicianList } from '../../requests/query/DropdownData';
import FormProvider, { RHFAutoCompleteShort } from '../minimal/hook-form';

SelectMaintenanceTechnicianForm.propTypes = {
  onChangeTechnicianCallback: PropTypes.func.isRequired,
};

function SelectMaintenanceTechnicianForm({ onChangeTechnicianCallback }) {
  const { translate } = useLocales();
  const { allTechnician } = useMaintenanceTechnicianList();

  const selectTechnicianForm = Yup.object().shape({
    Technician: Yup.object().required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(selectTechnicianForm),
    defaultValues: {
      Technician: { Id: 0, Name: '' },
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    onChangeTechnicianCallback(values.Technician);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ProjectPaper>
        <Stack spacing={3}>
          <RHFAutoCompleteShort
            name={`Technician`}
            label={translate('maintenance.change_technician')}
            data={allTechnician}
          />
          <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
            {translate('button.change')}
          </Button>
        </Stack>
      </ProjectPaper>
    </FormProvider>
  );
}

export default SelectMaintenanceTechnicianForm;
