import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
} from '../../utilities/Constants';
import CashDeliveryBillDetailPage from '../../components/pages/FinanceModule/CashDeliveryBillDetailPage';
import SearchFinancePage from '../../components/pages/FinanceModule/SearchFinancePage';
import PrintCashDeliverySummaryReportPage from '../../components/pages/FinanceModule/PrintCashDeliverySummaryReportPage';
import CashDeliveryBillListPage from '../../components/pages/FinanceModule/CashDeliveryBillListPage';
import { paths } from '../paths';

const financeModuleRouteDetails = [
  {
    path: paths.finance.cash_delivery_bill_list,
    component: <CashDeliveryBillListPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, FinanceUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.finance.cash_delivery_bill_detail(':id'),
    component: <CashDeliveryBillDetailPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, FinanceUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.finance.search,
    component: <SearchFinancePage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, FinanceUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.finance.print_cash_delivery_bill_summary_report,
    component: <PrintCashDeliverySummaryReportPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId, FinanceUserTypeId, AccountingUserTypeId],
  },
];

export default financeModuleRouteDetails;
