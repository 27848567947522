import React, { useState } from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { PrintMultiplePDFMakeDocument } from 'utilities/Helper/PrintHelper';
import SelectBillingDateForm from 'components/forms/SelectBillingDateForm';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
  OpenTranslateWarningConfirmNotification,
} from 'utilities/Helper/NotificationHelper';
import { billing_note_prefix } from 'utilities/Strings/Prefix';
import { useLocales } from 'locales';
import CustomStepper from 'components/customs/CustomStepper';
import SelectDeliveryBillForm from 'components/forms/SelectDeliveryBillForm';
import RecheckCreateBillingNoteForm from 'components/forms/RecheckCreateBillingNoteForm';
import { createBillingNote, getBillingNotePDFById } from 'requests/ProductionServerHandler';
import StepSelectCustomerForm from 'components/forms/StepSelectCustomerForm';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';

function CreateBillingNotePage() {
  const { translate } = useLocales();
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [selectDeliveryBills, setSelectDeliveryBills] = useState([]);
  const [selectDataTable, setSelectDataTable] = useState({});
  const [selectBillingDate, setSelectBillingDate] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printBillingNoteMutation = useMutation({
    mutationFn: getBillingNotePDFById,
    onSuccess: async (pdfStructure, variables) => {
      await PrintMultiplePDFMakeDocument([pdfStructure], `${billing_note_prefix}_${variables}.pdf`);
      hideLoadingScreen();
      await OpenNotificationThenReloadPage();
    },
    onError: async () => {
      hideLoadingScreen();
      await OpenErrorNotification();
    },
  });
  const createBillingNoteMutation = useMutation({
    mutationFn: createBillingNote,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (newBillingNoteId) => {
      await printBillingNoteMutation.mutateAsync(newBillingNoteId);
    },
    onError: async () => {
      hideLoadingScreen();
      await OpenErrorNotification();
    },
  });

  const STEPS = [
    translate('sales.select_customer'),
    translate('accounting.select_delivery_bills'),
    translate('accounting.select_billing_date'),
    translate('title.conclude'),
  ];

  const handleSelectCustomer = (value) => {
    setSelectCustomer(value);
    setActiveStep(1);
  };

  const onChooseDeliveryBills = (deliveryBills, asd) => {
    setSelectDeliveryBills(deliveryBills);
    setSelectDataTable(asd);
    setActiveStep(2);
  };

  const onSelectBillingDate = (date) => {
    setSelectBillingDate(date);
    setActiveStep(3);
  };

  const onCreateBillingNote = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_create_billing_note',
      async () => {
        let deliverBillIds = [];
        let additionalCostBillIds = [];

        selectDeliveryBills.forEach((deliveryBill) => {
          if (deliveryBill.Id.toString().includes('A-')) {
            additionalCostBillIds.push(deliveryBill.Id.toString().replace('A-', ''));
          } else {
            deliverBillIds.push(deliveryBill.Id);
          }
        });

        await createBillingNoteMutation.mutateAsync({
          DeliveryBillIds: deliverBillIds,
          AdditionalCostBillIds: additionalCostBillIds,
          CustomerId: selectCustomer.Id,
          BillingDate: selectBillingDate,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.create_billing_note_title')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.create_billing_note_title') },
      ]}
    >
      <CustomStepper currentStep={activeStep} steps={STEPS} />
      <>
        {activeStep === 0 && (
          <StepSelectCustomerForm
            onSubmitCallback={handleSelectCustomer}
            defaultCustomer={selectCustomer}
          />
        )}
        {activeStep === 1 && (
          <SelectDeliveryBillForm
            selectCustomer={selectCustomer}
            defaultSelectBills={selectDataTable}
            onSelectDeliveryBills={onChooseDeliveryBills}
            onBackPressed={() => setActiveStep(0)}
          />
        )}
        {activeStep === 2 && (
          <SelectBillingDateForm
            billingDate={selectBillingDate}
            onSelectDateCallBack={onSelectBillingDate}
            onBackPressed={() => setActiveStep(1)}
          />
        )}
        {activeStep === 3 && (
          <RecheckCreateBillingNoteForm
            billingDate={selectBillingDate}
            onConfirm={onCreateBillingNote}
            bills={selectDeliveryBills}
            customer={selectCustomer}
            onBackPressed={() => setActiveStep(2)}
          />
        )}
      </>
    </ProjectPageContainer>
  );
}

export default CreateBillingNotePage;
