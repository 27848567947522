import React from 'react';
import PropTypes from 'prop-types';
import { useBOM } from '../../../requests/query/useBOM';

BomDetail.propTypes = {
  bomId: PropTypes.number.isRequired,
};

function BomDetail({bomId}) {
  const {bomDetail} = useBOM(bomId);
  return (
    <div>{
      bomDetail !== null ? bomDetail.Name : 'BOM not found'
    }</div>
  );
}

export default BomDetail;