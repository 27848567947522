import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Stack, Button, IconButton } from '@mui/material';
import ReactTable from 'components/react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import { fCurrency } from 'utilities/formatNumber';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, {
  RHFAutoCompleteShort,
  RHFDatePicker,
  RHFTextField,
} from 'components/minimal/hook-form';
import GeneralTable from 'components/customs/GeneralTable';
import { DELETE_ICON } from 'config-global';
import { useQuery } from '@tanstack/react-query';
import { getAllProduct } from 'requests/ProductionServerHandler';

CreateCustomerPricingForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

function CreateCustomerPricingForm({ onComplete }) {
  const { translate } = useLocales();
  const today = new Date();
  today.setDate(today.getDate() + 1);

  const { data: productOptions } = useQuery({
    queryKey: ['product'],
    queryFn: getAllProduct,
    initialData: [],
  });

  const discountOptions = [
    {
      Id: 1,
      Name: '0 %',
      Value: 0,
    },
    {
      Id: 2,
      Name: '5 %',
      Value: 5,
    },
  ];

  const productPriceDetailSchema = Yup.object().shape({
    Products: Yup.array()
      .of(
        Yup.object().shape({
          Product: Yup.object()
            .shape({
              Id: Yup.number().min(1, translate('error.invalid_input')),
              Name: Yup.string().required(translate('error.invalid_input')),
            })
            .required(translate('error.invalid_input')),
          Discount: Yup.object()
            .shape({
              Id: Yup.number().min(0, translate('error.invalid_input')),
              Name: Yup.string().required(translate('error.invalid_input')),
            })
            .required(translate('error.invalid_input')),
          MinimumAmount: Yup.number().min(100, translate('error.invalid_input')),
        })
      )
      .min(1, 'error.invalid_input'),
    ExpiredAt: Yup.date().required(translate('error.due_date_require')),
  });
  const methods = useForm({
    resolver: yupResolver(productPriceDetailSchema),
    defaultValues: {
      Products: [],
      ExpiredAt: today,
      Detail: '',
    },
  });
  const { handleSubmit, control } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Products',
  });
  const products = useWatch({
    control,
    name: 'Products',
    defaultValue: [],
  });
  const handleSelectProducts = (values) => {
    onComplete(values);
  };
  const handleAddProduct = (product) => {
    append({
      Product: product,
      Discount: discountOptions[0],
      MinimumAmount: product.MinimumAmount,
    });
  };
  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleSelectProducts)}>
      <Stack spacing={2}>
        <Card>
          <CardHeader title={translate('title.detail')} />
          <CardContent>
            <Stack spacing={2}>
              <RHFTextField name="Detail" label={translate('title.detail')} />
              <RHFDatePicker name="ExpiredAt" label={translate('title.expire_at')} includeTime />
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <ReactTable
              search
              defaultPageSize={10}
              data={productOptions.map((product) => {
                return {
                  id: product.Id,
                  name: product.Name,
                  sku: product.SKU,
                  price: product.BasePrice,
                  actions: product,
                };
              })}
              header={[
                {
                  header: translate('title.amount'),
                  accessorKey: 'actions',
                  size: 50,
                  cell: (info) =>
                    products.filter(
                      (selectProduct) => selectProduct.Product.Id === info.getValue().Id
                    ).length > 0 ? (
                      <Button
                        variant={'contained'}
                        startIcon={<Iconify icon={'material-symbols:check-small'} />}
                        color={'success'}
                      >
                        {translate('button.already_selected')}
                      </Button>
                    ) : (
                      <Button
                        variant={'contained'}
                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                        color={'primary'}
                        onClick={() => handleAddProduct(info.getValue())}
                      >
                        {translate('button.selected')}
                      </Button>
                    ),
                },
                {
                  header: translate('title.id'),
                  accessorKey: 'id',
                  size: 50,
                },
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                  size: 100,
                },
                {
                  header: translate('title.sku'),
                  accessorKey: 'sku',
                  size: 50,
                },
                {
                  header: translate('title.price_per_sheet'),
                  accessorKey: 'price',
                  size: 50,
                  cell: (info) => fCurrency(info.getValue()),
                },
              ]}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader title={translate('button.already_selected')} />
          <CardContent>
            <Stack spacing={2}>
              <GeneralTable
                tableHeaders={[
                  { align: 'left', title: 'title.id' },
                  { align: 'left', title: 'title.name' },
                  { align: 'left', title: 'title.sku' },
                  { align: 'left', title: 'title.price_per_sheet' },
                  { align: 'left', title: 'title.discount' },
                  { align: 'left', title: 'title.minimum_amount' },
                  { align: 'left', title: '' },
                ]}
                tableData={fields.map((item, index) => [
                  item.Product.Id,
                  item.Product.Name,
                  item.Product.SKU,
                  fCurrency(item.Product.BasePrice),
                  <RHFAutoCompleteShort
                    name={`Products[${index}].Discount`}
                    label={translate('title.discount')}
                    data={discountOptions}
                  />,
                  <RHFTextField
                    type={'number'}
                    size="small"
                    name={`Products[${index}].MinimumAmount`}
                    label={translate('title.minimum_amount')}
                    InputLabelProps={{ shrink: true }}
                  />,
                  <IconButton color={'error'} onClick={() => handleRemove(index)}>
                    <Iconify icon={DELETE_ICON} />
                  </IconButton>,
                ])}
              />
            </Stack>
          </CardContent>
        </Card>

        <Button variant={'contained'} color={'primary'} fullWidth type={'submit'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateCustomerPricingForm;
