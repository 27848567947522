import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from '../../../locales';
import Image from 'components/minimal/image';

export const ItemTemplateDetailComponent = ({ itemTemplate }) => {
  const { translate } = useLocales();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <ProjectPaper>
          <Image
            alt="large image"
            src={
              process.env.REACT_APP_IMAGE_END_POINT +
              process.env.REACT_APP_BUCKET_IMAGE_FOLDER_NAME +
              process.env.REACT_APP_IMAGE_PREFIX +
              itemTemplate.Id
            }
            ratio="1/1"
          />
        </ProjectPaper>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText title={translate('title.name')} value={itemTemplate.Name} />
            <GridDetailText title={translate('title.detail')} value={itemTemplate.Detail} />
            <GridDetailText
              title={translate('title.type')}
              value={itemTemplate.ItemTemplateType.Name}
            />
            <GridDetailText
              title={translate('unit.title')}
              value={itemTemplate.ItemTemplateUnit.Name}
            />
            <GridDetailText
              title={translate('title.safety_amount')}
              value={itemTemplate.SafetyAmount}
            />
            <GridDetailText
              title={translate('warehouse.amount_in_store_title')}
              value={itemTemplate.CurrentAmount}
            />
            <GridDetailText
              title={translate('warehouse.store')}
              value={itemTemplate.InventoryStore.Name}
            />
            <GridDetailText
              title={translate('warehouse.shelf')}
              value={itemTemplate.InventoryShelf.Name}
            />
          </Grid>
        </ProjectPaper>
      </Grid>
    </Grid>
  );
};

ItemTemplateDetailComponent.PropType = {
  itemTemplate: PropTypes.object.isRequired,
};
