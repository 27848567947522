import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import ProjectPaper from '../../customs/ProjectPaper';
import { useLocales } from '../../../locales';
import { useTheme } from '@mui/material/styles';

MaintenanceJobEvaluateDetail.propTypes = {
  jobDetail: PropTypes.object.isRequired,
};

function MaintenanceJobEvaluateDetail({ jobDetail }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const getEvaluationColor = (score) => {
    if (score === 1) {
      return theme.palette.success.main;
    }
    if (score === 2) {
      return theme.palette.warning.main;
    }
    if (score === 3) {
      return theme.palette.error.main;
    }
  };

  return (
    <div>
      {jobDetail !== null && (
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText
              title={translate('maintenance.evaluate_job')}
              value={jobDetail?.EvaluationScore.Name}
              color={getEvaluationColor(jobDetail?.EvaluationScore.Id)}
            />
            <GridDetailText
              title={translate('title.detail')}
              value={jobDetail?.EvaluationComment}
            />
          </Grid>
        </ProjectPaper>
      )}
    </div>
  );
}

export default MaintenanceJobEvaluateDetail;
