import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  editSupplierSellItemContainerDetail,
  getSupplierSellItemById,
} from '../../../requests/WarehouseServerHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import EditSupplierSellItemForm from '../../forms/EditSupplierSellItemForm';
import { OpenNotificationThenCallback } from '../../../utilities/Helper/NotificationHelper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useItemUnitOptions } from '../../../requests/query/DropdownData';

function EditSupplierSellItemDetailPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { id } = useParams();

  const { data: supplierSellItemDetail } = useQuery({
    queryKey: ['getSupplierSellItemById', id],
    queryFn: () => getSupplierSellItemById(id),
    initialData: null,
  });

  const { itemUnitOptions } = useItemUnitOptions();

  const editSupplierSellItemContainerDetailMutation = useMutation({
    mutationFn: editSupplierSellItemContainerDetail,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', () => {
        navigate(-1);
      });
    },
  });

  const onSubmit = async (values) => {
    await editSupplierSellItemContainerDetailMutation.mutateAsync({
      SupplierSellItemContainerId: id,
      Name: values.Name,
      SellItemUnitId: values.SellItemUnit.Id,
      ItemContainerId: values.ItemContainer.Id,
      PricePerSellUnit: values.Price,
      SellUnitPerItemContainerUnitAmount: values.SellUnitPerItemContainerUnitAmount,
    });
  };
  return (
    <ProjectPageContainer
      menu_title={translate('procurement.edit_supplier_sell_item_container')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.edit_supplier_sell_item_container') },
      ]}
    >
      {supplierSellItemDetail !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('procurement.supplier')}
                value={supplierSellItemDetail.Supplier.Name}
              />
              <GridDetailText
                title={translate('warehouse.item_master_name')}
                value={supplierSellItemDetail.ItemMaster.Name}
              />
              <GridDetailText
                title={translate('warehouse.item_container_name')}
                value={supplierSellItemDetail.ItemContainer.Name}
              />
            </Grid>
          </ProjectPaper>
          <EditSupplierSellItemForm
            itemName={supplierSellItemDetail.Name}
            price={supplierSellItemDetail.PricePerSellUnit}
            amountPerContainer={supplierSellItemDetail.SellUnitPerItemContainerUnitAmount}
            currentContainerId={supplierSellItemDetail.ItemContainer.Id}
            itemContainerOptions={supplierSellItemDetail.ItemMaster.ItemContainers.map((itemContainer) => {
              return {
                Id: itemContainer.Id,
                Name: `${itemContainer.Name} (จำนวน Master ${itemContainer.ItemMasterSize} ${supplierSellItemDetail.ItemMaster.ItemUnit.Name})`,
              };
            })}
            itemUnit={itemUnitOptions.find(
              (itemUnit) => itemUnit.Id === supplierSellItemDetail.SellItemUnitId
            )}
            onCompleteForm={onSubmit}
            itemUnitOptions={itemUnitOptions}
          />
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default EditSupplierSellItemDetailPage;
