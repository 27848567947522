import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from 'components/minimal/iconify';
import EditProductDetailFrom from '../../forms/EditProductDetailFrom';
import { useResponsive } from '../../../hooks/useResponsive';
import LoadingComponent from '../../customs/LoadingComponent';
import ProductDetail from '../../details/product/ProductDetail';
import ProductSellHistoryDetail from '../../details/product/ProductSellHistoryDetail';
import useTabs from '../../../hooks/useTabs';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  ManagementUserTypeId,
  ProductionControlUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
} from '../../../utilities/Constants';
import { useProduct } from '../../../requests/query/useProduct';
import { paths } from '../../../routes/paths';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import ProductBOMDetail from '../../details/product/ProductBOMDetail';

const ProductDetailPage = () => {
  const { id } = useParams();
  const { translate } = useLocales();
  const [isEditMode, setIsEditMode] = useState(false);
  const isDesktop = useResponsive('up', 'lg');
  const { currentTab, onChangeTab } = useTabs('detail');

  const { productDetail, editProduct } = useProduct(id);
  const onEditPressed = async () => {
    setIsEditMode(true);
  };
  const onSaveEditProductDetail = async (value) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_edit_data',
      async () => {
        await editProduct.mutateAsync({
          Id: productDetail?.Id,
          Name: value.Name,
          Detail: value.Detail !== undefined ? value.Detail : '',
          BasePrice: value.BasePrice,
          TargetPerMonth: value.TargetPerMonth,
          MinimumPrice: value.MinimumPrice,
          IsTaxable: value.IsTaxable,
          IsDiscountable: value.IsDiscountable,
          ProductProcessIds: value.Process.map((process) => process.Id),
          ProductTypeId: value.ProductType.Id,
          MinimumAmount: value.MinimumAmount,
          MinimumValue: value.MinimumValue,
          BundlePrice: value.BundlePrice,
          PricePerSquareInch: value.PricePerSquareInch,
        });
        setIsEditMode(false);
      }
    );
  };

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: <ProductDetail productDetail={productDetail} />,
    },
    {
      value: 'history',
      title: translate('sales.price_history'),
      icon: <Iconify icon={'ri:bill-line'} width={20} height={20} />,
      component: (
        <RoleBasedGuard roles={[ManagementUserTypeId, SalesUserTypeId, AccountingUserTypeId]}>
          <ProductSellHistoryDetail productDetail={productDetail} />
        </RoleBasedGuard>
      ),
    },
    {
      value: 'bom',
      title: translate('production.bom'),
      icon: <Iconify icon={'eva:cube-outline'} width={20} height={20} />,
      component: (
        <RoleBasedGuard
          roles={[
            ManagementUserTypeId,
            AdminUserTypeId,
            QualityAssuranceUserTypeId,
            ProductionControlUserTypeId,
          ]}
        >
          <ProductBOMDetail productDetail={productDetail} />
        </RoleBasedGuard>
      ),
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('product.detail')}
      breadcrumbs={[
        { name: translate('product.title') },
        { name: translate('product.list'), href: paths.product.product_list },
        { name: `${translate('product.detail')} : ${id}` },
      ]}
      action={
        <Stack spacing={2} direction={isDesktop ? 'row' : 'column'}>
          {isEditMode ? null : (
            <Button
              variant={'contained'}
              startIcon={<Iconify icon={'ic:baseline-edit'} />}
              color={'info'}
              onClick={onEditPressed}
            >
              {translate('title.edit')}
            </Button>
          )}
        </Stack>
      }
    >
      {productDetail !== null ? (
        <>
          {isEditMode ? (
            <EditProductDetailFrom
              productDetail={productDetail}
              onUpdateCompleteCallback={onSaveEditProductDetail}
            />
          ) : (
            <>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
              >
                {INFORMATION_TABS.map((tab, index) => (
                  <Tab
                    disableRipple
                    key={index}
                    label={tab.title}
                    icon={tab.icon}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              <Box sx={{ mb: 3 }} />
              {INFORMATION_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && tab.component;
              })}
            </>
          )}
        </>
      ) : (
        <LoadingComponent />
      )}
    </ProjectPageContainer>
  );
};

export default ProductDetailPage;
