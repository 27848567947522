import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import { useLocales } from "locales";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Typography from "@mui/material/Typography";
import CheckIcon from '@mui/icons-material/Check';
import {fNumber} from "../../utilities/formatNumber";

SelectMachineDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleDialogClose: PropTypes.func.isRequired,
    handleSelectMachine: PropTypes.func.isRequired,
    machineList: PropTypes.array.isRequired,
};

function SelectMachineDialog({isOpen, handleDialogClose , handleSelectMachine, machineList}) {

    const {translate} = useLocales();
    const [selectMachineId, setSelectMachineId] = useState(0)

    const handleToggleStateChange = (event, newAlignment) => {
        setSelectMachineId(newAlignment);
    };

    const onSelectMachine = () => {
        handleSelectMachine(selectMachineId);
        setSelectMachineId(0);
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleDialogClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-select-machine"
                aria-describedby="scroll-dialog-select-machine"
            >
                <DialogTitle id="scroll-dialog-po">{translate('planning.select_machine')}</DialogTitle>
                <DialogContent dividers={'paper'}>
                    {
                        machineList.length > 0 ?
                            <ToggleButtonGroup
                                orientation="vertical"
                                value={selectMachineId}
                                exclusive
                                onChange={handleToggleStateChange}
                            >
                                {
                                    machineList.map((machine) => {
                                        return <ToggleButton value={machine.Id} key={machine.Id}>
                                            <Typography variant={'body1'} sx={{mr:'50px'}}>{machine.Name}</Typography>
                                            {
                                                machine.ProductOrderPlannings.length > 0 ?
                                                    <>
                                                        {
                                                            `กำลังวิ่งงาน : ${machine.ProductOrderPlannings[0].ProductOrderId}`
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <CheckIcon color={'success'}/>
                                                        <Typography sx={{ml:1}} variant={'body2'}>ว่าง</Typography>
                                                    </>
                                            }
                                            <Typography sx={{ml:'100px'}} variant={'body1'}>{`รับได้ ${fNumber(machine.ExpectedResult)} แผ่น`}</Typography>
                                        </ToggleButton>
                                    })
                                }
                            </ToggleButtonGroup>
                            :
                            <Typography variant={'h6'}>{`ไม่มีเครื่องที่สามารถลงแผนได้`}</Typography>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'error'} onClick={handleDialogClose}>{translate('button.close')}</Button>
                    <Button variant={'contained'} disabled={selectMachineId === 0} onClick={onSelectMachine}>{translate('button.selected')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SelectMachineDialog;
