import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { DatePickerWithTypeSelection } from '../../date-picker';
import { Grid, Button } from '@mui/material';
import { useLocales } from 'locales';
import { getMaintenanceJobReport, getMachinePartCostReport } from '../../../requests/ProductionServerHandler';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';
import Stack from "@mui/material/Stack";

export default function PrintMaintenanceJobReportPage() {
  const { translate } = useLocales();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const getMaintenanceJobReportExcelMutation = useMutation({
    mutationFn: getMaintenanceJobReport,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (report) => {
      await hideLoadingScreen();
      saveAs(report, 'mt_report.xlsx');
      await OpenSuccessNotification();
    },
  });

  const getMachinePartCostExcelMutation = useMutation({
    mutationFn: getMachinePartCostReport,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (report) => {
      await hideLoadingScreen();
      saveAs(report, 'machine_part_cost_report.xlsx');
      await OpenSuccessNotification();
    },
  });

  const onPrintExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await getMaintenanceJobReportExcelMutation.mutateAsync({
        fromDate: fromDate,
        toDate: toDate,
      });
    } else {
      await OpenErrorNotification();
    }
  };

  const onPrintMachinePartCostExcel = async () => {
    if (fromDate !== null && toDate !== null) {
      await getMachinePartCostExcelMutation.mutateAsync({
        fromDate: fromDate,
        toDate: toDate,
      });
    } else {
      await OpenErrorNotification();
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('maintenance.print_maintenance_job_report_page_title')}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: translate('maintenance.print_maintenance_job_report_page_title') },
      ]}
    >
      <DatePickerWithTypeSelection
        onFromChange={setFromDate}
        onToChange={setToDate}
        selectionActions={
          <Grid item xs={12} sm={12} spacing={2}>
            <Stack spacing={2}>
              <Button
                  fullWidth
                  color={'info'}
                  variant={'contained'}
                  onClick={onPrintExcel}
              >{`${translate(
                  'maintenance.print_maintenance_job_report_page_title'
              )} (Excel)`}</Button>
              <Button
                  fullWidth
                  color={'info'}
                  variant={'contained'}
                  onClick={onPrintMachinePartCostExcel}
              >{`พิมพ์รายงานค่าอะไหล่เครื่องจักร (Excel)`}</Button>
            </Stack>
          </Grid>
        }
      />
    </ProjectPageContainer>
  );
}
