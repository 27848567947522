import React from 'react';
import { useLocales } from 'locales';
import {
  OpenConfirmNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { createItJob } from '../../../requests/AdminServerHandler';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import ProjectPaper from '../../customs/ProjectPaper';
import CreateItJobForm from '../../forms/CreateItJobForm';
import useAuth from '../../../auth/hooks/useAuth';
import { useMutation } from '@tanstack/react-query';

function CreateItJobPage() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const createItJobMutation = useMutation({
    mutationFn: createItJob,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage(translate('success.create_it_job'));
    },
  });
  const onCreateItJob = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_it_job'),
      translate('button.confirm'),
      '',
      async () => {
        await createItJobMutation.mutateAsync({
          asset_id: values.Asset.Id,
          user_detail: values.Detail,
          user_employee_id: parseInt(user.EmployeeCode),
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('it.create_job')}
      breadcrumbs={[{ name: translate('it.title') }, { name: translate('it.create_job') }]}
    >
      <ProjectPaper>
        <CreateItJobForm onSubmitValue={onCreateItJob} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateItJobPage;
