import {createSlice} from '@reduxjs/toolkit'

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState: {
        PartsPurchaseCart: [],
        PartsRequestCart: [],
        PurchaseCart: [],
        RequestCart: [],
        selectMaintenanceId:null,
        selectProductOrderId:null
    },
    reducers: {
        adjustRequestCart: (state, action) => {
            let returnCart = [];
            const adjustRequestAmount = parseFloat(action.payload.Amount);
            if(adjustRequestAmount > 0)
            {
                let isInCart = false;
                returnCart = state.RequestCart.map((cartItem) => {
                    if(cartItem.Id === action.payload.Id)
                    {
                        isInCart = true;
                        return {
                            Id: action.payload.Id,
                            Name: action.payload.Name,
                            Amount: adjustRequestAmount
                        }
                    }else{
                        return cartItem
                    }
                });

                if(!isInCart)
                {
                    returnCart.push({
                        Id: action.payload.Id,
                        Name: action.payload.Name,
                        Amount: adjustRequestAmount
                    })
                }
            }else{
                returnCart = state.RequestCart.filter((cartItem) => cartItem.Id !== action.payload.Id);
            }

            state.RequestCart = returnCart
        },
        adjustPurchaseCart: (state, action) => {
            let purchaseCart = [];
            const adjustPurchaseAmount = parseFloat(action.payload.Amount);
            if(adjustPurchaseAmount > 0)
            {
                let isInCart = false;
                purchaseCart = state.PurchaseCart.map((cartItem) => {
                    if(cartItem.Id === action.payload.Id)
                    {
                        isInCart = true;
                        return {
                            Id: action.payload.Id,
                            Name: action.payload.Name,
                            Amount: adjustPurchaseAmount
                        }
                    }else{
                        return cartItem
                    }
                });

                if(!isInCart)
                {
                    purchaseCart.push({
                        Id: action.payload.Id,
                        Name: action.payload.Name,
                        Amount: adjustPurchaseAmount
                    })
                }
            }else{
                purchaseCart = state.PurchaseCart.filter((cartItem) => cartItem.Id !== action.payload.Id);
            }

            state.PurchaseCart = purchaseCart
        },
        resetRequestCart: (state) => {
            state.RequestCart = []
        },
        resetPurchaseCart: (state) => {
            state.PurchaseCart = []
        },
        adjustPartsRequestCart: (state, action) => {
            let returnCart = [];
            const adjustRequestAmount = parseFloat(action.payload.Amount);
            if(adjustRequestAmount > 0)
            {
                let isInCart = false;
                returnCart = state.PartsRequestCart.map((cartItem) => {
                    if(cartItem.Id === action.payload.Id)
                    {
                        isInCart = true;
                        return {
                            Id: action.payload.Id,
                            Name: action.payload.Name,
                            Amount: adjustRequestAmount,
                            MaxAmount: action.payload.MaxAmount
                        }
                    }else{
                        return cartItem
                    }
                });

                if(!isInCart)
                {
                    returnCart.push({
                        Id: action.payload.Id,
                        Name: action.payload.Name,
                        Amount: adjustRequestAmount,
                        MaxAmount: action.payload.MaxAmount
                    })
                }
            }else{
                returnCart = state.PartsRequestCart.filter((cartItem) => cartItem.Id !== action.payload.Id);
            }

            state.PartsRequestCart = returnCart
        },
        adjustPartsPurchaseCart: (state, action) => {
            let purchaseCart = [];
            const adjustPurchaseAmount = parseFloat(action.payload.Amount);
            if(adjustPurchaseAmount > 0)
            {
                let isInCart = false;
                purchaseCart = state.PartsPurchaseCart.map((cartItem) => {
                    if(cartItem.Id === action.payload.Id)
                    {
                        isInCart = true;
                        return {
                            Id: action.payload.Id,
                            Name: action.payload.Name,
                            Amount: adjustPurchaseAmount
                        }
                    }else{
                        return cartItem
                    }
                });

                if(!isInCart)
                {
                    purchaseCart.push({
                        Id: action.payload.Id,
                        Name: action.payload.Name,
                        Amount: adjustPurchaseAmount
                    })
                }
            }else{
                purchaseCart = state.PartsPurchaseCart.filter((cartItem) => cartItem.Id !== action.payload.Id);
            }

            state.PartsPurchaseCart = purchaseCart
        },
        resetPartsRequestCart: (state) => {
            state.PartsRequestCart = []
        },
        resetPartsPurchaseCart: (state) => {
            state.PartsPurchaseCart = []
        },
        selectItemForMaintenanceId: (state, action) =>
        {
            state.selectMaintenanceId = action.payload
        },
        selectItemForProductOrder: (state, action) =>
        {
            state.selectProductOrderId = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {adjustPartsRequestCart,adjustPartsPurchaseCart,resetPartsRequestCart,resetPartsPurchaseCart,adjustRequestCart,adjustPurchaseCart,resetRequestCart,resetPurchaseCart,selectItemForMaintenanceId,selectItemForProductOrder} = inventorySlice.actions
export const selectPurchaseCart = (state) => state.inventory.PurchaseCart;
export const selectRequestCart = (state) => state.inventory.RequestCart;
export const selectPartsPurchaseCart = (state) => state.inventory.PartsPurchaseCart;
export const selectPartsRequestCart = (state) => state.inventory.PartsRequestCart;
export const selectItemMaintenanceId = (state) => state.inventory.selectMaintenanceId;
export const selectItemProductOrderId = (state) => state.inventory.selectProductOrderId;
export default inventorySlice.reducer
