import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import MachineQueueDetail from './MachineQueueDetail';

MachineDetailDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  machineDetail: PropTypes.object.isRequired,
};

function MachineDetailDialog({ isOpen, handleDialogClose, machineDetail }) {
  const { translate } = useLocales();
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-m"
        aria-describedby="scroll-dialog-m-description"
      >
        <DialogTitle id="scroll-dialog-po">{translate('planning.machine_detail')}</DialogTitle>
        <DialogContent dividers={'paper'}>
          {machineDetail !== null && <MachineQueueDetail machineDetail={machineDetail} />}
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={handleDialogClose}>
            {translate('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MachineDetailDialog;
