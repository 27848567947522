import React, { useState } from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { SILDatePicker } from 'components/date-picker';
import { getSalesDashboardData } from 'requests/ProductionServerHandler';
import { useLocales } from 'locales';
import { Card, CardContent, IconButton } from '@mui/material';
import ReactTable from 'components/react-table/ReactTable';
import { fDateTimeText } from 'utilities/formatTime';
import { fCurrency } from 'utilities/formatNumber';
import Iconify from 'components/minimal/iconify';
import { RouterLink } from 'routes/components';
import { paths } from 'routes/paths';
import { OPEN_ICON } from 'config-global';
import { useQuery } from '@tanstack/react-query';

const SalesDashboardPage = () => {
  const { translate } = useLocales();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);

  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const { data: dashboardData, refetch } = useQuery({
    queryKey: ['sales-dashboard-data', dateRange],
    queryFn: () => getSalesDashboardData(dateRange),
    initialData: [],
  });

  const handleGetDashboardDataByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate,
      toDate,
    });
    await refetch();
  };

  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[{ name: translate('sales.title') }, { name: translate('dashboard') }]}
    >
      <SILDatePicker onSearchDate={handleGetDashboardDataByDate} />
      <Card>
        <CardContent>
          <ReactTable
            search
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
              },
              {
                header: translate('title.customer'),
                accessorKey: 'customer',
              },
              {
                header: translate('title.create_at'),
                accessorKey: 'create',
                cell: (info) => fDateTimeText(info.getValue()),
              },
              {
                header: translate('title.value'),
                accessorKey: 'value',
                cell: (info) => fCurrency(info.getValue()),
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <IconButton
                    component={RouterLink}
                    href={paths.accounting.delivery_bill_detail(info.getValue())}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                ),
              },
            ]}
            data={dashboardData.map((deliveryBill) => {
              return {
                id: deliveryBill.Id,
                customer: deliveryBill.Customer.Name,
                create: deliveryBill.createdAt,
                value: deliveryBill.TotalValue,
                actions: deliveryBill.Id,
              };
            })}
          />
        </CardContent>
      </Card>
    </ProjectPageContainer>
  );
};

export default SalesDashboardPage;
