import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  OpenErrorNotification,
  OpenTranslateInputNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import {
  AdminUserTypeId,
  ManagementUserTypeId,
  StoreUserTypeId,
} from '../../../utilities/Constants';
import { getItemTemplateDetailById } from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import { useResponsive } from '../../../hooks/useResponsive';
import { Button, Grid, IconButton, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import GeneralTable from '../../customs/GeneralTable';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { useItemTemplateRequestReport } from '../../../requests/query/useItemTemplateRequestReport';
import { paths } from '../../../routes/paths';
import { DataScanner } from '../../barcode-reader';
import ItemTemplateCodeForm from '../../forms/ItemTemplateCodeForm';
import { useMutation } from '@tanstack/react-query';
import { usePartStockReport } from '../../../requests/query/usePartStockReport';
import Label from '../../minimal/label';
import Link from "@mui/material/Link";

export default function ItemTemplateRequestReportDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const isDesktop = useResponsive('up', 'lg');
  const [addItems, setAddItems] = useState([]);
  const { itemTemplateRequestReportDetail, cancel, printReport } = useItemTemplateRequestReport(id);
  const { create } = usePartStockReport(null);
  const searchItemTemplateMutation = useMutation({
    mutationFn: getItemTemplateDetailById,
    onSuccess: async (itemTemplate) => {
      await OpenTranslateInputNotification(
        translate,
        translate('warehouse.release_item_amount_title'),
        `${itemTemplate.Name} \nมี ${itemTemplate.CurrentAmount} ${itemTemplate.ItemTemplateUnit.Name} ในคลัง`,
        'text',
        async (value) => {
          if (!value) {
            return translate('error.error_invalid_amount');
          } else {
            const amount = parseFloat(value);
            if (isNaN(amount)) {
              return translate('error.error_invalid_amount');
            } else {
              if (amount <= 0) {
                return translate('error.error_invalid_amount');
              } else {
                if (amount > itemTemplate.CurrentAmount) {
                  return translate('error.insufficient_amount');
                }
              }
            }
          }
        },
        async (value) =>
          setAddItems([
            ...addItems.filter((addItem) => addItem.Id !== itemTemplate.Id),
            {
              Id: itemTemplate.Id,
              Name: itemTemplate.Name,
              Amount: value,
            },
          ])
      );
    },
  });
  const handlePrintPDF = async () => {
    await printReport.mutateAsync(id);
  };
  const handleCancel = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => await cancel.mutateAsync(id)
    );
  };
  const handleSubmitItems = async () => {
    const checkOutItems = addItems.map((addItem) => {
      return {
        Id: addItem.Id,
        Amount: addItem.Amount,
      };
    });

    await create.mutateAsync({
      CheckoutItems: checkOutItems,
      ItemTemplateRequestReportId: itemTemplateRequestReportDetail.Id,
    });
  };
  const handleSearchQRCode = async (searchString) => {
    if (searchString.trim() !== '') {
      if (
        itemTemplateRequestReportDetail.ItemTemplates.find(
          (itemTemplate) => itemTemplate.Id === parseInt(searchString)
        ) !== undefined
      ) {
        await searchItemTemplateMutation.mutateAsync(searchString);
      } else {
        await OpenErrorNotification(translate('error.error_invalid_item'));
      }
    } else {
      await OpenErrorNotification(translate('error.error_invalid_item'));
    }
  };
  const handleRemoveItem = (removeIndex) => {
    setAddItems(addItems.filter((item, index) => index !== removeIndex));
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.item_template_request_report_detail')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        {
          name: translate('warehouse.request_list'),
          href: paths.general_warehouse.release_items,
        },
        {
          name: `${translate('warehouse.item_template_request_report_detail')} ${translate(
            'title.id'
          )} : ${id}`,
        },
      ]}
      action={
        itemTemplateRequestReportDetail?.CompletedAt === null &&
        itemTemplateRequestReportDetail?.CanceledAt === null && (
          <Stack spacing={2} direction={isDesktop ? 'row' : 'column'}>
            <Button
              variant="contained"
              color={'error'}
              startIcon={<Iconify icon={'eva:close-fill'} />}
              onClick={handleCancel}
            >
              {translate('button.cancel')}
            </Button>
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={handlePrintPDF}
            >
              {translate('button.print_item_template_request_document')}
            </Button>
          </Stack>
        )
      }
    >
      {itemTemplateRequestReportDetail !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('title.detail')}
                value={
                  itemTemplateRequestReportDetail?.Detail !== null
                    ? itemTemplateRequestReportDetail?.Detail.trim() !== ''
                      ? itemTemplateRequestReportDetail?.Detail
                      : translate('title.none')
                    : translate('title.none')
                }
              />
              <GridDetailText
                title={translate('title.create_by')}
                value={getEmployeeFullName(itemTemplateRequestReportDetail?.CreateEmployee)}
              />
              <GridDetailText
                title={translate('user.department')}
                value={itemTemplateRequestReportDetail?.CreateEmployee.Department.Name}
              />
              {itemTemplateRequestReportDetail?.CompletedAt !== null && (
                <GridDetailText
                  title={translate('title.complete_at')}
                  value={fDateTimeText(itemTemplateRequestReportDetail?.CompletedAt)}
                />
              )}
              {itemTemplateRequestReportDetail?.CanceledAt !== null && (
                <GridDetailText
                  title={translate('title.canceled_at')}
                  value={fDateTimeText(itemTemplateRequestReportDetail?.CanceledAt)}
                />
              )}
            </Grid>
          </ProjectPaper>
          {itemTemplateRequestReportDetail?.MaintenanceJobs.length > 0 && (
            <ProjectPaper>
              <Grid container spacing={3}>
                <GridDetailText
                  title={translate('warehouse.for_maintenance_job_id')}
                  value={itemTemplateRequestReportDetail?.MaintenanceJobs[0].Id}
                  link={paths.maintenance.job_detail(
                    itemTemplateRequestReportDetail?.MaintenanceJobs[0].Id
                  )}
                />
              </Grid>
            </ProjectPaper>
          )}
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.id' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'warehouse.item_position' },
                { align: 'left', title: 'warehouse.current_amount_title' },
                { align: 'left', title: 'warehouse.request_item_amount_title' },
                { align: 'left', title: 'warehouse.pickup_amount_title' },
                { align: 'left', title: 'title.status' },
                { align: 'left', title: '' },
              ]}
              tableData={itemTemplateRequestReportDetail?.ItemTemplates.map(
                (itemTemplate) => {
                  const totalPickupItem = addItems.reduce((total, item) => {
                    if (item.Id === itemTemplate.Id) {
                      return total + parseFloat(item.Amount);
                    } else {
                      return total + 0;
                    }
                  }, 0);
                  return [
                    itemTemplate.Id,
                    <Link href={paths.general_warehouse.item_template_detail(itemTemplate.Id)}>
                      {itemTemplate.Name}
                    </Link>,
                    itemTemplate.InventoryStore.Name + `(${itemTemplate.InventoryShelf.Name})`,
                    itemTemplate.CurrentAmount,
                    itemTemplate.ItemTemplateRequestReportHasItemTemplates.Amount,
                    itemTemplateRequestReportDetail.CompletedAt === null
                      ? totalPickupItem
                      : translate('title.complete'),
                    itemTemplateRequestReportDetail.CompletedAt !== null ? (
                      <Label color={'success'}>{translate('title.complete')}</Label>
                    ) : totalPickupItem >=
                      itemTemplate.ItemTemplateRequestReportHasItemTemplates.Amount ? (
                      <Label color={'success'}>{translate('title.complete')}</Label>
                    ) : (
                      <Label color={'error'}>{translate('title.not_complete')}</Label>
                    ),
                    itemTemplateRequestReportDetail.CompletedAt === null &&
                      itemTemplateRequestReportDetail.CanceledAt === null && (
                        <IconButton onClick={() => handleSearchQRCode(itemTemplate.Id.toString())}>
                          <Iconify icon={'eva:plus-fill'} width={20} height={20} />
                        </IconButton>
                      ),
                  ];
                }
              )}
            />
          </ProjectPaper>
          {addItems.length > 0 && (
            <ProjectPaper>
              <Stack spacing={2}>
                <GeneralTable
                  tableHeaders={[
                    { align: 'left', title: '#' },
                    { align: 'left', title: 'title.name' },
                    { align: 'left', title: 'title.amount' },
                    { align: 'left', title: '' },
                  ]}
                  tableData={addItems.map((item, index) => {
                    return [
                      index + 1,
                      item.Name,
                      item.Amount,
                      <IconButton onClick={() => handleRemoveItem(index)}>
                        <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                      </IconButton>,
                    ];
                  })}
                />
                <Button
                  color={'primary'}
                  variant={'contained'}
                  fullWidth
                  onClick={handleSubmitItems}
                >
                  {translate('warehouse.request_item')}
                </Button>
              </Stack>
            </ProjectPaper>
          )}
          <RoleBasedGuard roles={[ManagementUserTypeId, StoreUserTypeId, AdminUserTypeId]}>
            {itemTemplateRequestReportDetail.CompletedAt === null && itemTemplateRequestReportDetail.CanceledAt === null && (
                <DataScanner
                  onScanCallback={handleSearchQRCode}
                  FormComponent={<ItemTemplateCodeForm onSubmitCallBack={handleSearchQRCode} />}
                />
              )}
          </RoleBasedGuard>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}
