// ----------------------------------------------------------------------

import {
  loginServerRequest,
  productionServerRequest,
  warehouseServerRequest,
} from '../utilities/Helper/NetworkHelper';
import jwtDecode from 'jwt-decode';
import { paths } from '../routes/paths';

export const decodeUserData = (accessToken) => {
  if (!accessToken) {
    return null;
  }
  return jwtDecode(accessToken);
};

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;
  expiredTimer = setTimeout(() => {
    alert('Token expired');
    const STORAGE_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY || 'accessToken';
    localStorage.removeItem(STORAGE_KEY);
    window.location.href = paths.login;
  }, timeLeft);
  clearTimeout(expiredTimer);


};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  const STORAGE_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY || 'accessToken';
  if (accessToken) {
    localStorage.setItem(STORAGE_KEY, accessToken);

    loginServerRequest.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    warehouseServerRequest.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    productionServerRequest.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem(STORAGE_KEY);

    delete loginServerRequest.defaults.headers.common.Authorization;
    delete warehouseServerRequest.defaults.headers.common.Authorization;
    delete productionServerRequest.defaults.headers.common.Authorization;
  }
};
