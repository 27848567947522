import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import AddressSelector from '../../forms/AddressSelector';
import { createSupplier } from '../../../requests/WarehouseServerHandler';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { createPartSupplier } from '../../../requests/ProductionServerHandler';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { supplierSchemaValidator } from '../../../utilities/Helper/FormValidator';
import { useMutation } from '@tanstack/react-query';

function CreateSupplierPage() {
  const { translate } = useLocales();

  const supplierTypeOptions = [
    {
      Id: 1,
      Name: translate('procurement.sell_material_supplier'),
    },
    {
      Id: 2,
      Name: translate('procurement.sell_parts_supplier'),
    },
  ];
  const createSupplierMutation = useMutation({
    mutationFn: createSupplier,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const createPartSupplierMutation = useMutation({
    mutationFn: createPartSupplier,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const SupplierDetailSchema = supplierSchemaValidator(translate);

  const methods = useForm({
    resolver: yupResolver(SupplierDetailSchema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const handleCreateSupplier = async (values) => {
    if (values.District !== null) {
      if (values.SupplierType.Id === 1) {
        await createSupplierMutation.mutateAsync({
          Name: values.Name,
          AdditionAddress: values.AdditionalAddress,
          DistrictId: values.District.Id,
          TaxRegistrationNumber: values.TaxRegistrationNumber,
          TelephoneNumber: values.TelephoneNumber,
          ContactName: values.ContactName,
        });
      } else {
        await createPartSupplierMutation.mutateAsync({
          Name: values.Name,
          AdditionAddress: values.AdditionalAddress,
          DistrictId: values.District.Id,
          TaxRegistrationNumber: values.TaxRegistrationNumber,
          TelephoneNumber: values.TelephoneNumber,
          ContactName: values.ContactName,
        });
      }
    } else {
      await OpenErrorNotification(translate('error.error_please_select_address'));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.create_supplier_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.create_supplier_title') },
      ]}
    >
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleCreateSupplier)}>
          <Stack spacing={3}>
            <RHFTextField name="Name" label={translate('title.name')} />
            <RHFTextField name="AdditionalAddress" label={translate('title.additional_address')} />
            <AddressSelector
              name="District"
              onDistrictChange={setValue}
              error={errors.District !== undefined}
            />
            <RHFTextField
              name="TaxRegistrationNumber"
              label={translate('title.tax_registration_number')}
            />
            <RHFTextField name="TelephoneNumber" label={translate('title.telephone_number')} />
            <RHFTextField name="ContactName" label={translate('procurement.contact_name_title')} />
            <RHFAutoCompleteShort
              name="SupplierType"
              label={translate('title.type')}
              data={supplierTypeOptions}
            />
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateSupplierPage;
