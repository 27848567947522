import mainModuleRouteDetails from "./RouteDetails/mainModuleRouteDetails";
import humanResourceRouteDetails from "./RouteDetails/humanResourceModuleRouteDetails";
import salesModuleRouteDetails from "./RouteDetails/salesModuleRouteDetails";
import accountingModuleRouteDetails from "./RouteDetails/accountingModuleRouteDetails";
import adminModuleRouteDetails from "./RouteDetails/adminModuleRouteDetails";
import productionModuleRouteDetails from "./RouteDetails/productionModuleRouteDetails";
import planningModuleRouteDetails from "./RouteDetails/planningModuleRouteDetails";
import qualityAssuranceModuleRouteDetails from "./RouteDetails/qualityAssuranceModuleRouteDetails";
import logisticModuleRouteDetails from "./RouteDetails/logisticModuleRouteDetails";
import generalWarehouseModuleRouteDetails from "./RouteDetails/generalWarehouseModuleRouteDetails";
import warehouseModuleRouteDetails from "./RouteDetails/warehouseModuleRouteDetails";
import procurementModuleRouteDetails from "./RouteDetails/procurementModuleRouteDetails";
import maintenanceModuleRouteDetails from "./RouteDetails/maintenanceModuleRouteDetails";
import managementModuleRouteDetails from "./RouteDetails/managementModuleRouteDetails";
import financeModuleRouteDetails from "./RouteDetails/financeModuleRouteDetails";
import {itModuleRouteDetails} from "./RouteDetails/itRouteDetail";
import productModuleRouteDetails from "./RouteDetails/productModuleRouteDetails";

const routeDetails = [
    ...mainModuleRouteDetails,
    ...humanResourceRouteDetails,
    ...productionModuleRouteDetails,
    ...qualityAssuranceModuleRouteDetails,
    ...logisticModuleRouteDetails,
    ...generalWarehouseModuleRouteDetails,
    ...warehouseModuleRouteDetails,
    ...procurementModuleRouteDetails,
    ...planningModuleRouteDetails,
    ...salesModuleRouteDetails,
    ...accountingModuleRouteDetails,
    ...adminModuleRouteDetails,
    ...maintenanceModuleRouteDetails,
    ...managementModuleRouteDetails,
    ...itModuleRouteDetails,
    ...financeModuleRouteDetails,
    ...productModuleRouteDetails
];

export default routeDetails;
