import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import PileScanner from '../barcode-reader/PileScanner';
import { OpenErrorNotification } from '../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../customs/ProjectPaper';
import { Grid, Stack } from '@mui/material';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import GridDetailText from '../customs/GridDetailText';
import Button from '@mui/material/Button';

ProductionAdjustPileForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

function ProductionAdjustPileForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const [editPile, setEditPile] = useState(null);
  const [adjustAmount, setAdjustAmount] = useState(0);

  const adjustPileSchema = Yup.object().shape({
    GoodAmount: Yup.number()
      .min(1, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
    DefectAmount: Yup.number()
      .min(0, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(adjustPileSchema),
  });

  const { handleSubmit, control, setValue } = methods;

  const goodAmountWatch = useWatch({
    control,
    name: 'GoodAmount',
    defaultValue: 0,
  });

  const defectAmountWatch = useWatch({
    control,
    name: 'DefectAmount',
    defaultValue: 0,
  });

  useEffect(() => {
    if (editPile !== null) {
      const totalOld =
        parseInt(editPile.GoodAmount) +
        parseInt(editPile.DefectAmount) +
        parseInt(editPile.AdjustAmount);

      const totalNew = parseInt(goodAmountWatch) + parseInt(defectAmountWatch);

      setAdjustAmount(totalNew - totalOld);
    }
  }, [defectAmountWatch, editPile, goodAmountWatch]);

  const handleSearchPile = async (pile) => {
    if (pile !== null || pile?.PileStatusId !== 7) {
      setEditPile(pile);
      setValue('GoodAmount', pile.GoodAmount);
      setValue('DefectAmount', pile.DefectAmount);
    } else {
      await OpenErrorNotification(translate('ขางานยกเลิกไปแล้ว'));
    }
  };

  const handleSubmitChangeSize = async (data) => {
    onSubmitCallback({
      Id: editPile.Id,
      GoodAmount: data.GoodAmount,
      DefectAmount: data.DefectAmount,
      AdjustAmount: adjustAmount,
    });
  };

  return (
    <div>
      {editPile !== null ? (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitChangeSize)}>
          <ProjectPaper>
            <Stack spacing={3}>
              <RHFTextField name={'GoodAmount'} label={translate('title.amount_good')} />
              <RHFTextField name={'DefectAmount'} label={translate('title.amount_defect')} />
              <Grid container spacing={3} sx={{ mb: 3 }}>
                <GridDetailText title={translate('title.diff_amount')} value={adjustAmount} />
              </Grid>
              <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
                {translate('button.change')}
              </Button>
            </Stack>
          </ProjectPaper>
        </FormProvider>
      ) : (
        <PileScanner
          onSearchCallBack={handleSearchPile}
          isPreFetchData
          isShowTooltips
        />
      )}
    </div>
  );
}

export default ProductionAdjustPileForm;
