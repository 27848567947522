import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import {
  OpenConfirmNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import CreateItAssetCategoryForm from '../../forms/CreateItAssetCategoryForm';
import { createAssetCategory } from '../../../requests/AdminServerHandler';
import useAuth from '../../../auth/hooks/useAuth';
import { useMutation } from '@tanstack/react-query';

function CreateItAssetCategoryPage() {
  const { translate } = useLocales();
  const { user } = useAuth();

  const createItAssetCategoryMutation = useMutation({
    mutationFn: createAssetCategory,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage(translate('success.create_asset_category'));
    },
    onError: async (error) => {
      await OpenNotificationThenReloadPage(error);
    },
  });
  const onCreateItAssetCategory = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_asset_category'),
      translate('button.confirm'),
      '',
      async () => {
        await createItAssetCategoryMutation.mutateAsync({
          name: values.Name,
          shortName: values.ShortName,
          staff_employee_id: user.EmployeeCode,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('it.create_asset_category')}
      breadcrumbs={[
        { name: translate('it.title') },
        { name: translate('it.create_asset_category') },
      ]}
    >
      <ProjectPaper>
        <CreateItAssetCategoryForm onSubmitValue={onCreateItAssetCategory} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateItAssetCategoryPage;
