import React from "react";
import {ProjectPageContainer} from "../../container/ProjectPageContainer";
import {getInventoryDetailById} from "../../../requests/ProductionServerHandler";
import ProjectPaper from "../../customs/ProjectPaper";
import {Box, Grid} from "@mui/material";
import GridDetailText from "../../customs/GridDetailText";
import {fDateTimeText} from "../../../utilities/formatTime";
import {useParams} from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocales } from '../../../locales';
import useTabs from '../../../hooks/useTabs';
import Iconify from '../../minimal/iconify';
import { useQuery } from '@tanstack/react-query';
import {paths} from '../../../routes/paths';

export default function InventoryDetailPage() {

  const {id} = useParams();
  const {translate} = useLocales();
  const {currentTab, onChangeTab} = useTabs("detail");

  const {data: inventoryDetail} = useQuery({
    queryKey: ['getInventoryDetailById', id],
    queryFn: () => getInventoryDetailById(id),
    initialData: null
  })

  const INFORMATION_TABS = [
    {
      value: "detail",
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20}/>,
      component: <ProjectPaper>
        <Grid container spacing={3}>
          <GridDetailText title={translate('title.id')} value={inventoryDetail?.Id}/>
          <GridDetailText title={translate('title.name')} value={inventoryDetail?.ItemTemplate.Name}
                          link={paths.general_warehouse.item_template_detail(inventoryDetail?.ItemTemplate.Id)}/>
          <GridDetailText title={translate('procurement.purchase_value_title')}
                          value={inventoryDetail?.Value}/>
          <GridDetailText title={translate('title.amount')} value={inventoryDetail?.TotalAmount}/>
          <GridDetailText title={translate('title.create_at')}
                          value={fDateTimeText(inventoryDetail?.createdAt)}/>
          <GridDetailText title={translate('warehouse.supplier_title')}
                          value={inventoryDetail?.Supplier.Name}
                          link={paths.procurement.part_supplier_detail(inventoryDetail?.Supplier.Id)}/>
          <GridDetailText title={translate('title.status')}
                          value={inventoryDetail?.InventoryStatus.Name}/>
        </Grid>
      </ProjectPaper>
    },
  ]

  return (
    <ProjectPageContainer menu_title={translate('warehouse.lot_detail')} breadcrumbs={[
      {name: translate('warehouse.parts')},
      {
        name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${inventoryDetail?.ItemTemplate.Id}`,
        href: paths.general_warehouse.item_template_detail(inventoryDetail?.ItemTemplate.Id)
      },
      {name: `${translate('warehouse.lot_detail')} ${translate('title.id')} : ${id}`}
    ]}>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {INFORMATION_TABS.map((tab, index) => (
          <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value}/>
        ))}
      </Tabs>
      <Box sx={{mb: 3}}/>
      {INFORMATION_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && tab.component;
      })}
    </ProjectPageContainer>)
};
