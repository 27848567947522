import React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllServicePurchaseOrder } from '../../../requests/WarehouseServerHandler';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import IconButton from '@mui/material/IconButton';
import Iconify from 'components/minimal/iconify';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

function ServicePurchaseOrderListPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { data: servicePO } = useQuery({
    queryKey: ['getAllServicePurchaseOrder'],
    queryFn: getAllServicePurchaseOrder,
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.procurement_service_purchase_order_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.procurement_service_purchase_order_title') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          data={servicePO.map((report) => {
            return {
              id: report.Id,
              maintenance_job_id: report.MaintenanceJobHasServicePurchaseOrders[0].MaintenanceJobId,
              reason: report.Reason,
              actions: report.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.maintenance_job_id'),
              accessorKey: 'maintenance_job_id',
              cell: (info) => (
                <Link href={paths.maintenance.job_detail(info.getValue())}>{info.getValue()}</Link>
              ),
            },
            {
              header: translate('title.detail'),
              accessorKey: 'reason',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    onClick={() => navigate(paths.procurement.accept_spo(info.getValue()))}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default ServicePurchaseOrderListPage;
