import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { getWarehouseShelfDetail } from '../../../requests/WarehouseServerHandler';
import { useQuery } from '@tanstack/react-query';
import { useLocales } from '../../../locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { Grid, IconButton } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import Stack from '@mui/material/Stack';
import ReactTable from '../../react-table/ReactTable';
import { paths } from '../../../routes/paths';
import Iconify from '../../minimal/iconify';
import { OPEN_ICON } from '../../../config-global';
import { fDateTimeTextShort } from '../../../utilities/formatTime';
import Link from "@mui/material/Link";

const WarehouseShelfDetailPage = () => {
  const { id } = useParams();
  const { translate } = useLocales();
  const { data: shelfDetail } = useQuery({
    queryKey: ['warehouseShelfDetail', id],
    queryFn: () => getWarehouseShelfDetail(id),
    initialData: null,
  });

  if (shelfDetail !== null) {
    return (
      <ProjectPageContainer
        menu_title={translate('warehouse.shelf_detail')}
        breadcrumbs={[
          { name: translate('warehouse.title') },
          { name: `${translate('warehouse.shelf_detail')} : ${id}` },
        ]}
      >
        <Stack spacing={2}>
          <ProjectPaper>
            <Grid container spacing={2}>
              <GridDetailText title={translate('title.id')} value={shelfDetail.Id} />
              <GridDetailText title={translate('title.name')} value={shelfDetail.Name} />
              <GridDetailText
                title={translate('warehouse.store')}
                value={shelfDetail.Warehouse.Name}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <ReactTable
              search
              data={shelfDetail?.Items.map((item) => {
                return {
                  id: item.Id,
                  create_at: item.createdAt,
                  barcode: item.BarcodeId,
                  lot_id: item.ItemLot.Id,
                  actions: item.Id,
                };
              })}
              header={[
                {
                  header: translate('title.id'),
                  accessorKey: 'id',
                },
                {
                  header: translate('warehouse.barcode_id'),
                  accessorKey: 'barcode',
                },
                {
                  header: translate('warehouse.lot'),
                  accessorKey: 'lot_id',
                  cell: (info) => (
                    <Link href={paths.warehouse.item_lot_detail(info.getValue())}>
                      {info.getValue()}
                    </Link>
                  ),
                },
                {
                  header: translate('title.create_at'),
                  accessorKey: 'create_at',
                  cell: (info) => fDateTimeTextShort(info.getValue()),
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      component={Link}
                      href={paths.warehouse.item_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </Stack>
      </ProjectPageContainer>
    );
  }
};

export default WarehouseShelfDetailPage;
