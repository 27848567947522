import { useLoadingQuery } from '../utility';
import { getBOMDetailById } from '../WarehouseServerHandler';

export const useBOM = (id) => {
  const { data } = useLoadingQuery({
    queryKey: ['bom-detail', id],
    queryFn: () => getBOMDetailById(id),
    initialData: null,
  });
  return {
    bomDetail: data,
  };
}