import React from 'react';
import Label from 'components/minimal/label';
import GridDetailText from '../../components/customs/GridDetailText';

export const getDetailTagString = (details) => {
  let returnString = '';
  details.forEach((detail, index) => {
    if (index === 0) {
      returnString = returnString.concat(detail.Name);
    } else {
      returnString = returnString.concat(', ', detail.Name);
    }
  });
  return returnString;
};

export const getItemInfoAmountText = (title, currentAmount, safetyAmount, unitName, theme) => {
  let storeAmountPercent = 1;
  if (safetyAmount > 0) {
    storeAmountPercent = currentAmount / safetyAmount;
  }
  if (storeAmountPercent >= 1) {
    return (
      <GridDetailText
        title={title}
        value={`${currentAmount} ${unitName}`}
        color={theme.palette.success.main}
      />
    );
  } else if (storeAmountPercent < 1 && storeAmountPercent > 0.5) {
    return (
      <GridDetailText
        title={title}
        value={`${currentAmount} ${unitName}`}
        color={theme.palette.warning.main}
      />
    );
  } else if (storeAmountPercent <= 0.5) {
    return (
      <GridDetailText
        title={title}
        value={`${currentAmount} ${unitName}`}
        color={theme.palette.error.main}
      />
    );
  } else {
    return <GridDetailText title={title} value={`${currentAmount} ${unitName}`} />;
  }
};

export const getTagString = (tags) => {
  let tagString = '';
  if (tags.length > 0) {
    tags.forEach((tag, index) => {
      tagString = tagString.concat(tag.Name);
      if (index < tags.length - 1) {
        tagString = tagString.concat(', ');
      }
    });
  } else {
    tagString = 'N/A';
  }
  return tagString;
};

export const getMaintenanceDetail = (detail, detailTags) => {
  let returnString = '';
  if (detail !== null) {
    returnString = returnString.concat(detail);
  }
  if (detailTags !== undefined && detailTags.length > 0) {
    returnString = returnString.concat(' ');
    returnString = returnString.concat(getDetailTagString(detailTags));
  }
  return returnString;
};

export const getMaintenanceJobStatusCard = (theme, translate, jobStatusId) => {
  let text = translate(`status.mt_status_${jobStatusId}`);
  let color = 'info';
  if (jobStatusId === 1) {
    color = 'info';
  }
  if (jobStatusId === 2) {
    color = 'info';
  }
  if (jobStatusId === 3) {
    color = 'warning';
  }
  if (jobStatusId === 4) {
    color = 'success';
  }
  if (jobStatusId === 5) {
    color = 'error';
  }
  if (jobStatusId === 6) {
    color = 'warning';
  }
  if (jobStatusId === 7) {
    color = 'error';
  }
  if (jobStatusId === 8) {
    color = 'warning';
  }

  return (
    <Label
      variant={theme === 'light' ? 'filled' : 'filled'}
      color={color}
      sx={{ textTransform: 'capitalize' }}
    >
      {text}
    </Label>
  );
};

export const getItJobStatusCard = (theme, translate, jobStatus) => {
  let text = translate(`status.mt_status_${jobStatus.name}`);
  let color = 'info';
  if (jobStatus.id === 1) {
    text = translate(`status.mt_status_1`);
    color = 'info';
  }
  if (jobStatus.id === 2) {
    text = translate(`status.mt_status_2`);
    color = 'info';
  }
  if (jobStatus.id === 3) {
    text = translate(`status.mt_status_4`);
    color = 'success';
  }
  if (jobStatus.id === 4) {
    text = translate(`status.mt_status_3`);
    color = 'warning';
  }
  if (jobStatus.id === 5) {
    text = translate(`status.mt_status_4`);
    color = 'success';
  }
  return (
    <Label
      variant={theme === 'light' ? 'filled' : 'filled'}
      color={color}
      sx={{ textTransform: 'capitalize' }}
    >
      {text}
    </Label>
  );
};
