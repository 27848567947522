import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import Image from 'components/minimal/image';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';

ItemContainerDetail.propTypes = {
  itemContainer: PropTypes.object.isRequired,
};

function ItemContainerDetail({ itemContainer }) {
  const { translate } = useLocales();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <ProjectPaper>
          <Image
            alt="large image"
            src={
              process.env.REACT_APP_IMAGE_END_POINT +
              process.env.REACT_APP_BUCKET_MASTER_IMAGE_FOLDER_NAME +
              process.env.REACT_APP_IMAGE_PREFIX +
              itemContainer?.ItemMaster.Id
            }
            ratio="1/1"
          />
        </ProjectPaper>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText
              title={translate('warehouse.item_master_name')}
              value={itemContainer?.ItemMaster.Name}
              link={paths.warehouse.item_master_detail(itemContainer?.ItemMaster.Id)}
            />
            <GridDetailText title={translate('title.name')} value={itemContainer?.Name} />
            <GridDetailText
              title={translate('title.type')}
              value={itemContainer?.ItemMaster.ItemType.Name}
            />
            <GridDetailText
              title={translate('title.detail')}
              value={itemContainer?.ItemMaster.Detail}
            />
            <GridDetailText title={translate('title.sku')} value={itemContainer?.SKU} />
            <GridDetailText
              title={translate('warehouse.amount_in_store_title')}
              value={itemContainer?.ItemLots.reduce((accumulator, itemLot) => {
                return accumulator + itemLot.Amount;
              }, 0)}
            />
            <GridDetailText
              title={translate('title.amount_defect')}
              value={`${itemContainer?.DefectAmount} (${
                itemContainer?.DefectAmount * itemContainer?.ItemMasterSize
              })`}
            />
            <GridDetailText
              title={translate('unit.title')}
              value={`${itemContainer?.ItemUnit.Name} ( 1 ${itemContainer?.ItemUnit.Name} = ${itemContainer?.ItemMasterSize} ${itemContainer?.ItemMaster.ItemUnit.Name})`}
            />
          </Grid>
        </ProjectPaper>
      </Grid>
    </Grid>
  );
}

export default ItemContainerDetail;
