import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import {
  AdminUserTypeId,
  allowToUploadUserTypeId,
  ManagementUserTypeId,
  ProcurementUserTypeId, StoreUserTypeId
} from "../../../utilities/Constants";
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import { useTheme } from '@mui/material/styles';
import EditableGridDetailText from '../../customs/EditableGridDetailText';
import GeneralTable from '../../customs/GeneralTable';
import LoadingComponent from '../../customs/LoadingComponent';
import { fCurrency } from '../../../utilities/formatNumber';
import useAuth from '../../../auth/hooks/useAuth';
import EditPOItemForm from '../../forms/EditPOItemForm';
import { usePurchaseOrder } from '../../../requests/query/usePurchaseOrder';
import { paths } from '../../../routes/paths';
import UploadFileForm from '../../forms/UploadFileForm';

function PurchaseOrderDetailPage() {
  const theme = useTheme();

  const { translate } = useLocales();
  const { user } = useAuth();
  const { id } = useParams();

  const {
    purchaseOrder,
    printPO,
    cancelPO,
    uploadApprove,
    editPOItems,
    editPODetail,
    editPOReferenceId,
    addVat,
    removeVat,
    removeItem,
  } = usePurchaseOrder(id);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editOrder, setEditOrder] = useState(null);

  const handlePrintPO = async () => {
    await printPO.mutateAsync(id);
  };
  const handleCancelPO = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancelPO.mutateAsync(id);
      },
    );
  };
  const uploadApproveDocument = async (values) => {
    await uploadApprove.mutateAsync({
      Id: id,
      Document: values.File[0],
      UploadEmployeeId: user.EmployeeId,
    });
  };
  const editOrderDetail = (order) => {
    setIsEditMode(true);
    setEditOrder(order);
  };

  const onRemoveItem = async (itemId) => {
    await removeItem.mutateAsync({
      PurchaseOrderId: id,
      ItemId: itemId,
    });
  }
  const handleEditPOItem = async (orderId, amount, buyPrice, detail) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_edit_data',
      async () => {
        await editPOItems.mutateAsync({
          PurchaseOrderItemContainerId: orderId,
          Amount: amount,
          BuyPrice: buyPrice,
          Detail: detail,
        });
        setIsEditMode(false);
        setEditOrder(null);
      },
    );
  };
  const onCancelEdit = async () => {
    setIsEditMode(false);
    setEditOrder(null);
  };
  const handleEditOrderDetail = async (detail) => {
    await editPODetail.mutateAsync({
      PurchaseOrderId: id,
      Detail: detail,
    });
  };
  const handleEditOrderReferenceId = async (referenceId) => {
    await editPOReferenceId.mutateAsync({
      PurchaseOrderId: id,
      ReferenceId: referenceId,
    });
  };

  const handleAddVat = async () => {
    await addVat.mutateAsync({
      PurchaseOrderId: id,
    });
  };
  const handleRemoveVat = async () => {
    await removeVat.mutateAsync({
      PurchaseOrderId: id,
    });
  };
  return (
    <ProjectPageContainer
      menu_title={translate('procurement.purchase_order_detail')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.purchase_order_list_title'),
          href: paths.procurement.po_list,
        },
        { name: `${translate('procurement.purchase_order_detail')} : ${id}` },
      ]}
      action={
        !isEditMode && (
          <Stack spacing={2} direction="row">
            <RoleBasedGuard roles={[ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId, StoreUserTypeId]}>
              {
                purchaseOrder !== null && purchaseOrder.Vat > 0 ?
                  <Button
                    variant="contained"
                    color={'error'}
                    startIcon={<Iconify icon={'material-symbols:money-off'} />}
                    onClick={handleRemoveVat}
                  >
                    {translate('button.remove_vat')}
                  </Button>
                  :
                  <Button
                    variant="contained"
                    color={'success'}
                    startIcon={<Iconify icon={'material-symbols:attach-money'} />}
                    onClick={handleAddVat}
                  >
                    {translate('button.add_vat')}
                  </Button>
              }
              <Button
                variant={'contained'}
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={handleCancelPO}
              >
                {translate('button.cancel')}
              </Button>
              <Button
                variant="contained"
                color={'info'}
                startIcon={<Iconify icon={'ic:outline-print'} />}
                onClick={handlePrintPO}
              >
                {translate('button.print_po')}
              </Button>
            </RoleBasedGuard>
          </Stack>
        )
      }
    >
      {isEditMode ? (
        <EditPOItemForm
          order={editOrder}
          unitName={editOrder.SupplierSellItemContainer.SellItemUnit.Name}
          name={editOrder.SupplierSellItemContainer.Name}
          onEditCallback={handleEditPOItem}
          onCancelCallback={onCancelEdit}
        />
      ) : purchaseOrder !== null ? (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={purchaseOrder.Id} />
              <GridDetailText
                title={translate('procurement.supplier')}
                value={purchaseOrder.Supplier.Name}
              />
              <RoleBasedGuard
                roles={[ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId]}
                children={
                  <EditableGridDetailText
                    title={translate('title.detail')}
                    onEdit={handleEditOrderDetail}
                    value={purchaseOrder.Detail}
                    isIgnoreCheck
                  />
                }
                otherComponent={
                  <GridDetailText title={translate('title.detail')} value={purchaseOrder.Detail} />
                }
              />
              <RoleBasedGuard
                roles={[ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId]}
                children={
                  <EditableGridDetailText
                    title={translate('procurement.po_reference_id_title')}
                    onEdit={handleEditOrderReferenceId}
                    value={purchaseOrder.ReferenceId}
                  />
                }
                otherComponent={
                  <GridDetailText
                    title={translate('procurement.po_reference_id_title')}
                    value={purchaseOrder.ReferenceId}
                  />
                }
              />
              <GridDetailText
                title={translate('title.value')}
                value={fCurrency(purchaseOrder.Value)}
              />
              <GridDetailText
                title={translate('procurement.discount')}
                value={fCurrency(purchaseOrder.Discount)}
              />
              <GridDetailText
                title={translate('procurement.delivery_price_title')}
                value={fCurrency(purchaseOrder.DeliveryPrice)}
              />
              <GridDetailText
                title={translate('procurement.vat_title')}
                value={fCurrency(purchaseOrder.Vat)}
              />
              <GridDetailText
                title={translate('title.create_by')}
                value={getEmployeeFullName(purchaseOrder.CreateEmployee)}
              />
              <GridDetailText
                title={translate('title.due_at')}
                value={fDateTimeText(purchaseOrder.DeliveredAt)}
              />
              {purchaseOrder.ArchivedAt !== null && (
                <GridDetailText
                  title={translate('title.status')}
                  value={translate('button.cancel')}
                  color={theme.palette.error.main}
                />
              )}
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.detail' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: 'procurement.price_per_unit' },
                { align: 'left', title: 'procurement.pr_id' },
                { align: 'left', title: '' },
              ]}
              tableData={purchaseOrder.PurchaseOrderItemContainers.map((item) => {
                console.log(item)
                return [
                  <Link
                    to={paths.warehouse.item_master_detail(
                      item.SupplierSellItemContainer.ItemMaster.Id,
                    )}
                  >
                    {item.SupplierSellItemContainer.Name}
                  </Link>,
                  item.Detail,
                  item.Amount,
                  item.SupplierSellItemContainer.SellItemUnit.Name,
                  item.BuyPrice,
                  <Link
                    to={paths.procurement.pr_detail(
                      item.PurchaseRequisitionItemMaster.PurchaseRequisitionId,
                    )}
                  >
                    {item.PurchaseRequisitionItemMaster.PurchaseRequisitionId}
                  </Link>,
                  item.IsCanceled ? 'ยกเลิก' :[
                    <IconButton onClick={() => editOrderDetail(item)}>
                      <Iconify icon={'ic:baseline-edit'} width={20} height={20} />
                    </IconButton>,
                    <RoleBasedGuard roles={[ManagementUserTypeId, ProcurementUserTypeId, AdminUserTypeId]}>
                      <IconButton onClick={() => onRemoveItem(item.Id)}>
                        <Iconify icon={'material-symbols:delete'} width={20} height={20} />
                      </IconButton>
                    </RoleBasedGuard>,
                  ],
                ];
              })}
            />
          </ProjectPaper>
          {purchaseOrder.ApproveAt !== null ? (
            <ProjectPaper>
              <Grid container spacing={3}>
                <GridDetailText
                  title={translate('title.approve_at')}
                  value={fDateTimeText(purchaseOrder.ApproveAt)}
                />
                {allowToUploadUserTypeId.includes(user.UserTypeId) ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        {translate('procurement.approve_document_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant={'contained'}
                        href={`https://sil-space.sgp1.digitaloceanspaces.com/ApprovePurchaseOrder/PO_Aprrove_${id}.pdf`}
                      >
                        {translate('procurement.open_document')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <GridDetailText
                    title={translate('procurement.approve_document_title')}
                    value={translate('procurement.already_upload')}
                  />
                )}
                <GridDetailText
                  title={translate('procurement.upload_by_title')}
                  value={getEmployeeFullName(purchaseOrder.UploadApproveEmployee)}
                />
              </Grid>
            </ProjectPaper>
          ) : (
            <RoleBasedGuard roles={allowToUploadUserTypeId}>
              <ProjectPaper>
                <UploadFileForm isImage onSubmitForm={uploadApproveDocument} />
              </ProjectPaper>
            </RoleBasedGuard>
          )}
        </React.Fragment>
      ) : (
        <LoadingComponent />
      )}
    </ProjectPageContainer>
  );
}

export default PurchaseOrderDetailPage;
