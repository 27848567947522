import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField } from '../minimal/hook-form';
import { Button, Stack } from '@mui/material';

CreateItAssetCategoryForm.propTypes = {
  onSubmitValue: PropTypes.func.isRequired,
};

function CreateItAssetCategoryForm({ onSubmitValue }) {
  const { translate } = useLocales();

  const createItAssetCategorySchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.detail_require')),
    ShortName: Yup.string().required(translate('error.detail_require')),
  });
  const defaultValue = {
    Name: '',
    ShortName: '',
  };

  const methods = useForm({
    resolver: yupResolver(createItAssetCategorySchema),
    defaultValues: defaultValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onSubmitValue(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name={'Name'} label={translate('title.name')} />
        <RHFTextField name={'ShortName'} label={translate('title.short_name')} />
        <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateItAssetCategoryForm;
