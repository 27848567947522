import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import Image from 'components/minimal/image';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from '../../../locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import { useTheme } from '@mui/material/styles';
import { paths } from '../../../routes/paths';

ItemDetail.propTypes = {
  itemDetail: PropTypes.object.isRequired,
};

function ItemDetail({ itemDetail }) {
  const { translate } = useLocales();
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <ProjectPaper>
          <Image
            alt="large image"
            src={
              process.env.REACT_APP_IMAGE_END_POINT +
              process.env.REACT_APP_BUCKET_MASTER_IMAGE_FOLDER_NAME +
              process.env.REACT_APP_IMAGE_PREFIX +
              itemDetail?.ItemLot.ItemContainer.ItemMaster.Id
            }
            ratio="1/1"
          />
        </ProjectPaper>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText
              title={translate('warehouse.item_master_name')}
              value={itemDetail?.ItemLot.ItemContainer.ItemMaster.Name}
              link={paths.warehouse.item_master_detail(
                itemDetail?.ItemLot.ItemContainer.ItemMaster.Id
              )}
            />
            <GridDetailText
              title={translate('warehouse.barcode_id')}
              value={itemDetail?.BarcodeId}
            />
            <GridDetailText
              title={translate('title.detail')}
              value={itemDetail?.ItemLot.ItemContainer.ItemMaster.Detail}
            />
            <GridDetailText
              title={translate('title.type')}
              value={itemDetail?.ItemLot.ItemContainer.ItemMaster.ItemType.Name}
            />
            <GridDetailText
              title={translate('warehouse.lot_id')}
              value={itemDetail?.ItemLot.Id}
              link={paths.warehouse.item_lot_detail(itemDetail?.ItemLot.Id)}
            />
            <GridDetailText
              title={translate('warehouse.container_detail_title')}
              value={itemDetail?.ItemLot.ItemContainer.Name}
              link={paths.warehouse.item_container_detail(itemDetail?.ItemLot.ItemContainer.Id)}
            />
            <GridDetailText
              title={translate('procurement.supplier')}
              value={itemDetail?.ItemLot.Supplier.Name}
              link={paths.procurement.supplier_detail(itemDetail?.ItemLot.Supplier.Id)}
            />
            <GridDetailText
              title={translate('warehouse.shelf')}
              value={itemDetail?.WarehouseShelf.Name}
              link={paths.warehouse.shelf_detail(itemDetail?.WarehouseShelf.Id)}
            />
            <GridDetailText
              title={translate('warehouse.remain_amount_title')}
              value={`${itemDetail?.RemainAmount} ${itemDetail?.ItemLot.ItemContainer.ItemMaster.ItemUnit.Name}`}
            />
            <GridDetailText
              title={translate('title.create_at')}
              value={fDateTimeText(itemDetail?.createdAt)}
            />
            <GridDetailText
              title={translate('title.update_at')}
              value={fDateTimeText(itemDetail?.updatedAt)}
            />
            <GridDetailText
              title={translate('title.status')}
              value={
                itemDetail?.WarehouseShelf.Id === 2
                  ? translate('status.break')
                  : translate('status.normal')
              }
              color={
                itemDetail?.WarehouseShelf.Id === 2
                  ? theme.palette.error.main
                  : theme.palette.primary.main
              }
            />
          </Grid>
        </ProjectPaper>
      </Grid>
    </Grid>
  );
}

export default ItemDetail;
