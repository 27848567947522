import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import FormProvider, { RHFTextField, RHFAutoCompleteShort } from 'components/minimal/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Button } from '@mui/material';
import { usePaymentType } from '../../requests/query/DropdownData';

ApproveCashBillForm.PropType = {
  onSubmitForm: PropTypes.func.isRequired,
};

function ApproveCashBillForm({ onSubmitForm }) {
  const { translate } = useLocales();
  const { paymentTypeOptions } = usePaymentType();

  const approveCashBillSchema = Yup.object().shape({
    PaymentType: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    PaymentReference: Yup.string().required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(approveCashBillSchema),
    defaultValues: {
      PaymentType: null,
      PaymentReference: '',
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    onSubmitForm(values);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFAutoCompleteShort
          name={'PaymentType'}
          label={translate('title.type')}
          data={paymentTypeOptions}
        />
        <RHFTextField name={'PaymentReference'} label={translate('finance.payment_reference')} />
        <Button variant={'contained'} type={'submit'} color={'primary'}>
          {translate('button.save')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default ApproveCashBillForm;
