import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import Iconify from 'components/minimal/iconify';
import GeneralTable from 'components/customs/GeneralTable';
import ProjectPaper from 'components/customs/ProjectPaper';
import { fDateTimeText } from 'utilities/formatTime';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';

PileProductionRecordDetail.propTypes = {
  productionRecords: PropTypes.array.isRequired,
};

function PileProductionRecordDetail({ productionRecords }) {
  const theme = useTheme();

  return (
    <ProjectPaper>
      <GeneralTable
        tableHeaders={[
          { align: 'left', title: '#' },
          { align: 'left', title: 'title.status' },
          { align: 'left', title: 'title.process' },
          { align: 'left', title: 'title.machine' },
          { align: 'left', title: 'title.amount' },
          { align: 'left', title: 'title.amount_good' },
          { align: 'left', title: 'title.amount_defect' },
          { align: 'left', title: 'title.complete_height' },
          { align: 'left', title: 'title.create_by' },
          { align: 'left', title: 'title.create_at' },
          { align: 'left', title: 'qa.approve_quality_by' },
          { align: 'left', title: 'qa.approve_quality_at' },
        ]}
        tableData={productionRecords.map((record, index) => [
          index + 1,
          record.CompletedAt !== null ? (
            <Iconify
              icon={'ic:baseline-check'}
              color={theme.palette.success.main}
              width={20}
              height={20}
            />
          ) : (
            <Iconify
              icon={'ic:baseline-close'}
              color={theme.palette.error.main}
              width={20}
              height={20}
            />
          ),
          record.ProductionProcess.Name,
          record.Machine !== null ? record.Machine.Name : '-',
          record.Amount,
          record.GoodAmount,
          record.DefectAmount,
          record.Height,
          getEmployeeFullName(record.CreateEmployee),
          fDateTimeText(record.CompletedAt),
          getEmployeeFullName(record.ApproveQualityEmployee),
          fDateTimeText(record.ApprovedQualityAt),
        ])}
      />
    </ProjectPaper>
  );
}

export default PileProductionRecordDetail;
