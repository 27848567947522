import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useLocales } from 'locales';
import ProjectPaper from 'components/customs/ProjectPaper';
import { createSpecialDiscount } from 'requests/ProductionServerHandler';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from 'utilities/Helper/NotificationHelper';
import { useMutation } from '@tanstack/react-query';
import CreateSpecialDiscountForm from 'components/forms/CreateSpecialDiscountForm';
import { paths } from 'routes/paths';

export default function CreateSpecialDiscountPage() {
  const { translate } = useLocales();

  const handleCreateSpecialDiscount = useMutation({
    mutationFn: createSpecialDiscount,
    onSuccess: async () => {
      await OpenSuccessNotification();
      window.location.reload();
    },
  });

  const handleSubmitForm = async (data) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_create_special_discount',
      async () => {
        await handleCreateSpecialDiscount.mutateAsync({
          CustomerId: data.Customer.Id,
          ProductId: data.Product.Id,
          Price: data.PricePerSheet,
          PricePerSquareInch: data.PricePerSquareInch,
          DurationId: data.Duration.Id,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('management.create_special_discount_title')}
      breadcrumbs={[
        { name: translate('management.title') },
        {
          name: translate('management.approve_special_price_history_title'),
          href: paths.management.special_discount_list,
        },
        { name: translate('management.create_special_discount_title') },
      ]}
    >
      <ProjectPaper>
        <CreateSpecialDiscountForm onSubmitCallback={handleSubmitForm} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}
