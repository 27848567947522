import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import Iconify from '../../minimal/iconify';
import Button from '@mui/material/Button';
import { paths } from 'routes/paths';
import { RouterLink } from '../../../routes/components';
import { useLocales } from '../../../locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import { OPEN_ICON } from '../../../config-global';
import GeneralTable from '../../customs/GeneralTable';
import { MaintenanceJobStatus } from '../../../utilities/Constants';

MaintenanceJobOutSourceDetail.propTypes = {
  jobDetail: PropTypes.object.isRequired,
  jobServicePO: PropTypes.array.isRequired,
  onRequestServiceCallback: PropTypes.func.isRequired,
};

function MaintenanceJobOutSourceDetail({ jobDetail, jobServicePO, onRequestServiceCallback }) {
  const { translate } = useLocales();
  return (
    <Card>
      <CardHeader
        action={
          <Button
            variant="contained"
            color={'primary'}
            startIcon={<Iconify icon={'ic:baseline-add'} />}
            onClick={onRequestServiceCallback}
            disabled={[
              MaintenanceJobStatus.WaitForAccept,
              MaintenanceJobStatus.Complete,
              MaintenanceJobStatus.WaitForEvaluation,
              MaintenanceJobStatus.Delete,
            ].includes(jobDetail?.MaintenanceJobStatus.Id)}
          >
            {translate('button.request_external_provider')}
          </Button>
        }
      />
      <CardContent>
        <GeneralTable
          tableHeaders={[
            { align: 'left', title: 'title.id' },
            { align: 'left', title: 'title.status' },
            { align: 'left', title: 'title.create_at' },
            'Actions',
          ]}
          tableData={jobServicePO?.map((supplierServiceReport) => {
            return [
              supplierServiceReport.ServicePurchaseOrder.Id,
              supplierServiceReport.ServicePurchaseOrder.CompletedAt !== null
                ? fDateTimeText(supplierServiceReport.CompletedAt)
                : 'ยังไม่เสร็จ',
              supplierServiceReport.ServicePurchaseOrder.createdAt !== null
                ? fDateTimeText(supplierServiceReport.createdAt)
                : 'ยังไม่เสร็จ',
              <IconButton
                key={supplierServiceReport.Id}
                component={RouterLink}
                to={paths.procurement.service_po_detail(supplierServiceReport.Id)}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>,
            ];
          })}
        />
      </CardContent>
    </Card>
  );
}

export default MaintenanceJobOutSourceDetail;
