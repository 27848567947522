import { getSupplierDetailById } from '../WarehouseServerHandler';
import { useQuery } from '@tanstack/react-query';
import { getPartSupplierDetailById } from '../ProductionServerHandler';

export const useSupplierDetail = (id) => {
  const { data, refetch } = useQuery({
    queryKey: ['getSupplierById', id],
    queryFn: () => getSupplierDetailById(id),
    initialData: null,
    enabled: id !== null,
  });
  return {
    supplierDetail: data,
    refetch,
  };
};

export const usePartSupplierDetail = (id) => {
  const { data, refetch } = useQuery({
    queryKey: ['getPartSupplierDetailById', id],
    queryFn: () => getPartSupplierDetailById(id),
    initialData: null,
    enabled: id !== null,
  });
  return {
    supplierDetail: data,
    refetch,
  };
};
