import React from 'react';
import { useLocales } from 'locales';
import { Button, Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { AdminUserTypeId, ManagementUserTypeId, SalesUserTypeId } from 'utilities/Constants';
import Iconify from 'components/minimal/iconify';
import ReactTable from 'components/react-table/ReactTable';
import { fDateTimeText } from 'utilities/formatTime';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { getAllCustomerProductOrderQuotation } from 'requests/ProductionServerHandler';
import { getPaperSizeText } from 'utilities/Helper/DataHelper';
import { fNumber } from 'utilities/formatNumber';
import { RouterLink } from 'routes/components';
import { useQuery } from '@tanstack/react-query';
import { OPEN_ICON } from 'config-global';
import { paths } from 'routes/paths';

function CustomerProductOrderQuotationDetail({ customerId }) {
  const { translate } = useLocales();

  const { data: allCustomerQuotation } = useQuery({
    queryKey: ['all-po-quotation', customerId],
    queryFn: () => getAllCustomerProductOrderQuotation(customerId),
    initialData: [],
  });

  return (
    <Card>
      <CardHeader
        title={translate('sales.customer_pricing')}
        action={
          <RoleBasedGuard roles={[AdminUserTypeId, ManagementUserTypeId, SalesUserTypeId]}>
            <Button
              variant="contained"
              startIcon={<Iconify icon={'ic:baseline-add'} />}
              color={'primary'}
              href={`${paths.sales.create_product_order_quotation}?customerId=${customerId}`}
            >
              {translate('sales.create_customer_pricing')}
            </Button>
          </RoleBasedGuard>
        }
      />
      <CardContent>
        <ReactTable
          data={allCustomerQuotation.map((quotation) => {
            return {
              id: quotation.Id,
              create_date: quotation.createdAt,
              expire_date: quotation.ExpiredAt,
              name: quotation.Name,
              create_by: quotation.CreatedEmployee,
              approve_employee: quotation.ApprovedEmployee,
              amount: quotation.Amount,
              size: {
                width: quotation.Width,
                length: quotation.Length,
              },
              actions: quotation.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
              size: 20,
            },
            {
              header: translate('title.expire_at'),
              accessorKey: 'expire_date',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'create_by',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('title.approve_status'),
              accessorKey: 'approve_employee',
              cell: (info) =>
                info.getValue() !== null ? (
                  <Typography variant="body1" sx={{ color: 'success.main' }}>
                    {translate('title.already_approve')}
                  </Typography>
                ) : (
                  <Typography variant="body1" sx={{ color: 'warning.main' }}>
                    {translate('title.wait_for_approve')}
                  </Typography>
                ),
            },
            {
              header: translate('title.amount'),
              accessorKey: 'amount',
              cell: (info) => fNumber(info.getValue()),
              size: 20,
            },
            {
              header: translate('title.size'),
              accessorKey: 'size',
              cell: (info) =>
                `${info.getValue().width} x ${info.getValue().length} (${getPaperSizeText(
                  info.getValue().width,
                  info.getValue().length
                )})`,
              size: 50,
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton
                  component={RouterLink}
                  href={paths.sales.product_order_quotation_detail(info.getValue())}
                >
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

export default CustomerProductOrderQuotationDetail;
