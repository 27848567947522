import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import ProjectPaper from 'components/customs/ProjectPaper';
import Button from '@mui/material/Button';
import { useLocales } from 'locales';

const SelectBillingDateForm = ({ billingDate, onSelectDateCallBack, onBackPressed }) => {
  const { translate } = useLocales();
  const [selectDate, setSelectDate] = useState(billingDate);

  const handleDateChange = (date) => {
    setSelectDate(date);
  };

  const onSelectBillingDate = () => {
    if (selectDate !== null) {
      onSelectDateCallBack(selectDate);
    }
  };

  return (
    <ProjectPaper>
      <MUIDateTimePicker
        ampm={false}
        inputFormat="dd/MM/yyyy HH:mm"
        label={translate('title.billing_at')}
        value={selectDate}
        onChange={handleDateChange}
        slotProps={{
          textField: {
            fullWidth: true,
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button color={'primary'} onClick={onBackPressed}>
          {translate('button.back')}
        </Button>
        <Button
          color={'primary'}
          disabled={selectDate === null}
          variant={'contained'}
          onClick={onSelectBillingDate}
        >
          {translate('button.next')}
        </Button>
      </Box>
    </ProjectPaper>
  );
};

SelectBillingDateForm.propTypes = {
  billingDate: PropTypes.object,
  onSelectDateCallBack: PropTypes.func.isRequired,
  onBackPressed: PropTypes.func.isRequired,
};

export default SelectBillingDateForm;
