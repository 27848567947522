import LoginPage from '../../components/pages/MainModule/LoginPage';
import LandingPage from '../../components/pages/MainModule/LandingPage';
import MainDashboardPage from '../../components/pages/MainModule/MainDashboardPage';
import UserProfilePage from '../../components/pages/MainModule/UserProfilePage';
import MaintenancePage from '../../components/pages/MainModule/MaintenancePage';
import { paths } from '../paths';
const mainModuleRouteDetails = [
  {
    path: paths.landing,
    component: <LandingPage />,
    isPrivate: false,
  },
  {
    path: paths.login,
    component: <LoginPage />,
    isPrivate: false,
  },
  {
    path: paths.maintenance_page,
    component: <MaintenancePage />,
    isPrivate: false,
  },
  {
    path: paths.dashboard.main,
    component: <MainDashboardPage />,
    isPrivate: true,
  },
  {
    path: paths.user,
    component: <UserProfilePage />,
    isPrivate: true,
  },
];
export default mainModuleRouteDetails;
