const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
  ACCOUNTING: '/accounting',
  PRODUCTION: '/production',
  LOGISTIC: '/logistic',
  WAREHOUSE: '/warehouse',
  GENERAL_WAREHOUSE: '/general-warehouse',
  MAINTENANCE: '/maintenance',
  MANAGEMENT: '/management',
  SALES: '/sales',
  FINANCE: '/finance',
  HR: '/hr',
  QA: '/quality',
  PLANNING: '/planning',
  PRODUCT: '/product',
  PROCUREMENT: '/procurement',
  ADMIN: '/admin',
  IT: '/it',
};

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  maintenance_page: '/maintenance_page',
  landing: '/',
  login: '/login',
  user: '/user',
  quick_search: '/quick-search',
  quick_request: '/quick-request',
  management: {
    root: ROOTS.MANAGEMENT,
    dashboard: `${ROOTS.MANAGEMENT}/dashboard`,
    special_discount_list: `${ROOTS.MANAGEMENT}/special-discount/list`,
    create_special_discount: `${ROOTS.MANAGEMENT}/special-discount/create`,
  },
  sales: {
    root: ROOTS.SALES,
    dashboard: `${ROOTS.SALES}/dashboard`,
    customer_list: `${ROOTS.SALES}/customer/list`,
    customer_detail: (id) => `${ROOTS.SALES}/customer/detail/${id}`,
    create_product_order: `${ROOTS.SALES}/product-order/create`,
    print_customer_report: `${ROOTS.SALES}/print-customer-report`,
    print_sales_value_report: `${ROOTS.SALES}/print-sales-value-report`,
    create_additional_cost: `${ROOTS.SALES}/additional-cost/create`,
    create_customer_pricing: `${ROOTS.SALES}/customer-pricing/create`,
    customer_pricing_detail: (id) => `${ROOTS.SALES}/customer-pricing/detail/${id}`,
    create_product_order_quotation: `${ROOTS.SALES}/po-quotation/create`,
    product_order_quotation_detail: (id) => `${ROOTS.SALES}/po-quotation/detail/${id}`,
    create_discount_bill: `${ROOTS.SALES}/discount-bill/create`,
    active_quotation_list: `${ROOTS.SALES}/po-quotation/all`,
    search: `${ROOTS.SALES}/search`,
    create_order_from_logistic: `${ROOTS.SALES}/create-order-from-logistic`,
    create_order_from_logistic_by_id: (id) => `${ROOTS.SALES}/logistic-order/create/${id}`,
  },
  accounting: {
    root: `${ROOTS.ACCOUNTING}`,
    create_customer: `${ROOTS.ACCOUNTING}/customer/create`,
    create_billing_note: `${ROOTS.ACCOUNTING}/billing-note/create`,
    billing_note_detail: (id) => `${ROOTS.ACCOUNTING}/billing-note/detail/${id}`,
    search: `${ROOTS.ACCOUNTING}/search`,
    print_delivery_bill_report: `${ROOTS.ACCOUNTING}/print-delivery-bill-report`,
    print_product_sell_report: `${ROOTS.ACCOUNTING}/print-product-sell-report`,
    create_claim_record: `${ROOTS.ACCOUNTING}/claim-record/create`,
    archive_customer: `${ROOTS.ACCOUNTING}/customer/archive`,
    delivery_bill_detail: (id) => `${ROOTS.ACCOUNTING}/delivery-bill/detail/${id}`,
    create_product_order_additional_cost_bill: `${ROOTS.ACCOUNTING}/product-order-additional-cost-bill/create`,
    product_order_additional_cost_bill_detail: (id) =>
      `${ROOTS.ACCOUNTING}/product-order-additional-cost-bill/detail/${id}`,
  },
  finance: {
    root: `${ROOTS.FINANCE}`,
    cash_delivery_bill_list: `${ROOTS.FINANCE}/cash-delivery-bill/list`,
    search: `${ROOTS.FINANCE}/search`,
    print_cash_delivery_bill_summary_report: `${ROOTS.FINANCE}/cash-delivery-bill/report`,
    cash_delivery_bill_detail: (id) => `${ROOTS.FINANCE}/cash-delivery-bill/detail/${id}`,
  },
  production: {
    root: `${ROOTS.PRODUCTION}`,
    dashboard: `${ROOTS.PRODUCTION}/dashboard`,
    employee_dashboard: `${ROOTS.PRODUCTION}/employee-dashboard`,
    employee_performance: `${ROOTS.PRODUCTION}/employee-performance`,
    submit_production_record: `${ROOTS.PRODUCTION}/production-record/submit`,
    product_order_list: `${ROOTS.PRODUCTION}/product-order/list`,
    product_order_detail: (id) => `${ROOTS.PRODUCTION}/product-order/detail/${id}`,
    pile_detail: (id) => `${ROOTS.PRODUCTION}/pile/detail/${id}`,
    create_production_process: `${ROOTS.PRODUCTION}/production-process/create`,
    machine_production_report: `${ROOTS.PRODUCTION}/machine-production-report`,
    search: `${ROOTS.PRODUCTION}/search`,
    create_pile: `${ROOTS.PRODUCTION}/pile/create`,
    machine_dashboard_detail: (id) => `${ROOTS.PRODUCTION}/machine_dashboard/detail/${id}`,
    create_product_order_problem: `${ROOTS.PRODUCTION}/product-order-problem/create`,
    solve_production_problem: `${ROOTS.PRODUCTION}/product-order-problem/solve`,
    search_product_order_result: `${ROOTS.PRODUCTION}/search-product-order-result`,
    rework_order_detail: (id) => `${ROOTS.PRODUCTION}/rework-order/detail/${id}`,
    create_pile_from_logistic_pile: `${ROOTS.PRODUCTION}/pile/create-from-logistic-pile`,
    approve_change_price_detail: (id) => `${ROOTS.PRODUCTION}/approve-change-price/detail/${id}`,
  },
  product: {
    root: `${ROOTS.PRODUCT}`,
    product_list: `${ROOTS.PRODUCT}/list`,
    product_bom_list: `${ROOTS.PRODUCT}/bom/list`,
    create_product: `${ROOTS.PRODUCT}/create`,
    create_bom: `${ROOTS.PRODUCT}/bom/create`,
    product_detail: (id) => `${ROOTS.PRODUCT}/detail/${id}`,
    create_material: `${ROOTS.PRODUCT}/material/create`,
    material_list: `${ROOTS.PRODUCT}/material/list`,
    material_detail: (id) => `${ROOTS.PRODUCT}/material/detail/${id}`,
    bom_detail: (id) => `${ROOTS.PRODUCT}/bom/detail/${id}`,
  },
  hr: {
    root: `${ROOTS.HR}`,
    employee_detail: (id) => `${ROOTS.HR}/employee/detail/${id}`,
    employee_list: `${ROOTS.HR}/employee/list`,
    add_employee: `${ROOTS.HR}/employee/create`,
    remove_employee: `${ROOTS.HR}/employee/remove`,
    add_penalty_record: `${ROOTS.HR}/penalty-record/create`,
    add_employee_record: `${ROOTS.HR}/employee-record/create`,
  },
  logistic: {
    root: `${ROOTS.LOGISTIC}`,
    create_car: `${ROOTS.LOGISTIC}/car/create`,
    create_delivery_bill: `${ROOTS.LOGISTIC}/delivery-bill/create`,
    car_time_stamp: `${ROOTS.LOGISTIC}/car-time-stamp`,
  },
  warehouse: {
    root: `${ROOTS.WAREHOUSE}`,
    dashboard: `${ROOTS.WAREHOUSE}/dashboard`,
    store: `${ROOTS.WAREHOUSE}/store`,
    accept_items: `${ROOTS.WAREHOUSE}/item/accept`,
    add_items: `${ROOTS.WAREHOUSE}/item/add`,
    release_items: `${ROOTS.WAREHOUSE}/item/release`,
    return_items: `${ROOTS.WAREHOUSE}/item/return`,
    create_unit: `${ROOTS.WAREHOUSE}/item/create-unit`,
    create_master: `${ROOTS.WAREHOUSE}/item/create-master`,
    create_inventory: `${ROOTS.WAREHOUSE}/inventory/create`,
    create_shelf: `${ROOTS.WAREHOUSE}/shelf/create`,
    search: `${ROOTS.WAREHOUSE}/search`,
    move_items: `${ROOTS.WAREHOUSE}/item/move`,
    print_warehouse_data: `${ROOTS.WAREHOUSE}/print-warehouse-data`,
    print_stock_transaction: `${ROOTS.WAREHOUSE}/print-stock-transaction`,
    manage_safety_stock: `${ROOTS.WAREHOUSE}/manage-safety-stock`,
    item_requisition_report_detail: (id) =>
      `${ROOTS.WAREHOUSE}/item-requisition-report/detail/${id}`,
    item_master_detail: (id) => `${ROOTS.WAREHOUSE}/item-master/detail/${id}`,
    item_container_detail: (id) => `${ROOTS.WAREHOUSE}/item-container/detail/${id}`,
    item_lot_detail: (id) => `${ROOTS.WAREHOUSE}/item-lot/detail/${id}`,
    item_receipt_report_detail: (id) => `${ROOTS.WAREHOUSE}/item-receipt-report/detail/${id}`,
    item_detail: (id) => `${ROOTS.WAREHOUSE}/item/detail/${id}`,
    shelf_detail: (id) => `${ROOTS.WAREHOUSE}/shelf/detail/${id}`,
    upload_item_price: `${ROOTS.WAREHOUSE}/upload-item-price`,
    admin_create_lot: `${ROOTS.WAREHOUSE}/admin-create-lot`,
    return_film: `${ROOTS.WAREHOUSE}/return-film`,
    create_container: `${ROOTS.WAREHOUSE}/container/create`,
    check_out_items: `${ROOTS.WAREHOUSE}/item/check-out`,
    accept_store_items: (id) => `${ROOTS.WAREHOUSE}/item/accept-store/${id}`,
  },
  general_warehouse: {
    root: `${ROOTS.GENERAL_WAREHOUSE}`,
    store: `${ROOTS.GENERAL_WAREHOUSE}/store`,
    search: `${ROOTS.GENERAL_WAREHOUSE}/search`,
    quick_request: `${ROOTS.GENERAL_WAREHOUSE}/quick-request`,
    add_items: `${ROOTS.GENERAL_WAREHOUSE}/item/add`,
    release_items: `${ROOTS.GENERAL_WAREHOUSE}/item/release`,
    accept_items: `${ROOTS.GENERAL_WAREHOUSE}/item/accept`,
    return_items: `${ROOTS.GENERAL_WAREHOUSE}/item/return`,
    move_items: `${ROOTS.GENERAL_WAREHOUSE}/item/move`,
    print_warehouse_data: `${ROOTS.GENERAL_WAREHOUSE}/print-warehouse-data`,
    print_stock_transaction: `${ROOTS.GENERAL_WAREHOUSE}/print-stock-transaction`,
    manage_safety_stock: `${ROOTS.GENERAL_WAREHOUSE}/manage-safety-stock`,
    create_master: `${ROOTS.GENERAL_WAREHOUSE}/item/create-master`,
    create_unit: `${ROOTS.GENERAL_WAREHOUSE}/item/create-unit`,
    create_inventory: `${ROOTS.GENERAL_WAREHOUSE}/inventory/create`,
    create_shelf: `${ROOTS.GENERAL_WAREHOUSE}/shelf/create`,
    inventory_detail: (id) => `${ROOTS.GENERAL_WAREHOUSE}/inventory/detail/${id}`,
    item_template_detail: (id) => `${ROOTS.GENERAL_WAREHOUSE}/item-template/detail/${id}`,
    shelf_detail: (id) => `${ROOTS.GENERAL_WAREHOUSE}/shelf/detail/${id}`,
    request_report_detail: (id) => `${ROOTS.GENERAL_WAREHOUSE}/request-report/detail/${id}`,
    stock_report_detail: (id) => `${ROOTS.GENERAL_WAREHOUSE}/stock-report/detail/${id}`,
    check_out_items: `${ROOTS.GENERAL_WAREHOUSE}/item/check-out`,
  },
  maintenance: {
    root: ROOTS.MAINTENANCE,
    dashboard: `${ROOTS.MAINTENANCE}/dashboard`,
    machine_dashboard: `${ROOTS.MAINTENANCE}/machine-dashboard`,
    create_job: `${ROOTS.MAINTENANCE}/job/create`,
    job_detail: (id) => `${ROOTS.MAINTENANCE}/job/detail/${id}`,
    evaluate_job: (id) => `${ROOTS.MAINTENANCE}/job/evaluate/${id}`,
    personal_dashboard: `${ROOTS.MAINTENANCE}/personal-dashboard`,
    job_list: `${ROOTS.MAINTENANCE}/job/list`,
    search: `${ROOTS.MAINTENANCE}/search`,
    print_job_report: `${ROOTS.MAINTENANCE}/print-job-report`,
  },
  procurement: {
    root: ROOTS.PROCUREMENT,
    search: `${ROOTS.PROCUREMENT}/search`,
    material_purchase_store: `${ROOTS.PROCUREMENT}/material-purchase-store`,
    part_purchase_store: `${ROOTS.PROCUREMENT}/part-purchase-store`,
    create_parts_purchase_requisition: `${ROOTS.PROCUREMENT}/parts-purchase-requisition/create`,
    create_purchase_requisition: `${ROOTS.PROCUREMENT}/purchase-requisition/create`,
    pr_list: `${ROOTS.PROCUREMENT}/purchase-requisition/list`,
    parts_pr_list: `${ROOTS.PROCUREMENT}/parts-purchase-requisition/list`,
    po_list: `${ROOTS.PROCUREMENT}/purchase-order/list`,
    parts_po_list: `${ROOTS.PROCUREMENT}/parts-purchase-order/list`,
    create_po: `${ROOTS.PROCUREMENT}/purchase-order/create`,
    create_parts_po: `${ROOTS.PROCUREMENT}/parts-purchase-order/create`,
    create_supplier: `${ROOTS.PROCUREMENT}/supplier/create`,
    supplier_list: `${ROOTS.PROCUREMENT}/supplier/list`,
    create_part_supplier: `${ROOTS.PROCUREMENT}/part-supplier/create`,
    supplier_parts_list: `${ROOTS.PROCUREMENT}/part-supplier/list`,
    create_spo: `${ROOTS.PROCUREMENT}/service-po/create`,
    spo_list: `${ROOTS.PROCUREMENT}/service-po/list`,
    print_supplier_report: `${ROOTS.PROCUREMENT}/print-supplier-report`,
    part_supplier_detail: (id) => `${ROOTS.PROCUREMENT}/part-supplier/detail/${id}`,
    service_po_detail: (id) => `${ROOTS.PROCUREMENT}/service-po/detail/${id}`,
    create_supplier_sell_item: `${ROOTS.PROCUREMENT}/supplier-sell-item/create`,
    create_supplier_sell_item_template: `${ROOTS.PROCUREMENT}/supplier-sell-item-template/list`,
    search_item_master_to_create_sell_container: (query) =>
      `${ROOTS.PROCUREMENT}/search-item-master-to-create-sell-container/${query}`,
    search_item_template_to_create_sell_template: (query) =>
      `${ROOTS.PROCUREMENT}/search-item-template-to-create-sell-template/${query}`,
    edit_supplier_sell_item: (id) => `${ROOTS.PROCUREMENT}/supplier-sell-item/edit/${id}`,
    edit_supplier_sell_item_template: (id) =>
      `${ROOTS.PROCUREMENT}/supplier-sell-item-template/edit/${id}`,
    store: `${ROOTS.PROCUREMENT}/store`,
    part_store: `${ROOTS.PROCUREMENT}/part-store`,
    pr_detail: (id) => `${ROOTS.PROCUREMENT}/purchase-requisition/detail/${id}`,
    parts_pr_detail: (id) => `${ROOTS.PROCUREMENT}/parts-purchase-requisition/detail/${id}`,
    check_out_po: `${ROOTS.PROCUREMENT}/purchase-order/check-out`,
    check_out_part_po: `${ROOTS.PROCUREMENT}/parts-purchase-order/check-out`,
    check_out_supplier_po: (id) => `${ROOTS.PROCUREMENT}/supplier/purchase-order/check-out/${id}`,
    check_out_part_supplier_po: (id) =>
      `${ROOTS.PROCUREMENT}/supplier/part-purchase-order/check-out/${id}`,
    po_detail: (id) => `${ROOTS.PROCUREMENT}/purchase-order/detail/${id}`,
    parts_po_detail: (id) => `${ROOTS.PROCUREMENT}/parts-purchase-order/detail/${id}`,
    accept_spo: (id) => `${ROOTS.PROCUREMENT}/service-po/accept/${id}`,
    supplier_detail: (id) => `${ROOTS.PROCUREMENT}/supplier/detail/${id}`,
    purchase_order_detail: (id) => `${ROOTS.PROCUREMENT}/purchase-order/detail/${id}`,
    search_supplier_sell_item: (id) => `${ROOTS.PROCUREMENT}/search-supplier-sell-item/${id}`,
    search_supplier_sell_item_template: (id) =>
      `${ROOTS.PROCUREMENT}/search-supplier-sell-item-template/${id}`,
  },
  qa: {
    root: ROOTS.QA,
    dashboard: `${ROOTS.QA}/dashboard`,
    quality_dashboard: `${ROOTS.QA}/quality_dashboard`,
    all_active_problem: `${ROOTS.QA}/all-active-problem`,
    approve_quality: `${ROOTS.QA}/approve-quality`,
    hold_pile: `${ROOTS.QA}/hold-pile`,
    un_hold_pile: `${ROOTS.QA}/un-hold-pile`,
    quality_problem_detail: (id) => `${ROOTS.QA}/quality-problem/detail/${id}`,
    adjust_pile_amount: `${ROOTS.QA}/adjust-pile-amount`,
    report_product_order_problem: `${ROOTS.QA}/product-order-problem/report`,
    approve_quality_process: `${ROOTS.QA}/approve-quality-process`,
  },
  planning: {
    root: ROOTS.PLANNING,
    customer_list: `${ROOTS.PLANNING}/customer/list`,
    dashboard: `${ROOTS.PLANNING}/dashboard`,
    report_product_order_problem: `${ROOTS.PLANNING}/product-order-problem/report`,
    production_planning: `${ROOTS.PLANNING}/production-planning`,
    production_planning_old: `${ROOTS.PLANNING}/production-planning_old`,
    approve_exceed_amount: `${ROOTS.PLANNING}/approve-exceed-amount`,
    create_rework_order: `${ROOTS.PLANNING}/rework-order/create`,
    edit_pile_reference: `${ROOTS.PLANNING}/pile/edit`,
    print_report_planning: `${ROOTS.PLANNING}/print-report-planning-title`,
    edit_pile_good_amount_page: `${ROOTS.PLANNING}/edit-pile-good-amount`,
    machine_queue_detail: (id) => `${ROOTS.PLANNING}/machine-queue/detail/${id}`,
    add_quality_process_page: `${ROOTS.PLANNING}/add-quality-process`,
    edit_size: `${ROOTS.PLANNING}/edit-size`,
  },
  it: {
    root: ROOTS.IT,
    create_it_job: `${ROOTS.IT}/it-job/create`,
    it_job_list: `${ROOTS.IT}/it-job/list`,
    create_it_asset: `${ROOTS.IT}/it-asset/create`,
    create_it_asset_category: `${ROOTS.IT}/it-asset-category/create`,
    it_job_detail: (id) => `${ROOTS.IT}/it-job/detail/${id}`,
    create_user: `${ROOTS.IT}/user/create`,
    change_user_password: `${ROOTS.IT}/user/change-password`,
  },
  admin: {
    root: ROOTS.ADMIN,
    edit_pile_amount: `${ROOTS.ADMIN}/edit-pile-amount`,
    edit_product_order_amount: `${ROOTS.ADMIN}/edit-product-order-amount`,
    edit_pile_good_amount: `${ROOTS.ADMIN}/edit-pile-good-amount`,
    edit_size: `${ROOTS.ADMIN}/edit-size`,
    edit_price: `${ROOTS.ADMIN}/edit-price`,
    edit_po_price: `${ROOTS.ADMIN}/edit-po-price`,
    change_price_to_bundle: `${ROOTS.ADMIN}/change-price-to-bundle`,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    main: `${ROOTS.DASHBOARD}/main`,
  },
};
