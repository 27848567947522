import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import GeneralTable from '../../customs/GeneralTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import { useLocales } from '../../../locales';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import ProductOrderProblemDetailList from './ProductOrderProblemDetailList';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';

ProductOrderProblemDetail.propTypes = {
  piles: PropTypes.array,
  productOrderId: PropTypes.string.isRequired,
};

function ProductOrderProblemDetail({ piles, productOrderId }) {
  const [reworkPiles, setReworkPiles] = useState([]);
  const [qualityProblems, setQualityProblems] = useState([]);
  const { translate } = useLocales();
  useEffect(() => {
    const allReworkPile = [];
    const allQualityProblem = [];
    piles.forEach((pile) => {
      allReworkPile.push(...pile.ReworkOrderPiles);
      allQualityProblem.push(...pile.PileQualityProblemHistories);
    });
    setReworkPiles(allReworkPile);
    setQualityProblems(allQualityProblem);
  }, [piles]);
  return (
    <div>
      <ProductOrderProblemDetailList productOrderId={productOrderId} />
      <Card sx={{ mt: 3 }}>
        <CardHeader title={translate('production.rework_order')} />
        <CardContent>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: 'title.id' },
              { align: 'left', title: 'production.rework_order_id' },
              { align: 'left', title: 'title.create_at' },
              { align: 'left', title: '' },
            ]}
            tableData={reworkPiles.map((reworkPile) => [
              reworkPile.PileId,
              reworkPile.ReworkOrderId,
              fDateTimeText(reworkPile.createdAt),
              <IconButton
                key={reworkPile.Id}
                component={RouterLink}
                href={paths.production.rework_order_detail(reworkPile.ReworkOrderId)}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>,
            ])}
          />
        </CardContent>
      </Card>
      <Card sx={{ mt: 3 }}>
        <CardHeader title={translate('qa.pile_quality_problem')} />
        <CardContent>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: 'production.pile_id' },
              { align: 'left', title: 'title.problem' },
              { align: 'left', title: 'title.create_at' },
              { align: 'left', title: 'title.check_by' },
              { align: 'left', title: 'title.solve_at' },
              { align: 'left', title: '' },
            ]}
            tableData={qualityProblems.map((problem) => [
              problem.PileId,
              problem.QualityProblem.Name,
              fDateTimeText(problem.createdAt),
              getEmployeeFullName(problem.HoldEmployee),
              fDateTimeText(problem.UnHoldAt),
              <IconButton
                key={problem.Id}
                component={RouterLink}
                to={paths.qa.quality_problem_detail(problem.Id)}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>,
            ])}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default ProductOrderProblemDetail;
