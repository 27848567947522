import PropTypes from 'prop-types';
import { FormProvider as Form } from 'react-hook-form';

// ----------------------------------------------------------------------

export default function FormProvider({ children, onSubmit, methods }) {
  const onKeyDown = (event) => {
    // Prevent form submit on Enter key press
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Form {...methods} onKeyDown={onKeyDown}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}

FormProvider.propTypes = {
  children: PropTypes.node,
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
};
