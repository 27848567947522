import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, CardHeader, Chip, Divider, Stack } from '@mui/material';
import { useLocales } from 'locales';
import Typography from '@mui/material/Typography';
import { fNumber } from '../../../utilities/formatNumber';
import { useTheme } from '@mui/material/styles';
import Label from 'components/minimal/label';

OverallProductOrderDetailCard.propTypes = {
  productOrderDetail: PropTypes.object.isRequired,
};

function OverallProductOrderDetailCard({ productOrderDetail }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const openProductOrderDetail = async () => {};

  const getLabel = () => {
    const isComplete = productOrderDetail.CompletedAt !== null;
    let color = 'error';
    let text = translate('status.not_in_plan');
    if (isComplete) {
      color = 'success';
      text = translate('status.pile_status_4');
    } else {
      if (productOrderDetail.ProductOrderPlannings.length > 0) {
        color = 'warning';
        text = translate('status.in_plan');
      }
    }

    return (
      <Label
        variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
        color={color}
        sx={{ textTransform: 'capitalize' }}
      >
        {text}
      </Label>
    );
  };
  return (
    <Card>
      <CardHeader
        title={`${translate('title.id')}: ${productOrderDetail.Id}`}
        titleTypographyProps={{ onClick: () => openProductOrderDetail() }}
        subheader={
          <Stack>
            <Typography>{productOrderDetail.Customer.Name}</Typography>
            <Typography>{productOrderDetail.Name}</Typography>
            <Stack spacing={2} direction="row">
              <Typography>{`${translate('title.size')}:`}</Typography>
              <Typography sx={{ textAlign: 'right' }}>{`${productOrderDetail.Width} x ${
                productOrderDetail.Length
              } ${translate('unit.inch')}`}</Typography>
            </Stack>
            <Stack spacing={2} direction="row">
              <Typography>{`${translate('title.amount')}:`}</Typography>
              <Typography sx={{ textAlign: 'right' }}>
                {fNumber(productOrderDetail.Amount)}
              </Typography>
            </Stack>
          </Stack>
        }
        action={getLabel()}
      />
      <CardContent>
        <Divider orientation="horizontal" sx={{ mb: 2 }} />
        <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
          {productOrderDetail.ProductSellHistories.map((sellHistory) => {
            return sellHistory.Product.ProductionProcesses.map((process) => {
              const plans = productOrderDetail.ProductOrderPlannings.filter(
                (plan) =>
                  plan.ProductHasProductionProcessId === process.ProductHasProductionProcess.Id
              );
              return (
                <Stack
                  key={`${sellHistory.Id}-${sellHistory.Product.Id}-${process.Id}`}
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  sx={{ alignContent: 'center' }}
                >
                  <Typography sx={{ p: 1, textAlign: 'left' }}>{process.Name}</Typography>
                  {plans.length > 0 && (
                    <Box sx={{ p: 0.5 }}>
                      {plans.map((planWithMachine) => {
                        return (
                          <Chip
                            sx={{ mr: 1 }}
                            label={
                              planWithMachine.Machine !== null
                                ? planWithMachine.Machine.Name
                                : translate('status.not_in_machine')
                            }
                            variant="outlined"
                          />
                        );
                      })}
                    </Box>
                  )}
                </Stack>
              );
            });
          })}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OverallProductOrderDetailCard;
