import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getAllActiveDeliveryNoteByCustomerId } from 'requests/ProductionServerHandler';
import ProjectPaper from 'components/customs/ProjectPaper';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from 'utilities/formatTime';
import { Alert, Box, Checkbox } from '@mui/material';
import { useLocales } from 'locales';
import { fCurrency } from 'utilities/formatNumber';
import Button from '@mui/material/Button';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DebouncedInput, fuzzyFilter } from 'components/react-table/utils';
import ReactTableSkeleton from 'components/react-table/ReactTableSkeleton';
import { useQuery } from '@tanstack/react-query';

const SelectDeliveryBillForm = (props) => {
  const { translate } = useLocales();
  const { selectCustomer, onSelectDeliveryBills, onBackPressed, defaultSelectBills } = props;
  const [allBills, setAllBills] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowSelection, setRowSelection] = useState(defaultSelectBills);

  const columns = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            indeterminate={table.getIsSomeRowsSelected()}
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        ),
      },
      {
        header: translate('title.id'),
        accessorKey: 'Id',
      },
      {
        header: translate('title.customer'),
        accessorKey: 'Customer',
      },
      {
        header: translate('title.create_at'),
        accessorKey: 'CreateAt',
        cell: (info) => fDateTimeText(info.getValue()),
      },
      {
        header: translate('title.delivery_by'),
        accessorKey: 'CreateBy',
      },
      {
        header: translate('title.value'),
        accessorKey: 'TotalValue',
        cell: (info) => fCurrency(info.getValue()),
      },
      {
        header: translate('title.type'),
        accessorKey: 'Type',
        cell: (info) =>
          info.getValue() > 1
            ? translate('sales.additional_cost_bill')
            : translate('sales.delivery_bill'),
      },
    ],
    [translate]
  );

  const { data: customerAllBills } = useQuery({
    queryKey: ['getAllActiveDeliveryNoteByCustomerId', selectCustomer.Id],
    queryFn: () => getAllActiveDeliveryNoteByCustomerId(selectCustomer.Id),
    initialData: {
      DeliveryBills: [],
      AdditionalCostBills: [],
    },
  });

  const deliveryBills = useMemo(() => {
    return customerAllBills.DeliveryBills.map((deliveryBill) => {
      return {
        Id: deliveryBill.Id,
        Customer: selectCustomer.Name,
        CreateAt: new Date(deliveryBill.createdAt),
        CreateBy: getEmployeeFullName(deliveryBill.CreateEmployee),
        TotalValue: deliveryBill.TotalValue,
        Type: 1,
      };
    });
  }, [customerAllBills.DeliveryBills, selectCustomer.Name]);

  const additionalCostBills = useMemo(() => {
    return customerAllBills.AdditionalCostBills.map((additionalCostBill) => {
      return {
        Id: `A-${additionalCostBill.Id}`,
        Customer: selectCustomer.Name,
        CreateAt: new Date(additionalCostBill.createdAt),
        CreateBy: getEmployeeFullName(additionalCostBill.CreateEmployee),
        TotalValue: additionalCostBill.TotalValue + additionalCostBill.TotalVat,
        Type: 2,
      };
    });
  }, [customerAllBills.AdditionalCostBills, selectCustomer.Name]);

  useEffect(() => {
    setAllBills([...deliveryBills, ...additionalCostBills]);
  }, [deliveryBills, additionalCostBills]);

  const table = useReactTable({
    data: allBills,
    columns,
    state: {
      sorting,
      globalFilter,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 5,
      },
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    globalFilterFn: fuzzyFilter,
  });

  const handleCompleteSelectedDeliveryBills = () => {
    const selected = [];
    Object.keys(rowSelection).forEach((objectIndex) => {
      selected.push(allBills[objectIndex]);
    });
    onSelectDeliveryBills(selected, rowSelection);
  };

  return (
    <ProjectPaper>
      <DebouncedInput
        value={globalFilter ?? ''}
        onChange={(value) => setGlobalFilter(String(value))}
      />
      {Object.keys(rowSelection).length > 0 && (
        <Alert sx={{ mb: 2 }} onClose={() => table.toggleAllRowsSelected(false)}>
          {`${Object.keys(rowSelection).length}  ${translate('title.rows_selected')}`}
        </Alert>
      )}
      <ReactTableSkeleton table={table} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button color={'primary'} onClick={onBackPressed}>
          {translate('button.back')}
        </Button>
        <Button
          color={'primary'}
          disabled={Object.keys(rowSelection).length <= 0}
          variant={'contained'}
          onClick={handleCompleteSelectedDeliveryBills}
        >
          {translate('button.next')}
        </Button>
      </Box>
    </ProjectPaper>
  );
};

SelectDeliveryBillForm.propTypes = {
  onSelectDeliveryBills: PropTypes.func.isRequired,
  selectCustomer: PropTypes.object.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  defaultSelectBills: PropTypes.object,
};

export default SelectDeliveryBillForm;
