import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenSuccessNotification } from '../../../utilities/Helper/NotificationHelper';
import { cash_delivery_bill_report_prefix } from '../../../utilities/Strings/Prefix';
import DatePickerCustomer from '../../date-picker/DatePickerCustomer';
import { useLocales } from 'locales';
import { printCashDeliveryBillReportExcel } from '../../../requests/ProductionServerHandler';
import { useLoading } from '../../loading';
import { useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver';

export default function PrintCashDeliverySummaryReportPage() {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printProductSellSummaryReportPDFMutation = useMutation({
    mutationFn: printCashDeliveryBillReportExcel,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, `${cash_delivery_bill_report_prefix}.xlsx`);
      await OpenSuccessNotification();
    },
  });
  const onPrintExcel = async (fromDate, toDate, customer) => {
    await printProductSellSummaryReportPDFMutation.mutateAsync({
      customerId: customer.Id,
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('finance.print_cash_delivery_bill_summary_report_page_title')}
      breadcrumbs={[
        { name: translate('finance.title') },
        { name: translate('finance.print_cash_delivery_bill_summary_report_page_title') },
      ]}
    >
      <DatePickerCustomer
        includeAllCustomerOption
        actions={[{ title: `${translate('button.print')} (Excel)`, callBack: onPrintExcel }]}
      />
    </ProjectPageContainer>
  );
}
