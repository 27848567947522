import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { useLocales } from 'locales';
import { fNumber } from '../../../utilities/formatNumber';
import { useDispatch } from 'react-redux';
import { toggleShowProductOrderDetail } from '../../../redux/reducers/planning';

ProductOrderPlanningCard.propTypes = {
  productOrderDetail: PropTypes.object.isRequired,
  onShowEligibleMachineList: PropTypes.func.isRequired,
  onRemoveMachine: PropTypes.func.isRequired,
};

function ProductOrderPlanningCard({
  productOrderDetail,
  onShowEligibleMachineList,
  onRemoveMachine,
}) {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [isExpand, setIsExpand] = useState(false);

  const getTotalMachineCount = (productOrderPlan) => {
    const totalMachine = new Set(
      productOrderPlan.filter((plan) => plan.MachineId !== null).map((plan) => plan.MachineId)
    ).size;
    if (totalMachine > 0) {
      return (
        <Typography
          variant={'body2'}
          sx={{ textAlign: 'right', mt: 1, mr: 1 }}
        >{`${totalMachine} ${translate('title.machine')}`}</Typography>
      );
    } else {
      return (
        <Typography
          variant={'body2'}
          sx={{ textAlign: 'right', mt: 1, mr: 1 }}
          color={'error'}
        >{`${translate('planning.not_in_machine')}`}</Typography>
      );
    }
  };
  const openProductOrderDetail = async () => {
    await dispatch(
      toggleShowProductOrderDetail({
        isShowProductOrderDetail: true,
        productOrderDetail: productOrderDetail,
      })
    );
  };

  return (
    <Card>
      <CardHeader
        sx={{ mb: 2 }}
        title={`${translate('title.id')}: ${productOrderDetail.Id}`}
        titleTypographyProps={{ onClick: () => openProductOrderDetail() }}
        subheader={
          <Stack>
            <Typography variant={'h6'} color={'text.primary'}>
              {productOrderDetail.Customer.Name}
            </Typography>
            <Typography color={'text.primary'}>{productOrderDetail.Name}</Typography>
            <Stack spacing={2} direction="row">
              <Typography>{translate('title.size')}</Typography>
              <Typography>{`${productOrderDetail.Width} x ${productOrderDetail.Length}`}</Typography>
            </Stack>
            <Stack spacing={2} direction="row">
              <Typography>{translate('title.amount')}</Typography>
              <Typography>{fNumber(productOrderDetail.Amount)}</Typography>
            </Stack>
          </Stack>
        }
        action={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {getTotalMachineCount(productOrderDetail.ProductOrderPlannings)}
            <IconButton onClick={() => setIsExpand(!isExpand)}>
              {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        }
      />
      <Collapse in={isExpand}>
        <CardContent>
          <Divider orientation="horizontal" sx={{ mb: 2 }} />
          <Stack spacing={2} divider={<Divider orientation="horizontal" flexItem />}>
            {productOrderDetail.ProductSellHistories.map((sellHistory) => {
              return sellHistory.Product.ProductionProcesses.map((process) => {
                const plans = productOrderDetail.ProductOrderPlannings.filter(
                  (plan) =>
                    plan.ProductHasProductionProcessId === process.ProductHasProductionProcess.Id
                );
                return (
                  <Stack
                    key={`${sellHistory.Id}-${sellHistory.Product.Id}-${process.Id}`}
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{ alignContent: 'center' }}
                  >
                    <Typography sx={{ width: 100, p: 1, textAlign: 'left' }}>
                      {process.Name}
                    </Typography>
                    <Typography sx={{ width: 100, p: 1, textAlign: 'right' }}>{`${plans.reduce(
                      (totalComplete, plan) => {
                        return totalComplete + plan.CompleteAmount;
                      },
                      0
                    )}/${productOrderDetail.Amount}`}</Typography>
                    <Box sx={{ p: 0.5 }}>
                      {plans
                        .filter((plan) => plan.MachineId !== null)
                        .map((planWithMachine) => {
                          return (
                            <Chip
                              sx={{ mr: 1 }}
                              label={planWithMachine.Machine.Name}
                              variant="outlined"
                              onDelete={() => onRemoveMachine(planWithMachine.Id)}
                            />
                          );
                        })}
                      <Chip
                        onClick={() =>
                          onShowEligibleMachineList(
                            productOrderDetail.Id,
                            sellHistory.Id,
                            process.ProductHasProductionProcess.Id,
                            process.Id
                          )
                        }
                        icon={<AddIcon />}
                        label={translate('title.add_machine')}
                        color="primary"
                      />
                    </Box>
                  </Stack>
                );
              });
            })}
          </Stack>
        </CardContent>
      </Collapse>
      <CardActions></CardActions>
    </Card>
  );
}

export default ProductOrderPlanningCard;
