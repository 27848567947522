import { itServerRequest } from '../utilities/Helper/NetworkHelper';

export async function getAllLogisticOrder() {
  const options = {
    method: 'GET',
    url: `order/uncreated`,
  };

  const response = await itServerRequest(options);
  return response.data;
}

export async function getLogisticOrderById(id) {
  const options = {
    method: 'GET',
    url: `order/uncreated/${id}`,
  };

  const response = await itServerRequest(options);
  return response.data;
}

export async function markLogisticOrderAsCreate(data) {
  const options = {
    method: 'PATCH',
    url: `order/sil-create`,
    data:data
  };

  const response = await itServerRequest(options);
  return response.data;
}

export async function getLogisticPileByProductOrderId(id) {
  const options = {
    method: 'GET',
    url: `pile/uncreated/${id}`,
  };

  const response = await itServerRequest(options);
  return response.data;
}

export async function updateLogisticPile(data) {
  const options = {
    method: 'PATCH',
    url: `pile/sil-create-one`,
    data:data
  };

  const response = await itServerRequest(options);
  return response.data;
}