import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenNotificationThenReloadPage,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useAuth from '../../../auth/hooks/useAuth';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { deactivateEmployee } from '../../../requests/ProductionServerHandler';
import { deactivateUser } from '../../../requests/LoginServerHandler';

const DeactivateEmployeePage = () => {
  const { user } = useAuth();
  const deactivateUserMutation = useMutation({
    mutationFn: deactivateUser,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const deactivateEmployeeMutation = useMutation({
    mutationFn: deactivateEmployee,
    onSuccess: async (responseDeactivateEmployee) => {
      if (responseDeactivateEmployee.UserId !== null) {
        await deactivateUserMutation.mutateAsync({
          AdminUserId: user.Id,
          UserId: responseDeactivateEmployee.UserId,
        });
      } else {
        await OpenNotificationThenReloadPage();
      }
    },
  });
  const deleteEmployee = async (values) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await deactivateEmployeeMutation.mutateAsync(values.EmployeeCode);
      }
    );
  };

  const { translate } = useLocales();

  const deactivateUserSchema = Yup.object().shape({
    EmployeeCode: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(deactivateUserSchema),
  });

  const { handleSubmit } = methods;

  return (
    <ProjectPageContainer
      menu_title={translate('hr.remove_employee')}
      breadcrumbs={[{ name: translate('hr.title') }, { name: translate('hr.remove_employee') }]}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(deleteEmployee)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name="EmployeeCode" label={translate('user.employee_code')} />
            <Button variant={'contained'} fullWidth type={'submit'} color={'error'}>
              {translate('button.save')}
            </Button>
          </Stack>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
};

export default DeactivateEmployeePage;
