import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import Image from 'components/minimal/image';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useLocales } from '../../../locales';
import { fCurrency } from '../../../utilities/formatNumber';
import { fDateTimeText } from '../../../utilities/formatTime';
import { paths } from '../../../routes/paths';

ItemLotDetail.propTypes = {
  itemLotDetail: PropTypes.object.isRequired,
};

function ItemLotDetail({ itemLotDetail }) {
  const { translate } = useLocales();

  const totalLotAmount = itemLotDetail?.Items.reduce((total, item) => {
    if (item.RemainAmount > 0) {
      total += 1;
    }
    return total;
  }, 0);

  const totalMasterAmount = itemLotDetail?.Items.reduce((total, item) => {
    if (item.RemainAmount > 0) {
      total += item.RemainAmount;
    }
    return total;
  }, 0);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4}>
        <ProjectPaper>
          <Image
            alt="large image"
            src={
              process.env.REACT_APP_IMAGE_END_POINT +
              process.env.REACT_APP_BUCKET_MASTER_IMAGE_FOLDER_NAME +
              process.env.REACT_APP_IMAGE_PREFIX +
              itemLotDetail?.ItemContainer.ItemMaster.Id
            }
            ratio="1/1"
          />
        </ProjectPaper>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <ProjectPaper>
          <Grid container spacing={3}>
            <GridDetailText
              title={translate('warehouse.item_master_name')}
              value={itemLotDetail?.ItemContainer.ItemMaster.Name}
              link={paths.warehouse.item_master_detail(itemLotDetail?.ItemContainer.ItemMaster.Id)}
            />
            <GridDetailText
              title={translate('title.value')}
              value={fCurrency(itemLotDetail?.Value * totalLotAmount)}
            />
            <GridDetailText
              title={translate('warehouse.remain_amount_title')}
              value={`${totalLotAmount} ${itemLotDetail?.ItemContainer.ItemUnit.Name}`}
            />
            <GridDetailText
              title={translate('warehouse.master_amount')}
              value={`${totalMasterAmount} ${itemLotDetail?.ItemContainer.ItemMaster.ItemUnit.Name}`}
            />
            <GridDetailText
              title={translate('title.create_at')}
              value={fDateTimeText(itemLotDetail?.createdAt)}
            />
            <GridDetailText
              title={translate('title.create_by')}
              value={getEmployeeFullName(itemLotDetail?.CreateEmployee)}
            />
            <GridDetailText
              title={translate('title.supplier')}
              value={itemLotDetail?.Supplier.Name}
              link={paths.procurement.supplier_detail(itemLotDetail?.Supplier.Id)}
            />
            <GridDetailText
              title={translate('title.status')}
              value={itemLotDetail?.ItemLotStatus.Name}
            />
          </Grid>
        </ProjectPaper>
      </Grid>
    </Grid>
  );
}

export default ItemLotDetail;
