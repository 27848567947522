import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllItemMasterBySafety } from '../../../requests/WarehouseServerHandler';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { adjustPurchaseCart, selectPurchaseCart } from '../../../redux/reducers/inventory';
import {
  showAddItemToCartInput,
} from '../../../utilities/Helper/NotificationHelper';
import IconButton from '@mui/material/IconButton';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import ReactTable from '../../react-table/ReactTable';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

const SafetyStockManagementPage = () => {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const PurchaseCart = useSelector(selectPurchaseCart);
  const { data } = useQuery({
    queryKey: ['getAllItemMasterBySafety'],
    queryFn: getAllItemMasterBySafety,
    initialData: [],
  });

  const handleAddItemToCart = async (itemMaster) => {
    await showAddItemToCartInput(translate, itemMaster, true).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          adjustPurchaseCart({ Id: itemMaster.Id, Name: itemMaster.Name, Amount: result.value })
        );
      }
    });
  };

  return (
    <ProjectPageContainer
      menu_title={`${translate('warehouse.safety_stock_management_title')} ${translate(
        'warehouse.material_store'
      )}`}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        {
          name: `${translate('warehouse.safety_stock_management_title')} ${translate(
            'warehouse.material_store'
          )}`,
        },
      ]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={PurchaseCart.length === 0}
          to={paths.procurement.create_purchase_requisition}
        >
          {`${translate('warehouse.order_item')} ${PurchaseCart.length} ${translate(
            'warehouse.items'
          )}`}
        </Button>
      }
    >
      <Card>
        <CardHeader
          title={translate('warehouse.safety_stock_management_title')}
        />
        <CardContent>
          <ReactTable
            data={data.map((itemMaster) => {
              return {
                id: itemMaster.Id,
                name: itemMaster.Name,
                amount: itemMaster.Amount,
                safety_amount: itemMaster.SafetyAmount,
                amount_to_buy: itemMaster.SafetyAmount - itemMaster.Amount,
                already_order: Math.round(itemMaster.TotalBuyAmount),
                type: itemMaster.Type,
                unit: itemMaster.Unit,
                actions: itemMaster,
              };
            })}
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
                size: 50,
              },
              {
                header: translate('title.name'),
                accessorKey: 'name',
              },
              {
                header: translate('title.type'),
                accessorKey: 'type',
                size: 50,
              },
              {
                header: translate('title.safety_amount'),
                accessorKey: 'safety_amount',
                size: 50,
              },
              {
                header: translate('warehouse.amount_in_store_title'),
                accessorKey: 'amount',
                size: 50,
              },
              {
                header: translate('warehouse.amount_need_to_order_title'),
                accessorKey: 'amount_to_buy',
                size: 50,
              },
              {
                header: translate('warehouse.amount_already_order_title'),
                accessorKey: 'already_order',
              },
              {
                header: translate('unit.title'),
                accessorKey: 'unit',
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <>
                    <IconButton
                      component={RouterLink}
                      to={paths.warehouse.item_master_detail(info.getValue().Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                    <IconButton
                      color={'success'}
                      onClick={() => handleAddItemToCart(info.getValue())}
                    >
                      <Iconify icon={'ant-design:shopping-cart-outlined'} width={20} height={20} />
                    </IconButton>
                  </>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>
    </ProjectPageContainer>
  );
};

export default SafetyStockManagementPage;
