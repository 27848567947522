import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  includeTime: PropTypes.bool,
};

export default function RHFDatePicker({ name, includeTime, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (includeTime !== undefined) {
          return (
            <MUIDateTimePicker
              ampm={false}
              inputFormat="dd/MM/yyyy HH:mm"
              label={name}
              value={field.value}
              onChange={(newValue) => {
                field.onChange(newValue);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error ? error?.message : '',
                },
              }}
              {...other}
            />
          );
        }

        return (
          <MUIDatePicker
            ampm={false}
            inputFormat="dd/MM/yyyy HH:mm"
            label={name}
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                error: !!error,
                helperText: error ? error?.message : '',
              },
            }}
            {...other}
          />
        );
      }}
    />
  );
}
