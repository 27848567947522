import {toggleLoading} from "../../redux/reducers/interface";
import {OpenErrorNotification} from "./NotificationHelper";
import {error_contact_admin, error_re_login} from "../Strings/NotificationString";
import {terminateLoginData} from "../../redux/reducers/user";
import axios from 'axios';
export const loginServerRequest = axios.create({
    baseURL: process.env.REACT_APP_LOGIN_API_ENDPOINT
});
loginServerRequest.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong login server')
);

export const productionServerRequest = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT
});

productionServerRequest.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong with production server')
);


export const warehouseServerRequest = axios.create({
    baseURL: process.env.REACT_APP_WARE_HOUSE_ENDPOINT
});

export const adminServerRequest = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_ENDPOINT
});
adminServerRequest.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong login server')
);

warehouseServerRequest.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong with warehouse server')
);

export const itServerRequest = axios.create({
    baseURL: process.env.REACT_APP_IT_ENDPOINT
});

itServerRequest.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong with it server')
);


export function handleLoadingTask(request){
    return async (dispatch) => {
        await dispatch(toggleLoading(true));
        try {
            const response = await request;
            await dispatch(toggleLoading(false));
            return response.data
        }catch (e)
        {
            await dispatch(toggleLoading(false));
            if(e.response !== undefined)
            {
                const statusCode = e.response.status;
                if(statusCode === 401)
                {
                    await OpenErrorNotification(error_re_login);
                    await dispatch(terminateLoginData());
                    localStorage.clear();
                }
                return handleError(e)
            }else{
                await OpenErrorNotification(error_contact_admin);
                return null
            }
        }
    }
}

export async function handleError(error){
    const statusCode = error.response.status;
    if(statusCode === 401)
    {
        return null
    }else if(statusCode === 500)
    {
        await OpenErrorNotification(error_contact_admin);
        return null
    }else{
        await OpenErrorNotification(error_contact_admin);
        return null
    }
}
