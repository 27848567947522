import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useLocales } from 'locales';
import ReactTable from 'components/react-table/ReactTable';
import { IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import { paths } from 'routes/paths';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from 'components/customs/ProjectPaper';
import { useCustomers } from 'requests/query/DropdownData';
import { OPEN_ICON } from 'config-global';
import Label from '../../minimal/label';
import { useTheme } from '@mui/material/styles';

function CustomerListPage() {
  const { translate } = useLocales();
  const { customers } = useCustomers();
  const theme = useTheme();

  return (
    <ProjectPageContainer
      menu_title={translate('sales.customer_list')}
      breadcrumbs={[{ name: translate('sales.title') }, { name: translate('sales.customer_list') }]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={customers.map((customer) => {
            return {
              id: customer.Id,
              name: customer.Name,
              actions: customer.Id,
              is_archived: customer.ArchivedAt,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.status'),
              accessorKey: 'is_archived',
              cell: (info) => (
                <Label
                  variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                  color= {info.getValue() === null ? 'success' : 'error'}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {info.getValue() === null ? 'ปรกติ' : 'มีปัญหาห้ามผลิต'}
                </Label>
              ),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    component={RouterLink}
                    to={paths.sales.customer_detail(info.getValue())}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CustomerListPage;
