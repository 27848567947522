import {useCallback, useEffect, useMemo, useReducer} from "react";
import {localStorageGetItem} from "../utilities/localStorage";
import {isValidToken, setSession, decodeUserData} from "./utils";
import {getEmployeeDetailByUserId} from "../requests/ProductionServerHandler";
import {User} from "../classes/User";
import {loginWithoutToken} from "../requests/LoginServerHandler";
import {AuthContext} from "./AuthContext";
import PropTypes from "prop-types";
import {getEmployeeFullName} from "../utilities/Helper/UtilitiesHelper";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    isTemp: false,
    user: null,
};
const authInitializer = () => {
    return {
        isAuthenticated: false,
        isInitialized: false,
        isTemp: false,
        user: null,
    };
};
const reducer = (state, action) => {
    if (action.type === 'INITIAL') {
        return {
            ...state,
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGIN') {
        return {
            ...state,
            isAuthenticated: true,
            user: action.payload.user,
        };
    }
    if (action.type === 'LOGOUT') {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        };
    }
    return state;
};


const STORAGE_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY || 'accessToken';

export function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState, authInitializer);

    const initialize = useCallback(async () => {
        try {
            const accessToken = localStorageGetItem(STORAGE_KEY);

            if (accessToken && isValidToken(accessToken)) {
                setSession(accessToken);

                const user = decodeUserData(accessToken);
                const employeeDetail = await getEmployeeDetailByUserId(user.id);

                const userObject = new User(
                    user.id,
                    user.username,
                    getEmployeeFullName(employeeDetail),
                    employeeDetail.Department,
                    employeeDetail.EmployeeCode,
                    employeeDetail.Id,
                    user.user_type.Id
                );

                dispatch({
                    type: 'INITIAL',
                    payload: {
                        isAuthenticated: true,
                        user: userObject,
                    },
                });
            } else {
                dispatch({
                    type: 'INITIAL',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: 'INITIAL',
                payload: {
                    user: null,
                },
            });
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = useCallback(async (username, password) => {
        const token = await loginWithoutToken(username, password)
        const user = decodeUserData(token);
        setSession(token);
        const employeeDetail = await getEmployeeDetailByUserId(user.id);
        const userObject = new User(
            user.id,
            user.username,
            getEmployeeFullName(employeeDetail),
            employeeDetail.Department,
            employeeDetail.EmployeeCode,
            employeeDetail.Id,
            user.user_type.Id
        );

        dispatch({
            type: 'LOGIN',
            payload: {
                user: userObject
            },
        });
    }, []);

    // LOGOUT
    const logout = useCallback(async () => {
        setSession(null);
        dispatch({
            type: 'LOGOUT',
        });
    }, []);

    // ----------------------------------------------------------------------

    const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

    const status = state.loading ? 'loading' : checkAuthenticated;

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            status,
            isAuthenticated: state.isAuthenticated,
            isInitialized: state.isInitialized,
            isTemp: state.isTemp,
            authenticated: status === 'authenticated',
            //
            login,
            logout,
        }),
        [login, logout, state.isAuthenticated, state.isInitialized, state.isTemp, state.user, status]
    );

    return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
    children: PropTypes.node,
};
