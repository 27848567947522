import React from 'react';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Search } from '@mui/icons-material';
import PropsTypes from 'prop-types';

SearchPileForm.propsType = {
  onSubmitCallBack: PropsTypes.func.isRequired,
};

function SearchPileForm({ onSubmitCallBack }) {
  const { translate } = useLocales();
  const onSearch = async (value) => {
    onSubmitCallBack(value.PileId);
    reset();
  };

  const searchPileSchema = Yup.object().shape({
    PileId: Yup.number()
      .min(1, translate('error.error_pile_id'))
      .required(translate('error.error_pile_id')),
  });

  const methods = useForm({
    resolver: yupResolver(searchPileSchema),
    defaultValues: {
      PileId: '',
    },
  });

  const { handleSubmit, reset } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSearch)}>
      <RHFTextField
        name={'PileId'}
        label={translate('production.pile_id')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type={'submit'} edge="end">
                {<Search />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormProvider>
  );
}

export default SearchPileForm;
