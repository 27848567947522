import React from 'react';
import { useLocales } from 'locales';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from 'routes/paths';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { Card, CardContent, CardHeader, Grid, Stack, Button, IconButton } from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import { fCurrency, fNumber, fPercent } from 'utilities/formatNumber';
import Iconify from 'components/minimal/iconify';
import ReactTable from 'components/react-table/ReactTable';
import { calculateDiscountPrice } from 'utilities/Helper/DataHelper';
import { OpenConfirmNotification } from 'utilities/Helper/NotificationHelper';
import { ManagementUserTypeId, SalesUserTypeId } from 'utilities/Constants';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import ProjectPaper from 'components/customs/ProjectPaper';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { fDateTimeTextShort } from 'utilities/formatTime';
import { useCustomerPricingData } from 'requests/query/useCustomerPricingData';
import { DELETE_ICON } from 'config-global';

function CustomerPricingDetailPage() {
  const { translate } = useLocales();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    customerPricingDetail,
    removeProductFromPricing,
    cancelCustomerPricing,
    approveCustomerPricing,
    printProductPricingPDF,
  } = useCustomerPricingData(id);

  const handleRemoveProduct = async (removeObject) => {
    await OpenConfirmNotification(
      translate('warning.confirm_delete_product_from_pricing'),
      translate('button.confirm'),
      '',
      async () => {
        await removeProductFromPricing.mutateAsync({
          ProductId: removeObject.ProductId,
          CustomerPricingId: removeObject.CustomerPricingId,
        });
      }
    );
  };
  const onCancelPressed = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_delete_pricing'),
      translate('button.confirm'),
      '',
      async () => {
        await cancelCustomerPricing.mutateAsync(id);
        navigate(paths.sales.customer_detail(customerPricingDetail.Customer.Id));
      }
    );
  };
  const onApprovePressed = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_approve_pricing'),
      translate('button.confirm'),
      '',
      async () => {
        await approveCustomerPricing.mutateAsync(id);
      }
    );
  };
  const onPrintPressed = async () => {
    await printProductPricingPDF.mutateAsync(id);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.customer_pricing')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('sales.customer_list'), href: paths.sales.customer_list },
        {
          name: `${translate('sales.customer_detail')} : ${
            customerPricingDetail !== null ? customerPricingDetail.Customer.Id : 0
          }`,
          href: `${paths.sales.customer_detail(
            customerPricingDetail !== null ? customerPricingDetail.Customer.Id : 0
          )}`,
        },
        { name: `${translate('sales.customer_pricing')} : ${id}` },
      ]}
      action={
        <RoleBasedGuard roles={[ManagementUserTypeId, SalesUserTypeId]}>
          {customerPricingDetail !== null && customerPricingDetail.ApprovedEmployeeId === null ? (
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color={'error'}
              startIcon={<Iconify icon={'eva:close-fill'} />}
              onClick={onCancelPressed}
            >
              {translate('button.cancel')}
            </Button>
          ) : (
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={onPrintPressed}
            >
              {translate('button.print')}
            </Button>
          )}
        </RoleBasedGuard>
      }
    >
      {customerPricingDetail !== null ? (
        <>
          <Stack spacing={2}>
            <Card>
              <CardHeader title={translate('sales.customer_detail')} />
              <CardContent>
                <Grid container spacing={2}>
                  <GridDetailText
                    title={translate('title.id')}
                    value={customerPricingDetail.Customer.Id}
                  />
                  <GridDetailText
                    title={translate('title.name')}
                    value={customerPricingDetail.Customer.Name}
                  />
                  <GridDetailText
                    title={translate('title.create_by')}
                    value={getEmployeeFullName(customerPricingDetail.CreatedEmployee)}
                  />
                  <GridDetailText
                    title={translate('title.status')}
                    value={
                      customerPricingDetail.ApprovedEmployeeId !== null
                        ? 'อนุมัติ'
                        : 'ยังไม่อนุมัติ'
                    }
                  />
                  <GridDetailText
                    title={translate('title.approve_by')}
                    value={getEmployeeFullName(customerPricingDetail.ApprovedEmployee)}
                  />
                  <GridDetailText
                    title={translate('title.approve_at')}
                    value={fDateTimeTextShort(customerPricingDetail.ApprovedAt)}
                  />
                  <GridDetailText
                    title={translate('title.expire_at')}
                    value={fDateTimeTextShort(customerPricingDetail.ExpiredAt)}
                  />
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={translate('sales.product_detail')} />
              <CardContent>
                <ReactTable
                  defaultPageSize={10}
                  data={customerPricingDetail.Products.map((product, index) => {
                    return {
                      id: product.Id,
                      name: product.Name,
                      sku: product.SKU,
                      basePrice: product.BasePrice,
                      pricePerSheet: calculateDiscountPrice(
                        product.CustomerPricingHasProduct.PricePerSheet,
                        product.CustomerPricingHasProduct.DiscountPercent
                      ),
                      discount: product.CustomerPricingHasProduct.DiscountPercent,
                      minimumAmount: product.CustomerPricingHasProduct.MinimumAmount,
                      actions: {
                        ProductId: product.Id,
                        CustomerPricingId: id,
                        Length: customerPricingDetail.Products.length,
                      },
                    };
                  })}
                  header={[
                    {
                      header: translate('title.id'),
                      accessorKey: 'id',
                      size: 50,
                    },
                    {
                      header: translate('title.name'),
                      accessorKey: 'name',
                      size: 100,
                    },
                    {
                      header: translate('title.sku'),
                      accessorKey: 'sku',
                      size: 50,
                    },
                    {
                      header: translate('sales.middle_price'),
                      accessorKey: 'basePrice',
                      size: 50,
                      cell: (info) => fCurrency(info.getValue()),
                    },
                    {
                      header: translate('sales.sell_price'),
                      accessorKey: 'pricePerSheet',
                      size: 50,
                      cell: (info) => fCurrency(info.getValue()),
                    },
                    {
                      header: `${translate('title.discount')} %`,
                      accessorKey: 'discount',
                      size: 50,
                      cell: (info) => fPercent(info.getValue()),
                    },
                    {
                      header: translate('title.minimum_amount'),
                      accessorKey: 'minimumAmount',
                      size: 50,
                      cell: (info) => fNumber(info.getValue()),
                    },
                    {
                      header: 'Actions',
                      accessorKey: 'actions',
                      size: 50,
                      cell: (info) =>
                        info.getValue().Length === 1 ||
                        customerPricingDetail.ApprovedEmployeeId !== null ? (
                          '-'
                        ) : (
                          <IconButton
                            color={'error'}
                            onClick={() => handleRemoveProduct(info.getValue())}
                          >
                            <Iconify icon={DELETE_ICON} />
                          </IconButton>
                        ),
                    },
                  ]}
                />
              </CardContent>
            </Card>
            <RoleBasedGuard roles={[ManagementUserTypeId]}>
              {customerPricingDetail.ApprovedEmployeeId === null ? (
                <ProjectPaper>
                  <Stack spacing={2} direction="row">
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      fullWidth
                      startIcon={<Iconify icon={'eva:check-fill'} />}
                      onClick={onApprovePressed}
                    >
                      {translate('title.approve')}
                    </Button>
                    <Button
                      variant={'contained'}
                      color={'error'}
                      fullWidth
                      startIcon={<Iconify icon={'eva:close-fill'} />}
                      onClick={onCancelPressed}
                    >
                      {translate('button.cancel')}
                    </Button>
                  </Stack>
                </ProjectPaper>
              ) : null}
            </RoleBasedGuard>
          </Stack>
        </>
      ) : null}
    </ProjectPageContainer>
  );
}

export default CustomerPricingDetailPage;
