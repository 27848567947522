import React, { useState, useEffect } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm, useWatch } from 'react-hook-form';
import {
  createMaintenanceJob,
  getAllMachineMaintenanceDetail,
} from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import useAuth from '../../../auth/hooks/useAuth';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { EmployeeScanner } from '../../barcode-reader';
import { useMachine } from '../../../requests/query/DropdownData';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../../minimal/hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';

function CreateMaintenanceJobPage() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const [submitEmployee, setSubmitEmployee] = useState(
    user !== null
      ? {
          Id: user.EmployeeId,
          Name: user.Name,
          Department: user.Department,
          EmployeeCode: user.EmployeeCode,
          IsTemp: false,
        }
      : {
          Id: 0,
          Name: null,
          Department: null,
          EmployeeCode: null,
          IsTemp: true,
        }
  );
  const { machines } = useMachine(submitEmployee.IsTemp, false);

  const createMaintenanceJobMutation = useMutation({
    mutationFn: createMaintenanceJob,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const handleGetEmployeeDetail = (employeeDetail) => {
    setSubmitEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };
  const handleCreateMaintenanceJob = async (values) => {
    let machineDetailId = null;
    if (values.MaintenanceDetail !== undefined) {
      machineDetailId = values.MaintenanceDetail.Id;
    }
    await createMaintenanceJobMutation.mutateAsync({
      Detail: values.Detail,
      MachineId: values.Machine.Id,
      MaintenanceDetailId: machineDetailId,
      MaintenanceJobTypeId: 5,
      BookedAt: new Date(),
      CreateEmployeeId: submitEmployee.Id,
      IsTemp: submitEmployee.IsTemp,
    });
  };

  const CreateMaintenanceJobSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.name_is_require')),
    MaintenanceDetail: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.product_order_subtype_is_require')),
        Name: Yup.string().required(translate('error.product_order_subtype_is_require')),
      })
      .required(translate('error.product_order_subtype_is_require')),
    Machine: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.product_order_subtype_is_require')),
        Name: Yup.string().required(translate('error.product_order_subtype_is_require')),
      })
      .required(translate('error.product_order_subtype_is_require')),
  });

  const methods = useForm({
    resolver: yupResolver(CreateMaintenanceJobSchema),
  });

  const { handleSubmit, control } = methods;

  const selectedMachine = useWatch({
    control: control,
    name: 'Machine',
    defaultValue: null,
  });

  const { data: machineDetail, refetch } = useQuery({
    queryKey: ['all-machine-maintenance-detail', selectedMachine],
    queryFn: () =>
      getAllMachineMaintenanceDetail({
        MachineId: selectedMachine.Id,
        IsTemp: submitEmployee.IsTemp,
      }),
    enabled: selectedMachine !== null,
    initialData: {
      Details: [],
    },
  });
  useEffect(() => {
    if (selectedMachine !== null) {
      refetch();
    }
  }, [refetch, selectedMachine]);

  return (
    <ProjectPageContainer
      menu_title={translate('maintenance.create_maintenance_job')}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: translate('maintenance.create_maintenance_job') },
      ]}
    >
      {submitEmployee.Id !== 0 ? (
        <React.Fragment>
          <Stack spacing={3}>
            <Card>
              <CardHeader title={translate('user.employee_detail')} />
              <CardContent>
                <Grid container spacing={3}>
                  <GridDetailText
                    title={translate('title.create_by')}
                    value={submitEmployee.Name}
                  />
                  <GridDetailText
                    title={translate('user.employee_code')}
                    value={submitEmployee.EmployeeCode}
                  />
                </Grid>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title={translate('title.detail')} />
              <CardContent>
                <FormProvider onSubmit={handleSubmit(handleCreateMaintenanceJob)} methods={methods}>
                  <Stack spacing={3}>
                    <RHFAutoCompleteShort
                      data={machines}
                      label={translate('title.machine')}
                      name={'Machine'}
                    />
                    <RHFAutoCompleteShort
                      data={machineDetail !== undefined ? machineDetail.Details : []}
                      label={translate('maintenance.machine_maintenance_position_title')}
                      name={'MaintenanceDetail'}
                      disabled={selectedMachine === null}
                    />
                    <RHFTextField label={translate('title.detail')} name={'Detail'} />
                    <Button color={'primary'} variant={'contained'} fullWidth type="submit">
                      {translate('button.create')}
                    </Button>
                  </Stack>
                </FormProvider>
              </CardContent>
            </Card>
          </Stack>
        </React.Fragment>
      ) : (
        <EmployeeScanner
          onScanCallback={handleGetEmployeeDetail}
          isPreFetchData
          isShowTooltips
          disableInput
        />
      )}
    </ProjectPageContainer>
  );
}

export default CreateMaintenanceJobPage;
