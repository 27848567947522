import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack, Button } from '@mui/material';
import ProjectPaper from '../customs/ProjectPaper';
import GridDetailText from '../customs/GridDetailText';
import { fCurrency } from '../../utilities/formatNumber';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';

EditPoItemForm.propTypes = {
  order: PropTypes.object.isRequired,
  onEditCallback: PropTypes.func.isRequired,
  onCancelCallback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  unitName: PropTypes.string.isRequired,
};

function EditPoItemForm({ order, onEditCallback, onCancelCallback, name, unitName }) {
  const { translate } = useLocales();

  const editPartPOItemSchema = Yup.object().shape({
    Amount: Yup.number()
      .min(1, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
    BuyPrice: Yup.number()
      .min(0.0001, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(editPartPOItemSchema),
    defaultValues: {
      Detail: order.Detail,
      Amount: order.Amount,
      BuyPrice: order.BuyPrice,
    },
  });

  const { handleSubmit, control } = methods;

  const amountWatch = useWatch({
    control,
    name: 'Amount',
    defaultValue: order.Amount,
  });

  const buyPriceWatch = useWatch({
    control,
    name: 'BuyPrice',
    defaultValue: order.BuyPrice,
  });

  const onSaveEditDetail = async (data) => {
    onEditCallback(order.Id, data.Amount, data.BuyPrice, data.Detail);
  };

  const onCancelEdit = () => {
    onCancelCallback();
  };

  return (
    <Stack spacing={3}>
      <ProjectPaper>
        <Grid container spacing={3}>
          <GridDetailText title={translate('title.name')} value={name} />
          <GridDetailText title={translate('title.amount')} value={`${order.Amount} ${unitName}`} />
          <GridDetailText
            title={translate('procurement.total_price_title')}
            value={fCurrency(amountWatch * buyPriceWatch)}
          />
          <GridDetailText
            title={translate('title.vat')}
            value={fCurrency(amountWatch * buyPriceWatch * 0.07)}
          />
        </Grid>
      </ProjectPaper>
      <ProjectPaper>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSaveEditDetail)}>
          <Stack spacing={2}>
            <RHFTextField name={'Detail'} label={translate('title.detail')} />
            <RHFTextField name={'Amount'} label={translate('title.amount')} />
            <RHFTextField name={'BuyPrice'} label={translate('procurement.price_per_unit')} />
            <Stack spacing={2} direction="row">
              <Button variant={'contained'} fullWidth type="submit" color={'primary'}>
                {translate('button.save')}
              </Button>
              <Button variant={'contained'} color={'error'} fullWidth onClick={onCancelEdit}>
                {translate('button.cancel')}
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </ProjectPaper>
    </Stack>
  );
}

export default EditPoItemForm;
