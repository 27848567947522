import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import useTabs from '../../../hooks/useTabs';
import Iconify from 'components/minimal/iconify';
import PileDetail from '../../details/pile/PileDetail';
import PileProductionRecordDetail from '../../details/pile/PileProductionRecordDetail';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import EmptyContent from '../../customs/EmptyContent';
import { fDateTimeText } from '../../../utilities/formatTime';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import GeneralTable from '../../customs/GeneralTable';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  ManagementUserTypeId,
  ProductionControlUserTypeId,
  SalesUserTypeId,
} from '../../../utilities/Constants';
import { usePile } from '../../../requests/query/usePile';
import { paths } from '../../../routes/paths';
import { OpenConfirmNotification } from '../../../utilities/Helper/NotificationHelper';

function PileDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const { pile, print, cancel } = usePile(id, false);
  const { currentTab, onChangeTab } = useTabs('detail');

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: <PileDetail pile={pile} />,
    },
    {
      value: 'productionRecord',
      title: translate('production.production_record'),
      icon: <Iconify icon={'ant-design:ordered-list-outlined'} width={20} height={20} />,
      component: <PileProductionRecordDetail productionRecords={pile?.ProductionRecords} />,
    },
    {
      value: 'delivery_bill',
      title: translate('sales.delivery_bill'),
      icon: <Iconify icon={'eva:car-outline'} width={20} height={20} />,
      component: (
        <ProjectPaper>
          {pile !== null && pile.DeliveryBills.length > 0 ? (
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={pile.DeliveryBills[0].Id} />
              <GridDetailText
                title={translate('title.create_at')}
                value={fDateTimeText(pile.DeliveryBills[0].createAt)}
              />
              <GridDetailText
                title={translate('title.delivery_by')}
                value={getEmployeeFullName(pile.DeliveryBills[0].CreateEmployee)}
              />
            </Grid>
          ) : (
            <EmptyContent
              title={translate('title.empty')}
              description={translate('placeholder.empty_data')}
              img="/assets/illustrations/illustration_empty_content.svg"
            />
          )}
        </ProjectPaper>
      ),
    },
    {
      value: 'problem_list',
      title: translate('qa.problem_list'),
      icon: <Iconify icon={'ic:baseline-report-problem'} width={20} height={20} />,
      component: (
        <ProjectPaper>
          {pile !== null && pile.ApprovalRecords.length > 0 ? (
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.create_by' },
                { align: 'left', title: 'title.create_at' },
              ]}
              tableData={pile.ApprovalRecords.map((approvalRecord, index) => [
                index + 1,
                approvalRecord.ApprovalType.Name,
                getEmployeeFullName(approvalRecord.CreateEmployee),
                fDateTimeText(approvalRecord.createdAt),
              ])}
            />
          ) : (
            <EmptyContent
              title={translate('title.empty')}
              description={translate('placeholder.empty_data')}
              img="/assets/illustrations/illustration_empty_content.svg"
            />
          )}
        </ProjectPaper>
      ),
    },
    {
      value: 'quality_problem_title',
      title: translate('qa.quality_problem_title'),
      icon: <Iconify icon={'ant-design:bug-outlined'} width={20} height={20} />,
      component: (
        <ProjectPaper>
          {pile !== null && pile.PileQualityProblemHistories.length > 0 ? (
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'qa.hold_at' },
                { align: 'left', title: 'qa.hold_employee' },
                { align: 'left', title: 'qa.un_hold_at' },
                { align: 'left', title: 'qa.un_hold_by' },
              ]}
              tableData={pile.PileQualityProblemHistories.map(
                (pileQualityProblemHistory, index) => {
                  return [
                    index + 1,
                    pileQualityProblemHistory.QualityProblem.Name,
                    fDateTimeText(pileQualityProblemHistory.HoldAt),
                    getEmployeeFullName(pileQualityProblemHistory.HoldEmployee),
                    fDateTimeText(pileQualityProblemHistory.UnHoldAt),
                    getEmployeeFullName(pileQualityProblemHistory.UnHoldEmployee),
                  ];
                }
              )}
            />
          ) : (
            <EmptyContent
              title={translate('title.empty')}
              description={translate('placeholder.empty_data')}
              img="/assets/illustrations/illustration_empty_content.svg"
            />
          )}
        </ProjectPaper>
      ),
    },
  ];

  const onPrintPileDocument = async () => {
    await print.mutateAsync(id);
  };

  const onCancelPile = async () => {
    await OpenConfirmNotification(
      translate('ยกเลิกขางานนี้หรือไม่?'),
      translate('button.confirm'),
      '',
      async () => {
        await cancel.mutateAsync(id);
      },
    );

  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.pile_detail')}
      breadcrumbs={[
        { name: translate('production.title') },
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${
            pile?.ProductOrder.Id
          }`,
          href: paths.production.product_order_detail(pile?.ProductOrder.Id),
        },
        { name: `${translate('production.pile_detail')} ${translate('title.id')} : ${pile?.Id}` },
      ]}
      action={
      <>
        <RoleBasedGuard
          roles={[
            ManagementUserTypeId,
            SalesUserTypeId,
            AdminUserTypeId,
            ProductionControlUserTypeId,
          ]}
        >
          {pile !== null && pile.PileStatusId !== 7 && (
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={onPrintPileDocument}
            >
              {translate('button.print_pile_document')}
            </Button>
          )}
        </RoleBasedGuard>
        <RoleBasedGuard
          roles={[
            ManagementUserTypeId,
            AccountingUserTypeId
          ]}
        >
          {pile !== null && pile.PileStatusId !== 7 && (
            <Button
              variant="contained"
              color={'error'}
              startIcon={<Iconify icon={'eva:close-fill'} />}
              onClick={onCancelPile}
            >
              {translate('button.cancel')}
            </Button>
          )}
        </RoleBasedGuard>
      </>

      }
    >
      {pile !== null ? (
        <>
          <>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {INFORMATION_TABS.map((tab, index) => (
                <Tab
                  disableRipple
                  key={index}
                  label={tab.title}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Box sx={{ mb: 3 }} />
            {INFORMATION_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && tab.component;
            })}
          </>
        </>
      ) : (
        <EmptyContent title={'ไม่พบขาที่ต้องการ'} />
      )}
    </ProjectPageContainer>
  );
}

export default PileDetailPage;
