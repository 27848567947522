import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Divider, Grid, Button } from '@mui/material';
import { useLocales } from '../../locales';
import { OpenConfirmNotification } from '../../utilities/Helper/NotificationHelper';
import GridDetailText from '../customs/GridDetailText';
import { fCurrency } from '../../utilities/formatNumber';
import GeneralTable from '../customs/GeneralTable';

RecheckCreateProductOrderDetail.propTypes = {
  products: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  productDetail: PropTypes.object.isRequired,
  onBackPress: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function RecheckCreateProductOrderDetail({
  products,
  customer,
  productDetail,
  onBackPress,
  onConfirm,
}) {
  const { translate } = useLocales();

  const handleConfirmCreateProductOrder = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_product_order'),
      translate('button.confirm'),
      '',
      onConfirm
    );
  };

  const calculateProductOrderValue = (amount, products) =>
    products.reduce((totalValue, product) => {
      if (product.Price.IsBundle) {
        totalValue += product.Price.BundlePrice;
      } else {
        totalValue += amount * product.Price.Price;
      }
      return totalValue;
    }, 0);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <GridDetailText title={translate('title.name')} value={productDetail.Name} />
          <GridDetailText title={translate('title.detail')} value={productDetail.Detail} />
          <GridDetailText title={translate('title.width')} value={productDetail.Width} />
          <GridDetailText title={translate('title.length')} value={productDetail.Length} />
          <GridDetailText title={translate('title.amount')} value={productDetail.Amount} />
          <GridDetailText
            title={translate('title.product_order_subtype')}
            value={productDetail.ProductOrderSubType.Name}
          />
          <GridDetailText title={translate('title.customer')} value={customer.Name} />
          <GridDetailText
            title={translate('title.value')}
            value={fCurrency(calculateProductOrderValue(productDetail.Amount, products))}
          />
        </Grid>
        <Divider sx={{ mt: 3, mb: 3 }} />
        <GeneralTable
          tableHeaders={[
            { align: 'left', title: translate('title.name') },
            { align: 'left', title: translate('title.price') },
            { align: 'left', title: 'รวมราคา' },
          ]}
          tableData={products.map((product) => {
            return [
              product.Detail.Name,
              product.Price.IsBundle
                ? `${translate('title.bundle_price')} ${product.Price.BundlePrice}`
                : product.Price.Price,
              product.Price.IsBundle
                ? fCurrency(product.Price.BundlePrice)
                : fCurrency(product.Price.Price * productDetail.Amount),
            ];
          })}
        />
        <Divider sx={{ mt: 3 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3,
          }}
        >
          <Button color={'primary'} onClick={onBackPress}>
            {translate('button.back')}
          </Button>
          <Button color={'primary'} variant={'contained'} onClick={handleConfirmCreateProductOrder}>
            {translate('button.create')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default RecheckCreateProductOrderDetail;
