import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import { useBOMs } from '../../requests/query/DropdownData';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFAutoCompleteShort } from '../minimal/hook-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { OpenConfirmNotification, OpenNotificationThenReloadPage } from '../../utilities/Helper/NotificationHelper';
import { useLoadingMutation } from '../../requests/utility';
import { selectBOMForProduct } from '../../requests/ProductionServerHandler';

SelectBomForm.propTypes = {
  productId: PropTypes.number.isRequired,
};

function SelectBomForm({productId}) {

  const { translate } = useLocales();
  const { bom } = useBOMs();
  const updateBOMMutation = useLoadingMutation({
    mutationFn: selectBOMForProduct,
    onSuccess: async () => {
     await OpenNotificationThenReloadPage('คุณ update BOM แล้ว');
    },
  });

  const selectBOMSchema = Yup.object().shape({
    BOM: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(selectBOMSchema),
    defaultValues: {
      BOM: null,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_select_bom'),
      translate('button.confirm'),
      '',
      async () => {
        await updateBOMMutation.mutateAsync({
          BOMId: values.BOM.Id,
          ProductId: productId,
        });
      }
    );
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFAutoCompleteShort name={`BOM`}
                              label={translate('product.select_bom')}
                              data={bom} />
        <Button color={'primary'} variant={'contained'} fullWidth type="submit">
          {translate('button.select')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default SelectBomForm;