import { useMutation, useQuery } from '@tanstack/react-query';
import {
  acceptItemReceiptById,
  createItemReceipt,
  getItemReceiptBarcodePDFById,
  getItemReceiptById,
  getItemReceiptPDFById,
} from '../WarehouseServerHandler';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { useLoading } from '../../components/loading';

export const useItemReceipt = (id) => {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['item', id],
    queryFn: () => getItemReceiptById(id),
    enabled: id !== null,
    initialData: null,
  });

  const printReportMutation = useMutation({
    mutationFn: getItemReceiptPDFById,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(response, `ใบรับของ.pdf`);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  const printBarcodeMutation = useMutation({
    mutationFn: getItemReceiptBarcodePDFById,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(response, `ใบQR.pdf`);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });

  const createItemReceiptMutation = useMutation({
    mutationFn: createItemReceipt,
    onSuccess: async (response) => {
      await OpenSuccessNotification();
    },
  });

  const acceptItemReceiptMutation = useMutation({
    mutationFn: acceptItemReceiptById,
    onSuccess: async (response) => {
      await OpenNotificationThenReloadPage();
    },
  });

  return {
    itemReceipt: data,
    createItemReceipt: createItemReceiptMutation,
    acceptItemReceipt: acceptItemReceiptMutation,
    printReport: printReportMutation,
    printBarcode: printBarcodeMutation,
  };
};
