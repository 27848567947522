import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenSuccessNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getPartInventoryExcelFileURL } from '../../../requests/ProductionServerHandler';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';

export default function PrintInventoryDataPage() {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  const printProductSellSummaryReportExcelMutation = useMutation({
    mutationFn: getPartInventoryExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'inventory_report.xlsx');
      await OpenSuccessNotification();
    },
  });

  const onPrintItemMasterExcel = async () => {
    await printProductSellSummaryReportExcelMutation.mutateAsync();
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.print_warehouse_data_page_title')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.print_warehouse_data_page_title') },
      ]}
    >
      <ProjectPaper>
        <Button variant={'contained'} fullWidth onClick={onPrintItemMasterExcel} color={'primary'}>
          {translate('button.print')}
        </Button>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}
