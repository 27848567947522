import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import GeneralTable from '../../customs/GeneralTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useLocales } from '../../../locales';
import { fCurrency } from '../../../utilities/formatNumber';
import { paths } from 'routes/paths';
import { OPEN_ICON } from '../../../config-global';

ProductOrderAdditionalCostBillList.propTypes = {
  additionalCostBills: PropTypes.array,
  productOrderId: PropTypes.number.isRequired,
};

function ProductOrderAdditionalCostBillList({ additionalCostBills, productOrderId }) {
  const { translate } = useLocales();
  return (
    <Card>
      <CardHeader
        action={
          <Button
            variant="contained"
            color={'primary'}
            component={RouterLink}
            href={`${paths.accounting.create_product_order_additional_cost_bill}?productOrderId=${productOrderId}`}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {translate('sales.create_additional_cost_bill')}
          </Button>
        }
      />
      <CardContent>
        <GeneralTable
          tableHeaders={[
            { align: 'left', title: 'title.id' },
            { align: 'left', title: 'title.value' },
            { align: 'left', title: 'title.create_by' },
            { align: 'left', title: 'title.create_at' },
            { align: 'left', title: 'title.canceled_at' },
            { align: 'left', title: 'title.billing_at' },
            { align: 'left', title: '' },
          ]}
          tableData={additionalCostBills.map((bill) => [
            bill.Id,
            fCurrency(bill.TotalValue),
            getEmployeeFullName(bill.CreateEmployee),
            fDateTimeText(bill.createdAt),
            fDateTimeText(bill.ArchivedAt),
            fDateTimeText(bill.InvoiceAt),
            <IconButton
              key={bill.Id}
              component={RouterLink}
              to={paths.accounting.product_order_additional_cost_bill_detail(bill.Id)}
            >
              <Iconify icon={OPEN_ICON} />
            </IconButton>,
          ])}
        />
      </CardContent>
    </Card>
  );
}

export default ProductOrderAdditionalCostBillList;
