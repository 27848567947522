import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';
import { Button, Stack } from '@mui/material';
import { getAllAsset } from '../../requests/AdminServerHandler';
import { useQuery } from '@tanstack/react-query';

CreateItJobForm.propTypes = {
  onSubmitValue: PropTypes.func.isRequired,
};

function CreateItJobForm({ onSubmitValue }) {
  const { translate } = useLocales();
  const { data } = useQuery({
    queryKey: ['getAllAsset'],
    queryFn: getAllAsset,
    initialData: {
      data: [],
    },
  });

  const createItJobSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    Asset: Yup.object().required(translate('error.detail_require')),
  });
  const defaultValue = {
    Detail: '',
    Asset: null,
  };

  const methods = useForm({
    resolver: yupResolver(createItJobSchema),
    defaultValues: defaultValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onSubmitValue(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name={'Detail'} label={translate('title.detail')} />
        <RHFAutoCompleteShort
          data={data.data.map((item) => {
            return {
              Id: item.id,
              Name: item.code,
            };
          })}
          name={'Asset'}
          label={translate('it.asset_code')}
        />
        <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateItJobForm;
