import React from 'react';
import PropTypes from 'prop-types';
import GridDetailText from '../../customs/GridDetailText';
import { fDateTimeText } from '../../../utilities/formatTime';
import Grid from '@mui/material/Grid';
import { useLocales } from '../../../locales';
import ProjectPaper from '../../customs/ProjectPaper';

CompleteMaintenanceJobDetail.propTypes = {
  jobDetail: PropTypes.object.isRequired,
};

function CompleteMaintenanceJobDetail({ jobDetail }) {
  const { translate } = useLocales();
  return (
    <ProjectPaper>
      <Grid container spacing={2}>
        <GridDetailText
          title={translate('maintenance.maintenance_detail')}
          value={jobDetail.CompleteDetail}
        />
        <GridDetailText
          title={translate('title.complete_date')}
          value={fDateTimeText(jobDetail.CompletedAt)}
        />
        <GridDetailText
          title={translate('maintenance.hour_meter')}
          value={jobDetail.FinishHourMeter}
        />
      </Grid>
    </ProjectPaper>
  );
}

export default CompleteMaintenanceJobDetail;
