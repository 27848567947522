import React from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllActiveCashDeliveryBills } from '../../../requests/ProductionServerHandler';
import ReactTable from '../../react-table/ReactTable';
import { IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { fCurrency } from 'utilities/formatNumber';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';
import { OPEN_ICON } from 'config-global';

function CashDeliveryBillListPage() {
  const { translate } = useLocales();

  const { data: allCashDeliveryBill } = useQuery({
    queryKey: ['all-cash-delivery-bill'],
    queryFn: getAllActiveCashDeliveryBills,
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('finance.cash_delivery_bill_list')}
      breadcrumbs={[
        { name: translate('finance.title') },
        { name: translate('finance.cash_delivery_bill_list') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={allCashDeliveryBill.map((cashDeliveryBill) => {
            return {
              id: cashDeliveryBill.Id,
              customer: cashDeliveryBill.DeliveryBill.Customer.Name,
              value: cashDeliveryBill.DeliveryBill.TotalValue,
              createdEmployee: getEmployeeFullName(cashDeliveryBill.DeliveryBill.CreateEmployee),
              actions: cashDeliveryBill.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
              cell: (info) => `C-${info.getValue()}`,
            },
            {
              header: translate('title.customer'),
              accessorKey: 'customer',
            },
            {
              header: translate('title.value'),
              accessorKey: 'value',
              cell: (info) => fCurrency(info.getValue()),
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'createdEmployee',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  <IconButton
                    component={RouterLink}
                    to={paths.finance.cash_delivery_bill_detail(info.getValue())}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CashDeliveryBillListPage;
