import {
  cancelDeliveryBillById,
  cancelExampleDeliveryBillById,
  getDeliveryBillDetailById,
  getDeliveryBillPDFById,
} from '../ProductionServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLoading } from '../../components/loading';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { delivery_bill_prefix } from '../../utilities/Strings/Prefix';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useDeliveryBill = (id) => {
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();

  const { data } = useQuery({
    queryKey: ['delivery-bill', id],
    queryFn: () => getDeliveryBillDetailById(id),
    initialData: null,
  });

  const printDeliveryBillMutation = useMutation({
    mutationFn: getDeliveryBillPDFById,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (pdfStructure) => {
      hideLoadingScreen();
      await PrintMultiplePDFMakeDocument(pdfStructure, `${delivery_bill_prefix}_P${id}.pdf`);
      await OpenSuccessNotification();
    },
  });

  const cancelDeliveryBillMutation = useMutation({
    mutationFn: cancelDeliveryBillById,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delivery-bill', id]);
      await OpenSuccessNotification();
    },
  });

  const cancelExampleDeliveryBillMutation = useMutation({
    mutationFn: cancelExampleDeliveryBillById,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['delivery-bill', id]);
      await OpenSuccessNotification();
    },
  });

  return {
    deliveryBillDetail: data,
    printDeliveryBill: printDeliveryBillMutation,
    cancelDeliveryBill: cancelDeliveryBillMutation,
    cancelExampleDeliveryBill: cancelExampleDeliveryBillMutation,
  };
};
