import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, Typography } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import GridDetailSkeleton from './GridDetailSkeleton';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useLocales } from 'locales';
import Link from "@mui/material/Link";

EditableGridDetailText.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
  link: PropTypes.string,
  isIgnoreCheck: PropTypes.bool,
};

function EditableGridDetailText({ title, value, color, link, onEdit, isIgnoreCheck }) {
  const [isEdit, setIsEdit] = useState(false);
  const { translate } = useLocales();

  const editDetailSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const defaultValues = {
    Detail: value,
  };

  let formDetail = {
    resolver: yupResolver(editDetailSchema),
    defaultValues: defaultValues,
  };

  if (isIgnoreCheck) {
    formDetail = {
      defaultValues: defaultValues,
    };
  }

  const methods = useForm(formDetail);

  const { handleSubmit } = methods;

  const onSave = (value) => {
    onEdit(value.Detail);
    setIsEdit(false);
  };
  const renderComponent = isEdit ? (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
      <RHFTextField
        name={`Detail`}
        label={translate('title.detail')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type={'submit'}>
                <Iconify icon={'ic:baseline-save'} width={20} height={20} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormProvider>
  ) : color !== undefined ? (
    <Typography variant="body1" sx={{ color }}>
      {value}
      <IconButton color={'info'} onClick={() => setIsEdit(true)} sx={{ ml: 1 }}>
        <Iconify icon={'ic:baseline-edit'} width={20} height={20} />
      </IconButton>
    </Typography>
  ) : (
    <Typography variant="body1">
      {value}
      <IconButton color={'info'} onClick={() => setIsEdit(true)} sx={{ ml: 1 }}>
        <Iconify icon={'ic:baseline-edit'} width={15} height={15} />
      </IconButton>
    </Typography>
  );

  return (
    <GridDetailSkeleton title={title}>
      {link !== undefined ? <Link href={link}>{renderComponent}</Link> : renderComponent}
    </GridDetailSkeleton>
  );
}

export default EditableGridDetailText;
