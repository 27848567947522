import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormProvider, { RHFAutoCompleteShort, RHFTextField, RHFUpload } from '../minimal/hook-form';
import ProjectPaper from '../customs/ProjectPaper';
import { Box, Button, Chip, IconButton, Stack } from '@mui/material';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Iconify from '../minimal/iconify';
import GeneralTable from '../customs/GeneralTable';
import useAuth from '../../auth/hooks/useAuth';
import { getCompressImage } from '../../utilities/Helper/DataHelper';
import { OpenNotificationThenReloadPage } from '../../utilities/Helper/NotificationHelper';
import { itemContainerValidator } from '../../utilities/Helper/FormValidator';
import { useAttributeNumbers, useAttributeStrings, useItemMasterCreateData } from '../../requests/query/DropdownData';
import { useItemMasterDetail } from '../../requests/query/useItemMasterDetail';

CreateItemMasterForm.propTypes = {
  itemMasterDetail: PropTypes.object,
};

function CreateItemMasterForm({ itemMasterDetail }) {
  const { translate } = useLocales();
  const { user } = useAuth();

  const { itemTypeOptions, itemUnitOptions } = useItemMasterCreateData();
  const { createItemMaster, editItemMasterDetail, uploadImage } = useItemMasterDetail(null);
  const { attributeStrings } = useAttributeStrings();
  const { attributeNumbers } = useAttributeNumbers();
  const editItemMasterSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    SafetyAmount: Yup.number()
      .min(0, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    ItemUnit: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    ItemType: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });
  const createItemMasterSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    SafetyAmount: Yup.number()
      .min(0, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    ItemUnit: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    ItemType: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    Containers: Yup.array().of(itemContainerValidator(translate)).min(1, 'error.invalid_input'),
  });

  const defaultValue =
    itemMasterDetail !== undefined && itemMasterDetail !== null
      ? {
        Name: itemMasterDetail.Name,
        Detail: itemMasterDetail.Detail,
        SafetyAmount: itemMasterDetail.SafetyAmount,
        ItemUnit: itemMasterDetail.ItemUnit,
        ItemType: itemMasterDetail.ItemType,
        ItemTags: itemMasterDetail.Tags.map((tag) => tag.Name),
      }
      : {
        Name: '',
        Detail: '',
        SafetyAmount: 0,
        ItemTags: [],
        AttributeStrings: [],
        AttributeNumbers: [],
      };

  const methods = useForm({
    resolver: yupResolver(
      itemMasterDetail !== undefined ? editItemMasterSchema : createItemMasterSchema,
    ),
    defaultValues: defaultValue,
  });

  const { control, setValue, handleSubmit } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Containers',
  });
  const {
    fields: attributeStringFields,
    remove: attributeStringRemove,
    append: attributeStringAppend,
  } = useFieldArray({
    control,
    name: 'AttributeStrings',
  });

  const {
    fields: attributeNumberFields,
    remove: attributeNumberRemove,
    append: attributeNumberAppend,
  } = useFieldArray({
    control,
    name: 'AttributeNumbers',
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'ItemImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
      }
    },
    [setValue],
  );

  const onSubmit = async (values) => {
    let response;

    if (itemMasterDetail !== undefined) {
      await editItemMasterDetail.mutateAsync({
        ItemMasterId: itemMasterDetail.Id,
        Name: values.Name,
        Detail: values.Detail !== undefined ? values.Detail : 'N/A',
        SafetyAmount: values.SafetyAmount,
        ItemTypeId: values.ItemType.Id,
        ItemUnitId: values.ItemUnit.Id,
        Tags: values.ItemTags.map((tag) => {
          return { Name: tag, TagTypeId: '1' };
        }),
        EditEmployeeId: user.EmployeeId,
      });
      response = itemMasterDetail;
    } else {
      const { mutateAsync } = createItemMaster;
      response = await mutateAsync({
        Name: values.Name,
        Detail: values.Detail !== undefined ? values.Detail : 'N/A',
        SafetyAmount: values.SafetyAmount,
        ItemTypeId: values.ItemType.Id,
        ItemUnitId: values.ItemUnit.Id,
        ItemCategoryId: 1,
        ItemBrand: 1,
        Tags: values.ItemTags.map((tag) => {
          return { Name: tag, TagTypeId: '1' };
        }),
        CreateEmployeeId: user.EmployeeId,
        Containers:
          values.Containers !== undefined && values.Containers.length > 0 ? values.Containers : [],
        AttributeStrings: values.AttributeStrings.map((item) => {
          return { Id: item.AttributeString.Id, Value: item.Value };
        }),
        AttributeNumbers: values.AttributeNumbers.map((item) => {
          return { Id: item.AttributeNumber.Id, Value: item.Value };
        }),
      });
    }

    if (response !== null) {
      if (values.ItemImage !== undefined && values.ItemImage !== null) {
        const compressedFile = await getCompressImage(values.ItemImage);
        await uploadImage.mutateAsync({
          Id: response.Id,
          Image: compressedFile,
        });
        await OpenNotificationThenReloadPage(
          itemMasterDetail !== undefined
            ? `${translate('notification.success_edit_item_id_title')}:${itemMasterDetail.Id}`
            : `${translate('notification.success_create_item_id_title')}:${response.Id}`,
        );
      } else {
        await OpenNotificationThenReloadPage(
          itemMasterDetail !== undefined
            ? `${translate('notification.success_edit_item_id_title')}:${itemMasterDetail.Id}`
            : `${translate('notification.success_create_item_id_title')}:${response.Id}`,
        );
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ProjectPaper>
        <Stack spacing={3}>
          <RHFUpload name="ItemImage" accept="image/*" maxSize={3145728} onDrop={handleDrop} />
          <RHFTextField name={'Name'} label={translate('title.name')} />
          <RHFTextField name={'Detail'} label={translate('title.detail')} />
          <RHFTextField
            name={'SafetyAmount'}
            type={'number'}
            label={translate('title.safety_amount')}
          />
          <RHFAutoCompleteShort
            name={'ItemUnit'}
            label={translate('unit.title')}
            data={itemUnitOptions}
          />
          <RHFAutoCompleteShort
            name={'ItemType'}
            label={translate('title.type')}
            data={itemTypeOptions}
          />
          <Controller
            name="ItemTags"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                freeSolo
                onChange={(event, newValue) => field.onChange(newValue)}
                options={[]}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                  ))
                }
                renderInput={(params) => <TextField label="Tags" {...params} />}
              />
            )}
          />
          {itemMasterDetail !== undefined && itemMasterDetail !== null && (
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.save')}
            </Button>
          )}
        </Stack>
      </ProjectPaper>
      {(itemMasterDetail === undefined || itemMasterDetail === null) && (
        <Stack spacing={3}>

          <ProjectPaper>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
              <Button
                variant="contained"
                color={'primary'}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => attributeStringAppend({ AttributeString: attributeStrings[0], Value: '' })}
              >
                {translate('เพิ่ม Attribute ประเภท ข้อมูล')}
              </Button>
            </Box>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.value' },
                { align: 'left', title: '' },
              ]}
              tableData={attributeStringFields.map((item, index) => {
                return [
                  index + 1,
                  <RHFAutoCompleteShort
                    name={`AttributeStrings[${index}].AttributeString`}
                    label={translate('title.name')}
                    data={attributeStrings}
                  />,
                  <RHFTextField name={`AttributeStrings[${index}].Value`} type={'text'} />,
                  <IconButton color={'error'} onClick={() => attributeStringRemove(index)}>
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>,
                ];
              })}
            />
          </ProjectPaper>
          <ProjectPaper>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
              <Button
                variant="contained"
                color={'primary'}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => attributeNumberAppend({ AttributeNumber: attributeNumbers[0], Value: 0 })}
              >
                {translate('เพิ่ม Attribute ประเภท ค่าเลข')}
              </Button>
            </Box>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.value' },
                { align: 'left', title: '' },
              ]}
              tableData={attributeNumberFields.map((item, index) => {
                return [
                  index + 1,
                  <RHFAutoCompleteShort
                    name={`AttributeNumbers[${index}].AttributeNumber`}
                    label={translate('title.name')}
                    data={attributeNumbers}
                  />,
                  <RHFTextField name={`AttributeNumbers[${index}].Value`} type={'text'} />,
                  <IconButton color={'error'} onClick={() => attributeNumberRemove(index)}>
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>,
                ];
              })}
            />
          </ProjectPaper>
          <ProjectPaper>
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
              <Button
                variant="contained"
                color={'primary'}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => append({ Name: '', Size: 1, Unit: itemUnitOptions[0] })}
              >
                {translate('button.create_item_container')}
              </Button>
            </Box>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'warehouse.container_size_title' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: '' },
              ]}
              tableData={fields.map((item, index) => {
                return [
                  index + 1,
                  <RHFTextField name={`Containers[${index}].Name`} type={'text'} />,
                  <RHFTextField name={`Containers[${index}].Size`} type={'text'} />,
                  <RHFAutoCompleteShort
                    name={`Containers[${index}].ItemUnit`}
                    label={translate('unit.title')}
                    data={itemUnitOptions}
                  />,
                  <IconButton color={'error'} onClick={() => remove(index)}>
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>,
                ];
              })}
            />
            <Button
              variant={'contained'}
              disabled={fields.length === 0}
              fullWidth
              type={'submit'}
              color={'primary'}
            >
              {translate('button.create')}
            </Button>
          </ProjectPaper>
        </Stack>
      )}
    </FormProvider>
  );
}

export default CreateItemMasterForm;
