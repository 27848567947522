import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  getItemContainerBySKU,
  getItemDetailByBarcodeId,
} from '../../../requests/WarehouseServerHandler';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { paths } from '../../../routes/paths';
import { useMutation } from '@tanstack/react-query';
import { OpenErrorNotification } from '../../../utilities/Helper/NotificationHelper';

const SearchWarehousePage = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const searchItemDetailByBarcode = useMutation({
    mutationFn: getItemDetailByBarcodeId,
    onSuccess: (response) => {
      navigate(paths.warehouse.item_detail(response.Id));
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const searchItemContainerBySKUMutation = useMutation({
    mutationFn: getItemContainerBySKU,
    onSuccess: (response) => {
      navigate(paths.warehouse.item_container_detail(response.Id));
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });
  const onSearch = async (searchString, selectType) => {
    if (selectType === 1) {
      if (searchString.includes('BarcodeId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.BarcodeId !== undefined) {
          await searchItemDetailByBarcode.mutateAsync(dataJson.BarcodeId);
        }
      } else {
        await searchItemDetailByBarcode.mutateAsync(searchString);
      }
    } else if (selectType === 2) {
      if (searchString.includes('ItemTemplateId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ItemMasterId !== undefined) {
          navigate(paths.warehouse.item_master_detail(dataJson.ItemMasterId));
        }
      } else {
        navigate(paths.warehouse.item_master_detail(searchString));
      }
    } else if (selectType === 3) {
      if (searchString.includes('ReportId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ReportId !== undefined) {
          navigate(paths.warehouse.item_receipt_report_detail(dataJson.ReportId));
        }
      } else {
        navigate(paths.warehouse.item_receipt_report_detail(searchString));
      }
    } else if (selectType === 4) {
      if (searchString.includes('InventoryId')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.ItemLotId !== undefined) {
          navigate(paths.warehouse.item_lot_detail(dataJson.ItemLotId));
        }
      } else {
        navigate(paths.warehouse.item_lot_detail(searchString));
      }
    } else if (selectType === 5) {
      if (searchString.includes('SKU')) {
        const dataJson = JSON.parse(searchString);
        if (dataJson.SKU !== undefined) {
          await openItemContainerBySKU(dataJson.SKU);
        }
      } else {
        await openItemContainerBySKU(searchString);
      }
    }
  };

  const openItemContainerBySKU = async (sku) => {
    await searchItemContainerBySKUMutation.mutateAsync(sku);
  };

  const searchType = [
    { value: 1, Name: translate('warehouse.search_by_barcode') },
    { value: 2, Name: translate('warehouse.search_by_item_master_id') },
    { value: 3, Name: translate('warehouse.search_item_receipt_by_id') },
    { value: 4, Name: translate('warehouse.search_lot_by_id') },
    { value: 5, Name: translate('warehouse.search_by_sku') },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('button.search') },
      ]}
    >
      <React.Fragment>
        <SearchBarWithSelectType
          search_label={translate('button.search')}
          select_options={searchType}
          onSearchCallBack={onSearch}
        />
      </React.Fragment>
    </ProjectPageContainer>
  );
};

export default SearchWarehousePage;
