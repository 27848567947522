import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { getWarehouseShelfDetail, moveShelf } from '../../../requests/WarehouseServerHandler';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import ProjectPaper from '../../customs/ProjectPaper';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import { useMutation } from '@tanstack/react-query';
import { DetailScanner } from '../../barcode-reader';
import MaterialScanner from '../../barcode-reader/MaterialScanner';

export default function MoveItemShelfPage() {
  const { translate } = useLocales();
  const findWarehouseShelfMutation = useMutation({
    mutationFn: getWarehouseShelfDetail,
    onSuccess: (data) => {
      setTargetShelf(data);
    },
    onError: async () => {
      await OpenErrorNotification('ไม่พบชั้นวางที่ต้องการย้าย');
    },
  });

  const moveItemMutation = useMutation({
    mutationFn: moveShelf,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
    onError: async () => {
      await OpenErrorNotification();
    },
  });

  const findItemFromBarcode = async (toMoveItem) => {
    const duplicate = moveItems.find((item) => item.BarcodeId === toMoveItem.BarcodeId);
    if (duplicate === undefined) {
      setMoveItems([...moveItems, toMoveItem]);
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };
  const findShelfWithId = async (id) => {
    await findWarehouseShelfMutation.mutateAsync(id);
  };
  const onShelfMove = async () => {
    await moveItemMutation.mutateAsync({
      TargetShelfId: targetShelf.Id,
      ItemIds: moveItems.map((item) => item.Id),
    });
  };
  const onBarcodeScan = async (scan) => {
    const dataJson = JSON.parse(scan);
    if (dataJson.Id !== undefined) {
      await findShelfWithId(dataJson.Id);
    }
  };
  const onDeleteItem = (itemId) => {
    setMoveItems(moveItems.filter((item) => item.Id !== itemId));
  };

  const [targetShelf, setTargetShelf] = useState(null);
  const [moveItems, setMoveItems] = useState([]);

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.move_shelf')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.move_shelf') },
      ]}
    >
      {targetShelf !== null ? (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('warehouse.shelf_id')} value={targetShelf.Id} />
              <GridDetailText
                title={translate('warehouse.target_move_shelf')}
                value={targetShelf.Name}
              />
              <GridDetailText
                title={translate('warehouse.store')}
                value={targetShelf.Warehouse.Name}
              />
            </Grid>
          </ProjectPaper>
          <Card>
            <CardHeader title={translate('warehouse.items_to_move')} />
            <CardContent>
              <Stack spacing={3}>
                <GeneralTable
                  tableHeaders={[
                    { align: 'left', title: '#' },
                    { align: 'left', title: 'title.name' },
                    { align: 'left', title: 'warehouse.item_container_detail' },
                    { align: 'left', title: 'warehouse.barcode_id' },
                    { align: 'left', title: 'title.amount' },
                    { align: 'left', title: '' },
                  ]}
                  tableData={moveItems.map((item, index) => {
                    return [
                      index + 1,
                      item.ItemLot.ItemContainer.ItemMaster.Name,
                      item.ItemLot.ItemContainer.Name,
                      item.BarcodeId,
                      1,
                      [
                        <IconButton color={'error'} onClick={() => onDeleteItem(item)}>
                          <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                        </IconButton>,
                      ],
                    ];
                  })}
                />
                {moveItems.length > 0 && (
                  <Button color={'primary'} variant={'contained'} fullWidth onClick={onShelfMove}>
                    {translate('button.move')}
                  </Button>
                )}
              </Stack>
            </CardContent>
          </Card>
          <MaterialScanner onSearchCallBack={findItemFromBarcode} isPreFetchData />
        </Stack>
      ) : (
        <DetailScanner
          onScanCallback={onBarcodeScan}
          onEnterTextField={findShelfWithId}
          textFieldLabel={translate('warehouse.shelf_id')}
        />
      )}
    </ProjectPageContainer>
  );
}
