import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Chip, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

RhfTags.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number,
      Name: PropTypes.string,
    })
  ),
};

export default function RhfTags({ name, options, label }) {
  const { control } = useFormContext();

  const getOptions = (option) => option.Name;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            {...field}
            multiple
            freeSolo
            onChange={(event, newValue) => field.onChange(newValue)}
            options={options}
            getOptionLabel={getOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.Id}
                  size="small"
                  label={option.Name}
                />
              ))
            }
            renderInput={(params) => (
              <TextField label={label} error={!!error} helperText={error?.message} {...params} />
            )}
          />
        );
      }}
    />
  );
}
