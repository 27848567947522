import { createSlice } from '@reduxjs/toolkit'

export const interfaceSlice = createSlice({
    name: 'interface',
    initialState: {
        isLoading: false,
    },
    reducers: {
        toggleLoading: (state,action) => {
            state.isLoading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { toggleLoading } = interfaceSlice.actions

export const selectIsLoading = (state) => state.interface.isLoading;

export default interfaceSlice.reducer