import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm } from 'react-hook-form';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { createItemUnit } from '../../../requests/WarehouseServerHandler';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

function CreateItemUnitPage() {
  const { translate } = useLocales();

  const createItemUnitMutation = useMutation({
    mutationFn: createItemUnit,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const reworkOrderSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
  });

  const methods = useForm({
    resolver: yupResolver(reworkOrderSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    await createItemUnitMutation.mutateAsync(values.Name);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_new_unit')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.create_new_unit') },
      ]}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name="Name" label={translate('title.name')} />
            <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
}

export default CreateItemUnitPage;
