import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { Card, CardContent, CardHeader, IconButton, Stack } from '@mui/material';
import ReactTable from 'components/react-table/ReactTable';
import { sortById } from 'utilities/Helper/DataHelper';
import { fCurrency } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import Label from 'components/minimal/label';
import { RouterLink } from 'routes/components';
import Iconify from 'components/minimal/iconify';
import { useTheme } from '@mui/material/styles';
import ProgressItem from 'components/customs/ProgressItem';
import { useCustomerFinanceDetail } from 'requests/query/useCustomerDetail';
import { paths } from 'routes/paths';
import { OPEN_ICON } from 'config-global';

CustomerFinanceDetail.propTypes = {
  customerId: PropTypes.string.isRequired,
};

function CustomerFinanceDetail({ customerId }) {
  const { translate } = useLocales();
  const { customerFinanceDetail } = useCustomerFinanceDetail(customerId);
  const theme = useTheme();

  const creditDetail = useMemo(() => {
    if (customerFinanceDetail === null) {
      return {
        CreditValue: 0,
        CreditPercent: 0,
      };
    }
    const maxLimit = customerFinanceDetail.CreditLimit;
    const totalDeliveryBills = customerFinanceDetail.DeliveryBills.filter(
      (bill) => bill.InvoiceAt === null
    ).reduce((totalBillValue, deliveryBill) => {
      return totalBillValue + deliveryBill.TotalValue;
    }, 0);
    const creditPercent = maxLimit > 0 ? (totalDeliveryBills * 100) / maxLimit : 100;
    return {
      CreditValue: totalDeliveryBills,
      CreditPercent: creditPercent,
    };
  }, [customerFinanceDetail]);

  return (
    <>
      {customerFinanceDetail !== null && (
        <Stack spacing={2}>
          <Card>
            <CardHeader title={translate('title.finance')} subheader={''} />
            <Stack spacing={4} sx={{ p: 3 }}>
              <ProgressItem
                progress={{
                  amount: fCurrency(creditDetail.CreditValue),
                  label: translate('title.credit_limit'),
                  value: creditDetail.CreditPercent,
                }}
                color={creditDetail.CreditPercent > 50 ? 'error' : 'success'}
              />
            </Stack>
          </Card>
          <Card>
            <CardHeader title={translate('title.all_delivery_bill')} subheader={''} />
            <CardContent>
              <ReactTable
                search
                data={customerFinanceDetail.DeliveryBills.sort(sortById).map((deliveryBill) => {
                  return {
                    id: deliveryBill.Id,
                    value: deliveryBill.TotalValue,
                    createAt: deliveryBill.createdAt,
                    status: deliveryBill,
                    actions: deliveryBill.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                    size: 20,
                  },
                  {
                    header: translate('title.value'),
                    accessorKey: 'value',
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    header: translate('title.create_at'),
                    accessorKey: 'createAt',
                    cell: (info) => fDateTimeText(info.getValue()),
                  },
                  {
                    header: translate('title.status'),
                    accessorKey: 'status',
                    cell: (info) => {
                      const deliveryBill = info.getValue();
                      if (deliveryBill.IsExample) {
                        return (
                          <Label
                            variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                            color={'info'}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {translate('title.example_bill')}
                          </Label>
                        );
                      } else {
                        if (deliveryBill.ArchivedAt !== null) {
                          return (
                            <Label
                              variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                              color={'error'}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {translate('title.canceled')}
                            </Label>
                          );
                        }
                        if (deliveryBill.InvoiceAt !== null) {
                          return (
                            <Label
                              variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                              color={'success'}
                              sx={{ textTransform: 'capitalize' }}
                            >
                              {`${translate('title.invoice_at')} ${fDateTimeText(
                                deliveryBill.InvoiceAt
                              )}`}
                            </Label>
                          );
                        }
                        return (
                          <Label
                            variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                            color={'info'}
                            sx={{ textTransform: 'capitalize' }}
                          >
                            {translate('title.not_bill')}
                          </Label>
                        );
                      }
                    },
                    size: 50,
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'actions',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        href={paths.accounting.delivery_bill_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                    size: 20,
                  },
                ]}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('title.example_bill')} subheader={''} />
            <CardContent>
              <ReactTable
                search
                data={customerFinanceDetail.Examples.sort(sortById).map((deliveryBill) => {
                  return {
                    id: deliveryBill.Id,
                    value: deliveryBill.TotalValue,
                    createAt: deliveryBill.createdAt,
                    status: deliveryBill.ArchivedAt,
                    actions: deliveryBill.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                    size: 20,
                  },
                  {
                    header: translate('title.value'),
                    accessorKey: 'value',
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    header: translate('title.create_at'),
                    accessorKey: 'createAt',
                    cell: (info) => fDateTimeText(info.getValue()),
                  },
                  {
                    header: translate('title.status'),
                    accessorKey: 'status',
                    cell: (
                      <Label
                        variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                        color={'warning'}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {translate('title.example_bill')}
                      </Label>
                    ),
                    size: 50,
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'actions',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        href={paths.accounting.delivery_bill_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                    size: 20,
                  },
                ]}
              />
            </CardContent>
          </Card>
          <Card>
            <CardHeader title={translate('title.all_billing_note')} subheader={''} />
            <CardContent>
              <ReactTable
                search
                data={customerFinanceDetail.BillingNotes.sort(sortById).map((billingNote) => {
                  return {
                    id: billingNote.Id,
                    createAt: billingNote.createdAt,
                    value: billingNote.TotalValue,
                    actions: billingNote.Id,
                  };
                })}
                header={[
                  {
                    header: translate('title.id'),
                    accessorKey: 'id',
                    size: 20,
                  },
                  {
                    header: translate('title.value'),
                    accessorKey: 'value',
                    cell: (info) => fCurrency(info.getValue()),
                  },
                  {
                    header: translate('title.create_at'),
                    accessorKey: 'createAt',
                    cell: (info) => fDateTimeText(info.getValue()),
                  },
                  {
                    header: translate('title.status'),
                    accessorKey: 'createAt',
                    cell: (
                      <Label
                        variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
                        color={'success'}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {translate('accounting.already_bill')}
                      </Label>
                    ),
                    size: 50,
                  },
                  {
                    header: 'Actions',
                    accessorKey: 'actions',
                    cell: (info) => (
                      <IconButton
                        component={RouterLink}
                        href={paths.accounting.billing_note_detail(info.getValue())}
                      >
                        <Iconify icon={OPEN_ICON} />
                      </IconButton>
                    ),
                    size: 20,
                  },
                ]}
              />
            </CardContent>
          </Card>
        </Stack>
      )}
    </>
  );
}

export default CustomerFinanceDetail;
