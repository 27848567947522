import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';

CreateDiscountBillForm.propTypes = {
  defaultDiscountValue: PropTypes.number.isRequired,
  onSubmitValue: PropTypes.func.isRequired,
};

function CreateDiscountBillForm({ defaultDiscountValue, onSubmitValue }) {
  const { translate } = useLocales();

  const createDiscountBillSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    DiscountValue: Yup.number()
      .max(defaultDiscountValue, translate('error.invalid_input'))
      .required(translate('error.detail_require')),
  });

  const defaultValue = {
    Detail: '',
    DiscountValue: defaultDiscountValue,
  };

  const methods = useForm({
    resolver: yupResolver(createDiscountBillSchema),
    defaultValues: defaultValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onSubmitValue(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name={'Detail'} label={translate('title.detail')} />
        <RHFTextField name={'DiscountValue'} label={translate('sales.discount_value')} />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateDiscountBillForm;
