import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import PropsType from 'prop-types';

ReactQueryProvider.propType = {
    children: PropsType.node.isRequired,
};

const queryClient = new QueryClient();

function ReactQueryProvider({ children }) {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export default ReactQueryProvider;
