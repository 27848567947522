import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import React, { useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import ProjectPaper from '../../customs/ProjectPaper';
import { Grid, Stack, Button } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useLocales } from 'locales';
import MaintenanceJobDetail from '../../details/maintenance/MaintenanceJobDetail';
import { EmployeeScanner } from '../../barcode-reader';
import { useMaintenanceJob } from '../../../requests/query/useMaintenanceJob';
import { MaintenanceJobStatus, ManagementUserTypeId } from '../../../utilities/Constants';
import { paths } from '../../../routes/paths';
import { useAuth } from '../../../auth/hooks';

export default function EvaluateMaintenanceJobPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [evaluateEmployee, setEvaluateEmployee] = useState(null);
  const { id } = useParams();
  const { translate } = useLocales();
  const { jobDetail, evaluateJob } = useMaintenanceJob(id);

  const onEvaluateJob = async (score) => {
    await evaluateJob.mutateAsync({
      Id: jobDetail.Id,
      EvaluationScoreId: score,
      EvaluationComment: `ประเมินโดย ${getEmployeeFullName(evaluateEmployee)}`,
      EvaluatedEmployeeId: evaluateEmployee.Id,
    });

    navigate(paths.maintenance.job_detail(id));
  };

  const onScanEmployee = (value) => {
    setEvaluateEmployee(value);
  };

  if (jobDetail !== null) {
    if (user.UserTypeId !== ManagementUserTypeId) {
      if (
        jobDetail.Specialist[0].Id !== user.EmployeeId ||
        jobDetail.MaintenanceJobStatus.Id !== MaintenanceJobStatus.WaitForEvaluation
      ) {
        return <Navigate to={paths.maintenance.job_detail(id)} />;
      }
    }
  }

  return (
    <ProjectPageContainer
      menu_title={translate('maintenance.evaluate_maintenance_job_detail')}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: translate('maintenance.maintenance_job_list'), href: paths.maintenance.job_list },
        {
          name: `${translate('maintenance.maintenance_job_detail')} ${translate(
            'title.id'
          )} : ${id}`,
          href: paths.maintenance.job_detail(id),
        },
        {
          name: `${translate('maintenance.evaluate_maintenance_job_detail')} ${translate(
            'title.id'
          )} : ${id}`,
        },
      ]}
    >
      <Stack spacing={3}>
        {evaluateEmployee !== null ? (
          <>
            {jobDetail !== null && <MaintenanceJobDetail jobDetail={jobDetail} />}
            <ProjectPaper>
              <Grid container spacing={3}>
                <GridDetailText
                  title={translate('maintenance.evaluator')}
                  value={getEmployeeFullName(evaluateEmployee)}
                />
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={'contained'}
                    color={'success'}
                    fullWidth
                    onClick={() => onEvaluateJob(1)}
                  >
                    {translate('button.accept')}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant={'contained'}
                    fullWidth
                    color={'error'}
                    onClick={() => onEvaluateJob(3)}
                  >
                    {translate('button.decline')}
                  </Button>
                </Grid>
              </Grid>
            </ProjectPaper>
          </>
        ) : (
          <EmployeeScanner
            disableInput
            onScanCallback={onScanEmployee}
            isPreFetchData
            isShowTooltips
          />
        )}
      </Stack>
    </ProjectPageContainer>
  );
}
