import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createPileFromProductOrderId, getProductOrderDetailById } from '../../../requests/ProductionServerHandler';
import { paths } from '../../../routes/paths';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { Button, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import Iconify from '../../minimal/iconify';
import GridDetailText from '../../customs/GridDetailText';
import { fNumber } from '../../../utilities/formatNumber';
import ProjectPaper from '../../customs/ProjectPaper';
import { ProductOrderScanner } from '../../barcode-reader';
import CreatePileFromLogisticPileForm from '../../forms/CreatePileFromLogisticPileForm';
import { PrintMultiplePDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useLoading } from '../../loading';
import { getLogisticPileByProductOrderId, updateLogisticPile } from '../../../requests/ITServerHandler';

function CreatePileFromLogisticPilePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { translate } = useLocales();
  const [productOrderId, setProductOrderId] = useState(searchParams.get('productOrderId'));
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const queryClient = useQueryClient();

  const { data: productOrder, refetch } = useQuery({
    queryKey: ['getProductOrderDetailById', productOrderId],
    queryFn: () => getProductOrderDetailById(productOrderId),
    enabled: productOrderId !== undefined && productOrderId !== null,
    initialData: null,
  });

  const { data: logisticPiles } = useQuery({
    queryKey: ['logistic-piles', productOrderId],
    queryFn: () => getLogisticPileByProductOrderId(productOrderId),
    initialData: [],
  });

  const updatePileMutation = useMutation({
    mutationFn: updateLogisticPile,
    onSuccess: () => {
    }
  });

  const createPileMutation = useMutation({
    mutationFn: createPileFromProductOrderId,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (data, variables) => {
      await PrintMultiplePDFMakeDocument([data.PDFFile], `Pile_Doc_${data.Id}.pdf`);
      await updatePileMutation.mutate({
        LogisticPileId: parseInt(variables.LogisticPileId),
        PileId: data.Id,
      })
      await queryClient.invalidateQueries(['getProductOrderDetailById', productOrderId]);
      await queryClient.invalidateQueries(['logistic-piles', productOrderId]);
      hideLoadingScreen();
      await OpenNotificationThenReloadPage(`${translate('notification.create_pile_id')} ${data.Id}`);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
      hideLoadingScreen();
    },
  });

  const clearProductOrder = async () => {
    setProductOrderId(null);
    setSearchParams({});
    await refetch();
  };
  const searchProductOrderById = async (productOrderId) => {
    setProductOrderId(productOrderId);
    setSearchParams({ productOrderId: productOrderId });
    await refetch();
  };

  const handleCreatePile = async (values) => {
    await createPileMutation.mutateAsync({
      ProductOrderId: productOrder.Id,
      ReferenceId: values.ReferenceId,
      Amount: values.Amount,
      Height: values.Height,
      LogisticPileId: values.Id,
    });
  }

  return (
    <ProjectPageContainer
      menu_title={`${translate('production.create_pile')}จากจัดส่ง`}
      breadcrumbs={
        productOrder !== null
          ? [
            { name: translate('production.title') },
            {
              name: translate('production.product_order_list'),
              href: paths.production.product_order_list,
            },
            {
              name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${
                productOrder?.Id
              }`,
              href: paths.production.product_order_detail(productOrder?.Id),
            },
            { name: translate('production.create_pile') },
          ]
          : [
            { name: translate('production.title') },
            {
              name: translate('production.product_order_list'),
              href: paths.production.product_order_list,
            },
            { name: `${translate('production.create_pile')}จากจัดส่ง` },
          ]
      }
    >
      {productOrder !== null ? (
        <Stack spacing={2}>
          <Card>
            <CardHeader
              title={translate('title.detail')}
              action={
                <Button
                  color={'error'}
                  variant={'contained'}
                  startIcon={<Iconify icon={'eva:close-fill'} />}
                  onClick={clearProductOrder}
                >
                  {translate('button.change_product_order')}
                </Button>
              }
            />
            <CardContent>
              <Grid container spacing={3}>
                <GridDetailText title={translate('title.id')} value={productOrder.Id} />
                <GridDetailText title={translate('title.name')} value={productOrder.Name} />
                <GridDetailText title={translate('title.detail')} value={productOrder.Detail} />
                <GridDetailText
                  title={`${translate('title.width')} x ${translate('title.length')}`}
                  value={`${productOrder.Width} x ${productOrder.Length}`}
                />
                <GridDetailText
                  title={translate('title.amount')}
                  value={fNumber(productOrder.Amount)}
                />
                <GridDetailText
                  title={translate('title.amount_in')}
                  value={fNumber(
                    productOrder.Piles.reduce((totalAmount, pile) => {
                      return totalAmount + pile.Amount;
                    }, 0)
                  )}
                />
                <GridDetailText
                  title={translate('title.customer')}
                  value={productOrder.Customer.Name}
                />
              </Grid>
            </CardContent>
          </Card>
          <ProjectPaper>
            <CreatePileFromLogisticPileForm logisticPiles={logisticPiles.filter((pile) => pile.sil_created === false)} onFormSubmit={handleCreatePile}/>
          </ProjectPaper>
        </Stack>
      ) : (
        <ProductOrderScanner onSearchCallBack={searchProductOrderById} />
      )}
    </ProjectPageContainer>
  );
}

export default CreatePileFromLogisticPilePage;