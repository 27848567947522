import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Divider, Grid, Stack, Button } from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import { useLocales } from 'locales';
import { fCurrency } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import ReactTable from 'components/react-table/ReactTable';
import { OpenConfirmNotification } from 'utilities/Helper/NotificationHelper';

RecheckCreateBillingNoteForm.propTypes = {
  bills: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  billingDate: PropTypes.object.isRequired,
  onBackPressed: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function RecheckCreateBillingNoteForm({ bills, customer, billingDate, onBackPressed, onConfirm }) {
  const { translate } = useLocales();

  const calculateBillingValue = () =>
    bills.reduce((totalValue, bill) => {
      totalValue += bill.TotalValue;
      return totalValue;
    }, 0);

  const calculateAdditionalCostValue = () =>
    bills.reduce((totalValue, bill) => {
      if (bill.Type === 2) {
        totalValue += bill.TotalValue;
      }
      return totalValue;
    }, 0);

  const columns = useMemo(
    () => [
      {
        header: translate('title.id'),
        accessorKey: 'Id',
      },
      {
        header: translate('title.customer'),
        accessorKey: 'Customer',
      },
      {
        header: translate('title.create_at'),
        accessorKey: 'CreateAt',
        cell: (info) => fDateTimeText(info.getValue()),
      },
      {
        header: translate('title.delivery_by'),
        accessorKey: 'CreateBy',
      },
      {
        header: translate('title.value'),
        accessorKey: 'TotalValue',
        cell: (info) => fCurrency(info.getValue()),
      },
      {
        header: translate('title.type'),
        accessorKey: 'Type',
        cell: (info) =>
          info.getValue() > 1
            ? translate('sales.additional_cost_bill')
            : translate('sales.delivery_bill'),
      },
    ],
    [translate]
  );

  const handleConfirmCreateBillingNote = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_billing_note'),
      translate('button.confirm'),
      '',
      onConfirm
    );
  };
  return (
    <Card>
      <CardContent>
        <Stack spacing={2}>
          <Grid sx={{ mb: 3 }} container spacing={3}>
            <GridDetailText title={translate('title.customer')} value={customer.Name} />
            <GridDetailText title={translate('title.credit_day')} value={customer.CreditDays} />
            <GridDetailText
              title={translate('title.billing_at')}
              value={fDateTimeText(billingDate)}
            />
            <GridDetailText
              title={translate('sales.delivery_bill')}
              value={fCurrency(calculateBillingValue() - calculateAdditionalCostValue())}
            />
            <GridDetailText
              title={translate('sales.additional_cost_bill')}
              value={fCurrency(calculateAdditionalCostValue())}
            />
            <GridDetailText
              title={translate('title.value')}
              value={fCurrency(calculateBillingValue())}
            />
          </Grid>
          <ReactTable
            data={bills.map((bill) => {
              return {
                Id: bill.Id,
                Customer: bill.Customer,
                CreateAt: bill.CreateAt,
                CreateBy: bill.CreateBy,
                TotalValue: bill.TotalValue,
                Type: bill.Type,
              };
            })}
            header={columns}
          />
        </Stack>

        <Divider sx={{ mt: 3 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3,
          }}
        >
          <Button color={'primary'} onClick={onBackPressed}>
            {translate('button.back')}
          </Button>
          <Button color={'primary'} variant={'contained'} onClick={handleConfirmCreateBillingNote}>
            {translate('button.create')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default RecheckCreateBillingNoteForm;
