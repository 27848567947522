import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import useAuth from '../../auth/hooks/useAuth';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import PileScanner from '../barcode-reader/PileScanner';
import { ManagementUserTypeId } from '../../utilities/Constants';
import { useMutation } from '@tanstack/react-query';
import { getAllPileProblemHistory, getAllProblemOfPile } from '../../requests/ProductionServerHandler';
import { OpenErrorNotification } from '../../utilities/Helper/NotificationHelper';

HoldProblemForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
  isUnHold: PropTypes.bool,
};

function HoldProblemForm({ onSubmitCallback, isUnHold }) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const [holdProblems, setHoldProblems] = useState([]);
  const [selectProblems, setSelectProblems] = useState([]);
  const [selectPileId, setSelectPileId] = useState(null);

  const getAllProblemOfPileMutation = useMutation({
    mutationFn: isUnHold ? getAllPileProblemHistory : getAllProblemOfPile,
    onSuccess: async (data, variables) => {
      if (isUnHold || !data.IsOnHold) {
        setSelectPileId(variables);
        setHoldProblems(data.Problems);
      } else {
        await OpenErrorNotification(translate('error.error_qa_pile_already_hold'));
      }
    },
  });

  const onBarCodeScan = async (data) => {
    await getAllProblemOfPileMutation.mutateAsync(data.Id);
  };

  const onSelectChange = (event, value) => {
    if (value !== null) {
      setSelectProblems(value);
    } else {
      setSelectProblems([]);
    }
  };

  const handleSubmit = () => {
    if (selectProblems.length > 0 && selectPileId !== null) {
      const sendSelectProblems = selectProblems.map((problem) => problem.value);
      let uniqueProblems = [...new Set(sendSelectProblems)];
      onSubmitCallback({
        PileId: selectPileId,
        ProblemIds: uniqueProblems,
      });
    }
  };

  return (
    <div>
      {holdProblems.length > 0 ? (
        <React.Fragment>
          <Card>
            <CardHeader title={translate('qa.input_hold_problem_title')} />
            <CardContent>
              <Stack spacing={3}>
                <Autocomplete
                  multiple
                  options={holdProblems.map((customer) => {
                    return { title: customer.Name, value: customer.Id };
                  })}
                  getOptionLabel={(option) => option.title}
                  onChange={onSelectChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={translate('qa.quality_problem_title')}
                      fullWidth
                    />
                  )}
                />
                <Button color={'primary'} fullWidth variant={'contained'} onClick={handleSubmit}>
                  {translate('button.submit')}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </React.Fragment>
      ) : (
        <PileScanner
          onSearchCallBack={onBarCodeScan}
          isShowTooltips
          isPreFetchData
          disableInput={user.UserTypeId !== ManagementUserTypeId}
        />
      )}
    </div>
  );
}

export default HoldProblemForm;
