import * as Yup from 'yup';
import {useState} from 'react';
// form
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
// @mui
import {Stack, Alert, IconButton, InputAdornment} from '@mui/material';
import {LoadingButton} from '@mui/lab';
// hooks
import useAuth from 'auth/hooks/useAuth';
// components
import Iconify from 'components/minimal/iconify';
import FormProvider, {RHFTextField} from 'components/minimal/hook-form';
import {useLocales} from "locales";

// ----------------------------------------------------------------------

export default function LoginForm() {
    const {login} = useAuth();
    const {translate} = useLocales();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required(translate('error.username_require')),
        password: Yup.string().required(translate('error.password_require'))
    });

    const defaultValues = {
        username: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: {errors, isSubmitting},
    } = methods;

    const onSubmit = async (data) => {
        try {
            await login(data.username, data.password);
        } catch (error) {
            reset();
            setError('afterSubmit', {...error, message: translate('error.unable_to_login')});
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                <RHFTextField name="username" label={translate('auth.username')}/>
                <RHFTextField
                    name="password"
                    label={translate('auth.password')}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <LoadingButton fullWidth size="large" type="submit" color={'primary'} variant="contained" loading={isSubmitting}>
                    {translate('login')}
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
