import React, { useEffect } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import { adjustPurchaseCart, selectPurchaseCart } from '../../../redux/reducers/inventory';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import ProjectPaper from '../../customs/ProjectPaper';
import { Button, IconButton, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import GeneralTable from '../../customs/GeneralTable';
import { useCreatePRData } from '../../../requests/query/DropdownData';
import { paths } from '../../../routes/paths';
import { usePurchaseRequisition } from '../../../requests/query/usePurchaseRequisition';

function CreatePurchaseRequisitionPage() {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const PurchaseCart = useSelector(selectPurchaseCart);
  const { priorityOptions } = useCreatePRData();
  const { createPR } = usePurchaseRequisition(null);

  const reworkOrderSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });
  const methods = useForm({
    resolver: yupResolver(reworkOrderSchema),
  });
  const { handleSubmit, control, setValue } = methods;
  const detailWatch = useWatch({
    control,
    name: 'Detail',
    defaultValue: '',
  });
  const tempItemMasterWatch = useWatch({
    control,
    name: 'TempItemMaster',
    defaultValue: [],
  });

  useEffect(() => {
    const detailFromSessionStorage = sessionStorage.getItem('pr_detail');
    if (detailFromSessionStorage !== null) {
      setValue('Detail', detailFromSessionStorage);
    }
    const tempItemFromSessionStorage = JSON.parse(sessionStorage.getItem('pr_temp_item_master'));
    if (tempItemFromSessionStorage !== null && tempItemFromSessionStorage.length > 0) {
      setValue('TempItemMaster', tempItemFromSessionStorage);
    }
  }, [setValue]);
  useEffect(() => {
    sessionStorage.setItem('pr_detail', detailWatch);
  }, [dispatch, detailWatch]);
  useEffect(() => {
    sessionStorage.setItem('pr_temp_item_master', JSON.stringify(tempItemMasterWatch));
  }, [dispatch, tempItemMasterWatch]);

  const onAddItem = (itemDetail) => {
    dispatch(
      adjustPurchaseCart({
        Id: itemDetail.Id,
        Name: itemDetail.Name,
        Amount: itemDetail.Amount + 1,
      })
    );
  };
  const onRemoveItem = (itemDetail) => {
    dispatch(
      adjustPurchaseCart({
        Id: itemDetail.Id,
        Name: itemDetail.Name,
        Amount: itemDetail.Amount - 1,
      })
    );
  };
  const onDeleteItem = (itemDetail) => {
    dispatch(adjustPurchaseCart({ Id: itemDetail.Id, Name: itemDetail.Name, Amount: 0 }));
  };

  const onCreatePurchaseRequisition = async (data) => {
    let tempItemMaster = [];
    if (data.TempItemMaster !== undefined) {
      tempItemMaster = data.TempItemMaster.map((template) => {
        return {
          Name: template.Name,
          Detail: template.Detail,
          Amount: template.Amount,
          ItemCategoryId: template.ItemCategory.Id,
          ItemTypeId: template.ItemType.Id,
          ItemUnit: template.ItemUnit,
        };
      });
    }

    await createPR.mutateAsync({
      Detail: data.Detail,
      PriorityLevelId: data.PriorityLevel.Id,
      PurchaseRequisitionTempItemMaster: tempItemMaster,
      PurchaseRequisitionItemMaster: PurchaseCart,
    });
  };

  const handleOpenRequestItemStorePage = () => {
    navigate(paths.procurement.store);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.request_buy_material_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.request_buy_material_title') },
      ]}
      action={
        <Button
          variant={'contained'}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          onClick={handleOpenRequestItemStorePage}
          color={'primary'}
        >
          {translate('button.add')}
        </Button>
      }
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onCreatePurchaseRequisition)}>
        <ProjectPaper>
          <Stack spacing={3}>
            <RHFTextField name={'Detail'} label={translate('title.detail')} />
            {priorityOptions.length > 0 && (
              <RHFAutoCompleteShort
                name={'PriorityLevel'}
                label={translate('title.priority_level_title')}
                data={priorityOptions}
              />
            )}
          </Stack>
        </ProjectPaper>
        <ProjectPaper>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: '#' },
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'title.amount' },
              { align: 'left', title: '' },
            ]}
            tableData={PurchaseCart.map((item, index) => {
              return [
                index + 1,
                <Link href={paths.warehouse.item_master_detail(item.Id)}>{item.Name}</Link>,
                item.Amount,
                <>
                  <IconButton
                    disabled={item.Amount >= item.MaxAmount}
                    color={'success'}
                    onClick={() => onAddItem(item)}
                  >
                    <Iconify icon={'ant-design:plus-outlined'} width={20} height={20} />
                  </IconButton>
                  <IconButton color={'error'} onClick={() => onRemoveItem(item)}>
                    <Iconify icon={'ant-design:minus-outlined'} width={20} height={20} />
                  </IconButton>
                  <IconButton color={'error'} onClick={() => onDeleteItem(item)}>
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>
                </>,
              ];
            })}
          />
          <Button
            disabled={PurchaseCart.length === 0}
            variant={'contained'}
            fullWidth
            color={'primary'}
            type="submit"
          >
            {translate('button.create')}
          </Button>
        </ProjectPaper>
      </FormProvider>
    </ProjectPageContainer>
  );
}

export default CreatePurchaseRequisitionPage;
