import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import React from 'react';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { paths } from '../../../routes/paths';

function SearchMaintenancePage() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const searchType = [{ value: 1, Name: translate('maintenance.search_mt_job') }];

  const onSearch = async (searchString, type) => {
    if (type === 1) {
      navigate(paths.maintenance.job_detail(searchString));
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[{ name: translate('maintenance.title') }, { name: translate('button.search') }]}
    >
      <SearchBarWithSelectType
        search_label={translate('button.search')}
        select_options={searchType}
        onSearchCallBack={onSearch}
      />
    </ProjectPageContainer>
  );
}

export default SearchMaintenancePage;
