import { PrintPDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { useLoading } from '../../components/loading';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createStockReport,
  getStockReportById,
  printStockReportPDF,
  returnItemToWarehouse,
} from '../ProductionServerHandler';
import {
  OpenErrorNotification, OpenNotificationThenReloadPage,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';

export const usePartStockReport = (reportId) => {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['stock-report', reportId],
    queryFn: () => getStockReportById(reportId),
    initialData: null,
    enabled: reportId !== null,
  });
  const printReportMutation = useMutation({
    mutationFn: printStockReportPDF,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await PrintPDFMakeDocument(response, `stock_report.pdf`);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  const createMutation = useMutation({
    mutationFn: createStockReport,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await printReportMutation.mutateAsync(response);
      await OpenNotificationThenReloadPage();
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  const returnMutation = useMutation({
    mutationFn: returnItemToWarehouse,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      hideLoadingScreen();
      await printReportMutation.mutateAsync(response);
      await OpenSuccessNotification();
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    },
  });
  return {
    itemTemplateRequestReportDetail: data,
    printReport: printReportMutation,
    create: createMutation,
    returnItemsToStock: returnMutation,
  };
};
