import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  cancelRequestReport,
  getAllItemTemplateRequestReports,
} from '../../../requests/ProductionServerHandler';
import {
  OpenSuccessNotification,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import { IconButton } from '@mui/material';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { OPEN_ICON } from '../../../config-global';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

const ItemTemplateRequestReportDashboardPage = () => {
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { data: requestReports } = useQuery({
    queryKey: ['getAllItemTemplateRequestReports'],
    queryFn: getAllItemTemplateRequestReports,
    initialData: [],
  });

  const cancelReportMutation = useMutation({
    mutationFn: cancelRequestReport,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['getAllItemTemplateRequestReports']);
    },
  });

  const handleCancelRequestReport = async (reportId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancelReportMutation.mutate(reportId);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.request_list')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.request_list') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          data={requestReports.map((report) => {
            return {
              id: report.Id,
              detail: report.Detail,
              create_by: report.CreateEmployee,
              department: report.CreateEmployee.Department.Name,
              create_at: report.createdAt,
              actions: report.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.detail'),
              accessorKey: 'detail',
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'create_by',
              cell: (cell) => getEmployeeFullName(cell.getValue()),
            },
            {
              header: translate('user.department'),
              accessorKey: 'department',
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create_at',
              cell: (cell) => fDateTimeText(cell.getValue()),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (cell) => (
                <>
                  <IconButton
                    color={'error'}
                    onClick={() => handleCancelRequestReport(cell.getValue())}
                  >
                    <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                  </IconButton>
                  <IconButton
                    component={Link}
                    href={paths.general_warehouse.request_report_detail(cell.getValue())}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default ItemTemplateRequestReportDashboardPage;
