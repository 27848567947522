import React from 'react';
import PropTypes from 'prop-types';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';
import { Stack, Button } from '@mui/material';
import ProjectPaper from '../customs/ProjectPaper';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../locales';

EditSupplierSellItemForm.propTypes = {
  price: PropTypes.number.isRequired,
  amountPerContainer: PropTypes.number.isRequired,
  currentContainerId: PropTypes.number.isRequired,
  itemName: PropTypes.string.isRequired,
  itemUnit: PropTypes.object.isRequired,
  itemContainerOptions: PropTypes.array.isRequired,
  itemUnitOptions: PropTypes.array.isRequired,
  onCompleteForm: PropTypes.func.isRequired,
};

function EditSupplierSellItemForm({ price, amountPerContainer, currentContainerId, itemContainerOptions, itemName, itemUnit, itemUnitOptions, onCompleteForm }) {
  const { translate } = useLocales();

  const editSupplierSellItemSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    Price: Yup.number().required(translate('error.name_is_require')),
    SellUnitPerItemContainerUnitAmount: Yup.number().required(translate('error.invalid_input')),
    SellItemUnit: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    ItemContainer: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(editSupplierSellItemSchema),
    defaultValues: {
      Name: itemName,
      SellItemUnit: itemUnit,
      Price: price,
      SellUnitPerItemContainerUnitAmount: amountPerContainer,
      ItemContainer: itemContainerOptions.find((itemContainer) => itemContainer.Id === currentContainerId),
    },
  });

  const onSubmit = (values) => {
    onCompleteForm(values);
  };

  const { handleSubmit } = methods;

  return (
    <ProjectPaper>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <RHFTextField name="Name" label={translate('title.name')} />
          <RHFTextField name="Price" label={translate('title.price')} />
          <RHFTextField name="SellUnitPerItemContainerUnitAmount" label={'จำนวนของบรรจุที่ได้ต่อ หน่วยขาย เช่น 54 กิโลกรัม ได้ 1 ม้วน ให้ใส่ช่องนี้ 54'} />
          <RHFAutoCompleteShort
            name="ItemContainer"
            label={`ภาชนะบรรจุ ที่จะได้หลังรับเข้า`}
            data={itemContainerOptions}
          />
          <RHFAutoCompleteShort
            name="SellItemUnit"
            label={translate('procurement.sell_unit')}
            data={itemUnitOptions}
          />
          <Button color={'primary'} variant={'contained'} fullWidth type="submit">
            {translate('button.save')}
          </Button>
        </Stack>
      </FormProvider>
    </ProjectPaper>
  );
}

export default EditSupplierSellItemForm;
