import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getEmployeeDetailById } from '../../../requests/ProductionServerHandler';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import Avatar from '@mui/material/Avatar';
import { Paper, Stack } from '@mui/material';
import MaintenancePerformanceDetail from '../../details/maintenance/MaintenancePerformanceDetail';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import Grid from '@mui/material/Grid';
import GridDetailText from '../../customs/GridDetailText';
import createAvatar from '../../../utilities/createAvatar';
import ProjectPaper from 'components/customs/ProjectPaper';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

function EmployeeDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();

  const { data: employeeDetail } = useQuery({
    queryKey: ['employee', id],
    queryFn: () => getEmployeeDetailById(id),
    initialData: null,
  });

  const getEmployeePerformanceByDepartment = (employeeId, departmentId) => {
    if (departmentId === 3) {
      return <MaintenancePerformanceDetail employeeId={employeeId} />;
    } else {
      return null;
    }
  };
  return (
    <ProjectPageContainer
      menu_title={translate('user.employee_detail')}
      breadcrumbs={[
        { name: translate('hr.title') },
        { name: translate('hr.employee_list'), href: paths.hr.employee_list },
        { name: `${translate('user.employee_detail')}${translate('title.id')}: ${id}` },
      ]}
    >
      {employeeDetail !== null && (
        <Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Paper sx={{ py: 10, px: 3, textAlign: 'center' }} elevation={3}>
                <Avatar
                  src={`${process.env.REACT_APP_PROFILE_IMAGE_PREFIX}${employeeDetail.EmployeeCode}.jpg`}
                  alt={employeeDetail.Name}
                  color={
                    employeeDetail.EmployeeCode
                      ? 'default'
                      : createAvatar(employeeDetail.Name).color
                  }
                  sx={{
                    mx: 'auto',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'common.white',
                    width: { xs: 80, md: 128 },
                    height: { xs: 80, md: 128 },
                  }}
                >
                  {createAvatar(employeeDetail.Name).name}
                </Avatar>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <ProjectPaper>
                <Grid container spacing={3}>
                  <GridDetailText
                    title={translate('user.employee_uid')}
                    value={employeeDetail.Id}
                  />
                  <GridDetailText
                    title={translate('title.name')}
                    value={getEmployeeFullName(employeeDetail)}
                  />
                  <GridDetailText
                    title={translate('user.employee_code')}
                    value={employeeDetail.EmployeeCode}
                  />
                  <GridDetailText
                    title={translate('user.department')}
                    value={employeeDetail?.Department.Name}
                  />
                </Grid>
              </ProjectPaper>
            </Grid>
          </Grid>
          {getEmployeePerformanceByDepartment(employeeDetail.Id, employeeDetail.Department.Id)}
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default EmployeeDetailPage;
