import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@mui/material";
import GridDetailText from "../customs/GridDetailText";
import {getFullAddressText} from "../../utilities/Helper/UtilitiesHelper";
import ProjectPaper from "../customs/ProjectPaper";
import { useLocales } from "locales";

SupplierDetail.propTypes = {
    supplier: PropTypes.object.isRequired
};

function SupplierDetail({supplier}) {
    const {translate} = useLocales();
    return (
        <ProjectPaper>
            <Grid container spacing={3}>
                <GridDetailText title={translate('title.id')} value={supplier.Detail.Id}/>
                <GridDetailText title={translate('title.name')} value={supplier.Detail.Name}/>
                <GridDetailText title={translate('title.additional_address')} value={getFullAddressText(supplier.Detail.District,supplier.Detail.AdditionAddress)}/>
                <GridDetailText title={translate('title.tax_registration_number')} value={supplier.Detail.TaxRegistrationNumber}/>
                <GridDetailText title={translate('title.telephone_number')} value={supplier.Detail.TelephoneNumber}/>
                <GridDetailText title={translate('procurement.contact_name_title')} value={supplier.Detail.ContactName}/>
                <GridDetailText title={translate('procurement.total_purchase_value')} value={supplier.TotalPurchaseValue}/>
            </Grid>
        </ProjectPaper>
    );
}

export default SupplierDetail;
