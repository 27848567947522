import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { PrintMultiplePDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { Button, IconButton, Stack } from '@mui/material';
import GeneralTable from '../../customs/GeneralTable';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import {
  getItemReturnReportPDF,
  returnItemToWarehouse,
} from '../../../requests/WarehouseServerHandler';
import Swal from 'sweetalert2';
import { return_item_pdf_prefix } from '../../../utilities/Strings/Prefix';
import MaterialScanner from '../../barcode-reader/MaterialScanner';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';

export default function ReturnItemPage() {
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { translate } = useLocales();
  const [returnItems, setReturnItems] = useState([]);

  const getItemReturnReportPDFMutation = useMutation({
    mutationFn: getItemReturnReportPDF,
    onSuccess: async (data, variables) => {
      hideLoadingScreen();
      await PrintMultiplePDFMakeDocument([data], `${return_item_pdf_prefix}_${variables}.pdf`);
      await OpenNotificationThenReloadPage();
    },
  });

  const returnItemToWarehouseMutation = useMutation({
    mutationFn: returnItemToWarehouse,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (data) => {
      await getItemReturnReportPDFMutation.mutateAsync(data.Id);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
      hideLoadingScreen();
    },
  });
  const handleSearchItem = async (item) => {
    if (item !== null) {
      const { value: amount } = await Swal.fire({
        title: translate('warehouse.amount_to_return'),
        input: 'number',
        inputLabel: `${item.ItemLot.ItemContainer.ItemMaster.Name}`,
        inputPlaceholder: translate('title.amount'),
        inputValidator: async (value) => {
          if (!value) {
            return translate('error.error_invalid_amount');
          } else {
            const amount = parseFloat(value);
            if (isNaN(amount)) {
              return translate('error.error_invalid_amount');
            } else {
              if (amount <= 0) {
                return translate('error.error_invalid_amount');
              }
            }
          }
        },
      });

      if (amount) {
        await onAddItem(amount, item);
      }
    }
  };
  const onAddItem = async (amount, itemToReturn) => {
    if (amount <= itemToReturn.ItemLot.ItemContainer.ItemMasterSize - itemToReturn.RemainAmount) {
      let isDuplicate = false;
      let adjustReturnItems = returnItems.map((item) => {
        if (item.Id === itemToReturn.Id) {
          isDuplicate = true;
          return {
            Id: itemToReturn.Id,
            Name: itemToReturn.ItemLot.ItemContainer.ItemMaster.Name,
            BarcodeId: itemToReturn.BarcodeId,
            Amount: amount,
          };
        } else {
          return item;
        }
      });

      if (!isDuplicate) {
        adjustReturnItems.push({
          Id: itemToReturn.Id,
          Name: itemToReturn.ItemLot.ItemContainer.ItemMaster.Name,
          BarcodeId: itemToReturn.BarcodeId,
          IsDefect: false,
          Amount: amount,
        });
      }
      setReturnItems(adjustReturnItems);
    } else {
      await OpenErrorNotification(translate('error.error_invalid_amount'));
    }
  };
  const onCheckDefect = (defectItem) => {
    let adjustReturnItem = returnItems.map((item) => {
      if (item.Id === defectItem.Id) {
        item.IsDefect = !item.IsDefect;
        return item;
      } else {
        return item;
      }
    });
    setReturnItems(adjustReturnItem);
  };
  const onRemoveItem = (removeItem) => {
    let adjustReturnItems = returnItems.filter((item) => item.Id !== removeItem.Id);
    setReturnItems(adjustReturnItems);
  };
  const onSubmitReturn = async (values) => {
    await returnItemToWarehouseMutation.mutateAsync({
      ReturnItems: returnItems,
      Detail: values.Detail,
    });
  };

  const inventoryCheckoutSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(inventoryCheckoutSchema),
  });

  const { handleSubmit } = methods;
  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.return_item')}
      breadcrumbs={[
        { name: translate('warehouse.title') },
        { name: translate('warehouse.return_item') },
      ]}
    >
      <Stack spacing={3}>
        <ProjectPaper>
          <GeneralTable
            tableHeaders={[
              { align: 'left', title: '#' },
              { align: 'left', title: 'title.name' },
              { align: 'left', title: 'warehouse.barcode_id' },
              { align: 'left', title: 'title.amount' },
              { align: 'left', title: '' },
            ]}
            tableData={returnItems.map((item, index) => {
              return [
                index + 1,
                item.Name,
                item.BarcodeId,
                item.Amount,
                [
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => onCheckDefect(item)}
                          checked={item.IsDefect}
                        />
                      }
                      label="ของเสีย"
                    />
                    <IconButton color={'error'} onClick={() => onRemoveItem(item)}>
                      <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                    </IconButton>
                  </>,
                ],
              ];
            })}
          />
        </ProjectPaper>
        {returnItems.length > 0 && (
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitReturn)}>
              <Stack spacing={3}>
                <RHFTextField name="Detail" label={translate('title.detail')} />
                <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
                  {translate('button.submit')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        )}
        <MaterialScanner onSearchCallBack={handleSearchItem} isPreFetchData />
      </Stack>
    </ProjectPageContainer>
  );
}
