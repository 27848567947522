import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort } from '../minimal/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { getAllQualityProcessOptions } from 'requests/ProductionServerHandler';
import Button from '@mui/material/Button';

SelectQualityProcessForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
  defaultCustomer: PropTypes.object,
};

function SelectQualityProcessForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const { data: qualityProcesses } = useQuery({
    queryKey: ['select-quality-process-data'],
    queryFn: getAllQualityProcessOptions,
    initialData: [],
  });

  const selectQualityProcessSchema = Yup.object().shape({
    QualityProcess: Yup.object()
      .shape({
        Id: Yup.number().min(1).required('required'),
        Name: Yup.string().required('required'),
      })
      .required('Customer is required'),
  });
  const methods = useForm({
    resolver: yupResolver(selectQualityProcessSchema),
    defaultValues: {
      QualityProcess: { Id: 0, Name: '' },
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = (values) => {
    onSubmitCallback(values.QualityProcess);
  };

  return (
    <Card>
      <CardHeader title={translate('planning.select_quality_process')} />
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFAutoCompleteShort
            data={qualityProcesses}
            name="QualityProcess"
            label={translate('planning.quality_process')}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              mt: 3,
            }}
          >
            <Button color={'primary'} variant="contained" type={'submit'}>
              {translate('button.next')}
            </Button>
          </Box>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default SelectQualityProcessForm;
