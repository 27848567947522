import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import { TableRow, TableCell, Typography, Stack, IconButton } from '@mui/material';
// utils
import { fDateTimeDMY } from '../../utilities/formatTime';
// components
import Label from '../minimal/label';
import Iconify from '../minimal/iconify';
import { OPEN_ICON } from '../../config-global';
import { fNumber } from '../../utilities/formatNumber';

// ----------------------------------------------------------------------

ProductOrderTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  onViewRow: PropTypes.func,
};

export default function ProductOrderTableRow({ row, onViewRow }) {
  const theme = useTheme();
  const {
    Id,
    Amount,
    Customer,
    createdAt,
    DueDatedAt,
    Name,
    ProductOrderStatus,
    TotalInAmount,
    TotalGoodAmount,
    TotalProblem,
  } = row;

  return (
    <TableRow hover>
      <TableCell padding="checkbox">
        <Typography noWrap variant="body2">
          {Id}
        </Typography>
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack>
          <Typography variant="subtitle2">{Name}</Typography>

          <Typography variant="body2" sx={{ color: 'text.disabled' }}>
            {Customer.Name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">{fDateTimeDMY(createdAt)}</TableCell>

      <TableCell align="left">{fDateTimeDMY(DueDatedAt)}</TableCell>

      <TableCell align="center">{fNumber(Amount)}</TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {!Number.isNaN(TotalInAmount) ? fNumber(TotalInAmount) : 0}
      </TableCell>

      <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {!Number.isNaN(TotalGoodAmount) ? fNumber(TotalGoodAmount) : 0}
      </TableCell>

      <TableCell align="left">
        {TotalProblem > 0 ? (
          <Label
            variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
            color={'warning'}
            sx={{ textTransform: 'capitalize' }}
          >
            {`${ProductOrderStatus.Name} (พบ ${TotalProblem} ปัญหา)`}
          </Label>
        ) : (
          <Label
            variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
            color={
              (ProductOrderStatus.Id === 1 && 'success') ||
              (ProductOrderStatus.Id === 2 && 'info') ||
              (ProductOrderStatus.Id === 5 && 'warning') ||
              (ProductOrderStatus.Id === 6 && 'error') ||
              'default'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {ProductOrderStatus.Name}
          </Label>
        )}
      </TableCell>

      <TableCell align="right">
        <IconButton onClick={onViewRow}>
          <Iconify icon={OPEN_ICON} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
