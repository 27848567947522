import React from 'react';
import { Box } from '@mui/material';
import { useLocales } from 'locales';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useTabs from '../../../hooks/useTabs';
import MachinePlanProductOrderView from './MachinePlanProductOrderView';
import MachinePlanMachineView from './MachinePlanMachineView';
import { toggleMachineList, toggleRefreshInMachinePlan } from '../../../redux/reducers/planning';
import { useDispatch } from 'react-redux';

function MachinePlanningList() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { currentTab, onChangeTab } = useTabs('order');

  const INFORMATION_TABS = [
    {
      value: 'order',
      title: translate('planning.order_view'),
      component: <MachinePlanProductOrderView />,
    },
    {
      value: 'machine',
      title: translate('planning.machine_view'),
      component: <MachinePlanMachineView />,
    },
  ];

  const handleChangeTab = async (event, newValue) => {
    if (newValue === 'order') {
      dispatch(toggleRefreshInMachinePlan(true));
    } else {
      dispatch(toggleMachineList(true));
    }
    onChangeTab(event, newValue);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </Box>
  );
}

export default MachinePlanningList;
