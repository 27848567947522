export function checkBundlePrice(amount, minimumToBundle) {
    return amount <= minimumToBundle
}

export function getEmployeeFullName(employee)
{
    if(employee !== undefined && employee !== null)
    {
        return employee.FirstName.concat(' ',employee.LastName)
    }else {
        return 'N/A'
    }
}

export function getFullAddressText(districtObject, additionAddress)
{
    return `${additionAddress} ${districtObject.Name} ${districtObject.Amphur.Name} ${districtObject.Amphur.Province.Name} ${districtObject.Amphur.Postcode}`
}

export function isAllowToSellPrice(width, length, sellPricePerSheet, minPricePerSheet, minPricePerSquareInch) {
    const totalSquareInch = width * length;
    //ราคาที่ควรจะขาย
    const minRealPriceToSellPerSheet = roundUpNumber(totalSquareInch * minPricePerSquareInch, 2);
    if (sellPricePerSheet >= 0) {
        return sellPricePerSheet >= minRealPriceToSellPerSheet;
    } else {
        return false
    }

}

export function checkBundle(amount, minimumToBundle) {
    return amount <= minimumToBundle
}

export function roundUpNumber(num, precision) {
    return Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision);
}

export const numberTextWithCommas = (number,addTHB=false) => {
    if(addTHB)
    {
        return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").concat(' ','บาท');
    }else{
        return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

}


