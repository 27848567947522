import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import React, { useState } from 'react';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import useAuth from '../../../auth/hooks/useAuth';
import { paths } from '../../../routes/paths';
import { EmployeeScanner } from '../../barcode-reader';

function SearchProductionDetailPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { user } = useAuth();

  const [employee, setEmployee] = useState(
    user !== null
      ? {
          Id: user.EmployeeId,
          Name: user.Name,
          Department: user.Department,
          EmployeeCode: user.EmployeeCode,
          IsTemp: false,
        }
      : {
          Id: 0,
          Name: null,
          Department: null,
          EmployeeCode: null,
          IsTemp: true,
        }
  );

  const searchType = [
    { value: 1, Name: translate('production.search_product_order') },
    { value: 2, Name: translate('production.search_pile') },
    { value: 3, Name: translate('production.search_by_name') },
  ];

  const handleGetEmployeeDetail = (employeeDetail) => {
    setEmployee({
      Id: employeeDetail.Id,
      Name: getEmployeeFullName(employeeDetail),
      Department: employeeDetail.Department,
      EmployeeCode: employeeDetail.EmployeeCode,
      IsTemp: true,
    });
  };

  const onSearch = async (searchString, type) => {
    if (type === 1) {
      let searchId = searchString;
      if (searchString.includes('PileId')) {
        const dataJson = JSON.parse(searchString);
        searchId = dataJson.PileId;
      }
      navigate(paths.production.product_order_detail(searchId));
    } else if (type === 2) {
      let searchId = searchString;
      if (searchString.includes('OrderId')) {
        const dataJson = JSON.parse(searchString);
        searchId = dataJson.OrderId;
      }
      navigate(paths.production.pile_detail(searchId));
    } else if (type === 3) {
      navigate(`${paths.production.search_product_order_result}?search=${searchString}`);
    }
  };

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[{ name: translate('production.title') }, { name: translate('button.search') }]}
    >
      {employee.Id !== 0 ? (
        <React.Fragment>
          <SearchBarWithSelectType
            search_label={translate('button.search')}
            select_options={searchType}
            onSearchCallBack={onSearch}
          />
        </React.Fragment>
      ) : (
        <EmployeeScanner onScanCallback={handleGetEmployeeDetail} isPreFetchData isShowTooltips />
      )}
    </ProjectPageContainer>
  );
}

export default SearchProductionDetailPage;
