import {
  createItemTemplate,
  editItemTemplateAmount,
  editItemTemplateDetail,
  getItemTemplateDetailById,
  toggleQuickRequestItem,
  uploadItemTemplateImage,
} from '../ProductionServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getItemTemplateQRCodeDocument,
  getItemTemplateQRCodeStickerDocument,
} from '../../utilities/Helper/DocumentStructure';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import Swal from 'sweetalert2';
import { useLocales } from '../../locales';
import { useLoading } from '../../components/loading';

export const useItemTemplateDetail = (id) => {
  const { translate } = useLocales();
  const queryClient = useQueryClient();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const { data, refetch } = useQuery({
    queryKey: ['item-template-detail', id],
    queryFn: () => getItemTemplateDetailById(id),
    enabled: id !== null,
    initialData: null,
  });

  const toggleQuickRequestItemMutation = useMutation({
    mutationFn: toggleQuickRequestItem,
    onSuccess: async () => {
      if (data.IsQuickRequestEnable) {
        await OpenSuccessNotification(translate('notification.success_remove_quick_request_item'));
      } else {
        await OpenSuccessNotification(translate('notification.success_save_quick_request_item'));
      }
      await queryClient.invalidateQueries(['item-template-detail', id]);
    },
  });

  const editAmountMutation = useMutation({
    mutationFn: editItemTemplateAmount,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['item-template-detail', id]);
      await OpenSuccessNotification();
    },
  });
  const editDetailMutation = useMutation({
    mutationFn: editItemTemplateDetail,
    onSuccess: async (response) => {
      if (response.IsError !== undefined && response.IsError !== null) {
        await OpenErrorNotification(response.IsError);
      } else {
        await queryClient.invalidateQueries(['item-template-detail', id]);
        await OpenSuccessNotification();
      }
    },
  });

  const createMutation = useMutation({
    mutationFn: createItemTemplate,
    onMutate: () => {
      showLoadingScreen();
    },
    onSuccess: async (response) => {
      if (response.IsError !== undefined && response.IsError !== null) {
        await OpenErrorNotification(response.IsError);
        hideLoadingScreen();
        return null;
      } else {
        hideLoadingScreen();
        await OpenSuccessNotification();
        return response.data;
      }
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    }
  });

  const uploadImageMutation = useMutation({
    mutationFn: uploadItemTemplateImage,
    onMutate: () => {
      showLoadingScreen();
    },
    onSuccess: async () => {
      hideLoadingScreen();
      await queryClient.invalidateQueries(['item-template-detail', id]);
    },
    onError: async (error) => {
      hideLoadingScreen();
      await OpenErrorNotification(error);
    }
  });

  const printItemTemplateQRCode = async () => {
    const pdfStructure = getItemTemplateQRCodeDocument(data.Id, data.Name);
    const printing = await PrintMultiplePDFMakeDocument([pdfStructure], `QRCode_${data.Id}.pdf`);
    if (printing === 'OK') {
      await OpenSuccessNotification();
    }
  };
  const printItemTemplateQRStickerCode = async () => {
    await Swal.fire({
      title: translate('warehouse.amount_to_print'),
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: translate('button.confirm'),
      cancelButtonText: translate('button.cancel'),
      inputValidator: async (value) => {
        if (!value) {
          return translate('error.error_invalid_amount');
        } else {
          const amount = parseFloat(value);
          if (isNaN(amount)) {
            return translate('error.error_invalid_amount');
          } else {
            if (amount <= 0) {
              return translate('error.error_invalid_amount');
            }
            if (amount >= 30) {
              return translate('error.maximum_reach');
            }
          }
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const pdfStructure = getItemTemplateQRCodeStickerDocument(data.Id, data.Name, result.value);
        const printing = await PrintMultiplePDFMakeDocument(
          [pdfStructure],
          `QRCode_${data.Id}.pdf`
        );
        if (printing === 'OK') {
          await OpenSuccessNotification();
        }
      }
    });
  };

  return {
    itemTemplateDetail: data,
    printItemTemplateQRCode,
    printItemTemplateQRStickerCode,
    toggleQuickRequestItem: toggleQuickRequestItemMutation,
    editAmount: editAmountMutation,
    editDetail: editDetailMutation,
    create: createMutation,
    uploadImage: uploadImageMutation,
    refetch,
  };
};
