import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import PropTypes from 'prop-types';

const ScanOverlay = () => {
  return (
    <svg
      viewBox={'0 0 100 100'}
      style={{
        top: 0,
        left: 0,
        zIndex: 1,
        boxSizing: 'border-box',
        border: '50px solid transparent',
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <path fill={'none'} d={'M13,0 L0,0 L0,13'} stroke={'rgba(255, 0, 0, 0.9)'} strokeWidth={5} />
      <path
        fill={'none'}
        d={'M0,87 L0,100 L13,100'}
        stroke={'rgba(255, 0, 0, 0.9)'}
        strokeWidth={5}
      />
      <path
        fill={'none'}
        d={'M87,100 L100,100 L100,87'}
        stroke={'rgba(255, 0, 0, 0.9)'}
        strokeWidth={5}
      />
      <path
        fill={'none'}
        d={'M100,13 L100,0 87,0'}
        stroke={'rgba(255, 0, 0, 0.9)'}
        strokeWidth={5}
      />
    </svg>
  );
};

CameraScanner.propsType = {
  onScanCallback: PropTypes.func.isRequired,
};
function CameraScanner({ onScanCallback }) {
  const { translate } = useLocales();
  const [cameraMode, setCameraMode] = useState('environment');
  const onChangeCameraMode = () => {
    setCameraMode((prev) => (prev === 'environment' ? 'user' : 'environment'));
  };
  const onCameraBarCodeScan = (data) => {
    onScanCallback(data);
  };
  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <QrReader
        scanDelay={300}
        containerStyle={{
          width: '300px',
          height: '300px',
        }}
        onResult={async (result, error) => {
          if (result) {
            onCameraBarCodeScan(result?.getText());
          }
        }}
        constraints={{ facingMode: cameraMode }}
        ViewFinder={ScanOverlay}
      />
      <Typography>{translate('placeholder.camera_guide')}</Typography>
      <Button
        onClick={onChangeCameraMode}
        color={'info'}
        startIcon={<Iconify icon={'ant-design:camera-outlined'} />}
        variant={'contained'}
      >
        {cameraMode === 'environment'
          ? translate('button.front_camera')
          : translate('button.rear_camera')}
      </Button>
    </Stack>
  );
}

export default CameraScanner;
