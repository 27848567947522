import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { getItemMasterSellerList } from '../../../requests/WarehouseServerHandler';
import { adjustPOCart, selectPurchaseOrderItemCart } from '../../../redux/reducers/procurement';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { fNumber } from '../../../utilities/formatNumber';
import ReactTable from '../../react-table/ReactTable';
import { showRequestToBuyItemInput } from '../../../utilities/Helper/NotificationHelper';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { RouterLink } from '../../../routes/components';

function SearchSupplierSellItemPage() {
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const PurchaseOrderItems = useSelector(selectPurchaseOrderItemCart);

  const { data: itemMasterStore } = useQuery({
    queryKey: ['getItemMasterSellerList', id],
    queryFn: () => getItemMasterSellerList(id),
    initialData: null,
  });

  const onConfirm = (item, amount) => {
    dispatch(
      adjustPOCart({
        Item: {
          Id: item.Id,
          Name: item.Name,
          Detail: null,
          Unit: item.Unit,
          Price: item.Price,
          Amount: amount,
          PRItemMasterId: item.PRItemMasterId,
          IsTemp: false,
        },
        Supplier: item.Supplier,
      })
    );
  };

  const handleShowRequestToBuyItemInput = async (item, recommendValue) => {
    await showRequestToBuyItemInput(translate, item, recommendValue, onConfirm);
  };
  const getRecommendBuyAmount = (sellItemDetail) => {
    const targetAmount = itemMasterStore.Amount;
    const sellFullContainerSize = sellItemDetail.ItemMasterUnitAmount;

    return Math.ceil(targetAmount / sellFullContainerSize);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.search_purchase_item_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.create_po_title'), href: paths.procurement.create_po },
        { name: `${translate('procurement.search_purchase_item_title')} : ${id}` },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            component={RouterLink}
            to={`${paths.procurement.create_supplier_sell_item}?SupplierId=${null}&ItemMasterId=${
              itemMasterStore?.ItemMasterId
            }&SellItemId=${id}`}
          >
            {translate('procurement.add_supplier_sell_item')}
          </Button>
          <Button
            variant="contained"
            color={'primary'}
            startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
            component={RouterLink}
            disabled={PurchaseOrderItems.length === 0}
            to={paths.procurement.check_out_po}
          >
            {`${translate('procurement.buy_item')} ${PurchaseOrderItems.length} ${translate(
              'warehouse.items'
            )}`}
          </Button>
        </Stack>
      }
    >
      {itemMasterStore !== null && (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('title.name')}
                value={itemMasterStore.ItemMaster.Name}
                link={paths.warehouse.item_master_detail(itemMasterStore.ItemMaster.Id)}
              />
              <GridDetailText
                title={translate('warehouse.request_buy_item_amount_title')}
                value={`${fNumber(itemMasterStore.Amount)} ${
                  itemMasterStore.ItemMaster.ItemUnit.Name
                }`}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <ReactTable
              search
              data={itemMasterStore.ItemMaster.SupplierSellItemContainers.map((sellItem) => {
                return {
                  name: sellItem.Name,
                  container: sellItem.ItemContainer.Name,
                  supplier: sellItem.Supplier.Name,
                  unit: sellItem.SellItemUnit.Name,
                  price_per_unit: sellItem.PricePerSellUnit,
                  convert_unit: `${fNumber(sellItem.ItemMasterUnitAmount)} ${
                    itemMasterStore.ItemMaster.ItemUnit.Name
                  }`,
                  recommend_unit: getRecommendBuyAmount(sellItem),
                  actions: sellItem,
                };
              })}
              header={[
                {
                  header: translate('procurement.supplier_sell_item_name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('warehouse.container_detail_title'),
                  accessorKey: 'container',
                },
                {
                  header: translate('procurement.supplier'),
                  accessorKey: 'supplier',
                },
                {
                  header: translate('procurement.sell_unit'),
                  accessorKey: 'unit',
                },
                {
                  header: translate('warehouse.convert_to_master_amount'),
                  accessorKey: 'convert_unit',
                },
                {
                  header: translate('procurement.price_average_detail_title'),
                  accessorKey: 'price_per_unit',
                },
                {
                  header: translate('procurement.recommend_to_buy_amount'),
                  accessorKey: 'recommend_unit',
                },
                {
                  header: '',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <>
                      <IconButton
                        onClick={() =>
                          navigate(paths.procurement.edit_supplier_sell_item(info.getValue().Id))
                        }
                      >
                        <Iconify icon={'ic:baseline-edit'} width={20} height={20} />
                      </IconButton>
                      <IconButton
                        color={'success'}
                        onClick={() =>
                          handleShowRequestToBuyItemInput(
                            {
                              Id: info.getValue().Id,
                              Name: info.getValue().Name,
                              Unit: info.getValue().SellItemUnit.Name,
                              Price: info.getValue().PricePerSellUnit,
                              Supplier: info.getValue().Supplier,
                              PRItemMasterId: id,
                            },
                            getRecommendBuyAmount(info.getValue())
                          )
                        }
                      >
                        <Iconify icon={'ic:baseline-add-shopping-cart'} width={20} height={20} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </React.Fragment>
      )}
    </ProjectPageContainer>
  );
}

export default SearchSupplierSellItemPage;
