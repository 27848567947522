import Swal from 'sweetalert2';
import { error_contact_admin, success_please_save_your_file } from '../Strings/NotificationString';

const complete_title = 'สำเร็จ';
const error_title = 'Error';

export async function OpenNotificationThenCallback(title = 'Complete', body = '', callBack = null) {
  return Swal.fire(title, body, 'success').then(() => {
    if (callBack !== null) {
      callBack();
    }
  });
}

export async function OpenSuccessNotification(body = '') {
  return await Swal.fire(complete_title, body, 'success');
}

export async function OpenErrorNotification(errorMessage = '') {
  try {
    return await Swal.fire(error_title, errorMessage, 'error');
  } catch (e) {
    return await Swal.fire(error_title, error_contact_admin, 'error');
  }
}

export async function OpenErrorNotificationThenReload(errorMessage = '') {
  return Swal.fire(error_title, errorMessage, 'error').then(() => {
    window.location.reload();
  });
}

export async function OpenPrintSuccessNotification() {
  return await Swal.fire(complete_title, success_please_save_your_file, 'success');
}

export async function OpenErrorNotificationThenReloadPage(body = '') {
  return Swal.fire(error_title, body, 'error').then(() => {
    window.location.reload();
  });
}

export async function OpenWarningNotificationThenReloadPage(body = '') {
  return Swal.fire(complete_title, body, 'warning').then(() => {
    window.location.reload();
  });
}

export async function OpenNotificationThenReloadPage(body = '') {
  return Swal.fire(complete_title, body, 'success').then(() => {
    window.location.reload();
  });
}

export async function OpenConfirmNotification(
  title = 'Are you sure?',
  confirmTitle = 'Confirm',
  body = '',
  callBack = null
) {
  return await Swal.fire({
    title: title,
    text: body,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: confirmTitle,
  }).then(async (result) => {
    if (result.isConfirmed) {
      if (callBack !== null) {
        callBack();
      }
    }
  });
}

export async function OpenTranslateWarningConfirmNotification(
  translate,
  warningText = '',
  callBack = null
) {
  return await Swal.fire({
    title: translate('warning.title'),
    text: translate(warningText),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: translate('button.confirm'),
    cancelButtonText: translate('button.cancel'),
  }).then(async (result) => {
    if (result.isConfirmed) {
      if (callBack !== null) {
        callBack();
      }
    }
  });
}

export async function OpenTranslateInputNotification(
  translate,
  title,
  inputLabel = '',
  type = 'text',
  inputValidator,
  callBack = null
) {
  return await Swal.fire({
    title: title,
    input: type,
    inputLabel: inputLabel,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: translate('button.confirm'),
    cancelButtonText: translate('button.cancel'),
    inputValidator: (value) => inputValidator(value),
  }).then(async (result) => {
    if (result.isConfirmed) {
      if (callBack !== null) {
        callBack(result.value);
      }
    }
  });
}

export const showRequestToBuyItemInput = async (translate, item, recommendValue, onConfirm) => {
  await Swal.fire({
    title: translate('warehouse.request_buy_item_amount_title'),
    input: 'text',
    inputLabel: `${translate('warehouse.request_buy_item_amount_title')} ${item.Unit}`,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: translate('button.confirm'),
    cancelButtonText: translate('button.cancel'),
    inputValue: recommendValue,
    inputValidator: async (value) => {
      if (!value) {
        return translate('error.error_invalid_amount');
      } else {
        const amount = parseFloat(value);
        if (isNaN(amount)) {
          return translate('error.error_invalid_amount');
        } else {
          if (amount <= 0) {
            return translate('error.error_invalid_amount');
          }
        }
      }
    },
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm(item, result.value);
    }
  });
};

export const showAddItemToCartInput = async (translate, itemMaster, isMaterial) => {
  let amount;
  let defaultValue;

  if (isMaterial) {
    amount = itemMaster.Amount;
    defaultValue = itemMaster.SafetyAmount - itemMaster.Amount;
  } else {
    amount = itemMaster.CurrentAmount;
    defaultValue = itemMaster.SafetyAmount - itemMaster.CurrentAmount;
  }

  if (amount <= 0) {
    amount = 0;
  }

  if (defaultValue <= 0) {
    defaultValue = 0;
  }

  return await Swal.fire({
    title: translate('warehouse.amount_to_order'),
    input: 'text',
    inputLabel: `${itemMaster.Name} ${translate('warehouse.have')} ${amount} ${
      itemMaster.Unit !== undefined ? itemMaster.Unit : itemMaster.ItemTemplateUnit.Name
    }  ${translate('warehouse.in_store')}`,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: translate('button.confirm'),
    cancelButtonText: translate('button.cancel'),
    inputValue: defaultValue,
    inputValidator: async (value) => {
      if (!value) {
        return translate('error.error_invalid_amount');
      } else {
        const amount = parseFloat(value);
        if (isNaN(amount)) {
          return translate('error.error_invalid_amount');
        } else {
          if (amount <= 0) {
            return translate('error.error_invalid_amount');
          }
        }
      }
    },
  });
};
