import React from 'react';
import PropTypes from 'prop-types';
import { useCarType } from '../../requests/query/DropdownData';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';

CreateCarForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

function CreateCarForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const { carTypes } = useCarType();
  const createCarSchema = Yup.object().shape({
    CarBrand: Yup.string().required(translate('error.detail_require')),
    CarRegistrationNumber: Yup.string().required(translate('error.detail_require')),
    CarType: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
  });
  const methods = useForm({
    resolver: yupResolver(createCarSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = async (values) => {
    onSubmitCallback(values);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="CarBrand" label={translate('title.brand')} />
        <RHFTextField
          name="CarRegistrationNumber"
          label={translate('logistic.car_registration_number_title')}
        />
        <RHFAutoCompleteShort name="CarType" label={translate('title.type')} data={carTypes} />
        <Button color={'primary'} variant={'contained'} fullWidth type="submit">
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateCarForm;
