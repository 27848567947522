import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';

SolveProductOrderProblemForm.propTypes = {
  onSubmitValue: PropTypes.func.isRequired,
};

function SolveProductOrderProblemForm({ onSubmitValue }) {
  const { translate } = useLocales();

  const createSolveProblemDetailSchema = Yup.object().shape({
    Note: Yup.string().required(translate('error.detail_require')),
  });
  const defaultValue = {
    Note: '',
  };

  const methods = useForm({
    resolver: yupResolver(createSolveProblemDetailSchema),
    defaultValues: defaultValue,
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onSubmitValue(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name={'Note'} label={translate('title.note')} />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('button.submit')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default SolveProductOrderProblemForm;
