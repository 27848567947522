import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { allowToUploadUserTypeId } from '../../../utilities/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Button, Grid, Stack, Typography } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { fDateTimeText } from '../../../utilities/formatTime';
import useAuth from '../../../auth/hooks/useAuth';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import Iconify from 'components/minimal/iconify';
import { useServicePurchaseOrder } from '../../../requests/query/useServicePurchaseOrder';
import { paths } from '../../../routes/paths';
import UploadFileForm from '../../forms/UploadFileForm';

function ServicePurchaseOrderDetailPage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { id } = useParams();
  const { user } = useAuth();
  const { servicePurchaseOrder, print, cancel, uploadApprove } = useServicePurchaseOrder(id);

  const printPODocument = async () => {
    await print.mutateAsync(id);
  };

  const handleCancelPO = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancel.mutateAsync(id);
        navigate(paths.procurement.spo_list);
      }
    );
  };
  const uploadApproveDocument = async (values) => {
    await uploadApprove.mutateAsync({
      Id: servicePurchaseOrder.Id,
      Document: values.File[0],
      UploadEmployeeId: user.EmployeeId,
    });
    await printPODocument();
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.spo_detail')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: `${translate('procurement.spo_detail')}${translate('title.id')}: ${id}` },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant={'contained'}
            color={'error'}
            startIcon={<Iconify icon={'eva:close-fill'} />}
            onClick={handleCancelPO}
          >
            {translate('button.cancel')}
          </Button>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={printPODocument}
          >
            {translate('button.print_po')}
          </Button>
        </Stack>
      }
    >
      {servicePurchaseOrder !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={servicePurchaseOrder.Id} />
              <GridDetailText
                title={translate('title.detail')}
                value={servicePurchaseOrder.Reason}
              />
              <GridDetailText
                title={translate('title.create_at')}
                value={fDateTimeText(servicePurchaseOrder.createdAt)}
              />
              <GridDetailText
                title={translate('title.create_by')}
                value={servicePurchaseOrder.CreateEmployee}
              />
            </Grid>
          </ProjectPaper>
          <>
            {servicePurchaseOrder.ApprovedAt !== null ? (
              <ProjectPaper>
                <GridDetailText
                  title={translate('title.approve_at')}
                  value={fDateTimeText(servicePurchaseOrder.ApprovedAt)}
                />
                {allowToUploadUserTypeId.includes(user.UserTypeId) ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        {translate('procurement.approve_document_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant={'contained'}
                        href={`https://sil-space.sgp1.digitaloceanspaces.com/ApprovePurchaseOrder/PO_Service_Aprrove_${id}.pdf`}
                      >
                        {translate('procurement.open_document')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <GridDetailText
                    title={translate('procurement.approve_document_title')}
                    value={translate('procurement.already_upload')}
                  />
                )}
                <GridDetailText
                  title={translate('procurement.upload_by_title')}
                  value={getEmployeeFullName(servicePurchaseOrder.UploadApproveEmployee)}
                />
              </ProjectPaper>
            ) : (
              <RoleBasedGuard roles={allowToUploadUserTypeId}>
                <ProjectPaper>
                  <UploadFileForm isImage onSubmitForm={uploadApproveDocument} />
                </ProjectPaper>
              </RoleBasedGuard>
            )}
          </>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default ServicePurchaseOrderDetailPage;
