import React from 'react';
import { DataScanner } from './index';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getCarDetailById } from '../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { useLocales } from '../../locales';
import { Box, Stack, Typography } from '@mui/material';
import { scanPileIdBurma } from '../../locales/BurmaStrings';
import useAuth from '../../auth/hooks/useAuth';
import SearchCarForm from '../forms/SearchCarForm';

CarScanner.propTypes = {
  onSearchCallBack: PropTypes.func.isRequired,
  isPreFetchData: PropTypes.bool,
  isShowTooltips: PropTypes.bool,
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
};

function CarScanner({
                       onSearchCallBack,
                       isPreFetchData,
                       isShowTooltips,
                       disableInput,
                       disableScan,
                     }) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const searchCarMutation = useMutation({
    mutationFn: getCarDetailById,
    onSuccess: async (car) => {
      await OpenSuccessNotification();
      onSearchCallBack(car);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
    },
  });

  const handleScanCallback = async (data) => {
    try {
      if (data.includes('CarId')) {
        const dataJson = JSON.parse(data);
        if (dataJson.CarId !== undefined) {
          await handleSearchCar(dataJson.CarId);
        }
      } else {
        await handleSearchCar(data);
      }
    } catch (e) {
      await OpenErrorNotification(translate('error'));
    }
  };
  const handleSearchCar = async (id) => {
    if (isPreFetchData) {
      searchCarMutation.mutate({
        CarId: id,
        IsTemp: user === null,
      });
    } else {
      await OpenSuccessNotification();
      onSearchCallBack(id);
    }
  };
  const searchPileForm = <SearchCarForm onSubmitCallBack={handleSearchCar} />;
  return (
    <Stack spacing={2}>
      {isShowTooltips && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt={'scan'}
              style={{ height: '200px', width: '300px' }}
              src={'/assets/illustrations/Scan_Image.jpg'}
              data-holder-rendered="true"
            />
          </Box>
          <Typography variant={'h3'}>{translate(`กรุณา Scan QR รถของท่าน`)}</Typography>
          <Typography variant={'h3'}>{scanPileIdBurma}</Typography>
        </>
      )}
      <DataScanner
        disableInput={disableInput}
        disableScan={disableScan}
        onScanCallback={handleScanCallback}
        FormComponent={searchPileForm}
      />
    </Stack>
  );
}

export default CarScanner;
