import { LinearProgress, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { fPercent } from 'utilities/formatNumber';
import PropTypes from 'prop-types';

ProgressItem.propTypes = {
  progress: PropTypes.shape({
    amount: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  color: PropTypes.string,
};

export default function ProgressItem({ progress, color }) {
  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {progress.label}
        </Typography>
        <Typography variant="subtitle2">{progress.amount}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp;({fPercent(progress.value)})
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={progress.value}
        color={color ? color : 'primary'}
      />
    </Stack>
  );
}
