import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import Link from "@mui/material/Link";
import {
  AdminUserTypeId,
  allowToUploadUserTypeId,
  ManagementUserTypeId,
  ProcurementUserTypeId,
} from '../../../utilities/Constants';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import useAuth from '../../../auth/hooks/useAuth';
import { useTheme } from '@mui/material/styles';
import { useLocales } from 'locales';
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import EditableGridDetailText from '../../customs/EditableGridDetailText';
import { fCurrency } from '../../../utilities/formatNumber';
import { fDateTimeText } from '../../../utilities/formatTime';
import GeneralTable from '../../customs/GeneralTable';
import EditPoItemForm from '../../forms/EditPOItemForm';
import { usePartPurchaseOrder } from '../../../requests/query/usePartPurchaseOrder';
import { paths } from '../../../routes/paths';
import UploadFileForm from '../../forms/UploadFileForm';

function PartsPurchaseOrderDetailPage() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const { id } = useParams();
  const theme = useTheme();
  const {
    purchaseOrder,
    printPO,
    cancelPO,
    uploadApprove,
    editPOItems,
    editPODetail,
    editPOReferenceId,
  } = usePartPurchaseOrder(id);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editOrder, setEditOrder] = useState(null);

  const handlePrintPODocument = async () => {
    await printPO.mutateAsync(id);
  };
  const handleCancelPO = async () => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_cancel_data',
      async () => {
        await cancelPO.mutateAsync(id);
      }
    );
  };
  const uploadApproveDocument = async (values) => {
    await uploadApprove.mutateAsync({
      Id: id,
      Document: values.File[0],
      UploadEmployeeId: user.EmployeeId,
    });
  };
  const editOrderDetail = (order) => {
    setIsEditMode(true);
    setEditOrder(order);
  };
  const onEditComplete = async (orderId, amount, buyPrice, detail) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_edit_data',
      async () => {
        await editPOItems.mutateAsync({
          PurchaseOrderItemTemplateId: orderId,
          Amount: amount,
          BuyPrice: buyPrice,
          Detail: detail,
        });
        setIsEditMode(false);
        setEditOrder(null);
      }
    );
  };

  const onCancelEdit = () => {
    setIsEditMode(false);
    setEditOrder(null);
  };

  const handleEditOrderDetail = async (detail) => {
    await editPODetail.mutateAsync({
      PurchaseOrderId: id,
      Detail: detail,
    });
  };
  const handleEditOrderReferenceId = async (referenceId) => {
    await editPOReferenceId.mutateAsync({
      PurchaseOrderId: id,
      ReferenceId: referenceId,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.purchase_order_detail')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: `${translate('procurement.purchase_order_list_title')} ${translate(
            'warehouse.parts'
          )}`,
          href: paths.procurement.parts_po_list,
        },
        {
          name: `${translate('procurement.purchase_order_detail')} ${translate(
            'warehouse.parts'
          )} : ${id}`,
        },
      ]}
      action={
        !isEditMode && (
          <Stack spacing={2} direction="row">
            {purchaseOrder?.ApproveAt === null && (
              <Button
                variant={'contained'}
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={handleCancelPO}
              >
                {translate('button.cancel')}
              </Button>
            )}
            <Button
              variant="contained"
              color={'info'}
              startIcon={<Iconify icon={'ic:outline-print'} />}
              onClick={handlePrintPODocument}
            >
              {translate('button.print_po')}
            </Button>
          </Stack>
        )
      }
    >
      {isEditMode ? (
        <EditPoItemForm
          order={editOrder}
          onEditCallback={onEditComplete}
          onCancelCallback={onCancelEdit}
          name={editOrder.SupplierSellItemTemplate.Name}
          unitName={editOrder.SupplierSellItemTemplate.SellItemTemplateUnit.Name}
        />
      ) : purchaseOrder !== null ? (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={`P${purchaseOrder.Id}`} />
              <GridDetailText
                title={translate('procurement.supplier')}
                value={purchaseOrder.Supplier.Name}
              />
              <RoleBasedGuard
                roles={[ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId]}
                children={
                  <EditableGridDetailText
                    title={translate('title.detail')}
                    onEdit={handleEditOrderDetail}
                    value={purchaseOrder.Detail}
                    isIgnoreCheck
                  />
                }
                otherComponent={
                  <GridDetailText title={translate('title.detail')} value={purchaseOrder.Detail} />
                }
              />
              <RoleBasedGuard
                roles={[ManagementUserTypeId, AdminUserTypeId, ProcurementUserTypeId]}
                children={
                  <EditableGridDetailText
                    title={translate('procurement.po_reference_id_title')}
                    onEdit={handleEditOrderReferenceId}
                    value={purchaseOrder.ReferenceId}
                  />
                }
                otherComponent={
                  <GridDetailText
                    title={translate('procurement.po_reference_id_title')}
                    value={purchaseOrder.ReferenceId}
                  />
                }
              />
              <GridDetailText
                title={translate('title.value')}
                value={fCurrency(purchaseOrder.Value)}
              />
              <GridDetailText
                title={translate('procurement.discount')}
                value={fCurrency(purchaseOrder.Discount)}
              />
              <GridDetailText
                title={translate('procurement.delivery_price_title')}
                value={fCurrency(purchaseOrder.DeliveryPrice)}
              />
              <GridDetailText
                title={translate('procurement.vat_title')}
                value={fCurrency(purchaseOrder.Vat)}
              />
              <GridDetailText
                title={translate('title.create_by')}
                value={getEmployeeFullName(purchaseOrder.CreateEmployee)}
              />
              <GridDetailText
                title={translate('title.due_at')}
                value={fDateTimeText(purchaseOrder.DeliveredAt)}
              />
              {purchaseOrder.ArchivedAt !== null && (
                <GridDetailText
                  title={translate('title.status')}
                  value={translate('button.cancel')}
                  color={theme.palette.error.main}
                />
              )}
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'procurement.supplier_sell_item_name' },
                { align: 'left', title: 'warehouse.item_master_name' },
                { align: 'left', title: 'title.detail' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: 'procurement.price_per_unit' },
                { align: 'left', title: 'procurement.pr_id' },
                { align: 'left', title: '' },
              ]}
              tableData={purchaseOrder.PurchaseOrderItemTemplates.map((item) => {
                return [
                  item.SupplierSellItemTemplate.Name,
                  <Link
                    to={paths.general_warehouse.item_template_detail(
                      item.SupplierSellItemTemplate.ItemTemplate.Id
                    )}
                  >
                    {item.SupplierSellItemTemplate.ItemTemplate.Name}
                  </Link>,
                  item.Detail,
                  item.Amount,
                  item.SupplierSellItemTemplate.SellItemTemplateUnit.Name,
                  item.BuyPrice,
                  <Link
                    to={paths.procurement.parts_pr_detail(
                      item.PurchaseRequisitionItemTemplate.PurchaseRequisitionId
                    )}
                  >{`P${item.PurchaseRequisitionItemTemplate.PurchaseRequisitionId}`}</Link>,
                  [
                    <IconButton onClick={() => editOrderDetail(item)}>
                      <Iconify icon={'ic:baseline-edit'} width={20} height={20} />
                    </IconButton>,
                  ],
                ];
              })}
            />
          </ProjectPaper>
          {purchaseOrder.ApproveAt !== null ? (
            <ProjectPaper>
              <Grid container spacing={3}>
                <GridDetailText
                  title={translate('title.approve_at')}
                  value={fDateTimeText(purchaseOrder.ApproveAt)}
                />
                {allowToUploadUserTypeId.includes(user.UserTypeId) ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        {translate('procurement.approve_document_title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant={'contained'}
                        href={`https://sil-space.sgp1.digitaloceanspaces.com/ApprovePurchaseOrder/PO_Aprrove_P${id}.pdf`}
                      >
                        {translate('procurement.open_document')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <GridDetailText
                    title={translate('procurement.approve_document_title')}
                    value={translate('procurement.already_upload')}
                  />
                )}
                <GridDetailText
                  title={translate('procurement.upload_by_title')}
                  value={getEmployeeFullName(purchaseOrder.UploadApproveEmployee)}
                />
              </Grid>
            </ProjectPaper>
          ) : (
            <RoleBasedGuard roles={allowToUploadUserTypeId}>
              <ProjectPaper>
                <UploadFileForm isImage onSubmitForm={uploadApproveDocument} />
              </ProjectPaper>
            </RoleBasedGuard>
          )}
        </React.Fragment>
      ) : null}
    </ProjectPageContainer>
  );
}

export default PartsPurchaseOrderDetailPage;
