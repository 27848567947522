import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import SearchBarWithSelectType from '../../customs/SearchBarWithSelectType';
import { useLocales } from 'locales';
import { paths } from 'routes/paths';

const SearchPage = () => {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const onSearch = (searchString, selectType) => {
    if (selectType === 1) {
      navigate(paths.general_warehouse.item_template_detail(searchString));
    } else if (selectType === 2) {
      navigate(paths.general_warehouse.request_report_detail(searchString));
    } else if (selectType === 3) {
      const dataJson = JSON.parse(searchString);
      if (dataJson.ReportId !== undefined) {
        navigate(paths.general_warehouse.stock_report_detail(dataJson.ReportId));
      } else {
        navigate(paths.general_warehouse.stock_report_detail(searchString));
      }
    }
  };

  const searchType = [
    { value: 1, Name: translate('warehouse.search_by_item_template_id') },
    { value: 2, Name: translate('warehouse.search_by_item_template_request_report_id') },
    { value: 3, Name: translate('warehouse.search_by_stock_report_id') },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('button.search')}
      breadcrumbs={[{ name: translate('warehouse.parts') }, { name: translate('button.search') }]}
    >
      <SearchBarWithSelectType
        search_label={translate('button.search')}
        select_options={searchType}
        onSearchCallBack={onSearch}
      />
    </ProjectPageContainer>
  );
};

export default SearchPage;
