import React, { useMemo } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Button, Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import { createSupplierSellItemContainer } from '../../../requests/WarehouseServerHandler';
import {
  OpenErrorNotification,
  OpenNotificationThenCallback,
} from '../../../utilities/Helper/NotificationHelper';
import { useItemMasterDetail } from '../../../requests/query/useItemMasterDetail';
import { useAllSupplier, useItemUnitOptions } from '../../../requests/query/DropdownData';
import { useSupplierDetail } from '../../../requests/query/useSupplierDetail';
import { paths } from '../../../routes/paths';
import { useMutation } from '@tanstack/react-query';

function CreateSupplierSellItemPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const supplierId = searchParams.get('SupplierId');
  const itemMasterId = searchParams.get('ItemMasterId');
  const sellItemId = searchParams.get('SellItemId');
  const { translate } = useLocales();

  const { itemMasterDetail } = useItemMasterDetail(itemMasterId !== 'null' ? itemMasterId : null);
  const { itemUnitOptions } = useItemUnitOptions();
  const { allSupplier } = useAllSupplier(true);
  const { supplierDetail } = useSupplierDetail(supplierId !== 'null' ? supplierId : null);
  const itemContainerOptions = useMemo(() => {
    if (itemMasterDetail === null) return [];
    return itemMasterDetail.ItemContainer.map((container) => {
      return {
        Id: container.Id,
        Name: `${container.Name} (${container.ItemMasterSize} ${itemMasterDetail.ItemUnit.Name} : 1 ${container.ItemUnit.Name})`,
        ItemMasterSize: container.ItemMasterSize,
        Unit: container.ItemUnit.Name,
      };
    });
  }, [itemMasterDetail]);
  const createSupplierSellItemContainerMutation = useMutation({
    mutationFn: createSupplierSellItemContainer,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', () => {
        if (sellItemId !== undefined && sellItemId !== null) {
          navigate(paths.procurement.search_supplier_sell_item(sellItemId));
        } else {
          navigate(paths.procurement.supplier_detail(supplierId));
        }
      });
    },
  });

  const createSupplierSellItemSchema = Yup.object().shape({
    ItemContainer: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    SellItemUnit: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    Name: Yup.string().required(translate('error.invalid_input')),
    SellUnitPerItemContainerUnitAmount: Yup.number()
      .min(0, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
    PricePerSellUnit: Yup.number()
      .min(0, translate('error.invalid_input'))
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(createSupplierSellItemSchema),
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async (values) => {
    let sendSupplierId = supplierId !== 'null' ? supplierId : values.Supplier.Id;
    if (sendSupplierId !== null) {
      await createSupplierSellItemContainerMutation.mutateAsync({
        Name: values.Name,
        SupplierId: sendSupplierId,
        ItemMasterId: itemMasterId,
        ItemContainerId: values.ItemContainer.Id,
        SellItemUnitId: values.SellItemUnit.Id,
        SellUnitPerItemContainerUnitAmount: values.SellUnitPerItemContainerUnitAmount,
        PricePerSellUnit: values.PricePerSellUnit,
      });
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };

  const itemContainer = useWatch({
    control,
    name: 'ItemContainer',
    defaultValue: null,
  });
  const sellUnitPerItemContainerUnitAmount = useWatch({
    control,
    name: 'SellUnitPerItemContainerUnitAmount',
    defaultValue: 1,
  });
  const sellUnit = useWatch({
    control,
    name: 'SellItemUnit',
    defaultValue: null,
  });

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.add_supplier_sell_item')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.create_po_title'), href: paths.procurement.create_po },
        {
          name: `${translate('procurement.search_purchase_item_title')} : ${sellItemId}`,
          href: paths.procurement.search_supplier_sell_item(sellItemId),
        },
        { name: translate('procurement.add_supplier_sell_item') },
      ]}
    >
      {itemMasterDetail !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              {supplierDetail !== null && (
                <GridDetailText
                  title={translate('procurement.supplier')}
                  value={supplierDetail.Name}
                />
              )}
              <GridDetailText
                title={translate('warehouse.item_master_name')}
                value={itemMasterDetail.Name}
              />
              <GridDetailText
                title={translate('unit.title')}
                value={itemMasterDetail.ItemUnit.Name}
              />
              <GridDetailText
                title={translate('procurement.convert_to_amount')}
                value={
                  sellUnit !== null && itemContainer !== null
                    ? `${translate('procurement.buy')} 1 ${sellUnit.Name} = ${
                        itemContainer.ItemMasterSize / sellUnitPerItemContainerUnitAmount
                      } ${itemMasterDetail.ItemUnit.Name}`
                    : 'N/A'
                }
              />
              <GridDetailText
                title={translate('title.example')}
                value={
                  sellUnit !== null && itemContainer !== null
                    ? `${translate('procurement.buy')} ${sellUnitPerItemContainerUnitAmount} ${
                        sellUnit.Name
                      } = 1 ${itemContainer.Unit}`
                    : 'N/A'
                }
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                {supplierDetail === null && (
                  <RHFAutoCompleteShort
                    name={'Supplier'}
                    label={translate('procurement.supplier')}
                    data={allSupplier}
                  />
                )}
                <RHFAutoCompleteShort
                  name={'ItemContainer'}
                  label={translate('warehouse.container')}
                  data={itemContainerOptions}
                />
                <RHFTextField name={'Name'} label={translate('title.name')} />
                <RHFAutoCompleteShort
                  name={'SellItemUnit'}
                  label={translate('procurement.sell_unit')}
                  data={itemUnitOptions}
                />
                <RHFTextField
                  name={'SellUnitPerItemContainerUnitAmount'}
                  label={`${translate(
                    'procurement.sell_unit_amount_per_item_container'
                  )} (${translate('procurement.sell_unit')}:${translate(
                    'procurement.container_unit_title'
                  )} ${translate('warning.example_supplier_convert_1')})`}
                />
                <RHFTextField
                  name={'PricePerSellUnit'}
                  label={`${translate('procurement.price_detail')} (${translate(
                    'warning.example_supplier_convert_2'
                  )})`}
                />
                <Button color={'primary'} variant={'contained'} fullWidth type="submit">
                  {translate('button.create')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default CreateSupplierSellItemPage;
