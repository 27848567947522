import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenNotificationThenReloadPage,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { createItemTemplateUnit } from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { useMutation } from '@tanstack/react-query';
import CreateItemTemplateUnitForm from '../../forms/CreateItemTemplateUnitForm';

function CreateItemTemplateUnitPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const search = useLocation().search;
  const redirect = new URLSearchParams(search).get('redirect');

  const createItemTemplateUnitMutation = useMutation({
    mutationFn: createItemTemplateUnit,
    onSuccess: async () => {
      if (redirect !== null) {
        await OpenSuccessNotification();
        navigate(redirect);
      } else {
        await OpenNotificationThenReloadPage();
      }
    },
  });

  const handleCreateItemTemplateUnit = async (value) => {
    await createItemTemplateUnitMutation.mutateAsync(value.Name);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_new_unit_title')}
      breadcrumbs={[
        { name: translate('warehouse.parts') },
        { name: translate('warehouse.create_new_unit_title') },
      ]}
    >
      <ProjectPaper>
        <CreateItemTemplateUnitForm onSubmitCallback={handleCreateItemTemplateUnit} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateItemTemplateUnitPage;
