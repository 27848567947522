import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getAllMaintenanceJobForEmployeeId } from '../../../requests/ProductionServerHandler';
import useAuth from '../../../auth/hooks/useAuth';
import { Grid, IconButton } from '@mui/material';
import DetailWidget from '../../customs/DetailWidget';
import { useLocales } from 'locales';
import ReactTable from '../../react-table/ReactTable';
import {
  getMaintenanceDetail,
  getMaintenanceJobStatusCard,
} from '../../../utilities/Helper/UserInterfaceHelper';
import { useTheme } from '@mui/material/styles';
import ProjectPaper from '../../customs/ProjectPaper';
import Iconify from 'components/minimal/iconify';
import { useNavigate } from 'react-router-dom';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

export default function MaintenancePersonalDashboardPage() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data: allMaintenanceJob } = useQuery({
    queryKey: ['all-personal-mt-job', user.EmployeeId],
    queryFn: () => getAllMaintenanceJobForEmployeeId(user.EmployeeId),
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('maintenance.assign_maintenance_jobs')}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: translate('maintenance.assign_maintenance_jobs') },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <DetailWidget
            color={'info'}
            title={translate('maintenance.total_wait_job')}
            total={allMaintenanceJob.length}
            icon={'ic:baseline-access-time'}
            isSimple
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <ProjectPaper>
            <ReactTable
              search
              defaultPageSize={25}
              data={allMaintenanceJob.map((job) => {
                return {
                  id: job.Id,
                  detail: getMaintenanceDetail(job.Detail, job.DetailTags),
                  machine: job.Machine,
                  status: job.JobStatus.Id,
                  actions: job.Id,
                };
              })}
              header={[
                {
                  header: translate('title.id'),
                  accessorKey: 'id',
                },
                {
                  header: translate('title.detail'),
                  accessorKey: 'detail',
                },
                {
                  header: translate('title.machine'),
                  accessorKey: 'machine',
                },
                {
                  header: translate('title.status'),
                  accessorKey: 'status',
                  cell: (info) =>
                    getMaintenanceJobStatusCard(theme.palette.mode, translate, info.getValue()),
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      onClick={() => navigate(paths.maintenance.job_detail(info.getValue()))}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
}
