import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { OpenSuccessNotification } from 'utilities/Helper/NotificationHelper';
import DatePickerCustomer from 'components/date-picker/DatePickerCustomer';
import { useLocales } from 'locales';
import { useMutation } from '@tanstack/react-query';
import { getCustomerSalesReportExcel } from 'requests/ProductionServerHandler';
import { saveAs } from 'file-saver';
import { useLoading } from '../../loading';
export default function PrintCustomerReportPage() {
  const { translate } = useLocales();
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const printReportMutation = useMutation({
    mutationFn: getCustomerSalesReportExcel,
    onSuccess: async (report) => {
      await hideLoadingScreen();
      saveAs(report, 'customer_report.xlsx');
      await OpenSuccessNotification();
    },
  });
  const onPrintExcel = async (fromDate, toDate, customer) => {
    await showLoadingScreen();
    await printReportMutation.mutateAsync({
      fromDate: fromDate,
      toDate: toDate,
      customerId: customer?.Id,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.print_customer_report_page_title')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('sales.print_customer_report_page_title') },
      ]}
    >
      <DatePickerCustomer
        actions={[
          { title: translate('sales.print_customer_report_page_title'), callBack: onPrintExcel },
        ]}
      />
    </ProjectPageContainer>
  );
}
