import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  editSupplierSellItemTemplateDetail,
  getSupplierSellItemTemplateById,
} from '../../../requests/ProductionServerHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import EditSupplierSellItemForm from '../../forms/EditSupplierSellItemForm';
import { useLocales } from 'locales';
import { OpenNotificationThenCallback } from '../../../utilities/Helper/NotificationHelper';
import { useItemTemplateUnitOptions } from '../../../requests/query/DropdownData';
import { useMutation, useQuery } from '@tanstack/react-query';

function EditSupplierSellItemTemplatePage() {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { id } = useParams();

  const { data: supplierSellItemTemplateDetail } = useQuery({
    queryKey: ['getSupplierSellItemTemplateById', id],
    queryFn: () => getSupplierSellItemTemplateById(id),
    initialData: null,
  });
  const { itemTemplateUnitOptions } = useItemTemplateUnitOptions();

  const editSupplierSellItemTemplateDetailMutation = useMutation({
    mutationFn: editSupplierSellItemTemplateDetail,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', () => {
        navigate(-1);
      });
    },
  });
  const onSaveEditDetail = async (values) => {
    await editSupplierSellItemTemplateDetailMutation.mutateAsync({
      SupplierSellItemTemplateId: id,
      Name: values.Name,
      SellItemTemplateUnitId: values.SellItemUnit.Id,
    });
  };

  console.log('supplierSellItemTemplateDetail', supplierSellItemTemplateDetail);
  return (
    <ProjectPageContainer
      menu_title={translate('procurement.edit_supplier_sell_item_template')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        { name: translate('procurement.edit_supplier_sell_item_template') },
      ]}
    >
      {supplierSellItemTemplateDetail !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('procurement.supplier')}
                value={supplierSellItemTemplateDetail.Supplier.Name}
              />
              <GridDetailText
                title={translate('title.name')}
                value={supplierSellItemTemplateDetail.ItemTemplate.Name}
              />
              <GridDetailText
                title={translate('unit.title')}
                value={supplierSellItemTemplateDetail.SellItemTemplateUnit.Name}
              />
            </Grid>
          </ProjectPaper>
          <EditSupplierSellItemForm
            itemName={supplierSellItemTemplateDetail.Name}
            itemUnit={itemTemplateUnitOptions.find(
              (itemUnit) => itemUnit.Id === supplierSellItemTemplateDetail.SellItemTemplateUnit.Id
            )}
            onCompleteForm={onSaveEditDetail}
            itemUnitOptions={itemTemplateUnitOptions}
          />
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default EditSupplierSellItemTemplatePage;
