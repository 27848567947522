import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useProductionProcessType } from '../../requests/query/DropdownData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Stack, Button } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';

CreateProductionProcessForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreateProductionProcessForm({ onFormSubmit }) {
  const { translate } = useLocales();
  const { productionProcessTypeOptions } = useProductionProcessType();
  const createProductionProcessSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    ProductionProcessType: Yup.object()
      .shape({
        Id: Yup.number().min(1).required(translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });
  const methods = useForm({
    resolver: yupResolver(createProductionProcessSchema),
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onFormSubmit(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="Name" label={translate('title.name')} />
        <RHFAutoCompleteShort
          name={'ProductionProcessType'}
          label={translate('title.type')}
          data={productionProcessTypeOptions}
        />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateProductionProcessForm;
