import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Grid,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import ProjectPaper from './ProjectPaper';

const SearchBarWithSelectType = ({ onSearchCallBack, select_options, search_label }) => {
  const onSearch = (searchString) => {
    onSearchCallBack(searchString, selectType);
    setIsSearch(true);
  };

  const onEnterKeyPressed = async (event) => {
    if (event.key === 'Enter') {
      onSearch(searchString);
    }
  };

  const handleTypeChange = (event) => {
    setSelectType(event.target.value);
  };
  const handleTextChange = (event) => {
    setSearchString(event.target.value);
  };

  const onClearSearch = () => {
    setSelectType(1);
    setSearchString('');
    setIsSearch(false);
    onSearchCallBack(null, selectType);
  };

  const [selectType, setSelectType] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [isSearch, setIsSearch] = useState(false);

  return (
    <ProjectPaper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={9}>
          <TextField
            value={searchString}
            onChange={handleTextChange}
            onKeyDown={onEnterKeyPressed}
            autoFocus
            fullWidth
            id="search-bar-with-select-outline-search"
            label={search_label}
            InputProps={{
              endAdornment: isSearch ? (
                <InputAdornment position="end">
                  <IconButton aria-label="clear-title" onClick={onClearSearch} size="large">
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <FormControl variant="outlined" fullWidth>
            <Select
              labelId="search-bar-with-select-select"
              id="search-bar-with-select-select-outline"
              value={selectType}
              onChange={handleTypeChange}
            >
              {select_options.map((type, index) => {
                return (
                  <MenuItem key={index} value={type.value}>
                    {type.Name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </ProjectPaper>
  );
};

SearchBarWithSelectType.propTypes = {
  select_options: PropTypes.array.isRequired,
  search_label: PropTypes.string.isRequired,
  onSearchCallBack: PropTypes.func.isRequired,
};

export default SearchBarWithSelectType;
