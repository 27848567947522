import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from 'locales';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort } from '../minimal/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { getAllActiveCustomerOptions } from 'requests/ProductionServerHandler';
import Button from '@mui/material/Button';

StepSelectCustomerForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
  defaultCustomer: PropTypes.object,
};

function StepSelectCustomerForm({ onSubmitCallback, defaultCustomer }) {
  const { translate } = useLocales();
  const { data: customers } = useQuery({
    queryKey: ['select-customer-data'],
    queryFn: getAllActiveCustomerOptions,
    initialData: [],
  });

  const selectCustomerSchema = Yup.object().shape({
    Customer: Yup.object()
      .shape({
        Id: Yup.number().min(1).required('Customer Id is required'),
        Name: Yup.string().required('Customer Name is required'),
      })
      .required('Customer is required'),
  });
  const methods = useForm({
    resolver: yupResolver(selectCustomerSchema),
    defaultValues: {
      Customer: defaultCustomer !== undefined ? defaultCustomer : { Id: 0, Name: '' },
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = (values) => {
    onSubmitCallback(values.Customer);
  };

  return (
    <Card>
      <CardHeader title={translate('sales.select_customer')} />
      <CardContent>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFAutoCompleteShort
            data={customers}
            name="Customer"
            label={translate('title.customer')}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              mt: 3,
            }}
          >
            <Button color={'primary'} variant="contained" type={'submit'}>
              {translate('button.next')}
            </Button>
          </Box>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default StepSelectCustomerForm;
