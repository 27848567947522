import React, { useMemo } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { getMaintenanceDashboard } from '../../../requests/ProductionServerHandler';
import { Grid, Stack } from '@mui/material';
import { useLocales } from 'locales';
import DetailWidget from '../../customs/DetailWidget';
import ProjectPaper from '../../customs/ProjectPaper';
import GeneralTable from '../../customs/GeneralTable';
import LineGraph from '../../graph/LineGraph';
import { thaiMonths } from '../../../utilities/Constants';
import { useQuery } from '@tanstack/react-query';

function MaintenanceDashboardPage() {
  const { translate } = useLocales();

  const { data: dashboardData } = useQuery({
    queryKey: ['dashboard-mt-data'],
    queryFn: () => getMaintenanceDashboard(),
    initialData: null,
  });

  const lineChartData = useMemo(() => {
    if (dashboardData !== null) {
      const graphData = dashboardData.GraphData[0];
      let data = [
        parseInt(graphData.JAN),
        parseInt(graphData.FEB),
        parseInt(graphData.MAR),
        parseInt(graphData.APR),
        parseInt(graphData.MAY),
        parseInt(graphData.JUN),
        parseInt(graphData.JUL),
        parseInt(graphData.AUG),
        parseInt(graphData.SEP),
        parseInt(graphData.OCT),
        parseInt(graphData.NOV),
        parseInt(graphData.DECEM),
      ];

      return {
        categories: thaiMonths,
        series: [
          {
            year: 'Line',
            data: [
              {
                name: 'Line',
                data: data,
              },
            ],
          },
        ],
      };
    } else {
      return null;
    }
  }, [dashboardData]);

  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[{ name: translate('maintenance.title') }, { name: translate('dashboard') }]}
    >
      {dashboardData !== null ? (
        <Stack spacing={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <DetailWidget
                color={'info'}
                title={translate('maintenance.complete_job')}
                total={dashboardData.Overall[0].CompleteJob}
                icon={'ant-design:setting-outlined'}
                isSimple
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DetailWidget
                color={'success'}
                title={translate('maintenance.total_accept_job')}
                total={dashboardData.Overall[0].AcceptJob}
                icon={'eva:checkmark-fill'}
                isSimple
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DetailWidget
                color={'warning'}
                title={translate('maintenance.total_wait_job')}
                total={dashboardData.Overall[0].UnAccepted}
                icon={'ic:baseline-access-time'}
                isSimple
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LineGraph title={translate('title.maintenance_job_number')} chart={lineChartData} />
            </Grid>
          </Grid>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'maintenance.total_job' },
                { align: 'left', title: 'maintenance.complete_job' },
                { align: 'left', title: 'maintenance.in_hand_job' },
                { align: 'left', title: 'title.hold' },
                { align: 'left', title: 'maintenance.evaluate_job' },
              ]}
              tableData={dashboardData.EmployeeStatus.map((employee) => {
                return [
                  employee.Name,
                  employee.TotalJob,
                  employee.CompleteJob,
                  employee.TotalJob - employee.CompleteJob,
                  employee.HoldJob,
                  employee.EvaluateJob,
                ];
              })}
            />
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'maintenance.mechanical' },
                { align: 'left', title: 'maintenance.electronic' },
                { align: 'left', title: 'maintenance.car' },
                { align: 'left', title: 'maintenance.general' },
                { align: 'left', title: 'maintenance.project' },
                { align: 'left', title: 'maintenance.pm' },
              ]}
              tableData={dashboardData.EmployeeStatus.map((employee) => {
                return [
                  employee.Name,
                  employee.Mechanical,
                  employee.Electrical,
                  employee.Car,
                  employee.General,
                  employee.Project,
                  employee.PM,
                ];
              })}
            />
          </ProjectPaper>
        </Stack>
      ) : null}
    </ProjectPageContainer>
  );
}

export default MaintenanceDashboardPage;
