import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { selectPurchaseCart } from '../../../redux/reducers/inventory';
import { RouterLink } from 'routes/components';
import ProjectPaper from '../../customs/ProjectPaper';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import MaterialPurchaseStore from '../../details/warehouse/MaterialPurchaseStore';
import { getItemMasterItemsByCategory } from '../../../requests/WarehouseServerHandler';
import { isNeedSafety } from '../../../utilities/Helper/DataHelper';
import { Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';

const ProcurementStorePage = () => {
  const { translate } = useLocales();
  const PurchaseCart = useSelector(selectPurchaseCart);

  const { data } = useQuery({
    queryKey: ['procurement-item-master', 1],
    queryFn: () => getItemMasterItemsByCategory(1),
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.material_store')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.request_buy_material_title'),
          href: paths.procurement.create_purchase_requisition,
        },
        { name: translate('procurement.material_store') },
      ]}
      action={
        <Button
          variant="contained"
          color={'primary'}
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={PurchaseCart.length === 0}
          to={paths.procurement.create_purchase_requisition}
        >
          {`${translate('warehouse.order_item')} ${PurchaseCart.length} ${translate(
            'warehouse.items'
          )}`}
        </Button>
      }
    >
      <ProjectPaper>
        <MaterialPurchaseStore
          itemMasterData={data.map((itemMaster) => {
            return {
              id: itemMaster.Id,
              name: itemMaster.Name,
              type: itemMaster.ItemType,
              safety: itemMaster.SafetyAmount,
              amount: itemMaster.RemainAmount,
              defect: itemMaster.DefectAmount,
              unit: itemMaster.ItemUnit,
              average_use: itemMaster.AverageUsage,
              is_safety: {
                ItemId: itemMaster.Id,
                IsSafety: isNeedSafety(itemMaster.SafetyAmount, itemMaster.RemainAmount),
              },
              actions: {
                Id: itemMaster.Id,
                Name: itemMaster.Name,
                Unit: itemMaster.ItemUnit,
                CurrentAmount: itemMaster.CurrentAmount,
                SafetyAmount: itemMaster.SafetyAmount,
                Amount: itemMaster.Amount,
              },
            };
          })}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default ProcurementStorePage;
