import React from 'react';
import PropTypes from 'prop-types';
import ProjectPaper from '../../customs/ProjectPaper';
import { Grid, Stack } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { getMaintenanceDetail } from '../../../utilities/Helper/UserInterfaceHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useLocales } from '../../../locales';
import { useTheme } from '@mui/material/styles';
import {
  MaintenanceControlUserTypeId,
  MaintenanceJobStatus,
  MaintenanceUserTypeId,
  ManagementUserTypeId,
  SecretaryUserTypeId,
} from '../../../utilities/Constants';
import AcceptMaintenanceJobForm from '../../forms/AcceptMaintenanceJobForm';
import CompleteMaintenanceJobForm from '../../forms/CompleteMaintenanceJobForm';
import { useAuth } from '../../../auth/hooks';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import {fCurrency} from "../../../utilities/formatNumber";

MaintenanceJobDetail.propTypes = {
  jobDetail: PropTypes.object.isRequired,
  onAcceptJobCallback: PropTypes.func,
  onCompleteJobCallback: PropTypes.func,
};

function MaintenanceJobDetail({ jobDetail, onAcceptJobCallback, onCompleteJobCallback }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const { user } = useAuth();
  const isAllowToCompleteJob =
    jobDetail.Specialist.length > 0 &&
    (user.EmployeeId === jobDetail.Specialist[0].Id || user.UserTypeId === ManagementUserTypeId);
  const getMaintenanceJobStatusText = (jobStatus) => {
    let color = theme.palette.info.main;
    if (jobStatus.Id === 1) {
      color = theme.palette.info.main;
    }
    if (jobStatus.Id === 2) {
      color = theme.palette.info.main;
    }
    if (jobStatus.Id === 3) {
      color = theme.palette.warning.main;
    }
    if (jobStatus.Id === 4) {
      color = theme.palette.success.main;
    }
    if (jobStatus.Id === 5) {
      color = theme.palette.error.main;
    }
    if (jobStatus.Id === 6) {
      color = theme.palette.warning.main;
    }
    if (jobStatus.Id === 7) {
      color = theme.palette.error.main;
    }
    if (jobStatus.Id === 8) {
      color = theme.palette.warning.main;
    }

    return (
      <GridDetailText title={translate('title.status')} value={jobStatus.Name} color={color} />
    );
  };

  const handleAcceptJob = async (data) => {
    if (onAcceptJobCallback !== undefined) {
      onAcceptJobCallback(data);
    }
  };

  const handleCompleteJob = async (data) => {
    if (onCompleteJobCallback !== undefined) {
      onCompleteJobCallback(data);
    }
  };
  return (
    <Stack spacing={2}>
      <ProjectPaper>
        <Grid container spacing={3}>
          <GridDetailText
            title={translate('title.detail')}
            value={getMaintenanceDetail(jobDetail.Detail, jobDetail.DetailTags)}
          />
          <GridDetailText title={translate('title.machine')} value={jobDetail.Machine.Name} />
          <GridDetailText
            title={translate('title.type')}
            value={jobDetail.MaintenanceJobType.Name}
          />
          <GridDetailText
            title={translate('title.create_at')}
            value={fDateTimeText(jobDetail.BookedAt)}
          />
          {getMaintenanceJobStatusText(jobDetail.MaintenanceJobStatus)}
          <GridDetailText
            title={translate('title.create_by')}
            value={getEmployeeFullName(jobDetail.Informer)}
          />
          <GridDetailText
              title={translate('มูลค่าอะไหล่')}
              value={fCurrency(jobDetail.PartCosts.reduce((acc, part) => acc + part.Amount * part.LatestPrice, 0))}
          />
          {jobDetail.Specialist.length > 0 && (
            <GridDetailText
              title={translate('maintenance.specialist_title')}
              value={getEmployeeFullName(jobDetail.Specialist[0])}
            />
          )}
        </Grid>
      </ProjectPaper>
      <RoleBasedGuard
        roles={[
          ManagementUserTypeId,
          MaintenanceUserTypeId,
          MaintenanceControlUserTypeId,
          SecretaryUserTypeId,
        ]}
      >
        {jobDetail?.MaintenanceJobStatus.Id === MaintenanceJobStatus.WaitForAccept && (
          <AcceptMaintenanceJobForm onAcceptJobCallback={handleAcceptJob} />
        )}
        {isAllowToCompleteJob &&
          jobDetail?.MaintenanceJobStatus.Id === MaintenanceJobStatus.WorkInProcess && (
            <CompleteMaintenanceJobForm onCompleteJobCallback={handleCompleteJob} />
          )}
      </RoleBasedGuard>
    </Stack>
  );
}

export default MaintenanceJobDetail;
