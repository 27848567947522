import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  LinearProgress,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { fNumber, fPercent } from '../../../utilities/formatNumber';
import { useLocales } from 'locales';
import {
  getCompletePercentage,
  getTotalComplete,
  getTotalInThisMachine,
} from '../../../utilities/Helper/DataHelper';

MachineDetailCard.propTypes = {
  machineDetail: PropTypes.object.isRequired,
  openMachineDetail: PropTypes.func.isRequired,
};

function MachineDetailCard({ machineDetail, openMachineDetail }) {
  const { translate } = useLocales();
  const activeProductOrderPlan = machineDetail.ProductOrderPlannings.find(
    (plan) => plan.QueueNumber === 1
  );

  return (
    <Card>
      <CardHeader
        title={machineDetail.Name}
        titleTypographyProps={{ variant: 'h5' }}
        subheader={
          <>
            <Typography variant={'h6'}>
              {`${translate('planning.number_of_queue')} : ${
                machineDetail.ProductOrderPlannings.length
              }`}
            </Typography>
            <Typography variant={'body2'}>
              {`${translate('title.capacity')} ${getTotalInThisMachine(
                machineDetail.ProductOrderPlannings,
                machineDetail.ExpectedResult
              )}/${machineDetail.ExpectedResult}`}
            </Typography>
          </>
        }
      />
      <CardContent>
        <Stack>
          <Divider />
          <Stack spacing={2} sx={{ mt: 2 }}>
            {activeProductOrderPlan !== undefined ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack direction={'row'}>
                    <AutorenewIcon sx={{ color: '#FF4842' }} />
                    <Typography sx={{ ml: 1 }} variant={'h6'}>{`${translate(
                      'title.work_in_process'
                    )} : ${activeProductOrderPlan.ProductOrder.Id}`}</Typography>
                  </Stack>
                  <Chip label="อยู่ในแผน" color="primary" variant="outlined" />
                </Box>
                <Stack spacing={1}>
                  <Typography variant={'body2'}>
                    {activeProductOrderPlan.ProductOrder.Name}
                  </Typography>
                  <Typography variant={'body2'}>
                    {activeProductOrderPlan.ProductOrder.Customer.Name}
                  </Typography>
                  <Typography variant={'body2'}>{`${translate('title.amount')} : ${fNumber(
                    activeProductOrderPlan.ProductOrder.Amount
                  )}`}</Typography>
                  <Typography variant={'body2'}>เวลาเสร็จตามแผน: N/A</Typography>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant={'h6'}>Progress</Typography>
                      <Typography variant={'h6'}>
                        {fPercent(getCompletePercentage(activeProductOrderPlan))}
                      </Typography>
                    </Box>
                    <LinearProgress
                      variant="determinate"
                      value={getCompletePercentage(activeProductOrderPlan)}
                    />
                    <Typography variant={'body2'}>{`${translate(
                      'title.complete_amount'
                    )} ${getTotalComplete(activeProductOrderPlan)}/${
                      activeProductOrderPlan.ProductOrder.Amount
                    }`}</Typography>
                  </Stack>
                  <Button size="small" onClick={() => openMachineDetail(machineDetail.Id)}>
                    {machineDetail.ProductOrderPlannings.length > 1
                      ? `ดู ${machineDetail.ProductOrderPlannings.length - 1} ${translate(
                          'title.queue'
                        )}`
                      : translate('title.more_detail')}
                  </Button>
                </Stack>
              </>
            ) : (
              <Typography variant={'h6'} color={'success'}>
                {translate('title.free')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default MachineDetailCard;
