import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../minimal/iconify';
import { useLocales } from 'locales';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

ProductOrderTableToolBar.propTypes = {
  filterName: PropTypes.string,
  filterCustomer: PropTypes.number,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onFilterCustomer: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  optionsCustomer: PropTypes.arrayOf(PropTypes.string),
  onFilterClear: PropTypes.func,
};

export default function ProductOrderTableToolBar({
  optionsCustomer,
  filterStartDate,
  filterEndDate,
  filterName,
  filterCustomer,
  onFilterName,
  onFilterCustomer,
  onFilterStartDate,
  onFilterEndDate,
  onFilterClear,
}) {
  const { translate } = useLocales();
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <TextField
        fullWidth
        select
        label={translate('title.customer')}
        value={filterCustomer}
        onChange={onFilterCustomer}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsCustomer.map((option) => (
          <MenuItem
            key={option.Id}
            value={option.Id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option.Name}
          </MenuItem>
        ))}
      </TextField>

      <DatePicker
        label={translate('title.start_date')}
        value={filterStartDate}
        onChange={onFilterStartDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <DatePicker
        label={translate('title.end_date')}
        value={filterEndDate}
        onChange={onFilterEndDate}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder={translate('placeholder.search_product_order')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      {(filterCustomer > 0 ||
        filterName !== '' ||
        filterStartDate !== null ||
        filterEndDate !== null) && (
        <IconButton color={'error'} onClick={onFilterClear}>
          <Iconify icon={'eva:close-fill'} />
        </IconButton>
      )}
    </Stack>
  );
}
