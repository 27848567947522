import {createSlice} from '@reduxjs/toolkit'

export const salesSlice = createSlice({
    name: 'sales',
    initialState: {
        currentState: 0,
        selectCustomer: null,
        adjustPriceDetail: [],
        selectItems: [],
        selectProducts: [],
        createOrderDetail: null,
        searchParameter: null,
        searchPageNumber: 0,
        searchCategory: 1,
        searchItemPerPage: 10,
        confirmSelectItems: false
    },
    reducers: {
        changeSelectState: (state, action) => {
            state.currentState = action.payload
        },
        chooseCustomer: (state, action) => {
            state.selectCustomer = action.payload
            state.currentState = 1
        },
        saveAdjustPrice: (state, action) => {
            state.adjustPriceDetail = action.payload
        },
        checkoutItem: (state, action) => {
            let adjustSelectItems = [...state.selectItems];
            let findCheckoutItem = adjustSelectItems.find((item) => parseInt(item.ItemTemplateId) === parseInt(action.payload.ItemTemplateId) && parseFloat(item.Price) === parseFloat(action.payload.Price));

            if (findCheckoutItem !== undefined) {
                findCheckoutItem.Amount = findCheckoutItem.Amount + action.payload.Amount;
            } else {
                if (action.payload.Amount > 0) {
                    adjustSelectItems.push(action.payload)
                }
            }

            adjustSelectItems = adjustSelectItems.filter((item) => item.Amount > 0);

            state.selectItems = adjustSelectItems
        },
        checkoutProduct: (state, action) => {
            let adjustProducts = [...state.selectProducts.map((selectProduct, index) => {
                return {
                    Index: index,
                    Product: selectProduct.Product,
                    Price: selectProduct.Price,
                    IsBundle: selectProduct.IsBundle
                }
            })];
            adjustProducts.push({
                Index: adjustProducts.length,
                Product: action.payload.Product,
                Price: action.payload.Price,
                IsBundle: action.payload.IsBundle,
            });
            state.selectProducts = adjustProducts
        },
        removeProduct: (state, action) => {
            state.selectProducts = [...state.selectProducts.filter((product) => product.Index !== action.payload.Index)];
        },
        saveSearchParameter: (state, action) => {
            state.searchParameter = action.payload
        },
        saveSearchItemPerPage: (state, action) => {
            state.searchItemPerPage = action.payload
            state.searchPageNumber = 0
        },
        saveSearchPageNumber: (state, action) => {
            state.searchPageNumber = action.payload
        },
        saveSearchCategory: (state, action) => {
            state.searchCategory = action.payload
        },
        confirmSelectItems: (state, action) => {
            state.confirmSelectItems = action.payload
        },
        removeItemFromSelectItems: (state, action) => {
            let allSelectItems = [...state.selectItems];
            allSelectItems = allSelectItems.filter((item) => parseInt(item.ItemTemplateId) !== parseInt(action.payload.ItemTemplateId) && parseFloat(item.Price) !== parseFloat(action.payload.Price));
            if (allSelectItems.length <= 0) {
                state.selectItems = allSelectItems;
                state.confirmSelectItems = false;
                state.currentState = 1;
            } else {
                state.selectItems = allSelectItems;
            }
        },
        clearCheckoutData: (state) => {
            state.currentState = 0;
            state.selectCustomer = null;
            state.adjustPriceDetail = [];
            state.selectItems = [];
            state.selectProducts = [];
            state.createOrderDetail = null;
            state.searchParameter = null;
            state.searchPageNumber = 0;
            state.searchCategory = 1;
            state.searchItemPerPage = 10;
            state.confirmSelectItems = false;
        },
        updateOrderDetail: (state, action) => {
            state.createOrderDetail = action.payload;
            state.currentState = 2;
        },
    },
})

export const {
    changeSelectState,
    chooseCustomer,
    saveAdjustPrice,
    checkoutItem,
    checkoutProduct,
    removeProduct,
    saveSearchParameter,
    saveSearchItemPerPage,
    saveSearchPageNumber,
    saveSearchCategory,
    confirmSelectItems,
    removeItemFromSelectItems,
    clearCheckoutData,
    updateOrderDetail
} = salesSlice.actions

export const selectSalesCurrentState = (state) => state.sales.currentState;
export const selectSalesCustomer = (state) => state.sales.selectCustomer;
export const selectSelectItems = (state) => state.sales.selectItems;
export const selectConfirmSelectItems = (state) => state.sales.confirmSelectItems;
export const selectCreateOrderDetail = (state) => state.sales.createOrderDetail;
export const selectAdjustPriceDetail = (state) => state.sales.adjustPriceDetail;
export const selectSelectProducts = (state) => state.sales.selectProducts;
export default salesSlice.reducer