import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from 'locales';
import { Stack, Button } from '@mui/material';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';

CreateClaimRecordForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreateClaimRecordForm({ onFormSubmit }) {
  const { translate } = useLocales();
  const ClaimRecordSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
    ProductOrderId: Yup.number()
      .min(1, translate('error.product_order_id_require'))
      .required(translate('error.product_order_id_require')),
    Amount: Yup.number()
      .min(1, translate('error.amount_is_require'))
      .required(translate('error.amount_is_require')),
  });

  const defaultValues = {
    Detail: '',
    ProductOrderId: 0,
    Amount: 0,
  };

  const methods = useForm({
    resolver: yupResolver(ClaimRecordSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const handleFormSubmit = (data) => {
    onFormSubmit(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="Detail" label={translate('title.detail')} />
        <RHFTextField
          name="ProductOrderId"
          type={'number'}
          label={translate('production.product_order_id')}
        />
        <RHFTextField name="Amount" type={'number'} label={translate('title.amount')} />
        <Button variant={'contained'} color={'primary'} fullWidth type="submit">
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateClaimRecordForm;
