import React, { useState } from 'react';
import { DataScanner } from 'components/barcode-reader';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

DetailScanner.propsTypes = {
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
  onScanCallback: PropTypes.func.isRequired,
  onEnterTextField: PropTypes.func,
  isShowTooltips: PropTypes.bool,
  placeholderTexts: PropTypes.node,
  textFieldLabel: PropTypes.string,
};

function DetailScanner({
  disableScan,
  disableInput,
  onScanCallback,
  onEnterTextField,
  isShowTooltips,
  placeholderTexts,
  textFieldLabel,
}) {
  const [searchString, setSearchString] = useState('');
  const onCameraBarCodeScan = async (data) => {
    return onScanCallback(data);
  };

  const onEnterKeyPressed = async (event) => {
    if (event.key === 'Enter') {
      if (onEnterTextField !== undefined) {
        await onEnterTextField(searchString);
      } else {
        await onCameraBarCodeScan(searchString);
      }
      event.target.value = '';
    }
  };

  const handleTextChange = (event) => {
    setSearchString(event.target.value);
  };
  const employeeCodeForm = (
    <TextField
      onChange={handleTextChange}
      onKeyDown={onEnterKeyPressed}
      fullWidth
      label={textFieldLabel}
      variant="outlined"
    />
  );
  return (
    <Stack spacing={2}>
      {isShowTooltips && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt={'scan'}
              style={{ height: '250px', width: '300px' }}
              src={'/assets/illustrations/Scan_Image.jpg'}
              data-holder-rendered="true"
            />
          </Box>
          {placeholderTexts}
        </>
      )}
      <DataScanner
        disableInput={disableInput}
        disableScan={disableScan}
        onScanCallback={onCameraBarCodeScan}
        FormComponent={employeeCodeForm}
      />
    </Stack>
  );
}

export default DetailScanner;
