import React, { useState } from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { breakCustomer } from 'requests/ProductionServerHandler';
import { OpenNotificationThenReloadPage } from 'utilities/Helper/NotificationHelper';
import ProjectPaper from 'components/customs/ProjectPaper';
import { Stack } from '@mui/material';
import { useLocales } from 'locales';
import Button from '@mui/material/Button';
import CustomerDropdown from '../../customs/dropdown/CustomerDropdown';
import { useMutation } from '@tanstack/react-query';

export default function ArchivedCustomerPage() {
  const { translate } = useLocales();
  const [selectCustomer, setSelectCustomer] = useState(null);
  const breakCustomerMutation = useMutation({
    mutationFn: breakCustomer,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const handleSelectCustomer = (customer) => {
    setSelectCustomer(customer);
  };

  const onBreakCustomer = async (isBreak) => {
    await breakCustomerMutation.mutate({
      CustomerId: selectCustomer.Id,
      IsBreak: isBreak,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.disable_customer')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.disable_customer') },
      ]}
    >
      <ProjectPaper>
        <Stack spacing={3}>
          <CustomerDropdown
            onCustomerChange={handleSelectCustomer}
            label={translate('title.customer')}
          />
          <Button variant={'contained'} color={'error'} onClick={() => onBreakCustomer(1)}>
            {translate('button.lock')}
          </Button>
          <Button variant={'contained'} color={'success'} onClick={() => onBreakCustomer(0)}>
            {translate('button.release')}
          </Button>
        </Stack>
      </ProjectPaper>
    </ProjectPageContainer>
  );
}
