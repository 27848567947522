import React, { useMemo, useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import SILDatePicker from '../../date-picker/SILDatePicker';
import { useParams } from 'react-router-dom';
import { getMachineDashboardDetail } from '../../../requests/ProductionServerHandler';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { fDateTimeTextShort } from '../../../utilities/formatTime';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { RouterLink } from '../../../routes/components';
import Iconify from 'components/minimal/iconify';
import { OPEN_ICON } from '../../../config-global';

const MachineDashboardDetailPage = () => {
  const { id } = useParams();
  const { translate } = useLocales();

  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const toDate = new Date();
  toDate.setHours(23, 59, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: toDate,
  });

  const { data: machineDetail, refetch } = useQuery({
    queryKey: ['machine-dashboard-detail', dateRange, id],
    queryFn: () => getMachineDashboardDetail({ ...dateRange, machineId: id }),
    initialData: null,
  });

  const fetchDashboardDataByDate = async (fromDate, toDate) => {
    setDateRange({
      fromDate: fromDate,
      toDate: toDate,
    });
    await refetch();
  };

  const getColorPercent = (percent) => {
    if (percent < 50) {
      return (
        <Typography variant={'h5'} color={'error'}>
          {`${percent} %`}
        </Typography>
      );
    }
    if (percent >= 50 && percent < 80) {
      return (
        <Typography variant={'h5'} color={'warning'}>
          {`${percent} %`}
        </Typography>
      );
    }
    if (percent >= 80) {
      return (
        <Typography variant={'h5'} color={'success'}>
          {`${percent} %`}
        </Typography>
      );
    }
  };

  const allQualityProblem = useMemo(() => {
    if (machineDetail === null) return [];

    let allProblems = [];

    machineDetail.Piles.forEach((record) => {
      if (record.Pile.PileQualityProblemHistories.length > 0) {
        allProblems.push(...record.Pile.PileQualityProblemHistories);
      }
    });

    return allProblems;
  }, [machineDetail]);

  return (
    <ProjectPageContainer
      menu_title={translate('dashboard')}
      breadcrumbs={[{ name: translate('production.title') }, { name: translate('dashboard') }]}
    >
      <SILDatePicker onSearchDate={fetchDashboardDataByDate} />
      {machineDetail !== null ? (
        <Stack spacing={2}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.name')} value={machineDetail.Name} />
              <GridDetailText
                title={translate('title.capacity')}
                value={machineDetail.ExpectedResult}
              />
              <GridDetailText
                title={translate('title.productive_rate')}
                value={getColorPercent(machineDetail.SuccessRate)}
              />
              <GridDetailText
                title={translate('title.amount_good')}
                value={machineDetail.TotalGood}
              />
              <GridDetailText
                title={translate('title.amount_defect')}
                value={machineDetail.TotalDefect}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <ReactTable
              defaultPageSize={10}
              search
              data={machineDetail.Piles.map((record, index) => {
                return {
                  pile_id: record.Pile.Id,
                  name: record.Pile.ProductOrder.Name,
                  good_amount: record.GoodAmount,
                  defect_amount: record.DefectAmount,
                  complete_at_title: record.CompletedAt,
                  actions: record.Pile.Id,
                };
              })}
              header={[
                {
                  header: translate('title.id'),
                  accessorKey: 'pile_id',
                },
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('title.amount_good'),
                  accessorKey: 'good_amount',
                },
                {
                  header: translate('title.amount_defect'),
                  accessorKey: 'defect_amount',
                },
                {
                  header: translate('title.complete_at'),
                  accessorKey: 'complete_at_title',
                  cell: (info) => fDateTimeTextShort(info.getValue()),
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      component={RouterLink}
                      to={paths.production.pile_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
          <ProjectPaper>
            <ReactTable
              defaultPageSize={10}
              search
              data={allQualityProblem.map((record, index) => {
                return {
                  pile_id: record.PileId,
                  name: record.QualityProblem.Name,
                  hold_at: record.HoldAt,
                  un_hold_at: record.UnHoldAt,
                  employee: record.HoldEmployee,
                  actions: record.PileId,
                };
              })}
              header={[
                {
                  header: translate('title.id'),
                  accessorKey: 'pile_id',
                },
                {
                  header: translate('title.name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('qa.hold_at'),
                  accessorKey: 'hold_at',
                  cell: (info) => fDateTimeTextShort(info.getValue()),
                },
                {
                  header: translate('qa.un_hold_at'),
                  accessorKey: 'un_hold_at',
                  cell: (info) => fDateTimeTextShort(info.getValue()),
                },
                {
                  header: translate('title.by_employee'),
                  accessorKey: 'employee',
                  cell: (info) => getEmployeeFullName(info.getValue()),
                },
                {
                  header: 'Actions',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <IconButton
                      component={RouterLink}
                      to={paths.production.pile_detail(info.getValue())}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </Stack>
      ) : null}
    </ProjectPageContainer>
  );
};

export default MachineDashboardDetailPage;
