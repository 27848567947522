import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocales } from 'locales';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getCreateSpecialDiscountRequireData } from 'requests/ProductionServerHandler';
import { useQuery } from '@tanstack/react-query';
import { BASE_PAPER_SIZE } from 'config-global';
import FormProvider from 'components/minimal/hook-form';
import { Stack, Grid } from '@mui/material';
import GridDetailText from 'components/customs/GridDetailText';
import { fCurrency, fCurrencyDecimal, fPercent, roundUpNumber } from 'utilities/formatNumber';
import { RHFTextField, RHFAutoCompleteShort } from 'components/minimal/hook-form';
import { SubmitFormButton } from '../buttons';

CreateSpecialDiscountForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

GridDetailText.propTypes = {
  title: PropTypes.any,
  value: PropTypes.any,
};

function CreateSpecialDiscountForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const { data: requireData } = useQuery({
    queryKey: ['create-special-discount-require-data'],
    queryFn: getCreateSpecialDiscountRequireData,
  });
  const defaultValues = {
    Customer: { Id: 0, Name: '' },
    Product: { Id: 0, Name: '', BasePrice: 0, PricePerSquareInch: 0 },
    Duration: { Id: 0, Name: '' },
    PricePerSquareInch: 0,
  };
  const getPricePerSheet = (pricePerSquareInch) =>
    roundUpNumber(pricePerSquareInch * BASE_PAPER_SIZE, 2);
  const calculatePerSheetPercentDiscount = (pricePerSquareInch, basePrice) => {
    const pricePerSheet = getPricePerSheet(pricePerSquareInch);
    return (pricePerSheet * 100) / basePrice - 100;
  };
  const calculatePerSqrPercentDiscount = (discountPricePerSquareInch, pricePerSquareInch) =>
    (discountPricePerSquareInch * 100) / pricePerSquareInch - 100;

  const createSpecialPriceSchema = Yup.object().shape({
    Customer: Yup.object()
      .shape({
        Id: Yup.number().min(1).required('Customer Id is required'),
        Name: Yup.string().required('Customer Name is required'),
      })
      .required('Customer is required'),
    Product: Yup.object()
      .shape({
        Id: Yup.number().min(1).required('Product Id is required'),
        Name: Yup.string().required('Product Name is required'),
        BasePrice: Yup.number().required('Product BasePrice is required'),
        PricePerSquareInch: Yup.number().required('Product PricePerSquareInch is required'),
      })
      .required('Product is required'),
    Duration: Yup.object()
      .shape({
        Id: Yup.number().min(1).required('Duration Id is required'),
        Name: Yup.string().required('Duration Name is required'),
      })
      .required('Duration is required'),
    PricePerSquareInch: Yup.number().min(0.00001).required('PricePerSquareInch is required'),
  });

  const methods = useForm({
    resolver: yupResolver(createSpecialPriceSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit } = methods;

  const product = useWatch({
    control: methods.control,
    name: 'Product',
    defaultValue: defaultValues.Product,
  });
  const pricePerSquareInch = useWatch({
    control: methods.control,
    name: 'PricePerSquareInch',
    defaultValue: 0,
  });

  const onSubmit = async (data) => {
    onSubmitCallback({ ...data, PricePerSheet: getPricePerSheet(data.PricePerSquareInch) });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {requireData && (
        <Stack spacing={2}>
          {product !== undefined && product.Id !== 0 && (
            <>
              <Grid container spacing={2}>
                <GridDetailText title={translate('title.id')} value={product.Id} />
                <GridDetailText title={translate('title.name')} value={product.Name} />
                <GridDetailText
                  title={translate('title.price_per_sheet')}
                  value={fCurrency(product.BasePrice)}
                />
                <GridDetailText
                  title={translate('title.price_per_square_inch')}
                  value={fCurrencyDecimal(product.PricePerSquareInch)}
                />
                <GridDetailText
                  title={translate('title.price_per_sheet_discount')}
                  value={`${fCurrency(getPricePerSheet(pricePerSquareInch))} (${fPercent(
                    calculatePerSheetPercentDiscount(pricePerSquareInch, product.BasePrice)
                  )})`}
                />
                <GridDetailText
                  title={translate('title.price_per_sqr_discount')}
                  value={`${fCurrencyDecimal(pricePerSquareInch)} (${fPercent(
                    calculatePerSqrPercentDiscount(pricePerSquareInch, product.PricePerSquareInch)
                  )})`}
                />
              </Grid>
            </>
          )}
          <RHFAutoCompleteShort
            data={requireData.Customers}
            name="Customer"
            label={translate('title.customer')}
          />
          <RHFAutoCompleteShort
            data={requireData.Products}
            name="Product"
            label={translate('title.product')}
          />
          <RHFAutoCompleteShort
            data={requireData.Durations}
            name="Duration"
            label={translate('title.duration')}
          />
          <RHFTextField
            name="PricePerSquareInch"
            label={translate('title.price_per_square_inch')}
          />
          <SubmitFormButton />
        </Stack>
      )}
    </FormProvider>
  );
}

export default CreateSpecialDiscountForm;
