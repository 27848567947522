import React from 'react';
import ProjectPaper from '../../customs/ProjectPaper';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import useQueryParameter from '../../../hooks/useQueryParameter';
import ProblemDetail from '../../details/ProblemDetail';
import SolveProductOrderProblemForm from '../../forms/SolveProductOrderProblemForm';
import {
  OpenConfirmNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import {
  getProductOrderProblemDetailById,
  solveProductOrderProblems,
} from '../../../requests/ProductionServerHandler';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

function SolveProductionProblemPage() {
  const queryParameter = useQueryParameter();
  const productOrderId = queryParameter.get('productOrderId');
  const problemId = queryParameter.get('problemId');
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { data: problemDetail } = useQuery(
    ['problemDetail', problemId],
    () => getProductOrderProblemDetailById(problemId),
    {
      initialData: null,
    }
  );

  const solveProblemMutation = useMutation({
    mutationFn: solveProductOrderProblems,
    onSuccess: async () => {
      await OpenSuccessNotification(translate('status.success'));
      navigate(paths.production.product_order_detail(productOrderId));
    },
  });

  const onSaveProblemNote = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_solve_problem'),
      translate('button.confirm'),
      '',
      async () => {
        await solveProblemMutation.mutateAsync({
          ProblemId: problemId,
          SolvingNote: values.Note,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('title.solve_problem')}
      breadcrumbs={[
        { name: translate('production.title') },
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${
            productOrderId !== null ? productOrderId : 'ไม่มี'
          }`,
          href:
            productOrderId !== null
              ? paths.production.product_order_detail(productOrderId)
              : paths.production.product_order_list,
        },
        { name: translate('title.solve_problem') },
      ]}
    >
      <ProblemDetail problemDetail={problemDetail} />
      {problemDetail !== undefined && problemDetail !== null && problemDetail.SolvedAt === null ? (
        <ProjectPaper>
          <SolveProductOrderProblemForm onSubmitValue={onSaveProblemNote} />
        </ProjectPaper>
      ) : null}
    </ProjectPageContainer>
  );
}

export default SolveProductionProblemPage;
