import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Button, Grid, Stack, Typography } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import GridDetailText from '../../customs/GridDetailText';
import { fDateTimeText } from '../../../utilities/formatTime';
import GeneralTable from '../../customs/GeneralTable';
import { useItemReceipt } from '../../../requests/query/useItemReceipt';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";
function ItemReceiptDetailPage() {
  const { translate } = useLocales();
  const { id } = useParams();
  const { itemReceipt, printReport, printBarcode } = useItemReceipt(id);
  const onPrintBill = async () => {
    await printReport.mutateAsync(id);
  };

  const onPrintBarcodePDF = async () => {
    await printBarcode.mutateAsync(id);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.item_receipt_report')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.item_receipt_report') },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant={'contained'}
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={onPrintBarcodePDF}
          >{`${translate('button.print')} Barcode`}</Button>
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'ic:outline-print'} />}
            onClick={onPrintBill}
          >
            {translate('button.print')}
          </Button>
        </Stack>
      }
    >
      {itemReceipt !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={id} />
              <GridDetailText
                title={translate('warehouse.create_item_request_by_title')}
                value={itemReceipt.CreateRequestEmployeeName}
              />
              <GridDetailText
                title={translate('warehouse.create_item_request_at_title')}
                value={fDateTimeText(itemReceipt.RequestReport.createdAt)}
              />
              <GridDetailText
                title={translate('warehouse.fulfill_by_title')}
                value={itemReceipt.FulfillEmployeeName}
              />
              <GridDetailText
                title={translate('warehouse.fulfill_at_title')}
                value={fDateTimeText(itemReceipt.ItemReceipt.AcceptedAt)}
              />
              <GridDetailText
                title={translate('warehouse.accept_by_title')}
                value={itemReceipt.AcceptEmployeeName}
              />
              <GridDetailText
                title={translate('warehouse.accept_at_title')}
                value={fDateTimeText(itemReceipt.ItemReceipt.AcceptedAt)}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <Typography variant="body2" gutterBottom>
              {translate('warehouse.want_item')}
            </Typography>
            <br />
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: '' },
              ]}
              tableData={itemReceipt.RequestReport.ItemMasters.map((requestItem) => {
                return [
                  <Link href={paths.warehouse.item_master_detail(requestItem.Id)}>
                    {requestItem.Name}
                  </Link>,
                  requestItem.ItemRequisitionReportHasItemMaster.Amount,
                  requestItem.ItemUnit.Name,
                ];
              })}
            />
          </ProjectPaper>
          <ProjectPaper>
            <Typography variant="body2" gutterBottom>
              {translate('warehouse.release_item')}
            </Typography>
            <br />
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: '' },
              ]}
              tableData={itemReceipt.ItemReceipt.ItemMovementTransactions.map((transaction) => {
                return [
                  <Link href={paths.warehouse.item_master_detail(transaction.ItemMaster.Id)}>
                    {transaction.ItemMaster.Name}
                  </Link>,
                  transaction.Amount,
                  transaction.ItemMaster.ItemUnit.Name,
                ];
              })}
            />
          </ProjectPaper>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default ItemReceiptDetailPage;
