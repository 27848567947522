import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useForm, useWatch } from 'react-hook-form';
import {
  getDeliveryBillDetailById,
  changeProductSellHistoryPrice,
} from '../../../requests/ProductionServerHandler';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import { Box, Grid, Stack, Button } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import { useMutation } from '@tanstack/react-query';

SearchDeliveryBillComponent.propTypes = {
  onSearchDeliveryBill: PropTypes.func.isRequired,
};

function SearchDeliveryBillComponent(props) {
  const { translate } = useLocales();
  const { onSearchDeliveryBill } = props;
  const searchDeliveryBillSchema = Yup.object().shape({
    DeliveryBillId: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(searchDeliveryBillSchema),
  });

  const { handleSubmit } = methods;

  const searchDeliveryBillMutation = useMutation({
    mutationFn: getDeliveryBillDetailById,
    mutationKey: 'searchDeliveryBillMutation',
    onSuccess: (response, variables) => {
      onSearchDeliveryBill(response.Piles[0].ProductOrder, variables.DeliveryBillId);
    },
    onError: async () => {
      await OpenErrorNotification(translate('error.detail_require'));
    },
  });
  const handleFindDeliveryBillId = async (data) => {
    try {
      await searchDeliveryBillMutation.mutateAsync(data.DeliveryBillId);
    } catch (e) {
      await OpenErrorNotification(translate('error.detail_require'));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(handleFindDeliveryBillId)}>
      <ProjectPaper>
        <RHFTextField name="DeliveryBillId" label={translate('accounting.delivery_bill_id')} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            m: 3,
          }}
        >
          <Button variant={'contained'} type={'submit'} color={'primary'}>
            {translate('button.search')}
          </Button>
        </Box>
      </ProjectPaper>
    </FormProvider>
  );
}

export default function ChangeProductSellHistoryPricePage() {
  const { translate } = useLocales();
  const [editProductOrder, setEditProductOrder] = useState(null);
  const [changeDeliveryBillId, setChangeDeliveryBillId] = useState(null);

  const changeProductSellHistoryPriceMutation = useMutation({
    mutationFn: changeProductSellHistoryPrice,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage(translate('admin.edit_sell_price_title'));
    },
    onError: async () => {
      await OpenErrorNotification(translate('error.detail_require'));
    },
  });

  const changePriceSchema = Yup.object().shape({
    SelectProductSellHistory: Yup.object()
      .shape({
        Id: Yup.number().required(translate('error.detail_require')),
        Name: Yup.string().required(translate('error.detail_require')),
      })
      .required(translate('error.detail_require')),
    NewPrice: Yup.number().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(changePriceSchema),
  });

  const { handleSubmit, control } = methods;

  const selectProductSellHistoryWatch = useWatch({
    control,
    name: 'SelectProductSellHistory',
    defaultValue: null,
  });

  const changeProductSellPrice = async (values) => {
    if (changeDeliveryBillId !== null && editProductOrder !== null) {
      await changeProductSellHistoryPriceMutation.mutateAsync({
        DeliveryBillId: changeDeliveryBillId,
        ProductSellHistoryId: values.SelectProductSellHistory.Id,
        NewPrice: values.NewPrice,
      });
    } else {
      await OpenErrorNotification('Please Select Delivery Bill Id');
    }
  };

  const handleFindProductOrder = (productOrderDetail, changeDeliveryBillId) => {
    setEditProductOrder(productOrderDetail);
    setChangeDeliveryBillId(changeDeliveryBillId);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_sell_price_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.edit_sell_price_title') },
      ]}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {editProductOrder !== null ? (
            <Stack spacing={3}>
              <ProjectPaper>
                <Grid container spacing={3}>
                  <GridDetailText title={translate('title.id')} value={editProductOrder.Id} />
                  <GridDetailText title={translate('title.name')} value={editProductOrder.Name} />
                  {selectProductSellHistoryWatch !== null ? (
                    <GridDetailText
                      title={translate('accounting.current_sell_price')}
                      value={selectProductSellHistoryWatch.SellPrice}
                    />
                  ) : null}
                </Grid>
              </ProjectPaper>
              <ProjectPaper>
                <FormProvider methods={methods} onSubmit={handleSubmit(changeProductSellPrice)}>
                  <Stack spacing={3}>
                    <RHFAutoCompleteShort
                      name={'SelectProductSellHistory'}
                      label={translate('title.process')}
                      data={editProductOrder.ProductSellHistories.map((priceDetail) => {
                        return {
                          Id: priceDetail.Id,
                          Name: priceDetail.Product.Name,
                          SellPrice: priceDetail.SellPrice,
                        };
                      })}
                    />
                    <RHFTextField name={'NewPrice'} label={translate('title.price')} />
                    <Button variant={'contained'} fullWidth type={'submit'}>
                      {translate('button.save')}
                    </Button>
                  </Stack>
                </FormProvider>
              </ProjectPaper>
            </Stack>
          ) : (
            <SearchDeliveryBillComponent onSearchDeliveryBill={handleFindProductOrder} />
          )}
        </Grid>
      </Grid>
    </ProjectPageContainer>
  );
}
