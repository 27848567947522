import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { editProductDetail, getProductDetailById } from '../ProductionServerHandler';
import { OpenSuccessNotification } from '../../utilities/Helper/NotificationHelper';

export const useProduct = (id) => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['product-detail', id],
    queryFn: () => getProductDetailById(id),
    initialData: null,
  });

  const editProductDetailMutation = useMutation({
    mutationFn: editProductDetail,
    onSuccess: async () => {
      await queryClient.invalidateQueries(['product-detail', id]);
      await OpenSuccessNotification();
    },
  });
  return {
    productDetail: data,
    editProduct: editProductDetailMutation,
  };
};
