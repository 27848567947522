import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import UploadFileForm from '../../forms/UploadFileForm';
import ProjectPaper from '../../customs/ProjectPaper';
import {
  OpenConfirmNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { uploadItemMasterPriceFile } from '../../../requests/WarehouseServerHandler';
import { useMutation } from '@tanstack/react-query';

function UploadItemMasterPricePage() {
  const { translate } = useLocales();
  const uploadFileMutation = useMutation({
    mutationFn: uploadItemMasterPriceFile,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage(translate('status.success'));
    },
  });
  const onUploadPriceForm = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_upload_file'),
      translate('button.confirm'),
      '',
      async () => {
        await uploadFileMutation.mutateAsync(values.File[0]);
      }
    );
  };
  return (
    <ProjectPageContainer
      menu_title={`${translate('warehouse.upload_item_price')}`}
      breadcrumbs={[
        { name: translate('warehouse.title') },
        { name: translate('warehouse.upload_item_price') },
      ]}
    >
      <ProjectPaper>
        <UploadFileForm onSubmitForm={onUploadPriceForm} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default UploadItemMasterPricePage;
