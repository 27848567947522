import React from 'react';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import FormProvider, { RHFTextField } from '../minimal/hook-form';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

InventoryCheckoutForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
};

function InventoryCheckoutForm({ onSubmitCallback }) {
  const { translate } = useLocales();
  const inventoryCheckoutSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(inventoryCheckoutSchema),
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onSubmitCallback(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="Detail" label={translate('title.detail')} />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('button.submit')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default InventoryCheckoutForm;
