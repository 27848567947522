import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useLocales } from 'locales';
import ReactTable from '../../react-table/ReactTable';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import { fCurrency } from '../../../utilities/formatNumber';
import { getAllActivePO } from '../../../requests/WarehouseServerHandler';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

function PurchaseOrderListPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const { data: allActivePO } = useQuery({
    queryKey: ['getAllActivePO'],
    queryFn: getAllActivePO,
    initialData: [],
  });

  return (
    <ProjectPageContainer
      menu_title={`${translate('procurement.purchase_order_list_title')} ${translate(
        'warehouse.material_store'
      )}`}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: `${translate('procurement.purchase_order_list_title')} ${translate(
            'warehouse.material_store'
          )}`,
        },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          defaultPageSize={25}
          data={allActivePO.map((po) => {
            return {
              po_id: po.Id,
              po_ref_id: po.ReferenceId,
              supplier: po.Supplier.Name,
              value: po.TotalValue,
              create_by: po.CreateEmployee,
              create_at: po.createdAt,
              delivery_at: po.DeliveredAt,
              actions: po.Id,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'po_id',
              size:20
            },
            {
              header: translate('procurement.po_reference_id_title'),
              accessorKey: 'po_ref_id',
              size:20
            },
            {
              header: translate('procurement.supplier'),
              accessorKey: 'supplier',
            },
            {
              header: translate('title.value'),
              accessorKey: 'value',
              cell: (info) => fCurrency(info.getValue()),
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'create_by',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: translate('title.deliver_at'),
              accessorKey: 'delivery_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton onClick={() => navigate(paths.procurement.po_detail(info.getValue()))}>
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default PurchaseOrderListPage;
