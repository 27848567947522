import React from 'react';
import PropTypes from 'prop-types';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLocales } from '../../locales';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

CreatePileForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreatePileForm({ onFormSubmit }) {
  const { translate } = useLocales();
  const pileCreateSchema = Yup.object().shape({
    Amount: Yup.number()
      .min(1, translate('error.amount_is_require'))
      .max(30000, translate('error.amount_is_require'))
      .required(translate('error.amount_is_require')),
    Height: Yup.number()
      .min(1, translate('error.height_require'))
      .max(300, translate('error.height_require'))
      .required(translate('error.height_require')),
  });

  const methods = useForm({
    resolver: yupResolver(pileCreateSchema),
    defaultValues: {
      ReferenceId: '',
      Amount: 0,
      Height: 0,
    },
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = (data) => {
    onFormSubmit(data);
    reset();
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField name="ReferenceId" label={translate('title.external_reference_id')} />
        <RHFTextField name="Amount" type={'number'} label={translate('title.amount')} />
        <RHFTextField name="Height" type={'number'} label={translate('title.height')} />
        <Button color={'primary'} variant={'contained'} type={'submit'} fullWidth>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreatePileForm;
