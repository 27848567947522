import { m } from 'framer-motion';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// layouts
import CompactLayout from 'layouts/compact';
// routes
import { RouterLink } from 'routes/components';
// components
import { MotionContainer, varBounce } from 'components/minimal/animate';
// assets
import { PageNotFoundIllustration } from 'assets/illustrations';

// ----------------------------------------------------------------------

export default function NotFoundView() {
    return (
        <CompactLayout>
            <MotionContainer>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Sorry, Page Not Found!
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
                        sure to check your spelling.
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <PageNotFoundIllustration
                        sx={{
                            height: 260,
                            my: { xs: 5, sm: 10 },
                        }}
                    />
                </m.div>

                <Button component={RouterLink} href="/" size="large" variant="contained">
                    Go to Home
                </Button>
            </MotionContainer>
        </CompactLayout>
    );
}
