import SalesDashboardPage from '../../components/pages/SalesModule/SalesDashboardPage';
import CreateProductOrderPage from '../../components/pages/SalesModule/CreateProductOrderPage';
import CreateQuotationPage from '../../components/pages/SalesModule/CreateQuotationPage';
import CreateAdditionalCostPage from '../../components/pages/SalesModule/CreateAdditionalCostPage';
import PrintCustomerReportPage from '../../components/pages/SalesModule/PrintCustomerReportPage';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
  ProductionControlUserTypeId,
  QualityAssuranceUserTypeId,
  SalesUserTypeId,
  SecretaryUserTypeId,
} from '../../utilities/Constants';
import PrintSalesValueReportPage from '../../components/pages/SalesModule/PrintSalesValueReportPage';
import CustomerListPage from '../../components/pages/SalesModule/CustomerListPage';
import CustomerDetailPage from '../../components/pages/SalesModule/CustomerDetailPage';
import CreateCustomerPricingPage from '../../components/pages/SalesModule/CreateCustomerPricingPage';
import CustomerPricingDetailPage from '../../components/pages/SalesModule/CustomerPricingDetailPage';
import ProductOrderQuotationDetailPage from '../../components/pages/SalesModule/ProductOrderQuotationDetailPage';
import CreateDiscountBillPage from '../../components/pages/SalesModule/CreateDiscountBillPage';
import { paths } from '../paths';
import ProductOrderQuotationListPage from "../../components/pages/SalesModule/ProductOrderQuotationListPage";
import SearchSalesPage from "../../components/pages/SalesModule/SearchSalesPage";
import CreateProductOrderFromLogisticPage from '../../components/pages/SalesModule/CreateProductOrderFromLogisticPage';
import CreateProductOrderFromLogisticOrderIdPage
  from '../../components/pages/SalesModule/CreateProductOrderFromLogisticOrderIdPage';

const salesModuleRouteDetails = [
  {
    path: paths.sales.dashboard,
    component: <SalesDashboardPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.create_product_order,
    component: <CreateProductOrderPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId, ProductionControlUserTypeId],
  },
  {
    path: paths.sales.create_product_order_quotation,
    component: <CreateQuotationPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.create_product_order_quotation,
    component: <CreateQuotationPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.active_quotation_list,
    component: <ProductOrderQuotationListPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.print_customer_report,
    component: <PrintCustomerReportPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId,ProductionControlUserTypeId],
  },
  {
    path: paths.sales.print_sales_value_report,
    component: <PrintSalesValueReportPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.customer_list,
    component: <CustomerListPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.create_additional_cost,
    component: <CreateAdditionalCostPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.customer_detail(':id'),
    component: <CustomerDetailPage />,
    isPrivate: true,
    allowUserTypeId: [
      SalesUserTypeId,
      SecretaryUserTypeId,
      AdminUserTypeId,
      AccountingUserTypeId,
      FinanceUserTypeId,
      QualityAssuranceUserTypeId,
      ProductionControlUserTypeId,
    ],
  },
  {
    path: paths.sales.create_customer_pricing,
    component: <CreateCustomerPricingPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.customer_pricing_detail(':id'),
    component: <CustomerPricingDetailPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.product_order_quotation_detail(':id'),
    component: <ProductOrderQuotationDetailPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.create_discount_bill,
    component: <CreateDiscountBillPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.search,
    component: <SearchSalesPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.create_order_from_logistic,
    component: <CreateProductOrderFromLogisticPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  {
    path: paths.sales.create_order_from_logistic_by_id(':id'),
    component: <CreateProductOrderFromLogisticOrderIdPage />,
    isPrivate: true,
    allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  },
  // {
  //   path: LogisticProductOrderListPageURL,
  //   component: <LogisticProductOrderListPage />,
  //   isPrivate: true,
  //   allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  // },
  // {
  //   path: PrintLogisticOrderPageURL,
  //   component: <PrintLogisticOrderPage />,
  //   isPrivate: true,
  //   allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  // },
  // {
  //   path: CreateProductOrderFromLogisticPageURL,
  //   component: <CreateProductOrderFromLogisticPage />,
  //   isPrivate: true,
  //   queryParameter: '/:id',
  //   allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  // },
  // {
  //   path: PrintProductQuotationPageURL,
  //   component: <PrintProductQuotationPage />,
  //   isPrivate: true,
  //   allowUserTypeId: [SalesUserTypeId, SecretaryUserTypeId, AdminUserTypeId, AccountingUserTypeId],
  // },
];
export default salesModuleRouteDetails;
