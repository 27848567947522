import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { createAdditionalCost } from 'requests/ProductionServerHandler';
import ProjectPaper from 'components/customs/ProjectPaper';
import { useLocales } from 'locales';
import { OpenNotificationThenReloadPage } from 'utilities/Helper/NotificationHelper';
import CreateAdditionalCostForm from 'components/forms/CreateAdditionalCostForm';
import { useMutation } from '@tanstack/react-query';

export default function CreateAdditionalCostPage() {
  const { translate } = useLocales();

  const createAdditionalCostMutation = useMutation({
    mutationFn: createAdditionalCost,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const onCreateAdditionalCost = async (values) => {
    await createAdditionalCostMutation.mutateAsync({
      Name: values.Name,
      AdditionalCostTypeId: values.AdditionalCostType.Id,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.create_additional_service_cost')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('sales.create_additional_service_cost') },
      ]}
    >
      <ProjectPaper>
        <CreateAdditionalCostForm onFormSubmit={onCreateAdditionalCost} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}
