import React from 'react';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Search } from '@mui/icons-material';
import PropsTypes from 'prop-types';

SearchMaterialForm.propsType = {
  onSubmitCallBack: PropsTypes.func.isRequired,
};

function SearchMaterialForm({ onSubmitCallBack }) {
  const { translate } = useLocales();
  const onSearch = async (value) => {
    onSubmitCallBack(value.ItemId);
    reset();
  };

  const searchMaterialSchema = Yup.object().shape({
    ItemId: Yup.number()
      .min(1, translate('error.error_item_id'))
      .required(translate('error.error_item_id')),
  });

  const methods = useForm({
    resolver: yupResolver(searchMaterialSchema),
    defaultValues: {
      ItemId: '',
    },
  });

  const { handleSubmit, reset } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSearch)}>
      <RHFTextField
        name={'ItemId'}
        label={translate('button.item_id')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type={'submit'} edge="end">
                {<Search />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormProvider>
  );
}

export default SearchMaterialForm;
