import React from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import {
  OpenConfirmNotification,
  OpenNotificationThenReloadPage,
} from 'utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from 'components/customs/ProjectPaper';
import { createClaimRecord } from 'requests/ProductionServerHandler';
import CreateClaimRecordForm from 'components/forms/CreateClaimRecordForm';
import { useMutation } from '@tanstack/react-query';

function CreateClaimRecordPage() {
  const { translate } = useLocales();
  const createClaimRecordMutation = useMutation({
    mutationFn: createClaimRecord,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const createClaim = async (data) => {
    await OpenConfirmNotification(
      translate('warning.confirm_create_data'),
      translate('button.confirm'),
      '',
      async () => {
        await createClaimRecordMutation.mutateAsync({
          Detail: data.Detail,
          ProductOrderId: data.ProductOrderId,
          Amount: data.Amount,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('accounting.create_claim_record')}
      breadcrumbs={[
        { name: translate('accounting.title') },
        { name: translate('accounting.create_claim_record') },
      ]}
    >
      <ProjectPaper>
        <CreateClaimRecordForm onFormSubmit={createClaim} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateClaimRecordPage;
