import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from "locales";
import {Grid} from "@mui/material";
import ProjectPaper from "../customs/ProjectPaper";
import GridDetailText from "../customs/GridDetailText";
import {getEmployeeFullName} from "../../utilities/Helper/UtilitiesHelper";
import {fDateTimeTextShort} from "../../utilities/formatTime";

ProblemDetail.propTypes = {
    problemDetail: PropTypes.object.isRequired
};

function ProblemDetail({problemDetail}) {
    const {translate} = useLocales();

    return (
        <div>
            {
                problemDetail !== undefined && problemDetail !== null ?
                    <ProjectPaper>
                        <Grid container spacing={2}>
                            <GridDetailText title={translate('title.id')} value={problemDetail.Id}/>
                            <GridDetailText title={translate('title.detail')} value={problemDetail.ProblemDetail.Name}/>
                            <GridDetailText title={translate('title.create_by')} value={getEmployeeFullName(problemDetail.CreateEmployee)}/>
                            <GridDetailText title={translate('title.create_at')} value={fDateTimeTextShort(problemDetail.createdAt)}/>
                            <GridDetailText title={translate('title.status')} value={problemDetail.SolvedAt !== null ? translate('status.solved') : translate('status.wait_for_solve')}/>
                            <GridDetailText title={translate('title.approve_date')} value={getEmployeeFullName(problemDetail.SolveEmployee)}/>
                            <GridDetailText title={translate('title.approve_by')} value={fDateTimeTextShort(problemDetail.SolvedAt)}/>
                            <GridDetailText title={translate('title.note')} value={problemDetail.SolvingNote !== null ? problemDetail.SolvingNote : 'N/A'}/>
                        </Grid>
                    </ProjectPaper>
                    :
                    null
            }
        </div>
    );
}

export default ProblemDetail;
