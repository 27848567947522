import React from 'react';
import PropTypes from 'prop-types';
import { getMaintenanceEmployeeDetail } from '../../../requests/ProductionServerHandler';
import DetailWidget from '../../customs/DetailWidget';
import { fNumber } from '../../../utilities/formatNumber';
import { Grid, Stack } from '@mui/material';
import { useLocales } from '../../../locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

MaintenancePerformanceDetail.propTypes = {
  employeeId: PropTypes.number.isRequired,
};

function MaintenancePerformanceDetail({ employeeId }) {
  const { translate } = useLocales();
  const { data: maintenanceDetail } = useQuery({
    queryKey: ['employee-maintenance-detail', employeeId],
    queryFn: () => getMaintenanceEmployeeDetail(employeeId),
  });

  return (
    <>
      {maintenanceDetail !== null && (
        <Stack spacing={3}>
          <Grid sx={{ mt: 3 }} container spacing={3}>
            <Grid item xs={12} md={6}>
              <DetailWidget
                color={'success'}
                title={translate('maintenance.total_accept_job')}
                total={fNumber(maintenanceDetail.Overall.TotalJob)}
                icon={'eva:checkmark-fill'}
                isSimple
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DetailWidget
                color={'info'}
                title={translate('maintenance.complete_job')}
                total={fNumber(maintenanceDetail.Overall.CompleteJob)}
                icon={'ant-design:setting-outlined'}
                isSimple
              />
            </Grid>
          </Grid>
          <ProjectPaper>
            <ReactTable
              search
              data={maintenanceDetail.MaintenanceJob.map((job) => {
                return {
                  id: job.Id,
                  detail: job.Detail,
                  type: job.Type,
                  status: job.JobStatus,
                  create: job.CreatedAt,
                  accept: job.AcceptedAt,
                  complete: job.CompletedAt,
                };
              })}
              header={[
                {
                  header: translate('title.id'),
                  accessorKey: 'id',
                  cell: (info) => (
                    <Link
                      href={paths.maintenance.job_detail(info.getValue())}
                    >{`${info.getValue()}`}</Link>
                  ),
                },
                {
                  header: translate('title.detail'),
                  accessorKey: 'detail',
                },
                {
                  header: translate('title.type'),
                  accessorKey: 'type',
                },
                {
                  header: translate('title.status'),
                  accessorKey: 'status',
                },
                {
                  header: translate('title.create_at'),
                  accessorKey: 'create',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('warehouse.accept_at_title'),
                  accessorKey: 'accept',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('title.complete_date'),
                  accessorKey: 'complete',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
              ]}
            />
          </ProjectPaper>
        </Stack>
      )}
    </>
  );
}

export default MaintenancePerformanceDetail;
