import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useLocales } from '../../locales';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Button, Stack } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';

CreateInventoryShelfForm.propTypes = {
  onSubmitCallback: PropTypes.func.isRequired,
  storeOptions: PropTypes.array.isRequired,
};

function CreateInventoryShelfForm({ onSubmitCallback, storeOptions }) {
  const { translate } = useLocales();

  const createInventoryShelfSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_require')),
    InventoryStore: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(createInventoryShelfSchema),
    defaultValues: {
      Name: '',
      InventoryStore: {
        Id: 0,
        Name: '',
      },
    },
  });

  const { handleSubmit } = methods;
  const onSubmit = (data) => {
    onSubmitCallback(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="Name" label={translate('title.name')} />
        <RHFAutoCompleteShort
          name={'InventoryStore'}
          label={translate('warehouse.store')}
          data={storeOptions}
        />
        <Button variant={'contained'} type={'submit'} color={'primary'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default CreateInventoryShelfForm;
