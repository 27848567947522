import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { IconButton } from '@mui/material';
import { RouterLink } from 'routes/components';
import { getAllItemRequisitionReport } from '../../../requests/WarehouseServerHandler';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';
import { useItemRequisition } from '../../../requests/query/useItemRequisition';

const ItemRequisitionListPage = () => {
  const { translate } = useLocales();
  const { approveItemRequisition } = useItemRequisition(null);

  const { data: requestReports, refetch } = useQuery({
    queryKey: ['getAllItemRequisitionReport'],
    queryFn: getAllItemRequisitionReport,
    initialData: [],
  });

  const onPrintStockReportDocument = async (stockReportId) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_print_item_requisition',
      async () => {
        await approveItemRequisition.mutateAsync(stockReportId);
        await refetch();
      }
    );
  };

  const getTableItem = () => {
    return requestReports.map((report) => {
      return {
        id: report.Id,
        detail: report.Detail,
        create_by: report.CreateEmployee,
        department: report.CreateEmployee.Department.Name,
        priority: report.PriorityLevel.Name,
        create_at: report.createdAt,
        actions: {
          Id: report.Id,
          CompletedAt: report.CompletedAt,
        },
      };
    });
  };
  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.request_list')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.request_list') },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          search
          data={getTableItem()}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.detail'),
              accessorKey: 'detail',
            },
            {
              header: translate('title.create_by'),
              accessorKey: 'create_by',
              cell: (info) => getEmployeeFullName(info.getValue()),
            },
            {
              header: translate('user.department'),
              accessorKey: 'department',
            },
            {
              header: translate('title.priority_level_title'),
              accessorKey: 'priority',
            },
            {
              header: translate('title.create_at'),
              accessorKey: 'create_at',
              cell: (info) => fDateTimeText(info.getValue()),
            },
            {
              header: '',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  {info.getValue().CompletedAt !== null ? (
                    <IconButton onClick={() => onPrintStockReportDocument(info.getValue().Id)}>
                      <Iconify icon={'ic:outline-print'} width={20} height={20} />
                    </IconButton>
                  ) : (
                    <IconButton
                      component={RouterLink}
                      to={paths.warehouse.item_requisition_report_detail(info.getValue().Id)}
                    >
                      <Iconify icon={OPEN_ICON} />
                    </IconButton>
                  )}
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default ItemRequisitionListPage;
