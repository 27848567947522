import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocales } from 'locales';
import {
  OpenNotificationThenCallback,
} from 'utilities/Helper/NotificationHelper';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { paths } from 'routes/paths';
import CreateProductOrderMainForm from '../../forms/CreateProductOrderMainForm';

function CreateProductOrderPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();

  const handleCreateProductOrderDetail = async (newProductOrder) => {
    await OpenNotificationThenCallback(
      translate('notification.create_success'),
      `${translate('title.id')} : ${newProductOrder.Id}`,
      () => {
        navigate(paths.production.product_order_detail(newProductOrder.Id));
      }
    );
  }
  return (
    <ProjectPageContainer
      menu_title={translate('production.create_new_product_order')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('production.create_new_product_order') },
      ]}
    >
      <CreateProductOrderMainForm onCreateProductOrder={handleCreateProductOrderDetail}/>
    </ProjectPageContainer>
  );
}

export default CreateProductOrderPage;
