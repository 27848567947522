import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { AccountingUserTypeId, ManagementUserTypeId, SalesUserTypeId } from 'utilities/Constants';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import { Button, Grid, Stack } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import ProjectPaper from 'components/customs/ProjectPaper';
import GridDetailText from 'components/customs/GridDetailText';
import { getEmployeeFullName } from 'utilities/Helper/UtilitiesHelper';
import { fCurrency } from 'utilities/formatNumber';
import { fDateTimeText } from 'utilities/formatTime';
import GeneralTable from 'components/customs/GeneralTable';
import { useProductOrderAdditionalCostBill } from 'requests/query/useProductOrderAdditionalCostBill';
import { paths } from 'routes/paths';
import { OpenConfirmNotification } from 'utilities/Helper/NotificationHelper';

export default function ProductOrderAdditionalCostBillDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const { productOrderAdditionalCostBill, printBill, cancelBill } =
    useProductOrderAdditionalCostBill(id);

  const onPrintBill = async () => {
    await printBill.mutateAsync(id);
  };

  const onCancelBill = async () => {
    await OpenConfirmNotification(
      translate('warning.confirm_cancel_data'),
      translate('button.confirm'),
      '',
      async () => {
        await cancelBill.mutateAsync(id);
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('sales.product_order_additional_cost_bill_detail_title')}
      breadcrumbs={[
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${
            productOrderAdditionalCostBill?.ProductOrder.Id
          }`,
          href: paths.production.product_order_detail(
            productOrderAdditionalCostBill?.ProductOrder.Id
          ),
        },
        { name: translate('sales.product_order_additional_cost_bill_detail_title') },
      ]}
      action={
        <>
          <RoleBasedGuard roles={[ManagementUserTypeId, AccountingUserTypeId, SalesUserTypeId]}>
            {productOrderAdditionalCostBill?.ArchivedAt === null && (
              <Button
                variant="contained"
                color={'info'}
                startIcon={<Iconify icon={'ic:outline-print'} />}
                onClick={onPrintBill}
              >
                {translate('button.print')}
              </Button>
            )}
          </RoleBasedGuard>
          <RoleBasedGuard roles={[ManagementUserTypeId]}>
            {productOrderAdditionalCostBill?.ArchivedAt === null && (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color={'error'}
                startIcon={<Iconify icon={'eva:close-fill'} />}
                onClick={onCancelBill}
              >
                {translate('button.cancel')}
              </Button>
            )}
          </RoleBasedGuard>
        </>
      }
    >
      {productOrderAdditionalCostBill !== null && (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText title={translate('title.id')} value={id} />
              <GridDetailText
                title={translate('title.create_by')}
                value={getEmployeeFullName(productOrderAdditionalCostBill.CreateEmployee)}
              />
              <GridDetailText
                title={translate('title.name')}
                value={productOrderAdditionalCostBill.ProductOrder.Name}
                link={paths.production.product_order_detail(
                  productOrderAdditionalCostBill.ProductOrder.Id
                )}
              />
              <GridDetailText
                title={translate('title.customer')}
                value={productOrderAdditionalCostBill.ProductOrder.Customer.Name}
              />
              <GridDetailText
                title={translate('title.value')}
                value={fCurrency(productOrderAdditionalCostBill.TotalValue)}
              />
              <GridDetailText
                title={translate('title.vat')}
                value={fCurrency(productOrderAdditionalCostBill.TotalVat)}
              />
              <GridDetailText
                title={translate('title.billing_at')}
                value={
                  productOrderAdditionalCostBill.InvoiceAt !== null
                    ? fDateTimeText(productOrderAdditionalCostBill.InvoiceAt)
                    : 'N/A'
                }
              />
              <GridDetailText
                title={translate('title.create_at')}
                value={fDateTimeText(productOrderAdditionalCostBill.createdAt)}
              />
              {productOrderAdditionalCostBill?.ArchivedAt !== null && (
                <>
                  <GridDetailText
                    title={translate('title.canceled_at')}
                    value={fDateTimeText(productOrderAdditionalCostBill?.ArchivedAt)}
                  />
                </>
              )}
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: '#' },
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.value' },
              ]}
              tableData={productOrderAdditionalCostBill.ProductOrderAdditionalCosts.map(
                (cost, index) => {
                  return [index + 1, cost.AdditionalCost.Name, fCurrency(cost.Value)];
                }
              )}
            />
          </ProjectPaper>
        </Stack>
      )}
    </ProjectPageContainer>
  );
}
