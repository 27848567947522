import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import useQueryParameter from '../../../hooks/useQueryParameter';
import CreateProductOrderProblemDetailForm from '../../forms/CreateProductOrderProblemDetailForm';
import ProjectPaper from '../../customs/ProjectPaper';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createProductOrderProblems,
  getAllProblemDetail,
} from '../../../requests/ProductionServerHandler';
import {
  OpenConfirmNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';

function CreateProductOrderProblemDetailPage() {
  const { translate } = useLocales();
  const queryParameter = useQueryParameter();
  const productOrderId = queryParameter.get('productOrderId');
  const navigate = useNavigate();

  const { data: problemDetails } = useQuery(['problemDetails'], getAllProblemDetail);
  const createProductOrderProblemsMutation = useMutation({
    mutationFn: createProductOrderProblems,
    onSuccess: async () => {
      await OpenSuccessNotification(translate('status.success'));
      navigate(paths.production.product_order_detail(productOrderId));
    },
  });
  const onCreateProblem = async (value) => {
    await OpenConfirmNotification(
      translate('warning.confirm_add_problem'),
      translate('button.confirm'),
      '',
      async () => {
        await createProductOrderProblemsMutation.mutateAsync({
          ProductOrderId: productOrderId,
          Problems: value.Problems,
        });
      }
    );
  };

  return (
    <ProjectPageContainer
      menu_title={translate('button.add_problem_flag')}
      breadcrumbs={[
        { name: translate('production.title') },
        {
          name: translate('production.product_order_list'),
          href: paths.production.product_order_list,
        },
        {
          name: `${translate('production.product_order_detail')} ${translate('title.id')} : ${
            productOrderId !== null ? productOrderId : 'ไม่มี'
          }`,
          href:
            productOrderId !== null
              ? paths.production.product_order_detail(productOrderId)
              : paths.production.product_order_list,
        },
        { name: translate('button.add_problem_flag') },
      ]}
    >
      <ProjectPaper>
        {problemDetails !== undefined && problemDetails.length > 0 ? (
          <CreateProductOrderProblemDetailForm
            onSubmitValue={onCreateProblem}
            allProblemDetails={problemDetails}
          />
        ) : null}
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateProductOrderProblemDetailPage;
