import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { Button, Stack } from '@mui/material';
import ProjectPaper from '../../customs/ProjectPaper';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import { useMutation } from '@tanstack/react-query';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';
import {
  getItemContainerExcelFileURL,
  getItemMasterExcelFileURL,
  getDownloadItemMasterTransactionExcelFileURL,
} from '../../../requests/WarehouseServerHandler';

export default function PrintWarehouseDataReportPage(props) {
  const { translate } = useLocales();
  const { showLoadingScreen, hideLoadingScreen } = useLoading();
  const printItemMasterExcelMutation = useMutation({
    mutationFn: getItemMasterExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'ItemMaster_Report.xlsx');
      await OpenSuccessNotification();
    },
  });
  const printItemContainerExcelMutation = useMutation({
    mutationFn: getItemContainerExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'ItemContainer_Report.xlsx');
      await OpenSuccessNotification();
    },
  });

  const printItemMasterTransactionMutation = useMutation({
    mutationFn: getDownloadItemMasterTransactionExcelFileURL,
    onMutate: async () => {
      showLoadingScreen();
    },
    onSuccess: async (report) => {
      hideLoadingScreen();
      saveAs(report, 'ItemMaster_Transaction_Report.xlsx');
      await OpenSuccessNotification();
    },
  });

  const handlePrintItemMasterExcel = async () => {
    await printItemMasterExcelMutation.mutateAsync();
  };
  const handlePrintItemContainerExcel = async () => {
    await printItemContainerExcelMutation.mutateAsync();
  };

  const handlePrintItemMasterTransactionExcel = async () => {
    if (fromDate !== null) {
      await printItemMasterTransactionMutation.mutateAsync(fromDate.toISOString());
    } else {
      await OpenErrorNotification(translate('error.invalid_input'));
    }
  };
  const handleFromChange = (momentObject) => {
    setFromDate(momentObject);
  };
  const [fromDate, setFromDate] = useState(null);
  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.print_warehouse_data_page_title')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.print_warehouse_data_page_title') },
      ]}
    >
      <Stack spacing={3}>
        <ProjectPaper>
          <Stack spacing={3}>
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth
              onClick={handlePrintItemMasterExcel}
            >
              {translate('button.print_item_master_excel')}
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth
              onClick={handlePrintItemContainerExcel}
            >
              {translate('button.print_item_container_excel')}
            </Button>
          </Stack>
        </ProjectPaper>
        <ProjectPaper>
          <Stack spacing={3}>
            <MUIDateTimePicker
              ampm={false}
              format="dd/MM/yyyy HH:mm"
              label={translate('title.start_date')}
              value={fromDate}
              onChange={handleFromChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
            <Button
              variant={'contained'}
              fullWidth
              onClick={handlePrintItemMasterTransactionExcel}
              color={'primary'}
            >
              {translate('button.print_item_master_amount_excel')}
            </Button>
          </Stack>
        </ProjectPaper>
      </Stack>
    </ProjectPageContainer>
  );
}
