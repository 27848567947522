import React, { useState } from 'react';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { IconButton } from '@mui/material';
import DatePickerInterval from 'components/date-picker/DatePickerInterval';
import { RouterLink } from 'routes/components';
import ReactTable from 'components/react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from 'components/customs/ProjectPaper';
import { useLocales } from 'locales';
import { getProductionEmployeeDashboardData } from 'requests/ProductionServerHandler';
import { fNumber } from 'utilities/formatNumber';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'routes/paths';
import { OPEN_ICON } from 'config-global';

function ProductionEmployeeDashboardPage() {
  const { translate } = useLocales();
  const fromDate = new Date();
  fromDate.setHours(0, 0, 0, 0);
  const [dateRange, setDateRange] = useState({
    fromDate: fromDate,
    toDate: new Date(),
  });
  const { data: employeeDetail, refetch } = useQuery({
    queryKey: ['production-employee-dashboard', dateRange],
    queryFn: () => getProductionEmployeeDashboardData(dateRange),
    initialData: [],
  });

  const handleChangeDate = async (from, to) => {
    setDateRange({
      fromDate: from,
      toDate: to,
    });

    await refetch();
  };

  return (
    <ProjectPageContainer
      menu_title={translate('production.employee_dashboard')}
      breadcrumbs={[
        { name: translate('production.title') },
        { name: translate('production.employee_dashboard') },
      ]}
    >
      <DatePickerInterval onFetchData={handleChangeDate} />
      <ProjectPaper>
        <ReactTable
          defaultPageSize={25}
          search
          data={employeeDetail.map((employee) => {
            return {
              name: employee.Name,
              total_good: employee.TotalAmountSubmit,
              total_bad: employee.TotalDefectSubmit,
              department: employee.Department,
              actions: employee.Id,
            };
          })}
          header={[
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.amount_good'),
              accessorKey: 'total_good',
              cell: (info) => fNumber(info.getValue()),
            },
            {
              header: translate('title.amount_defect'),
              accessorKey: 'total_bad',
              cell: (info) => fNumber(info.getValue()),
            },
            {
              header: translate('user.department'),
              accessorKey: 'department',
            },
            {
              header: 'Actions',
              accessorKey: 'actions',
              cell: (info) => (
                <IconButton component={RouterLink} to={paths.hr.employee_detail(info.getValue())}>
                  <Iconify icon={OPEN_ICON} />
                </IconButton>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default ProductionEmployeeDashboardPage;
