import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import { createLogisticCar } from '../../../requests/ProductionServerHandler';
import CreateCarForm from '../../forms/CreateCarForm';
import { useMutation } from '@tanstack/react-query';

function CreateCarPage() {
  const { translate } = useLocales();
  const createCarMutation = useMutation({
    mutationFn: createLogisticCar,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });
  const handleCreateCar = async (values) => {
    await createCarMutation.mutateAsync({
      CarRegistrationNumber: values.CarRegistrationNumber,
      CarTypeId: values.CarType.Id,
      CarBrand: values.CarBrand,
    });
  };

  return (
    <ProjectPageContainer
      menu_title={translate('logistic.add_car')}
      breadcrumbs={[{ name: translate('logistic.title') }, { name: translate('logistic.add_car') }]}
    >
      <ProjectPaper>
        <CreateCarForm onSubmitCallback={handleCreateCar} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateCarPage;
