import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
import ProductOrderDetail from './product_order/ProductOrderDetail';
import { useLocales } from 'locales';

ProductOrderDetailDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
};

function ProductOrderDetailDialog({ isOpen, handleDialogClose, productOrderDetail }) {
  const { translate } = useLocales();

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleDialogClose}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-po"
        aria-describedby="scroll-dialog-po-description"
      >
        <DialogTitle id="scroll-dialog-po">{translate('title.po_detail')}</DialogTitle>
        <DialogContent dividers>
          {productOrderDetail !== null && (
            <ProductOrderDetail productOrder={productOrderDetail} isShortMode={true} />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={handleDialogClose}>
            {translate('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ProductOrderDetailDialog;
