import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, CardHeader, IconButton, Stack } from '@mui/material';
import { RouterLink } from 'routes/components';
import GeneralTable from '../../customs/GeneralTable';
import { fDateTimeText } from '../../../utilities/formatTime';
import Iconify from 'components/minimal/iconify';
import { useLocales } from '../../../locales';
import { useTheme } from '@mui/material/styles';
import Label from 'components/minimal/label';
import { paths } from '../../../routes/paths';
import { OPEN_ICON } from '../../../config-global';
import { useQuery } from '@tanstack/react-query';
import { getLogisticPileByProductOrderId } from '../../../requests/ITServerHandler';

ProductOrderPileDetail.propTypes = {
  piles: PropTypes.array,
  productOrderId: PropTypes.number.isRequired,
};

function ProductOrderPileDetail({ piles, productOrderId }) {
  const { translate } = useLocales();
  const theme = useTheme();
  const getIcon = (pileStatusId) => {
    let text = translate(`status.pile_status_${pileStatusId}`);
    let color = 'info';
    if (pileStatusId === 1) {
      color = 'info';
    }
    if (pileStatusId === 2) {
      color = 'info';
    }
    if (pileStatusId === 3) {
      color = 'warning';
    }
    if (pileStatusId === 4) {
      color = 'success';
    }
    if (pileStatusId === 5) {
      color = 'error';
    }
    if (pileStatusId === 6) {
      color = 'success';
    }
    if (pileStatusId === 7) {
      color = 'error';
    }

    return (
      <Label
        variant={theme.palette.mode === 'light' ? 'filled' : 'filled'}
        color={color}
        sx={{ textTransform: 'capitalize' }}
      >
        {text}
      </Label>
    );
  };

  const { data: logisticPiles } = useQuery({
    queryKey: ['logistic-piles', productOrderId],
    queryFn: () => getLogisticPileByProductOrderId(productOrderId),
    initialData: [],
  });

  return (
    <Card>
      <CardHeader
        title={'ขางานในโรงงาน'}
        action={
          <Stack spacing={2} direction={'row'} >
            <Button
              variant="contained"
              color={'primary'}
              component={RouterLink}
              to={`${paths.production.create_pile_from_logistic_pile}?productOrderId=${productOrderId}`}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              {`รับขางานจากแผนกจัดส่ง (${logisticPiles.filter((pile) => pile.sil_created === false).length} ขา)`}
            </Button>
            <Button
              variant="contained"
              color={'primary'}
              component={RouterLink}
              to={`${paths.production.create_pile}?productOrderId=${productOrderId}`}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              {translate('production.create_pile')}
            </Button>
          </Stack>
        }
      />
      <CardContent>
        <GeneralTable
          tableHeaders={[
            { align: 'left', title: 'title.id' },
            { align: 'left', title: 'title.external_reference_id' },
            { align: 'left', title: 'title.amount' },
            { align: 'left', title: 'title.amount_good' },
            { align: 'left', title: 'title.amount_defect' },
            { align: 'left', title: 'title.height' },
            { align: 'left', title: 'title.complete_height' },
            { align: 'left', title: 'title.status' },
            { align: 'left', title: 'title.create_at' },
            { align: 'left', title: '' },
          ]}
          tableData={piles.map((pile) => [
            pile.Id,
            pile.ReferenceId,
            pile.Amount,
            pile.GoodAmount,
            pile.DefectAmount,
            pile.Height,
            pile.CompleteHeight,
            getIcon(pile.PileStatusId),
            fDateTimeText(pile.createdAt),
            <IconButton
              key={pile.Id}
              component={RouterLink}
              href={paths.production.pile_detail(pile.Id)}
            >
              <Iconify icon={OPEN_ICON} />
            </IconButton>,
          ])}
        />
      </CardContent>
    </Card>
  );
}

export default ProductOrderPileDetail;
