import * as Yup from "yup";

export const changePriceSchema = (translate) => {
    return Yup.object().shape({
        SelectProductSellHistory: Yup.object().shape({
            Id: Yup.number().required(translate('error.detail_require')),
            Name: Yup.string().required(translate('error.detail_require')),
        }).required(translate('error.detail_require')),
        NewPrice: Yup.number().required(translate('error.detail_require')),
    });
}