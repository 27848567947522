import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { createReworkOrderByPlanning } from '../../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import CustomStepper from '../../customs/CustomStepper';
import ReworkOrderDetailForm from '../../forms/ReworkOrderDetailForm';
import ReworkOrderSelectProcessForm from '../../forms/ReworkOrderSelectProcessForm';
import GeneralTable from '../../customs/GeneralTable';
import ProjectPaper from '../../customs/ProjectPaper';
import { Box, Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import GridDetailText from '../../customs/GridDetailText';
import { PrintMultiplePDFMakeDocument } from '../../../utilities/Helper/PrintHelper';
import { pileDataTable } from '../../../utilities/Helper/DataHelper';
import PileScanner from '../../barcode-reader/PileScanner';
import { useMutation } from '@tanstack/react-query';

export default function CreateReworkOrderPage() {
  const { translate } = useLocales();
  const [activeStep, setActiveStep] = useState(0);
  const [orderDetail, setOrderDetail] = useState('');
  const [selectDataTable, setSelectDataTable] = useState({});
  const [selectProductionProcess, setSelectProductionProcess] = useState([]);
  const [checkoutPile, setCheckoutPiles] = useState([]);
  const STEPS = [
    translate('title.detail'),
    translate('title.process'),
    translate('title.pile'),
    translate('title.conclude'),
  ];
  const createReworkOrderMutation = useMutation({
    mutationFn: createReworkOrderByPlanning,
    onSuccess: async (data) => {
      await PrintMultiplePDFMakeDocument(data.PDF, 'ใบงานซ่อม.pdf');
      await OpenNotificationThenReloadPage();
    },
  });

  const handleCreateReworkOrder = async () => {
    await createReworkOrderMutation.mutateAsync({
      Detail: orderDetail,
      ProductionProcess: selectProductionProcess.map((process) => process.Id),
      Piles: checkoutPile.map((pile) => pile.Id),
    });
  };
  const onSearchPileDetails = async (searchPile) => {
    if (checkoutPile.find((pile) => pile.Id === searchPile.Id) === undefined) {
      setCheckoutPiles([...checkoutPile, searchPile]);
    } else {
      await OpenErrorNotification(translate('error.pile_not_found'));
    }
  };
  const onRemoveItem = (removeIndex) => {
    setCheckoutPiles(checkoutPile.filter((item, index) => index !== removeIndex));
  };

  const handleSaveOrderDetail = (detail) => {
    setOrderDetail(detail);
    setActiveStep(1);
  };

  const handleSelectProcess = (process, asd) => {
    setSelectProductionProcess(process);
    setActiveStep(2);
    setSelectDataTable(asd);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('planning.create_rework_order')}
      breadcrumbs={[
        { name: translate('planning.title') },
        { name: translate('planning.create_rework_order') },
      ]}
    >
      <CustomStepper currentStep={activeStep} steps={STEPS} />
      {activeStep === 0 && (
        <ReworkOrderDetailForm onCompleteForm={handleSaveOrderDetail} detail={orderDetail} />
      )}
      {activeStep === 1 && (
        <ReworkOrderSelectProcessForm
          onSelectProcess={handleSelectProcess}
          defaultSelectProcess={selectDataTable}
          onBackPressed={() => setActiveStep(0)}
        />
      )}
      {activeStep === 2 && (
        <ProjectPaper>
          <Stack spacing={3}>
            <PileScanner onSearchCallBack={onSearchPileDetails} isPreFetchData />
            {pileDataTable(checkoutPile, onRemoveItem)}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
              }}
            >
              <Button color={'primary'} onClick={() => setActiveStep(1)}>
                {translate('button.back')}
              </Button>
              <Button
                color={'primary'}
                disabled={checkoutPile.length === 0}
                variant={'contained'}
                onClick={() => setActiveStep(3)}
              >
                {translate('button.next')}
              </Button>
            </Box>
          </Stack>
        </ProjectPaper>
      )}
      {activeStep === 3 && (
        <>
          <Stack spacing={3}>
            <ProjectPaper>
              <Grid container spacing={3}>
                <GridDetailText title={translate('title.detail')} value={orderDetail} />
              </Grid>
            </ProjectPaper>
            <ProjectPaper>
              <GeneralTable
                tableHeaders={[
                  { align: 'left', title: '#' },
                  { align: 'left', title: 'title.id' },
                  { align: 'left', title: 'title.name' },
                  { align: 'left', title: 'title.type' },
                ]}
                tableData={selectProductionProcess.map((process, index) => {
                  return [index + 1, process.Id, process.Name, process.ProductionProcessType.Name];
                })}
              />
            </ProjectPaper>
            <ProjectPaper>
              <GeneralTable
                tableHeaders={[
                  { align: 'left', title: '#' },
                  { align: 'left', title: 'title.id' },
                  { align: 'left', title: 'title.name' },
                  { align: 'left', title: 'title.customer' },
                  { align: 'left', title: 'title.amount_good' },
                  { align: 'left', title: 'title.amount_defect' },
                ]}
                tableData={checkoutPile.map((pile, index) => {
                  return [
                    index + 1,
                    pile.Id,
                    pile.ProductOrder.Name,
                    pile.ProductOrder.Customer.Name,
                    pile.GoodAmount,
                    pile.DefectAmount,
                  ];
                })}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 3,
                }}
              >
                <Button color={'primary'} onClick={() => setActiveStep(2)}>
                  {translate('button.back')}
                </Button>
                <Button
                  color={'primary'}
                  disabled={checkoutPile.length === 0}
                  variant={'contained'}
                  onClick={handleCreateReworkOrder}
                >
                  {translate('button.create')}
                </Button>
              </Box>
            </ProjectPaper>
          </Stack>
        </>
      )}
    </ProjectPageContainer>
  );
}
