import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { createWarehouse } from '../../../requests/WarehouseServerHandler';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import CreateInventoryForm from '../../forms/CreateInventoryForm';
import { useMutation } from '@tanstack/react-query';

function CreateWarehousePage() {
  const { translate } = useLocales();

  const createWarehouseMutation = useMutation({
    mutationFn: createWarehouse,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const handleCreateWarehouse = async (values) => {
    await createWarehouseMutation.mutateAsync(values.Name);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_new_store')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.create_new_store') },
      ]}
    >
      <ProjectPaper>
        <CreateInventoryForm onFormSubmit={handleCreateWarehouse} />
      </ProjectPaper>
    </ProjectPageContainer>
  );
}

export default CreateWarehousePage;
