import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocales } from 'locales';
import Iconify from 'components/minimal/iconify';
import ProjectPaper from '../../customs/ProjectPaper';
import ItemContainerDetail from '../../details/warehouse/ItemContainerDetail';
import ReactTable from '../../react-table/ReactTable';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useTabs from '../../../hooks/useTabs';
import { OPEN_ICON } from '../../../config-global';
import { useItemContainerDetail } from '../../../requests/query/useItemContainerDetail';
import { paths } from '../../../routes/paths';
import Link from "@mui/material/Link";

function ItemContainerDetailPage() {
  const { id } = useParams();
  const { translate } = useLocales();
  const { itemContainer } = useItemContainerDetail(id);
  const { currentTab, onChangeTab } = useTabs('detail');

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: <ItemContainerDetail itemContainer={itemContainer} />,
    },
    {
      value: 'lots',
      title: translate('warehouse.lot_detail'),
      icon: <Iconify icon={'fe:list-order'} width={20} height={20} />,
      component: (
        <ProjectPaper>
          <ReactTable
            data={itemContainer?.ItemLots.map((itemLot) => {
              return {
                id: itemLot.Id,
                value: itemLot.Value,
                amount: itemLot.Amount,
                master_amount: itemLot.MasterAmount,
                actions: itemLot.Id,
              };
            })}
            header={[
              {
                header: translate('title.id'),
                accessorKey: 'id',
              },
              {
                header: translate('title.amount'),
                accessorKey: 'amount',
              },
              {
                header: translate('warehouse.master_amount'),
                accessorKey: 'master_amount',
              },
              {
                header: translate('title.value'),
                accessorKey: 'value',
              },
              {
                header: 'Actions',
                accessorKey: 'actions',
                cell: (info) => (
                  <IconButton
                    component={Link}
                    href={paths.warehouse.item_lot_detail(info.getValue())}
                  >
                    <Iconify icon={OPEN_ICON} />
                  </IconButton>
                ),
              },
            ]}
          />
        </ProjectPaper>
      ),
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.item_container_detail')}
      breadcrumbs={[
        { name: translate('warehouse.material_store') },
        { name: translate('warehouse.title'), href: paths.warehouse.store },
        {
          name: `${translate('warehouse.item_template_detail')} ${translate('title.id')} : ${
            itemContainer?.ItemMaster.Id
          }`,
          href: paths.warehouse.item_master_detail(itemContainer?.ItemMaster.Id),
        },
        {
          name: `${translate('warehouse.item_container_detail')} ${translate('title.id')} : ${
            itemContainer?.Id
          }`,
        },
      ]}
    >
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {INFORMATION_TABS.map((tab, index) => (
          <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
        ))}
      </Tabs>
      <Box sx={{ mb: 3 }} />
      {INFORMATION_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && tab.component;
      })}
    </ProjectPageContainer>
  );
}

export default ItemContainerDetailPage;
