import React from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormProvider, { RHFTextField, RHFDatePicker } from '../minimal/hook-form';
import { Box, Card, CardContent, CardHeader, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

ProductOrderQuotationDetailForm.propTypes = {
  productOrderDetail: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Detail: PropTypes.string,
    Width: PropTypes.number.isRequired,
    Length: PropTypes.number.isRequired,
    Amount: PropTypes.number.isRequired,
  }).isRequired,
  onSubmitProductOrderDetail: PropTypes.func.isRequired,
  onBackPress: PropTypes.func.isRequired,
};

function ProductOrderQuotationDetailForm({
  productOrderDetail,
  onSubmitProductOrderDetail,
  onBackPress,
}) {
  const { translate } = useLocales();
  const ProductOrderDetailSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    Width: Yup.number()
      .min(5, translate('error.width_is_require'))
      .required(translate('error.width_is_require')),
    Length: Yup.number()
      .min(5, translate('error.length_is_require'))
      .required(translate('error.length_is_require')),
    Amount: Yup.number()
      .min(1, translate('error.amount_is_require'))
      .required(translate('error.amount_is_require')),
    ExpiredAt: Yup.date().required(translate('error.due_date_require')),
  });

  const defaultValues = {
    Name: productOrderDetail?.Name || '',
    Detail: productOrderDetail?.Detail || '',
    Width: productOrderDetail?.Width || 0,
    Length: productOrderDetail?.Length || 0,
    Amount: productOrderDetail?.Amount || 0,
    ExpiredAt: new Date(),
  };

  const methods = useForm({
    resolver: yupResolver(ProductOrderDetailSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (value) => {
    onSubmitProductOrderDetail(value);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={translate('title.detail')} />
        <CardContent>
          <Stack spacing={3} alignItems="flex-end">
            <RHFTextField name="Name" label={translate('title.name')} />
            <RHFTextField name="Detail" label={translate('title.detail')} />
          </Stack>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              mt: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
            }}
          >
            <RHFTextField name="Width" label={translate('title.width')} />
            <RHFTextField name="Length" label={translate('title.length')} />
          </Box>
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <RHFTextField name="Amount" label={translate('title.amount')} />
          </Stack>
          <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
            <RHFDatePicker name="ExpiredAt" label={translate('title.expire_at')} includeTime />
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mt: 3,
            }}
          >
            <Button color={'primary'} onClick={onBackPress}>
              {translate('button.back')}
            </Button>
            <LoadingButton
              color={'primary'}
              variant="contained"
              type={'submit'}
              loading={isSubmitting}
            >
              {translate('button.next')}
            </LoadingButton>
          </Box>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

export default ProductOrderQuotationDetailForm;
