import React, { useCallback } from 'react';
import FormProvider, { RHFUpload } from '../minimal/hook-form';
import { useLocales } from '../../locales';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Button, Stack } from '@mui/material';
import { Alert } from '@mui/lab';

UploadFileForm.PropType = {
  onSubmitForm: PropTypes.func.isRequired,
};

function UploadFileForm({ onSubmitForm }) {
  const { translate } = useLocales();

  const uploadFileFormSchema = Yup.object().shape({
    File: Yup.array().length(1).required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(uploadFileFormSchema),
    defaultValues: {
      File: [],
    },
  });

  const {
    formState: { errors },
    setValue,
    handleSubmit,
  } = methods;

  const onSubmit = async (values) => {
    onSubmitForm(values);
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      setValue('File', acceptedFiles);
    },
    [setValue]
  );
  const handleRemoveFiles = () => {
    setValue('File', []);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {errors.File !== undefined && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {translate('error.error_invalid_file')}
        </Alert>
      )}
      <Stack spacing={2}>
        <RHFUpload
          multiple
          name="File"
          accept={[
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          maxSize={3145728}
          onDrop={handleDrop}
          onRemove={handleRemoveFiles}
        />
        <Button color={'primary'} variant={'contained'} fullWidth type={'submit'}>
          {translate('button.create')}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default UploadFileForm;
