// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Tooltip, Container, Typography, Alert } from '@mui/material';
// hooks
import useAuth from 'auth/hooks/useAuth';
// components
import Image from 'components/minimal/image';
import SILLogo from 'assets/SIL_Main_logo.png';
// sections
import {useLocales} from "locales";
import {ProjectPageContainer} from "components/container/ProjectPageContainer";
import LoginForm from "components/forms/LoginForm";
import { Navigate } from 'react-router-dom';
import {PATH_AFTER_LOGIN} from "config-global";
import ProjectPaper from "components/customs/ProjectPaper";

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '10',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
    const { isAuthenticated } = useAuth();
    const { translate } = useLocales();

    if (isAuthenticated) {
        return <Navigate to={PATH_AFTER_LOGIN} />;
    }

    return (
        <ProjectPageContainer menu_title={'Login'}>
            <RootStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        <ProjectPaper>
                            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography variant="h4" gutterBottom>
                                        {translate('auth.sign_in_to_sil_service')}
                                    </Typography>
                                    <Typography sx={{ color: 'text.secondary' }}>{translate('auth.enter_detail')}</Typography>
                                </Box>

                                <Tooltip title={'test'} placement="right">
                                    <>
                                        <Image
                                            disabledEffect
                                            src={SILLogo}
                                            sx={{ width: 32, height: 32 }}
                                        />
                                    </>
                                </Tooltip>
                            </Stack>
                            <Alert severity="info" sx={{ mb: 3 }}>
                                {translate('auth.tooltip')}
                            </Alert>
                            <LoginForm />
                        </ProjectPaper>

                    </ContentStyle>
                </Container>
            </RootStyle>
        </ProjectPageContainer>
    );

}
