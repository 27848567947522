import React from 'react';
import Button from '@mui/material/Button';
import { useLocales } from 'locales';

function SubmitFormButton() {
  const { translate } = useLocales();
  return (
    <Button color="primary" type="submit" variant="contained">
      {translate('button.create')}
    </Button>
  );
}

export default SubmitFormButton;
