import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  adjustPOPartsCart,
  selectPurchaseOrderItemPartsCart,
} from '../../../redux/reducers/procurement';
import { getItemTemplateSellerList } from '../../../requests/ProductionServerHandler';
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from 'components/minimal/iconify';
import { useLocales } from 'locales';
import GridDetailText from '../../customs/GridDetailText';
import { fNumber } from '../../../utilities/formatNumber';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { showRequestToBuyItemInput } from '../../../utilities/Helper/NotificationHelper';
import { useQuery } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';
import { RouterLink } from '../../../routes/components';

function SearchSupplierSellItemTemplatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { id } = useParams();
  const PurchaseOrderItemParts = useSelector(selectPurchaseOrderItemPartsCart);

  const { data: itemTemplateSeller } = useQuery({
    queryKey: ['getItemTemplateSellerList', id],
    queryFn: () => getItemTemplateSellerList(id),
    initialData: null,
  });

  const onConfirm = (item, amount) => {
    dispatch(
      adjustPOPartsCart({
        Item: {
          Id: item.Id,
          Name: item.Name,
          Detail: null,
          Unit: item.Unit,
          Price: item.Price,
          Amount: amount,
          PRItemTemplateId: item.PRItemTemplateId,
          IsTemp: false,
        },
        Supplier: item.Supplier,
      })
    );
  };

  const getRecommendBuyAmount = (sellItemDetail) => {
    const targetAmount = itemTemplateSeller.Amount;
    const onPackSize = sellItemDetail.SellUnitPerItemTemplateUnitAmount;
    return Math.ceil(targetAmount / onPackSize);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.search_purchase_item_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.create_po_parts_title'),
          href: paths.procurement.create_parts_po,
        },
        { name: `${translate('procurement.search_purchase_item_title')} : ${id}` },
      ]}
      action={
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            color={'info'}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            component={RouterLink}
            to={`${
              paths.procurement.create_supplier_sell_item_template
            }?SupplierId=${null}&ItemTemplateId=${
              itemTemplateSeller?.ItemTemplateId
            }&SellItemId=${id}`}
          >
            {translate('procurement.add_supplier_sell_item')}
          </Button>
          <Button
            variant="contained"
            color={'primary'}
            startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
            component={RouterLink}
            disabled={PurchaseOrderItemParts.length === 0}
            to={paths.procurement.check_out_part_po}
          >
            {`${translate('procurement.buy_item')} ${PurchaseOrderItemParts.length} ${translate(
              'warehouse.items'
            )}`}
          </Button>
        </Stack>
      }
    >
      {itemTemplateSeller !== null ? (
        <React.Fragment>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('title.name')}
                value={itemTemplateSeller.ItemTemplate.Name}
                link={paths.general_warehouse.item_template_detail(
                  itemTemplateSeller.ItemTemplate.Id
                )}
              />
              <GridDetailText
                title={translate('warehouse.request_buy_item_amount_title')}
                value={`${fNumber(itemTemplateSeller.Amount)} ${
                  itemTemplateSeller.ItemTemplate.ItemTemplateUnit.Name
                }`}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <ReactTable
              search
              data={itemTemplateSeller.ItemTemplate.SupplierSellItemTemplates.map((sellItem) => {
                return {
                  name: sellItem.Name,
                  supplier: sellItem.Supplier.Name,
                  unit: sellItem.SellItemTemplateUnit.Name,
                  price_per_unit: sellItem.PricePerSellUnit,
                  convert_unit: `${fNumber(sellItem.SellUnitPerItemTemplateUnitAmount)} ${
                    itemTemplateSeller.ItemTemplate.ItemTemplateUnit.Name
                  }`,
                  recommend_unit: getRecommendBuyAmount(sellItem),
                  actions: sellItem,
                };
              })}
              header={[
                {
                  header: translate('procurement.supplier_sell_item_name'),
                  accessorKey: 'name',
                },
                {
                  header: translate('procurement.supplier'),
                  accessorKey: 'supplier',
                },
                {
                  header: translate('procurement.sell_unit'),
                  accessorKey: 'unit',
                },
                {
                  header: translate('warehouse.convert_to_master_amount'),
                  accessorKey: 'convert_unit',
                },
                {
                  header: translate('procurement.price_average_detail_title'),
                  accessorKey: 'price_per_unit',
                },
                {
                  header: translate('procurement.recommend_to_buy_amount'),
                  accessorKey: 'recommend_unit',
                },
                {
                  header: '',
                  accessorKey: 'actions',
                  cell: (info) => (
                    <>
                      <IconButton
                        onClick={() =>
                          navigate(
                            paths.procurement.edit_supplier_sell_item_template(info.getValue().Id)
                          )
                        }
                      >
                        <Iconify icon={'ic:baseline-edit'} width={20} height={20} />
                      </IconButton>
                      <IconButton
                        color={'success'}
                        onClick={() =>
                          showRequestToBuyItemInput(
                            translate,
                            {
                              Id: info.getValue().Id,
                              Name: info.getValue().Name,
                              Unit: info.getValue().SellItemTemplateUnit.Name,
                              Price: info.getValue().PricePerSellUnit,
                              Supplier: info.getValue().Supplier,
                              PRItemTemplateId: id,
                            },
                            getRecommendBuyAmount(info.getValue()),
                            onConfirm
                          )
                        }
                      >
                        <Iconify icon={'ic:baseline-add-shopping-cart'} width={20} height={20} />
                      </IconButton>
                    </>
                  ),
                },
              ]}
            />
          </ProjectPaper>
        </React.Fragment>
      ) : null}
    </ProjectPageContainer>
  );
}

export default SearchSupplierSellItemTemplatePage;
