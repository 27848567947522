import { cancelPile, getPileDetailById, getPilePDF } from '../ProductionServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { pile_pdf_prefix } from '../../utilities/Strings/Prefix';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { useLoading } from '../../components/loading';

export const usePile = (id, isTemp) => {
  const queryClient = useQueryClient();
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const { data } = useQuery({
    queryKey: ['pile', id, isTemp],
    queryFn: () =>
      getPileDetailById({
        PileId: id,
        IsTemp: isTemp,
      }),
    initialData: null,
  });

  const printPilePDFMutation = useMutation({
    mutationFn: getPilePDF,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (response) => {
      await PrintMultiplePDFMakeDocument([response], `${pile_pdf_prefix}_${id}.pdf`);
      await hideLoadingScreen();
      await OpenSuccessNotification();
    },
    onError: async () => {
      await hideLoadingScreen();
      await OpenErrorNotification();
    },
  });

  const cancelPileMutation = useMutation({
    mutationFn: cancelPile,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async () => {
      await hideLoadingScreen();
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['pile', id, isTemp]);
    },
    onError: async () => {
      await hideLoadingScreen();
      await OpenErrorNotification();
    },
  });

  return {
    pile: data,
    print: printPilePDFMutation,
    cancel: cancelPileMutation
  };
};
