import React from 'react';
import PropTypes from 'prop-types';
import {Card, Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

OverallDetailGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        primary_text: PropTypes.string.isRequired,
        secondary_text: PropTypes.string.isRequired,
        primary_text_color: PropTypes.string,
    }),)
};

function OverallDetailGrid({data}) {

    return (
        <Card sx={{height: '100%'}}>
            <Stack sx={{mt: 4, mb: 4}} direction="row"
                   divider={<Divider orientation="vertical" flexItem/>}>
                {
                    data.map((gridData, index) => {
                        return <Stack key={index} width={1} textAlign="center">
                            <Typography variant="h4" color={gridData.primary_text_color}>
                                {gridData.primary_text}
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                {gridData.secondary_text}
                            </Typography>
                        </Stack>
                    })
                }
            </Stack>
        </Card>
    );
}

export default OverallDetailGrid;
