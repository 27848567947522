import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../locales';
import { Box, Button, IconButton } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import GeneralTable from '../customs/GeneralTable';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from '../minimal/hook-form';
import { useAdditionalCost } from '../../requests/query/DropdownData';

CreateProductOrderAdditionalCostBillForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreateProductOrderAdditionalCostBillForm({ onFormSubmit }) {
  const { translate } = useLocales();
  const { additionalCosts } = useAdditionalCost();
  const additionalCostBillSchema = Yup.object().shape({
    AdditionalCost: Yup.array()
      .of(
        Yup.object().shape({
          Value: Yup.number()
            .min(1, translate('error.invalid_input'))
            .required(translate('error.invalid_input')),
          AdditionalCostDetail: Yup.object()
            .shape({
              Id: Yup.number().min(1, translate('error.invalid_input')),
              Name: Yup.string().required(translate('error.invalid_input')),
              AdditionalCostTypeId: Yup.number().min(1, translate('error.invalid_input')),
            })
            .required(translate('error.invalid_input')),
        })
      )
      .min(1, 'error.invalid_input'),
  });

  const methods = useForm({
    resolver: yupResolver(additionalCostBillSchema),
  });

  const { handleSubmit, control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'AdditionalCost',
  });

  const onSubmit = (values) => {
    onFormSubmit(values);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 2 }}>
        <Button
          variant="contained"
          color={'primary'}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          onClick={() => append({ Value: 1, AdditionalCostDetail: null })}
        >
          {translate('button.create_additional_cost')}
        </Button>
      </Box>
      <GeneralTable
        tableHeaders={[
          { align: 'left', title: '#' },
          { align: 'left', title: 'title.name' },
          { align: 'left', title: 'title.value' },
          { align: 'left', title: '' },
        ]}
        tableData={fields.map((item, index) => {
          return [
            index + 1,
            <RHFAutoCompleteShort
              name={`AdditionalCost[${index}].AdditionalCostDetail`}
              label={translate('sales.additional_cost')}
              data={additionalCosts}
            />,
            <RHFTextField name={`AdditionalCost[${index}].Value`} type={'text'} />,
            <IconButton color={'error'} onClick={() => remove(index)}>
              <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
            </IconButton>,
          ];
        })}
      />
      <Button
        variant={'contained'}
        color={'primary'}
        disabled={fields.length === 0}
        fullWidth
        type={'submit'}
      >
        {translate('button.create')}
      </Button>
    </FormProvider>
  );
}

export default CreateProductOrderAdditionalCostBillForm;
