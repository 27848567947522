import {createSlice} from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        uid: null,
        employeeCode: null,
        name: null,
        employeeId: null,
        department: null,
        userTypeId: null,
        isTemp: true,
        userDetail:null,
    },
    reducers: {
        saveLoginData: (state, action) => {
            state.uid = action.payload.Id;
            state.employeeCode = action.payload.EmployeeCode;
            state.name = action.payload.Name;
            state.employeeId = action.payload.EmployeeId;
            state.userTypeId = action.payload.UserTypeId;
            state.department = action.payload.Department;
            state.isTemp = false;
        },
        createTempLoginData: (state, action) => {
            state.uid = null;
            state.employeeCode = action.payload.EmployeeCode;
            state.name = action.payload.Name;
            state.employeeId = action.payload.EmployeeId;
            state.userTypeId = null;
            state.department = null;
            state.isTemp = true;
        },
        terminateLoginData: (state) => {
            state.uid = null;
            state.employeeCode = null;
            state.name = null;
            state.employeeId = null;
            state.department = null;
            state.userTypeId = null;
            state.isTemp = true;
        },
    },
})

// Action creators are generated for each case reducer function
export const {saveLoginData,createTempLoginData,terminateLoginData} = userSlice.actions
export const selectUserId = (state) => state.user.uid;
export const selectName = (state) => state.user.name;
export const selectDepartment = (state) => state.user.department;
export const selectUserTypeId = (state) => state.user.userTypeId;
export const selectEmployeeId = (state) => state.user.employeeId;
export const selectIsTempEmployee = (state) => state.user.isTemp;
export const selectEmployeeCode = (state) => state.user.employeeCode;
export const selectUser = (state) => state.user.userDetail;
export default userSlice.reducer
