import React from 'react';
import { useLocales } from 'locales';
import { ProjectPageContainer } from 'components/container/ProjectPageContainer';
import { useParams } from 'react-router-dom';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import Iconify from 'components/minimal/iconify';
import useTabs from 'hooks/useTabs';
import CustomerDetail from 'components/details/customer/CustomerDetail';
import CustomerProductOrderDetail from 'components/details/customer/CustomerProductOrderDetail';
import CustomerFinanceDetail from 'components/details/customer/CustomerFinanceDetail';
import CustomerPricingDetail from 'components/details/customer/CustomerPricingDetail';
import CustomerProductOrderQuotationDetail from 'components/details/customer/CustomerProductOrderQuotationDetail';
import RoleBasedGuard from 'auth/guard/RoleBaseGuard';
import {
  AccountingUserTypeId,
  AdminUserTypeId,
  FinanceUserTypeId,
  ManagementUserTypeId,
  SalesUserTypeId,
} from 'utilities/Constants';
import { paths } from 'routes/paths';

function CustomerDetailPage() {
  const { translate } = useLocales();
  const { id } = useParams();
  const { currentTab, onChangeTab } = useTabs('detail');

  const INFORMATION_TABS = [
    {
      value: 'detail',
      title: translate('title.detail'),
      icon: <Iconify icon={'eva:info-outline'} width={20} height={20} />,
      component: (
        <Stack spacing={2}>
          <CustomerDetail customerId={id} />
          <CustomerProductOrderDetail customerId={id} />
        </Stack>
      ),
    },
    {
      value: 'finance',
      title: translate('title.finance'),
      icon: <Iconify icon={'ic:baseline-attach-money'} width={20} height={20} />,
      component: (
        <RoleBasedGuard
          roles={[
            AdminUserTypeId,
            SalesUserTypeId,
            FinanceUserTypeId,
            AccountingUserTypeId,
            ManagementUserTypeId,
          ]}
        >
          <CustomerFinanceDetail customerId={id} />
        </RoleBasedGuard>
      ),
    },
    {
      value: 'pricing',
      title: translate('title.pricing'),
      icon: <Iconify icon={'material-symbols:sell-outline'} width={20} height={20} />,
      component: (
        <RoleBasedGuard
          roles={[
            AdminUserTypeId,
            SalesUserTypeId,
            FinanceUserTypeId,
            AccountingUserTypeId,
            ManagementUserTypeId,
          ]}
        >
          <CustomerPricingDetail customerId={id} />
        </RoleBasedGuard>
      ),
    },
    {
      value: 'product_order_quotation',
      title: translate('sales.customer_pricing'),
      icon: <Iconify icon={'material-symbols:sell-outline'} width={20} height={20} />,
      component: (
        <RoleBasedGuard
          roles={[
            AdminUserTypeId,
            SalesUserTypeId,
            FinanceUserTypeId,
            AccountingUserTypeId,
            ManagementUserTypeId,
          ]}
        >
          <CustomerProductOrderQuotationDetail customerId={id} />
        </RoleBasedGuard>
      ),
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('sales.customer_detail')}
      breadcrumbs={[
        { name: translate('sales.title') },
        { name: translate('sales.customer_list'), href: paths.sales.customer_list },
        { name: `${translate('sales.customer_detail')} : ${id}` },
      ]}
    >
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
}

export default CustomerDetailPage;
