import { AdminUserTypeId } from '../../utilities/Constants';
import CreateItAssetPage from '../../components/pages/ITModule/CreateITAssetPage';
import CreateItAssetCategoryPage from '../../components/pages/ITModule/CreateITAssetCategoryPage';
import CreateItJobPage from '../../components/pages/ITModule/CreateItJobPage';
import ItJobDetailPage from '../../components/pages/ITModule/ItJobDetailPage';
import ItJobListPage from '../../components/pages/ITModule/ItJobListPage';
import { paths } from 'routes/paths';
import CreateUserPage from '../../components/pages/ITModule/CreateUserPage';
import ChangeUserPasswordPage from '../../components/pages/ITModule/ChangeUserPasswordPage';

export const itModuleRouteDetails = [
  {
    path: paths.it.create_it_asset,
    component: <CreateItAssetPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId],
  },
  {
    path: paths.it.create_it_asset_category,
    component: <CreateItAssetCategoryPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId],
  },
  {
    path: paths.it.create_it_job,
    component: <CreateItJobPage />,
    isPrivate: false,
  },
  {
    path: paths.it.it_job_list,
    component: <ItJobListPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId],
  },
  {
    path: paths.it.it_job_detail(':id'),
    component: <ItJobDetailPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId],
  },
  {
    path: paths.it.create_user,
    component: <CreateUserPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId],
  },
  {
    path: paths.it.change_user_password,
    component: <ChangeUserPasswordPage />,
    isPrivate: true,
    allowUserTypeId: [AdminUserTypeId],
  },
];
