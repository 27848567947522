import React from 'react';
import PropTypes from 'prop-types';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import { RouterLink } from '../../../routes/components';
import { useLocales } from '../../../locales';
import { fDateTimeText } from '../../../utilities/formatTime';
import { Box, Button, IconButton } from '@mui/material';
import Iconify from '../../minimal/iconify';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import { allowToEditItemTemplateAmountUserTypeId } from '../../../utilities/Constants';
import { OPEN_ICON } from '../../../config-global';
import { paths } from '../../../routes/paths';

ItemMasterContainerDetail.propTypes = {
  itemContainerDetail: PropTypes.array.isRequired,
  itemMasterUnit: PropTypes.string.isRequired,
  itemMasterId: PropTypes.number.isRequired,
};

function ItemMasterContainerDetail({ itemContainerDetail, itemMasterUnit, itemMasterId }) {
  const { translate } = useLocales();

  return (
    <ProjectPaper>
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mb: 3 }}>
        <RoleBasedGuard roles={allowToEditItemTemplateAmountUserTypeId}>
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            component={RouterLink}
            color={'primary'}
            to={paths.warehouse.create_container + `?itemMasterId=${itemMasterId}`}
          >
            {translate('button.create_item_container')}
          </Button>
        </RoleBasedGuard>
      </Box>
      <ReactTable
        data={itemContainerDetail.map((container) => {
          return {
            id: container.Id,
            name: container.Name,
            container_size: container.ItemMasterSize,
            sku: container.SKU,
            amount: `${container.ItemLots.reduce((totalLot, itemLot) => {
              return totalLot + itemLot.RemainItem;
            }, 0)} ${container.ItemUnit.Name}`,
            defect: `${container.DefectAmount} ${container.ItemUnit.Name}`,
            master_amount: `${container.ItemLots.reduce((totalLot, itemLot) => {
              return totalLot + itemLot.MasterAmount;
            }, 0)} ${itemMasterUnit}`,
            create_at: container.createdAt,
            actions: container.Id,
          };
        })}
        header={[
          {
            header: translate('title.container_id'),
            accessorKey: 'id',
            size: 50,
          },
          {
            header: translate('title.name'),
            accessorKey: 'name',
          },
          {
            header: translate('warehouse.container_size_title'),
            accessorKey: 'container_size',
            size: 70,
          },
          {
            header: translate('title.sku'),
            accessorKey: 'sku',
          },
          {
            header: translate('title.amount'),
            accessorKey: 'amount',
            size: 50,
          },
          {
            header: translate('title.amount_defect'),
            accessorKey: 'defect',
            size: 50,
          },
          {
            header: translate('warehouse.master_amount'),
            accessorKey: 'master_amount',
            size: 50,
          },
          {
            header: translate('title.create_at'),
            accessorKey: 'create_at',
            cell: (info) => fDateTimeText(info.getValue()),
          },
          {
            header: translate('Actions'),
            accessorKey: 'actions',
            cell: (info) => (
              <IconButton
                component={RouterLink}
                to={paths.warehouse.item_container_detail(info.getValue())}
              >
                <Iconify icon={OPEN_ICON} />
              </IconButton>
            ),
            size: 50,
          },
        ]}
      />
    </ProjectPaper>
  );
}

export default ItemMasterContainerDetail;
