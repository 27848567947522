import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../locales';
import ProjectPaper from '../customs/ProjectPaper';
import FormProvider, { RHFTextField } from '../minimal/hook-form';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

ReworkOrderDetailForm.propTypes = {
  onCompleteForm: PropTypes.func.isRequired,
  detail: PropTypes.string.isRequired,
};

function ReworkOrderDetailForm({ onCompleteForm, detail }) {
  const { translate } = useLocales();

  const reworkOrderSchema = Yup.object().shape({
    Detail: Yup.string().required(translate('error.detail_require')),
  });

  const methods = useForm({
    resolver: yupResolver(reworkOrderSchema),
    defaultValues: {
      Detail: detail,
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    onCompleteForm(values.Detail);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ProjectPaper>
        <RHFTextField name="Detail" label={translate('title.detail')} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            m: 3,
          }}
        >
          <Button color={'primary'} variant={'contained'} type={'submit'}>
            {translate('button.next')}
          </Button>
        </Box>
      </ProjectPaper>
    </FormProvider>
  );
}

export default ReworkOrderDetailForm;
