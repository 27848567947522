import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../locales';
import ProjectPaper from '../customs/ProjectPaper';
import { Button, Stack } from '@mui/material';
import FormProvider, { RHFAutoCompleteShort, RHFTextField, RHFUpload } from '../minimal/hook-form';
import { getCompressImage } from '../../utilities/Helper/DataHelper';
import {
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../utilities/Helper/NotificationHelper';
import { useItemTemplateCreateData } from '../../requests/query/DropdownData';
import { useItemTemplateDetail } from '../../requests/query/useItemTemplateDetail';

ItemTemplateDetailForm.propTypes = {
  itemTemplateDetail: PropTypes.object,
};

function ItemTemplateDetailForm({ itemTemplateDetail }) {
  const { translate } = useLocales();
  const { editDetail, create, uploadImage } = useItemTemplateDetail(itemTemplateDetail !== undefined ? itemTemplateDetail.Id : null);
  const {
    itemTypeOptions,
    itemUnitOptions,
    itemCategoryOptions,
    inventoryStoreOptions,
    allInventoryShelfOptions,
  } = useItemTemplateCreateData();

  const [inventoryShelfOptions, setInventoryShelfOptions] = useState([]);

  const createItemTemplateSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    Detail: Yup.string().required(translate('error.detail_require')),
    SafetyAmount: Yup.number()
      .min(0, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    ItemTemplateUnit: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    ItemTemplateType: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    ItemTemplateCategory: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    InventoryStore: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    InventoryShelf: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const defaultValue =
    itemTemplateDetail !== undefined && itemTemplateDetail !== null
      ? {
          Name: itemTemplateDetail.Name,
          Detail: itemTemplateDetail.Detail,
          SafetyAmount: itemTemplateDetail.SafetyAmount,
          ItemTemplateUnit: itemTemplateDetail.ItemTemplateUnit,
          ItemTemplateType: itemTemplateDetail.ItemTemplateType,
          ItemTemplateCategory: itemTemplateDetail.ItemTemplateCategory,
          InventoryStore: itemTemplateDetail.InventoryStore,
          InventoryShelf: itemTemplateDetail.InventoryShelf,
        }
      : {
          Name: '',
          Detail: '',
          SafetyAmount: 0,
        };

  const methods = useForm({
    resolver: yupResolver(createItemTemplateSchema),
    defaultValues: defaultValue,
  });

  const { control, setValue, handleSubmit } = methods;

  const selectInventoryStore = useWatch({
    control: control,
    name: 'InventoryStore',
    defaultValue: null,
  });

  useEffect(() => {
    if (selectInventoryStore !== null) {
      setInventoryShelfOptions(
        allInventoryShelfOptions.filter(
          (shelf) => shelf.InventoryStoreId === selectInventoryStore.Id
        )
      );
    }
  }, [allInventoryShelfOptions, selectInventoryStore]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setValue(
          'ItemImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onSubmit = async (values) => {
    if (values.InventoryShelf.InventoryStoreId === values.InventoryStore.Id) {
      let id;
      if (itemTemplateDetail !== undefined) {
        await editDetail.mutate({
          ItemTemplateId: itemTemplateDetail.Id,
          Name: values.Name,
          Detail: values.Detail,
          SafetyAmount: values.SafetyAmount,
          ItemTemplateTypeId: values.ItemTemplateType.Id,
          ItemTemplateUnitId: values.ItemTemplateUnit.Id,
          ItemTemplateCategoryId: values.ItemTemplateCategory.Id,
          InventoryStoreId: values.InventoryStore.Id,
          InventoryShelfId: values.InventoryShelf.Id,
        });
        id = itemTemplateDetail.Id;
      } else {
        const { mutateAsync } = create;
        const createItem = await mutateAsync({
          Name: values.Name,
          Detail: values.Detail,
          SafetyAmount: values.SafetyAmount,
          ItemTemplateTypeId: values.ItemTemplateType.Id,
          ItemTemplateUnitId: values.ItemTemplateUnit.Id,
          ItemTemplateCategoryId: values.ItemTemplateCategory.Id,
          InventoryStoreId: values.InventoryStore.Id,
          InventoryShelfId: values.InventoryShelf.Id,
        });
        id = createItem.Id;
      }
      if (values.ItemImage !== undefined && values.ItemImage !== null) {
        const compressedFile = await getCompressImage(values.ItemImage);
        await uploadImage.mutateAsync({
          Id: id,
          Image: compressedFile,
        });
        await OpenNotificationThenReloadPage(
          itemTemplateDetail !== undefined
            ? `${translate('notification.success_edit_item_id_title')}:${itemTemplateDetail.Id}`
            : `${translate('notification.success_create_item_id_title')}:${id}`
        );
      } else {
        await OpenNotificationThenReloadPage(
          itemTemplateDetail !== undefined
            ? `${translate('notification.success_edit_item_id_title')}:${itemTemplateDetail.Id}`
            : `${translate('notification.success_create_item_id_title')}:${id}`
        );
      }
    } else {
      await OpenErrorNotification(translate('error.invalid_shelf'));
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <ProjectPaper>
        <Stack spacing={3}>
          <RHFUpload name="ItemImage" accept="image/*" maxSize={3145728} onDrop={handleDrop} />
          <RHFTextField name={'Name'} label={translate('title.name')} />
          <RHFTextField name={'Detail'} label={translate('title.detail')} />
          <RHFTextField
            name={'SafetyAmount'}
            type={'number'}
            label={translate('title.safety_amount')}
          />
          <RHFAutoCompleteShort
            name={'ItemTemplateUnit'}
            label={translate('unit.title')}
            data={itemUnitOptions}
          />
          <RHFAutoCompleteShort
            name={'ItemTemplateType'}
            label={translate('title.type')}
            data={itemTypeOptions}
          />
          <RHFAutoCompleteShort
            name={'ItemTemplateCategory'}
            label={translate('title.category')}
            data={itemCategoryOptions}
          />
          <RHFAutoCompleteShort
            name={'InventoryStore'}
            label={translate('warehouse.store')}
            data={inventoryStoreOptions}
          />
          <RHFAutoCompleteShort
            name={'InventoryShelf'}
            disabled={selectInventoryStore === null}
            label={translate('warehouse.shelf')}
            data={inventoryShelfOptions}
          />
          <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
            {translate('button.create')}
          </Button>
        </Stack>
      </ProjectPaper>
    </FormProvider>
  );
}

export default ItemTemplateDetailForm;
