import React, { useEffect, useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useForm, useWatch } from 'react-hook-form';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ProjectPaper from '../../customs/ProjectPaper';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import { Button, Stack } from '@mui/material';
import ItemMasterDetailForCreateLot from '../../details/warehouse/ItemMasterDetailForCreateLot';
import { fDateTimeText } from '../../../utilities/formatTime';
import { getItemMasterDetailById, returnFilm } from '../../../requests/WarehouseServerHandler';
import SearchItemMasterForm from '../../forms/SearchItemMasterForm';
import { useMutation } from '@tanstack/react-query';

function ReturnFilmPage() {
  const { translate } = useLocales();
  const [itemMaster, setItemMaster] = useState(null);
  const [itemContainerOptions, setItemContainerOptions] = useState([]);
  const [itemLotOptions, setItemLotOptions] = useState([]);

  const searchItemMasterMutation = useMutation({
    mutationFn: getItemMasterDetailById,
    onSuccess: (data) => {
      setItemMaster(data);
      setItemContainerOptions(
        data.ItemContainer.map((container) => {
          return {
            Id: container.Id,
            Name: `${container.Name} (${container.ItemMasterSize} ${data.ItemUnit.Name} ต่อ ${container.ItemUnit.Name})`,
          };
        })
      );
    },
  });
  const returnFilmMutation = useMutation({
    mutationFn: returnFilm,
    onSuccess: async () => {
      await OpenNotificationThenReloadPage();
    },
  });

  const returnFilmSchema = Yup.object().shape({
    Amount: Yup.number()
      .min(1, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    Value: Yup.number()
      .min(0, translate('error.error_invalid_amount'))
      .required(translate('error.error_invalid_amount')),
    ItemContainer: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
    ItemLot: Yup.object()
      .shape({
        Id: Yup.number().min(1, translate('error.invalid_input')),
        Name: Yup.string().required(translate('error.invalid_input')),
      })
      .required(translate('error.invalid_input')),
  });

  const methods = useForm({
    resolver: yupResolver(returnFilmSchema),
  });

  const { control, handleSubmit } = methods;

  const containerSelect = useWatch({
    control,
    name: 'ItemContainer',
    defaultValue: null,
  });

  const onSearchItemMaster = async (itemMasterId) => {
    await searchItemMasterMutation.mutateAsync(itemMasterId);
  };
  const onCreateReturnFilm = async (data) => {
    await returnFilmMutation.mutateAsync({
      ItemContainerId: data.ItemContainer.Id,
      ItemLotId: data.ItemLot.Id,
      Amount: data.Amount,
      Value: data.Value,
    });
  };

  useEffect(() => {
    if (containerSelect !== null) {
      const container = itemMaster.ItemContainer.find(
        (container) => container.Id === containerSelect.Id
      );
      setItemLotOptions(
        container.ItemLots.map((lot) => {
          return {
            Id: lot.Id,
            Name: fDateTimeText(lot.createdAt),
          };
        })
      );
    } else {
      setItemLotOptions([]);
    }
  }, [containerSelect, itemMaster?.ItemContainer]);

  return (
    <ProjectPageContainer
      menu_title={translate('warehouse.create_return_film_lot_title')}
      breadcrumbs={[
        { name: translate('warehouse.title') },
        { name: translate('warehouse.create_return_film_lot_title') },
      ]}
    >
      {itemMaster !== null ? (
        <>
          <ItemMasterDetailForCreateLot itemMaster={itemMaster} />
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(onCreateReturnFilm)}>
              <Stack spacing={3}>
                <RHFAutoCompleteShort
                  name={'ItemContainer'}
                  label={translate('warehouse.item_container_detail')}
                  data={itemContainerOptions}
                />
                <RHFAutoCompleteShort
                  name={'ItemLot'}
                  label={translate('warehouse.lot_detail')}
                  data={itemLotOptions}
                  disabled={itemLotOptions.length === 0}
                />
                <RHFTextField name={'Amount'} label={translate('title.amount')} />
                <RHFTextField name={'Value'} label={translate('title.value')} />
                <Button color={'primary'} variant={'contained'} fullWidth type="submit">
                  {translate('button.create')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </>
      ) : (
        <SearchItemMasterForm onSearchCallBack={onSearchItemMaster} />
      )}
    </ProjectPageContainer>
  );
}

export default ReturnFilmPage;
