import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { Grid, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {
  OpenNotificationThenCallback,
  OpenTranslateWarningConfirmNotification,
} from '../../../utilities/Helper/NotificationHelper';
import {
  adjustPOPartsCart,
  deleteItemInPOPartsCart,
  deleteSupplierPOParts,
  selectPurchaseOrderItemPartsCart,
} from '../../../redux/reducers/procurement';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { createPartPO } from '../../../requests/ProductionServerHandler';
import { useLocales } from 'locales';
import { EditValueInPOForm } from '../../forms/EditValueInPOForm';
import GridDetailText from '../../customs/GridDetailText';
import ProjectPaper from '../../customs/ProjectPaper';
import GeneralTable from '../../customs/GeneralTable';
import { fCurrency } from '../../../utilities/formatNumber';
import Iconify from 'components/minimal/iconify';
import CreateProcurementPoForm from '../../forms/CreateProcurementPOForm';
import { usePartPurchaseOrder } from '../../../requests/query/usePartPurchaseOrder';
import { useMutation } from '@tanstack/react-query';
import { paths } from '../../../routes/paths';

function CheckoutSupplierPurchaseOrderPartsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const supplierCart = useSelector(selectPurchaseOrderItemPartsCart).find(
    (supplier) => supplier.SupplierDetail.Id === parseInt(id)
  );
  const [isEditValue, setIsEditValue] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const { printPO } = usePartPurchaseOrder(null);
  const createPOMutation = useMutation({
    mutationFn: createPartPO,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(
        translate('title.complete'),
        `${translate('notification.success_create_material_purchase_order_id_title')}${
          response.Id
        }`,
        async () => {
          await printPO.mutateAsync(response.Id);
          await dispatch(deleteSupplierPOParts(supplierCart.SupplierDetail));
          navigate(paths.procurement.parts_po_detail(response.Id));
        }
      );
    },
  });

  const toggleEditData = (item) => {
    setEditItem(item);
    setIsEditValue(!isEditValue);
  };
  const onSaveEditData = (values) => {
    dispatch(adjustPOPartsCart(values));
    setIsEditValue(!isEditValue);
  };
  const createPO = async (values) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_create_po',
      async () => {
        await createPOMutation.mutateAsync({
          Detail: values.Detail,
          DeliveredAt: values.DeliveryAt,
          Discount: values.Discount,
          DeliveryPrice: values.DeliveryPrice,
          ItemInCart: supplierCart.ItemInCart,
          ReferenceId: values.ReferenceId,
          SupplierId: supplierCart.SupplierDetail.Id,
          IsVat: values.IsVat,
        });
      }
    );
  };
  if (supplierCart === undefined || supplierCart === null) {
    return <Navigate to={paths.procurement.create_parts_po} />;
  }

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.create_po_parts_title')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.check_out_po_title'),
          href: paths.procurement.check_out_part_po,
        },
        {
          name: translate('procurement.check_out_supplier_po_title'),
        },
        { name: translate('procurement.create_po_parts_title') },
      ]}
    >
      {isEditValue ? (
        <EditValueInPOForm
          itemToEdit={editItem}
          onSaveEditDetailCallBack={onSaveEditData}
          supplier={supplierCart.SupplierDetail}
        />
      ) : (
        <Stack spacing={3}>
          <ProjectPaper>
            <Grid container spacing={3}>
              <GridDetailText
                title={translate('procurement.supplier')}
                value={supplierCart.SupplierDetail.Name}
              />
            </Grid>
          </ProjectPaper>
          <ProjectPaper>
            <GeneralTable
              tableHeaders={[
                { align: 'left', title: 'title.name' },
                { align: 'left', title: 'title.detail' },
                { align: 'left', title: 'title.amount' },
                { align: 'left', title: 'unit.title' },
                { align: 'left', title: 'procurement.price_per_unit' },
                { align: 'left', title: 'procurement.total_price_title' },
                { align: 'left', title: '' },
              ]}
              tableData={supplierCart.ItemInCart.map((item) => {
                return [
                  item.Name,
                  item.Detail !== undefined && item.Detail !== null && item.Detail.trim() !== ''
                    ? item.Detail
                    : 'N/A',
                  item.Amount,
                  item.Unit,
                  fCurrency(item.Price),
                  fCurrency(item.Price * item.Amount),
                  [
                    <IconButton
                      color={'error'}
                      onClick={() =>
                        dispatch(
                          deleteItemInPOPartsCart({
                            Item: item,
                            Supplier: supplierCart.SupplierDetail,
                          })
                        )
                      }
                    >
                      <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                    </IconButton>,
                    <IconButton onClick={() => toggleEditData(item)}>
                      <Iconify icon={'ic:baseline-edit'} width={20} height={20} />
                    </IconButton>,
                  ],
                ];
              })}
            />
          </ProjectPaper>
          <CreateProcurementPoForm handleCreatePO={createPO} />
        </Stack>
      )}
    </ProjectPageContainer>
  );
}

export default CheckoutSupplierPurchaseOrderPartsPage;
