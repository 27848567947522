import React from 'react';
import { DataScanner } from './index';
import { useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { getPileDetailById } from '../../requests/ProductionServerHandler';
import {
  OpenErrorNotification,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import SearchPileForm from '../forms/SearchPileForm';
import { useLocales } from '../../locales';
import { Box, Stack, Typography } from '@mui/material';
import { scanPileIdBurma } from '../../locales/BurmaStrings';
import useAuth from '../../auth/hooks/useAuth';

PileScanner.propTypes = {
  onSearchCallBack: PropTypes.func.isRequired,
  isPreFetchData: PropTypes.bool,
  isShowTooltips: PropTypes.bool,
  disableScan: PropTypes.bool,
  disableInput: PropTypes.bool,
};

function PileScanner({
  onSearchCallBack,
  isPreFetchData,
  isShowTooltips,
  disableInput,
  disableScan,
}) {
  const { translate } = useLocales();
  const { user } = useAuth();
  const searchPileMutation = useMutation({
    mutationFn: getPileDetailById,
    onSuccess: async (pile) => {
      await OpenSuccessNotification();
      onSearchCallBack(pile);
    },
    onError: async (error) => {
      await OpenErrorNotification(error);
    },
  });

  const handleScanCallback = async (data) => {
    try {
      if (data.includes('PileId')) {
        const dataJson = JSON.parse(data);
        if (dataJson.PileId !== undefined) {
          await handleSearchPile(dataJson.PileId);
        }
      } else {
        await handleSearchPile(data);
      }
    } catch (e) {
      await OpenErrorNotification(translate('error.pile_not_found'));
    }
  };
  const handleSearchPile = async (id) => {
    if (isPreFetchData) {
      searchPileMutation.mutate({
        PileId: id,
        IsTemp: user === null,
      });
    } else {
      await OpenSuccessNotification();
      onSearchCallBack(id);
    }
  };
  const searchPileForm = <SearchPileForm onSubmitCallBack={handleSearchPile} />;
  return (
    <Stack spacing={2}>
      {isShowTooltips && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt={'scan'}
              style={{ height: '200px', width: '300px' }}
              src={'/assets/illustrations/Scan_Image.jpg'}
              data-holder-rendered="true"
            />
          </Box>
          <Typography variant={'h3'}>{translate('placeholder.please_scan_pile_id')}</Typography>
          <Typography variant={'h3'}>{scanPileIdBurma}</Typography>
        </>
      )}
      <DataScanner
        disableInput={disableInput}
        disableScan={disableScan}
        onScanCallback={handleScanCallback}
        FormComponent={searchPileForm}
      />
    </Stack>
  );
}

export default PileScanner;
