export const delivery_bill_prefix = 'ใบส่งของ';
export const billing_note_prefix = 'ใบวางบิล';
export const additional_cost_bill_prefix = 'บิลค่าใช้จ่ายเพิ่มเติม';
export const item_qr_prefix = 'Item_QR_';
export const delivery_bill_report_prefix = 'ใบสรุปการส่งของ';
export const product_sell_report_prefix = 'ใบสรุปการขายProduct';
export const pile_pdf_prefix = 'ใบเสียบข้างกอง';
export const planning_pdf_prefix = 'ใบแผนงาน';
export const item_requisition_pdf_prefix = 'ใบหยิบของ';
export const return_item_pdf_prefix = 'ใบคืนของ';
export const pr_prefix = 'ใบขอซื้อเลขที่';
export const po_prefix = 'ใบ PO เลขที่';
export const spo_prefix = 'ใบจัดจ้าง เลขที่';
export const poq_prefix = 'ใบเสนอราคาคำสั่งลูกค้าเลขที่';
export const cash_delivery_bill_report_prefix = 'ใบสรุปการเก็บเงิน';
