import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from '@mui/material';
import ReactTable from '../../react-table/ReactTable';
import { fCurrency } from '../../../utilities/formatNumber';
import { fDateTimeText } from '../../../utilities/formatTime';
import { useLocales } from '../../../locales';
import { ManagementUserTypeId } from '../../../utilities/Constants';
import RoleBasedGuard from '../../../auth/guard/RoleBaseGuard';
import { getEmployeeFullName } from '../../../utilities/Helper/UtilitiesHelper';

ProductSellHistoryDetail.propTypes = {
  productDetail: PropTypes.object.isRequired,
};

function ProductSellHistoryDetail({ productDetail }) {
  const { translate } = useLocales();
  const getSellHistoryDetailData = () => {
    let returnData = [];
    if (productDetail !== null && productDetail.ProductSellHistories.length > 0) {
      returnData = productDetail.ProductSellHistories.map((history) => {
        return {
          name: history.Customer.Name,
          id: history.Id,
          price: history.SellPrice,
          discount: history.DiscountPercent,
          latest_sell_date: history.createdAt,
        };
      });
    }
    return returnData;
  };
  const generateSpecialDiscountData = () => {
    return productDetail.SpecialDiscountPriceHistories.map((history) => {
      return {
        customer: history.Customer.Name,
        product: history.Product.Name,
        price: history.Price,
        create: history.createdAt,
        end: history.EndDatedAt,
        createEmployee: getEmployeeFullName(history.CreateEmployee),
      };
    });
  };

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <ReactTable
            data={getSellHistoryDetailData()}
            header={[
              {
                header: translate('title.name'),
                accessorKey: 'name',
              },
              {
                header: translate('title.id'),
                accessorKey: 'id',
              },
              {
                header: translate('title.price_per_sheet'),
                accessorKey: 'price',
                cell: (info) => fCurrency(info.getValue()),
              },
              {
                header: `${translate('title.discount')} %`,
                accessorKey: 'discount',
              },
              {
                header: translate('sales.latest_sales_date'),
                accessorKey: 'latest_sell_date',
                cell: (info) => fDateTimeText(info.getValue()),
              },
              {
                header: '',
                accessorKey: 'actions',
              },
            ]}
          />
        </CardContent>
      </Card>
      <RoleBasedGuard roles={[ManagementUserTypeId]}>
        <Card sx={{ mb: 2 }}>
          <CardHeader title={translate('management.approve_special_price_history_title')} />
          <CardContent>
            <ReactTable
              data={generateSpecialDiscountData()}
              header={[
                {
                  header: translate('title.customer'),
                  accessorKey: 'customer',
                },
                {
                  header: translate('title.product_name'),
                  accessorKey: 'product',
                },
                {
                  header: translate('title.value'),
                  accessorKey: 'price',
                  cell: (info) => fCurrency(info.getValue()),
                },
                {
                  header: translate('title.create_at'),
                  accessorKey: 'create',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('title.complete_date'),
                  accessorKey: 'end',
                  cell: (info) => fDateTimeText(info.getValue()),
                },
                {
                  header: translate('title.create_by'),
                  accessorKey: 'createEmployee',
                },
                {
                  header: '',
                  accessorKey: 'actions',
                },
              ]}
            />
          </CardContent>
        </Card>
      </RoleBasedGuard>
    </>
  );
}

export default ProductSellHistoryDetail;
