// ----------------------------------------------------------------------

export function flattenArray(list, key = 'children') {
    let children = [];

    const flatten = list?.map((item) => {
        if (item[key] && item[key].length) {
            children = [...children, ...item[key]];
        }
        return item;
    });

    return flatten?.concat(children.length ? flattenArray(children, key) : children);
}
