import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenSuccessNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { DatePickerMachine } from '../../date-picker';
import { useMutation } from '@tanstack/react-query';
import { getMachinePerformanceExcel } from '../../../requests/ProductionServerHandler';
import { useLoading } from '../../loading';
import { saveAs } from 'file-saver';

function PrintMachineProductionReportPage() {
  const { hideLoadingScreen, showLoadingScreen } = useLoading();
  const printReportMutation = useMutation({
    mutationFn: getMachinePerformanceExcel,
    onMutate: async () => {
      await showLoadingScreen();
    },
    onSuccess: async (report) => {
      await hideLoadingScreen();
      saveAs(report, 'machine_report.xlsx');
      await OpenSuccessNotification();
    },
  });

  const onPrintExcel = async (fromDate, toDate, selectMachine) => {
    await printReportMutation.mutateAsync({
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
      machineId: selectMachine?.Id,
    });
  };
  const { translate } = useLocales();

  return (
    <ProjectPageContainer
      menu_title={translate('production.print_machine_production_report_title')}
      breadcrumbs={[
        { name: translate('production.title') },
        { name: translate('production.print_machine_production_report_title') },
      ]}
    >
      <DatePickerMachine
        actions={[
          {
            title: `${translate('production.print_machine_production_report_title')} (Excel)`,
            callBack: onPrintExcel,
          },
        ]}
      />
    </ProjectPageContainer>
  );
}

export default PrintMachineProductionReportPage;
