import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenTranslateWarningConfirmNotification } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import ProjectPaper from '../../customs/ProjectPaper';
import ReactTable from '../../react-table/ReactTable';
import Iconify from 'components/minimal/iconify';
import { IconButton, Typography } from '@mui/material';
import { useMachine } from '../../../requests/query/DropdownData';
import { useTheme } from '@mui/material/styles';
const MaintenanceMachineDashboardPage = () => {
  const { translate } = useLocales();
  const theme = useTheme();
  const { machines, breakMachine, resumeMachine } = useMachine(false, false);

  const onBreakMachine = async (id) => {
    await OpenTranslateWarningConfirmNotification(
      translate,
      'warning.confirm_break_machine',
      async () => {
        await breakMachine.mutateAsync(id);
      }
    );
  };

  const onResumeMachine = async (id) => {
    await resumeMachine.mutateAsync(id);
  };

  const getStatusText = (machineStatus) => {
    if (machineStatus.Id === 1) {
      return (
        <Typography variant={'body2'} color={theme.palette.success.main}>
          {`${machineStatus.Name}`}
        </Typography>
      );
    }

    if (machineStatus.Id === 2) {
      return (
        <Typography variant={'body2'} color={theme.palette.error.main}>
          {`${machineStatus.Name}`}
        </Typography>
      );
    }

    if (machineStatus.Id === 3) {
      return (
        <Typography variant={'body2'} color={theme.palette.warning.main}>
          {`${machineStatus.Name}`}
        </Typography>
      );
    }

    if (machineStatus.Id === 4 || machineStatus.Id === 5) {
      return <Typography variant={'body2'}>{`${machineStatus.Name}`}</Typography>;
    }
  };

  return (
    <ProjectPageContainer
      menu_title={`${translate('dashboard')} ${translate('title.machine')}`}
      breadcrumbs={[
        { name: translate('maintenance.title') },
        { name: `${translate('dashboard')} ${translate('title.machine')}` },
      ]}
    >
      <ProjectPaper>
        <ReactTable
          defaultPageSize={25}
          data={machines.map((machine) => {
            return {
              id: machine.Id,
              name: machine.Name,
              status: machine.MachineStatus,
              actions: machine,
            };
          })}
          header={[
            {
              header: translate('title.id'),
              accessorKey: 'id',
            },
            {
              header: translate('title.name'),
              accessorKey: 'name',
            },
            {
              header: translate('title.status'),
              accessorKey: 'status',
              cell: (info) => getStatusText(info.getValue()),
            },
            {
              header: '',
              accessorKey: 'actions',
              cell: (info) => (
                <>
                  {[2, 3].includes(info.getValue().MachineStatusId) ? (
                    <IconButton
                      color={'success'}
                      onClick={() => onResumeMachine(info.getValue().Id)}
                    >
                      <Iconify icon={'akar-icons:play'} width={20} height={20} />
                    </IconButton>
                  ) : (
                    <IconButton color={'error'} onClick={() => onBreakMachine(info.getValue().Id)}>
                      <Iconify icon={'ant-design:pause-outlined'} width={20} height={20} />
                    </IconButton>
                  )}
                </>
              ),
            },
          ]}
        />
      </ProjectPaper>
    </ProjectPageContainer>
  );
};

export default MaintenanceMachineDashboardPage;
