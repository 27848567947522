import React from 'react';
import { useSelector } from 'react-redux';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { selectPartsPurchaseCart } from '../../../redux/reducers/inventory';
import { RouterLink } from 'routes/components';
import { useLocales } from 'locales';
import useTabs from '../../../hooks/useTabs';
import Iconify from 'components/minimal/iconify';
import { Box, Button } from '@mui/material';
import PartsPurchaseStore from '../../details/general-warehouse/PartsPurchaseStore';
import { useResponsive } from '../../../hooks/useResponsive';
import { paths } from '../../../routes/paths';

const ProcurementPartStorePage = () => {
  const PartsPurchaseCarts = useSelector(selectPartsPurchaseCart);
  const { translate } = useLocales();
  const { currentTab, onChangeTab } = useTabs('parts');
  const isDesktop = useResponsive('up', 'lg');

  const INFORMATION_TABS = [
    {
      value: 'parts',
      title: translate('warehouse.part_item'),
      icon: <Iconify icon={'ant-design:setting-outlined'} width={20} height={20} />,
      component: <PartsPurchaseStore category_id={2} isMobile={!isDesktop} />,
    },
    {
      value: 'generals',
      title: translate('warehouse.general_item'),
      icon: <Iconify icon={'akar-icons:shipping-box-01'} width={20} height={20} />,
      component: <PartsPurchaseStore category_id={3} isMobile={!isDesktop} />,
    },
  ];

  return (
    <ProjectPageContainer
      menu_title={translate('procurement.parts_store')}
      breadcrumbs={[
        { name: translate('procurement.title') },
        {
          name: translate('procurement.request_buy_parts_title'),
          href: paths.procurement.create_parts_purchase_requisition,
        },
        { name: translate('procurement.parts_store') },
      ]}
      action={
        <Button
          variant="contained"
          startIcon={<Iconify icon={'ant-design:shopping-cart-outlined'} />}
          component={RouterLink}
          disabled={PartsPurchaseCarts.length === 0}
          to={paths.procurement.create_parts_purchase_requisition}
        >
          {`${translate('warehouse.order_item')} ${PartsPurchaseCarts.length} ${translate(
            'warehouse.items'
          )}`}
        </Button>
      }
    >
      <>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {INFORMATION_TABS.map((tab, index) => (
            <Tab disableRipple key={index} label={tab.title} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>
        <Box sx={{ mb: 3 }} />
        {INFORMATION_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && tab.component;
        })}
      </>
    </ProjectPageContainer>
  );
};

export default ProcurementPartStorePage;
