import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocales } from '../../../locales';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMachine } from '../../../requests/query/DropdownData';

MachineDropdown.propTypes = {
  onMachineChange: PropTypes.func,
  disable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  includeAllOptions: PropTypes.bool,
};

function MachineDropdown({ onMachineChange, disable, label, error, includeAllOptions }) {
  const { translate } = useLocales();

  const { machines } = useMachine(false, true);

  const customerOptions = useMemo(() => {
    if (machines) {
      if (includeAllOptions) {
        return [
          {
            Id: 0,
            Name: translate('title.all'),
          },
          ...machines.map((customer) => {
            return {
              Id: machines.Id,
              Name: customer.Name,
            };
          }),
        ];
      } else {
        return machines.map((customer) => {
          return {
            Id: customer.Id,
            Name: customer.Name,
          };
        });
      }
    }
    return [];
  }, [machines, includeAllOptions, translate]);

  const onOptionSelect = (option, value) => {
    if (option === '') {
      return true;
    }
    return option.Id === value.Id;
  };

  const getOptionLabel = (option) => {
    if (option === '') {
      return '';
    }
    return option.Name;
  };

  const handleDropdownValueChange = (event, value) => {
    if (value !== null) {
      if (onMachineChange !== undefined) {
        onMachineChange(value);
      }
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={customerOptions}
      isOptionEqualToValue={onOptionSelect}
      getOptionLabel={getOptionLabel}
      disabled={disable}
      onChange={handleDropdownValueChange}
      disablePortal
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          variant="outlined"
          label={label}
          error={error !== undefined}
          helperText={
            error !== undefined ? translate('error.error_please_select_one_option') : null
          }
        />
      )}
    />
  );
}

export default MachineDropdown;
