import React from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { OpenNotificationThenReloadPage } from '../../../utilities/Helper/NotificationHelper';
import { useLocales } from 'locales';
import { holdPileWithProblems } from '../../../requests/ProductionServerHandler';
import { useMutation } from '@tanstack/react-query';
import HoldProblemForm from '../../forms/HoldProblemForm';

function HoldPilePage() {
  const { translate } = useLocales();
  const holdPileMutation = useMutation({
    mutationFn: holdPileWithProblems,
    onSuccess: async (data, variables) => {
      await OpenNotificationThenReloadPage(
        `${translate('qa.hold_pile_title')} : ${translate('production.pile_id')} ${
          variables.PileId
        }`
      );
    },
  });

  const submitProblems = async (data) => {
    await holdPileMutation.mutateAsync(data);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('qa.hold_pile_title')}
      breadcrumbs={[{ name: translate('qa.title') }, { name: translate('qa.hold_pile_title') }]}
    >
      <HoldProblemForm onSubmitCallback={submitProblems} />
    </ProjectPageContainer>
  );
}

export default HoldPilePage;
