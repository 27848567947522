import React, { useState } from 'react';
import { ProjectPageContainer } from '../../container/ProjectPageContainer';
import { useLocales } from 'locales';
import GridDetailText from '../../customs/GridDetailText';
import { Button, Card, CardContent, CardHeader, Grid, IconButton, Stack } from '@mui/material';
import { getProductOrderPrice } from '../../../utilities/Helper/DataHelper';
import { fCurrency } from '../../../utilities/formatNumber';
import Iconify from 'components/minimal/iconify';
import FormProvider, { RHFAutoCompleteShort, RHFTextField } from 'components/minimal/hook-form';
import ProjectPaper from '../../customs/ProjectPaper';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { changePriceSchema } from '../../forms/FormSchema';
import {
  OpenConfirmNotification,
  OpenErrorNotification,
  OpenNotificationThenReloadPage,
} from '../../../utilities/Helper/NotificationHelper';
import { useMutation } from '@tanstack/react-query';
import { changeProductSellHistoryToBundlePrice } from '../../../requests/ProductionServerHandler';
import { ProductOrderScanner } from '../../barcode-reader';
import { paths } from '../../../routes/paths';

function EditProductOrderPriceToBundlePage() {
  const { translate } = useLocales();
  const [productOrder, setProductOrder] = useState(null);
  const mutation = useMutation((formData) =>
    changeProductSellHistoryToBundlePrice(formData.Id, formData.Price)
  );
  const handleFindProductOrder = (productOrderDetail) => {
    setProductOrder(productOrderDetail);
  };

  const methods = useForm({
    resolver: yupResolver(changePriceSchema(translate)),
  });

  const { handleSubmit, control } = methods;

  const selectProductSellHistoryWatch = useWatch({
    control,
    name: 'SelectProductSellHistory',
    defaultValue: null,
  });

  const newPriceWatch = useWatch({
    control,
    name: 'NewPrice',
    defaultValue: 0,
  });
  const onChangeProductSellPrice = async (values) => {
    await OpenConfirmNotification(
      translate('warning.confirm_change_price'),
      translate('button.confirm'),
      '',
      async () => {
        try {
          await mutation.mutateAsync({
            Id: values.SelectProductSellHistory.Id,
            Price: values.NewPrice,
          });
          await OpenNotificationThenReloadPage(translate('success.change_price'));
        } catch (error) {
          await OpenErrorNotification(error);
        }
      }
    );
  };
  const handleCalculateNewPrice = () => {
    const newPrice = newPriceWatch === null || isNaN(newPriceWatch) ? 0 : newPriceWatch;
    const productSellHistories = productOrder.ProductSellHistories.filter(
      (priceDetail) => priceDetail.Id !== selectProductSellHistoryWatch.Id
    );
    const allPriceDetail = [
      ...productSellHistories,
      {
        ...selectProductSellHistoryWatch,
        IsBundle: true,
        BundlePrice: parseFloat(newPrice),
      },
    ];
    const newValue = getProductOrderPrice(allPriceDetail, productOrder.Amount);
    const oldValue = getProductOrderPrice(productOrder.ProductSellHistories, productOrder.Amount);
    if (newPrice === 0) return fCurrency(0);

    return fCurrency(newValue - oldValue);
  };

  return (
    <ProjectPageContainer
      menu_title={translate('admin.edit_price_to_bundle_title')}
      breadcrumbs={[
        { name: translate('admin.title') },
        { name: translate('admin.edit_price_to_bundle_title') },
      ]}
    >
      {productOrder !== null ? (
        <Stack spacing={2}>
          <Card sx={{ mb: 2 }}>
            <CardHeader
              title={translate('title.detail')}
              action={
                <IconButton color={'error'} onClick={() => setProductOrder(null)}>
                  <Iconify icon={'ant-design:delete-outlined'} width={20} height={20} />
                </IconButton>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <GridDetailText title={translate('title.id')} value={productOrder.Id} />
                <GridDetailText
                  title={translate('title.name')}
                  value={productOrder.Name}
                  link={paths.production.product_order_detail(productOrder.Id)}
                />
                <GridDetailText
                  title={translate('title.customer')}
                  value={productOrder.Customer.Name}
                  link={paths.sales.customer_detail(productOrder.Customer.Id)}
                />
                <GridDetailText title={translate('title.amount')} value={productOrder.Amount} />
                <GridDetailText
                  title={translate('title.value')}
                  value={fCurrency(
                    getProductOrderPrice(productOrder.ProductSellHistories, productOrder.Amount)
                  )}
                />
              </Grid>
            </CardContent>
          </Card>
          {selectProductSellHistoryWatch !== null && (
            <Card sx={{ mb: 2 }}>
              <CardHeader title={translate('title.detail')} />
              <CardContent>
                <Grid container spacing={2}>
                  <GridDetailText
                    title={translate('title.price')}
                    value={
                      selectProductSellHistoryWatch.IsBundle
                        ? `${translate('title.bundle_price')} : ${fCurrency(
                            selectProductSellHistoryWatch.SellPrice
                          )}`
                        : fCurrency(selectProductSellHistoryWatch.SellPrice)
                    }
                  />
                  <GridDetailText
                    title={translate('title.change_value')}
                    value={handleCalculateNewPrice()}
                  />
                </Grid>
              </CardContent>
            </Card>
          )}
          <ProjectPaper>
            <FormProvider methods={methods} onSubmit={handleSubmit(onChangeProductSellPrice)}>
              <Stack spacing={3}>
                <RHFAutoCompleteShort
                  name={'SelectProductSellHistory'}
                  label={translate('title.process')}
                  data={productOrder.ProductSellHistories.map((priceDetail) => {
                    return {
                      Id: priceDetail.Id,
                      Name: priceDetail.Product.Name,
                      SellPrice: priceDetail.IsBundle
                        ? priceDetail.BundlePrice
                        : priceDetail.SellPrice,
                      IsBundle: priceDetail.IsBundle,
                    };
                  })}
                />
                <RHFTextField name={'NewPrice'} label={translate('title.price')} />
                <Button variant={'contained'} fullWidth type={'submit'} color={'primary'}>
                  {translate('button.save')}
                </Button>
              </Stack>
            </FormProvider>
          </ProjectPaper>
        </Stack>
      ) : (
        <ProductOrderScanner onSearchCallBack={handleFindProductOrder} isPreFetchData />
      )}
    </ProjectPageContainer>
  );
}

export default EditProductOrderPriceToBundlePage;
