import { createSlice } from '@reduxjs/toolkit'

export const procurementSlice = createSlice({
    name: 'procurement',
    initialState: {
        PurchaseOrderItemCart: [],
        PurchaseOrderItemTemplateCart: [],
    },
    reducers: {
        adjustPOCart: (state,action) => {
            let returnCart = state.PurchaseOrderItemCart;
            const addSupplier = action.payload.Supplier;
            const addItem = action.payload.Item;

            let newPurchaseOrderItemCart = [];

            let alreadyAdjust = false;
            newPurchaseOrderItemCart = returnCart.map((supplier) => {
                if(supplier.SupplierDetail.Id === addSupplier.Id)
                {
                    alreadyAdjust = true
                    let isFoundUpdate = false;
                    let newItemInCart = supplier.ItemInCart.map((item) => {
                        if (item.Id === addItem.Id) {
                            isFoundUpdate = true;
                            item.Amount = parseFloat(addItem.Amount);
                            item.Detail = addItem.Detail;
                            item.Price = parseFloat(addItem.Price);
                            return item
                        } else {
                            return item
                        }
                    });

                    if (!isFoundUpdate) {
                        newItemInCart.push({
                            Id: addItem.Id,
                            Name: addItem.Name,
                            Detail: addItem.Detail,
                            Unit: addItem.Unit,
                            Price: addItem.Price,
                            Amount: parseFloat(addItem.Amount),
                            IsTemp: addItem.IsTemp,
                            PRItemMasterId: addItem.PRItemMasterId
                        })
                    }
                    supplier.ItemInCart = newItemInCart
                    return supplier
                }else {
                    return supplier;
                }
            });

            if(!alreadyAdjust)
            {
                newPurchaseOrderItemCart.push({
                    SupplierDetail: addSupplier,
                    Detail:null,
                    ReferenceId:null,
                    Discount:0,
                    DeliveryPrice:0,
                    ItemInCart: [{
                        Id: addItem.Id,
                        Name: addItem.Name,
                        Detail: addItem.Detail,
                        Unit: addItem.Unit,
                        Price: addItem.Price,
                        Amount: addItem.Amount,
                        IsTemp: addItem.IsTemp,
                        PRItemMasterId: addItem.PRItemMasterId
                    }]
                })
            }
            state.PurchaseOrderItemCart = newPurchaseOrderItemCart
        },
        deleteItemInPOCart: (state,action) => {
            let adjustCart = state.PurchaseOrderItemCart;
            const removeSupplier = action.payload.Supplier;
            const removeItem = action.payload.Item;

            const supplier = state.PurchaseOrderItemCart.find((supplier) => supplier.SupplierDetail.Id === removeSupplier.Id);
            if(supplier !== undefined)
            {
                supplier.ItemInCart = supplier.ItemInCart.filter((cartItem) => cartItem.Id !== removeItem.Id);
                if(supplier.ItemInCart.length <= 0)
                {
                    adjustCart = adjustCart.filter((supplier) => supplier.SupplierDetail.Id !== removeSupplier.Id);
                }
            }
            state.PurchaseOrderItemCart = adjustCart
        },
        resetPOCart:(state,action) => {
            state.PurchaseOrderItemCart = []
        },
        editPODetail: (state,action) => {
            const editSupplier = action.payload.Supplier;
            const editDetail = action.payload.Detail;

            const toEditSupplier = state.PurchaseOrderItemCart.find((supplier) => supplier.SupplierDetail.Id === editSupplier.Id);
            toEditSupplier.Detail = editDetail;
        },
        editPORefId: (state,action) => {
            const editREFSupplier = action.payload.Supplier;
            const editREFId = action.payload.RefId;

            const toEditREFSupplier = state.PurchaseOrderItemCart.find((supplier) => supplier.SupplierDetail.Id === editREFSupplier.Id);
            toEditREFSupplier.ReferenceId = editREFId;
        },
        editPODiscount: (state,action) => {
            const editDiscountSupplier = action.payload.Supplier;
            const editDiscount = action.payload.Discount;

            const toEditDiscountSupplier = state.PurchaseOrderItemCart.find((supplier) => supplier.SupplierDetail.Id === editDiscountSupplier.Id);
            toEditDiscountSupplier.Discount = editDiscount;
        },
        editPODeliveryPrice: (state,action) => {
            const editDeliveryPriceSupplier = action.payload.Supplier;
            const editDeliveryPrice = action.payload.DeliveryPrice;

            const toEditDiscountSupplier = state.PurchaseOrderItemCart.find((supplier) => supplier.SupplierDetail.Id === editDeliveryPriceSupplier.Id);
            toEditDiscountSupplier.DeliveryPrice = editDeliveryPrice;
        },
        deleteSupplierPO: (state,action) => {
            state.PurchaseOrderItemCart =  state.PurchaseOrderItemCart.filter((supplier) => supplier.SupplierDetail.Id !== action.payload.Id)
        },
        adjustPOPartsCart: (state,action) => {
            let returnCart = state.PurchaseOrderItemTemplateCart;
            const addSupplier = action.payload.Supplier;
            const addItem = action.payload.Item;

            let newPurchaseOrderItemCart = [];

            let alreadyAdjust = false;
            newPurchaseOrderItemCart = returnCart.map((supplier) => {
                if(supplier.SupplierDetail.Id === addSupplier.Id)
                {
                    alreadyAdjust = true
                    let isFoundUpdate = false;
                    let newItemInCart = supplier.ItemInCart.map((item) => {
                        if (item.Id === addItem.Id) {
                            isFoundUpdate = true;
                            item.Amount = parseFloat(addItem.Amount);
                            item.Detail = addItem.Detail;
                            item.Price = parseFloat(addItem.Price);
                            return item
                        } else {
                            return item
                        }
                    });

                    if (!isFoundUpdate) {
                        newItemInCart.push({
                            Id: addItem.Id,
                            Name: addItem.Name,
                            Detail: addItem.Detail,
                            Unit: addItem.Unit,
                            Price: addItem.Price,
                            Amount: parseFloat(addItem.Amount),
                            IsTemp: addItem.IsTemp,
                            PRItemTemplateId: addItem.PRItemTemplateId
                        })
                    }
                    supplier.ItemInCart = newItemInCart
                    return supplier
                }else {
                    return supplier;
                }
            });

            if(!alreadyAdjust)
            {
                newPurchaseOrderItemCart.push({
                    SupplierDetail: addSupplier,
                    Detail:null,
                    ReferenceId:null,
                    Discount:0,
                    DeliveryPrice:0,
                    ItemInCart: [{
                        Id: addItem.Id,
                        Name: addItem.Name,
                        Detail: addItem.Detail,
                        Unit: addItem.Unit,
                        Price: addItem.Price,
                        Amount: addItem.Amount,
                        IsTemp: addItem.IsTemp,
                        PRItemTemplateId: addItem.PRItemTemplateId
                    }]
                })
            }
            state.PurchaseOrderItemTemplateCart = newPurchaseOrderItemCart
        },
        deleteItemInPOPartsCart: (state,action) => {
            let adjustCart = state.PurchaseOrderItemTemplateCart;
            const removeSupplier = action.payload.Supplier;
            const removeItem = action.payload.Item;

            const supplier = state.PurchaseOrderItemTemplateCart.find((supplier) => supplier.SupplierDetail.Id === removeSupplier.Id);
            if(supplier !== undefined)
            {
                supplier.ItemInCart = supplier.ItemInCart.filter((cartItem) => cartItem.Id !== removeItem.Id);
                if(supplier.ItemInCart.length <= 0)
                {
                    adjustCart = adjustCart.filter((supplier) => supplier.SupplierDetail.Id !== removeSupplier.Id);
                }
            }
            state.PurchaseOrderItemTemplateCart = adjustCart
        },
        resetPOPartsCart:(state,action) => {
            state.PurchaseOrderItemTemplateCart = []
        },
        editPOPartsDetail: (state,action) => {
            const editSupplier = action.payload.Supplier;
            const editDetail = action.payload.Detail;

            const toEditSupplier = state.PurchaseOrderItemTemplateCart.find((supplier) => supplier.SupplierDetail.Id === editSupplier.Id);
            toEditSupplier.Detail = editDetail;
        },
        editPOPartsRefId: (state,action) => {
            const editREFSupplier = action.payload.Supplier;
            const editREFId = action.payload.RefId;

            const toEditREFSupplier = state.PurchaseOrderItemTemplateCart.find((supplier) => supplier.SupplierDetail.Id === editREFSupplier.Id);
            toEditREFSupplier.ReferenceId = editREFId;
        },
        editPOPartsDiscount: (state,action) => {
            const editDiscountSupplier = action.payload.Supplier;
            const editDiscount = action.payload.Discount;

            const toEditDiscountSupplier = state.PurchaseOrderItemTemplateCart.find((supplier) => supplier.SupplierDetail.Id === editDiscountSupplier.Id);
            toEditDiscountSupplier.Discount = editDiscount;
        },
        editPOPartsDeliveryPrice: (state,action) => {
            const editDeliveryPriceSupplier = action.payload.Supplier;
            const editDeliveryPrice = action.payload.DeliveryPrice;

            const toEditDiscountSupplier = state.PurchaseOrderItemTemplateCart.find((supplier) => supplier.SupplierDetail.Id === editDeliveryPriceSupplier.Id);
            toEditDiscountSupplier.DeliveryPrice = editDeliveryPrice;
        },
        deleteSupplierPOParts: (state,action) => {
            state.PurchaseOrderItemTemplateCart =  state.PurchaseOrderItemTemplateCart.filter((supplier) => supplier.SupplierDetail.Id !== action.payload.Id)
        },
    },
})

export const {
    adjustPOCart,
    deleteItemInPOCart,
    resetPOCart,
    editPODetail,
    editPORefId,
    editPODiscount,
    editPODeliveryPrice,
    deleteSupplierPO,
    adjustPOPartsCart,
    deleteItemInPOPartsCart,
    resetPOPartsCart,
    editPOPartsDetail,
    editPOPartsRefId,
    editPOPartsDiscount,
    editPOPartsDeliveryPrice,
    deleteSupplierPOParts
} = procurementSlice.actions

export const selectPurchaseOrderItemCart = (state) => state.procurement.PurchaseOrderItemCart;
export const selectPurchaseOrderItemPartsCart = (state) => state.procurement.PurchaseOrderItemTemplateCart;
export default procurementSlice.reducer
