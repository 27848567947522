import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import GridDetailText from '../../customs/GridDetailText';
import ProjectPaper from '../../customs/ProjectPaper';
import { useLocales } from '../../../locales';

ItemMasterDetailForCreateLot.propTypes = {
  itemMaster: PropTypes.object.isRequired,
};

function ItemMasterDetailForCreateLot({ itemMaster }) {
  const { translate } = useLocales();

  return (
    <ProjectPaper>
      <Grid container spacing={3}>
        <GridDetailText title={translate('title.id')} value={itemMaster.Id} />
        <GridDetailText title={translate('title.name')} value={itemMaster.Name} />
        <GridDetailText title={translate('title.type')} value={itemMaster.ItemType.Name} />
        <GridDetailText title={translate('unit.title')} value={itemMaster.ItemUnit.Name} />
        <GridDetailText title={translate('title.detail')} value={itemMaster.Detail} />
        <GridDetailText title={translate('title.safety_amount')} value={itemMaster.SafetyAmount} />
        <GridDetailText
          title={translate('warehouse.remain_amount_title')}
          value={itemMaster.Amount}
        />
        <GridDetailText title={translate('title.amount_defect')} value={itemMaster.DefectAmount} />
      </Grid>
    </ProjectPaper>
  );
}

export default ItemMasterDetailForCreateLot;
