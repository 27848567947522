import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Stack, Button } from '@mui/material';
import FormProvider, { RHFTextField } from 'components/minimal/hook-form';
import AddressSelector from './AddressSelector';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from 'locales';

CreateCustomerForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};

function CreateCustomerForm({ onFormSubmit }) {
  const { translate } = useLocales();
  const CustomerDetailSchema = Yup.object().shape({
    Name: Yup.string().required(translate('error.name_is_require')),
    AdditionAddress: Yup.string().required(translate('error.address_require')),
    District: Yup.object().required(translate('error.address_require')),
    TaxRegistrationNumber: Yup.string().required(
      translate('error.tax_registration_number_require')
    ),
    TelephoneNumber: Yup.string().required(translate('error.telephone_number_require')),
  });

  const defaultValues = {
    Name: '',
    AdditionAddress: '',
    District: '',
    TaxRegistrationNumber: '',
    TelephoneNumber: '',
  };

  const methods = useForm({
    resolver: yupResolver(CustomerDetailSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const onSubmit = (values) => {
    onFormSubmit(values);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={translate('title.detail')} />
        <CardContent>
          <Stack spacing={3}>
            <RHFTextField name="Name" label={translate('title.name')} />
            <RHFTextField name="AdditionAddress" label={translate('title.additional_address')} />
            <AddressSelector
              name="District"
              onDistrictChange={setValue}
              error={errors.District !== undefined}
            />
            <RHFTextField
              name="TaxRegistrationNumber"
              label={translate('title.tax_registration_number')}
            />
            <RHFTextField name="TelephoneNumber" label={translate('title.telephone_number')} />
            <Button variant={'contained'} color={'primary'} fullWidth type={'submit'}>
              {translate('button.create')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </FormProvider>
  );
}

export default CreateCustomerForm;
