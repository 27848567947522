import {
  cancelPurchaseRequisition,
  createPurchaseRequisition,
  getPurchaseRequisitionDetail,
  printPurchaseRequisition,
  uploadPRDocument,
} from '../WarehouseServerHandler';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  OpenNotificationThenCallback,
  OpenSuccessNotification,
} from '../../utilities/Helper/NotificationHelper';
import { PrintMultiplePDFMakeDocument } from '../../utilities/Helper/PrintHelper';
import { pr_prefix } from '../../utilities/Strings/Prefix';
import { useLocales } from '../../locales';
import { useDispatch } from 'react-redux';
import { resetPurchaseCart } from '../../redux/reducers/inventory';
import { paths } from '../../routes/paths';
import { useNavigate } from 'react-router-dom';

export const usePurchaseRequisition = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { translate } = useLocales();
  const { data } = useQuery({
    queryKey: ['pr-detail', id],
    queryFn: () => getPurchaseRequisitionDetail(id),
    initialData: null,
    enabled: !!id,
  });

  const printPRMutation = useMutation({
    mutationFn: printPurchaseRequisition,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(translate('title.complete'), '', async () => {
        await PrintMultiplePDFMakeDocument([response.PDF], `${pr_prefix}_${id}.pdf`);
      });
    },
  });
  const createPRMutation = useMutation({
    mutationFn: createPurchaseRequisition,
    onSuccess: async (response) => {
      await OpenNotificationThenCallback(
        translate('title.complete'),
        `${translate('procurement.success_create_purchase_requisition_id_title')}:${response.Id}`,
        async () => {
          await sessionStorage.removeItem('pr_detail');
          await sessionStorage.removeItem('pr_temp_item_master');
          await printPRMutation.mutateAsync(response.Id);
          await dispatch(resetPurchaseCart());
          navigate(paths.procurement.pr_detail(response.Id));
        }
      );
    },
  });
  const uploadPRFileMutation = useMutation({
    mutationFn: uploadPRDocument,
    onSuccess: async () => {
      await OpenNotificationThenCallback(
        translate('title.complete'),
        translate('notification.success_upload_purchase_requisition_doc_title'),
        async () => {
          await queryClient.invalidateQueries(['pr-detail', id]);
        }
      );
    },
  });

  const cancelPRMutation = useMutation({
    mutationFn: cancelPurchaseRequisition,
    onSuccess: async () => {
      await OpenSuccessNotification();
      await queryClient.invalidateQueries(['pr-detail', id]);
    },
  });

  return {
    prDetail: data,
    createPR: createPRMutation,
    printPR: printPRMutation,
    uploadPRFile: uploadPRFileMutation,
    cancelPR: cancelPRMutation,
  };
};
