import { format, getTime, formatDistanceToNow } from 'date-fns';
import thLocale  from 'date-fns/locale/th'
// ----------------------------------------------------------------------

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateTimeDMY(date) {
    return format(new Date(date), 'dd/MM/yy');
}

export function fToNow(date) {
    return date
        ? formatDistanceToNow(new Date(date), {
            addSuffix: true,
        })
        : '';
}

export function fDateTimeText(date) {
    if (date === undefined || date === null) {
        return 'N/A'
    }
    return format(new Date(date), 'PPPP pp',{
        locale: thLocale
    });
}

export function fDateTimeTextShort(date) {
    if (date === undefined || date === null) {
        return 'N/A'
    }
    return format(new Date(date), 'Pp',{
        locale: thLocale
    });
}

export function fDate(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy';

    return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
    const fm = newFormat || 'dd MMM yyyy p';

    return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
    return date ? getTime(new Date(date)) : '';
}

